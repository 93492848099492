import React, { useContext, useEffect } from "react";
import { Route } from "react-router-dom";
import styled from "styled-components/macro";
import ErrorMessages from "../components/Notifications/ErrorMessages";
import SpinningLoader from "../components/Loaders/SpinningLoader";
import { AuthContext } from "../contexts/AuthContext";
import Login from "./Login";

const FullHeightContainer = styled.div`
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LoginRedirect = React.memo(function LoginRedirect() {
  const { loginErrors, doLogin, user, groupDataLoading, checkGroupErrors } =
    useContext(AuthContext);

  useEffect(() => {
    if (!loginErrors && !user) {
      //set current URL
      const currentURL = new URL(window.location);
      //set Token
      const token = currentURL.searchParams.get("token");
      //begin Login
      doLogin(token);
    }
  }, [doLogin, loginErrors, user]);

  if (user?.id && groupDataLoading) {
    return (
      <FullHeightContainer>
        <SpinningLoader text="Logging In" />
      </FullHeightContainer>
    );
  }

  return (
    <Route
      render={(props) =>
        loginErrors || checkGroupErrors ? (
          <FullHeightContainer>
            <Login {...props} />

            <ErrorMessages
              {...props}
              errors={loginErrors || checkGroupErrors}
            />
          </FullHeightContainer>
        ) : (
          <FullHeightContainer>
            <SpinningLoader text="Logging In" />
          </FullHeightContainer>
        )
      }
    />
  );
});

export default LoginRedirect;
