import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import {
  StyledSelect,
  FormControl,
  StyledInput,
} from "../../Form/FormControls";
import { patterns } from "./patterns";
import { ColumnModifiers } from "./buildingBlocks";
import { VscBeaker } from "react-icons/vsc";
import { MdOutlineHelp } from "react-icons/md";
import MarkdownFetcher from "../../../views/FeedsPage/transformerFunctions/MarkDownFetcher";
import Modal from "../../Modal";
import { Scrollbars } from "react-custom-scrollbars";
import NCalcEditor from "./NcalcEditor";

const NodeBody = styled.div`
  padding: 0.5em;
  flex: 1;
  background: rgba(0, 0, 0, 0.03);
`;

const Target = ({
  node,
  dispatch,
  index,
  isFilter,
  enableTest,
  testRow,
  goodTestParse,
}) => {
  let testRowDataOptions = [];

  try {
    if (testRow && goodTestParse) {
      testRowDataOptions = JSON.parse(testRow);
    }
  } catch (e) {
    testRowDataOptions = [];
  }

  const testOptions = testRowDataOptions.map((to, i) => {
    return {
      label: to,
      value: i,
    };
  });

  const isColumn = node.target.typeInformation[1].typeValue?.value === 1;

  const currentOperation = node.operation.typeInformation[1].typeValue?.value;

  const acceptableOperationsModifiers = [1, 2, 3, 4, 5, 14, 15];

  const showModifier = currentOperation
    ? acceptableOperationsModifiers.includes(currentOperation)
    : null;

  const TargetOptions = [
    { label: "Column", value: 1 },
    { label: "Value", value: 2 },
    { label: "Variable", value: 3 },
    { label: "Calculation", value: 4 },
  ];

  const businessRule = node.operation.typeInformation[1].typeValue?.value;

  const TargetVariables = [
    { label: "Current Date", value: 1 },
    { label: "Current Time", value: 2 },
    { label: "Current DateTime", value: 3 },
    { label: "Current Day Of Month", value: 4 },
    { label: "Current Day Of Week", value: 5 },
    { label: "Current Day Of Year", value: 6 },
    { label: "Current Week Of Year", value: 7 },
    { label: "Current Month", value: 8 },
    { label: "Current Year", value: 9 },
  ];

  if (isFilter) {
    TargetVariables.push({ label: "Most Recent Date", value: 10 });
  }

  const TargetVariableModifierTypes = [
    { label: "None", value: 0 },
    { label: "+", value: 1 },
    { label: "-", value: 2 },
  ];

  const sourceModifier = node.target.typeInformation.find(
    (ti) => ti?.typeHierarchyLevel === 3
  )?.typeValue;

  const [enableColumnAttribute, setEnableColumnAttribute] = useState(
    node.target.typeInformation.find((ti) => ti?.typeHierarchyLevel === 3)
      ? true
      : false
  );

  useEffect(() => {
    if (enableColumnAttribute) {
      dispatch({
        type: "UPDATE_MODIFIER_TARGET",
        payload: {
          node: node,
          value: sourceModifier ?? ColumnModifiers[0],
          index: index,
          fragmentValue: node?.target?.typeInformation[3]?.fragmentValue,
        },
      });
    } else {
      dispatch({
        type: "REMOVE_MODIFIER_TARGET",
        payload: {
          node: node,
          value: ColumnModifiers[0],
          index: index,
        },
      });
    }
    // eslint-disable-next-line
  }, [enableColumnAttribute, dispatch, sourceModifier]);

  //Show Helper
  const [showHelper, setShowHelper] = useState(false);

  const setHelper = (helperName) => {
    setShowHelper(helperName);
  };

  const isCalculation =
    node?.target?.typeInformation[1]?.typeValue?.value === 4;
  //when clicking on the modal to highlight text, its closing
  // allow search of text and go to anchor
  const isByAmountOrisByPercentage =
    node.operation.typeInformation[2]?.typeValue === 4 ||
    node.operation.typeInformation[2]?.typeValue === 5;

  return (
    <>
      {showHelper ? (
        <Modal hide={() => setShowHelper(false)}>
          <Scrollbars autoHeightMax={window.innerHeight * 0.7} autoHeight>
            <div
              style={{
                padding: "1rem",
                background: "#fbfbfb",
                marginBottom: "1rem",
              }}
            >
              <MarkdownFetcher
                hideClose
                MdFile={showHelper}
                close={setShowHelper}
              />
            </div>
          </Scrollbars>
        </Modal>
      ) : null}
      <NodeBody>
        {node?.source?.typeInformation[1]?.typeValue?.value !== 4 && (
          <>
            {businessRule !== 13 ? (
              <FormControl>
                <StyledSelect
                  className={`react-select-container`}
                  classNamePrefix={`react-select`}
                  name={`target`}
                  id={`target`}
                  inputId={`target-input`}
                  instanceId={`target-instance`}
                  label="target"
                  options={TargetOptions.map((to) => {
                    return {
                      ...to,
                      isDisabled:
                        businessRule === 16 ||
                        businessRule === 17 ||
                        isByAmountOrisByPercentage,
                    };
                  })}
                  placeholder={`Select Target Type`}
                  value={node.target.typeInformation[1].typeValue}
                  menuPortalTarget={document.body}
                  onChange={(e) => {
                    setEnableColumnAttribute(false);
                    dispatch({
                      type: "UPDATE_TARGET_TYPE",
                      payload: { node: node, value: e, index: index },
                    });
                  }}
                />
              </FormControl>
            ) : (
              <FormControl style={{ minWidth: "250px" }}>
                <StyledSelect
                  className={`react-select-container`}
                  classNamePrefix={`react-select`}
                  name={`regExPremade`}
                  id={`regExPremade`}
                  inputId={`regExPremade-input`}
                  instanceId={`regExPremade-instance`}
                  label="regExPremade"
                  options={patterns}
                  placeholder={`Select Pre-Made Pattern`}
                  value={
                    patterns.find(
                      (p) =>
                        p?.value ===
                        node.target.typeInformation[1].fragmentValue
                    ) ?? "Select pattern or enter custom below"
                  }
                  menuPortalTarget={document.body}
                  onChange={(e) =>
                    dispatch({
                      type: "UPDATE_TARGET_NAME",
                      payload: { node: node, value: e?.value, index: index },
                    })
                  }
                />
              </FormControl>
            )}
          </>
        )}

        <FormControl>
          {node.target.typeInformation[1].typeValue?.value === 3 ? (
            <StyledSelect
              className={`react-select-container`}
              classNamePrefix={`react-select`}
              name={`target-variables`}
              id={`target-variables`}
              inputId={`target-variables-input`}
              instanceId={`target-variables-instance`}
              label="variables"
              options={TargetVariables}
              placeholder={`Variables`}
              menuPortalTarget={document.body}
              value={node.target.typeInformation[1].fragmentValue}
              onChange={(e) =>
                dispatch({
                  type: "UPDATE_TARGET_VARIABLE_TYPE",
                  payload: { node: node, value: e, index: index },
                })
              }
            />
          ) : (
            <>
              {/* FIX THIS MATT */}
              {isCalculation ? (
                <NCalcEditor
                  node={node}
                  value={node.target.typeInformation[1].fragmentValue ?? ""}
                  dispatch={dispatch}
                  index={index}
                />
              ) : (
                <StyledInput
                  type="text"
                  name="target-value"
                  value={node.target.typeInformation[1].fragmentValue}
                  placeholder={
                    node.target.typeInformation[1].typeValue?.value === 1
                      ? "Name"
                      : "Value"
                  }
                  onChange={(e) =>
                    dispatch({
                      type: "UPDATE_TARGET_NAME",
                      payload: {
                        node: node,
                        value: e.target?.value,
                        index: index,
                      },
                    })
                  }
                />
              )}
            </>
          )}
        </FormControl>
        {node.target.typeInformation[1].typeValue?.value === 3 ? (
          <>
            Modifier
            <FormControl>
              <StyledSelect
                className={`react-select-container`}
                classNamePrefix={`react-select`}
                name={`variable-modifierType`}
                id={`variable-modifierType`}
                inputId={`variable-modifierType-input`}
                instanceId={`variable-modifierType-instance`}
                label="Modifier Type"
                options={TargetVariableModifierTypes}
                placeholder={`Modifier Type`}
                menuPortalTarget={document.body}
                value={node.target.typeInformation[2].typeValue}
                onChange={(e) =>
                  dispatch({
                    type: "UPDATE_TARGET_MODIFIED_TYPE",
                    payload: { node: node, value: e, index: index },
                  })
                }
              />
            </FormControl>
            {node.target.typeInformation[2].typeValue?.value !== 0 ? (
              <FormControl>
                <StyledInput
                  type="number"
                  name="variable-modifier-value"
                  value={node.target.typeInformation[2].fragmentValue}
                  placeholder="Modifier Value"
                  onChange={(e) =>
                    dispatch({
                      type: "UPDATE_TARGET_MODIFIED_VALUE",
                      payload: {
                        node: node,
                        value: e.target?.value,
                        index: index,
                      },
                    })
                  }
                />
              </FormControl>
            ) : null}
          </>
        ) : null}

        {isColumn && showModifier ? (
          <>
            <FormControl>
              <label>
                Use Modifier
                <input
                  type="checkbox"
                  checked={enableColumnAttribute}
                  onChange={() => {
                    setEnableColumnAttribute((prevState) => !prevState);
                  }}
                />
              </label>
            </FormControl>
            {enableColumnAttribute ? (
              <>
                <FormControl>
                  <StyledSelect
                    aria-label="fragmentColumnSelect"
                    className={`react-select-container`}
                    classNamePrefix={`react-select`}
                    name={`source`}
                    id={`source`}
                    inputId={`source-input`}
                    instanceId={`source-instance`}
                    label="Source"
                    options={
                      ColumnModifiers.filter((cm) => cm.label !== "Count") ??
                      ColumnModifiers
                    }
                    placeholder={`Select Modifier Type`}
                    value={sourceModifier}
                    menuPortalTarget={document.body}
                    onChange={(e) =>
                      dispatch({
                        type: "UPDATE_MODIFIER_TARGET",
                        payload: {
                          node: node,
                          value: e,
                          index: index,
                          fragmentValue:
                            node?.target?.typeInformation[3]?.fragmentValue,
                        },
                      })
                    }
                  />
                </FormControl>

                {node.target?.typeInformation[3]?.typeHierarchyLevel === 3 &&
                node.target?.typeInformation[3]?.typeValue?.value === 2 ? (
                  <FormControl>
                    <StyledInput
                      type="number"
                      name="variable-modifier-value"
                      value={node?.target?.typeInformation[3]?.fragmentValue}
                      placeholder="Modifier Value"
                      label="Number of days to add (to subtract, use a negative value)"
                      onChange={(e) =>
                        dispatch({
                          type: "UPDATE_TARGET_MODIFIER_VALUE",
                          payload: {
                            node: node,
                            value: e.target?.value,
                            index: index,
                          },
                        })
                      }
                    />
                  </FormControl>
                ) : null}
              </>
            ) : null}

            {enableTest ? (
              <FormControl>
                <VscBeaker /> Test Value{" "}
                <StyledSelect
                  aria-label="fragmentColumnSelect"
                  className={`react-select-container`}
                  classNamePrefix={`react-select`}
                  name={`source`}
                  id={`sourceTest`}
                  inputId={`sourceTest-input`}
                  instanceId={`sourceTest-instance`}
                  label="Source"
                  options={testOptions}
                  placeholder={`Select Source Type`}
                  value={node.target.testInfo}
                  menuPortalTarget={document.body}
                  onChange={(e) =>
                    dispatch({
                      type: "UPDATE_TEST_VALUE_TARGET",
                      payload: { node: node, value: e, index: index },
                    })
                  }
                />
              </FormControl>
            ) : null}
          </>
        ) : null}
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          {isCalculation ? (
            <div
              style={{
                display: "flex",
                fontSize: ".8rem",
                alignItems: "center",
              }}
              onClick={() => setHelper("N_CALC")}
            >
              Calculation Help
              <MdOutlineHelp
                style={{
                  marginLeft: ".5rem",
                  fontSize: "1.2rem",
                  cursor: "pointer",
                }}
                title="Calculation Help"
              />
            </div>
          ) : null}
        </div>
      </NodeBody>
    </>
  );
};

export default Target;
