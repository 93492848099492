import React, { useEffect, useCallback } from "react";
import { FormControl } from "../../components/Form/FormControls";
import { setDataSourceColumnMetadata } from "../../api/dataSourceMutations";
import SortTable from "../../components/Table/SortTable";
import { StyledSelect } from "../../components/Form/FormControls";
import { useApi } from "../../api/useApi";
import { dataSourceColumns } from "../../api/dataSourceQueries";
import ErrorMessages from "../../components/Notifications/ErrorMessages";
import columnFlagUpdateOptions from "../../common/helpers/datasources/columnFlagUpdateOptions";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Button from "../../components/Button";
import { setTagInstances } from "../../api/tagMutations";

const typeOptions = [
  { label: "DATE TIME", value: "DATE_TIME" },
  { label: "DECIMAL", value: "DECIMAL" },
  { label: "EMAIL", value: "EMAIL" },
  { label: "PHONE NUMBER", value: "PHONE_NUMBER" },
  { label: "STRING", value: "STRING" },
  { label: "WHOLE NUMBER", value: "WHOLE_NUMBER" },
  { label: "BOOLEAN", value: "BOOLEAN" },
  { label: "UUID", value: "UUID" },
  { label: "DATE TIME OFFSET", value: "DATE_TIME_OFFSET" },
  { label: "TIMESPAN", value: "TIMESPAN" },
];

const ColumnView = ({
  sourceId,
  sourceType,
  dispatch,
  isSingleSource,
  hasSourceId,
}) => {
  const [{ loading, errors, data: apiData }, getSource] = useApi();

  const [
    { loading: updating, errors: updateErrors, data: updateData },
    updateFetch,
  ] = useApi();

  const [
    {
      // loading: updatingSourceType,
      // errors: updateSourceTypeErrors,
      data: sourceTypeData,
    },
    doUpdateSourceType,
  ] = useApi();

  const getUpdatedSource = useCallback(() => {
    getSource({
      query: dataSourceColumns,
      variables: { id: Number(sourceId) },
    });
  }, [sourceId, getSource]);

  useEffect(() => {
    getSource({
      query: dataSourceColumns,
      variables: { id: Number(sourceId) },
    });
  }, [sourceId, getSource]);

  const updatePrimaryKey = useCallback(
    (columnData, isPrimaryKey) => {
      const variables = {
        metadata: {
          columnId: columnData.id,
          description: null,
          dataType: null,
          flagAction: isPrimaryKey
            ? columnFlagUpdateOptions.addPrimaryKey
            : columnFlagUpdateOptions.removePrimaryKey,
        },
      };

      updateFetch({ query: setDataSourceColumnMetadata, variables });
    },
    [updateFetch]
  );

  const updateType = useCallback(
    (value, columnData) => {
      const variables = {
        metadata: {
          columnId: columnData.id,
          description: columnData.description,
          dataType: value.value,
          flagAction: columnFlagUpdateOptions.none,
        },
      };

      updateFetch({ query: setDataSourceColumnMetadata, variables });
    },
    [updateFetch]
  );

  useEffect(() => {
    if (updateData) {
      getUpdatedSource();
      toast.success("Column Updated", {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }, [updateData, getUpdatedSource]);

  const updateCodeBreak = useCallback(
    (columnData, isBreaker) => {
      const variables = {
        metadata: {
          columnId: columnData.id,
          description: null,
          dataType: null,
          flagAction: isBreaker
            ? columnFlagUpdateOptions.addTrackUniqueValues
            : columnFlagUpdateOptions.removeTrackUniqueValues,
        },
      };

      updateFetch({ query: setDataSourceColumnMetadata, variables });
    },
    [updateFetch]
  );

  const updateSourceType = useCallback(() => {
    const dataSource = apiData?.dataSource;
    let currentTagIds = dataSource?.tagInstances?.map((ti) => ti?.tagId) ?? [];
    const columns = apiData?.dataSource?.columns ?? [];
    const foundRequiresCodeBreak = columns.find((c) => (c.flags & 64) > 0);

    if (!currentTagIds.includes(sourceType)) {
      doUpdateSourceType({
        query: setTagInstances,
        variables: !foundRequiresCodeBreak
          ? {
              remoteObjectId: dataSource?.id,
              tagIds: [...currentTagIds, sourceType, -4],
              type: "DATA_SOURCE",
            }
          : {
              remoteObjectId: dataSource?.id,
              tagIds: [...currentTagIds, sourceType],
              type: "DATA_SOURCE",
            },
      });
    } else {
      dispatch({ type: "CHANGE_STATE", payload: 2 });
    }
  }, [doUpdateSourceType, apiData, sourceType, dispatch]);

  useEffect(() => {
    if (sourceTypeData) {
      dispatch({ type: "CHANGE_STATE", payload: 2 });
    }
  }, [sourceTypeData, dispatch]);

  const columnsData = [
    {
      Header: "Column Name",
      id: "name",
      accessor: (d) => d?.name,
    },
    {
      Header: "Data Type",
      id: "datatype",
      accessor: (d) => d.dataType,
      Cell: ({ row: { original } }) => {
        return (
          <>
            <StyledSelect
              className={`react-select-container`}
              classNamePrefix={`react-select`}
              name={`dataType`}
              label={"Data Type"}
              id={`${original?.name}-dataType`}
              inputId={`${original?.name}-dataType-input`}
              instanceId={`${original?.name}-dataType-instance`}
              menuPortalTarget={document.body}
              styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
              options={typeOptions}
              placeholder={`Select Type`}
              value={typeOptions.find(
                (type) => type.value === original.dataType
              )}
              menuPlacement="auto"
              onChange={(e) => updateType(e, original)}
            />
          </>
        );
      },
    },

    {
      Header: "Primary Key",
      id: "primarykey",
      accessor: (d) => d.flags,
      width: 120,
      Cell: ({ row: { original } }) => {
        const flags = original?.flags;

        /*
          Flags === 4: PK
          Flags === 32: AsOfDate
        */
        const isPk = (flags & 4) > 0;
        const isAsOfDate = (flags & 32) > 0;
        return (
          <>
            <FormControl>
              <input
                disabled={updating || isAsOfDate}
                type="checkbox"
                name={`columns.${original.id}.primarykey`}
                label="Primary Key"
                checked={isPk}
                onChange={() => updatePrimaryKey(original, !isPk)}
              />
            </FormControl>
          </>
        );
      },
    },

    {
      Header: "Requires Code Break",
      id: "codebreak",
      accessor: (d) => d.flags,
      width: 120,
      Cell: ({ row: { original } }) => {
        const flags = original?.flags;
        /*
          2 = Unique
          4 = PK
          16 = Matching
          32 = AsOfDate
          64 = TrackUnique/CodeBreak
        */
        const isPK = (flags & 4) > 0;
        const isAsOfDate = (flags & 32) > 0;
        const isMatching = (flags & 16) > 0;
        const isCodeBreak = (flags & 64) > 0;
        return (
          <>
            <FormControl>
              <input
                disabled={isAsOfDate || isMatching || updating || isPK}
                type="checkbox"
                name={`columns.${original.id}.codeBreak`}
                label="Is CodeBreak"
                checked={isCodeBreak}
                onChange={() => updateCodeBreak(original, !isCodeBreak)}
              />
            </FormControl>
          </>
        );
      },
    },
  ];

  return (
    <>
      <h3>{apiData?.dataSource?.name} was selected, confirm column details</h3>
      <p>
        Update your Column Data, as well as mark those columns that need
        code-breaking.
      </p>
      <ErrorMessages errors={[...(errors ?? []), ...(updateErrors ?? [])]} />

      <SortTable
        dontReset={true}
        data={apiData?.dataSource?.columns ?? []}
        columns={columnsData}
        loading={loading}
        defaultPageSize={25}
      />

      <ToastContainer />

      {isSingleSource && (
        <>
          {!hasSourceId && (
            <Button
              list
              onClick={() => dispatch({ type: "CHANGE_STATE", payload: 0 })}
            >
              Back
            </Button>
          )}
          <Button onClick={() => updateSourceType()}>Continue</Button>
        </>
      )}
    </>
  );
};

export default ColumnView;
