import React from "react";
import Card from "../../Card";
import Spinner from "../../../components/Loaders/Spinner";
import DetailDataPoint from "./DetailDataPoint";
import CTAMessage from "../../../components/Help/CTAMessage";
import ErrorMessages from "../../../components/Notifications/ErrorMessages";
export default React.memo(
  ({ data, loading, errors, dataSourceName, failureCount }) => {
    const sourceName = dataSourceName ?? null;

    //Body Section of Widget
    function Body() {
      if (loading) return <Spinner />;
      if (errors)
        return (
          <CTAMessage
            msg={
              <div>
                <ErrorMessages errors={errors} />
              </div>
            }
          />
        );

      if (!data)
        return (
          <CTAMessage
            msg={
              <div>
                <p style={{ textAlign: "center" }}>No Data Available</p>
              </div>
            }
          />
        );

      let fillColor;
      switch (data?.bar?.failedBusinessRuleInstancePriority) {
        case "HIGH":
          fillColor = "#008CF6";
          break;
        case "NORMAL":
          fillColor = "#6ABCFA";
          break;
        case "LOW":
          fillColor = "#A9D8FC";
          break;
        default:
          fillColor = "#008CF6";
          break;
      }

      return (
        <>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "1rem",
            }}
          >
            <div
              style={{
                flex: 1,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                paddingLeft: "1rem",
                paddingRight: "1rem",
              }}
            >
              Rule Priority{" "}
              <div
                style={{
                  padding: "1rem",
                  borderRadius: "10px",
                  backgroundColor: fillColor,
                  color: "#fff",
                  marginLeft: "1rem",
                }}
              >
                {data?.bar?.failedBusinessRuleInstancePriority}
              </div>
            </div>

            <div
              style={{
                flex: 1,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderLeft: "2px solid #e8e8e8",
                borderRight: "2px solid #e8e8e8",
                paddingLeft: "1rem",
                paddingRight: "1rem",
              }}
            >
              <div style={{ marginLeft: "1rem", marginRight: "1rem" }}>
                Failure Reason
              </div>
              <DetailDataPoint data={data?.bar} dataPoint={"nullFailures"} />
              <DetailDataPoint data={data?.bar} dataPoint={"incorrectValues"} />
              <DetailDataPoint data={data?.bar} dataPoint={"noMatchingRows"} />
            </div>
            <div
              style={{
                flex: 1,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                paddingLeft: "1rem",
                paddingRight: "1rem",
              }}
            >
              <div style={{ marginRight: "1rem" }}>% Of All Rule Failures</div>
              <DetailDataPoint
                data={data?.bar}
                failureCount={failureCount}
                dataPoint={"allFailures"}
              />
            </div>
          </div>
          {/* <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              padding: "1rem",
            }}
          >
            <Link to={`/details`}>View Failure Details</Link>
          </div> */}
        </>
      );
    }

    return (
      <Card
        title={data?.bar?.failedRuleStandardName ?? ""}
        titleDescription={sourceName}
        body={Body}
        active={"topRight"}
      />
    );
  }
);
