import React, { useEffect, useContext } from "react";
import { ToastContainer, toast } from "react-toastify";
import { useApi } from "../api/useApi";
import { SocketContext } from "../contexts/useSubscriptions";

const dismissNotifications = `
  mutation($ids: [UUID!]!){
    dismissNotifications(ids: $ids)
  }
`;

const GenericToastNotification = () => {
  const { toastNotification } = useContext(SocketContext);
  const [{}, clear] = useApi(); // eslint-disable-line no-empty-pattern

  useEffect(() => {
    if (toastNotification?.payload?.AlertType === 53) {
      const clearNotif = (id) => {
        clear({
          query: dismissNotifications,
          variables: {
            ids: [id],
          },
        });
      };

      toast.warn(
        `${toastNotification?.payload?.MessageToDisplay}${
          toastNotification?.payload?.ErrorMessage
            ? ` - ${toastNotification?.payload?.ErrorMessage}`
            : ``
        }`,
        {
          position: "top-center",
          autoClose: false,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          toastId: toastNotification?.payload?.id,
          progress: undefined,
          onClose: () => clearNotif(toastNotification?.id),
        }
      );
    }
  }, [toastNotification, clear]);

  return (
    <ToastContainer
      position="top-center"
      autoClose={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      theme="colored"
    />
  );
};

export default GenericToastNotification;
