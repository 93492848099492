import React from "react";
import {
  ResponsiveContainer,
  Tooltip,
  XAxis,
  Line,
  LineChart,
  CartesianGrid,
  YAxis,
  Legend,
} from "recharts";
import AbsoluteHeadingCard from "../../Card/AbsoluteHeadingCard";
import Spinner from "../../Loaders/Spinner";
import { format } from "date-fns";
import styled, { withTheme } from "styled-components";

const StyledToolTip = styled.div`
  background: #555555;
  padding: 1em;
`;

const Title = styled.div`
  color: #ffffff;
  font-size: 0.8rem;
`;

const LegendContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 50px;
  text-align: center;
  left: 0px;
  top: 10px;
`;

const LegendIcon = ({ color }) => {
  return (
    <>
      <div
        style={{
          backgroundColor: color ?? "#0099FF",
          display: "block",
          width: "1rem",
          marginRight: ".2rem",
          height: ".5rem",
          borderRadius: ".5rem",
        }}
      />
    </>
  );
};

const DataSourceWithOpportunitiesHistory = React.memo(
  ({ data, loading, errors, theme }) => {
    // const sourceName = (data && data.sourceName) || null;

    //Body Section of Widget

    const historyData =
      (data &&
        data.history &&
        data.history.map((hist) => {
          return {
            ...hist,
            date: new Date(hist.date).getTime(),
          };
        })) ||
      [];

    const sortedHistoryData = historyData
      .slice()
      .sort((a, b) => b.date - a.date);

    //Body Section of Widget
    function Body() {
      if (loading)
        return (
          <div
            style={{
              position: "relative",
              minHeight: "300px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Spinner />
          </div>
        );
      if (errors)
        return (
          <div
            style={{
              position: "relative",
              minHeight: "300px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            Errors
          </div>
        );
      if (!sortedHistoryData.length)
        return (
          <div
            style={{
              position: "relative",
              minHeight: "300px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            No Historical Failure Data To Display
          </div>
        );

      function CustomTooltip(props) {
        if (props.active && props.payload && props.payload.length) {
          const { payload } = props;

          const date = payload && payload.length && payload[0].payload.date;

          return (
            <StyledToolTip>
              {payload.map((item, i) => {
                return (
                  <div
                    key={`tooltipFailureItem-${i}`}
                    style={{ color: "#ffffff", fontSize: "2rem" }}
                  >
                    {item.value}
                  </div>
                );
              })}

              <Title>{format(date, "MMM dd, HH:mm")}</Title>
            </StyledToolTip>
          );
        } else {
          return null;
        }
      }

      const renderLegend = (props) => {
        return (
          <LegendContainer>
            <div
              style={{
                display: "flex",
                marginRight: "1rem",
                alignItems: "center",
                marginBottom: "1rem",
                justifyContent: "center",
              }}
            >
              <LegendIcon /> Total Failures
            </div>
          </LegendContainer>
        );
      };

      return (
        <div style={{ position: "relative" }}>
          <ResponsiveContainer width="99.9%" height={300}>
            <LineChart
              data={sortedHistoryData}
              margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
            >
              <Legend height={50} verticalAlign="top" content={renderLegend} />
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                dataKey="date"
                domain={["auto", "auto"]}
                name="Time"
                tickLine={false}
                tickFormatter={(date) => format(date, "MMM dd, HH:mm")}
                type="number"
                fontSize={".8rem"}
                dy={5}
              />
              <YAxis dx={-5} tickLine={false} />
              <YAxis
                dx={5}
                tickLine={false}
                dataKey="failures"
                yAxisId="right"
                orientation="right"
              />
              <Tooltip content={<CustomTooltip />} />
              <Line
                type="monotone"
                dataKey="failures"
                stroke={"#0099FF"}
                fill={"#0099FF"}
              />
            </LineChart>
          </ResponsiveContainer>
        </div>
      );
    }

    return (
      <AbsoluteHeadingCard
        title={"History of total Failures"}
        // actions={Actions}
        body={Body}
        active={"topRight"}
      />
    );
  }
);

export default withTheme(DataSourceWithOpportunitiesHistory);
