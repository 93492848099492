import React, { useCallback, useContext } from "react";
import Card from "../../components/Card";
import { MdAdd } from "react-icons/md";
import ActionWrapper from "../../components/ActionsWrapper";
import StyledLink from "../../components/StyledLink";
import { useApi } from "../../api/useApi";
import Spinner from "../../components/Loaders/Spinner";
import { connection } from "../../api/connectionQueries";
import ErrorMessages from "../../components/Notifications/ErrorMessages";
import ConnectionTabs from "./ConnectionTabs";
import { AuthContext } from "../../contexts/AuthContext";

const BodyComponent = ({ connectionId, getConnection, connectionData }) => {
  const getUpdatedConnection = useCallback(() => {
    getConnection({
      query: connection,
      variables: { id: Number(connectionId) },
    });
  }, [getConnection, connectionId]);

  // const { connectionImportFinished, setConnectionImportFinished } =
  //   useConnectionImportFinished();

  // useEffect(() => {
  //   if (connectionImportFinished) {
  //     getUpdatedConnection();
  //     setConnectionImportFinished(null);
  //   }
  // }, [connectionImportFinished, setConnectionImportFinished]);

  return (
    <ConnectionTabs
      connectionId={connectionId}
      data={connectionData}
      getUpdatedConnection={getUpdatedConnection}
    />
  );
};

const ConnectionDetails = (props) => {
  const connectionId =
    props &&
    props.match &&
    props.match.params &&
    props.match.params.connectionId;

  //Available Connections Graph
  const [{ loading, errors, data }, getConnection] = useApi(connection, {
    id: Number(connectionId),
  });

  const { user } = useContext(AuthContext);

  if (!connectionId) return;

  if (loading) return <Spinner />;
  if (errors) return <ErrorMessages errors={errors} />;

  const connectionData = data?.connection;
  const connectionTitle = connectionData?.name;

  //Body Section of Widget
  function Body() {
    return (
      <BodyComponent
        connectionId={connectionId}
        getConnection={getConnection}
        connectionData={connectionData}
      />
    );
  }

  //Actions Section of Widget
  function Actions() {
    if (user && user?.role <= 2) return null;
    return (
      <>
        <ActionWrapper>
          <StyledLink to={"/connections/create"} title="Add New Connection">
            <MdAdd />
          </StyledLink>
        </ActionWrapper>
      </>
    );
  }

  return (
    <Card
      title={connectionTitle && connectionTitle}
      titleDescription={"Manage Data Connection"}
      body={Body}
      actions={Actions}
    />
  );
};

export default ConnectionDetails;
