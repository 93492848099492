import React from "react";
import Card from "../../Card";
import DataSourceList from "./DataSourceList";
import DataSourceArchiveList from "./DataSourceArchiveList";
import StyledLink from "../../StyledLink";

const DataSourceListWidget = ({ archive }) => {
  //Body Section of Widget
  function Body() {
    if (archive) return <DataSourceArchiveList />;
    return <DataSourceList />;
  }

  function Actions() {
    if (archive) return <StyledLink to={`/sources`}>View Active</StyledLink>;
    return <StyledLink to={`/sources/archive`}>View Archive</StyledLink>;
  }
  return (
    <Card
      title={"Sources"}
      titleDescription={
        archive ? "Archived Data Sources" : "Available Data Sources"
      }
      body={Body}
      actions={Actions}
    />
  );
};

export default DataSourceListWidget;
