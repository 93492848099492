import { useContext } from "react";
import { SocketContext } from "../contexts/useSubscriptions";

const UseETLProviderUpdateNotification = () => {
  const { etlProviderUpdateCompleted, setEtlProviderUpdateCompleted } =
    useContext(SocketContext);

  return { etlProviderUpdateCompleted, setEtlProviderUpdateCompleted };
};

export default UseETLProviderUpdateNotification;
