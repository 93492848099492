import React, {
  useState,
  useRef,
  useContext,
  useEffect,
  useCallback,
} from "react";
import Card from "../../components/Card";
import PagedTable from "../../components/Table/PagedTable";
import { useApi } from "../../api/useApi";
import { tagsPageList } from "../../api/tagQueries";
import columnSort from "../../components/Table/helpers/columnSort";
import TableActions from "../../components/Table/TableActions";
import TableButton from "../../components/Button/TableButton";
import { MdDelete } from "react-icons/md";
import { AuthContext } from "../../contexts/AuthContext";
import Modal from "../../components/Modal";
import { deleteTag } from "../../api/tagMutations";
import Spinner from "../../components/Loaders/Spinner";
import Button from "../../components/Button";
import {
  FormControl,
  StyledInput,
  Label,
} from "../../components/Form/FormControls";
import ErrorMessages from "../../components/Notifications/ErrorMessages";
import { updateTagQuery } from "../../api/tagMutations";
import { ToastContainer, toast } from "react-toastify";

const EditTag = ({ tag, close, updatedTagCB }) => {
  const [{ loading, data, errors }, updateTag] = useApi();

  const [name, setName] = useState(tag?.name);
  const [description, setDescription] = useState(tag?.description);

  const editTag = () => {
    updateTag({
      query: updateTagQuery,
      variables: {
        tag: {
          id: tag?.id,
          description: description,
          name: name,
        },
      },
    });
  };

  useEffect(() => {
    if (data && !errors) {
      updatedTagCB();
      close();
      toast.success("Tag Updated", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }, [data, updatedTagCB, errors, close]);

  return (
    <div>
      <FormControl>
        <Label>Name</Label>
        <StyledInput
          type="text"
          name="TagName"
          value={name}
          placeholder={"Tag Name"}
          onChange={(e) => setName(e?.target?.value)}
        />
      </FormControl>
      <FormControl>
        <Label>Description</Label>
        <StyledInput
          type="text"
          name="TagDescription"
          value={description}
          placeholder={"Tag Description"}
          onChange={(e) => setDescription(e?.target?.value)}
        />
      </FormControl>

      {errors ? <ErrorMessages errors={errors} /> : null}
      <div>
        <Button
          type="button"
          list="true"
          disabled={loading}
          danger
          onClick={() => editTag()}
        >
          {loading ? <Spinner /> : "Yes"}
        </Button>
        <Button
          type="button"
          disabled={loading}
          onClick={() => {
            close(null);
          }}
        >
          Cancel
        </Button>
      </div>
    </div>
  );
};

function Body() {
  //currentPage
  const [currentView, setCurrentView] = useState(null);

  const [showConfirm, setShowConfirm] = useState(false);
  const [showEdit, setShowEdit] = useState(false);

  const [tagDelete, setTagDelete] = useState(null);
  const [tagEdit, setTagEdit] = useState(null);
  //Init Data Fetch
  const [{ loading, data: apiData }, doFetch] = useApi();
  const [{ loading: removalLoading, data: removalData }, remove] = useApi();

  const tags = apiData?.tagsPAGED?.edges ?? [];
  const totalCount = apiData?.tagsPAGED?.totalCount;
  const pageInfo = apiData?.tagsPAGED?.pageInfo;
  const ref = useRef(null);

  const { user } = useContext(AuthContext);

  //Set Function to enable child component to fetch parent reference for width
  const getTableWidth = () => {
    return ref?.current?.offsetWidth ?? 0;
  };

  //Fetch for Table Paged
  const fetchData = React.useCallback(
    ({ pageSize, cursor, sortBy }) => {
      const sortedObject = columnSort(sortBy);
      setCurrentView(cursor);

      doFetch({
        query: tagsPageList,
        variables: {
          first: pageSize,
          after: cursor,
          ...(sortedObject
            ? {
                order: {
                  ...sortedObject,
                },
              }
            : {
                order: {
                  name: "ASC",
                },
              }),
        },
      });
    },
    [doFetch, setCurrentView]
  );

  //handle remove rule update
  useEffect(() => {
    if (removalData) {
      fetchData({ pageSize: 10, cursor: currentView ?? null });
      setShowConfirm(false);
      setTagDelete(null);
      toast.success("Tag Deleted", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }, [removalData, fetchData, currentView]);

  useEffect(() => {
    if (tagDelete) {
      setShowConfirm(true);
    } else {
      setShowConfirm(false);
    }
  }, [tagDelete]);

  useEffect(() => {
    if (tagEdit) {
      setShowEdit(true);
    } else {
      setShowEdit(false);
    }
  }, [tagEdit]);

  const tagToDelete = ({ id }) => {
    remove({
      query: deleteTag,
      variables: { id: id },
    });
  };

  const updatedTagCB = useCallback(() => {
    fetchData({ pageSize: 10, cursor: currentView ?? null });
  }, [fetchData, currentView]);

  const columnsData = [
    {
      Header: "Name",
      id: "name",
      accessor: "node.name",
      Cell: ({ row: { original } }) => {
        return (
          <div style={{ maxWidth: "400px" }}>
            <div style={{ fontWeight: "bold" }}>{original?.node?.name}</div>
            <div>{original?.node?.description}</div>
          </div>
        );
      },
    },
    {
      Header: "Instances",
      id: "tagInstanceCount",
      accessor: "node.tagInstanceCount",
      Cell: ({ row: { original } }) => {
        return <div>{original?.node?.tagInstanceCount}</div>;
      },
    },
    {
      Header: "Sources Count",
      id: "datasources",
      Cell: ({ row: { original } }) => {
        return <div>{original?.node?.dataSources?.length}</div>;
      },
    },
    {
      Header: "Policy Count",
      id: "businessRuleStandardCount",
      Cell: ({ row: { original } }) => {
        return <div>{original?.node?.businessRuleStandards?.length}</div>;
      },
    },
    {
      Header: "Workflow Count",
      id: "workflowsCounts",
      Cell: ({ row: { original } }) => {
        return <div>{original?.node?.workflows?.length}</div>;
      },
    },
    {
      Header: " ",
      id: "actions",
      resizable: false,
      sortable: false,
      Cell: ({ totalColumnsWidth, row: { original } }) => {
        const isStystemTag = original?.node?.id < 0;
        return (
          <div style={{ textAlign: "right" }}>
            <TableActions
              minWidth={115}
              totalColumnsWidth={totalColumnsWidth}
              getTableWidth={getTableWidth}
            >
              <TableButton
                list="true"
                type="button"
                disabled={isStystemTag}
                title={isStystemTag ? "System Tag" : "Edit"}
                bumpdown={true}
                onClick={() => setTagEdit(original?.node)}
              >
                {"Edit"}
              </TableButton>
              {user && user.role >= 2 && (
                <TableButton
                  danger
                  list="true"
                  type="button"
                  disabled={isStystemTag}
                  title={isStystemTag ? "System Tag" : "Delete"}
                  bumpdown={true}
                  onClick={() =>
                    setTagDelete({
                      id: original?.node?.id,
                      name: original?.node?.name,
                    })
                  }
                >
                  <MdDelete />
                </TableButton>
              )}
            </TableActions>
          </div>
        );
      },
    },
  ];

  return (
    <>
      {showConfirm ? (
        <Modal
          title={`Confirm ${tagDelete?.name} Deletion`}
          hide={() => setTagDelete(null)}
        >
          <p>
            Are you sure you wish to Delete {tagDelete?.name}tag? All instances
            of the mapped tag will be removed.
          </p>
          <div>
            <Button
              type="button"
              list="true"
              disabled={removalLoading}
              danger
              onClick={() => tagToDelete(tagDelete)}
            >
              {removalLoading ? <Spinner /> : "Yes"}
            </Button>
            <Button
              type="button"
              disabled={removalLoading}
              onClick={() => {
                setTagDelete(null);
              }}
            >
              Cancel
            </Button>
          </div>
        </Modal>
      ) : null}

      {showEdit ? (
        <Modal title={`Edit Tag`} hide={() => setShowEdit(null)}>
          <EditTag
            tag={tagEdit}
            close={setShowEdit}
            updatedTagCB={updatedTagCB}
          />
        </Modal>
      ) : null}

      <div ref={ref}>
        <PagedTable
          fetchData={fetchData}
          loading={loading}
          pageInfo={pageInfo}
          totalCount={totalCount}
          data={tags}
          columns={columnsData}
          defaultPageSize={50}
        />
      </div>
      <ToastContainer />
    </>
  );
}

const AdminPage = () => {
  return <Card title={"Tags"} titleDescription={"Manage Tags"} body={Body} />;
};

export default AdminPage;
