import React, { useState } from "react";
import ViewMapping from "../../RuleStandardPage/ViewMappingModal";
import styled from "styled-components/macro";
import FailureContextTable from "./FailureContextTable";
import Button from "../../../components/Button";

const ModalContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const ViewMappingContainer = styled.div`
  visibility: ${(props) => (props.showMapping ? "visible" : "hidden")};
  overflow: hidden;
  width: ${(props) => (props.showMapping ? "100%" : 0)};
`;

const FailureViewDetails = ({
  apiData,
  sourceId,
  refreshSummaryId,
  failedRuleInstanceId,
  failedRuleInstanceVersionId,
  failedRuleStandardVersionId,
}) => {
  const [showMapping, setShowMapping] = useState(false);

  return (
    <ModalContainer>
      <ViewMappingContainer showMapping={true}>
        <FailureContextTable
          dataSourceRefreshSummaryId={refreshSummaryId}
          ruleInstanceVersionId={failedRuleInstanceVersionId}
          apiData={apiData}
          showMapping={showMapping}
          setShowMapping={setShowMapping}
        />
      </ViewMappingContainer>
      {showMapping && (
        <div
          style={{
            borderLeft: "1px solid #ccc",
            marginLeft: "1rem",
            paddingRight: "1rem",
          }}
          onClick={() => setShowMapping((prevState) => !prevState)}
        ></div>
      )}

      <ViewMappingContainer showMapping={showMapping}>
        <div style={{ position: "absolute", right: 0, marginRight: "1.5rem" }}>
          <Button onClick={() => setShowMapping((prevState) => !prevState)}>
            Hide Policy
          </Button>
        </div>
        <ViewMapping
          sourceId={sourceId}
          instanceVersionId={failedRuleInstanceVersionId}
          ruleInstanceId={failedRuleStandardVersionId}
          ruleId={failedRuleInstanceId}
          editEnabled={false}
        />
      </ViewMappingContainer>
    </ModalContainer>
  );
};

export default FailureViewDetails;
