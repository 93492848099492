import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";

const copyString = (string) => {
  const el = document.createElement("textarea");
  el.value = string;
  el.setAttribute("readonly", "");
  el.style.position = "absolute";
  el.style.left = "-9999px";
  document.body.appendChild(el);
  const selected =
    document.getSelection().rangeCount > 0
      ? document.getSelection().getRangeAt(0)
      : false;
  el.select();
  document.execCommand("copy");
  document.body.removeChild(el);
  if (selected) {
    document.getSelection().removeAllRanges();
    document.getSelection().addRange(selected);
  }
};

const CopyContainer = styled.div`
  position: relative;
  cursor: pointer;
  font-size: 0.8125rem;
`;

const CopyNotification = styled.div`
  position: absolute;

  color: #fefefe;
  background-color: #333;
  padding: 0.4rem;
  top: -250%;
  z-index: 3;
`;

const Copy = (props) => {
  const [hasCopied, setHasCopied] = useState(false);

  function copy() {
    copyString(props.children);
    setHasCopied(true);
  }

  useEffect(() => {
    if (hasCopied) {
      const timer = setTimeout(() => setHasCopied(false), 1500);
      return () => clearTimeout(timer);
    }
  }, [hasCopied]);

  return (
    <CopyContainer onClick={copy}>
      {hasCopied && <CopyNotification>Copied!</CopyNotification>}
      {props.children}
    </CopyContainer>
  );
};

export default Copy;
