import React, { useState, useRef, useEffect, useContext } from "react";
import styled, { keyframes } from "styled-components";
import ReactMarkdown from "react-markdown";
import { MdRefresh } from "react-icons/md";
import { AiSettingsContext } from "../../contexts/ai/AiSettingsContext";

const AiChatWindow = styled.div`
  position: fixed;
  right: 0;
  width: 435px;
  height: 465px;
  padding-right: 2rem;
  bottom: 0;
  z-index: 99;
`;

const typingAnimation = keyframes`
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  50% {
    opacity: 0.5;
    transform: translateY(-5px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

const ChatBubble = styled.div`
  position: relative;
  background-color: #fff;
  border-radius: 20px;
  padding: 15px;
  margin-bottom: 10px;
`;

const Typing = styled.div`
  display: flex;
  align-items: center;
`;

const Dot = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #ccc;
  margin-right: 5px;
  animation: ${typingAnimation} 1s infinite;

  &:nth-child(2) {
    animation-delay: 0.2s;
  }

  &:nth-child(3) {
    animation-delay: 0.4s;
  }
`;

function ChatAnimation() {
  return (
    <ChatBubble>
      <Typing>
        <Dot />
        <Dot />
        <Dot />
      </Typing>
    </ChatBubble>
  );
}

const ChatContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  border-radius: 1rem;
  height: 450px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: 3px 3px rgba(0, 0, 0, 0.2);
  background: #fff;
  z-index: 998;
  padding: 1rem;
  width: 100%;
  margin-right: 2rem;
`;

const ChatBox = styled.div`
  background: #fff;
  display: flex;
  height: 100%;
  flex-direction: column;
`;

const MessagesContainer = styled.div`
  flex: 1;
  overflow-y: auto;
  flex-grow: 1;
  display: flex;
  height: 100%;
  flex-direction: column;
  padding-right: 0.5rem;
`;

const MessageBubble = styled.div`
  display: inline-block;
  max-width: 80%;
  padding: 8px 12px;
  border-radius: 20px;
  margin-bottom: 8px;
  font-size: 14px;
  line-height: 1.4;
  background-color: ${(props) => (props.isSender ? "#121135" : "#e5e5ea")};
  color: ${(props) => (props.isSender ? "white" : "black")};
  align-self: ${(props) => (props.isSender ? "flex-end" : "flex-start")};
  p:last-child {
    margin-bottom: 0;
  }

  ul {
    margin-top: 8px;
    margin-bottom: 8px;
    padding-left: 20px;
    list-style-type: disc; /* Add bullet points to list items */
  }

  ol {
    margin-top: 8px;
    margin-bottom: 8px;
    padding-left: 20px;
    list-style-type: decimal; /* Add numbers to list items */
  }

  li {
    margin-bottom: 4px;
  }
`;

const InputContainer = styled.div`
  display: flex;
  margin-top: 16px;
`;

const Input = styled.input`
  flex: 1;
  padding: 12px;
  font-size: 14px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 8px 0 0 8px;
`;

const SendButton = styled.button`
  padding: 12px;
  font-size: 14px;
  border: none;
  border-radius: 0 8px 8px 0;
  background-color: #121135;
  color: white;
  cursor: pointer;
`;

const ResetButton = styled.button`
  font-size: 1.4rem;
  border: none;
  border-radius: 8px;
  background-color: #efefef;
  color: #999;
  display: flex;
  margin-right: 0.5rem;
  align-items: center;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  cursor: pointer;
`;

const SmallVersion = styled.div`
  font-size: 0.7rem;
  color: grey;
  font-style: italic;
  margin-bottom: 1rem;
  margin-left: auto;
`;

const AiChat = ({ messages, sendMessage, isLoading, reset, isVisible }) => {
  const [inputValue, setInputValue] = useState("");
  const { enabled } = useContext(AiSettingsContext);

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleSendClick = () => {
    sendMessage({ role: "user", content: inputValue, shouldRender: true });
    setInputValue("");
  };

  const messagesEndRef = useRef(null);
  const lastMessageRef = useRef(null);
  const chatBubbleRef = useRef(null);

  useEffect(() => {
    if (isLoading) {
      messagesEndRef.current.scrollTop = chatBubbleRef.current.offsetTop - 45;
    } else if (lastMessageRef.current) {
      messagesEndRef.current.scrollTop = lastMessageRef.current.offsetTop - 45;
    }
  }, [messages, isLoading]);

  if (!enabled) {
    return;
  }

  return (
    <>
      <AiChatWindow
        style={{
          display: isVisible ? "block" : "none",
        }}
      >
        <ChatContainer>
          <ChatBox>
            <SmallVersion>v1.0.0</SmallVersion>
            <MessagesContainer ref={messagesEndRef}>
              {messages
                .filter((m) => m.shouldRender)
                .map((message, index, array) => {
                  // Replace each newline with two newlines
                  let messageSpaced = message.content.replace(/\n/g, "\n\n");

                  return (
                    <MessageBubble
                      key={index}
                      isSender={message?.role === "user"}
                      ref={chatBubbleRef}
                    >
                      <ReactMarkdown children={messageSpaced} />
                    </MessageBubble>
                  );
                })}
              {isLoading && <ChatAnimation />}
            </MessagesContainer>
            <InputContainer>
              <ResetButton
                onClick={() => reset()}
                title="Reset Chat"
                type="button"
              >
                <MdRefresh />
              </ResetButton>
              <Input
                type="text"
                placeholder="Type your message here..."
                value={inputValue}
                onChange={handleInputChange}
                disabled={isLoading}
                onKeyDown={(e) => {
                  if (!isLoading && e.key === "Enter" && inputValue.trim()) {
                    handleSendClick();
                  }
                }}
              />
              <SendButton
                disabled={!inputValue || isLoading}
                onClick={handleSendClick}
              >
                Send
              </SendButton>
            </InputContainer>
          </ChatBox>
        </ChatContainer>
      </AiChatWindow>
    </>
  );
};

export default AiChat;
