import React, { useEffect } from "react";
import Button from "../../Button";
import { useApi } from "../../../api/useApi";
import { dataSourceColumns } from "../../../api/dataSourceQueries";
import {
  setReconciliationDataSourceColumns,
  clearReconciliationDataSourceColumns,
} from "../../../api/dataSourceMutations";
import Spinner from "../../Loaders/Spinner";
import SplashLoader from "../../Loaders/SplashLoader";
import ErrorMessages from "../../Notifications/ErrorMessages";
import * as Yup from "yup";
import { Formik } from "formik";
import {
  FormControl,
  FormActions,
  StyledSelect,
  Label,
} from "../../Form/FormControls";
import { ToastContainer, toast } from "react-toastify";

const ReconciliationRuleSettings = ({ sourceId, modalAction }) => {
  const [{ loading, errors, data: apiData }] = useApi(dataSourceColumns, {
    id: Number(sourceId),
  });

  const [
    { errors: mappingErrors, loading: mappingColumns, data: reconColumnMap },
    mapReconColumns,
  ] = useApi();

  const [
    {
      errors: clearReconError,
      loading: clearReconLoading,
      data: clearReconData,
    },
    clearReconColumns,
  ] = useApi();

  useEffect(() => {
    if (modalAction && clearReconData && !mappingErrors) {
      modalAction();
    }
  }, [clearReconData, modalAction, mappingErrors]);

  useEffect(() => {
    if (modalAction && reconColumnMap && !mappingErrors) {
      modalAction();
    }
  }, [reconColumnMap, modalAction, mappingErrors]);

  const clearRecon = () => {
    const variables = {
      dataSourceId: sourceId,
    };

    clearReconColumns({
      query: clearReconciliationDataSourceColumns,
      variables: variables,
    });
  };

  const columns = apiData?.dataSource?.columns ?? [];

  let columnOptions = columns.map((column, i) => {
    return {
      value: column.id,
      label: column.name,
    };
  });

  const initialMatchingColumns =
    columns
      .filter((c) => c.flags >= 18 && c.flags <= 22)
      .map((column, i) => {
        return {
          value: column.id,
          label: column.name,
        };
      }) ?? [];

  const findInitialAsOfColumn = columns.find((c) => c.flags === 32) ?? null;
  const initialAsOfDateColumn = findInitialAsOfColumn
    ? { value: findInitialAsOfColumn.id, label: findInitialAsOfColumn.name }
    : null;

  if (loading)
    return (
      <div style={{ marginTop: "1rem" }}>
        <SplashLoader text={"Loading Columns"} />
      </div>
    );
  if (errors)
    return (
      <div style={{ marginTop: "1rem" }}>
        <ErrorMessages errors={errors} />
      </div>
    );

  return (
    <>
      <h3>RECONCILIATION RULES</h3>

      <Formik
        initialValues={{
          // caseSensitive: false, // Disabled until cross-table
          columnIds: initialMatchingColumns,
          asOfDateColumn: initialAsOfDateColumn,
        }}
        enableReinitialize
        validateOnMount={true}
        validationSchema={Yup.object().shape({
          columnIds: Yup.array().min(1).required("Required"),
          asOfDateColumn: Yup.object().required("Required"),
        })}
        onSubmit={(values) => {
          const variables = {
            mappingInstruction: {
              asOfDateColumnId: values?.asOfDateColumn?.value,
              dataSourceId: Number(sourceId),
              matchingColumnIds: values?.columnIds.map((c) => c.value),
            },
          };

          mapReconColumns({
            query: setReconciliationDataSourceColumns,
            variables: variables,
          });
        }}
      >
        {(props) => {
          return (
            <form onSubmit={props.handleSubmit}>
              <div style={{ display: "flex" }}>
                <div style={{ flex: 1, marginRight: "1rem" }}>
                  <FormControl>
                    <Label>Primary Keys</Label>
                    <StyledSelect
                      className={`react-select-container`}
                      classNamePrefix={`react-select`}
                      name={`columnIds`}
                      id={`columnIds`}
                      inputId={`columnIds-columnIdsSelect-input`}
                      instanceId={`columnIds-columnIdsSelect-instance`}
                      label="Primary Keys"
                      options={columnOptions.map((co) => {
                        return {
                          value: co.value,
                          label: co.label,
                          isDisabled:
                            co.value === props?.values?.asOfDateColumn?.value ??
                            false,
                        };
                      })}
                      placeholder={`Select`}
                      value={props.values.columnIds}
                      menuPlacement="auto"
                      isMulti
                      menuPortalTarget={document.body}
                      onChange={(e) => props.setFieldValue(`columnIds`, e)}
                    />
                  </FormControl>
                </div>
                <div style={{ flex: 1 }}>
                  <FormControl>
                    <Label>"Most Recent Date" Column</Label>
                    <StyledSelect
                      className={`react-select-container`}
                      classNamePrefix={`react-select`}
                      name={`asOfDateColumn`}
                      id={`asOfDateColumn`}
                      inputId={`asOfDateColumn-asOfDateColumnSelect-input`}
                      instanceId={`asOfDateColumn-asOfDateColumnSelect-instance`}
                      label={'"Most Recent Date" Column'}
                      options={columnOptions.map((co) => {
                        return {
                          value: co.value,
                          label: co.label,
                          isDisabled:
                            props?.values?.columnIds?.find(
                              (c) => c.value === co.value
                            ) ?? false,
                        };
                      })}
                      menuPortalTarget={document.body}
                      placeholder={`Select`}
                      value={props.values.asOfDateColumn}
                      menuPlacement="auto"
                      onChange={(e) => props.setFieldValue(`asOfDateColumn`, e)}
                    />
                  </FormControl>
                </div>
              </div>

              {/* Disabled-until cross-table */}
              {/* <FormControl>
                <label>
                  Primary Keys are Case Sensitive
                  <Field type="checkbox" name={`caseSensitive`} />
                </label>
              </FormControl> */}

              <FormActions>
                <Button list type="button" danger onClick={() => clearRecon()}>
                  {clearReconLoading ? <Spinner /> : "Clear"}
                </Button>
                <Button type="submit" disabled={!props.isValid}>
                  {mappingColumns ? <Spinner /> : "Update Reconciliation"}
                </Button>
                {clearReconError ? (
                  <ErrorMessages errors={clearReconError} />
                ) : null}
                {mappingErrors ? (
                  <ErrorMessages errors={mappingErrors} />
                ) : null}
              </FormActions>
            </form>
          );
        }}
      </Formik>
      <ToastContainer />
    </>
  );
};

export default ReconciliationRuleSettings;
