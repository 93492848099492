import React, { useCallback, useEffect, useState, useContext } from "react";
import { Formik, Form } from "formik";

import Button from "../../components/Button";
import Spinner from "../../components/Loaders/Spinner";
import ErrorMessages from "../../components/Notifications/ErrorMessages";
import { useApi } from "../../api/useApi";
import { NavLink, Route, Switch } from "react-router-dom";
import styled from "styled-components/macro";
import SortTable from "../../components/Table/SortTable";
import {
  updateWorkingDayHolidays,
  updateWorkingDays,
} from "../../api/configurationMutations";
import {
  allWorkingDayHolidays,
  allWorkingDays,
} from "../../api/configurationQueries";
import TableButton from "../../components/Button/TableButton";
import { NavContext } from "../../components/Layout/NavStateProvider";
import TransformEdit from "../../components/TableRowView/TransformEdit";
import {
  FormControl,
  FormActions,
  StyledField,
  StyledSelect,
  Label,
} from "../../components/Form/FormControls";

const Tabs = styled.div`
  border-bottom: 1px solid ${(props) => props.theme.surfaceAlt};
  background-color: ${(props) => props.theme.surface};
  position: relative;
  padding: 0.6rem;
  margin-bottom: 1rem;
`;

const TabLink = styled(NavLink)`
  font-size: 0.9rem;
  padding: 0.5rem;
  border-bottom: 2px solid transparent;
  text-decoration: none;
  opacity: 0.5;
  color: ${(props) => props.theme.onSurface};
  &.active {
    opacity: 1;
    border-bottom: 2px solid ${(props) => props.theme.onSecondarySurface};
  }
  &:hover {
    opacity: 1;
  }
  text-transform: uppercase;
`;

const enUsHolidays = [
  { label: "New Year's Day", value: "New Year" },
  { label: "New Year's Eve", value: "New Year's Eve" },
  {
    label: "Birthday of Martin Luther King, Jr.",
    value: "Birthday of Martin Luther King, Jr.",
  },
  { label: "Washington’s Birthday", value: "Washington’s Birthday" },
  { label: "Memorial Day", value: "Memorial Day" },
  { label: "Juneteenth", value: "Juneteenth" },
  { label: "Independence Day", value: "Independence Day" },
  { label: "Labor Day", value: "Labor Day" },
  { label: "Columbus Day", value: "Columbus Day" },
  { label: "Veterans Day", value: "Veterans Day" },
  { label: "Thanksgiving Day", value: "Thanksgiving Day" },
  { label: "Christmas", value: "Christmas" },
];

const holidayTypeOptions = [
  { label: "Culture Defined", value: "CULTURE_DEFINED" }, // default
  {
    label: "Fixed Holiday - e.g Christmas is on 25th of December",
    value: "FIXED_HOLIDAY",
  },
  {
    label: "Nth Day of Week After a Specific Day",
    value: "NTH_DAY_OF_WEEK_AFTER_DAY_HOLIDAY",
  },
  {
    label: "Nth Day of Week After an Already Defined Holiday (recursive)",
    value: "NTH_DAY_OF_WEEK_AFTER_BASE_HOLIDAY",
  },
  {
    label:
      "Nth Day of Week in a Month - e.g. Thanksgiving is last Thursday in November",
    value: "NTH_DAY_OF_WEEK_IN_MONTH_HOLIDAY",
  },
];

const HolidayForm = ({ isEditing, collapse, holidays, setFieldValue }) => {
  const [currentItem, setCurrentItem] = useState({ ...isEditing?.original });

  const CloseAndSave = () => {
    let currentHolidays = [...holidays];
    currentHolidays[isEditing?.index] = currentItem;
    setFieldValue(`workingDayHolidays`, currentHolidays);
    collapse();
  };

  const renderFields = useCallback(() => {
    switch (currentItem?.type) {
      default:
        return null;

      case "CULTURE_DEFINED":
        return (
          <>
            <FormControl>
              <Label>Name</Label>
              <StyledSelect
                className={`react-select-container`}
                classNamePrefix={`react-select`}
                name={`Name`}
                id={`Name`}
                inputId={`Name-input`}
                instanceId={`Name-instance`}
                menuPortalTarget={document.body}
                placeholder={`Name`}
                label="Name"
                value={
                  enUsHolidays.find((ht) => ht.value === currentItem?.name) ??
                  null
                }
                options={enUsHolidays}
                onChange={(e) => {
                  setCurrentItem((currentItem) => {
                    let item = { ...currentItem };
                    item.name = e.value;
                    return item;
                  });
                }}
              />
            </FormControl>
            {/* we should create an endpoint that lists these based on the culture info */}

            <FormControl>
              <StyledField
                name={`cultureInfo`}
                type="text"
                placeholder="culture Info"
                label="culture Info"
                value={currentItem?.cultureInfo}
                onChange={(e) => {
                  setCurrentItem((currentItem) => {
                    let item = { ...currentItem };
                    item.cultureInfo = e?.target?.value;
                    return item;
                  });
                }}
              />
            </FormControl>

            <FormControl>
              <Label>
                <input
                  type="checkbox"
                  checked={currentItem?.enabled}
                  onChange={(e) =>
                    setCurrentItem((currentItem) => {
                      let item = { ...currentItem };
                      item.enabled = e?.target?.checked;
                      return item;
                    })
                  }
                />
                Enabled
              </Label>
            </FormControl>
          </>
        );

      case "FIXED_HOLIDAY":
        return (
          <>
            <FormControl>
              <StyledField
                name={`name`}
                type="text"
                placeholder="name"
                label="name"
                value={currentItem?.name}
                onChange={(e) => {
                  setCurrentItem((currentItem) => {
                    let item = { ...currentItem };
                    item.name = e?.target?.value;
                    return item;
                  });
                }}
              />
            </FormControl>

            <FormControl>
              <StyledField
                name={`month`}
                type="number"
                key="month"
                placeholder="month"
                label="month"
                value={currentItem?.month}
                onChange={(e) => {
                  setCurrentItem((currentItem) => {
                    let item = { ...currentItem };
                    item.month = Number(e?.target?.value);
                    return item;
                  });
                }}
              />
            </FormControl>

            <FormControl>
              <StyledField
                name={`day`}
                type="number"
                placeholder="day"
                label="day"
                value={currentItem?.day}
                onChange={(e) => {
                  setCurrentItem((currentItem) => {
                    let item = { ...currentItem };
                    item.day = Number(e?.target?.value);
                    return item;
                  });
                }}
              />
            </FormControl>

            <FormControl>
              <Label>
                <input
                  type="checkbox"
                  checked={currentItem?.enabled}
                  onChange={(e) =>
                    setCurrentItem((currentItem) => {
                      let item = { ...currentItem };
                      item.enabled = e?.target?.checked;
                      return item;
                    })
                  }
                />
                Enabled
              </Label>
            </FormControl>
          </>
        );

      case "NTH_DAY_OF_WEEK_AFTER_DAY_HOLIDAY":
        return (
          <>
            <FormControl>
              <StyledField
                name={`name`}
                type="text"
                placeholder="name"
                label="name"
                value={currentItem?.name}
                onChange={(e) => {
                  setCurrentItem((currentItem) => {
                    let item = { ...currentItem };
                    item.name = e?.target?.value;
                    return item;
                  });
                }}
              />
            </FormControl>

            <FormControl>
              <StyledField
                name={`count`}
                type="number"
                key="count"
                placeholder="count"
                label="count"
                value={currentItem?.count}
                onChange={(e) => {
                  setCurrentItem((currentItem) => {
                    let item = { ...currentItem };
                    item.count = Number(e?.target?.value);
                    return item;
                  });
                }}
              />
            </FormControl>

            <FormControl>
              <StyledField
                name={`dayOfWeek`}
                type="number"
                key="dayOfWeek"
                placeholder="dayOfWeek"
                label="dayOfWeek"
                value={currentItem?.dayOfWeek}
                onChange={(e) => {
                  setCurrentItem((currentItem) => {
                    let item = { ...currentItem };
                    item.dayOfWeek = Number(e?.target?.value);
                    return item;
                  });
                }}
              />
            </FormControl>

            <FormControl>
              <StyledField
                name={`month`}
                type="number"
                key="month"
                placeholder="month"
                label="month"
                value={currentItem?.month}
                onChange={(e) => {
                  setCurrentItem((currentItem) => {
                    let item = { ...currentItem };
                    item.month = Number(e?.target?.value);
                    return item;
                  });
                }}
              />
            </FormControl>

            <FormControl>
              <StyledField
                name={`day`}
                type="number"
                placeholder="day"
                label="day"
                value={currentItem?.day}
                onChange={(e) => {
                  setCurrentItem((currentItem) => {
                    let item = { ...currentItem };
                    item.day = Number(e?.target?.value);
                    return item;
                  });
                }}
              />
            </FormControl>

            <FormControl>
              <Label>
                <input
                  type="checkbox"
                  checked={currentItem?.enabled}
                  onChange={(e) =>
                    setCurrentItem((currentItem) => {
                      let item = { ...currentItem };
                      item.enabled = e?.target?.checked;
                      return item;
                    })
                  }
                />
                Enabled
              </Label>
            </FormControl>
          </>
        );

      case "NTH_DAY_OF_WEEK_AFTER_BASE_HOLIDAY":
        return (
          <>
            <FormControl>
              <StyledField
                name={`name`}
                type="text"
                placeholder="name"
                label="name"
                value={currentItem?.name}
                onChange={(e) => {
                  setCurrentItem((currentItem) => {
                    let item = { ...currentItem };
                    item.name = e?.target?.value;
                    return item;
                  });
                }}
              />
            </FormControl>

            <FormControl>
              <StyledField
                name={`count`}
                type="number"
                key="count"
                placeholder="count"
                label="count"
                value={currentItem?.count}
                onChange={(e) => {
                  setCurrentItem((currentItem) => {
                    let item = { ...currentItem };
                    item.count = Number(e?.target?.value);
                    return item;
                  });
                }}
              />
            </FormControl>

            <FormControl>
              <StyledField
                name={`dayOfWeek`}
                type="number"
                key="dayOfWeek"
                placeholder="dayOfWeek"
                label="dayOfWeek"
                value={currentItem?.dayOfWeek}
                onChange={(e) => {
                  setCurrentItem((currentItem) => {
                    let item = { ...currentItem };
                    item.dayOfWeek = Number(e?.target?.value);
                    return item;
                  });
                }}
              />
            </FormControl>

            <FormControl>
              <StyledField
                name={`baseHolidayName`}
                type="text"
                key="baseHolidayName"
                placeholder="baseHolidayName"
                label="baseHolidayName"
                value={currentItem?.baseHolidayName}
                onChange={(e) => {
                  setCurrentItem((currentItem) => {
                    let item = { ...currentItem };
                    item.baseHolidayName = e?.target?.value;
                    return item;
                  });
                }}
              />
            </FormControl>

            <FormControl>
              <Label>
                <input
                  type="checkbox"
                  checked={currentItem?.enabled}
                  onChange={(e) =>
                    setCurrentItem((currentItem) => {
                      let item = { ...currentItem };
                      item.enabled = e?.target?.checked;
                      return item;
                    })
                  }
                />
                Enabled
              </Label>
            </FormControl>
          </>
        );

      case "NTH_DAY_OF_WEEK_IN_MONTH_HOLIDAY":
        return (
          <>
            <FormControl>
              <StyledField
                name={`name`}
                type="text"
                placeholder="name"
                label="name"
                value={currentItem?.name}
                onChange={(e) => {
                  setCurrentItem((currentItem) => {
                    let item = { ...currentItem };
                    item.name = e?.target?.value;
                    return item;
                  });
                }}
              />
            </FormControl>

            <FormControl>
              <StyledField
                name={`count`}
                type="number"
                key="count"
                placeholder="count"
                label="count"
                value={currentItem?.count}
                onChange={(e) => {
                  setCurrentItem((currentItem) => {
                    let item = { ...currentItem };
                    item.count = Number(e?.target?.value);
                    return item;
                  });
                }}
              />
            </FormControl>

            <FormControl>
              <StyledField
                name={`dayOfWeek`}
                type="number"
                key="dayOfWeek"
                placeholder="dayOfWeek"
                label="dayOfWeek"
                value={currentItem?.dayOfWeek}
                onChange={(e) => {
                  setCurrentItem((currentItem) => {
                    let item = { ...currentItem };
                    item.dayOfWeek = Number(e?.target?.value);
                    return item;
                  });
                }}
              />
            </FormControl>

            <FormControl>
              <StyledField
                name={`month`}
                type="number"
                key="month"
                placeholder="month"
                label="month"
                value={currentItem?.month}
                onChange={(e) => {
                  setCurrentItem((currentItem) => {
                    let item = { ...currentItem };
                    item.month = Number(e?.target?.value);
                    return item;
                  });
                }}
              />
            </FormControl>

            <FormControl>
              <StyledField
                name={`direction`}
                type="text"
                key="direction"
                placeholder="direction"
                label="direction"
                value={currentItem?.direction}
                onChange={(e) => {
                  setCurrentItem((currentItem) => {
                    let item = { ...currentItem };
                    item.direction = e?.target?.value;
                    return item;
                  });
                }}
              />
            </FormControl>

            <FormControl>
              <Label>
                <input
                  type="checkbox"
                  checked={currentItem?.enabled}
                  onChange={(e) =>
                    setCurrentItem((currentItem) => {
                      let item = { ...currentItem };
                      item.enabled = e?.target?.checked;
                      return item;
                    })
                  }
                />
                Enabled
              </Label>
            </FormControl>
          </>
        );
    }
  }, [currentItem]);

  return (
    <div>
      <FormControl>
        <Label>Model Type</Label>
        <StyledSelect
          className={`react-select-container`}
          classNamePrefix={`react-select`}
          name={`Model Id`}
          id={`modelType`}
          inputId={`modelType-input`}
          instanceId={`modelType-instance`}
          menuPortalTarget={document.body}
          placeholder={`Model Type`}
          label="Model Type"
          value={
            holidayTypeOptions.find((ht) => ht.value === currentItem?.type) ??
            null
          }
          options={holidayTypeOptions}
          onChange={(e) => {
            setCurrentItem((currentItem) => {
              let item = { ...currentItem };
              item.type = e.value;
              return item;
            });
          }}
        />
      </FormControl>
      {renderFields()}

      <Button
        danger
        list={true}
        type="button"
        onClick={() => {
          let currentHolidays = [...holidays];

          currentHolidays?.splice(isEditing?.index, 1);
          setFieldValue(`workingDayHolidays`, currentHolidays);
          collapse();
        }}
        title="Remove"
      >
        Remove
      </Button>

      <Button type="button" list={true} danger onClick={() => collapse()}>
        Cancel
      </Button>

      <Button type="button" onClick={() => CloseAndSave()}>
        Continue
      </Button>
    </div>
  );
};

const addHoliday = (setFieldValue, values) => {
  setFieldValue(`workingDayHolidays`, [
    { type: "CULTURE_DEFINED", name: "", cultureInfo: "en-US", enabled: true },
    ...values?.workingDayHolidays,
  ]);
};

const WorkingDayHolidays = () => {
  const [
    {
      loading: allWorkingDayLoading,
      errors: allWorkingDayErrors,
      data: allWorkingDayData,
    },
    getAllWorkingDays,
  ] = useApi(allWorkingDayHolidays);

  //updateWorkingDays
  const [{ errors: workingDaysErrors, data: updateWorkingDaysData }] = useApi();

  useEffect(() => {
    if (updateWorkingDaysData && !workingDaysErrors) {
      getAllWorkingDays({ query: allWorkingDays });
    }
  }, [updateWorkingDaysData, workingDaysErrors, getAllWorkingDays]);

  const [isEditing, setIsEditing] = useState(null);
  const { closeMenu } = useContext(NavContext);

  useEffect(() => {
    if (isEditing) {
      closeMenu();
    }
  }, [isEditing, closeMenu]);

  const columnsData = [
    {
      Header: "Name",
      id: "name",
      accessor: (d) => d?.name,
    },
    {
      Header: "Base Holiday Name",
      id: "baseHolidayName",
      accessor: (d) => d?.baseHolidayName,
    },
    {
      Header: "Count",
      id: "count",
      accessor: (d) => d?.count,
    },
    {
      Header: "Culture Info",
      id: "cultureInfo",
      accessor: (d) => d?.cultureInfo,
    },
    {
      Header: "Day",
      id: "day",
      accessor: (d) => d?.day,
    },
    {
      Header: "Day Of Week",
      id: "dayOfWeek",
      accessor: (d) => d?.dayOfWeek,
    },
    {
      Header: "Month",
      id: "month",
      accessor: (d) => d?.month,
    },
    {
      Header: "Direction",
      id: "direction",
      accessor: (d) => d?.direction,
    },
    {
      Header: "Type",
      id: "type",
      accessor: (d) => d?.type,
    },
    {
      Header: "Enabled",
      id: "enabled",
      accessor: (d) => d?.enabled,
      Cell: ({ row: { original } }) => {
        return <div>{original?.enabled ? "True" : "False"}</div>;
      },
    },
    {
      Header: " ",
      id: "actions",
      sortable: false,
      Cell: ({ row }) => {
        return (
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <TableButton type="button" list onClick={() => setIsEditing(row)}>
              Edit
            </TableButton>
          </div>
        );
      },
    },
  ];

  //Prime GraphAPI For Permissions Updates
  const [{ loading, errors, data }, doUpdateHolidays] = useApi();

  //callback
  const updateHolidays = (values) => {
    const variables = { workingDayHolidays: values?.workingDayHolidays ?? [] };
    doUpdateHolidays({ query: updateWorkingDayHolidays, variables });
  };

  useEffect(() => {
    if (data) {
      getAllWorkingDays({ query: allWorkingDayHolidays });
    }
  }, [data, getAllWorkingDays]);

  return (
    <div>
      <Formik
        enableReinitialize={true}
        initialValues={{
          workingDayHolidays:
            allWorkingDayData?.allWorkingDayHolidays?.edges.map(
              (h) => h.node
            ) ?? [],
        }}
        validateOnMount={true}
      >
        {({ values, setFieldValue }) => {
          return (
            <Form>
              {allWorkingDayErrors ? (
                <ErrorMessages errors={allWorkingDayErrors} />
              ) : null}
              {errors ? <ErrorMessages errors={errors} /> : null}

              {isEditing ? (
                <TransformEdit toggle={() => setIsEditing(null)}>
                  <HolidayForm
                    isEditing={isEditing}
                    collapse={() => setIsEditing(null)}
                    holidays={values.workingDayHolidays}
                    setFieldValue={setFieldValue}
                  />
                </TransformEdit>
              ) : null}

              <Button
                list
                type="button"
                onClick={() => addHoliday(setFieldValue, values)}
              >
                Add New Holiday
              </Button>

              <SortTable
                loading={allWorkingDayLoading}
                data={values?.workingDayHolidays ?? []}
                columns={columnsData}
              />

              <FormActions>
                <FormControl>
                  <Button
                    type="button"
                    disabled={loading}
                    onClick={() => updateHolidays(values)}
                  >
                    {loading ? <Spinner /> : "Save"}
                  </Button>
                </FormControl>
              </FormActions>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

const WorkingDays = () => {
  const [
    {
      loading: allWorkingDayHolidayLoading,
      errors: allWorkingDayHolidayErrors,
      data: allWorkingDayHolidayData,
    },
    getAllWorkingDayHolidays,
  ] = useApi(allWorkingDays);

  //updateWorkingDays
  const [
    { errors: workingDaysErrors, data: updateWorkingDaysData },
    doUpdateWorkingDays,
  ] = useApi();

  useEffect(() => {
    if (updateWorkingDaysData && !workingDaysErrors) {
      getAllWorkingDayHolidays({ query: allWorkingDays });
    }
  }, [updateWorkingDaysData, workingDaysErrors, getAllWorkingDayHolidays]);

  // Define the correct order of days for comparison
  const dayOrder = {
    SUNDAY: 0,
    MONDAY: 1,
    TUESDAY: 2,
    WEDNESDAY: 3,
    THURSDAY: 4,
    FRIDAY: 5,
    SATURDAY: 6,
  };

  const workingDaysOriginal =
    allWorkingDayHolidayData?.allWorkingDays?.edges ?? [];

  const workingDays =
    // Sort function to order the edges by dayOfWeek
    workingDaysOriginal?.sort((a, b) => {
      return dayOrder[a?.node?.dayOfWeek] - dayOrder[b?.node?.dayOfWeek];
    }) ?? [];

  const updateWorkDays = useCallback(
    (day) => {
      const workingDays = allWorkingDayHolidayData?.allWorkingDays?.edges ?? [];
      const currentDay = day?.node?.dayOfWeek;
      let dayUpdate = workingDays?.find(
        (wd) => wd?.node?.dayOfWeek === currentDay
      );

      const variables = {
        workingDay: {
          dayOfWeek: dayUpdate?.node?.dayOfWeek,
          enabled: !dayUpdate?.node?.enabled,
        },
      };
      doUpdateWorkingDays({ query: updateWorkingDays, variables });
    },
    [doUpdateWorkingDays, allWorkingDayHolidayData]
  );

  const columnsData = [
    {
      Header: "Day Of Week",
      id: "dayOfWeek",
      accessor: (d) => d?.node?.dayOfWeek,
    },
    {
      Header: "Enabled",
      id: "enabled",
      accessor: (d) => d?.node?.enabled,
      Cell: ({ row: { original } }) => {
        return (
          <div>
            <FormControl>
              <input
                type="checkbox"
                name={original?.node?.dayOfWeek}
                label="Primary Key"
                checked={original?.node?.enabled}
                onChange={() =>
                  updateWorkDays(original, original?.node?.enabled)
                }
              />
            </FormControl>
          </div>
        );
      },
    },
  ];

  return (
    <div>
      {allWorkingDayHolidayErrors ? (
        <ErrorMessages errors={allWorkingDayHolidayErrors} />
      ) : null}
      {workingDaysErrors ? <ErrorMessages errors={workingDaysErrors} /> : null}

      <SortTable
        loading={allWorkingDayHolidayLoading}
        data={workingDays}
        columns={columnsData}
      />
    </div>
  );
};

const ConnectionsPermissionsForm = () => {
  //Prime GraphAPI For Permissions Updates
  const [{ errors, data }] = useApi();

  //TODO: We already have the new form data, lets move the connection into state
  // and update from there instead of circling back, for now we hit the server to get valid data
  //   useEffect(() => {
  //     if (data) {
  //       getUpdatedConnection();
  //     }
  //   }, [data, getUpdatedConnection]);

  //callback
  //   const updatePermissions = (values) => {
  //     const variables = {
  //       id: connection.id,
  //       permissions: values.permissions,
  //     };

  //     doUpdatePermissions({ query: setConnectionPermissions, variables });
  //   };

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={
          {
            //   permissions: connection ? connection.permissions : [],
          }
        }
        validate={(values) => {
          let errors = {};

          return errors;
        }}
        validateOnMount={true}
        // onSubmit={(values) => updatePermissions(values)}
      >
        {({ isValid }) => {
          return (
            <Form>
              <Tabs>
                <TabLink to={`/admin/configuration/workingdays`}>
                  Working Days
                </TabLink>
                <TabLink to={`/admin/configuration/workingdayholidays`}>
                  Holidays
                </TabLink>
              </Tabs>
              <Switch>
                <Route
                  path={`/admin/configuration/workingdays`}
                  component={() => <WorkingDays />}
                />

                <Route
                  path={`/admin/configuration/workingdayholidays`}
                  component={() => <WorkingDayHolidays />}
                />
              </Switch>
              <FormActions>
                <FormControl>
                  {errors ? <ErrorMessages errors={errors} /> : null}
                  {/* TODO: Add Toasts */}
                  {data && <div>Updated!</div>}
                </FormControl>
              </FormActions>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default ConnectionsPermissionsForm;
