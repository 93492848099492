import React from "react";
import { Route, NavLink } from "react-router-dom";
import styled from "styled-components/macro";
import ConnectionsSettingsForm from "../../components/Forms/Connections/ConnectionsSettingsForm";
import ConnectionAlerts from "../../components/Forms/Connections/ConnectionsAlertsForm";
import ConnectionsPermissionsForm from "../../components/Forms/Connections/ConnectionsPermissionsForm";
import ConnectionSourcesContainer from "./ConnectionSourcesContainer";
import OCRSettingsForm from "../../components/Forms/Connections/OCRSettingsForm";

const Tabs = styled.div`
  border-bottom: 1px solid ${(props) => props.theme.surfaceAlt};
  background-color: ${(props) => props.theme.surface};
  position: relative;
  padding: 1.2rem;
  margin-bottom: 1rem;
`;

const TabLink = styled(NavLink)`
  padding: 1rem;
  border-bottom: 2px solid transparent;
  text-decoration: none;
  opacity: 0.5;
  color: ${(props) => props.theme.onSurface};
  &.active {
    opacity: 1;
    border-bottom: 2px solid ${(props) => props.theme.onSecondarySurface};
  }
  &:hover {
    opacity: 1;
  }
`;

const ConnectionTabs = ({ connectionId, data, getUpdatedConnection }) => {
  return (
    <>
      <Tabs>
        <TabLink
          data-testId="connectionTab-sources"
          exact
          to={`/connections/${connectionId}/sources`}
        >
          SOURCES
        </TabLink>
        {data?.ocrConnection && (
          <TabLink exact to={`/connections/${connectionId}/ocr`}>
            OCR
          </TabLink>
        )}
        <TabLink
          data-testId="connectionTab-settings"
          exact
          to={`/connections/${connectionId}/settings`}
        >
          SETTINGS
        </TabLink>
        <TabLink
          data-testId="connectionTab-alerts"
          exact
          to={`/connections/${connectionId}/alerts`}
        >
          ALERTS
        </TabLink>
        <TabLink exact to={`/connections/${connectionId}/permissions`}>
          PERMISSIONS
        </TabLink>
      </Tabs>

      <Route
        path={`/connections/:connectionId/sources`}
        component={() => (
          <ConnectionSourcesContainer
            data={data}
            getUpdatedConnection={getUpdatedConnection}
          />
        )}
      />

      <Route
        path={`/connections/:connectionId/ocr`}
        component={() => (
          <OCRSettingsForm
            connectionId={connectionId}
            connection={data}
            getUpdatedConnection={getUpdatedConnection}
          />
        )}
      />

      <Route
        path={`/connections/:connectionId/settings`}
        component={() => (
          <ConnectionsSettingsForm
            connectionId={connectionId}
            connection={data}
            getUpdatedConnection={getUpdatedConnection}
          />
        )}
      />


      <Route
        path={`/connections/:connectionId/alerts`}
        component={() => (
          <ConnectionAlerts
            sourceId={connectionId}
          />
        )}
      />

      <Route
        path={`/connections/:connectionId/permissions`}
        component={() => (
          <ConnectionsPermissionsForm
            connectionId={connectionId}
            connection={data}
            getUpdatedConnection={getUpdatedConnection}
          />
        )}
      />
    </>
  );
};

export default ConnectionTabs;
