export const platformAlerts = /* GraphQL */ `
  query {
    platformAlerts {
      totalCount
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      edges {
        cursor
        node {
          id
          enabled
          instances {
            id
            alertId
            notificationType
            enabled
            members {
              id
              alertInstanceId
              userId
              user {
                displayName
                emailAddress
                id
                profilePhoto
              }
              workGroupId
              group {
                displayName
                id
              }
            }
          }
          threshold
          boolFlag
          alertType
        }
      }
    }
  }
`;
