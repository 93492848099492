import styled from "styled-components/macro";

export const TableElement = styled.table`
  width: 100%;
  overflow-x: auto;
  max-width: 100%;
  margin: 20px auto;
  table-layout: auto;
  font-size: 0.8rem;
  line-height: 1.5;
  display: table;
  border-collapse: collapse;
`;

export const Td = styled.div`
  padding: 0.5rem;
  min-width: 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: left;
  overflow: hidden;
  min-width: ${(props) => (props.left ? "max(180px, 40px)" : "inherit")};
  z-index: ${(props) => (props.left || props.right ? 50 : "inherit")};
  position: ${(props) => (props.left || props.right ? "sticky" : "inherit")};
  left: ${(props) => (props.left ? props.left : "auto")};
  right: ${(props) => (props.right ? props.right : "auto")};
  box-shadow: ${(props) =>
    props.left
      ? props.isLast
        ? "rgb(0 0 0 / 10%) -4px 0px 8px -6px inset"
        : "none"
      : props.right
      ? "rgb(0 0 0 / 10%) 4px 0px 8px 6px"
      : "none"};
  clip-path: ${(props) => (props.right ? "inset(1px 0px 0px -350px)" : "none")};
  background-color: #fff;
      : "inherit"};
  & > div {
    min-width: 0;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-align: left;
    overflow: hidden;
  }
`;

export const Th = styled.div`
  align-items: center;
  padding: 0.5rem;
  min-height: 38px;
  font-family: "Source Sans Pro Semibold";
  & > div > div {
    min-width: 0;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-align: left;
    overflow: hidden;
  }

  &:last-child {
    border-right: none;
  }
`;

export const Tr = styled.div`
  align-items: center;
  border-collapse: collapse;
`;

export const TBody = styled.div`
  /* position: relative;
  display: block;
  height: ${(props) =>
    props.tableBodyHeight ? props.tableBodyHeight + `rem` : "auto"};
  overflow: scroll; */
`;

export const Thead = styled.div`
  /* border-bottom: 1px solid #d0d0d0;
  align-items: center;
  border-collapse: collapse; */
  background: #f8f8f8;
  padding: 0.5rem;
`;

export const PageButton = styled.button`
  border: 0;
  background: 0;
  font-family: "Source Sans Pro Semibold";
  font-size: 1.1rem;
  cursor: pointer;
  padding: 0.5rem;
  padding-bottom: 0.2rem;
  &:hover {
    background: #f2f2f2;
  }
`;

export const ActionsIcon = styled.button`
  cursor: pointer;
  font-size: 1.3rem;
  border: 0;
  background: 0;
  padding: 0;
`;

export const TableLoading = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 3;
  display: flex;
  background: rgba(255, 255, 255, 0.7);
`;

export const Resizer = styled.div`
  right: 0;
  width: 10px;
  height: 100%;
  position: absolute;
  top: 0;
  z-index: 1;
  touch-action: none;

  &.isResizing {
  }
`;
