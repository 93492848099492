import React from 'react';
import styled from 'styled-components/macro';
import bcalogo from '../../assets/images/BasecapIconWhite.png';

const UserImage = styled.div`
  border-radius: 50%;
  height: ${props => (props.isSmall ? '40px' : '48px')};
  width: ${props => (props.isSmall ? '40px' : '48px')};
  border: 2px solid ${props => props.theme.menuText};
  display: block;
  background-image: url(${props =>
    props.imageURL ? 'data:image/png;base64,' + props.imageURL : bcalogo});
  background-position: center;
  background-repeat: no-repeat;
  background-size: ${props => (props.isSmall ? '40px' : '48px')};
`;

const UserProfileImage = React.memo(function UserProfileImage({
  imageURL,
  small
}) {
  const profileImage = imageURL ? imageURL : null;
  if (!profileImage) return null;
  return <UserImage imageURL={profileImage} isSmall={small} />;
});

export default UserProfileImage;
