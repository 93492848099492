import React, { useContext } from "react";
import { withRouter } from "react-router-dom";
import Button from "../components/Button";
import styled from "styled-components/macro";
import { AuthContext } from "../contexts/AuthContext";

const LoginError = styled.div`
  margin-top: 20px;
`;

const Login = () => {
  const { user, logout, login, loginError } = useContext(AuthContext);
  return (
    <div>
      {user ? (
        <Button display="block" type="button" onClick={logout}>
          Logout
        </Button>
      ) : (
        <Button display="block" type="button" onClick={login}>
          {"LOGIN"}
        </Button>
      )}
      <LoginError>{loginError ? loginError : null}</LoginError>
    </div>
  );
};

export default withRouter(Login);
