import { useContext } from "react";
import { SocketContext } from "../contexts/useSubscriptions";

const UseExportNotifications = () => {
  const { exportNotification, setExportNotification } =
    useContext(SocketContext);

  return { exportNotification, setExportNotification };
};
export default UseExportNotifications;
