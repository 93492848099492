import React, { useState, useEffect, useContext, useCallback } from "react";
import { useApi } from "../../api/useApiRedux";
import ErrorMessages from "../../components/Notifications/ErrorMessages";
import PagedTableRedux from "../../components/Table/PagedTableRedux";
import {
  MdNotifications,
  MdExpandMore,
  MdExpandLess,
  MdFilterList,
  MdDoneAll,
} from "react-icons/md";
import RenderPriority from "../../common/RenderPriority";
import { formatTime } from "../../common/formats";
import styled from "styled-components/macro";
import {
  StyledSelect,
  Label,
  FormControl,
} from "../../components/Form/FormControls";
import TableRowView from "../../components/TableRowView/TableRowView";
import IssueRowDetailsRule from "./IssueRowDetailsRule";
import IssueRowDetailsOcr from "./IssueRowDetailsOcr";
import { ToastContainer, toast } from "react-toastify";
import { AuthContext } from "../../contexts/AuthContext";
import { sortByAlpha } from "../../common/helpers/util";
import { useApi as useApiRedux } from "../../api/useApiRedux";
import debounce from "lodash.debounce";
import SearchIssue from "./SearchIssue";
import TableButtonSecondary from "../../components/Button/TableButtonSecondary";

const ViewByOptions = [
  { label: "All", value: "All" },
  { label: "Assigned to Me", value: "AssignedToMe" },
  { label: "Assigned", value: "Assigned" },
  { label: "Unassigned", value: "Unassigned" },
  { label: "Active", value: "Active" },
  { label: "Resolved", value: "Resolved" },
  { label: "Closed", value: "Closed" },
  { label: "Snoozed", value: "Snoozed" },
  { label: "PinnedNote", value: "PinnedNote" },
];

const ResolvedIcon = styled.div`
  display: flex;
  align-items: center;
  color: rgb(1, 152, 255);
  margin-right: 0.1rem;
  margin-left: 0.5rem;
`;

const DataSourceName = styled.div`
  font-size: 0.6875rem;
  text-transform: uppercase;
  letter-spacing: 0.4px;
  color: #6d727c;
`;

const TableSortHeader = styled.div`
  display: flex;
  align-items: center;
  margin-top: 1rem;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid #ccc;
  align-items: center;
  @media (max-width: 1100px) {
    flex-direction: column;
    justify-content: left;
  }
`;

const TableSortControl = styled.div`
  align-items: center;
  @media (max-width: 1100px) {
    margin-left: inherit;
  }
`;

const IssueNameViewLink = styled.div`
  text-decoration: none;
  transition: all ${(props) => props.theme.transitionSpeed} linear;
  font-family: "Source Sans Pro Semibold";
  color: ${(props) => props.theme.linkPrimary};
  cursor: pointer;
  word-break: break-word;
  &:hover {
    text-decoration: underline;
    color: ${(props) => props.theme.linkPrimary};
  }
  &:active {
    text-decoration: underline;
    color: ${(props) => props.theme.linkSecondary};
  }
`;

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-gap: 1rem;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
`;

const TableDataHighlight = styled.div`
  background: #ebf3fb;
  padding: 1rem;
  position: relative;
  box-shadow: ${(props) => (props.active ? "0 0 0 2px #6674a3 inset" : "")};
`;

const DataHighlightFilter = styled.div`
  position: absolute;
  right: 0;
  bottom: 4px;
  margin: 0.5rem;
  margin-bottom: 0.2rem;
  font-size: 1.2rem;
  cursor: pointer;
  color: #273b7b;
  height: 22px;
  padding: 0.1rem;
  padding-bottom: 0;
  border-radius: 4px;
  background: ${(props) => (props.active ? "#d6e6f7" : "")};
  &:hover {
    background: #d6e6f7;
  }
`;

const DataHighlightBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const DataHighlightHeader = styled.div`
  font-family: "Source Sans Pro Semibold";
  font-size: 1rem;
`;

const DataHighlightValue = styled.div`
  font-size: 4rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  color: #273b7b;
`;

// const DataHighlightDesc = styled.div`
//   font-size: 1.2rem;
// `;

const DataHighlightUsers = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  padding-left: 2rem;
  padding-right: 2rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
`;

const DataHighlightUser = styled.div`
  display: flex;
  margin-bottom: 0.5rem;
`;

const DataHighlightUsername = styled.div`
  flex: 1;
`;

// Issues Tab on Datasource - Click - Filter issues by data source id active/unassigned

const UserSearch = ({ setSearchedUser, selectedUser }) => {
  const [stateUsers, setStateUsers] = useState();

  const [
    { errors: getUserErrors, loading: loadingUsers, data: usersData },
    getUsers,
  ] = useApiRedux();

  const [inputText, setInputText] = React.useState("");
  const [searchText, setSearchText] = React.useState("");

  useEffect(() => {
    if (usersData) {
      const userOptions = usersData?.data?.map((ug) => {
        return {
          label: ug?.emailAddress,
          value: ug?.id,
          type: "user",
        };
      });

      setStateUsers(userOptions);
    } else {
      setStateUsers([]);
    }
  }, [usersData]);

  useEffect(() => {
    if (searchText) {
      getUsers({
        query: `/search/users?query=${searchText}`,
        method: "GET",
      });
    } else {
      setStateUsers([]);
    }
  }, [searchText, getUsers, setStateUsers]);

  const setSearchTextDebounced = React.useRef(
    debounce((searchText) => setSearchText(searchText), 500)
  ).current;

  const handleInputChangePrimary = (inputText, event) => {
    // prevent outside click from resetting inputText to ""
    if (event.action !== "input-blur" && event.action !== "menu-close") {
      setInputText(inputText);
      setSearchTextDebounced(inputText);
    }
  };

  return (
    <div style={{ marginTop: "1rem", marginLeft: "1rem" }}>
      <FormControl style={{ minWidth: "300px" }}>
        <Label>Search By Owners</Label>
        <StyledSelect
          className={`react-select-container`}
          classNamePrefix={`react-select`}
          name={`owners`}
          id={`owners`}
          inputId={`owners-input`}
          instanceId={`owners-instance`}
          label="Owner"
          options={stateUsers}
          placeholder={`Search Owner`}
          value={selectedUser}
          menuPlacement="auto"
          inputValue={inputText}
          isLoading={loadingUsers}
          onChange={(e) => setSearchedUser(e)}
          onInputChange={handleInputChangePrimary}
          menuPortalTarget={document.body}
        />
        {getUserErrors ? <ErrorMessages errors={getUserErrors} /> : null}
      </FormControl>
    </div>
  );
};

const IssuesList = ({ filterSource }) => {
  const [selectedUser, setSelectedUser] = useState();
  const [stats, setStats] = useState(null);
  const initFilters = filterSource
    ? {
        page: 1,
        size: 10,
        filters: [
          ...(filterSource ? [{ DataSourceId: filterSource }] : []),
          { Status: "Active" },
          { Notify: true },
        ],
        Sorting: [{ Name: "asc" }],
      }
    : {
        page: 1,
        size: 10,
        filters: [{ Status: "Active" }, { Notify: true }],
        Sorting: [{ Name: "asc" }],
      };
  const [viewState, setViewState] = useState(initFilters);

  const [showRow, setShowRow] = useState(null);
  const [selectedFilter, setSelectedFilter] = useState({
    label: "Active",
    value: "Active",
  });

  const [tableState, setTableState] = useState([]);
  const [toggledMute, setToggledMute] = useState(null);

  const [searchedUser, setSearchedUser] = useState();

  const { user } = useContext(AuthContext);

  const selectTopFilter = useCallback(
    (e) => {
      switch (e.value) {
        default:
          setSelectedFilter(e);
          break;
        // SetLoad
        case "All": {
          const filterByAll = {
            page: 1,
            size: 10,
            filters: [
              ...(filterSource ? [{ DataSourceId: filterSource }] : []),
            ],
            Sorting: [{ Notify: "desc" }, { Name: "asc" }],
          };
          setViewState(filterByAll);
          setSelectedFilter(e);
          setSelectedUser(null);
          break;
        }
        case "Active": {
          const filterByActive = {
            page: 1,
            size: 10,
            filters: [
              ...(filterSource ? [{ DataSourceId: filterSource }] : []),
              { Status: "Active" },
              { Notify: true },
            ],
            Sorting: [{ Name: "asc" }],
          };

          setViewState(filterByActive);
          setSelectedFilter(e);
          setSelectedUser(null);
          break;
        }
        case "Resolved": {
          const filterByResolved = {
            page: 1,
            size: 10,
            filters: [
              ...(filterSource ? [{ DataSourceId: filterSource }] : []),
              { Status: "Resolved" },
            ],
            Sorting: [{ Name: "asc" }],
          };

          setViewState(filterByResolved);
          setSelectedFilter(e);
          setSelectedUser(null);
          break;
        }
        case "PinnedNote": {
          const filterByPinned = {
            page: 1,
            size: 10,
            filters: [
              ...(filterSource ? [{ DataSourceId: filterSource }] : []),
              { PinnedNote: true },
            ],
            Sorting: [{ Name: "asc" }],
          };

          setViewState(filterByPinned);
          setSelectedFilter(e);
          setSelectedUser(null);
          break;
        }
        case "AssignedToMe": {
          const filterByAssigned = {
            page: 1,
            size: 10,
            filters: [
              ...(filterSource ? [{ DataSourceId: filterSource }] : []),
              { UserId: user?.id },
            ],
            Sorting: [{ Name: "asc" }],
          };

          setViewState(filterByAssigned);
          setSelectedFilter(e);
          setSelectedUser(null);
          break;
        }
        case "Unassigned": {
          const filterByUnassigned = {
            page: 1,
            size: 10,
            filters: [
              ...(filterSource ? [{ DataSourceId: filterSource }] : []),
              { UserId: 0 },
            ],
            Sorting: [{ Name: "asc" }],
          };

          setViewState(filterByUnassigned);
          setSelectedFilter(e);
          setSelectedUser(null);
          break;
        }
        case "Assigned": {
          const filterByAssigned = {
            page: 1,
            size: 10,
            filters: [
              ...(filterSource ? [{ DataSourceId: filterSource }] : []),
              { UserId: -1 },
            ],
            Sorting: [{ Name: "asc" }],
          };

          setViewState(filterByAssigned);
          setSelectedFilter(e);
          setSelectedUser(null);
          break;
        }
        case "Closed": {
          const filterByClosed = {
            page: 1,
            size: 10,
            filters: [
              ...(filterSource ? [{ DataSourceId: filterSource }] : []),
              { Status: "Closed" },
            ],
            Sorting: [{ Name: "asc" }],
          };

          setViewState(filterByClosed);
          setSelectedFilter(e);
          setSelectedUser(null);
          break;
        }
        case "Snoozed": {
          //if its closed and muted we shouldn't show
          const filterByMuted = {
            page: 1,
            size: 10,
            filters: [
              ...(filterSource ? [{ DataSourceId: filterSource }] : []),
              { Notify: false },
              { Status: "Active" },
            ],
            Sorting: [{ Name: "asc" }],
          };

          setViewState(filterByMuted);
          setSelectedFilter(e);
          setSelectedUser(null);
          break;
        }
        case "ByUser": {
          const filterByAssigned = {
            page: 1,
            size: 10,
            filters: [
              ...(filterSource ? [{ DataSourceId: filterSource }] : []),
              { UserId: e?.id },
            ],
            Sorting: [{ Name: "asc" }],
          };

          setViewState(filterByAssigned);
          setSelectedUser(e?.user);
          break;
        }
        case "ById": {
          const filterById = {
            page: 1,
            size: 10,
            filters: [
              ...(filterSource ? [{ DataSourceId: filterSource }] : []),
              { Id: e?.id },
            ],
          };

          setViewState(filterById);
          setSelectedFilter(null);
          break;
        }
      }
    },
    [filterSource, user]
  );

  // Init Data Fetch
  const [{ errors, loading, data: apiData }, doFetch] = useApi();

  const [
    { errors: muteErrors, loading: muteLoading, data: apiMute },
    doUpdateMute,
  ] = useApi();

  const updateIssueRow = useCallback((issue, prevState) => {
    let cloneTableState = { ...prevState, data: [...prevState?.data] };

    const foundIndex = cloneTableState?.data?.findIndex(
      (item) => item?.id === issue?.id
    );

    //Build Assignees in table thats cloned
    if (foundIndex !== -1) {
      cloneTableState.data[foundIndex] = issue;
      cloneTableState.data[foundIndex].assignees = issue.assignees.map(
        (assignee) => {
          return { label: assignee?.emailAddress, value: assignee?.userId };
        }
      );
    }

    toast.success("Issue Updated", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

    return cloneTableState;
  }, []);

  //HANDLE MUTE TOGGLE
  useEffect(() => {
    if (apiMute && !muteErrors?.length) {
      setTableState((prevState) => {
        return updateIssueRow(apiMute, prevState);
      });
      setToggledMute(null);
    }
  }, [apiMute, setTableState, muteErrors, updateIssueRow]);

  const toggleMute = ({ original }) => {
    const cleanAssigned = original?.assignees?.length
      ? original?.assignees.map((user) => ({
          id: user?.id ?? 0,
          userId: user?.value,
        }))
      : [];

    const body = {
      ...original,
      notify: !original?.notify,
      assignees: cleanAssigned,
    };
    setToggledMute(body);
    doUpdateMute({
      query: `/Issues/${original?.id}`,
      method: "POST",
      body: body,
    });
  };

  function buildAssignees(assignees) {
    const newMappedUsers = assignees.map((user) => {
      return { label: user?.emailAddress, value: user?.userId };
    });
    const sortedMappedUsers = sortByAlpha(newMappedUsers, "label");

    return sortedMappedUsers;
  }

  // Handle init load of table data and store it to state
  useEffect(() => {
    if (apiData && !errors?.length) {
      const cleanData = apiData?.data.map((cd) => ({
        ...cd,
        assignees: buildAssignees(cd?.assignees),
      }));

      const setInitialData = {
        data: cleanData,
        metadata: apiData?.metadata,
      };
      setStats(apiData?.statistics);
      setTableState(setInitialData);
    }
  }, [setTableState, apiData, errors]);

  const handleFormSuccessStateSwap = (dataObj) => {
    setTableState((prevState) => {
      return updateIssueRow(dataObj, prevState);
    });
  };

  const issues = tableState?.data ?? [];
  const totalCount = tableState?.metadata?.availableItems;

  //Fetch for Table Paged
  const fetchData = React.useCallback(
    ({ params }) => {
      doFetch({
        query: `/Issues`,
        params: params,
        method: "GET",
      });
    },
    [doFetch]
  );

  useEffect(() => {
    if (searchedUser) {
      selectTopFilter({
        value: "ByUser",
        id: searchedUser?.value,
        user: searchedUser,
      });
    }
  }, [searchedUser, selectTopFilter]);

  const columnsData = [
    {
      Header: () => {
        const isSortedAndAsc = viewState?.Sorting?.find(
          (s) => s["Name"] === "asc"
        );
        const isSortedAndDesc = viewState?.Sorting?.find(
          (s) => s["Name"] === "desc"
        );

        return (
          <div
            style={{ cursor: "pointer" }}
            onClick={() =>
              setViewState((prev) => {
                let newName;
                if (prev?.Sorting?.find((s) => s["Name"] === "desc")) {
                  newName = {
                    ...prev,
                    Sorting: [{ Name: "asc" }],
                  };
                } else {
                  newName = {
                    ...prev,
                    Sorting: [{ Name: "desc" }],
                  };
                }

                return newName;
              })
            }
          >
            Name {isSortedAndAsc && <MdExpandMore />}
            {isSortedAndDesc && <MdExpandLess />}
          </div>
        );
      },
      id: "name",
      accessor: (d) => d?.name,
      Cell: ({ row: { original } }) => {
        const issueDetails = JSON.parse(original?.issueDetails);
        const showLink = original?.type === "RuleInstanceVersion";
        return (
          <>
            {showLink ? (
              <IssueNameViewLink
                data-testid="datasourcelink"
                onClick={() => setShowRow(original)}
              >
                {original?.name}
              </IssueNameViewLink>
            ) : (
              <div> {original?.name}</div>
            )}

            <DataSourceName>{issueDetails?.DataSourceName}</DataSourceName>
          </>
        );
      },
    },
    {
      Header: () => {
        const isSortedAndAsc = viewState?.Sorting?.find(
          (s) => s["Priority"] === "asc"
        );
        const isSortedAndDesc = viewState?.Sorting?.find(
          (s) => s["Priority"] === "desc"
        );

        return (
          <div
            style={{ cursor: "pointer" }}
            onClick={() =>
              setViewState((prev) => {
                let newPriority;
                if (prev?.Sorting?.find((s) => s["Priority"] === "asc")) {
                  newPriority = {
                    ...prev,
                    Sorting: [{ Priority: "desc" }],
                  };
                } else {
                  newPriority = {
                    ...prev,
                    Sorting: [{ Priority: "asc" }],
                  };
                }

                return newPriority;
              })
            }
          >
            Priority {isSortedAndAsc && <MdExpandMore />}
            {isSortedAndDesc && <MdExpandLess />}
          </div>
        );
      },
      id: "priority",
      width: 150,
      accessor: (d) => d?.priority,
      Cell: ({ row: { original } }) => {
        return (
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <RenderPriority priorityLevel={original?.priority} bump={false} />
          </div>
        );
      },
    },
    {
      Header: () => {
        const isSortedAndAsc = viewState?.Sorting?.find(
          (s) => s["DateOfCheck"] === "asc"
        );
        const isSortedAndDesc = viewState?.Sorting?.find(
          (s) => s["DateOfCheck"] === "desc"
        );

        return (
          <div
            style={{ cursor: "pointer" }}
            onClick={() =>
              setViewState((prev) => {
                let newDateOfCheck;
                if (prev?.Sorting?.find((s) => s["DateOfCheck"] === "desc")) {
                  newDateOfCheck = {
                    ...prev,
                    Sorting: [{ DateOfCheck: "asc" }],
                  };
                } else {
                  newDateOfCheck = {
                    ...prev,
                    Sorting: [{ DateOfCheck: "desc" }],
                  };
                }

                return newDateOfCheck;
              })
            }
          >
            Date of Check {isSortedAndAsc && <MdExpandLess />}
            {isSortedAndDesc && <MdExpandMore />}
          </div>
        );
      },
      id: "date",
      width: 150,
      accessor: (d) => d?.dateOfCheck,
      Cell: ({ row: { original } }) => {
        return original?.dateOfCheck
          ? formatTime(new Date(original?.dateOfCheck))
          : null;
      },
    },
    {
      Header: () => {
        const isSortedAndAsc = viewState?.Sorting?.find(
          (s) => s["FailureCount"] === "asc"
        );
        const isSortedAndDesc = viewState?.Sorting?.find(
          (s) => s["FailureCount"] === "desc"
        );

        return (
          <div
            style={{ cursor: "pointer" }}
            onClick={() =>
              setViewState((prev) => {
                let newFailureCount;
                if (prev?.Sorting?.find((s) => s["FailureCount"] === "desc")) {
                  newFailureCount = {
                    ...prev,
                    Sorting: [{ FailureCount: "asc" }],
                  };
                } else {
                  newFailureCount = {
                    ...prev,
                    Sorting: [{ FailureCount: "desc" }],
                  };
                }

                return newFailureCount;
              })
            }
          >
            Failures {isSortedAndAsc && <MdExpandLess />}
            {isSortedAndDesc && <MdExpandMore />}
          </div>
        );
      },
      id: "alertTime",
      width: 100,
      accessor: (d) => d?.failureCount,
    },
    {
      Header: () => {
        const isSortedAndAsc = viewState?.Sorting?.find(
          (s) => s["ReoccurrenceCount"] === "asc"
        );
        const isSortedAndDesc = viewState?.Sorting?.find(
          (s) => s["ReoccurrenceCount"] === "desc"
        );

        return (
          <div
            style={{ cursor: "pointer" }}
            onClick={() =>
              setViewState((prev) => {
                let newReoccurrenceCount;
                if (
                  prev?.Sorting?.find((s) => s["ReoccurrenceCount"] === "desc")
                ) {
                  newReoccurrenceCount = {
                    ...prev,
                    Sorting: [{ ReoccurrenceCount: "asc" }],
                  };
                } else {
                  newReoccurrenceCount = {
                    ...prev,
                    Sorting: [{ ReoccurrenceCount: "desc" }],
                  };
                }

                return newReoccurrenceCount;
              })
            }
          >
            Recurrence {isSortedAndAsc && <MdExpandLess />}
            {isSortedAndDesc && <MdExpandMore />}
          </div>
        );
      },
      id: "recurrence",
      accessor: (d) => d?.reoccurrenceCount,
      width: 100,
      Cell: ({ row: { original } }) => {
        if (
          original?.reoccurrenceCount === 1 &&
          original?.status !== "Closed"
        ) {
          return (
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  color: "#0198FF",
                  marginRight: ".1rem",
                }}
              >
                <MdNotifications />
              </div>
              <div>New</div>
              {original?.pinnedNote ? (
                <ResolvedIcon title="Has Resolution">
                  <MdDoneAll />
                </ResolvedIcon>
              ) : null}
            </div>
          );
        } else {
          return (
            <div>
              {original?.reoccurrenceCount}
              {original?.pinnedNote ? (
                <ResolvedIcon title="Has Resolution">
                  <MdDoneAll />
                </ResolvedIcon>
              ) : null}
            </div>
          );
        }
      },
    },
    {
      Header: () => {
        const isSortedAndAsc = viewState?.Sorting?.find(
          (s) => s["Status"] === "asc"
        );
        const isSortedAndDesc = viewState?.Sorting?.find(
          (s) => s["Status"] === "desc"
        );

        return (
          <div
            style={{ cursor: "pointer" }}
            onClick={() =>
              setViewState((prev) => {
                let newStatus;
                if (prev?.Sorting?.find((s) => s["Status"] === "desc")) {
                  newStatus = {
                    ...prev,
                    Sorting: [{ Status: "asc" }],
                  };
                } else {
                  newStatus = {
                    ...prev,
                    Sorting: [{ Status: "desc" }],
                  };
                }

                return newStatus;
              })
            }
          >
            Status {isSortedAndAsc && <MdExpandLess />}
            {isSortedAndDesc && <MdExpandMore />}
          </div>
        );
      },
      id: "status",
      width: 100,
      accessor: (d) => d?.status,
    },
    {
      Header: "Owner(s)",
      id: "owners",
      width: 250,
      accessor: (d) => d?.owners,
      Cell: ({ row: { original } }) => {
        return (
          <>
            {original?.assignees.map((uf, i) => {
              return (
                <div key={i + `${uf.label}`} style={{ fontSize: ".8125rem" }}>
                  {uf.label}
                </div>
              );
            })}
          </>
        );
      },
    },
    {
      Header: "",
      id: "actions",
      Cell: ({ row: { original } }) => {
        const isMuting = toggledMute?.id === original?.id && muteLoading;
        return (
          <div style={{ display: "flex", justifyContent: "right" }}>
            {original?.status === "Closed" ? null : (
              <TableButtonSecondary
                type="button"
                list={"true"}
                onClick={() => toggleMute({ original })}
                disabled={isMuting}
              >
                {original?.notify ? "Snooze" : "Un-snooze"}
              </TableButtonSecondary>
            )}

            <TableButtonSecondary
              type="button"
              list={"true"}
              onClick={() => setShowRow(original)}
            >
              Edit
            </TableButtonSecondary>
          </div>
        );
      },
    },
  ];

  function getDetails(showRow) {
    switch (showRow?.type) {
      case "RuleInstanceVersion":
        return IssueRowDetailsRule;
      case "OcrScanError":
        return IssueRowDetailsOcr;
      default:
        return IssueRowDetailsRule;
    }
  }

  const IssueComponent = getDetails(showRow);

  //create array from user stats keys

  const usersWithMostActiveIssues = stats?.usersWithMostActiveIssues
    ? Object.keys(stats?.usersWithMostActiveIssues).map((key) => {
        return { user: key, count: stats?.usersWithMostActiveIssues[key] };
      })
    : null;

  const usersWithMostResolvedOrClosedIssues =
    stats?.usersWithMostResolvedOrClosedIssues
      ? Object.keys(stats?.usersWithMostResolvedOrClosedIssues).map((key) => {
          return {
            user: key,
            count: stats?.usersWithMostResolvedOrClosedIssues[key],
          };
        })
      : null;

  return (
    <>
      {showRow ? (
        <TableRowView toggle={setShowRow}>
          <IssueComponent
            toggle={setShowRow}
            row={showRow}
            setRowAfterForm={handleFormSuccessStateSwap}
          />
        </TableRowView>
      ) : null}
      {stats && !filterSource ? (
        <>
          <GridContainer>
            <TableDataHighlight>
              <DataHighlightBody>
                <DataHighlightHeader>Total Issues</DataHighlightHeader>
                <DataHighlightValue>
                  {stats?.totalIssueCount}
                </DataHighlightValue>
                {/* <DataHighlightDesc>Issues Found</DataHighlightDesc> */}
              </DataHighlightBody>
              {/* <DataHighlightFilter>
            <MdFilterList />
          </DataHighlightFilter> */}
            </TableDataHighlight>

            <TableDataHighlight
              active={selectedFilter?.value === "AssignedToMe"}
            >
              <DataHighlightBody>
                <DataHighlightHeader>Assigned To Me</DataHighlightHeader>
                <DataHighlightValue>
                  {stats?.assignedToCurrentUser}
                </DataHighlightValue>
                {/* <DataHighlightDesc>Assigned Issues</DataHighlightDesc> */}
              </DataHighlightBody>
              <DataHighlightFilter
                title="Filter by Assigned to Me"
                onClick={() =>
                  selectTopFilter({
                    label: "Assigned to Me",
                    value: "AssignedToMe",
                  })
                }
                active={selectedFilter?.value === "AssignedToMe"}
              >
                <MdFilterList />
              </DataHighlightFilter>
            </TableDataHighlight>

            <TableDataHighlight active={selectedFilter?.value === "PinnedNote"}>
              <DataHighlightBody>
                <DataHighlightHeader>
                  Issues with resolution Steps
                </DataHighlightHeader>
                <DataHighlightValue>
                  {stats?.withResolutionSteps}
                </DataHighlightValue>
                {/* <DataHighlightDesc>BorrowerStatusCheck</DataHighlightDesc> */}
              </DataHighlightBody>
              <DataHighlightFilter
                title="Filter by Has Resolution"
                onClick={() =>
                  selectTopFilter({ label: "PinnedNote", value: "PinnedNote" })
                }
                active={selectedFilter?.value === "PinnedNote"}
              >
                <MdFilterList />
              </DataHighlightFilter>
            </TableDataHighlight>

            <TableDataHighlight active={selectedFilter?.value === "Resolved"}>
              <DataHighlightBody>
                <DataHighlightHeader>Top Resolvers</DataHighlightHeader>
                <DataHighlightUsers>
                  {usersWithMostResolvedOrClosedIssues?.map((uai, i) => {
                    return (
                      <DataHighlightUser key={`top${uai?.user}`}>
                        <DataHighlightUsername>
                          {uai?.user}
                        </DataHighlightUsername>
                        <div>{uai?.count}</div>
                      </DataHighlightUser>
                    );
                  })}
                  {usersWithMostResolvedOrClosedIssues?.length ? null : (
                    <div style={{ textAlign: "center" }}>
                      No Resolved Issues
                    </div>
                  )}
                </DataHighlightUsers>
                {/* <DataHighlightDesc>Users with most Resolved Issues</DataHighlightDesc> */}
              </DataHighlightBody>
              <DataHighlightFilter
                title="Filter by Resolved"
                onClick={() =>
                  selectTopFilter({ label: "Resolved", value: "Resolved" })
                }
                active={selectedFilter?.value === "Resolved"}
              >
                <MdFilterList />
              </DataHighlightFilter>
            </TableDataHighlight>

            <TableDataHighlight active={selectedFilter?.value === "Active"}>
              <DataHighlightBody>
                <DataHighlightHeader>
                  Users with most active Issues
                </DataHighlightHeader>
                <DataHighlightUsers>
                  {usersWithMostActiveIssues?.map((uai) => {
                    return (
                      <DataHighlightUser key={`active${uai?.user}`}>
                        <DataHighlightUsername>
                          {uai?.user}
                        </DataHighlightUsername>
                        <div>{uai?.count}</div>
                      </DataHighlightUser>
                    );
                  })}

                  {usersWithMostActiveIssues?.length ? null : (
                    <div style={{ textAlign: "center" }}>
                      No Assigned, Active Issues
                    </div>
                  )}
                </DataHighlightUsers>
              </DataHighlightBody>
              <DataHighlightFilter
                title="Filter by Active"
                onClick={() =>
                  selectTopFilter({ label: "Active", value: "Active" })
                }
                active={selectedFilter?.value === "Active"}
              >
                <MdFilterList />
              </DataHighlightFilter>
            </TableDataHighlight>
          </GridContainer>
        </>
      ) : null}

      <TableSortHeader>
        <SearchIssue
          selectTopFilter={selectTopFilter}
          filterSource={filterSource}
        />

        <UserSearch
          setSearchedUser={setSearchedUser}
          selectedUser={selectedUser}
        />

        <TableSortControl>
          <div
            style={{
              marginRight: "1rem",
              marginLeft: "1rem",
              display: "inline-block",
            }}
          >
            <Label>View by</Label>
            <StyledSelect
              className={`react-select-container`}
              classNamePrefix={`react-select`}
              name={`sortTable`}
              id={`viewBy`}
              inputId={`viewBy-input`}
              instanceId={`viewBy-instance`}
              options={ViewByOptions}
              menuPortalTarget={document.body}
              value={selectedFilter}
              menuPlacement="auto"
              onChange={(e) => selectTopFilter(e)}
            />
          </div>
        </TableSortControl>
      </TableSortHeader>

      <PagedTableRedux
        key={"viewState" + JSON.stringify(viewState)}
        fetchData={fetchData}
        totalCount={totalCount}
        loading={loading}
        data={issues}
        columns={columnsData}
        noMargin={true}
        initParams={viewState}
      />

      {errors && <ErrorMessages errors={errors} />}
      {muteErrors && <ErrorMessages errors={muteErrors} />}
      <ToastContainer />
    </>
  );
};

export default IssuesList;
