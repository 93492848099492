import React, { useState, useEffect, useCallback } from "react";
import { useApi } from "../../api/useApi";
import { availableOrganizations } from "../../api/organizationQueries";
import {
  updateOrganizationEnabledFlag,
  setOrganizationToConsentState,
} from "../../api/organizationMutations";
import SortTable from "../../components/Table/SortTable";
import { Link } from "react-router-dom";
import Button from "../../components/Button";
import StyledLink from "../../components/StyledLink";
import { MdVerifiedUser, MdArchive, MdSync } from "react-icons/md";
import { organizationAdd, organization } from "../../common/paths";
import Modal from "../../components/Modal";
import Spinner from "../../components/Loaders/Spinner";
import ErrorMessages from "../../components/Notifications/ErrorMessages";
import TableButton from "../../components/Button/TableButton";

const Organization = () => {
  const [{ loading, errors, data }, doFetch] = useApi(availableOrganizations);
  const [
    { loading: removalLoading, errors: removalErrors, data: removalData },
    remove,
  ] = useApi();
  const [{ loading: syncLoading, errors: syncErrors, data: syncData }, sync] =
    useApi();

  const [showConfirm, setShowConfirm] = useState(false);
  const [orgToDelete, setOrgToDelete] = useState(null);
  const [showArchived, setShowArchived] = useState(false);

  const organizations = data?.availableOrganizations?.edges ?? [];

  const enabledOrganizations = organizations.filter((wg) => wg?.node?.enabled);

  const disabledOrganizations = organizations.filter(
    (wg) => !wg?.node?.enabled
  );

  const columnsData = [
    {
      Header: "Name",
      id: "name",
      accessor: (d) => d?.node?.displayName,
      Cell: ({ row: { original } }) => {
        const isCoreOrAdmin =
          original.role === "ADMINISTRATOR" || original?.node?.role === "CORE";

        return original?.enabled ? (
          <StyledLink to={organization(original?.node?.id)}>
            {isCoreOrAdmin && (
              <>
                <MdVerifiedUser title={original?.node?.role} />{" "}
              </>
            )}
            {original?.node?.displayName}
          </StyledLink>
        ) : (
          original?.node?.displayName
        );
      },
    },
    {
      Header: "Id",
      id: "id",
      accessor: (d) => d?.node?.id,
    },
    {
      Header: "Status",
      id: "status",
      accessor: (d) => d?.node?.isPending,
      Cell: ({ row: { original } }) => {
        return original?.node?.isPending ? "Pending" : "Active";
      },
    },
    {
      Header: "Enabled",
      id: "enabled",
      accessor: (d) => d?.node?.enabled,
      Cell: ({ row: { original } }) => {
        return original?.node?.enabled.toString();
      },
    },
    {
      Header: "",
      id: "actions",
      maxWidth: 150,
      disableResizing: true,
      Cell: ({ row: { original } }) => {
        const enabled = original?.node?.enabled;
        const pending = original?.node?.isPending;

        return (
          <>
            {pending && enabled ? (
              <TableButton
                title="Force Sync"
                type="button"
                list={"true"}
                onClick={() =>
                  sync({
                    query: setOrganizationToConsentState,
                    variables: { id: original?.node?.id },
                  })
                }
              >
                {syncLoading ? <Spinner /> : <MdSync />}
              </TableButton>
            ) : null}

            <TableButton
              title="Archive Toggle"
              type="button"
              danger={enabled}
              onClick={() =>
                setOrgToDelete({ id: original?.node?.id, enabled: enabled })
              }
            >
              <MdArchive />
            </TableButton>
          </>
        );
      },
    },
  ];

  const fetchOrgs = useCallback(() => {
    doFetch({ query: availableOrganizations });
  }, [doFetch]);

  const toggleEnabled = ({ id, enabled }) => {
    remove({
      query: updateOrganizationEnabledFlag,
      variables: { id: id, enabledState: !enabled },
    });
  };

  //handle remove rule update
  useEffect(() => {
    if (removalData) {
      fetchOrgs();
      setShowConfirm(false);
      setOrgToDelete(null);
      setShowArchived(false);
    }
  }, [removalData, fetchOrgs]);

  useEffect(() => {
    if (orgToDelete) {
      setShowConfirm(true);
    } else {
      setShowConfirm(false);
    }
  }, [orgToDelete]);

  useEffect(() => {
    if (syncData) {
      fetchOrgs();
      setShowConfirm(false);
      setShowArchived(false);
    }
  }, [syncData, fetchOrgs]);

  return (
    <>
      {showConfirm ? (
        <Modal
          title={`Confirm Organization ${
            orgToDelete?.enabled ? "Archival" : "Reactivation"
          }`}
          hide={() => setOrgToDelete(null)}
        >
          <p>
            Are you sure you wish to{" "}
            {orgToDelete?.enabled ? "archive" : "reactivate"} this Organization?
          </p>
          {orgToDelete?.enabled ? (
            <p>
              This action will remove all current Workgroups and Permissions.
            </p>
          ) : null}

          <div>
            <Button
              type="button"
              list="true"
              disabled={removalLoading}
              danger
              onClick={() => toggleEnabled(orgToDelete)}
            >
              {removalLoading ? <Spinner /> : "Yes"}
            </Button>
            <Button
              type="button"
              disabled={removalLoading}
              onClick={() => {
                setOrgToDelete(null);
              }}
            >
              Cancel
            </Button>
          </div>
        </Modal>
      ) : null}

      <Link to={organizationAdd}>
        <Button list={"true"} type="button">
          Add Organization
        </Button>
      </Link>

      {disabledOrganizations && disabledOrganizations.length ? (
        <Button
          type="button"
          onClick={() => setShowArchived((prevState) => !prevState)}
        >
          {showArchived ? "Hide " : "Show "} Archived
        </Button>
      ) : null}

      {syncErrors ? <ErrorMessages errors={syncErrors} /> : null}
      {removalErrors ? <ErrorMessages errors={removalErrors} /> : null}
      {errors ? <ErrorMessages errors={errors} /> : null}
      {/* TODO: Update this to support pagination defaults max 250 orgs also filter based on call  */}
      <SortTable
        loading={loading}
        data={showArchived ? disabledOrganizations : enabledOrganizations}
        columns={columnsData}
      />
    </>
  );
};

export default Organization;
