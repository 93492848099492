// Update Platform Configuration
export const updatePlatformConfiguration = /* GraphQL */ `
  mutation ($newDeploymentId: UUID!) {
    updatePlatformConfiguration(newDeploymentId: $newDeploymentId)
  }
`;

// Update Working Day Holidays
export const updateWorkingDayHolidays = /* GraphQL */ `
  mutation ($workingDayHolidays: [WorkingDayHolidayInputModelInput!]!) {
    updateWorkingDayHolidays(workingDayHolidays: $workingDayHolidays)
  }
`;

// Update Working Day Holidays
export const updateWorkingDays = /* GraphQL */ `
  mutation ($workingDay: WorkingDayInputModelInput!) {
    updateWorkingDays(workingDay: $workingDay) {
      dayOfWeek
      enabled
    }
  }
`;
