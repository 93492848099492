import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Button from "../Button";
import { MdNotifications, MdNotificationsActive } from "react-icons/md";

const NotificationContainer = styled.div`
  display: flex;
  /* min-height: 100%; */
  width: 100%;
  justify-content: center;
  position: fixed;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
`;

const NotificationContent = styled.div`
  width: 50%;
  display: block;
  text-align: center;
  border: 2px solid ${(props) => props.theme.onSurface};
  padding: 20px;
  background: #fff;
  overflow: auto;
  color: ${(props) => props.theme.onSurface};
`;

const NotificationText = styled.div`
  margin-bottom: 1rem;
`;

const NotificationExample = styled.div`
  font-size: 1.2rem;
  margin-bottom: 1rem;
`;

const ActiveNotificationExample = styled.div`
  font-size: 1.2rem;
  margin-bottom: 1rem;
  color: ${(props) => props.theme.onSecondarySurface};
`;

const Notification = ({ text, closeCallBack }) => {
  const [icon, flipIcon] = useState(false);

  useEffect(() => {
    let timer;
    if (icon) {
      timer = setTimeout(() => {
        flipIcon(false);
      }, 2000);
    } else {
      timer = setTimeout(() => {
        flipIcon(true);
      }, 2000);
    }

    return () => clearTimeout(timer);
  }, [icon, flipIcon]);

  return (
    <NotificationContainer>
      <NotificationContent>
        {icon ? (
          <ActiveNotificationExample>
            <MdNotificationsActive />
          </ActiveNotificationExample>
        ) : (
          <NotificationExample>
            <MdNotifications />
          </NotificationExample>
        )}

        <NotificationText>{text ?? ""}</NotificationText>
        <div>
          <Button danger={true} type="button" onClick={() => closeCallBack()}>
            Close
          </Button>
        </div>
      </NotificationContent>
    </NotificationContainer>
  );
};

export default Notification;
