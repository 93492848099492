import React, { useContext, useState, useEffect } from "react";
import Card from "../../components/Card";
import PagedTable from "../../components/Table/PagedTable";
import StyledLink from "../../components/StyledLink";
// import StyledLink from "../../components/StyledLink";
import { manageWorkGroup, workGroupsAdd } from "../../common/paths";
import ActionWrapper from "../../components/ActionsWrapper";
// import StyledLink from "../../components/StyledLink";
import { MdAdd, MdArchive } from "react-icons/md";
import { AuthContext } from "../../contexts/AuthContext";
import styled from "styled-components/macro";
import { useApi } from "../../api/useApi";
import { availableWorkGroups } from "../../api/workgroupQueries";
import Button from "../../components/Button";
import { updateWorkGroupEnabledFlag } from "../../api/workgroupMutations";
import Modal from "../../components/Modal";
import Spinner from "../../components/Loaders/Spinner";
import ErrorMessages from "../../components/Notifications/ErrorMessages";
import TableButton from "../../components/Button/TableButton";
import columnSort from "../../components/Table/helpers/columnSort";

const SingleRowFlex = styled.div`
  display: flex;
  align-items: center;
`;

const DisplayName = styled.div`
  flex: 1;
`;

const WorkgroupPage = () => {
  //Body Section of Widget
  function Body() {
    const [{ loading, errors, data }, doFetch] = useApi();
    const [showConfirm, setShowConfirm] = useState(false);
    const [workgroupToDelete, setWorkgroupToDelete] = useState(null);
    const [showArchived, setShowArchived] = useState(false);

    const [
      { errors: removalErrors, loading: removalLoading, data: removalData },
      remove,
    ] = useApi();

    const groups = data?.availableWorkGroups?.edges ?? [];
    const totalCount = data?.availableWorkGroups?.totalCount;
    const pageInfo = data?.availableWorkGroups?.pageInfo;

    const workgroups = groups.sort(function (a, b) {
      if (a.type > b.type) {
        return -1;
      }
      if (a.type < b.type) {
        return 1;
      }
      return 0;
    });

    const columnsData = [
      {
        Header: "Name",
        id: "displayName",
        accessor: (d) => d?.node?.displayName,
        Cell: ({ row: { original } }) => {
          if (
            !original?.node?.enabled ||
            original?.node?.type === "ORGANIZATION"
          )
            return original?.node?.displayName;
          return (
            <SingleRowFlex>
              <DisplayName>
                <StyledLink to={manageWorkGroup(original?.node?.id)}>
                  {original?.node?.displayName}
                </StyledLink>
              </DisplayName>
            </SingleRowFlex>
          );
        },
      },
      {
        Header: "Type",
        id: "type",
        accessor: (d) => d.node.type,
      },
      {
        Header: "",
        id: "actions",
        maxWidth: 60,
        disableResizing: true,
        Cell: ({ row: { original } }) => {
          const enabled = original?.node?.enabled;

          return (
            original?.node?.type === "PLATFORM" && (
              <TableButton
                type="button"
                danger={enabled}
                title="Archive Toggle"
                onClick={() =>
                  setWorkgroupToDelete({
                    id: original?.node?.id,
                    enabled: enabled,
                  })
                }
              >
                <MdArchive />
              </TableButton>
            )
          );
        },
      },
    ];

    const toggleEnabled = ({ id, enabled }) => {
      remove({
        query: updateWorkGroupEnabledFlag,
        variables: { workGroupId: id, enabledState: !enabled },
      });
    };

    useEffect(() => {
      if (workgroupToDelete) {
        setShowConfirm(true);
      } else {
        setShowConfirm(false);
      }
    }, [workgroupToDelete]);

    //Fetch for Table Paged
    const fetchData = React.useCallback(
      ({ pageSize, cursor, sortBy }) => {
        const sortedObject = columnSort(sortBy);
        doFetch({
          query: availableWorkGroups,
          variables: {
            first: pageSize,
            after: cursor,
            where: {
              enabled: { eq: !showArchived },
            },
            ...(sortedObject
              ? {
                  order: {
                    ...sortedObject,
                  },
                }
              : {
                  order: {
                    type: "ASC",
                  },
                }),
          },
        });
      },
      [doFetch, showArchived]
    );

    //handle remove rule update
    useEffect(() => {
      if (removalData) {
        doFetch({
          query: availableWorkGroups,
          variables: {
            first: 10,
            after: null,
            order: {
              type: "ASC",
            },
            where: {
              enabled: { eq: true },
            },
          },
        });
        setShowConfirm(false);
        setWorkgroupToDelete(null);
        setShowArchived(false);
      }
    }, [removalData, doFetch]);

    return (
      <>
        {showConfirm ? (
          <Modal
            title={`Confirm Workgroup ${
              workgroupToDelete?.enabled ? "Archival" : "Reactivation"
            }`}
            hide={() => setWorkgroupToDelete(null)}
          >
            <p>
              Are you sure you wish to{" "}
              {workgroupToDelete?.enabled ? "archive" : "reactivate"} this
              Workgroup?
            </p>
            <div>
              <Button
                type="button"
                list="true"
                disabled={removalLoading}
                danger
                onClick={() => toggleEnabled(workgroupToDelete)}
              >
                {removalLoading ? <Spinner /> : "Yes"}
              </Button>
              <Button
                type="button"
                disabled={removalLoading}
                onClick={() => {
                  setWorkgroupToDelete(null);
                }}
              >
                Cancel
              </Button>
            </div>
          </Modal>
        ) : null}

        <Button
          type="button"
          onClick={() => setShowArchived((prevState) => !prevState)}
        >
          {showArchived ? "Hide " : "Show "} Archived
        </Button>

        {removalErrors ? <ErrorMessages errors={removalErrors} /> : null}
        {errors ? <ErrorMessages errors={errors} /> : null}

        <PagedTable
          fetchData={fetchData}
          loading={loading}
          pageInfo={pageInfo}
          totalCount={totalCount}
          data={workgroups}
          columns={columnsData}
          defaultPageSize={50}
        />
      </>
    );
  }

  //Actions Section of Widget
  function Actions() {
    const { user } = useContext(AuthContext);
    return (
      <>
        {user && user.role === 3 && (
          <ActionWrapper>
            <StyledLink to={workGroupsAdd} title="Add Workgroup">
              <MdAdd />
            </StyledLink>
          </ActionWrapper>
        )}
      </>
    );
  }

  return (
    <Card
      title={"Workgroups"}
      titleDescription={"available groups"}
      body={Body}
      actions={Actions}
      full
    />
  );
};
export default WorkgroupPage;
