// List of Rule Standards
export const allUserDefinedCleanupsAsync = /* GraphQL */ `
  query (
    $after: String
    $before: String
    $first: Int
    $last: Int
    $order: [UserDefinedCleanupSortInput!]
    $where: UserDefinedCleanupFilterInput
  ) {
    allUserDefinedCleanupsAsync(
      after: $after
      before: $before
      first: $first
      where: $where
      last: $last
      order: $order
    ) {
      totalCount
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      edges {
        cursor
        node {
          description
          sourceTimeInHours
          sourceType
        }
      }
    }
  }
`;
