import React from "react";
import { useTable, useSortBy, usePagination, useFlexLayout } from "react-table";
import { MdExpandMore, MdExpandLess } from "react-icons/md";
import {
  TableElement,
  Td,
  Th,
  Tr,
  TableLoading,
  Thead,
  TBody,
} from "./subElements";

import SplashLoader from "../Loaders/SplashLoader";

function Table({ columns, data, loading }) {
  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, page } =
    useTable(
      {
        columns,
        data,
        disableSortRemove: true,
        initialState: { pageIndex: 0 },
      },
      useSortBy,
      usePagination,
      useFlexLayout
    );

  return (
    <>
      <TableElement {...getTableProps()}>
        <Thead>
          {headerGroups.map((headerGroup, i) => (
            <Tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column, i) => (
                // Add the sorting props to control sorting. For this example
                // we can add them into the header props
                <Th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {/* Sort With Indicator */}
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div style={{ marginRight: "auto" }}>
                      {column.render("Header")}
                    </div>
                    {column.isSorted ? (
                      <div style={{ fontSize: "1.5rem" }}>
                        {column.isSortedDesc ? (
                          <MdExpandMore />
                        ) : (
                          <MdExpandLess />
                        )}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <TBody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <Tr {...row.getRowProps()}>
                {row.cells.map((cell, i) => {
                  const isActions = cell.column?.id === "actions";
                  return (
                    <Td
                      {...cell.getCellProps()}
                      right={isActions ? `0` : null}
                      isOdd={i % 2 !== 1}
                    >
                      {cell.render("Cell")}
                    </Td>
                  );
                })}
              </Tr>
            );
          })}
        </TBody>
      </TableElement>
      <>
        {loading ? (
          <TableLoading>
            <SplashLoader text="Loading Results" />
          </TableLoading>
        ) : null}
      </>
    </>
  );
}

const SubRowTable = ({ data, columns, loading }) => {
  return (
    <>
      <Table
        columns={columns}
        data={data && data.length ? data : []}
        loading={loading}
      />
    </>
  );
};

export default SubRowTable;
