import React from "react";
import { useApi } from "../../api/useApi";
import { withRouter } from "react-router-dom";
import PagedTable from "../../components/Table/PagedTable";
import { standardsList } from "../../api/ruleQueries";
import { IoIosGitNetwork } from "react-icons/io";
import TableButton from "../../components/Button/TableButton";

const SourceTable = ({ setMapId, mappingType }) => {
  //Init Data Fetch
  const [{ loading, data: apiData }, doFetch] = useApi();

  const standards = apiData?.availableBusinessRuleStandards?.edges ?? [];
  const totalCount = apiData?.availableBusinessRuleStandards?.totalCount;
  const pageInfo = apiData?.availableBusinessRuleStandards?.pageInfo;

  //Fetch for Table Paged
  const fetchData = React.useCallback(
    ({ pageSize, cursor }) => {
      doFetch({
        query: standardsList,
        variables: {
          first: pageSize,
          after: cursor,
          tagFilter: { tagIds: [], exactMatch: false },
          standardType: mappingType,
          where: {
            enabled: { eq: true },
          },
          order: {
            name: "ASC",
          },
        },
      });
    },
    [doFetch, mappingType]
  );

  const columnsData = [
    {
      Header: "Title",
      id: "title",
      accessor: (d) => d?.node?.name,
    },
    {
      Header: "",
      id: "actions",
      Cell: ({ row: { original } }) => {
        return (
          <>
            <TableButton
              onClick={() => setMapId(original?.node?.id)}
              iconBump={true}
              data-testid={`mapping-rule-${original?.node?.id}`}
            >
              <IoIosGitNetwork /> Map
            </TableButton>
          </>
        );
      },
    },
  ];

  return (
    <>
      <PagedTable
        fetchData={fetchData}
        loading={loading}
        pageInfo={pageInfo}
        totalCount={totalCount}
        data={standards}
        columns={columnsData}
        defaultPageSize={50}
      />
    </>
  );
};

const SourceList = ({ setMapId, mappingType }) => {
  // const { togglePin, pinnedSources } = useContext(AuthContext);

  return <SourceTable setMapId={setMapId} mappingType={mappingType} />;
};

const SourceListWithRouter = withRouter(SourceList);
export default SourceListWithRouter;
