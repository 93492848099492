import React, { useReducer, useState, useEffect, useCallback } from "react";

import {
  FormControl,
  StyledSelect,
  StyledInput,
  Label,
} from "../../Form/FormControls";
import Button from "../../Button";
// import Spinner from "../../Loaders/Spinner";
import reducer from "./CreateReducer";
import { format } from "date-fns";
import { v4 as uuid } from "uuid";
import Modal from "../../Modal";
import { createWorkflow, updateWorkflow } from "../../../api/workflowMutations";
import { useApi } from "../../../api/useApi";
import {
  availableEtlProviders,
  browseForEtlProvidersPipelines,
} from "../../../api/etlProviderQueries";
import useETLProviderPipelineBrowseNotification from "../../../Hooks/useETLProviderPipelineBrowseNotification";
import { sortByAlpha } from "../../../common/helpers/util";
import { useHistory } from "react-router-dom";
import RenderSegment from "./CreateForm/RenderSegment";
import { WorkflowDetails } from "./CreateForm/CreateFormStyles";
import EntryPoint from "./CreateForm/EntryPoint";
import UpdateThreshold from "./CreateForm/UpdateThreshold";
import AddPipelineForm from "./CreateForm/AddPipelineForm";
import SplashLoader from "../../Loaders/SplashLoader";
import ShowSuccessModal from "./CreateForm/ShowSuccessModal";
import ErrorMessages from "../../../components/Notifications/ErrorMessages";
import { getAllDataSources } from "../../../api/dataSourceQueries";
import Secondary from "../../Button/Secondary";
import SelectTags from "../../Tags/SelectTags";
import UpdateTags from "../../Tags/UpdateTags";

// ADD SEARCH FIELD TO HIGHLIGHT ETL OR DATASOURCE PIPELINE
const minuteOptions = [
  { value: "0", label: ":00" },
  { value: "1", label: ":15" },
  { value: "2", label: ":30" },
  { value: "3", label: ":45" },
];

const ampmOptions = [
  { value: true, label: "AM" },
  { value: false, label: "PM" },
];

const hourOptions = [
  { value: "1", label: "1" },
  { value: "2", label: "2" },
  { value: "3", label: "3" },
  { value: "4", label: "4" },
  { value: "5", label: "5" },
  { value: "6", label: "6" },
  { value: "7", label: "7" },
  { value: "8", label: "8" },
  { value: "9", label: "9" },
  { value: "10", label: "10" },
  { value: "11", label: "11" },
  { value: "12", label: "12" },
];

const getZone = format(new Date(), "XXX", {
  timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
});

//loop through, give unique id
//replace dependency array ids, with UNique id references

function buildPipelines(dependencies, stateSources) {
  const uniqueIdArray = dependencies.map((dependent) => {
    return { ...dependent, uid: uuid() };
  });

  const newDependencies = uniqueIdArray.map((dependent) => {
    //convert these ids to the index of the segment so we can manage and assign
    //swaps the ETL ID for the UUID of the dependent
    const newDependentPipelines = dependent.dependentPipelines.map(
      (dependent) => {
        return uniqueIdArray.find(function (d) {
          return d?.name === dependent;
        })?.uid;
      }
    );

    const newDependentDataSources = dependent.dependentDataSources.map((dd) => {
      const foundDataSource = stateSources.find((ds) => ds.value === dd.id);

      return {
        ...dd,
        label: foundDataSource?.label,
        value: foundDataSource?.value,
        dataQualityThreshold: foundDataSource?.dataQualityThreshold,
      };
    });

    const isTermination = uniqueIdArray.filter((dp) =>
      dp?.dependentPipelines.includes(dependent?.name)
    );

    return {
      ...dependent,
      name: { label: dependent?.name, value: dependent?.name },
      dependentPipelines: newDependentPipelines,
      dependentDataSources: newDependentDataSources,
      isTermination: isTermination?.length ? false : true,
    };
  });

  return newDependencies;
}

function flattenDepObjects(dependencies) {
  const cleanDeps = dependencies.map((dep, i) => {
    return {
      name: dep?.name?.value,
      dependentDataSources: dep.dependentDataSources.map((dsDep) => {
        return {
          id: dsDep?.value,
          dataQualityThreshold: dsDep?.dataQualityThreshold,
        };
      }),
      dependentPipelines: dep.dependentPipelines.map((dsDep) => {
        return dependencies.find((d) => d?.uid === dsDep)?.name?.value;
      }),
    };
  });

  return cleanDeps;
}

// Front-load Needed Data to Build a Pipeline

const ETLWorkflowFramework = ({ workflowData }) => {
  // If There is a workflowID found, get the Workflow, also get the state dataSources

  // Providers State
  const [providers, setProviders] = useState([]);

  // Instances Names State
  const [instanceNames, setInstanceNames] = useState([]);

  // Load Available Providers
  const [{ errors, data }] = useApi(availableEtlProviders);

  // Browse API Call
  const [{ errors: browseDataRequestErrors }, browse] = useApi();

  // Set locally browsed failure
  const [browseFailures, setBrowseFailures] = useState();

  const [{ errors: allSourceErrors, loading: isLoading, data: sources }] =
    useApi(getAllDataSources);

  const sortedDataSources = sources?.availableWorkflowDataSources?.length
    ? sortByAlpha(sources?.availableWorkflowDataSources, "name")
    : [];

  const stateSources =
    sortedDataSources?.map((source) => {
      const currentThreshold = source?.etlPipeline?.[0]?.dataQualityThreshold;
      return {
        label: source?.name,
        value: source?.id,
        type: "source",
        dataQualityThreshold:
          currentThreshold !== null && currentThreshold !== undefined
            ? currentThreshold
            : 70,
      };
    }) ?? [];

  // Handle Browse Request
  const browseRequest = useCallback(
    (browseObject) => {
      browse({
        query: browseForEtlProvidersPipelines,
        variables: {
          browseInfo: { etlProviderInstanceId: browseObject?.value },
        },
      });
    },
    [browse]
  );

  // Provider Handler
  useEffect(() => {
    if (data) {
      const providers =
        data?.availableEtlProviders?.edges?.[0]?.node?.instances
          .filter((instance) => instance.enabled)
          .map((inst) => {
            return {
              label: inst.name,
              value: inst.id,
            };
          }) ?? [];

      setProviders(providers);
    }
  }, [data, setProviders, browseRequest]);

  // Provider Browse Hook
  const { etlProviderPipelineBrowseCompleted, setEtlProviderBrowseCompleted } =
    useETLProviderPipelineBrowseNotification();

  // Handle Browse Results
  useEffect(() => {
    if (etlProviderPipelineBrowseCompleted) {
      const response = etlProviderPipelineBrowseCompleted.payload;
      if (response?.ErrorMessage) {
        setBrowseFailures([{ message: response?.ErrorMessage }]);
        setEtlProviderBrowseCompleted(null);
      } else {
        const items = response.BrowseItems.map((item) => {
          return {
            label: item.Name,
            value: item.Name,
          };
        });

        const sortedItems = items?.length ? sortByAlpha(items, "label") : [];

        setInstanceNames(sortedItems);
        setEtlProviderBrowseCompleted(null);
      }
    }
  }, [
    etlProviderPipelineBrowseCompleted,
    setInstanceNames,
    setEtlProviderBrowseCompleted,
    setBrowseFailures,
  ]);

  useEffect(() => {
    if (workflowData?.etlProviderInstanceId) {
      browseRequest({ value: workflowData?.etlProviderInstanceId });
    }
  }, [workflowData, browseRequest]);

  if (workflowData) {
    if (!providers || !instanceNames?.length || !stateSources?.length) {
      return <SplashLoader text={"Preparing Workflow Builder"} />;
    }
  } else if (!providers) {
    return <SplashLoader text={"Preparing Workflow Providers"} />;
  } else if (providers && isLoading) {
    return <SplashLoader text={"Preparing Workflow Sources"} />;
  } else if (!providers.length) {
    return (
      <ErrorMessages
        errors={[{ message: "No ETL Provider available to create workflow" }]}
      />
    );
  } else if (!stateSources?.length) {
    return (
      <ErrorMessages
        errors={[{ message: "No Data Source available to create workflow" }]}
      />
    );
  }

  if (browseDataRequestErrors?.length) {
    return <ErrorMessages errors={browseDataRequestErrors} />;
  }

  if (browseFailures?.length) {
    return <ErrorMessages errors={browseFailures} />;
  }

  if (errors?.length) {
    return <ErrorMessages errors={errors} />;
  }
  if (allSourceErrors) return <ErrorMessages errors={allSourceErrors} />;
  if (isLoading) return <SplashLoader text={"Loading Data Sources"} />;

  return (
    <ETLWorkFlowForm
      workflowData={workflowData}
      providers={providers}
      browseRequest={browseRequest}
      setInstanceNames={setInstanceNames}
      instanceNames={instanceNames}
      stateSources={stateSources}
    />
  );
};

const ETLWorkFlowForm = ({
  workflowData,
  providers,
  browseRequest,
  setInstanceNames,
  instanceNames,
  stateSources,
}) => {
  const workflowDate = workflowData
    ? new Date(workflowData?.alertTriggerTimestamp)
    : null;

  //initialize the reducer state
  const initialState = {
    showChangeThreshold: false,
    addNewPipeline: false,
    etlProviderInstanceId: workflowData?.etlProviderInstanceId
      ? providers.find((p) => p.value === workflowData?.etlProviderInstanceId)
      : null,
    workflowName: workflowData?.name ?? "",
    tagInstances:
      workflowData?.tagInstances?.map((ti) => {
        const createTagOption = {
          label: ti?.tag?.name,
          value: ti?.tagId,
          ...ti,
        };
        return createTagOption;
      }) ?? null,
    zone: getZone,
    hour: workflowData
      ? hourOptions.find(
          (o) => o.value === format(workflowDate, "h")?.toString()
        )
      : { value: "1", label: "1" },
    min: workflowData
      ? minuteOptions.find(
          (j) => j.value === workflowData?.alertTriggerTime.minutes.toString()
        )
      : { value: "0", label: ":00" },
    isAm: workflowData
      ? format(workflowDate, "aaaa")?.toString() === "a.m."
        ? { value: true, label: "AM" }
        : { value: false, label: "PM" }
      : { value: false, label: "PM" },
    dependencies: workflowData
      ? buildPipelines(workflowData?.dependencies, stateSources)
      : [
          {
            name: "",
            dependentDataSources: [],
            dependentPipelines: [],
            isTermination: true,
            uid: uuid(),
          },
        ],
  };

  const [state, dispatch] = useReducer(reducer, initialState);
  const [success, setShowSuccess] = useState(false);

  const [{ errors: createErrors, data: createData }, create] = useApi();

  // start listening to process for create if success
  useEffect(() => {
    if (createData && !createErrors?.length) {
      setShowSuccess(true);
    } else if (createErrors?.length) {
      setShowSuccess(false);
    }
  }, [createData, createErrors]);

  let history = useHistory();

  const SaveForm = () => {
    //update workflow vs save new
    if (workflowData) {
      const submitObject = {
        etlProviderInstanceId: state?.etlProviderInstanceId?.value,
        name: state?.workflowName,
        alertTriggerTime: {
          hour: Number(state?.hour?.value),
          minutes: Number(state?.min?.value),
          isAM: state?.isAm?.value,
          timeZone: state?.zone,
        },
        dependencies: flattenDepObjects(state?.dependencies),
      };

      create({ query: updateWorkflow, variables: { workflow: submitObject } });
    } else {
      const submitObject = {
        etlProviderInstanceId: state?.etlProviderInstanceId?.value,
        name: state?.workflowName,
        alertTriggerTime: {
          hour: Number(state?.hour?.value),
          minutes: Number(state?.min?.value),
          isAM: state?.isAm?.value,
          timeZone: state?.zone,
        },
        dependencies: flattenDepObjects(state?.dependencies),
        tagInstanceInputs: state?.tagInstanceInputs,
      };

      create({ query: createWorkflow, variables: { workflow: submitObject } });
    }
  };

  if (state?.etlProviderInstanceId && !instanceNames?.length) {
    return <SplashLoader text={"Preparing Workflow Builder"} />;
  }

  return (
    <div>
      {success ? (
        <ShowSuccessModal
          workflowData={workflowData}
          setShowSuccess={setShowSuccess}
          dispatch={dispatch}
        />
      ) : null}

      {!state.etlProviderInstanceId ? (
        <Modal
          title={"Create Workflow"}
          hide={() => history.push("/workflows")}
        >
          <EntryPoint
            dispatch={dispatch}
            providers={providers}
            browseRequest={browseRequest}
          />
        </Modal>
      ) : null}

      {state.showChangeThreshold ? (
        <Modal
          title={`Change Threshold`}
          hide={() =>
            dispatch({
              type: "TOGGLE_CHANGE_DATASOURCE_THRESHOLD",
            })
          }
        >
          <h6>{state?.showChangeThreshold?.dataSource?.label}</h6>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <div style={{ flex: 1 }}>
              <UpdateThreshold
                dispatch={dispatch}
                data={state?.showChangeThreshold}
              />
            </div>
          </div>
        </Modal>
      ) : null}

      {state.addNewPipeline ? (
        <Modal
          title={`Add New Pipeline`}
          hide={() =>
            dispatch({
              type: "TOGGLE_ADD_NEW_PIPELINE",
            })
          }
        >
          <div style={{ display: "flex", justifyContent: "center" }}>
            <div style={{ flex: 1 }}>
              <AddPipelineForm
                dispatch={dispatch}
                setInstanceNames={setInstanceNames}
                data={state?.addNewPipeline}
              />
            </div>
          </div>
        </Modal>
      ) : null}

      {state.etlProviderInstanceId ? (
        <>
          <WorkflowDetails>
            <div style={{ flex: 1, marginRight: "1rem" }}>
              {workflowData ? (
                state.workflowName
              ) : (
                <FormControl>
                  <StyledInput
                    type="text"
                    name="workflowName"
                    label="Name"
                    required={true}
                    value={state.workflowName}
                    placeholder={`Name`}
                    onChange={(e) =>
                      dispatch({
                        type: "UPDATE_FIELD",
                        payload: {
                          field: "workflowName",
                          value: e.target.value,
                        },
                      })
                    }
                  />
                </FormControl>
              )}
            </div>

            <div
              style={{ flex: 1, flexDirection: "column", marginRight: "1rem" }}
            >
              <>
                {workflowData?.alertTriggerTimestamp ? (
                  <div style={{ marginBottom: ".5rem" }}>
                    <b>Current Alert Time: </b>
                  </div>
                ) : null}

                <div style={{ display: "flex", alignItems: "center" }}>
                  <div
                    style={{
                      width: "100px",
                      marginRight: "1rem",
                      display: "inline-block",
                    }}
                  >
                    <StyledSelect
                      className={`react-select-container`}
                      classNamePrefix={`react-select`}
                      name={`hour`}
                      id={`hour`}
                      inputId={`hourSelect-input`}
                      instanceId={`hourSelect-instance`}
                      label="Hours"
                      options={hourOptions}
                      placeholder={`Select Hours`}
                      menuPortalTarget={document.body}
                      value={state.hour}
                      menuPlacement="auto"
                      onChange={(e) =>
                        dispatch({
                          type: "UPDATE_FIELD",
                          payload: { field: "hour", value: e },
                        })
                      }
                      styles={{
                        control: (provided) => ({
                          ...provided,
                          width: "100px",
                        }),
                      }}
                    />
                  </div>
                  <div
                    style={{
                      width: "110px",
                      marginRight: "1rem",
                      display: "inline-block",
                    }}
                  >
                    <StyledSelect
                      className={`react-select-container`}
                      classNamePrefix={`react-select`}
                      name={`min`}
                      id={`min`}
                      inputId={`minSelect-input`}
                      instanceId={`minSelect-instance`}
                      label="Minutes"
                      options={minuteOptions}
                      placeholder={`Select Minutes`}
                      menuPortalTarget={document.body}
                      value={state.min}
                      menuPlacement="auto"
                      onChange={(e) =>
                        dispatch({
                          type: "UPDATE_FIELD",
                          payload: { field: "min", value: e },
                        })
                      }
                      styles={{
                        control: (provided) => ({
                          ...provided,
                          width: "110px",
                        }),
                      }}
                    />
                  </div>
                  <div
                    style={{
                      width: "110px",
                      marginRight: "1rem",
                      display: "inline-block",
                    }}
                  >
                    <StyledSelect
                      className={`react-select-container`}
                      classNamePrefix={`react-select`}
                      name={`isAm`}
                      id={`isAm`}
                      inputId={`isAmSelect-input`}
                      instanceId={`isAmSelect-instance`}
                      label="AM/PM"
                      options={ampmOptions}
                      menuPortalTarget={document.body}
                      placeholder={`Select AM/PM`}
                      value={state.isAm}
                      menuPlacement="auto"
                      onChange={(e) =>
                        dispatch({
                          type: "UPDATE_FIELD",
                          payload: { field: "isAm", value: e },
                        })
                      }
                      styles={{
                        control: (provided) => ({
                          ...provided,
                          width: "110px",
                        }),
                      }}
                    />
                  </div>
                  <div
                    style={{
                      display: "inline-block",
                    }}
                  >
                    {Intl.DateTimeFormat().resolvedOptions().timeZone}
                  </div>
                </div>
              </>
            </div>

            <div
              style={{
                flex: 1,
                marginLeft: "auto",
                marginRight: "1rem",
              }}
            >
              {workflowData ? (
                state?.etlProviderInstanceId?.label
              ) : (
                <FormControl>
                  <Label>ETL Provider Instance</Label>
                  <StyledSelect
                    className={`react-select-container`}
                    classNamePrefix={`react-select`}
                    name={`etlProviderInstanceId`}
                    id={`etlProviderInstanceId`}
                    inputId={`etlProviderInstanceIdSelect-input`}
                    instanceId={`etlProviderInstanceIdSelect-instance`}
                    label="Instance"
                    options={providers}
                    placeholder={`Select Instance`}
                    value={state?.etlProviderInstanceId}
                    menuPortalTarget={document.body}
                    menuPlacement="auto"
                    onChange={(e) => {
                      dispatch({
                        type: "UPDATE_PROVIDER",
                        payload: { provider: e },
                      });
                      browseRequest(e);
                    }}
                  />
                </FormControl>
              )}
            </div>
            <div
              style={{
                flex: 1,
                marginLeft: "auto",
                textAlign: "right",
              }}
            >
              <Secondary
                type="button"
                list={true}
                onClick={() => history.push("/workflows")}
              >
                Cancel
              </Secondary>

              <Button
                type="button"
                onClick={() => {
                  SaveForm();
                }}
              >
                Save
              </Button>
            </div>
          </WorkflowDetails>

          {workflowData ? (
            <div style={{ marginBottom: "1rem" }}>
              <UpdateTags
                remoteObjectId={state?.etlProviderInstanceId?.value}
                tagType={"WORKFLOW"}
              />
            </div>
          ) : (
            <div style={{ marginBottom: "1rem" }}>
              <h3>Tags</h3>
              <p>Select the appropriate tags below.</p>
              <SelectTags
                updateTags={dispatch}
                tagType={"WORKFLOW"}
                dispatchType={"SET_TAGS"}
              />
            </div>
          )}

          {createErrors?.length ? (
            <ErrorMessages errors={createErrors} />
          ) : null}

          <div style={{ overflowX: "auto" }}>
            <RenderSegment
              key={"initial"}
              segment={
                state.dependencies.find((segment) => segment.isTermination) ??
                null
              }
              dependencies={state.dependencies}
              dispatch={dispatch}
              instanceNames={instanceNames}
              stateSources={stateSources}
            />
          </div>
        </>
      ) : null}

      {createErrors?.length ? <ErrorMessages errors={createErrors} /> : null}
    </div>
  );
};

export default ETLWorkflowFramework;
