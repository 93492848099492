import React, { useState, useRef, useEffect } from "react";
import { MdClose, MdAddCircle, MdEdit } from "react-icons/md";
import {
  DataSourceContainer,
  DataSourceHeading,
  FieldLabel,
  ThresholdContainer,
  FormContainers,
  DataSourceWrapper,
  ThresholdButton,
  DataSourceActions,
  RemoveDataSourceBtn,
} from "./CreateFormStyles";
import { StyledSelect } from "../../../Form/FormControls";

//generic deBouncer
function debounce(fn, ms) {
  let timer;
  return (_) => {
    clearTimeout(timer);
    timer = setTimeout((_) => {
      timer = null;
      fn.apply(this, arguments);
    }, ms);
  };
}

const DataSources = ({ segment, sources, dispatch, stateSources }) => {
  const itemRef = useRef(null);
  const [itemWidth, setItemWidth] = useState(-1);

  useEffect(() => {
    // Handler to call on window resize
    const debouncedHandleResize = debounce(function handleResize() {
      setItemWidth(itemRef.current.offsetWidth);
    }, 300);

    function handleResize() {
      // Set window width/height to state
      setItemWidth(itemRef.current.offsetWidth);
    }

    // Add event listener
    window.addEventListener("resize", debouncedHandleResize);

    // Call handler right away so state gets updated with initial window size
    handleResize();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", debouncedHandleResize);
  }, [itemRef]); // Empty array ensures that effect is only run handleResize mount

  return (
    <>
      <FormContainers
        ref={itemRef}
        itemWidth={itemWidth}
        sourceCount={sources?.length}
      >
        {sources.map((ds, i) => {
          const isLastSource = sources?.length === i + 1;
          return (
            <DataSourceContainer
              isLastSource={isLastSource}
              itemWidth={itemWidth}
              sourceCount={sources?.length}
              key={`dataSourceContainer-${i}-${ds?.id?.value}`}
            >
              <DataSourceWrapper itemWidth={itemWidth}>
                <DataSourceHeading>
                  <FieldLabel>Data Source</FieldLabel>
                  <ThresholdButton
                    onClick={() =>
                      dispatch({
                        type: "TOGGLE_CHANGE_DATASOURCE_THRESHOLD",
                        payload: {
                          dataSource: ds,
                          segment: segment,
                        },
                      })
                    }
                  >
                    <ThresholdContainer>
                      Min. DQ Score: {ds.dataQualityThreshold} <MdEdit />
                    </ThresholdContainer>
                  </ThresholdButton>
                </DataSourceHeading>

                <StyledSelect
                  className={`react-select-container`}
                  classNamePrefix={`react-select`}
                  name={`datasources`}
                  id={`datasources`}
                  inputId={`datasourcesSelect-input`}
                  instanceId={`datasourcesSelect-instance`}
                  label="Data Source"
                  options={stateSources.map((co) => {
                    return {
                      ...co,
                      isDisabled:
                        sources?.find((c) => c.value === co.value) ?? false,
                    };
                  })}
                  placeholder={`Select Data Source`}
                  menuPortalTarget={document.body}
                  value={ds?.value ? ds : null}
                  menuPlacement="auto"
                  onChange={(e) =>
                    dispatch({
                      type: "SELECT_DATA_SOURCE",
                      payload: {
                        pipeline: segment,
                        dataSource: e,
                        sourceIndex: i,
                      },
                    })
                  }
                />
              </DataSourceWrapper>
              <DataSourceActions>
                {segment?.isTermination || sources?.length >= 2 ? (
                  <RemoveDataSourceBtn
                    isLastSource={isLastSource}
                    title={"Remove Data Source"}
                    onClick={() =>
                      dispatch({
                        type: "REMOVE_DATA_SOURCE",
                        payload: {
                          pipeline: segment,
                          sourceIndex: i,
                        },
                      })
                    }
                  >
                    <MdClose />
                  </RemoveDataSourceBtn>
                ) : null}

                {isLastSource ? (
                  <div
                    style={{ cursor: "pointer" }}
                    title={"Add Data Source"}
                    onClick={() =>
                      dispatch({
                        type: "ADD_DATA_SOURCE",
                        payload: {
                          pipeline: segment,
                          sourceIndex: i,
                        },
                      })
                    }
                  >
                    <MdAddCircle />
                  </div>
                ) : null}
              </DataSourceActions>
            </DataSourceContainer>
          );
        })}
      </FormContainers>
    </>
  );
};

export default DataSources;
