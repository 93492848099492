export const notifications = /* GraphQL */ `
  subscription ($authToken: String!) {
    onNotification(authToken: $authToken) {
      payload
      id
      selfDismissing
      timestamp
    }
  }
`;
