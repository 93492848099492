import React from "react";
import styled from "styled-components/macro";
import ReactTooltip from "react-tooltip";
import Spinner from "../../../components/Loaders/Spinner";
import DataQualityFailuresCountHover from "../../../components/Widgets/DataSourceWidgets/DataQualityFailuresCountHover";
import DataQualityOpportunitiesForFailuresCountHover from "../../../components/Widgets/DataSourceWidgets/DataQualityOpportunitiesForFailuresCountHover";
import DataSourceRowsMeasuredHover from "../../../components/Widgets/DataSourceWidgets/DataSourceRowsMeasuredHover";

const StyledReactTooltip = styled(ReactTooltip).attrs({
  className: "custom-tooltip",
})`
  &.custom-tooltip {
    background-color: #131235;
    margin-top: 3.5rem;
    border-radius: 8px;
  }
`;

const HoverBlock = styled.div`
  display: flex;
  @media (max-width: 768px) {
    display: block;
  }
`;

const ScoreHover = React.memo(
  ({ data, sourceId, loading, errors, ...rest }) => {
    if (loading) return <Spinner />;
    if (errors) return <div>Errors</div>;
    if (!data) return <div>No Data Available</div>;

    return <div>{rest?.children}</div>;
  }
);

export default ScoreHover;
