import React from "react";
import { v4 as uuid } from "uuid";
import RenderSegment from "./RenderSegment";

import StyledLink from "../../../components/StyledLink";
import { useApi } from "../../../api/useApi";
import { getAllDataSources } from "../../../api/dataSourceQueries";
import ErrorMessages from "../../../components/Notifications/ErrorMessages";
import SplashLoader from "../../../components/Loaders/SplashLoader";
import { formatMinutes } from "../../../common/formats";

//loop through, give unique id
//replace dependency array ids, with UNique id references

function buildPipelines(dependencies, stateSources) {
  const uniqueIdArray = dependencies.map((dependent) => {
    return { ...dependent, uid: uuid() };
  });

  const newDependencies = uniqueIdArray.map((dependent) => {
    //convert these ids to the index of the segment so we can manage and assign
    //swaps the ETL ID for the UUID of the dependent
    const newDependentPipelines = dependent.dependentPipelines.map(
      (dependent) => {
        return uniqueIdArray.find(function (d) {
          return d?.name === dependent;
        })?.uid;
      }
    );

    const newDependentDataSources = dependent.dependentDataSources.map((dd) => {
      const foundDataSource = stateSources.find((ds) => ds.value === dd.id);

      return {
        ...dd,
        label: foundDataSource?.label,
        value: foundDataSource?.value,
        dataQualityThreshold: foundDataSource?.dataQualityThreshold,
      };
    });

    const isTermination = uniqueIdArray.filter((dp) =>
      dp?.dependentPipelines.includes(dependent?.name)
    );

    return {
      ...dependent,
      dependentPipelines: newDependentPipelines,
      dependentDataSources: newDependentDataSources,
      isTermination: isTermination?.length ? false : true,
    };
  });

  return newDependencies;
}

const WorkflowView = ({ data, workflowId }) => {
  const [{ errors, loading: isLoading, data: stateSources }] =
    useApi(getAllDataSources);

  const sources =
    stateSources?.availableWorkflowDataSources?.map((source) => {
      const currentThreshold = source?.etlPipeline?.[0]?.dataQualityThreshold;
      return {
        label: source?.name,
        value: source?.id,
        type: "source",
        dataQualityThreshold:
          currentThreshold !== null && currentThreshold !== undefined
            ? currentThreshold
            : 70,
      };
    }) ?? [];

  if (errors) return <ErrorMessages errors={errors} />;
  if (isLoading) return <SplashLoader text={"Loading Data Sources"} />;

  const builtDeps = buildPipelines(data?.dependencies, sources);

  return (
    <div>
      <>
        {/* <WorkflowDetails> */}

        <div
          style={{
            flex: 1,
            marginBottom: "1rem",
            marginLeft: "1rem",
            marginRight: "1rem",
          }}
        >
          <>
            <div style={{ fontWeight: "bold", marginBottom: ".5rem" }}>
              Alert Time
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <div
                style={{
                  marginRight: "1rem",
                  display: "flex",
                }}
              >
                {formatMinutes(new Date(data.alertTriggerTimestamp))}
              </div>
              <div style={{ marginLeft: "auto" }}>
                <StyledLink
                  to={`/workflows/${workflowId}/edit`}
                  title="Edit Workflow"
                >
                  Edit
                </StyledLink>
              </div>
            </div>
          </>
        </div>

        {/* </WorkflowDetails> */}
        <div style={{ overflowX: "auto" }}>
          <RenderSegment
            key={"initial"}
            segment={builtDeps.find((segment) => segment.isTermination) ?? null}
            dependencies={builtDeps}
            stateSources={sources}
          />
        </div>
      </>
    </div>
  );
};

export default WorkflowView;
