import React, { useContext } from "react";
import { PinContext } from "../../contexts/PinContext";
import { RiPushpinFill } from "react-icons/ri";
import TableButton from "../../components/Button/TableButton";

const updateSources = (payload) => {
  const action = payload?.action;
  const type = payload?.type;
  const item = payload?.item;
  const affectFunc = payload?.affect;
  const source = payload?.source;

  if (action) {
    if (action === "add") {
      const currentData = [...source];
      currentData.push(item);
      affectFunc(type, currentData);
      const stringData = JSON.stringify(currentData);
      localStorage.setItem(`pinned-${type}`, stringData);
    } else if (action === "remove") {
      const currentData = source;
      const newData = currentData.filter((data) => data !== item);
      const stringData = JSON.stringify(newData);
      affectFunc(type, newData);
      localStorage.setItem(`pinned-${type}`, stringData);
    }
  }
};

const PinButton = ({ type, item, slim }) => {
  const { pinnedSources, pinnedPolicies, update } = useContext(PinContext);
  let isPinned;
  let source;
  if (type === "sources") {
    source = pinnedSources;
    isPinned = pinnedSources.includes(item);
  } else if (type === "policies") {
    source = pinnedPolicies;
    isPinned = pinnedPolicies.includes(item);
  }
  return (
    <TableButton
      danger={isPinned}
      list={slim ? false : true}
      type="button"
      title="Pin Toggle"
      bumpdown={true}
      onClick={() =>
        updateSources({
          action: isPinned ? "remove" : "add",
          type,
          item,
          source: source,
          affect: update,
        })
      }
      style={{ opacity: isPinned ? ".5" : "" }}
    >
      <RiPushpinFill />
    </TableButton>
  );
};

export default PinButton;
