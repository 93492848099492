import React, { useEffect } from "react";
import styled from "styled-components/macro";
import { formatTime } from "../../common/formats";
import { MdDoneAll } from "react-icons/md";
import { useApi } from "../../api/useApiRedux";
import SplashLoader from "../../components/Loaders/SplashLoader";
import ErrorMessages from "../../components/Notifications/ErrorMessages";

const GenericNote = styled.div`
  background: #ebeef2;
  padding: 1rem;
  margin-bottom: 0.5rem;
  position: relative;
`;

const PinnedNote = styled.div`
  background: #ebf2fa;
  padding: 1rem;
  border: 1px solid #98a4c4;
  margin-bottom: 1rem;
  position: relative;
`;

const NoteSpacer = styled.div`
  margin-left: 2rem;
  height: 1.2rem;
  border-right: 1px solid #ccc;
  width: 1px;
  display: inline-block;
  margin-bottom: 0.5rem;
`;

const DateSpacer = styled.div`
  margin-right: 2rem;
  font-size: 0.8125rem;
  display: inline-block;
  color: #727781;
`;

const Author = styled.div`
  font-size: 0.8125rem;
  display: inline-block;
  color: #727781;
`;

const TypeSpan = styled.span`
  color: #727781;
`;

const PinContainer = styled.div`
  position: absolute;
  top: 0;
  margin: 1rem;
  right: 0;
`;

const PinButton = styled.div`
  cursor: pointer;
  font-size: 1.3rem;
  color: ${(props) => (props.isPinned ? "#6464e0" : "inherit")};
  &:hover {
    color: #6464e0;
  }
`;

const NoteLoading = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 3;
  display: flex;
  background: rgba(255, 255, 255, 0.7);
`;

// /// <summary>Status Change Type</summary>
// StatusChange = 1,

// /// <summary>Priority Change Type</summary>
// PriorityChange = 2,

// /// <summary>Assignee Change Type</summary>
// AssigneeChange = 3,

// /// <summary>General Comment Type, not associated with any updates to the issue</summary>
// Comment = 4,

const noteTypes = [
  { label: "Status", value: "StatusChange" },
  { label: "Priority", value: "PriorityChange" },
  { label: "Assignee", value: "AssigneeChange" },
  { label: "Comment", value: "Comment" },
];

const ChangeDetail = ({ type, noteDetailValue }) => {
  if (type === "Comment") return null;
  return (
    <>
      <TypeSpan>
        {noteTypes.find((nt) => nt.value === type)?.label ?? ""}:
      </TypeSpan>{" "}
      {noteDetailValue}
    </>
  );
};

//show notes
const RenderNotes = ({ row, notes, pinned, setRowAfterForm, toggle }) => {
  const [{ errors, loading, data }, doSubmit] = useApi();
  useEffect(() => {
    if (data && !errors?.length) {
      setRowAfterForm(data);
      toggle(null);
    }
  }, [data, errors, setRowAfterForm, toggle]);

  const PinNote = (id) => {
    const cleanAssigned = row?.assignees.length
      ? row?.assignees.map((user) => ({
          id: 0,
          userId: user?.value,
        }))
      : [];

    const body = {
      priority: row?.priority,
      assignees: cleanAssigned,
      status: row?.status,
      notify: row?.notify,
      note: null,
      pinnedNoteId: id,
    };

    doSubmit({
      query: `/Issues/${row?.id}`,
      method: "POST",
      body: body,
    });
  };

  return (
    <div style={{ marginTop: "1.5rem" }}>
      {loading && (
        <NoteLoading>
          <SplashLoader text="Updating Resolution" />
        </NoteLoading>
      )}
      {errors ? <ErrorMessages errors={errors} /> : null}
      {pinned && (
        <>
          <h3>Identified Solution</h3>
          <PinnedNote>
            {pinned?.noteDetailValue === "Resolved" ? (
              <PinContainer>
                <PinButton
                  onClick={() => PinNote(0)} //unassign
                  title="Remove as Identified Solution"
                  isPinned={true}
                >
                  <MdDoneAll />
                </PinButton>
              </PinContainer>
            ) : null}
            <ChangeDetail
              type={pinned?.type}
              noteDetailValue={pinned?.noteDetailValue}
            />

            <p style={{ marginTop: "1rem" }}>{pinned?.note}</p>

            <div>
              <DateSpacer>
                {pinned?.createdOn
                  ? formatTime(new Date(pinned?.createdOn))
                  : null}
              </DateSpacer>
              <Author>{pinned?.displayName}</Author>
            </div>
          </PinnedNote>
          <div
            style={{
              display: "block",
              marginTop: "1rem",
              marginBottom: "1rem",
              height: "2px",
              borderBottom: "1px solid #ccc",
            }}
          ></div>
        </>
      )}
      <h4 style={{ marginBottom: "1rem" }}>Change Log</h4>
      {notes.map((note, i) => {
        if (note?.id === pinned?.id) {
          return (
            <>
              <PinnedNote>
                {note?.noteDetailValue === "Resolved" ? (
                  <PinContainer>
                    <PinButton
                      onClick={() => PinNote(note?.id)}
                      title="Remove as Identified Solution"
                      isPinned={true}
                    >
                      <MdDoneAll />
                    </PinButton>
                  </PinContainer>
                ) : null}
                <ChangeDetail
                  type={note?.type}
                  noteDetailValue={note?.noteDetailValue}
                />

                <p style={{ marginTop: "1rem" }}>{note?.note}</p>

                <div>
                  <DateSpacer>
                    {note?.createdOn
                      ? formatTime(new Date(note?.createdOn))
                      : null}
                  </DateSpacer>
                  <Author>{note?.displayName}</Author>
                </div>
              </PinnedNote>
              {i + 1 === notes?.length ? null : <NoteSpacer />}
            </>
          );
        } else {
          return (
            <>
              <GenericNote>
                {note?.noteDetailValue === "Resolved" ? (
                  <PinContainer>
                    <PinButton
                      onClick={() => PinNote(note?.id)}
                      title="Mark as Identified Solution"
                    >
                      <MdDoneAll />
                    </PinButton>
                  </PinContainer>
                ) : null}
                <ChangeDetail
                  type={note?.type}
                  noteDetailValue={note?.noteDetailValue}
                />

                <p style={{ marginTop: "1rem" }}>{note?.note}</p>

                <div>
                  <DateSpacer>
                    {note?.createdOn
                      ? formatTime(new Date(note?.createdOn))
                      : null}
                  </DateSpacer>
                  <Author>{note?.displayName}</Author>
                </div>
              </GenericNote>
              {i + 1 === notes?.length ? null : <NoteSpacer />}
            </>
          );
        }
      })}
    </div>
  );
};

export default RenderNotes;
