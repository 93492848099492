import React from "react";
import { Field } from "formik";

import {
  FormControl,
  StyledField,
  ErrorMessage,
} from "../../Form/FormControls";

const DataverseConnection = ({ form }) => {
  return (
    <>
      <h3>Connection Details</h3>
      <FormControl>
        <label>
          Use Advanced Connection String
          <Field
            type="checkbox"
            checked={form.values.advanced}
            name={`advanced`}
            onChange={() => {
              form.setFieldValue(`advanced`, !form.values.advanced);
              form.setFieldValue(`details`, {
                dataverseConnectionString: {
                  connectionString: null,
                  environmentUri: null,
                },
                databaseConnectionString: null,
                azureStorageConnectionString: null,
                sftpConnectionString: null,
                cosmosConnectionString: null,
              });
            }}
          />
        </label>
      </FormControl>

      {form.values.advanced ? (
        <FormControl>
          <StyledField
            name={`details.dataverseConnectionString.connectionString`}
            type="text"
            placeholder="Connection String"
            label="Connection String"
          />
          <ErrorMessage
            name={`details.dataverseConnectionString.connectionString`}
          />
        </FormControl>
      ) : (
        <>
          <FormControl>
            <StyledField
              name={`details.dataverseConnectionString.environmentUri`}
              type="text"
              placeholder="Environment URI"
              label="Environment URI"
            />
            <ErrorMessage
              name={`details.dataverseConnectionString.environmentUri`}
            />
          </FormControl>
        </>
      )}
    </>
  );
};

export default DataverseConnection;
