import React from "react";
import { Field } from "formik";

import {
  FormControl,
  StyledField,
  ErrorMessage,
  StyledSelect,
  Label,
} from "../../Form/FormControls";
import { namingConventions } from "../../../common/formOptions";
import { useApi } from "../../../api/useApi";
import ErrorMessages from "../../../components/Notifications/ErrorMessages";
import { connectionsList } from "../../../api/connectionQueries";

const formatProviderSelect = ({ label, docProviderType }) => {
  return (
    <div style={{ display: "flex", flexDirection: "column", padding: ".5rem" }}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          fontSize: "1.1rem",
          marginBottom: ".3rem",
        }}
      >
        {label}
      </div>
      {docProviderType && (
        <div
          style={{
            opacity: 0.5,
            fontSize: "1rem",
            position: "relative",
            display: "block",
            maxWidth: "100%",
            whiteSpace: "normal",
          }}
        >
          {docProviderType}
        </div>
      )}
    </div>
  );
};

const BlobConnection = ({ form, isEditing }) => {
  // Query for Data Providers
  const queryProviders = `
      query(){
        allDocProviders() {
            displayName
            docProviderType
            enabled
            id
        }
    }
  `;
  // Loading the query on component mount for latest configuration
  const [{ errors: providersErrors, data: providersData }] =
    useApi(queryProviders);

  const providerOptions =
    providersData?.allDocProviders
      ?.filter((pd) => pd.enabled)
      .map((provider) => {
        return {
          label: provider?.displayName,
          value: provider?.id,
          docProviderType: provider?.docProviderType,
        };
      }) ?? [];

  const [{ errors, data }] = useApi(connectionsList, {
    first: 9999, //fix to get all with hc update
    where: {
      enabled: { eq: true },
      id: { neq: -1 },
    },
    order_by: {
      name: "ASC",
    },
  });

  const connectionsOptions =
    data?.availableConnections?.edges?.map((connection) => {
      return {
        label: connection?.node?.name,
        value: connection?.node?.id,
      };
    }) ?? [];

  const isDestinationVisible = [
    "EXTRACT_DOCUMENT",
    "COPY_DOCUMENT",
    "XML_LOAN_LOGIC",
    "CUSTOM_VISION_PREDICTION",
  ].includes(
    providerOptions.find(
      (po) => po.value === form?.values?.ocrConnection?.docProviderId
    )?.docProviderType
  );

  const hiddenDueToXMLCopyExtract = [
    "EXTRACT_DOCUMENT",
    "COPY_DOCUMENT",
    "XML_LOAN_LOGIC",
  ].includes(
    providerOptions.find(
      (po) => po.value === form?.values?.ocrConnection?.docProviderId
    )?.docProviderType
  );

  return (
    <>
      <h3>Blob Connection Details</h3>
      <FormControl>
        <label>
          Use Advanced Connection String
          <Field
            type="checkbox"
            checked={form.values.advanced}
            name={`advanced`}
            onChange={() => {
              form.setFieldValue(`advanced`, !form.values.advanced);
              form.setFieldValue(`details`, {
                azureStorageConnectionString: {
                  connectionString: null,
                  accountName: null,
                  accountKey: null,
                  blobUri: null,
                },
                dataverseConnectionString: null,
                databaseConnectionString: null,
                sftpConnectionString: null,
                cosmosConnectionString: null,
                ftpsConnectionString: null,
              });
            }}
          />
        </label>
      </FormControl>

      {form.values.advanced ? (
        <FormControl>
          <StyledField
            name={`details.azureStorageConnectionString.connectionString`}
            type="text"
            placeholder="Connection String"
            label="Connection String"
          />
          <ErrorMessage
            name={`details.azureStorageConnectionString.connectionString`}
          />
        </FormControl>
      ) : (
        <>
          <FormControl>
            <StyledField
              name={`details.azureStorageConnectionString.accountName`}
              type="text"
              placeholder="Account Name"
              label="Account Name"
            />
            <ErrorMessage
              name={`details.azureStorageConnectionString.accountName`}
            />
          </FormControl>

          <FormControl>
            <StyledField
              name={`details.azureStorageConnectionString.accountKey`}
              type="text"
              placeholder="Account Key"
              label="Account Key"
            />
            <ErrorMessage
              name={`details.azureStorageConnectionString.accountKey`}
            />
          </FormControl>

          <FormControl>
            <StyledField
              name={`details.azureStorageConnectionString.blobUri`}
              type="text"
              placeholder="URI"
              label="URI"
            />
            <ErrorMessage
              name={`details.azureStorageConnectionString.blobUri`}
            />
          </FormControl>
        </>
      )}
      {isEditing ? null : (
        <FormControl>
          <label>
            Connection with OCR Source Location
            <Field
              type="checkbox"
              checked={form.values.ocrEnabled}
              name={`ocr`}
              onChange={() => {
                form.setFieldValue(`ocrEnabled`, !form.values.ocrEnabled);
              }}
            />
          </label>
        </FormControl>
      )}

      {form.values.ocrEnabled && !isEditing ? (
        <>
          <h3>OCR Source Location</h3>
          <p>
            <small>
              <i>
                Documents will be added on the "OCR" tab on the connection once
                established.
              </i>
            </small>
          </p>
          <FormControl>
            <label style={{ cursor: "pointer", marginRight: "1rem" }}>
              <input
                id={`autocreatefromCSV`}
                type="checkbox"
                name={`Automatically create source from CSV outputs`}
                label="Automatically create source from CSV outputs"
                checked={form?.values?.ocrConnection?.isAutoSource}
                onChange={(e) =>
                  form.setFieldValue(
                    `ocrConnection.isAutoSource`,
                    e?.target?.checked
                  )
                }
              />
              Automatically create source from CSV outputs
            </label>
          </FormControl>
          <FormControl>
            <Label>Document Provider</Label>
            <StyledSelect
              className={`react-select-container`}
              classNamePrefix={`react-select`}
              id={`docProviderId`}
              inputId={`docProviderId-input`}
              instanceId={`docProviderId-instance`}
              name={`docProviderId`}
              label="Document Provider"
              options={providerOptions}
              formatOptionLabel={formatProviderSelect}
              placeholder={`Select Document Provider`}
              value={providerOptions.find(
                (nc) => nc?.value === form?.values?.ocrConnection?.docProviderId
              )}
              menuPortalTarget={document.body}
              menuPlacement="auto"
              onChange={(e) =>
                form.setFieldValue(`ocrConnection.docProviderId`, e?.value)
              }
            />
            <ErrorMessage name={`ocrConnection.docProviderId`} />
          </FormControl>

          {isDestinationVisible && (
            <FormControl>
              <Label>Destination Connection</Label>
              <StyledSelect
                className={`react-select-container`}
                classNamePrefix={`react-select`}
                name={`ocrConnection.destinationConnectionId`}
                id={`destinationConnectionId`}
                inputId={`destinationConnectionIdSelect-input`}
                instanceId={`destinationConnectionIdSelect-instance`}
                label="Destination Connection"
                options={connectionsOptions}
                placeholder={`Select Destination Connection`}
                value={connectionsOptions.find(
                  (nc) =>
                    nc?.value ===
                    form?.values?.ocrConnection?.destinationConnectionId
                )}
                menuPortalTarget={document.body}
                menuPlacement="auto"
                onChange={(e) =>
                  form.setFieldValue(
                    `ocrConnection.destinationConnectionId`,
                    e?.value
                  )
                }
              />
            </FormControl>
          )}

          <FormControl>
            <StyledField
              name={`ocrConnection.containerName`}
              type="text"
              placeholder="Container Name"
              label="Container Name"
            />
            <ErrorMessage name={`ocrConnection.containerName`} />
          </FormControl>

          <FormControl>
            <StyledField
              name={`ocrConnection.inputPath`}
              type="text"
              placeholder="Input Path"
              label="Input Path"
            />
            <ErrorMessage name={`ocrConnection.inputPath`} />
          </FormControl>

          {!hiddenDueToXMLCopyExtract && (
            <>
              <FormControl>
                <Label>File Naming Convention</Label>
                <StyledSelect
                  className={`react-select-container`}
                  classNamePrefix={`react-select`}
                  name={`ocrConnection.namingConvention`}
                  id={`namingConvention`}
                  inputId={`namingConventionSelect-input`}
                  instanceId={`namingConventionSelect-instance`}
                  label="Naming Convention"
                  options={namingConventions}
                  placeholder={`Select Convention`}
                  value={namingConventions.find(
                    (nc) =>
                      nc?.value ===
                      form?.values?.ocrConnection?.namingConvention
                  )}
                  menuPortalTarget={document.body}
                  menuPlacement="auto"
                  onChange={(e) =>
                    form.setFieldValue(
                      `ocrConnection.namingConvention`,
                      e?.value
                    )
                  }
                />
              </FormControl>
            </>
          )}

          {providersErrors ? <ErrorMessages errors={providersErrors} /> : null}
          {errors ? <ErrorMessages errors={errors} /> : null}
        </>
      ) : null}
    </>
  );
};

export default BlobConnection;
