import React, { useEffect, useState, useCallback } from "react";
import Spacer from "../../Layout/Spacer";
import { Formik } from "formik";
import {
  FormControl,
  StyledField,
  FormActions,
  ErrorMessage,
  StyledSelect,
  Label,
} from "../../Form/FormControls";
import Button from "../../Button";
import ErrorMessages from "../../Notifications/ErrorMessages";
import Spinner from "../../Loaders/Spinner";
import { MdDelete } from "react-icons/md";
import {
  setAlert,
  updateAlertEnableFlag,
  updateAlert,
} from "../../../api/alertMutations";
import { useApi } from "../../../api/useApi";
import { filterDisabled } from "../../../common/helpers/util";
import SortTable from "../../Table/SortTable";
import Modal from "../../Modal";
import styled from "styled-components/macro";
import bcalogo from "../../../assets/images/BasecapIconWhite.png";
import * as Yup from "yup";
import { connectionAlerts } from "../../../api/connectionQueries";
import SplashLoader from "../../Loaders/SplashLoader";
import TableButton from "../../Button/TableButton";
import debounce from "lodash.debounce";
import { useApi as useApiRedux } from "../../../api/useApiRedux";

const UserImage = styled.div`
border-radius: 50%;
height: 20px;
width: 20px;
border: 2px solid ${(props) => props.theme.menuText};
display: block;
background-image: url(${(props) =>
  props.profilePhoto
    ? "data:image/png;base64," + props.profilePhoto
    : bcalogo});
background-position: center;
background-repeat: no-repeat;
background-size: 20px};
margin-right: .5rem;
`;

const UserContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
`;

const SmallUser = ({ user }) => {
  return (
    <UserContainer>
      <UserImage profilePhoto={user.profilePhoto} title={user.displayName} />
    </UserContainer>
  );
};

const AlertsForm = ({ apiData, getUpdatedSource, showForm }) => {
  const alerts = apiData?.alerts;
  let isEditing = showForm?.id
    ? alerts.find((alert) => alert.id === showForm.id)
    : null;

  const connectionCheckAlertExists = alerts.find(
    (a) => a.alertType === "CONNECTION_CHECK_FAILURE"
  );

  const alertTypes = [
    {
      value: "CONNECTION_CHECK_FAILURE",
      label: "Connection Credentials Invalidated",
      disabled: connectionCheckAlertExists && !isEditing,
    },
  ];

  const [
    {
      loading: createAlertLoading,
      errors: createAlertErrors,
      data: createAlertData,
    },
    createAlert,
  ] = useApi();

  //callback
  const updateMeta = (values) => {
    let variables;
    const connectionId = (apiData && apiData.id) || null;
    if (isEditing) {
      variables = {
        alertId: isEditing.id,
        alertInfo: {
          sourceId: connectionId,
          sourceType: "CONNECTION",
          type: values?.type?.value,
          threshold: values?.threshold,
          boolFlag: values?.boolFlag,
          instances: [
            {
              // hard coding to email-alerts only
              notificationType: "EMAIL",
              members: values.members.map((member) => {
                if (member.type === "group") {
                  return { workGroupId: member.value };
                } else {
                  return { userId: member.value };
                }
              }),
            },
          ],
        },
      };
      createAlert({ query: updateAlert, variables });
    } else {
      variables = {
        alert: {
          sourceId: connectionId,
          sourceType: "CONNECTION",
          type: values?.type?.value,
          threshold: values?.threshold,
          boolFlag: values?.boolFlag,
          instances: [
            {
              // hard coding to email-alerts only
              notificationType: "EMAIL",
              members: values?.members.map((member) => {
                if (member.type === "group") {
                  return { workGroupId: member.value };
                } else {
                  return { userId: member.value };
                }
              }),
            },
          ],
        },
      };
      createAlert({ query: setAlert, variables });
    }
  };

  useEffect(() => {
    if (createAlertData && !createAlertErrors) {
      getUpdatedSource();
    }
  }, [createAlertData, createAlertErrors, getUpdatedSource]);

  const [stateUsers, setStateUsers] = useState();

  const [
    { errors: getUserErrors, loading: loadingUsers, data: usersData },
    getUsers,
  ] = useApiRedux();

  useEffect(() => {
    if (usersData) {
      const userOptions = usersData?.data?.map((ug) => {
        if (ug.type === 1) {
          return {
            label: ug?.emailAddress,
            value: ug?.userId,
            type: "user",
          };
        } else {
          return {
            label: ug?.displayName,
            value: ug?.workGroupId,
            type: "group",
          };
        }
      });

      setStateUsers(userOptions);
    } else {
      setStateUsers([]);
    }
  }, [usersData]);

  const [inputText, setInputText] = React.useState("");
  const [searchText, setSearchText] = React.useState("");

  useEffect(() => {
    if (searchText) {
      getUsers({
        query: `/search/usersworkgroups?query=${searchText}`,
        method: "GET",
      });
    } else {
      setStateUsers([]);
    }
  }, [searchText, getUsers, setStateUsers]);

  const setSearchTextDebounced = React.useRef(
    debounce((searchText) => setSearchText(searchText), 500)
  ).current;

  const handleInputChangePrimary = (inputText, event) => {
    // prevent outside click from resetting inputText to ""
    if (event.action !== "input-blur" && event.action !== "menu-close") {
      setInputText(inputText);
      setSearchTextDebounced(inputText);
    }
  };
  return (
    <>
      <Spacer />
      <Formik
        initialValues={{
          type: isEditing
            ? alertTypes.find((at) => at.value === isEditing.alertType)
            : null,
          // hard coded to first instance as currently only using email
          members: isEditing
            ? isEditing.instances[0]?.members.map((ug) => {
                if (ug?.user) {
                  return {
                    label: ug?.user?.emailAddress,
                    value: ug?.userId,
                    type: "user",
                  };
                } else {
                  return {
                    label: ug?.group?.displayName,
                    value: ug?.workGroupId,
                    type: "group",
                  };
                }
              })
            : null,
          threshold: isEditing ? isEditing.threshold : null,
          boolFlag: isEditing ? isEditing.boolFlag : null,
        }}
        enableReinitialize={true}
        validationSchema={Yup.object().shape({
          type: Yup.mixed().required("Required"),
          members: Yup.array().min(1).required("Required"),
        })}
        validateOnMount={true}
        onSubmit={(values, actions) => {
          updateMeta(values);
        }}
      >
        {(props) => {
          return (
            <form onSubmit={props.handleSubmit}>
              <FormControl>
                <Label>Alert Type</Label>
                <StyledSelect
                  className={`react-select-container`}
                  classNamePrefix={`react-select`}
                  name={`type`}
                  id={`alertType`}
                  inputId={`alertType-input`}
                  instanceId={`alertType-instance`}
                  label="Alert Type"
                  options={alertTypes}
                  isOptionDisabled={(option) => option.disabled}
                  placeholder={`Alert Type`}
                  value={props.values.type}
                  menuPortalTarget={document.body}
                  menuPlacement="auto"
                  onChange={(e) => props.setFieldValue(`type`, e)}
                />
                <ErrorMessage name={`type`} />
              </FormControl>

              <FormControl>
                <Label>Members</Label>
                <StyledSelect
                  className={`react-select-container`}
                  classNamePrefix={`react-select`}
                  name={`members`}
                  id={`members`}
                  inputId={`members-input`}
                  instanceId={`members-instance`}
                  label="Members"
                  options={stateUsers}
                  placeholder={`Users and Groups`}
                  value={props.values?.members}
                  menuPlacement="auto"
                  menuPortalTarget={document.body}
                  isMulti
                  inputValue={inputText}
                  isLoading={loadingUsers}
                  onChange={(e) => props.setFieldValue(`members`, e)}
                  onInputChange={handleInputChangePrimary}
                />
                <ErrorMessage name={`members`} />
                {getUserErrors ? (
                  <ErrorMessages errors={getUserErrors} />
                ) : null}
              </FormControl>

              {createAlertErrors ? (
                <ErrorMessages errors={createAlertErrors} />
              ) : null}

              <FormActions>
                <Button
                  type="submit"
                  disabled={createAlertLoading || !props.isValid}
                >
                  {createAlertLoading ? (
                    <Spinner />
                  ) : isEditing ? (
                    "Edit Alert"
                  ) : (
                    "Add Alert"
                  )}
                </Button>
              </FormActions>
            </form>
          );
        }}
      </Formik>
    </>
  );
};

const AlertTable = ({ apiData, setShowForm, getUpdatedSource }) => {
  const [showConfirm, setShowConfirm] = useState(false);
  const [alertToDelete, setAlertToDelete] = useState(null);

  useEffect(() => {
    if (alertToDelete) {
      setShowConfirm(true);
    } else {
      setShowConfirm(false);
    }
  }, [alertToDelete]);

  const [
    { loading: removeAlertDataLoading, data: removeAlertData },
    removeAlertApi,
  ] = useApi();

  //callback for removing a row
  const removeAlert = useCallback(
    (alert) => {
      const variables = {
        alertId: alert?.id,
        type: alert?.type,
        enabled: false,
      };
      removeAlertApi({ query: updateAlertEnableFlag, variables });
    },
    [removeAlertApi]
  );

  const [alerts, setAlerts] = useState(filterDisabled(apiData?.alerts ?? []));

  //handle remove rule update
  useEffect(() => {
    if (removeAlertData) {
      //set filtered rule
      setAlerts((prevAlerts) =>
        prevAlerts.filter(
          (alert) => alert.id !== removeAlertData?.updateAlertEnableFlag.id
        )
      );
      setAlertToDelete(null);
      getUpdatedSource();
    }
  }, [removeAlertData, getUpdatedSource]);

  //set rules into local state so we can mutate on remove
  useEffect(() => {
    if (apiData?.alerts) {
      setAlerts(filterDisabled(apiData?.alerts ?? []));
    }
  }, [apiData]);

  const columnsData = [
    {
      Header: "Type",
      id: "type",
      width: 120,
      accessor: (d) => d.alertType,
    },
    {
      Header: "Members",
      id: "Members",
      Cell: ({ row: { original } }) => {
        const members = original?.instances[0]?.members ?? [];
        return (
          <React.Fragment key={`alert-members-${original.id}`}>
            {members.map((member, i) => {
              const isGroup = member?.workGroupId;
              return (
                <React.Fragment key={i}>
                  {isGroup ? (
                    member?.group?.displayName
                  ) : (
                    <>
                      <SmallUser user={member.user} />
                    </>
                  )}
                </React.Fragment>
              );
            })}
          </React.Fragment>
        );
      },
    },
    {
      Header: " ",
      id: "actions",
      width: 220,
      sortable: false,
      Cell: ({ row: { original } }) => {
        return (
          <React.Fragment key={`alert-actions-${original.id}`}>
            <TableButton
              list="true"
              onClick={() => setShowForm({ id: original.id })}
              type="button"
            >
              Edit
            </TableButton>
            <TableButton
              list="true"
              danger
              onClick={() => setAlertToDelete(original)}
              type="button"
            >
              <MdDelete />
            </TableButton>
          </React.Fragment>
        );
      },
    },
  ];

  return (
    <>
      {showConfirm ? (
        <Modal
          title={`Confirm Alert Removal`}
          hide={() => setAlertToDelete(null)}
        >
          <p>Are you sure you wish to remove this Alert?</p>
          <div>
            <Button
              type="button"
              list="true"
              disabled={removeAlertDataLoading}
              danger
              onClick={() => removeAlert(alertToDelete)}
            >
              {removeAlertDataLoading ? <Spinner /> : "Yes"}
            </Button>
            <Button
              type="button"
              disabled={removeAlertDataLoading}
              onClick={() => {
                setAlertToDelete(null);
              }}
            >
              Cancel
            </Button>
          </div>
        </Modal>
      ) : null}

      <SortTable data={alerts} columns={columnsData} />
    </>
  );
};

const ConnectionAlerts = ({ sourceId }) => {
  const [{ loading, errors, data }, getSource] = useApi(connectionAlerts, {
    id: Number(sourceId),
  });
  const apiData = data?.connection;

  const getUpdatedSource = useCallback(() => {
    getSource({
      query: connectionAlerts,
      variables: { id: Number(sourceId) },
    });
    setShowForm(false);
  }, [sourceId, getSource]);

  const [showForm, setShowForm] = useState(false);

  if (loading) return <SplashLoader text={"Loading Alerts"} />;
  if (errors) return <ErrorMessages errors={errors} />;

  return (
    <>
      {showForm ? (
        <>
          <div>
            <Button danger onClick={() => setShowForm(false)}>
              Cancel
            </Button>
          </div>
          <AlertsForm
            apiData={apiData}
            getUpdatedSource={getUpdatedSource}
            showForm={showForm}
          />
        </>
      ) : (
        <>
          <div>
            <Button onClick={() => setShowForm((prevState) => !prevState)}>
              Create Alert
            </Button>
          </div>
          <AlertTable
            apiData={apiData}
            setShowForm={setShowForm}
            getUpdatedSource={getUpdatedSource}
          />
        </>
      )}
    </>
  );
};

export default ConnectionAlerts;
