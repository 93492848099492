import React from "react";
import styled from "styled-components/macro";
import _ from "lodash";
import {
  BusinessRuleVariables,
  BusinessRuleVariableOperations,
  ColumnModifiers,
} from "../Forms/Rules/buildingBlocks";
import { patterns } from "../Forms/Rules/patterns";
import ReactTooltip from "react-tooltip";
import { MdInfoOutline, MdWarning } from "react-icons/md";
import { FaDatabase } from "react-icons/fa";
import RenderOperation from "./OperationHelper";

const FragmentContainer = styled.div`
  display: inline-flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-left: ${(props) => (props.isAfterBreakPoint ? "2rem" : "0")};
  border: 1px dashed ${(props) => props.theme.surfaceAlt};
  border-left: 4px solid
    ${(props) => (props.hasError ? "red" : props.theme.onSecondarySurface)};
  padding: 1em;
  align-items: center;
  margin-bottom: 1rem;
`;

const FragmentSourceSection = styled.div`
  flex: 1;
  margin-right: 1em;
  background: rgba(0, 0, 0, 0.03);
  flex-grow: inherit;
  margin-top: 0.5rem;
`;

const FragmentOperationSection = styled.div`
  flex: 1;
  margin-right: 1em;
  flex-grow: 1;
  margin-top: 0.5rem;
`;

const FragmentTargetSection = styled.div`
  flex: 1;
  margin-right: 1em;
  background: rgba(0, 0, 0, 0.03);
  flex-grow: inherit;
  margin-top: 0.5rem;
`;

const FragmentSectionBody = styled.div`
  padding: 0.8em;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  /* max-width: 300px; */
  white-space: inherit;
`;

const RealColumnName = styled.div`
  padding: 8px;
  font-size: 0.8em;
  background: ${(props) => props.theme.surfaceAlt};
  margin-bottom: 5px;
  display: inline-block;
`;
const WarningIcon = styled.div`
  color: orange;
`;
const FragmentSourceInfo = ({ name }) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        marginBottom: ".5rem",
      }}
    >
      <div style={{ marginRight: ".5rem" }}>
        {name ? (
          <FaDatabase />
        ) : (
          <WarningIcon>
            <MdWarning />
          </WarningIcon>
        )}
      </div>{" "}
      {name ? name : "Remote Source Not Found"}
    </div>
  );
};

const FragmentWrapper = ({
  fragment,
  isAfterBreakPoint,
  failure,
  columns,
  mappings,
  calcMappings,
  isCrossTable,
}) => {
  const fragments = mappings ?? [];
  const source = fragment.source;
  const operation = fragment.operation;
  const target = fragment.target;

  const sourceZone = fragments.find(
    (frag) => frag.businessRuleFragmentId === source.id
  );

  let sourceColumn;
  if (sourceZone) {
    sourceColumn = columns?.find((col) => col.id === sourceZone.columnId);
  }

  const targetZone = target
    ? fragments.find((frag) => frag.businessRuleFragmentId === target.id)
    : null;

  const businessOperation = operation?.typeInformation?.[1]?.typeValue;

  let targetColumn;
  if (targetZone) {
    targetColumn = columns?.find((col) => col.id === targetZone.columnId);
  }

  let realSourceData = "null";
  let realTargetData = "null";
  //if we have the column data and the assigned column we can map the real data
  if (failure && sourceZone) {
    const foundSourceColumn =
      failure?.rowData?.data?.Cells[sourceColumn?.ordinal] ?? null;

    if (foundSourceColumn) {
      realSourceData = foundSourceColumn ? String(foundSourceColumn) : "null";
    }
  }

  if (failure && targetZone) {
    const foundTargetColumn =
      failure?.rowData?.data?.Cells[targetColumn?.ordinal] ?? null;

    if (foundTargetColumn) {
      realTargetData = foundTargetColumn ? String(foundTargetColumn) : "null";
    }
  }

  const sourceColumnName = sourceColumn?.fullyQualifiedName?.split(/\|/);
  const targetColumnName = targetColumn?.fullyQualifiedName?.split(/\|/);

  const sourceMod = source.typeInformation[2]?.typeValue
    ? ` (${ColumnModifiers[source.typeInformation[2]?.typeValue - 1]?.label})${
        source.typeInformation[2]?.typeValue === 2
          ? `(Days: ${source.typeInformation[2]?.fragmentValue}) `
          : null
      }`
    : null;

  const targetMod =
    target != null && target.typeInformation[3]?.typeValue
      ? ` (${
          ColumnModifiers[target.typeInformation[3]?.typeValue - 1]?.label
        }) ${
          target.typeInformation[3]?.typeValue === 2
            ? `(Days: ${target.typeInformation[3]?.fragmentValue}) `
            : null
        }`
      : null;

  const isByAmount = operation.typeInformation[2]?.typeValue === 4;
  const isByPercentage = operation.typeInformation[2]?.typeValue === 5;

  let calcMatches;
  if (target?.typeInformation?.[1]?.typeValue === 4) {
    const matches =
      target?.typeInformation?.[1]?.fragmentValue.match(/\[[^\][]*\]/g);

    calcMatches = matches.map((cm, i) => {
      const cleanedString = cm.replace(/[\[\]']+/g, "");
      return cleanedString ? cleanedString : i;
    });
  }

  // currently checking for value on source side, if so we hide it from user
  const isNotCalcValue = source?.typeInformation[1]?.typeValue !== 4;

  return (
    <FragmentContainer
      // hasError={source.id === sourceColumnError}
      isAfterBreakPoint={isAfterBreakPoint}
    >
      {isNotCalcValue && (
        <>
          <FragmentSourceSection>
            <FragmentSectionBody>
              <div>
                {isCrossTable && (
                  <FragmentSourceInfo name={sourceColumnName?.[0]} />
                )}
                <RealColumnName>
                  {sourceColumn && sourceColumn.name
                    ? _.truncate(sourceColumn.name, {
                        length: 45, // maximum 30 characters
                        separator: /,?\.* +/, // separate by spaces, including preceding commas and periods
                      })
                    : source.typeInformation[1].fragmentValue}
                  {sourceMod}
                </RealColumnName>
                <div>{realSourceData && failure ? realSourceData : null}</div>
              </div>
            </FragmentSectionBody>
          </FragmentSourceSection>
          <FragmentOperationSection>
            <FragmentSectionBody>
              <RenderOperation operationDetails={operation} isView={true} />
            </FragmentSectionBody>
          </FragmentOperationSection>
        </>
      )}

      {target ? (
        <FragmentTargetSection>
          <FragmentSectionBody>
            {/* Render Column */}
            <div>
              {target.typeInformation[1].typeValue === 1 ? (
                targetColumn && targetColumn.name ? (
                  <div>
                    {isCrossTable && (
                      <FragmentSourceInfo name={targetColumnName?.[0]} />
                    )}
                    <RealColumnName>
                      {_.truncate(targetColumn.name, {
                        length: 45, // maximum 30 characters
                        separator: /,?\.* +/, // separate by spaces, including preceding commas and periods
                      })}
                      {targetMod}
                    </RealColumnName>
                  </div>
                ) : (
                  <div>
                    {target.typeInformation[1].fragmentValue} {targetMod}
                  </div>
                )
              ) : null}

              {failure ? (
                <div>
                  {target.typeInformation[1].typeValue === 1 && realTargetData
                    ? realTargetData
                    : target.typeInformation[1].typeValue === 1
                    ? "No Data Loaded"
                    : null}
                </div>
              ) : null}

              {/* Render Value */}
              {target.typeInformation[1].typeValue === 2 ? (
                businessOperation === 13 ? (
                  <>
                    {patterns.find(
                      (p) => p.value === target.typeInformation[1].fragmentValue
                    )?.label ? (
                      <div style={{ display: "flex", alignItems: "center" }}>
                        {
                          patterns.find(
                            (p) =>
                              p.value ===
                              target.typeInformation[1].fragmentValue
                          )?.label
                        }
                        <div
                          data-tip={
                            patterns.find(
                              (p) =>
                                p.value ===
                                target.typeInformation[1].fragmentValue
                            )?.description
                          }
                          data-for="patternHelper"
                        >
                          <MdInfoOutline />
                        </div>
                        <ReactTooltip id="patternHelper" type="info">
                          <span>
                            {
                              patterns.find(
                                (p) =>
                                  p.value ===
                                  target.typeInformation[1].fragmentValue
                              )?.description
                            }
                          </span>
                        </ReactTooltip>
                      </div>
                    ) : (
                      target.typeInformation[1].fragmentValue
                    )}
                  </>
                ) : (
                  <>
                    {isByAmount ? "By " : null} {isByPercentage ? "By " : null}
                    {target.typeInformation[1].fragmentValue}
                    {isByPercentage ? "%" : null}
                  </>
                )
              ) : null}
              {/* Render Variable */}
              {target.typeInformation[1].typeValue === 3
                ? BusinessRuleVariables.find(
                    (sv) =>
                      sv.value ===
                      Number(target.typeInformation[1].fragmentValue)
                  ).label
                : null}
              {target.typeInformation[1].typeValue === 3 ? (
                <>
                  {target.typeInformation[2].typeValue ? (
                    <div style={{ marginLeft: ".5rem", color: "orange" }}>
                      {
                        BusinessRuleVariableOperations[
                          target.typeInformation[2].typeValue
                        ].label
                      }
                    </div>
                  ) : null}

                  {target.typeInformation[2].typeValue !== 0 ? (
                    <div style={{ color: "orange" }}>
                      {target.typeInformation[2].fragmentValue}
                    </div>
                  ) : null}
                </>
              ) : null}

              {target.typeInformation[1].typeValue === 4 &&
              calcMatches?.length ? (
                <div>
                  <div style={{ marginBottom: ".5rem" }}>
                    {target.typeInformation[1].fragmentValue}
                  </div>
                  <div style={{ display: "flex" }}>
                    {calcMatches?.map((cm, i) => {
                      const foundMapping = calcMappings?.find(
                        (mapping) =>
                          mapping?.businessRuleFragmentId === target?.id &&
                          mapping?.argument === i
                      );
                      const selectedColumn = columns?.find(
                        (col) => col.id === foundMapping?.columnId
                      );

                      const selectedColumnName =
                        selectedColumn?.fullyQualifiedName?.split(/\|/);
                      return (
                        <div style={{ marginRight: ".5rem" }}>
                          {isCrossTable && (
                            <FragmentSourceInfo
                              name={selectedColumnName?.[0]}
                            />
                          )}
                          <RealColumnName>
                            {_.truncate(selectedColumn?.name, {
                              length: 45, // maximum 30 characters
                              separator: /,?\.* +/, // separate by spaces, including preceding commas and periods
                            })}
                          </RealColumnName>
                        </div>
                      );
                    })}
                  </div>
                </div>
              ) : null}
            </div>
          </FragmentSectionBody>
        </FragmentTargetSection>
      ) : null}
    </FragmentContainer>
  );
};

export default FragmentWrapper;
