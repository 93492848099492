import React from "react";
import {
  Pipeline,
  SegmentVerticalLine,
  VerticalLine,
} from "./CreateFormStyles";
import RenderSegment from "./RenderSegment";
const RenderDependentPipelines = ({
  dependencies,
  pipelines,
  dispatch,
  instanceNames,
  stateSources,
}) => {
  const numberOfPipelines = pipelines.length;
  return (
    <>
      {pipelines.map((pipeline, i) => {
        const segment = dependencies.find((d) => d.uid === pipeline);
        const isLast = numberOfPipelines === i + 1;
        return (
          <Pipeline key={`${segment?.uid}-renderseg`}>
            <RenderSegment
              dependencies={dependencies}
              segment={segment}
              dispatch={dispatch}
              isLast={isLast}
              instanceNames={instanceNames}
              stateSources={stateSources}
            />

            <SegmentVerticalLine>
              <VerticalLine />
            </SegmentVerticalLine>
          </Pipeline>
        );
      })}
    </>
  );
};

export default RenderDependentPipelines;
