import React, { useState } from "react";
import styled from "styled-components/macro";
import { MdAdd } from "react-icons/md";

import Attachments from "./Attachments";
const FragmentContainer = styled.div`
  padding: 1rem;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  border: 1px solid ${(props) => props.theme.surfaceAlt};
`;

const DetailsToggle = styled.div`
  transition: 0.3s all;
  display: inline-block;
  height: 16px;
  width: 16px;
  color: grey;
  transform: ${(props) => (props.showAttachments ? "rotate(45deg)" : "")};
`;

const DetailsToggleWrapper = styled.div`
  padding: 0.5rem;
  background: ${(props) => props.theme.secondarySurface};
  display: flex;
  margin-right: ${(props) => (props.showAttachments ? "1rem" : "")};
  margin-left: 1rem;
  &:hover {
    ${DetailsToggle} {
      transform: ${(props) => (props.showAttachments ? "" : "rotate(90deg)")};
      cursor: pointer;
      color: black;
    }
  }
`;

const AttachmentToggleComponent = ({
  toggleShowAttachments,
  showAttachments,
}) => {
  function handleClicks(e) {
    e.stopPropagation();
    toggleShowAttachments(!showAttachments);
  }
  return (
    <DetailsToggleWrapper
      showAttachments={showAttachments}
      onClick={(e) => handleClicks(e)}
    >
      <DetailsToggle showAttachments={showAttachments}>
        <MdAdd />
      </DetailsToggle>
    </DetailsToggleWrapper>
  );
};

const Conditional = ({ element, dispatch, index, editView }) => {
  const [showAttachments, toggleShowAttachments] = useState(false);

  return (
    <FragmentContainer>
      {element.name}
      {editView && (
        <AttachmentToggleComponent
          showAttachments={showAttachments}
          toggleShowAttachments={toggleShowAttachments}
        />
      )}
      {showAttachments && (
        <Attachments
          index={index}
          dispatch={dispatch}
          showAttachments={showAttachments}
          toggleShowAttachments={toggleShowAttachments}
          type={element.name}
        />
      )}
    </FragmentContainer>
  );
};

export default Conditional;
