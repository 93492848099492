import { allSystemPermissions } from "../../api/auditQueries";
import React, { useState } from "react";
import { useApi } from "../../api/useApi";
import SortTable from "../../components/Table/SortTable";
import Button from "../Button";
import ErrorMessages from "../../components/Notifications/ErrorMessages";
import Modal from "../../components/Modal";
import ExportPermissionsAuditLog from "./ExportPermissionsAuditLog";

const PermissionsLog = () => {
  const [showExport, setShowExport] = useState(false);

  const columnsData = [
    {
      Header: "Entity Name",
      id: "entityName",
      accessor: (d) => d?.entityName,
    },
    {
      Header: "Entity Type",
      id: "entityType",
      accessor: (d) => d?.entityType,
    },
    {
      Header: "Read Only Groups",
      id: "readOnlyGroups",
      accessor: (d) => d?.readOnlyGroups,
      Cell: ({ row: { original } }) => {
        return <>{original.readOnlyGroups?.join(", ")}</>;
      },
    },
    {
      Header: "Elevated Groups",
      id: "elevatedGroups",
      accessor: (d) => d?.elevatedGroups,
      Cell: ({ row: { original } }) => {
        return <>{original.elevatedGroups?.join(", ")}</>;
      },
    },
    {
      Header: "Full Control Groups",
      id: "fullControlGroups",
      accessor: (d) => d?.fullControlGroups,
      Cell: ({ row: { original } }) => {
        return <>{original.fullControlGroups?.join(", ")}</>;
      },
    },
  ];

  //Init Data Fetch
  const [{ errors, data: apiData }] = useApi(allSystemPermissions);

  const allSystemPermissionsData = apiData?.allSystemPermissions ?? [];

  return (
    <>
      {showExport ? (
        <Modal
          title={`Export Permissions Audit`}
          hide={() => setShowExport(false)}
        >
          <ExportPermissionsAuditLog cancel={() => setShowExport(false)} />
        </Modal>
      ) : null}

      <div style={{ display: "flex", alignItems: "center" }}>
        <div style={{ marginLeft: "auto" }}>
          <Button
            type="button"
            title={`Export`}
            list={"true"}
            onClick={() => setShowExport(true)}
          >
            {`Export`}
          </Button>
        </div>
      </div>

      <SortTable data={allSystemPermissionsData} columns={columnsData} />
      {errors ? <ErrorMessages errors={errors} /> : null}
    </>
  );
};

export default PermissionsLog;
