import React, { useReducer, useCallback } from "react";
import Card from "../../components/Card";
import { feeds } from "../../common/paths";
import { MdArrowBack } from "react-icons/md";
import ActionWrapper from "../../components/ActionsWrapper";
import StyledLink from "../../components/StyledLink";
import styled from "styled-components/macro";
import SourceSelect from "./SourceSelect";
import SourceColumnConfirm from "./SourceColumnConfirm";
import StartDataSourceIngress from "./StartDataSourceIngress";
import SourceUniqueColumnConfirm from "./SourceUniqueColumnConfirm";
import { useHistory, useParams } from "react-router-dom";

const TabLink = styled.div`
  font-size: 0.9rem;
  padding: 0.7rem;
  border-left: 4px solid
    ${(props) =>
      props.active ? props.theme.onSecondarySurface : "transparent"};
  text-decoration: none;
  opacity: ${(props) => (props.active ? 1 : 0.5)};
  display: inline-block;
  color: ${(props) => props.theme.onSurface};

  &:hover {
    opacity: ${(props) => (props.disabled ? 0.5 : 1)};
    cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  }
  text-transform: uppercase;
`;

const SubTabLink = styled.div`
  font-size: 0.9rem;
  padding: 0.7rem;
  text-decoration: none;
  opacity: ${(props) => (props.active ? 1 : 0.5)};
  display: inline-block;
  color: ${(props) => props.theme.onSurface};

  &:hover {
    opacity: ${(props) => (props.disabled ? 0.5 : 1)};
    cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  }
  text-transform: uppercase;
`;

const ManageContainer = styled.div`
  display: flex;
`;

const ManageContent = styled.div`
  padding: 1rem;
  flex: 1;

  display: block;
`;
const ManageFeed = ({ state, dispatch, sourceType, sourceId }) => {
  let history = useHistory();

  const selectOutputSource = useCallback(
    (source) => {
      dispatch({ type: "SOURCE_SELECT", payload: source });
    },
    [dispatch]
  );

  switch (state?.step) {
    default:
      return <div>Invalid State</div>;

    case 0:
      return (
        <>
          <h3>Select Source</h3>
          <p>This will determine the column options available to you to map.</p>
          <SourceSelect
            state={state}
            selectSource={selectOutputSource}
            sourceType={sourceType}
          />
        </>
      );

    case 1:
      return (
        <div>
          <SourceColumnConfirm
            sourceId={state?.selectedSourceId}
            dispatch={dispatch}
            hasSourceId={sourceId}
            sourceType={sourceType}
            isSingleSource={true}
          />
        </div>
      );

    case 2:
      return (
        <div>
          <StartDataSourceIngress
            sourceId={state?.selectedSourceId}
            nextStep={() => dispatch({ type: "CHANGE_STATE", payload: 3 })}
          />
        </div>
      );
    case 3:
      return (
        <div>
          <SourceUniqueColumnConfirm
            sourceId={state?.selectedSourceId}
            state={state}
            dispatch={dispatch}
            singleNextStep={() =>
              history.push(
                sourceType === -2
                  ? "/feeds/inputsources/"
                  : "/feeds/outputsources/"
              )
            }
            singleBackStep={() =>
              dispatch({ type: "CHANGE_STATE", payload: 1 })
            }
            sourceType={sourceType}
          />
        </div>
      );
  }
};
const Body = ({ sourceType, sourceId }) => {
  const reducer = (state, action) => {
    switch (action.type) {
      //First State called when a query is passed to the component.
      default:
        return {
          ...state,
        };

      case "SOURCE_SELECT": {
        return {
          ...state,
          step: 1,
          selectedSourceId: action?.payload,
        };
      }

      case "CHANGE_STATE": {
        return {
          ...state,
          step: action?.payload,
        };
      }
    }
  };

  const initialState = {
    selectedSourceId: sourceId,
    step: sourceId ? 1 : 0,
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <ManageContainer>
      <div>
        <ul
          style={{
            borderRight: "1px solid #efefef",
            position: "sticky",
            insetBlockStart: "1rem",
          }}
        >
          <li>
            <TabLink
              active={state?.step <= 5}
              disabled={sourceId}
              onClick={() =>
                sourceId ? null : dispatch({ type: "CHANGE_STATE", payload: 0 })
              }
            >
              {sourceType === -3 ? "Output" : "Input"}
            </TabLink>
            <ul style={{ marginLeft: "1rem" }}>
              <li>
                <SubTabLink
                  disabled={!state?.selectedSourceId}
                  active={state?.step === 1}
                  onClick={() =>
                    state?.selectedSourceId &&
                    dispatch({ type: "CHANGE_STATE", payload: 1 })
                  }
                >
                  Column Settings
                </SubTabLink>
              </li>
              <li>
                <SubTabLink
                  disabled={!state?.selectedSourceId}
                  active={state?.step === 3}
                  onClick={() =>
                    state?.selectedSourceId &&
                    dispatch({ type: "CHANGE_STATE", payload: 3 })
                  }
                >
                  Column Values
                </SubTabLink>
              </li>
            </ul>
          </li>
        </ul>
      </div>

      <ManageContent>
        <ManageFeed
          state={state}
          dispatch={dispatch}
          sourceType={sourceType}
          sourceId={sourceId}
        />
      </ManageContent>
    </ManageContainer>
  );
};

//Actions Section of Widget
function Actions() {
  return (
    <>
      <ActionWrapper>
        <StyledLink title="Back to List" to={feeds()}>
          <MdArrowBack />
        </StyledLink>
      </ActionWrapper>
    </>
  );
}

const ManageSource = ({ sourceType }) => {
  let params = useParams();
  const sourceId = params?.sourceId ? Number(params?.sourceId) : null;

  return (
    <Card
      headless
      body={() => <Body sourceId={sourceId} sourceType={sourceType} />}
      actions={Actions}
    />
  );
};

export default ManageSource;
