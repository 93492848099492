import styled from "styled-components/macro";

export const DataSourceWrapper = styled.div`
  flex: 1;
`;

export const ThresholdButton = styled.div`
  cursor: pointer;
`;

export const AddNewPipelineContainer = styled.div`
  margin-left: auto;
`;

export const AddNewPipelineButton = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 0.75rem;
`;

export const FieldLabel = styled.div`
  font-size: 0.75rem;
  flex: 1;
`;

export const DataSourceHeading = styled.div`
  display: flex;
  font-size: 1rem;
  margin-bottom: 0.5rem;
`;

export const Pipeline = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-right: 1rem;
`;

export const PipelineContainer = styled.div`
  display: flex;
  & ${Pipeline}:last-of-type {
    margin-right: 1rem;
  }
`;

export const Segment = styled.div`
  border: 1px solid rgb(204, 204, 204);
  position: relative;
`;

export const SegmentContainer = styled.div`
  min-width: 300px;
`;

export const SegmentAddControlContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-end;
  position: relative;
  height: 22px;
`;

export const SegmentAddControlContainerBottom = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  position: relative;
  height: 22px;
`;

export const SegmentAdd = styled.div`
  position: absolute;
  transition: all ${(props) => props.theme.transitionSpeed} linear;
  height: 20px;
  width: 45px;
  background: ${(props) => props.theme.onSurfaceLight};
  border-radius: 60px 60px 0 0;
  color: ${(props) => props.theme.onSurfaceLightSecondary};
  display: flex;
  justify-content: center;
  line-height: 1.4;
  font-weight: bold;
  cursor: pointer;
  align-items: center;
  &:hover {
    opacity: 0.5;
    height: 22px;
    width: 47px;
    border-radius: 62px 62px 0 0;
  }
`;

export const SegmentAddBottom = styled.div`
  position: absolute;
  transition: all ${(props) => props.theme.transitionSpeed} linear;
  height: 20px;
  width: 45px;
  background: ${(props) => props.theme.onSurfaceLight};
  border-radius: 0 0 60px 60px;
  color: ${(props) => props.theme.onSurfaceLightSecondary};
  display: flex;
  justify-content: center;
  line-height: 1.4;
  font-weight: bold;
  cursor: pointer;
  align-items: center;
  &:hover {
    opacity: 0.5;
    height: 22px;
    width: 47px;
    border-radius: 0 0 62px 62px;
  }
`;

export const SegmentAddRight = styled.div`
  position: absolute;
  transition: all ${(props) => props.theme.transitionSpeed} linear;
  height: 38px;
  width: 17px;
  background: ${(props) => props.theme.onSurfaceLight};
  border-radius: 0 60px 60px 0;
  color: ${(props) => props.theme.onSurfaceLightSecondary};
  display: flex;
  justify-content: center;
  line-height: 1.4;
  font-weight: bold;
  cursor: pointer;
  right: -17px;
  top: 50%;
  align-items: center;
  margin-top: -30px;
  &:hover {
    height: 40px;
    opacity: 0.5;
    margin-top: -31px;
    border-radius: 0 62px 62px 0;
  }
`;

export const SegmentHeader = styled.div`
  display: flex;
  padding: 0.5rem;
  border-bottom: 1px solid rgb(204, 204, 204);
`;

export const SegmentBody = styled.div`
  padding: 0.5rem;
`;

export const RemoveDataSourceBtn = styled.div`
  cursor: pointer;
  margin-top: ${(props) => (props.isLastSource ? "0" : "1.2rem")};
`;

export const DataSourceActions = styled.div`
  margin-left: 0.5rem;
  display: flex;
  flex-direction: column;
`;

function showDisplayBlock(width, count) {
  // we want min width of source ot be 300px for flex state
  const minWidth = count * 300;
  if (width < minWidth) {
    return false;
  } else {
    return true;
  }
}

export const DataSourceContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: ${(props) => (props.isLastSource ? "flex-end" : "center")};
  margin-right: ${(props) =>
    showDisplayBlock(props.itemWidth, props.sourceCount) ? "1rem" : "0"};
  margin-bottom: ${(props) =>
    showDisplayBlock(props.itemWidth, props.sourceCount) ? "0" : "1rem"};
`;

export const FormContainers = styled.div`
  display: ${(props) =>
    showDisplayBlock(props.itemWidth, props.sourceCount) ? "flex" : "block"};
  background: #f7f7f7;
  padding: 0.8rem;
  margin-bottom: 0.8rem;
  border-radius: 0.4rem;
  & ${DataSourceContainer}:last-of-type {
    margin-right: 0;
    margin-bottom: 0;
  }
`;

export const ThresholdContainer = styled.div`
  margin-left: "auto";
  font-size: 0.65rem;
`;

export const SegmentVerticalLine = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
  min-height: 1.5rem;
  position: relative;
  margin-top: auto;
`;

export const VerticalLine = styled.div`
  flex: 1;
  width: 1px;
  display: block;
  border-right: 1px solid #ccc;
  position: absolute;
  top: 0;
  bottom: -22px;
`;

export const WorkflowDetails = styled.div`
  display: flex;
  border-bottom: 1px solid rgb(204, 204, 204);
  margin-bottom: 1rem;
  padding: 1rem;
  align-items: center;
`;

export const SmallText = styled.div`
  font-size: 0.8125rem;
  margin-bottom: 1rem;
  padding-left: 0.5rem;
`;

export const CheckBoxSuccess = styled.div`
  font-size: 4rem;
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
  color: ${(props) =>
    props.workflowProcess
      ? props.workflowProcess?.pass
        ? "#009fd4"
        : "#f87e7e"
      : "#ccc"};
`;

export const CheckBoxSuccessMsg = styled.div`
  font-size: 2rem;
  font-family: "Source Sans Pro Semibold";
  margin-bottom: 1.5rem;
  display: flex;
  justify-content: center;
`;
