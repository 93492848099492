// Login
export const loginQuery = /* GraphQL */ `
  mutation($emailAddress: String!, $authToken: String!) {
    login(emailAddress: $emailAddress, authToken: $authToken)
  }
`;

// Set Users Roles
export const setUsersRole = /* GraphQL */ `
  mutation($userAccount: PlatformUserInputModelInput!) {
    setUsersRole(userAccount: $userAccount) {
      id
      role
    }
  }
`;
