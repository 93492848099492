import { useContext } from "react";
import { SocketContext } from "../contexts/useSubscriptions";

const UseConnectionBrowse = () => {
  const { browseNotification, setBrowseNotification } =
    useContext(SocketContext);

  if (
    browseNotification &&
    browseNotification.payload &&
    browseNotification.payload
  ) {
    return { browseNotification, setBrowseNotification };
  }

  return { setBrowseNotification };
};

export default UseConnectionBrowse;
