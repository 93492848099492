// Create Category
export const createTag = /* GraphQL */ `
  mutation ($tag: TagInputModelInput!) {
    createTag(tag: $tag) {
      id
      name
      description
    }
  }
`;

// Delete Category
export const deleteTag = /* GraphQL */ `
  mutation ($id: Long!) {
    deleteTag(id: $id) {
      id
    }
  }
`;

// Create Category
export const updateTagQuery = /* GraphQL */ `
  mutation ($tag: TagUpdateInputModelInput!) {
    updateTag(tag: $tag) {
      id
    }
  }
`;

// Set Tag Instances
export const setTagInstances = /* GraphQL */ `
  mutation ($remoteObjectId: Long!, $tagIds: [Long!]!, $type: TagTypes!) {
    setTagInstances(
      remoteObjectId: $remoteObjectId
      tagIds: $tagIds
      type: $type
    )
  }
`;
