import React, { useState } from "react";
import {
  Button,
  Box,
  Typography,
  Tab,
  Tabs,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import { MdExpandMore as ExpandMoreIcon, MdEdit } from "react-icons/md";
import RecordFinder from "./widgetTemplates/RecordFinder";
import ReportSummary from "./widgetTemplates/ReportSummary";
import PolicyProfile from "./widgetTemplates/PolicyProfile";
import DataSourceScore from "./widgetTemplates/DataSourceScore";
import DataSourceList from "./widgetTemplates/DataSourceList";

// Map widget names to their respective components
const widgetComponents = {
  "Dynamic View": RecordFinder,
  "Report Summary": ReportSummary,
  "Sources List": DataSourceList,
  "Policy Profile": PolicyProfile,
  // Source Report Score
  "Source Report: Score": DataSourceScore,
};

// Helper function to save to local storage
const saveToLocalStorage = (workspaces) => {
  localStorage.setItem("workspaces", JSON.stringify(workspaces));
};

const PreviewComponent = ({
  data,
  selectedWidget,
  onEdit,
  setWorkspaces,
  handleCloseAddWidget,
  workspaceId,
  setWorkspace,
}) => {
  const [tabIndex, setTabIndex] = useState(0);
  const [showDebug, setShowDebug] = useState(false);
  const WidgetComponent = widgetComponents[selectedWidget] || null;

  const toggleDebug = () => {
    setShowDebug((prev) => !prev);
  };

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  const handleSave = () => {
    const existingWorkspaces =
      JSON.parse(localStorage.getItem("workspaces")) || [];
    const widgetId = data.id || `widget_${Date.now()}`;

    const defaultWidth = data?.defaultWidth || 4;
    const defaultHeight = data?.defaultHeight || 30;

    const workspaceIndex = existingWorkspaces.findIndex(
      (ws) => ws.id === workspaceId
    );

    if (workspaceIndex !== -1) {
      const widgetIndex = existingWorkspaces[
        workspaceIndex
      ].configuration.widgets.findIndex((w) => w.id === widgetId);

      if (widgetIndex !== -1) {
        existingWorkspaces[workspaceIndex].configuration.widgets[widgetIndex] =
          {
            ...existingWorkspaces[workspaceIndex].configuration.widgets[
              widgetIndex
            ],
            parameters: { ...data, id: widgetId },
            x: data.x,
            y: data.y,
            width: Number(data.width) || defaultWidth,
            height: Number(data.height) || defaultHeight,
            sectionId: data.sectionId,
          };
      } else {
        existingWorkspaces[workspaceIndex].configuration.widgets.push({
          id: widgetId,
          order:
            existingWorkspaces[workspaceIndex].configuration.widgets.length + 1,
          name: data.widgetName,
          type: selectedWidget,
          parameters: { ...data, id: widgetId },
          x: data.x || 0,
          y:
            data.y ||
            existingWorkspaces[workspaceIndex].configuration.widgets.length,
          width: Number(data.width) || defaultWidth,
          height: Number(data.height) || defaultHeight,
          sectionId: data.sectionId,
        });
      }

      saveToLocalStorage(existingWorkspaces);
      setWorkspaces(existingWorkspaces);

      // Update only the necessary part of the workspace state
      setWorkspace((prev) => ({
        ...prev,
        configuration: {
          ...prev.configuration,
          widgets: existingWorkspaces[workspaceIndex].configuration.widgets,
        },
      }));
    }

    handleCloseAddWidget();
  };

  return (
    <Box
      sx={{
        padding: 2,
        border: "1px solid #ccc",
        borderRadius: 1,
        ml: 2,
        backgroundColor: "#f5f5f5",
        flex: 1,
      }}
    >
      <Typography variant="h6">Widget Preview</Typography>
      {WidgetComponent && <WidgetComponent data={data} />}

      {showDebug && (
        <>
          <Tabs
            value={tabIndex}
            onChange={handleTabChange}
            aria-label="debug tabs"
          >
            <Tab label="Props" />
          </Tabs>
          {tabIndex === 0 && data && (
            <TableContainer component={Paper} sx={{ marginTop: 2 }}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <strong>Field Name</strong>
                    </TableCell>
                    <TableCell>
                      <strong>Value</strong>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Object.entries(data).map(([key, value]) => (
                    <TableRow key={key}>
                      <TableCell>{key}</TableCell>
                      <TableCell>{value?.toString()}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </>
      )}

      <Box sx={{ display: "flex" }}>
        <Button
          variant="contained"
          color="primary"
          onClick={toggleDebug}
          sx={{
            marginTop: 2,
            backgroundColor: "#ccc",
            "&:hover": {
              backgroundColor: "#ddd",
            },
          }}
        >
          Toggle Debug
        </Button>

        <Button
          variant="contained"
          color="primary"
          onClick={handleSave}
          sx={{
            marginTop: 2,
            backgroundColor: "rgb(18, 17, 53)",
            "&:hover": {
              backgroundColor: "rgba(18, 17, 53, 0.8)",
            },
            marginLeft: "auto",
          }}
        >
          Save
        </Button>
      </Box>
      {onEdit && (
        <Button
          variant="contained"
          color="secondary"
          onClick={onEdit}
          sx={{
            marginTop: 2,
            marginLeft: 2,
            backgroundColor: "rgb(18, 17, 53)",
            "&:hover": {
              backgroundColor: "rgba(18, 17, 53, 0.8)",
            },
          }}
        >
          <MdEdit />
        </Button>
      )}
    </Box>
  );
};

export default PreviewComponent;
