import React, { Component } from "react";
import styled, { keyframes } from "styled-components";

const rotate360 = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

const Rotate = styled.div`
  animation: ${rotate360} 1s linear infinite;
  box-sizing: border-box;
  width: 1rem;
  height: 1rem;
  border-radius: 100%;
  border: 2px solid ${(props) => props.theme.secondarySurface};
  border-top-color: ${(props) => props.theme.onSecondarySurface};
`;

export default class Spinner extends Component {
  render() {
    return <Rotate title="Loading" {...this.props} />;
  }
}
