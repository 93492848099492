function expand(str, val = {}) {
  const arr = str.split(".");

  return arr.reduceRight((acc, currentValue, index) => {
    const isLast = index === arr?.length - 1;
    return {
      [currentValue]: isLast
        ? {
            contains: acc,
          }
        : acc,
    };
  }, val);
}

export default function getFilteredObject(filterBy) {
  const sortedObject = filterBy ? expand(filterBy?.id, filterBy?.text) : null;

  return sortedObject;
}
