import React, { useState } from 'react';
import theme from '../../theme';

export const ThemeContext = React.createContext();

const CurrentThemeProvider = props => {
  const currentStoredTheme = window.localStorage.getItem('theme');

  const [currentTheme, setCurrentTheme] = useState(
    currentStoredTheme
      ? {
          name: currentStoredTheme,
          selectedTheme: theme[currentStoredTheme]
        }
      : {
          name: 'light',
          selectedTheme: theme.light
        }
  );

  const toggleTheme = () => {
    if (currentTheme.name === 'dark') {
      window.localStorage.setItem('theme', 'light');
      setCurrentTheme({ name: 'light', selectedTheme: theme.light });
    } else {
      window.localStorage.setItem('theme', 'dark');
      setCurrentTheme({ name: 'dark', selectedTheme: theme.dark });
    }
  };

  return (
    <ThemeContext.Provider
      value={{
        currentTheme,
        toggleTheme
      }}
    >
      {props.children}
    </ThemeContext.Provider>
  );
};

export default CurrentThemeProvider;
