import React, { useContext, useState, useEffect, useRef } from "react";
import { withRouter, Link } from "react-router-dom";
import styled from "styled-components/macro";
import { AuthContext } from "../../contexts/AuthContext";
import { NavContext } from "../Layout/NavStateProvider";
import { welcome } from "../../common/paths";
import { MdMenu, MdExitToApp, MdClose, MdLibraryBooks } from "react-icons/md";
import { FaDatabase } from "react-icons/fa";
import BCALogo from "../Logos/BCALogo";
import UserMenuItem from "../Layout/Header/UserMenuItem";
import ProfileImage from "../ProfileImage";
import { RiPushpinFill } from "react-icons/ri";
import { PinContext } from "../../contexts/PinContext";
import PinButton from "../Pin/PinButton";
import { useApi } from "../../api/useApi";
import StyledLink from "../../components/StyledLink";
import * as paths from "../../common/paths";
import NotificationsBar from "../../views/DataSourcesPage/NotificationsBar";

const UserMenuButtonNoLink = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  color: ${(props) => props.theme.onSecondarySurface};
  border: 0;
  padding: 0.5em;
  border-radius: 4px;
  font-size: 1em;
  width: 100%;
  text-decoration: none;
  &:hover {
    cursor: pointer;
    color: ${(props) => props.theme.menuText};
    background: ${(props) => props.theme.menuHoverActive};
  }
`;

const MenuIcon = styled.div`
  font-size: 1.2em;
  margin-right: ${(props) => (props.menuState ? ".5rem" : "")};
  color: ${(props) => props.theme.menuText};
  opacity: 0.5;
  padding-top: 0.1rem;
`;

const UserNav = styled.div`
  margin-top: auto;

  text-align: center;
  padding-top: 1rem;
`;

const BottomSection = styled.div`
  margin-top: auto;
  border-top: 1px solid ${(props) => props.theme.onSecondarySurface};
  text-align: center;
  padding-top: 1rem;
`;

const UserProfilePhotoContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
`;

const LogoWrap = styled(BCALogo)`
  width: 100%;
`;

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 1rem;
  padding-right: 1rem;
  margin-bottom: 1rem;
`;

const MenuToggle = styled.div`
  display: block;
  color: ${(props) =>
    props.state ? props.theme.menuText : props.theme.menuText};
  font-size: 1.5rem;
  text-align: center;
  margin-left: ${(props) => (props.menuState ? "auto" : "")};
  cursor: pointer;
`;

const VersionWrapper = styled.div`
  position: relative;
  font-size: 0.6em;
  text-align: center;
  bottom: 0;
  color: ${(props) => props.theme.menuText};
`;

const NavMenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 20px;
  padding-bottom: 20px;
  width: 100%;
  height: 100%;
`;

const NavContainer = styled.div`
  display: "flex";
  visibility: visible;
  flex-direction: column;
  position: fixed;
  color: ${(props) => props.theme.menuText};
  background: ${(props) => props.theme.headerNav};
  z-index: 3;
  width: ${(props) => (props.menuState ? "200px" : "60px")};
  transition: all 300ms;
  height: calc(100vh);
  body.home-page & {
    display: none;
    visibility: hidden;
  }
`;

const Wrap = styled.div`
  width: ${(props) => (props.menuState ? "200px" : "60px")};
  min-width: ${(props) => (props.menuState ? "200px" : "60px")};
  position: relative;
  z-index: 2;
  transition: all 300ms;
  height: 100vh;
`;

const Username = styled.div`
  margin-bottom: 0.5rem;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const UserJobTitle = styled.div`
  font-family: "Source Sans Pro Italic";
  font-size: 0.8rem;
  opacity: 0.6;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const PinnedWrap = styled.div`
  width: ${(props) => (props.showPin ? "350px" : "0")};
  position: absolute;
  top: 0;
  left: ${(props) => (props.menuState ? "200px" : "60px")};
  z-index: 2;
  background: #fafafa;
  transition: all 150ms;
  height: 100vh;
  overflow: hidden;
  color: #333;
  box-shadow: ${(props) =>
    props.showPin ? "9px 0px 11px -9px rgb(0 0 0 / 20%)" : "0"};
`;

const InnerPins = styled.div`
  padding: 1rem;
  transition: none;
`;

const QuickButton = styled.div`
  display: flex;
  align-items: center;
  background: transparent;
  color: ${(props) => props.theme.menuText};
  padding: 1rem;
  padding-bottom: 0.8rem;
  padding-top: 0.8rem;
  margin-right: 0;
  margin-left: 0;
  border-left: 4px solid transparent;
  font-size: 1em;
  text-decoration: none;
  &:hover {
    cursor: pointer;
    background: ${(props) => props.theme.menuHoverActive};
  }
  &.active {
    background: ${(props) => props.theme.menuHoverActive};
    border-left: 4px solid ${(props) => props.theme.onSecondarySurface};
    ${MenuIcon} {
      opacity: 1;
    }
  }
`;

const CloseAccess = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  margin: 1rem;
  font-size: 1.5rem;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
`;

export const pinnedSourcesQuery = /* GraphQL */ `
  query (
    $first: Int
    $after: String
    $order: [DataSourceSortInput!]
    $where: DataSourceFilterInput
  ) {
    availableDataSources(
      first: $first
      after: $after
      order: $order
      where: $where
    ) {
      edges {
        cursor
        node {
          id
          name
        }
      }
    }
  }
`;

export const pinnedPoliciesQuery = /* GraphQL */ `
  query (
    $first: Int
    $after: String
    $order: [BusinessRuleStandardSortInput!]
    $where: BusinessRuleStandardFilterInput
  ) {
    availableBusinessRuleStandards(
      first: $first
      after: $after
      order: $order
      where: $where
    ) {
      edges {
        cursor
        node {
          id
          name
        }
      }
    }
  }
`;

const FlyOut = ({ menuState, showPin, setShowPin }) => {
  const { pinnedSources, pinnedPolicies } = useContext(PinContext);

  const [{ data: pinnedSourcesData }, doFetchSources] = useApi();

  useEffect(() => {
    if (pinnedSources?.length) {
      doFetchSources({
        query: pinnedSourcesQuery,
        variables: {
          order: {
            name: "ASC",
          },
          where: {
            or: [
              ...pinnedSources.map((ps) => {
                return { id: { eq: ps } };
              }),
            ],
          },
        },
      });
    }
  }, [pinnedSources, doFetchSources]);

  const [{ data: pinnedPoliciesData }, doFetchPolicies] = useApi();

  useEffect(() => {
    if (pinnedPolicies?.length) {
      doFetchPolicies({
        query: pinnedPoliciesQuery,
        variables: {
          order: {
            name: "ASC",
          },
          where: {
            or: [
              ...pinnedPolicies.map((ps) => {
                return { id: { eq: ps } };
              }),
            ],
          },
        },
      });
    }
  }, [pinnedPolicies, doFetchPolicies]);

  function useOutsideAlerter(ref) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (
          ref.current &&
          !ref.current.contains(event.target) &&
          event?.target?.innerText !== "Quick Access"
        ) {
          setShowPin(false);
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  const wrapperRef = useRef(null);

  useOutsideAlerter(wrapperRef, showPin);

  return (
    <>
      <PinnedWrap menuState={menuState} showPin={showPin} ref={wrapperRef}>
        <InnerPins>
          <CloseAccess onClick={() => setShowPin((prevState) => !prevState)}>
            <MdClose />
          </CloseAccess>
          <div style={{ textAlign: "left" }}>
            <h3 style={{ display: "flex", alignItems: "center" }}>
              <RiPushpinFill /> Quick Access
            </h3>
            <h4
              style={{
                marginTop: "2rem",
                display: "flex",
                alignItems: "center",
              }}
            >
              <FaDatabase style={{ marginRight: ".5rem" }} /> Sources
            </h4>
            {pinnedSources?.length ? (
              pinnedSourcesData?.availableDataSources?.edges.map((psd, i) => {
                return (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: ".6rem",
                    }}
                    key={`sources${i}`}
                  >
                    <StyledLink
                      to={paths?.dataSource(psd?.node?.id)}
                      data-testid="sourceLink"
                      onClick={() => setShowPin((prevState) => !prevState)}
                    >
                      {psd?.node?.name}
                    </StyledLink>

                    <div style={{ marginLeft: "auto" }}>
                      <PinButton type="sources" item={psd?.node?.id} />
                    </div>
                  </div>
                );
              })
            ) : (
              <div style={{ fontSize: ".8rem" }}>No Pinned Sources</div>
            )}

            <h4
              style={{
                marginTop: "2rem",
                display: "flex",
                alignItems: "center",
              }}
            >
              <MdLibraryBooks style={{ marginRight: ".5rem" }} /> Policies
            </h4>
            {pinnedPolicies?.length ? (
              pinnedPoliciesData?.availableBusinessRuleStandards?.edges.map(
                (psd, i) => {
                  return (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginBottom: ".6rem",
                      }}
                      key={`policies${i}`}
                    >
                      <StyledLink
                        to={paths?.ruleStandardById(psd?.node?.id)}
                        data-testid="policyLink"
                        onClick={() => setShowPin((prevState) => !prevState)}
                      >
                        {psd?.node?.name}
                      </StyledLink>

                      <div style={{ marginLeft: "auto" }}>
                        <PinButton type="policies" item={psd?.node?.id} />
                      </div>
                    </div>
                  );
                }
              )
            ) : (
              <div style={{ fontSize: ".8rem" }}>No Pinned Policies</div>
            )}
          </div>
        </InnerPins>
      </PinnedWrap>
    </>
  );
};

const Nav = () => {
  const { user, logout } = useContext(AuthContext);

  const { toggleMenu, menuState } = useContext(NavContext);
  const [showPin, setShowPin] = useState(false);

  if (!user) return null;
  return (
    <Wrap menuState={menuState}>
      <NavContainer menuState={menuState}>
        <NavMenuContainer>
          <>
            <LogoContainer>
              {menuState && (
                <Link to={welcome()}>
                  <LogoWrap />
                </Link>
              )}

              <MenuToggle menuState={menuState} onClick={() => toggleMenu()}>
                <MdMenu />
              </MenuToggle>
            </LogoContainer>

            <NotificationsBar menuState={menuState} />

            <QuickButton onClick={() => setShowPin((prevState) => !prevState)}>
              <MenuIcon menuState={menuState}>
                <RiPushpinFill />
              </MenuIcon>
              {menuState && "Quick Access"}
            </QuickButton>

            <UserMenuItem menuState={menuState} />

            {user ? (
              <UserNav>
                <FlyOut
                  menuState={menuState}
                  showPin={showPin}
                  setShowPin={setShowPin}
                />

                <BottomSection>
                  {menuState && (
                    <UserProfilePhotoContainer>
                      <ProfileImage
                        imageURL={window.localStorage.getItem("sessionPhoto")}
                      />
                    </UserProfilePhotoContainer>
                  )}
                  {menuState && <Username>{user.name}</Username>}
                  {menuState && <UserJobTitle>{user.jobTitle}</UserJobTitle>}

                  <UserMenuButtonNoLink onClick={logout}>
                    <MenuIcon menuState={menuState}>
                      <MdExitToApp />
                    </MenuIcon>
                    {menuState && "Logout"}
                  </UserMenuButtonNoLink>
                </BottomSection>
              </UserNav>
            ) : null}
            <VersionWrapper>v{window.versionNumber}</VersionWrapper>
          </>
        </NavMenuContainer>
      </NavContainer>
    </Wrap>
  );
};

export default withRouter(Nav);
