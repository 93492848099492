import React, { useState }  from "react";
import { Field } from "formik";

import {
  FormControl,
  StyledField,
  ErrorMessage,
} from "../../Form/FormControls";

const SqlConnection = ({ form }) => {
    const [showPassword, setShowPassword] = useState(false);

  return (
    <>
      <h3>Connection Details</h3>
      <FormControl>
        <label>
          Use Advanced Connection String
          <Field
            type="checkbox"
            checked={form.values.advanced}
            name={`advanced`}
            onChange={() => {
              form.setFieldValue(`advanced`, !form.values.advanced);
              form.setFieldValue(`details`, {
                databaseConnectionString: {
                  connectionString: null,
                  defaultDatabase: null,
                  password: null,
                  host: null,
                  port: null,
                  username: null,
                },
                dataverseConnectionString: null,
                azureStorageConnectionString: null,
                sftpConnectionString: null,
                cosmosConnectionString: null,
                ftpsConnectionString: null,
              });
            }}
          />
        </label>
      </FormControl>

      {form.values.advanced ? (
        <FormControl>
          <StyledField
            name={`details.databaseConnectionString.connectionString`}
            type="text"
            placeholder="Connection String"
            label="Connection String"
          />
          <ErrorMessage
            name={`details.databaseConnectionString.connectionString`}
          />
        </FormControl>
      ) : (
        <>
          <FormControl>
            <StyledField
              name={`details.databaseConnectionString.defaultDatabase`}
              type="text"
              placeholder="Database Name"
              label="Database Name"
            />
            <ErrorMessage
              name={`details.databaseConnectionString.defaultDatabase`}
            />
          </FormControl>

          <FormControl>
            <StyledField
              name={`details.databaseConnectionString.host`}
              type="text"
              placeholder="Host"
              label="Host"
            />
            <ErrorMessage name={`details.databaseConnectionString.host`} />
          </FormControl>

          <FormControl>
            <StyledField
              name={`details.databaseConnectionString.port`}
              type="number"
              placeholder="Server Port"
              label="Server Port"
            />
            <ErrorMessage name={`details.databaseConnectionString.port`} />
          </FormControl>

          <FormControl>
            <StyledField
              name={`details.databaseConnectionString.username`}
              type="text"
              placeholder="Username"
              label="Username"
            />
            <ErrorMessage name={`details.databaseConnectionString.username`} />
          </FormControl>

          <FormControl>
            <StyledField
              name={`details.databaseConnectionString.password`}
              type={showPassword ? "text" : "password"}
              placeholder="Password"
              label="Password"
              />
              <label>
                    Show Password
                    <Field
                        type="checkbox"
                        checked={showPassword}
                        name={`showPassword`}
                        onChange={() => setShowPassword((prevState) => !prevState)}
                     />
              </label>
            <ErrorMessage name={`details.databaseConnectionString.password`} />
          </FormControl>
        </>
      )}
    </>
  );
};

export default SqlConnection;
