import React from "react";
import Card from "../../components/Card";
import { Route, NavLink, useLocation, Switch } from "react-router-dom";
import styled from "styled-components/macro";
import { MdAdd, MdArchive, MdArrowBack } from "react-icons/md";
import ActionWrapper from "../../components/ActionsWrapper";
import StyledLink from "../../components/StyledLink";
import FeedsList from "./FeedsList";
import Button from "../../components/Button/NavigationButton";
import InputSourceList from "./InputSourcesList";
import OutputSourcesList from "./OutputSourcesList";
import ManageSource from "./ManageSource";

const Tabs = styled.div`
  border-bottom: 1px solid ${(props) => props.theme.surfaceAlt};
  background-color: ${(props) => props.theme.surface};
  position: relative;
  padding: 1.2rem;
`;

const TabLink = styled(NavLink)`
  padding: 1rem;
  border-bottom: 2px solid transparent;
  text-decoration: none;
  opacity: 0.5;
  color: ${(props) => props.theme.onSurface};
  &.active {
    opacity: 1;
    border-bottom: 2px solid ${(props) => props.theme.onSecondarySurface};
  }
  &:hover {
    opacity: 1;
  }
`;

const Body = ({ archive }) => {
  return (
    <>
      <Tabs>
        <TabLink exact to={`/feeds`}>
          Transformations
        </TabLink>
        <TabLink to={`/feeds/outputsources`}>Output Sources</TabLink>
        <TabLink to={`/feeds/inputsources`}>Input Sources</TabLink>
      </Tabs>
      {/* TODO: css hack for container around edit form temp should figure out px issue */}
      <Switch>
        <Route
          path={`/feeds/outputsources/new`}
          component={() => <ManageSource sourceType={-3} />}
        />
        <Route
          path={`/feeds/inputsources/new`}
          component={() => <ManageSource sourceType={-2} />}
        />
        <Route
          path={`/feeds/outputsources/:sourceId`}
          component={() => <ManageSource sourceType={-3} />}
        />
        <Route
          path={`/feeds/inputsources/:sourceId`}
          component={() => <ManageSource sourceType={-2} />}
        />

        <Route
          path={`/feeds/archive`}
          component={() => (
            <div style={{ marginTop: "1px" }}>
              <FeedsList archive={archive} />
            </div>
          )}
        />

        <Route path={`/feeds/inputsources`} component={InputSourceList} />

        <Route path={`/feeds/outputsources`} component={OutputSourcesList} />

        <Route
          path={`/feeds`}
          component={() => (
            <div style={{ marginTop: "1px" }}>
              <div style={{ marginTop: "1rem", marginLeft: "1rem" }}>
                <Button title="Create New Transformation" to={"feeds/new"}>
                  Add New
                </Button>
              </div>

              <FeedsList />
            </div>
          )}
        />
      </Switch>
    </>
  );
};

//Actions Section of Widget
function Actions({ archive }) {
  return (
    <>
      <ActionWrapper>
        {archive ? (
          <StyledLink to={`/feeds`} title="Back to List">
            <MdArrowBack />
          </StyledLink>
        ) : (
          <StyledLink to={`/feeds/archive`} title="Transformations Archive">
            <MdArchive />
          </StyledLink>
        )}
      </ActionWrapper>
      <ActionWrapper>
        <StyledLink title="Create New Transformation" to={"/feeds/new"}>
          <MdAdd />
        </StyledLink>
      </ActionWrapper>
    </>
  );
}

const FeedsPage = () => {
  let location = useLocation();
  const isArchive = location.pathname.includes("/archive");
  return (
    <Card
      title={`Transformations ${isArchive ? "Archive" : ""}`}
      titleDescription={"Transform data"}
      body={() => <Body archive={isArchive} />}
      actions={() => <Actions archive={isArchive} />}
    />
  );
};

export default FeedsPage;
