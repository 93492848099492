import React, { useEffect } from "react";
import { Formik } from "formik";
import {
  FormControl,
  FormActions,
  FieldError,
  Label,
  StyledField,
  StyledSelect,
} from "../../Form/FormControls";
import Button from "../../Button";
import Spinner from "../../Loaders/Spinner";
import { useApi } from "../../../api/useApi";
import { organizations } from "../../../common/paths";
import { Link } from "react-router-dom";
import { createOrganization } from "../../../api/organizationMutations";
import styled from "styled-components/macro";
import { useHistory } from "react-router-dom";
import ErrorMessages from "../../Notifications/ErrorMessages";

const SmallText = styled.div`
  font-size: 0.7rem;
  margin-bottom: 0.4rem;
`;

const orgRoles = [
  { label: "THIRD PARTY", value: "THIRD_PARTY" },
  { label: "CORE", value: "CORE" },
];

const OrganizationComponent = () => {
  const [{ loading, errors, data }, create] = useApi();
  let history = useHistory();

  useEffect(() => {
    if (data && !errors) {
      history.push("/admin/organizations");
    }
  }, [data, errors, history]);

  return (
    <Formik
      initialValues={{
        displayName: "",
        type: "AZURE",
        role: { label: "THIRD PARTY", value: "THIRD_PARTY" },
        invitationRecipientName: "",
        invitationRecipientEmail: "",
        tenantId: "",
      }}
      validate={(values) => {
        let errors = {};
        if (!values.displayName) {
          errors.displayName = "required.";
        }
        if (!values.invitationRecipientName) {
          errors.invitationRecipientName = "required.";
        }
        if (!values.invitationRecipientEmail) {
          errors.invitationRecipientEmail = "required.";
        }
        if (!values.role) {
          errors.role = "required.";
        }
        if (!values.tenantId) {
          errors.tenantId = "required.";
        }
        return errors;
      }}
      onSubmit={(values) => {
        const variables = {
          organization: {
            displayName: values.displayName,
            type: "AZURE",
            role: values.role.value,
            invitationRecipientEmail: values.invitationRecipientEmail,
            invitationRecipientName: values.invitationRecipientName,
            details: { azureConnectionString: { tenantId: values.tenantId } },
          },
        };

        create({ query: createOrganization, variables });
      }}
    >
      {(props) => (
        <form onSubmit={props.handleSubmit}>
          <h3>Add Organization</h3>
          <FormControl>
            <StyledField
              type="text"
              name="displayName"
              label={`Organization Name`}
              value={props.values.displayName}
              placeholder={`Name`}
            />
            {props.errors?.displayName && (
              <FieldError>{props.errors.displayName}</FieldError>
            )}
          </FormControl>

          <FormControl>
            <StyledField
              type="text"
              name="tenantId"
              label={`Tenant ID`}
              value={props.values.tenantId}
              placeholder={`Tenant Id`}
            />
            {props.errors?.tenantId && (
              <FieldError>{props.errors.tenantId}</FieldError>
            )}
          </FormControl>

          <FormControl>
            <Label>Organization Role</Label>
            <StyledSelect
              className={`react-select-container`}
              classNamePrefix={`react-select`}
              menuPortalTarget={document.body}
              name={`${props.values.role}-role`}
              id={`${props.values.role}-role`}
              inputId={`${props.values.role}-roleSelect-input`}
              instanceId={`${props.values.role}-roleSelect-instance`}
              options={orgRoles}
              onChange={(e) => props.setFieldValue(`role`, e)}
              placeholder={props.values.role}
              value={orgRoles.find(
                (role) => props.values.role.value === role.value
              )}
            />
            {props.errors?.role && <FieldError>{props.errors.role}</FieldError>}
          </FormControl>

          <FormControl>
            <StyledField
              type="text"
              name="invitationRecipientName"
              label={`Admin Name`}
              value={props.values.invitationRecipientName}
              placeholder={`Provide an Admin Name`}
            />
            {props.errors?.invitationRecipientName && (
              <FieldError>{props.errors.invitationRecipientName}</FieldError>
            )}
          </FormControl>

          <FormControl>
            <StyledField
              type="text"
              name="invitationRecipientEmail"
              label={`Admin Email`}
              value={props.values.invitationRecipientEmail}
              placeholder={`Provide an Admin Email to send an invite`}
            />
            {props.errors?.invitationRecipientEmail && (
              <FieldError>{props.errors.invitationRecipientEmail}</FieldError>
            )}
          </FormControl>

          <FormActions>
            <SmallText>
              Upon completing this form the admin email provided will receive an
              invite link, the user must follow the instructions within the
              email to complete the sign-up process.
            </SmallText>

            <>
              <Link to={organizations}>
                <Button list="true" type="button">
                  Cancel
                </Button>
              </Link>
              <Button danger type="submit">
                {loading ? <Spinner /> : "Invite"}
              </Button>
            </>
            {errors && <ErrorMessages errors={errors} />}
          </FormActions>
        </form>
      )}
    </Formik>
  );
};

export default OrganizationComponent;
