import React from "react";
import { buildingBlocks } from "./buildingBlocks";
import Button from "../../Button";
import { MdDelete } from "react-icons/md";

const Attachments = ({
  index,
  dispatch,
  toggleShowAttachments,
  showAttachments,
  type,
}) => {
  const showAddStatement = type !== "Fragment" && type !== "End If";
  const showAddIf = type !== "If";
  return (
    <div>
      {showAddStatement && (
        <Button
          list="true"
          size="small"
          onClick={(e) => {
            e.stopPropagation();
            dispatch({
              type: "ATTACH_AFTER",
              payload: {
                attachment: buildingBlocks.Fragment[0],
                index: index,
              },
            });
            toggleShowAttachments(!showAttachments);
          }}
        >
          STATEMENT
        </Button>
      )}

      {showAddIf && (
        <Button
          list="true"
          size="small"
          onClick={(e) => {
            e.stopPropagation();
            dispatch({
              type: "ATTACH_AFTER",
              payload: {
                attachment: buildingBlocks["Conditions"][0],
                index: index,
              },
            });
            toggleShowAttachments(!showAttachments);
          }}
          data-testid="ifDataAttachment"
        >
          IF
        </Button>
      )}

      <Button
        list="true"
        size="small"
        onClick={(e) => {
          e.stopPropagation();
          dispatch({
            type: "ATTACH_AFTER",
            payload: {
              attachment: buildingBlocks["Conditions"][1],
              index: index,
            },
          });
          toggleShowAttachments(!showAttachments);
        }}
      >
        ELSE IF
      </Button>

      <Button
        list="true"
        size="small"
        onClick={(e) => {
          e.stopPropagation();
          dispatch({
            type: "ATTACH_AFTER",
            payload: {
              attachment: buildingBlocks["Conditions"][2],
              index: index,
            },
          });
          toggleShowAttachments(!showAttachments);
        }}
      >
        ELSE
      </Button>

      <Button
        list="true"
        size="small"
        onClick={(e) => {
          e.stopPropagation();
          dispatch({
            type: "ATTACH_AFTER",
            payload: {
              attachment: buildingBlocks["Conditions"][3],
              index: index,
            },
          });
          toggleShowAttachments(!showAttachments);
        }}
      >
        END IF
      </Button>

      <Button
        list="true"
        size="small"
        onClick={(e) => {
          e.stopPropagation();
          dispatch({
            type: "ATTACH_AFTER",
            payload: {
              attachment: buildingBlocks["Conditions"][4],
              index: index,
            },
          });
          toggleShowAttachments(!showAttachments);
        }}
      >
        THEN
      </Button>

      <Button
        list="true"
        size="small"
        onClick={(e) => {
          e.stopPropagation();
          dispatch({
            type: "ATTACH_AFTER",
            payload: {
              attachment: buildingBlocks["Condition Operators"][1],
              index: index,
            },
          });
          toggleShowAttachments(!showAttachments);
        }}
      >
        OR
      </Button>
      <Button
        list="true"
        size="small"
        onClick={(e) => {
          e.stopPropagation();
          dispatch({
            type: "ATTACH_AFTER",
            payload: {
              attachment: buildingBlocks["Condition Operators"][0],
              index: index,
            },
          });
          toggleShowAttachments(!showAttachments);
        }}
      >
        AND
      </Button>
      <Button
        list="true"
        size="small"
        onClick={(e) => {
          e.stopPropagation();
          dispatch({
            type: "ATTACH_AFTER",
            payload: {
              attachment: buildingBlocks["Condition Operators"][2],
              index: index,
            },
          });
          toggleShowAttachments(!showAttachments);
        }}
      >
        (
      </Button>
      <Button
        list="true"
        size="small"
        onClick={(e) => {
          e.stopPropagation();
          dispatch({
            type: "ATTACH_AFTER",
            payload: {
              attachment: buildingBlocks["Condition Operators"][3],
              index: index,
            },
          });
          toggleShowAttachments(!showAttachments);
        }}
      >
        )
      </Button>

      <Button
        list="true"
        size="small"
        danger
        onClick={(e) => {
          e.stopPropagation();
          dispatch({
            type: "REMOVE_ELEMENT",
            payload: {
              index: index,
            },
          });
          toggleShowAttachments(!showAttachments);
        }}
        data-testid="removeFragment"
      >
        <MdDelete />
      </Button>
    </div>
  );
};

export default Attachments;
