import Fuse from "fuse.js";

export function getSuggestedZoneMapping(suggestedColumns) {
  if (
    suggestedColumns?.fragments?.length &&
    suggestedColumns?.suggestions?.length &&
    !suggestedColumns?.didUseName
  ) {
    const options = {
      includeScore: true,
      minMatchCharLength: 3,
      // Search in 'name', 'description'
      keys: ["keyed"],
      threshold: 0,
    };

    const fuse = new Fuse(suggestedColumns?.suggestions, options);

    let suggestions = [];

    suggestedColumns.fragments.forEach((z) => {
      const zoneName = z?.name;

      const regex = /[^A-Za-z0-9]/g;
      const nameSplit = zoneName.replace(regex, " ");

      const nameArray = nameSplit.split(/([A-Z][a-z]+)/).filter(function (e) {
        return e;
      });

      const removeSecondSpace = nameArray.map((cl) => {
        return cl.split(" ").join("");
      });

      const cleanNameArray = removeSecondSpace.filter(function (str) {
        return /\S/.test(str);
      });

      //Loop through each column

      cleanNameArray.forEach((keyedZone) => {
        const result = fuse.search(`${keyedZone}`);
        if (result?.length) {
          suggestions.push({
            businessRuleFragmentId: z?.fragmentId,
            columnId: result[0]?.item?.id,
          });
        }
      });

      // const result = fuse.search(`${z?.name}`);
      // if (result?.length) {
      //   suggestions.push({
      //     businessRuleFragmentId: z?.fragmentId,
      //     columnId: result[0]?.item?.id,
      //   });
      // }
    });

    // Comment out guessing at mapping as it results in more work to remove mappings

    // if (!suggestions?.length) {
    //   suggestedColumns.fragments.forEach((z) => {
    //     suggestions.push({
    //       businessRuleFragmentId: z?.fragmentId,
    //       columnId: suggestedColumns?.suggestions[0]?.id,
    //     });
    //   });
    // }

    const cleanSuggestions = [
      ...new Map(
        suggestions.map((sug) => [sug["businessRuleFragmentId"], sug])
      ).values(),
    ];

    return cleanSuggestions;
  } else {
    return [];
  }
}
