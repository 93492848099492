import React, { useState, useEffect, useContext } from "react";
import Button from "../../Button";
import { Link } from "react-router-dom";
import { useApi } from "../../../api/useApi";
import {
  dataSourceReport,
  manageDataSource,
  dataConnectionsSources,
  dataSourceReports,
} from "../../../common/paths";
import { priorityLevelOptions } from "../../../common/formOptions";
import StyledLink from "../../StyledLink";
import { withRouter } from "react-router-dom";
import PagedTable from "../../Table/PagedTable";
import { archivedDataSources } from "../../../api/dataSourceQueries";
import { format } from "date-fns";
import { MdArchive, MdHistory } from "react-icons/md";
import Modal from "../../Modal";
import { setDataSourceEnabledFlag } from "../../../api/dataSourceMutations";
import Spinner from "../../Loaders/Spinner";
import { AuthContext } from "../../../contexts/AuthContext";
import TableButton from "../../../components/Button/TableButton";
import { InLineTitlePin } from "../../../components/Table/elements";
import ErrorMessages from "../../../components/Notifications/ErrorMessages";
const SourceTable = () => {
  const [showConfirm, setShowConfirm] = useState(false);
  const [sourceToDelete, setSourceToDelete] = useState(null);
  //currentPage
  const [currentView, setCurrentView] = useState(null);

  //Fetch Count

  const { user } = useContext(AuthContext);

  const [
    { errors: removalErrors, loading: removalLoading, data: removalData },
    remove,
  ] = useApi();

  const toggleSourceEnabled = ({ id, enabled }) => {
    remove({
      query: setDataSourceEnabledFlag,
      variables: { id: id, enabled: !enabled },
    });
  };

  //Init Data Fetch
  const [{ loading, data: apiData }, doFetch] = useApi();

  const dataSources = apiData?.availableDataSources?.edges ?? [];
  const totalCount = apiData?.availableDataSources?.totalCount;
  const pageInfo = apiData?.availableDataSources?.pageInfo;

  //Fetch for Table Paged
  const fetchData = React.useCallback(
    ({ pageSize, cursor }) => {
      setCurrentView(cursor);
      doFetch({
        query: archivedDataSources,
        variables: {
          first: pageSize,
          after: cursor,
          order: {
            name: "ASC",
          },
          where: {
            enabled: { eq: false },
          },
        },
      });
    },
    [doFetch, setCurrentView]
  );

  const columnsData = [
    {
      Header: "Name",
      id: "Name",
      accessor: "name",
      Cell: ({ row: { original } }) => {
        const modifiedName =
          original?.node?.schema === ""
            ? original?.node?.name
            : `${original?.node?.schema}.${original?.node?.name}`;
        return (
          <InLineTitlePin>
            {original?.node?.ruleInstancesCount &&
            original?.node?.latestReport?.refreshSummaryId &&
            original?.node?.latestReport?.refreshSummary?.resultState !==
              "REGENERATING_CACHED_REPORT" ? (
              <StyledLink
                to={dataSourceReport(
                  original?.node?.id,
                  original?.node?.latestReport?.refreshSummaryId
                )}
                data-testid="sourceLink"
              >
                {modifiedName}
              </StyledLink>
            ) : (
              modifiedName
            )}
          </InLineTitlePin>
        );
      },
    },
    {
      Header: "Score",
      id: "score",
      width: 100,
      accessor: (d) => d?.node?.latestReport?.qualityScore?.score,
      Cell: ({ row: { original } }) => {
        if (original?.node?.latestReport) {
          return original?.node?.ruleInstancesCount
            ? original?.node?.latestReport?.qualityScore?.score ?? 0
            : "N/A";
        } else {
          return "N/A";
        }
      },
    },
    {
      Header: "Impact",
      id: "priority",
      width: 150,
      accessor: (d) => d?.node?.priorityLevel,
      Cell: ({ row: { original } }) => {
        if (original?.node?.priorityLevel) {
          const priorityOption = priorityLevelOptions.find(
            (option) => option.value === original?.node?.priorityLevel
          );
          return priorityOption.label;
        } else {
          return "Normal";
        }
      },
    },
    {
      Header: "Updated",
      id: "updated",
      accessor: (d) => d?.node?.latestReport?.refreshSummary?.createdOn,
      Cell: ({ row: { original } }) => {
        const date = original?.node?.latestReport?.refreshSummary?.createdOn;
        if (date) {
          return format(new Date(date), "MM-dd-yyyy HH:mm:ss");
        } else {
          return "";
        }
      },
    },
    {
      Header: "Connection",
      id: "connection",
      accessor: (d) => d?.node?.connection?.name,
      Cell: ({ row: { original } }) => {
        return (
          <InLineTitlePin>
            {user && user.role >= 2 ? (
              <StyledLink
                to={dataConnectionsSources(original?.node?.connection?.id)}
              >
                {original?.node?.connection?.name}
              </StyledLink>
            ) : (
              original?.node?.connection?.name
            )}
          </InLineTitlePin>
        );
      },
    },
    {
      Header: " ",
      id: "actions",
      width: 375,
      resizable: false,
      sortable: false,
      Cell: ({ row: { original } }) => {
        const enabled = original?.node?.enabled;

        return (
          <>
            {enabled && (
              <Link to={manageDataSource(original?.node?.id)}>
                <TableButton
                  type="button"
                  title="Manage"
                  list="true"
                  data-testid="manageSource"
                >
                  Manage
                </TableButton>
              </Link>
            )}
            {enabled && (
              <Link to={dataSourceReports(original?.node?.id)}>
                <TableButton
                  title={"Report History"}
                  data-testid="sourceHistoryLink"
                  iconBump={false}
                  type="button"
                  list={"true"}
                  bumpdown={true}
                >
                  <MdHistory />
                </TableButton>
              </Link>
            )}

            {user && user.role >= 2 && (
              <TableButton
                danger={enabled}
                list="true"
                type="button"
                id={`${original?.node?.name}-toggleSourceArchiveReactivate`}
                data-testid={`${original?.node?.name}-toggleSourceArchiveReactivate`}
                title="Archive Toggle"
                bumpdown={true}
                onClick={() =>
                  setSourceToDelete({
                    id: original?.node?.id,
                    enabled: enabled,
                  })
                }
              >
                {enabled ? <MdArchive /> : "Reactivate"}
              </TableButton>
            )}
          </>
        );
      },
    },
  ];

  //handle remove rule update
  useEffect(() => {
    if (removalData) {
      fetchData({ pageSize: 10, cursor: currentView ?? null });
      setShowConfirm(false);
      setSourceToDelete(null);
    }
  }, [removalData, fetchData, currentView]);

  useEffect(() => {
    if (sourceToDelete) {
      setShowConfirm(true);
    } else {
      setShowConfirm(false);
    }
  }, [sourceToDelete]);

  return (
    <>
      {showConfirm ? (
        <Modal
          title={`Confirm Source ${
            sourceToDelete?.enabled ? "Archival" : "Reactivation"
          }`}
          hide={() => setSourceToDelete(null)}
        >
          <p>
            Are you sure you wish to{" "}
            {sourceToDelete?.enabled ? "archive" : "reactivate"} this source?
          </p>

          {removalErrors ? <ErrorMessages errors={removalErrors} /> : null}
          <div>
            <Button
              type="button"
              list="true"
              disabled={removalLoading}
              danger
              onClick={() => toggleSourceEnabled(sourceToDelete)}
            >
              {removalLoading ? <Spinner /> : "Yes"}
            </Button>
            <Button
              type="button"
              disabled={removalLoading}
              onClick={() => {
                setSourceToDelete(null);
              }}
            >
              Cancel
            </Button>
          </div>
        </Modal>
      ) : null}

      <PagedTable
        fetchData={fetchData}
        loading={loading}
        pageInfo={pageInfo}
        totalCount={totalCount}
        data={dataSources}
        columns={columnsData}
        defaultPageSize={50}
      />
    </>
  );
};

const SourceList = () => {
  return <SourceTable />;
};

const SourceListWithRouter = withRouter(SourceList);
export default SourceListWithRouter;
