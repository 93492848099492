export const buildingBlocks = {
  Fragment: [
    {
      name: "Fragment",
      def: "fragment",
      source: {
        typeInformation: [
          {
            //index of the type information
            typeHierarchyLevel: 0,
            //enum for business rule fragment type if hierarchy 0
            typeValue: 4,
            //should always be null for type level 0
            fragmentValue: null,
          },
          {
            typeHierarchyLevel: 1,
            //enum value for frag type
            typeValue: { label: "Column", value: 1 },
            //context for type (target), value, name
            fragmentValue: null,
          },
        ],
      },
      target: {
        typeInformation: [
          {
            typeHierarchyLevel: 0,
            typeValue: 5,
            fragmentValue: null,
          },
          {
            typeHierarchyLevel: 1,
            typeValue: { label: "Value", value: 2 },
            fragmentValue: null,
          },
          {
            typeHierarchyLevel: 2,
            //Variable type would be 3, enum would be 0 for none still
            typeValue: { label: "None", value: 0 },
            //context for type (target), value, name
            fragmentValue: "0",
          },
        ],
      },
      operation: {
        typeInformation: [
          {
            typeHierarchyLevel: 0,
            typeValue: 3,
            fragmentValue: null,
          },
          {
            typeHierarchyLevel: 1,
            typeValue: { label: "=", value: 1 },
            fragmentValue: null,
          },
        ],
      },
    },
    // Generative Expression Default State
    {
      name: "Fragment",
      def: "fragment",
      source: {
        typeInformation: [
          {
            typeHierarchyLevel: 0,
            typeValue: 4,
            fragmentValue: null,
          },
          {
            typeHierarchyLevel: 1,
            typeValue: { label: "Value", value: 4 },
            fragmentValue: "True",
          },
        ],
      },
      target: {
        typeInformation: [
          {
            typeHierarchyLevel: 0,
            typeValue: 5,
            fragmentValue: null,
          },
          {
            typeHierarchyLevel: 1,
            typeValue: {
              label: "Calculation",
              value: 4,
              isDisabled: false,
            },
            fragmentValue: ``,
          },
          {
            typeHierarchyLevel: 2,
            typeValue: { label: "None", value: 0 },
            fragmentValue: "0",
          },
        ],
        testInfo: null,
      },
      operation: {
        typeInformation: [
          {
            typeHierarchyLevel: 0,
            typeValue: 3,
            fragmentValue: null,
          },
          {
            typeHierarchyLevel: 1,
            typeValue: { label: "=", value: 1 },
            fragmentValue: null,
          },
        ],
      },
    },
  ],
  Conditions: [
    {
      name: "If",
      def: "conditions",
      typeInformation: [
        {
          typeHierarchyLevel: 0,
          typeValue: 1,
          fragmentValue: null,
        },
        {
          typeHierarchyLevel: 1,
          typeValue: 1,
          fragmentValue: null,
        },
      ],
    },
    {
      name: "Else If",
      def: "conditions",
      typeInformation: [
        {
          typeHierarchyLevel: 0,
          typeValue: 1,
          fragmentValue: null,
        },
        {
          typeHierarchyLevel: 1,
          typeValue: 2,
          fragmentValue: null,
        },
      ],
    },
    {
      name: "Else",
      def: "conditions",
      typeInformation: [
        {
          typeHierarchyLevel: 0,
          typeValue: 1,
          fragmentValue: null,
        },
        {
          typeHierarchyLevel: 1,
          typeValue: 3,
          fragmentValue: null,
        },
      ],
    },
    {
      name: "End If",
      def: "conditions",
      typeInformation: [
        {
          typeHierarchyLevel: 0,
          typeValue: 1,
          fragmentValue: null,
        },
        {
          typeHierarchyLevel: 1,
          typeValue: 4,
          fragmentValue: null,
        },
      ],
    },
    {
      name: "Then",
      def: "conditions",
      typeInformation: [
        {
          typeHierarchyLevel: 0,
          typeValue: 1,
          fragmentValue: null,
        },
        {
          typeHierarchyLevel: 1,
          typeValue: 5,
          fragmentValue: null,
        },
      ],
    },
  ],
  "Condition Operators": [
    {
      name: "And",
      def: "conditionOperators",
      typeInformation: [
        {
          typeHierarchyLevel: 0,
          typeValue: 2,
          fragmentValue: null,
        },
        {
          typeHierarchyLevel: 1,
          typeValue: 1,
          fragmentValue: null,
        },
      ],
    },
    {
      name: "Or",
      def: "conditionOperators",
      typeInformation: [
        {
          typeHierarchyLevel: 0,
          typeValue: 2,
          fragmentValue: null,
        },
        {
          typeHierarchyLevel: 1,
          typeValue: 2,
          fragmentValue: null,
        },
      ],
    },
    {
      name: "(",
      def: "conditionOperators",
      typeInformation: [
        {
          typeHierarchyLevel: 0,
          typeValue: 2,
          fragmentValue: null,
        },
        {
          typeHierarchyLevel: 1,
          typeValue: 3,
          fragmentValue: null,
        },
      ],
    },
    {
      name: ")",
      def: "conditionOperators",
      typeInformation: [
        {
          typeHierarchyLevel: 0,
          typeValue: 2,
          fragmentValue: null,
        },
        {
          typeHierarchyLevel: 1,
          typeValue: 4,
          fragmentValue: null,
        },
      ],
    },
  ],
};

export const BusinessRuleConditionOperators = [
  { label: "And", value: 1 },
  { label: "Or", value: 2 },
  { label: "Open Parentheses", value: 3 },
  { label: "Close Parentheses", value: 4 },
];

export const BusinessRuleConditions = [
  { label: "If", value: 1 },
  { label: "Else If", value: 2 },
  { label: "Else", value: 3 },
  { label: "End If", value: 4 },
  { label: "Then", value: 5 },
];

export const BusinessRuleVariables = [
  { label: "Current Date", value: 1 },
  { label: "Current Time", value: 2 },
  { label: "Current DateTime", value: 3 },
  { label: "Current Day Of Month", value: 4 },
  { label: "Current Day Of Week", value: 5 },
  { label: "Current Day Of Year", value: 6 },
  { label: "Current Week Of Year", value: 7 },
  { label: "Current Month", value: 8 },
  { label: "Current Year", value: 9 },
  { label: "Most Recent Date", value: 10 },
];

export const BusinessRuleVariableOperations = [
  { label: "None", value: 0 },
  { label: "+", value: 1 },
  { label: "-", value: 2 },
];

export const BusinessRuleTargetValues = [
  { label: "Column", value: 1 },
  { label: "Value", value: 2 },
  { label: "Variable", value: 3 },
  { label: "Calculation", value: 4 },
];

export const BusinessRuleSourceValues = [
  { label: "Column", value: 1 },
  { label: "Value", value: 4 },
];

export const BusinessRuleOperationModifiers = [
  { label: "Not", value: 1 },
  { label: "ApproximateMatch", value: 2 },
  { label: "CaseInsensitiveMatch", value: 3 },
  { label: "ChangeByAmount", value: 4 },
  { label: "ChangeByPercentage", value: 5 },
  { label: "CharacterMatch", value: 6 },
];

export const BusinessRuleStandardType = [
  { label: "Rule", value: 1 },
  { label: "Filter", value: 2 },
];

export const BusinessRuleOperations = [
  { label: "=", value: 1 },
  { label: ">", value: 2 },
  { label: "≥", value: 3 },
  { label: "<", value: 4 },
  { label: "≤", value: 5 },
  { label: "Is Empty", value: 6 },
  { label: "Is Repeating Digits", value: 8 },
  { label: "Is Consecutive Digits", value: 9 },
  { label: "Starts With", value: 10 },
  { label: "Ends With", value: 11 },
  { label: "Contains", value: 12 },
  { label: "Matches Pattern", value: 13 },
  { label: "Run over Run Must Change", value: 14 },
  { label: "Run over Run Must Not Change", value: 15 }, // Default value for Reconciliation is Must Change, will be updated to value of 15 if Reconciliation Must Not Change
  { label: "Does Exist", value: 16 },
  { label: "Does Not Exist", value: 17 },
];

export const BusinessRuleFragmentTypes = [
  { label: "Condition", value: 1 },
  { label: "Condition Operator", value: 2 },
  { label: "Operation", value: 3 },
  { label: "Source Value", value: 4 },
  { label: "Target Value", value: 5 },
];

export const ColumnModifiers = [
  {
    label: "Length",
    value: 1,
    acceptableOperations: [1, 2, 3, 4, 5, 14, 15],
  },
  {
    label: "Date",
    value: 2,
    acceptableOperations: [1, 2, 3, 4, 5, 14, 15],
  },
  {
    label: "Count",
    value: 3,
    acceptableOperations: [4, 5],
  },
];
