import React, { useEffect, useState, useRef } from "react";
import { useApi } from "../api/useApi";
import { simpleStandardsList } from "../api/ruleQueries";

const UseAllRuleStandards = () => {
  const [stateSources, setStateSources] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  //Available Users Via Graph
  const [{ data: userData }, getUsers] = useApi();

  //Fetch for Table Paged
  const fetchUsers = React.useCallback(
    ({ cursor }) => {
      getUsers({
        query: simpleStandardsList,
        variables: {
          first: 10,
          after: cursor ?? null,
          where: {
            enabled: { eq: true},
          },
        },
      });
    },
    [getUsers]
  );

  const countRef = useRef(stateSources?.length);

  //Effect for User Count and Users Update
  useEffect(() => {
    const totalCount =
      userData?.availableBusinessRuleStandards?.totalCount ?? 0;

    if (totalCount && userData?.availableBusinessRuleStandards?.edges?.length) {
      setStateSources((prevState) => [
        ...prevState,
        ...userData.availableBusinessRuleStandards.edges,
      ]);
      countRef.current =
        countRef.current +
        userData?.availableBusinessRuleStandards?.edges?.length;
    }

    if ((totalCount && totalCount > countRef.current) || !countRef.current) {
      fetchUsers({
        cursor: userData?.availableBusinessRuleStandards?.edges?.length
          ? userData?.availableBusinessRuleStandards?.edges.slice(-1).pop()
              .cursor
          : null,
      });
    }

    if (totalCount === countRef.current && countRef.current) {
      setIsLoading(false);
    }
  }, [userData, fetchUsers, setIsLoading]);

  const sourceOptions = stateSources.map((source) => {
    return {
      name: source?.node?.name,
      id: source?.node?.id,
      description: source?.node?.description,
    };
  });

  return [{ stateStandards: sourceOptions, isLoading: isLoading }];
};

export default UseAllRuleStandards;
