const columnFlagUpdateOptions = {
  none: "NONE",
  removeNullability: "REMOVE_NULLABILITY",
  addNullability: "ADD_NULLABILITY",
  removeUnique: "REMOVE_UNIQUE",
  addUnique: "ADD_UNIQUE",
  removeMatching: "REMOVE_MATCHING",
  addMatching: "ADD_MATCHING",
  addAsOfDate: "ADD_AS_OF_DATE",
  removeAsOfDate: "REMOVE_AS_OF_DATE",
  addPrimaryKey: "ADD_PRIMARY_KEY",
  removePrimaryKey: "REMOVE_PRIMARY_KEY",
  addTrackUniqueValues: "ADD_TRACK_UNIQUE_VALUES",
  removeTrackUniqueValues: "REMOVE_TRACK_UNIQUE_VALUES",
  addSensitive: "ADD_SENSITIVE",
  removeSensitive: "REMOVE_SENSITIVE",
};

export default columnFlagUpdateOptions;
