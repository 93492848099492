import ApiService from "../services/Api";
import Cookies from "js-cookie";

//TODO: Not the final implementations, but a WIP towards generation, or finding a final generator
const api = new ApiService();

//get the secured token for api auth
function getSecuredToken() {
  return Cookies.get("token");
}

//set fetch headers
function setHeaders(options) {
  if (options && options.secured)
    return {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${getSecuredToken()}`,
    };
  return {
    "Content-Type": "application/json",
    Accept: "application/json",
  };
}

//Generic Fetch
export function unsecureRequest(query, variables, signal) {
  return new Promise((resolve, reject) => {
    api
      .post(`/graphql`, {
        query,
        headers: setHeaders(),
        variables,
        signal,
      })
      .then((response) => {
        const organizedResponse = {
          ...(response.data && { data: response.data }),
          ...(response.errors ? { errors: response.errors } : { errors: null }),
        };
        if (response) {
          resolve(organizedResponse);
        }
      })
      .catch((err) => reject(err));
  });
}

//Generic Secured Fetch
export function request(query, variables, signal) {
  return new Promise((resolve, reject) => {
    api
      .post(`/graphql`, {
        query,
        headers: setHeaders({ secured: true }),
        variables,
        signal,
      })
      .then((response) => {
        const organizedResponse = {
          ...(response.data && { data: response.data }),
          ...(response.errors ? { errors: response.errors } : { errors: null }),
        };
        if (response) {
          resolve(organizedResponse);
        }
      })
      .catch((err) => {
        return reject(err);
      });
  });
}
