import React, { useEffect, useCallback, useState, useContext } from "react";
import { FormControl, StyledSelect } from "../../components/Form/FormControls";
import { MdDelete } from "react-icons/md";
import { StyledInput, Label } from "../../components/Form/FormControls";
import { useApi } from "../../api/useApi";
import { dataSourceColumns } from "../../api/dataSourceQueries";
import { getUniqueValuesByColumnId } from "../../api/serviceTransferQueries";
import {
  setUniqueValuesByCollection,
  setUniqueValuesFromRefreshByCollection,
} from "../../api/serviceTransferMutations";
import ErrorMessages from "../../components/Notifications/ErrorMessages";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Button from "../../components/Button";
import SortTable from "../../components/Table/SortTable";
import TableButton from "../../components/Button/TableButton";
import Spinner from "../../components/Loaders/Spinner";
import SplashLoader from "../../components/Loaders/SplashLoader";
import { SocketContext } from "../../contexts/useSubscriptions";
import { setTagInstances } from "../../api/tagMutations";
import Modal from "../../components/Modal";
import { Scrollbars } from "react-custom-scrollbars";

const ImportForm = ({
  collectionId,
  columnId,
  currentUniqueValues,
  setShowImportForm,
  fetchLatest,
}) => {
  const [{ errors, loading, data }, saveValues] = useApi();
  const [importValues, setImportValues] = useState([]);
  const [replaceAll, setReplaceAll] = useState(false);
  const isNotValid = importValues.filter((v) => !v.value)?.length;
  const [importError, setImportError] = useState();

  const updateValueInImport = ({ row, description, value }) => {
    setImportValues((prev) => {
      let clone = [...prev];

      clone[row.index].value = value;
      clone[row.index].description = description;
      return clone;
    });
  };

  const deleteUniqueValue = (index) => {
    setImportValues((prev) => {
      let cloneValues = [...prev];
      cloneValues.splice(index, 1);
      return cloneValues;
    });
  };

  const saveImport = () => {
    saveValues({
      query: setUniqueValuesByCollection,
      variables: {
        inputModel: {
          id: collectionId,
          name: "",
          uniqueValues: replaceAll
            ? [...importValues]
            : [...currentUniqueValues, ...importValues],
        },
        //conditional based on collectionId
        columnId: columnId,
      },
    });
  };

  useEffect(() => {
    if (data) {
      setShowImportForm((prev) => !prev);
      fetchLatest();
    }
  }, [data, fetchLatest, setShowImportForm]);

  function csvToArray(str, delimiter = ",") {
    // slice from start of text to the first \n index
    // use split to create an array from string by delimiter
    const headers = str.slice(0, str.indexOf("\n")).split(delimiter);

    // slice from \n index + 1 to the end of the text
    // use split to create an array of each csv value row
    const rows = str.slice(str.indexOf("\n") + 1).split("\n");

    // Map the rows
    // split values from each row into an array
    // use headers.reduce to create an object
    // object properties derived from headers:values
    // the object passed as an element of the array
    const arr = rows.map(function (row) {
      const values = row.split(delimiter);
      const el = headers.reduce(function (object, header, index) {
        object[header] = values[index];
        return object;
      }, {});
      return el;
    });

    // return the array
    return arr;
  }

  const handleFile = (e) => {
    const content = e?.target?.result;

    // convert read to array
    let cleanContent = content.replace(/(\r\n)/gm, "\n");

    const csvData = csvToArray(cleanContent);

    // filter data for ONLY values with value
    const filterData = csvData.filter((row) => row?.value);

    // set the imported json
    if (filterData?.length) {
      setImportValues(filterData);
      setImportError();
    } else {
      setImportError([
        {
          message:
            "Failed to import values, please check that the file headers are 'value,description' and usage of comma separator",
        },
      ]);
    }
  };

  const handleChangeFile = (file) => {
    let fileData = new FileReader();
    fileData.onloadend = handleFile;
    fileData.readAsText(file);
  };

  const columnsData = [
    {
      Header: "value",
      id: "value",
      accessor: (d) => d.value,
      Cell: ({ row }) => {
        const [cellValue, setCellValue] = useState(row?.original?.value);

        const onBlur = () => {
          updateValueInImport({
            row: row,
            description: row?.original?.description,
            value: cellValue,
          });
        };

        return (
          <div style={{ maxWidth: "250px" }}>
            <StyledInput
              type="text"
              name="value"
              value={cellValue}
              placeholder={"Value"}
              onChange={(e) => setCellValue(e.target.value)}
              onBlur={onBlur}
            />
          </div>
        );
      },
    },
    {
      Header: "description",
      id: "description",
      accessor: (d) => d.description,
      Cell: ({ row }) => {
        const [cellValue, setCellValue] = useState(row?.original?.description);

        const onBlur = () => {
          updateValueInImport({
            row: row,
            description: cellValue,
            value: row?.original?.value,
          });
        };

        return (
          <div style={{ maxWidth: "250px" }}>
            <StyledInput
              type="text"
              name="value description"
              value={cellValue}
              placeholder={"Value Description"}
              onChange={(e) => setCellValue(e.target.value)}
              onBlur={onBlur}
            />
          </div>
        );
      },
    },
    {
      Header: " ",
      id: "actions",
      width: 220,
      sortable: false,
      Cell: ({ row }) => {
        return (
          <React.Fragment key={`alert-actions-${row?.index}`}>
            <TableButton
              list="true"
              danger
              onClick={() => deleteUniqueValue(row?.index)}
              type="button"
            >
              <MdDelete />
            </TableButton>
          </React.Fragment>
        );
      },
    },
  ];

  return (
    <div>
      <div>
        <h4>Importing Values</h4>
        <p>
          Using a CSV import data with headers: values, description and using a
          comma for separated values. Click the "Choose File" button below and
          select your CSV. Confirm your data and click Save. We suggest doing a
          "Remove All Existing" for an import, as an import will append data to
          the currently set values.
          <h5 style={{ marginTop: "1rem" }}>Example CSV Contents:</h5>
          value,description
          <br />
          NY,New York
          <br />
          NJ,New Jersey
          <br />
          WA,Washington
        </p>
        <div style={{ marginBottom: "1rem" }}>
          <input
            type="file"
            accept=".csv"
            onChange={(e) => handleChangeFile(e?.target?.files[0])}
          />
        </div>
      </div>

      {importError && <ErrorMessages errors={importError} />}
      {errors && <ErrorMessages errors={errors} />}

      {importValues?.length ? (
        <>
          <Scrollbars
            hideTracksWhenNotNeeded={true}
            autoHeightMax={window.innerHeight - 450}
            autoHeight
          >
            <SortTable
              data={importValues}
              columns={columnsData}
              dontReset={true}
              defaultPageSize={50}
              hidePagination={true}
            />
          </Scrollbars>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "1rem",
              alignContent: "center",
            }}
          >
            <Button
              type="button"
              list
              danger
              onClick={() => setShowImportForm((prev) => !prev)}
            >
              Cancel
            </Button>
            <Button
              list
              type="button"
              disabled={isNotValid}
              onClick={() => saveImport()}
            >
              Import {loading ? <Spinner /> : null}
            </Button>
            <label style={{ display: "flex", alignItems: "center" }}>
              <input
                type="checkbox"
                checked={replaceAll}
                onChange={(e) => setReplaceAll(e.target.checked)}
              />
              Replace All Existing Values
            </label>
          </div>
        </>
      ) : null}
    </div>
  );
};

const UniqueForm = ({
  collectionId,
  columnId,
  currentUniqueValues,
  setShowAddUniqueForm,
  fetchLatest,
}) => {
  const [{ errors, loading, data }, saveValues] = useApi();

  const [newValue, setNewValue] = useState("");
  const [newName, setNewName] = useState("");

  const save = () => {
    saveValues({
      query: setUniqueValuesByCollection,
      variables: {
        inputModel: {
          id: collectionId,
          name: "",
          uniqueValues: [
            ...currentUniqueValues,
            {
              collectionId: collectionId,
              description: newName,
              value: newValue,
            },
          ],
        },
        //conditional based on collectionId
        columnId: columnId,
      },
    });
  };

  useEffect(() => {
    if (data) {
      setShowAddUniqueForm((prev) => !prev);
      fetchLatest();
    }
  }, [data, fetchLatest, setShowAddUniqueForm]);

  return (
    <div>
      <h4>Add Unique Value</h4>

      <FormControl>
        <Label>Value</Label>
        <StyledInput
          type="text"
          name="value"
          value={newValue}
          placeholder={"Unique Value"}
          onChange={(e) => setNewValue(e?.target?.value)}
        />
      </FormControl>

      <FormControl>
        <Label>Description</Label>
        <StyledInput
          type="text"
          name="value description"
          value={newName}
          placeholder={"Value Description"}
          onChange={(e) => setNewName(e?.target?.value)}
        />
      </FormControl>

      {errors && <ErrorMessages errors={errors} />}

      <div style={{ display: "flex" }}>
        <div style={{ marginLeft: "auto" }}>
          <Button
            type="button"
            list
            danger
            onClick={() => setShowAddUniqueForm((prev) => !prev)}
          >
            Cancel
          </Button>
          <Button type="button" disabled={!newValue} onClick={() => save()}>
            Save {loading ? <Spinner /> : null}
          </Button>
        </div>
      </div>
    </div>
  );
};

const RemoveAllForm = ({
  collectionId,
  columnId,
  setShowAddUniqueForm,
  setCurrentUniqueValues,
}) => {
  const [{ loading, errors, data }, saveValues] = useApi();

  const save = () => {
    saveValues({
      query: setUniqueValuesByCollection,
      variables: {
        inputModel: {
          id: collectionId,
          name: "",
          uniqueValues: [],
        },
        //conditional based on collectionId
        columnId: columnId,
      },
    });
  };

  useEffect(() => {
    if (data) {
      setShowAddUniqueForm((prev) => !prev);
      // fetchLatest(); Removing Fetch Latest in favor of just clearing state of column values
      setCurrentUniqueValues([]);
    }
  }, [data, setCurrentUniqueValues, setShowAddUniqueForm]);

  return (
    <div>
      <h4 style={{ textAlign: "center" }}>
        Confirm you want to remove all unique values for this column?
      </h4>
      {errors && <ErrorMessages errors={errors} />}
      <div style={{ display: "flex" }}>
        <div style={{ marginLeft: "auto" }}>
          <Button
            type="button"
            list
            danger
            onClick={() => setShowAddUniqueForm((prev) => !prev)}
          >
            Cancel
          </Button>
          <Button type="button" onClick={() => save()}>
            Remove All {loading ? <Spinner /> : null}
          </Button>
        </div>
      </div>
    </div>
  );
};

const ManageUniqueColumns = ({
  column,
  currentStep,
  totalSteps,
  setCurrentStep,
  dispatch,
  isTargets,
  confirmedSources,
  ingressSources,
  setConfirmedSources,
  singleNextStep,
  singleBackStep,
  dataSource,
}) => {
  let currentTagIds = dataSource?.tagInstances?.map((ti) => ti?.tagId) ?? [];
  const isConfigured = currentTagIds.includes(-4);

  const [{ data: apiData }, getSource] = useApi(getUniqueValuesByColumnId, {
    id: column?.id,
  });

  const [, setValues] = useApi();

  useEffect(() => {
    if (!isConfigured) {
      setValues({
        query: setUniqueValuesFromRefreshByCollection,
        variables: {
          columnId: column?.id,
        },
      });
    }
  }, [isConfigured, setValues, column?.id]);

  const reconfigValues = useCallback(() => {
    setValues({
      query: setUniqueValuesFromRefreshByCollection,
      variables: {
        columnId: column?.id,
      },
    });
  }, [column?.id, setValues]);

  const [
    {
      loading: updatingSourceType,
      errors: updateSourceTypeErrors,
      data: sourceTypeData,
    },
    doUpdateSourceType,
  ] = useApi();

  const [
    { errors: saveErrors, loading: valuesLoading, data: saveUniqueValuesData },
    saveValues,
  ] = useApi();

  const saveUniqueValuesLoading = valuesLoading || updatingSourceType;
  const { retrieveUVCNotification, setRetrieveUVCNotification } =
    useContext(SocketContext);

  const {
    servicerTransferUniqueCollectionComplete,
    setServicerTransferUniqueCollectionComplete,
  } = useContext(SocketContext);

  const [fetchingValues, setFetchingValues] = useState(true);
  const [fetchingValuesErrors, setFetchingValuesErrors] = useState();
  const [currentUniqueValues, setCurrentUniqueValues] = useState([]);
  const [showAddUniqueForm, setShowAddUniqueForm] = useState(false);
  const [showImportForm, setShowImportForm] = useState(false);
  const [showClearForm, setShowClearForm] = useState(false);
  const [
    servicerTransferUniqueCollectionCompleteErrors,
    setServicerTransferUniqueCollectionCompleteErrors,
  ] = useState();
  const [nextType, setNextType] = useState();

  useEffect(() => {
    const payload = retrieveUVCNotification?.payload;

    if (payload?.AlertType === 31) {
      //succeeded
      if (payload?.Values?.length) {
        setCurrentUniqueValues(
          payload?.Values.map((v) => {
            return {
              // DEV: not found in update
              // collection: v.Collection,
              collectionId: v.CollectionId,
              description: v.Description,
              id: v.Id,
              // DEV: not found in update
              //keyMapping: v.keyMapping,
              value: v.Value,
              // DEV: not found in update
              // valueMappings: v.ValueMappings,
            };
          }).sort((a, b) => a.id - b.id) ?? []
        );

        setRetrieveUVCNotification(null);
      } else if (payload.ErrorMessage) {
        setFetchingValuesErrors([{ message: payload.ErrorMessage }]);
      } else if (!payload?.Values?.length) {
        setCurrentUniqueValues([]);

        setRetrieveUVCNotification(null);
      }
    }
    setFetchingValues(false);
  }, [retrieveUVCNotification, setRetrieveUVCNotification]);

  useEffect(() => {
    const payload = servicerTransferUniqueCollectionComplete?.payload;

    if (payload?.AlertType === 51) {
      //succeeded
      if (payload.ErrorMessage) {
        setServicerTransferUniqueCollectionCompleteErrors([
          { message: payload.ErrorMessage },
        ]);
        if (payload?.Values?.length) {
          setCurrentUniqueValues(
            payload?.Values.map((v) => {
              return {
                // DEV: not found in update
                // collection: v.Collection,
                collectionId: v.CollectionId,
                description: v.Description,
                id: v.Id,
                // DEV: not found in update
                //keyMapping: v.keyMapping,
                value: v.Value,
                // DEV: not found in update
                // valueMappings: v.ValueMappings,
              };
            }).sort((a, b) => a.id - b.id) ?? []
          );
        }
      } else if (payload?.Values?.length) {
        setCurrentUniqueValues(
          payload?.Values.map((v) => {
            return {
              // DEV: not found in update
              // collection: v.Collection,
              collectionId: v.CollectionId,
              description: v.Description,
              id: v.Id,
              // DEV: not found in update
              //keyMapping: v.keyMapping,
              value: v.Value,
              // DEV: not found in update
              // valueMappings: v.ValueMappings,
            };
          }).sort((a, b) => a.id - b.id) ?? []
        );
        setServicerTransferUniqueCollectionCompleteErrors();
      } else {
        setServicerTransferUniqueCollectionCompleteErrors();
      }
    }
  }, [
    servicerTransferUniqueCollectionComplete,
    setServicerTransferUniqueCollectionComplete,
  ]);

  useEffect(() => {
    if (apiData) {
      setFetchingValues(true);
    }
  }, [apiData]);

  const fetchLatest = useCallback(() => {
    getSource({
      query: getUniqueValuesByColumnId,
      variables: {
        id: column?.id,
      },
    });
  }, [column, getSource]);

  const updateMyData = ({ original, description, value }) => {
    setCurrentUniqueValues((prev) => {
      let clone = [...prev];

      const index = clone.findIndex((item) => item.id === original?.id);
      clone[index].value = value;
      clone[index].description = description;
      return clone;
    });
  };

  //disabled the skip reset so you can page again
  // React.useEffect(() => {
  //   setSkipPageReset(false);
  // }, [currentUniqueValues]);

  useEffect(() => {
    if (saveUniqueValuesData && nextType) {
      switch (nextType) {
        case "basicNextStep": {
          setCurrentStep((prev) => prev + 1);
          break;
        }
        // case "changeState10": {
        //   dispatch({ type: "CHANGE_STATE", payload: 10 });
        //   break;
        // }
        case "nextSetConfirmedSources": {
          setConfirmedSources((prev) => prev + 1);
          break;
        }
        case "changeState": {
          singleNextStep
            ? singleNextStep()
            : dispatch({ type: "CHANGE_STATE", payload: isTargets ? 10 : 6 });
          break;
        }
        case "goBack": {
          singleBackStep
            ? singleBackStep()
            : dispatch({ type: "CHANGE_STATE", payload: isTargets ? 8 : 3 });
          break;
        }
        default: {
        }
      }
    } else if (saveUniqueValuesData) {
      fetchLatest();
    }
  }, [
    saveUniqueValuesData,
    nextType,
    isTargets,
    dispatch,
    setConfirmedSources,
    setCurrentStep,
    fetchLatest,
    singleNextStep,
    singleBackStep,
    sourceTypeData,
  ]);

  const saveUniqueValues = (nextAction) => {
    // step types
    // -------------------------
    // basicNextStep
    // changeState10
    // nextSetConfirmedSources
    // changeState
    // -------------------------
    // 1 setCurrentStep((prev) => prev + 1)
    // 2 dispatch({ type: "CHANGE_STATE", payload: 10 })
    // 3 setConfirmedSources((prev) => prev + 1);
    // 4 dispatch({ type: "CHANGE_STATE", payload: 10 })
    // 5 setConfirmedSources((prev) => prev + 1);
    // 6 setCurrentStep((prev) => prev + 1)
    // 7 dispatch({ type: "CHANGE_STATE", payload: isTargets ? 10 : 6 })
    // 8 setCurrentStep((prev) => prev + 1)
    // 9 dispatch({ type: "CHANGE_STATE", payload: isTargets ? 10 : 6 })
    // 10 setCurrentStep((prev) => prev + 1)
    setNextType(nextAction);
    saveValues({
      query: setUniqueValuesByCollection,
      variables: {
        columnId: column?.id,
        inputModel: {
          id: column?.uniqueValueCollectionId,
          name: "",
          uniqueValues: [...currentUniqueValues],
        },
      },
    });

    //Set Configured to Source state

    let currentTagIds = dataSource?.tagInstances?.map((ti) => ti?.tagId) ?? [];
    if (!currentTagIds.includes(-4)) {
      doUpdateSourceType({
        query: setTagInstances,
        variables: {
          remoteObjectId: dataSource?.id,
          tagIds: [...currentTagIds, -4],
          type: "DATA_SOURCE",
        },
      });
    }
  };

  const deleteUniqueValue = (id) => {
    let cloneValues = [...currentUniqueValues];
    cloneValues = cloneValues.filter((cv) => cv.id !== id);

    saveValues({
      query: setUniqueValuesByCollection,
      variables: {
        inputModel: {
          id: column?.uniqueValueCollectionId,
          name: "",
          uniqueValues: [...cloneValues],
        },
      },
    });
  };

  const columnsData = [
    {
      Header: "Value",
      id: "value",
      accessor: (d) => d.value,
      Cell: ({ row: { original } }) => {
        const [cellValue, setCellValue] = useState(original?.value);

        const onBlur = () => {
          updateMyData({
            original: original,
            description: original?.description,
            value: cellValue,
          });
        };

        return (
          <div style={{ maxWidth: "250px" }}>
            <StyledInput
              type="text"
              name="value"
              value={cellValue}
              placeholder={"Value"}
              onChange={(e) => setCellValue(e.target.value)}
              onBlur={onBlur}
            />
          </div>
        );
      },
    },
    {
      Header: "Description",
      id: "description",
      accessor: (d) => d.description,
      Cell: ({ row: { original } }) => {
        const [cellValue, setCellValue] = useState(original?.description);

        const onBlur = () => {
          updateMyData({
            original: original,
            description: cellValue,
            value: original?.value,
          });
        };

        return (
          <div style={{ maxWidth: "250px" }}>
            <StyledInput
              type="text"
              name="value description"
              value={cellValue}
              placeholder={"Value Description"}
              onChange={(e) => setCellValue(e.target.value)}
              onBlur={onBlur}
            />
          </div>
        );
      },
    },
    {
      Header: " ",
      id: "actions",
      width: 220,
      sortable: false,
      Cell: ({ row: { original } }) => {
        return (
          <React.Fragment key={`alert-actions-${original.id}`}>
            <TableButton
              list="true"
              danger
              onClick={() => deleteUniqueValue(original.id)}
              type="button"
            >
              <MdDelete />
            </TableButton>
          </React.Fragment>
        );
      },
    },
  ];
  if (fetchingValues) return <SplashLoader text={"Loading Unique Values"} />;
  return (
    <div>
      <h3>{column?.name}</h3>
      <h4>Update and or confirm expected unique values for {column?.name}.</h4>

      {fetchingValuesErrors ? (
        <ErrorMessages errors={fetchingValuesErrors} />
      ) : null}

      {servicerTransferUniqueCollectionCompleteErrors ? (
        <ErrorMessages
          errors={servicerTransferUniqueCollectionCompleteErrors}
        />
      ) : null}

      {saveErrors ? <ErrorMessages errors={saveErrors} /> : null}

      {updateSourceTypeErrors ? (
        <ErrorMessages errors={updateSourceTypeErrors} />
      ) : null}

      <div style={{ display: "flex", marginTop: "1rem" }}>
        <div>
          <Button
            type="button"
            list
            danger={showAddUniqueForm}
            onClick={() => {
              setShowAddUniqueForm((prev) => !prev);
            }}
          >
            {showAddUniqueForm ? "Cancel" : "Add Value"}
          </Button>

          <Button
            type="button"
            danger={showImportForm}
            onClick={() => {
              setShowImportForm((prev) => !prev);
            }}
          >
            {showImportForm ? "Cancel" : "Import Values"}
          </Button>
        </div>
        {currentUniqueValues?.length ? (
          <div style={{ marginLeft: "auto" }}>
            <Button
              type="button"
              list
              danger={true}
              onClick={() => {
                reconfigValues();
              }}
            >
              {"Reset Values from Default"}
            </Button>
            <Button
              list
              type="button"
              danger={true}
              onClick={() => {
                setShowClearForm((prev) => !prev);
              }}
            >
              {showClearForm ? "Cancel" : "Remove All"}
            </Button>
          </div>
        ) : (
          <div style={{ marginLeft: "auto" }}>
            <Button
              type="button"
              list
              danger={true}
              onClick={() => {
                reconfigValues();
              }}
            >
              {"Reset Values from Default"}
            </Button>
          </div>
        )}
      </div>
      <div
        style={{
          marginTop: "1rem",
          marginBottom: "1rem",
          position: "relative",
        }}
      >
        {showClearForm && (
          <Modal hide={() => setShowClearForm(false)}>
            <RemoveAllForm
              collectionId={column?.uniqueValueCollectionId}
              columnId={column?.id}
              setShowAddUniqueForm={setShowClearForm}
              currentUniqueValues={currentUniqueValues}
              setCurrentUniqueValues={setCurrentUniqueValues}
            />
          </Modal>
        )}

        {showAddUniqueForm && (
          <Modal hide={() => setShowAddUniqueForm(false)}>
            <UniqueForm
              collectionId={column?.uniqueValueCollectionId}
              columnId={column?.id}
              setShowAddUniqueForm={setShowAddUniqueForm}
              currentUniqueValues={currentUniqueValues}
              fetchLatest={fetchLatest}
            />
          </Modal>
        )}

        {showImportForm && (
          <Modal
            title={`Import Unique Values for ${column?.name}`}
            hide={() => setShowImportForm(false)}
          >
            <ImportForm
              collectionId={column?.uniqueValueCollectionId}
              columnId={column?.id}
              setShowImportForm={setShowImportForm}
              currentUniqueValues={currentUniqueValues}
              fetchLatest={fetchLatest}
            />
          </Modal>
        )}
        {currentUniqueValues?.length ? (
          <>
            <SortTable
              data={currentUniqueValues}
              columns={columnsData}
              dontReset={true}
              defaultPageSize={50}
            />

            <Button
              type="button"
              list
              danger={showAddUniqueForm}
              onClick={() => {
                setShowAddUniqueForm((prev) => !prev);
              }}
            >
              {showAddUniqueForm ? "Cancel" : "Add Value"}
            </Button>
            <Button
              type="button"
              danger={showImportForm}
              onClick={() => {
                setShowImportForm((prev) => !prev);
              }}
            >
              {showImportForm ? "Cancel" : "Import Values"}
            </Button>
          </>
        ) : (
          "No Unique Values Found, Please Add or Import Values"
        )}
      </div>

      <div>
        <UniqueNavigation
          currentStep={currentStep}
          totalSteps={totalSteps}
          setCurrentStep={setCurrentStep}
          dispatch={dispatch}
          isTargets={isTargets}
          confirmedSources={confirmedSources}
          ingressSources={ingressSources}
          setConfirmedSources={setConfirmedSources}
          saveUniqueValues={saveUniqueValues}
          saveUniqueValuesLoading={saveUniqueValuesLoading}
        />
      </div>
    </div>
  );
};

const UniqueNavigation = ({
  currentStep,
  totalSteps,
  dispatch,
  isTargets,
  setCurrentStep,
  confirmedSources,
  ingressSources,
  saveUniqueValues,
  saveUniqueValuesLoading,
}) => {
  const totalNumberOfSteps = totalSteps - 1;
  const totalNumberOfTargetSources = isTargets ? ingressSources?.length - 1 : 0;

  if (isTargets) {
    if (currentStep === 0) {
      // is First Step
      return (
        <>
          <Button list onClick={() => saveUniqueValues("goBack")}>
            Back
          </Button>

          {currentStep < totalNumberOfSteps ? (
            <Button onClick={() => saveUniqueValues("basicNextStep")}>
              Save & Continue {saveUniqueValuesLoading ? <Spinner /> : null}
            </Button>
          ) : confirmedSources >= totalNumberOfTargetSources ? (
            <Button onClick={() => saveUniqueValues("changeState")}>
              Save {saveUniqueValuesLoading ? <Spinner /> : null}
            </Button>
          ) : (
            <Button
              onClick={() => {
                saveUniqueValues("nextSetConfirmedSources");
              }}
            >
              Save & Continue {saveUniqueValuesLoading ? <Spinner /> : null}
            </Button>
          )}
        </>
      );
    } else if (currentStep === totalNumberOfSteps) {
      // is Last step
      return (
        <>
          <Button
            list
            onClick={() =>
              setCurrentStep((prev) => (prev - 1 >= 0 ? prev - 1 : 0))
            }
          >
            Back
          </Button>
          {confirmedSources >= totalNumberOfTargetSources ? (
            <Button onClick={() => saveUniqueValues("changeState")}>
              Save {saveUniqueValuesLoading ? <Spinner /> : null}
            </Button>
          ) : (
            <Button
              onClick={() => {
                saveUniqueValues("nextSetConfirmedSources");
              }}
            >
              Save & Continue {saveUniqueValuesLoading ? <Spinner /> : null}
            </Button>
          )}
        </>
      );
    } else if (currentStep < totalNumberOfSteps) {
      // Has more steps
      return (
        <>
          <Button
            list
            onClick={() =>
              setCurrentStep((prev) => (prev - 1 >= 0 ? prev - 1 : 0))
            }
          >
            Back
          </Button>

          <Button onClick={() => saveUniqueValues("basicNextStep")}>
            Save & Continue {saveUniqueValuesLoading ? <Spinner /> : null}
          </Button>
        </>
      );
    }
  } else {
    if (currentStep === 0) {
      // is First Step
      return (
        <>
          <Button list onClick={() => saveUniqueValues("goBack")}>
            Back
          </Button>

          {currentStep >= totalNumberOfSteps ? (
            <Button onClick={() => saveUniqueValues("changeState")}>
              Save {saveUniqueValuesLoading ? <Spinner /> : null}
            </Button>
          ) : (
            <Button onClick={() => saveUniqueValues("basicNextStep")}>
              Save & Continue {saveUniqueValuesLoading ? <Spinner /> : null}
            </Button>
          )}
        </>
      );
    } else if (currentStep === totalNumberOfSteps) {
      // is Last step
      return (
        <>
          <Button
            list
            onClick={() =>
              setCurrentStep((prev) => (prev - 1 >= 0 ? prev - 1 : 0))
            }
          >
            Back
          </Button>
          <Button onClick={() => saveUniqueValues("changeState")}>
            Save {saveUniqueValuesLoading ? <Spinner /> : null}
          </Button>
        </>
      );
    } else if (currentStep < totalNumberOfSteps) {
      // Has more steps
      return (
        <>
          <Button
            list
            onClick={() =>
              setCurrentStep((prev) => (prev - 1 >= 0 ? prev - 1 : 0))
            }
          >
            Back
          </Button>

          <Button onClick={() => saveUniqueValues("basicNextStep")}>
            Save & Continue {saveUniqueValuesLoading ? <Spinner /> : null}
          </Button>
        </>
      );
    }
  }
};

const UniqueColumnConfirmStates = ({
  uniqueColumns,
  dispatch,
  isTargets,
  setConfirmedSources,
  confirmedSources,
  ingressSources,
  singleNextStep,
  singleBackStep,
  dataSource,
  sourceType,
}) => {
  const [currentStep, setCurrentStep] = useState(0);
  const totalSteps = uniqueColumns?.length; //just short it to zero for array index

  const columnJumpOptions =
    uniqueColumns.map((c, i) => {
      return {
        ...c,
        value: i,
        label: c.name,
      };
    }) ?? [];

  return (
    <div>
      {/* Debug */}
      {totalSteps > 1 && (
        <FormControl>
          <Label>Jump To Column</Label>
          <StyledSelect
            className={`react-select-container`}
            classNamePrefix={`react-select`}
            name={`jumpToColumn`}
            label="Jump To Column"
            id={`jumpToColumn`}
            inputId={`jumpToColumn-input`}
            instanceId={`jumpToColumn-instance`}
            options={columnJumpOptions}
            placeholder={`Select Convention`}
            value={columnJumpOptions.find((nc) => nc?.value === currentStep)}
            menuPortalTarget={document.body}
            menuPlacement="auto"
            onChange={(e) => setCurrentStep(e.value)}
          />
        </FormControl>
      )}

      <p>
        column {currentStep + 1} of {totalSteps}
      </p>

      {uniqueColumns[currentStep] && (
        <ManageUniqueColumns
          key={uniqueColumns[currentStep]?.id}
          column={uniqueColumns[currentStep]}
          setConfirmedSources={setConfirmedSources}
          currentStep={currentStep}
          totalSteps={totalSteps}
          setCurrentStep={setCurrentStep}
          dispatch={dispatch}
          isTargets={isTargets}
          confirmedSources={confirmedSources}
          ingressSources={ingressSources}
          singleNextStep={singleNextStep}
          singleBackStep={singleBackStep}
          dataSource={dataSource}
          sourceType={sourceType}
        />
      )}
    </div>
  );
};

const ColumnView = ({
  sourceId,
  state,
  dispatch,
  isTargets,
  setConfirmedSources,
  ingressSources,
  confirmedSources,
  singleNextStep,
  singleBackStep,
  sourceType,
}) => {
  const [{ loading, data: apiData }, getSource] = useApi();

  const columns = apiData?.dataSource?.columns ?? [];
  const dataSource = apiData?.dataSource;
  function isCodeBreaker(column) {
    const flags = column?.flags;

    const isCodeBreak = (flags & 64) > 0;
    if (isCodeBreak) {
      return column;
    } else {
      return false;
    }
  }

  const columnsWithCodeBreak = columns?.filter(isCodeBreaker) ?? [];

  useEffect(() => {
    getSource({
      query: dataSourceColumns,
      variables: { id: Number(sourceId) },
    });
  }, [sourceId, getSource]);

  const [
    {
      // loading: updatingSourceType,
      // errors: updateSourceTypeErrors,
      data: sourceTypeData,
    },
    doUpdateSourceType,
  ] = useApi();

  useEffect(() => {
    if (sourceTypeData) {
      const isLastSource = confirmedSources + 1 === ingressSources?.length;

      if (!isLastSource && isTargets) {
        setConfirmedSources((prev) => prev + 1);
      } else {
        singleNextStep
          ? singleNextStep()
          : dispatch({ type: "CHANGE_STATE", payload: isTargets ? 10 : 6 });
      }
    }
  }, [
    setConfirmedSources,
    singleNextStep,
    sourceTypeData,
    dispatch,
    confirmedSources,
    isTargets,
    ingressSources?.length,
  ]);

  const updateEmptyUnique = () => {
    let currentTagIds = dataSource?.tagInstances?.map((ti) => ti?.tagId) ?? [];

    if (!currentTagIds.includes(-4)) {
      doUpdateSourceType({
        query: setTagInstances,
        variables: {
          remoteObjectId: dataSource?.id,
          tagIds: [...currentTagIds, -4],
          type: "DATA_SOURCE",
        },
      });
    } else {
      const isLastSource = confirmedSources + 1 === ingressSources?.length;

      if (!isLastSource && isTargets) {
        setConfirmedSources((prev) => prev + 1);
      } else {
        singleNextStep
          ? singleNextStep()
          : dispatch({ type: "CHANGE_STATE", payload: isTargets ? 10 : 6 });
      }
    }
  };

  if (loading) return <SplashLoader text="Loading Data" />;

  if (!columnsWithCodeBreak?.length)
    return (
      <div>
        <div style={{ marginBottom: "1rem" }}>
          No Code Break Columns have been selected.
        </div>
        <Button
          onClick={() => {
            updateEmptyUnique();
          }}
        >
          Continue
        </Button>
      </div>
    );

  return (
    <>
      <h3>Confirm Unique Values for {apiData?.dataSource?.name}</h3>

      <UniqueColumnConfirmStates
        key={columnsWithCodeBreak}
        uniqueColumns={columnsWithCodeBreak}
        state={state}
        dispatch={dispatch}
        isTargets={isTargets}
        setConfirmedSources={setConfirmedSources}
        ingressSources={ingressSources}
        confirmedSources={confirmedSources}
        singleNextStep={singleNextStep}
        singleBackStep={singleBackStep}
        dataSource={dataSource}
        sourceType={sourceType}
      />
      <ToastContainer />
    </>
  );
};

export default ColumnView;
