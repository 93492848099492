import React, { useState, useContext, useEffect } from "react";
import styled from "styled-components/macro";
import { priorityLevelOptions } from "../../common/formOptions";
import { useApi } from "../../api/useApiRedux";
import Spinner from "../../components/Loaders/Spinner";
import ErrorMessages from "../../components/Notifications/ErrorMessages";
import {
  StyledSelect,
  FormControl,
  Label,
  StyledTextArea,
  FormActions,
} from "../../components/Form/FormControls";
import { MdNotifications } from "react-icons/md";
import Button from "../../components/Button";
import Secondary from "../../components/Button/Secondary";
import { AuthContext } from "../../contexts/AuthContext";
import { sortByAlpha } from "../../common/helpers/util";
import debounce from "lodash.debounce";
import RenderNotes from "./RenderNotes";

const IssueDetails = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1.5rem;
`;

const IssueDetailsTypeContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
`;

const IssueDetailsType = styled.div`
  font-family: "Source Sans Pro Semibold";
  padding-left: 0.5rem;
`;

const IssueInfo = styled.div`
  display: flex;
  margin-bottom: 1.8rem;
`;

const IssueInfoBump = styled.div`
  padding-right: 2rem;
`;

const IssueInfoSection = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
`;

const SectionSpacer = styled.div`
  border-bottom: 1px solid #ccc;
  margin-bottom: 1.8rem;
`;

const statusOptions = [
  { label: "Active", value: "Active" },
  { label: "Resolved", value: "Resolved" },
  { label: "Closed", value: "Closed", isDisabled: true }, // disable as option //once in closed its closed
];

//add Notes below
const RenderRecurrence = ({ status, recurrence }) => {
  if (recurrence === 1 && status !== "Closed") {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            color: "#0198FF",
            marginRight: ".1rem",
          }}
        >
          <MdNotifications />
        </div>
        <div>New</div>
      </div>
    );
  } else {
    return recurrence;
  }
};

const IssueRowDetails = ({ row, toggle, setRowAfterForm }) => {
  const issueDetails = JSON.parse(row?.issueDetails);

  const [stateUsers, setStateUsers] = useState();

  const [
    { errors: getUserErrors, loading: loadingUsers, data: usersData },
    getUsers,
  ] = useApi();

  useEffect(() => {
    if (usersData) {
      const userOptions = usersData?.data?.map((user) => {
        return {
          label: user?.emailAddress,
          value: user?.id,
          type: "user",
        };
      });

      const sortedUserOptions = sortByAlpha(userOptions, "label");
      setStateUsers(sortedUserOptions);
    } else {
      setStateUsers([]);
    }
  }, [usersData]);

  const initialState = {
    priority:
      priorityLevelOptions.find(
        (dt) => dt.value.toLowerCase() === row?.priority.toLowerCase()
      ) ?? null,
    status:
      statusOptions.find(
        (dt) => dt.value.toLowerCase() === row?.status.toLowerCase()
      ) ?? null,
  };

  const [priority, setPriority] = useState(initialState?.priority);
  const [status, setStatus] = useState(initialState?.status);
  const [assignees, setAssignees] = useState(row?.assignees);
  const [body, setBody] = useState(null);
  const [note, setNote] = useState("");

  const [{ errors, loading, data }, doSubmit] = useApi();

  const { user } = useContext(AuthContext);

  useEffect(() => {
    if (data && !errors?.length) {
      setRowAfterForm(data);
      toggle(null);
    }
  }, [data, body, errors, setRowAfterForm, toggle]);

  const UpdateIssue = () => {
    const cleanAssigned = assignees?.length
      ? assignees.map((user) => ({
          id: 0,
          userId: user?.value,
        }))
      : [];

    const body = {
      priority: priority?.label,
      assignees: cleanAssigned,
      status: status?.value,
      notify: row?.notify,
      note: note ? { id: 0, note, userId: Number(user?.id) } : null,
    };

    // if (status?.value.toLocaleLowerCase() !== row?.status.toLocaleLowerCase()) {
    //   body.notes.push({ id: 0, note, userId: Number(user?.id) });
    // }

    setBody({ row: row?.id, body });
    doSubmit({
      query: `/Issues/${row?.id}`,
      method: "POST",
      body: body,
    });
  };

  const [inputText, setInputText] = React.useState("");
  const [searchText, setSearchText] = React.useState("");

  useEffect(() => {
    if (searchText) {
      getUsers({
        query: `/search/users?query=${searchText}`,
        method: "GET",
      });
    } else {
      setStateUsers([]);
    }
  }, [searchText, getUsers, setStateUsers]);

  const setSearchTextDebounced = React.useRef(
    debounce((searchText) => setSearchText(searchText), 500)
  ).current;

  const handleInputChangePrimary = (inputText, event) => {
    // prevent outside click from resetting inputText to ""
    if (event.action !== "input-blur" && event.action !== "menu-close") {
      setInputText(inputText);
      setSearchTextDebounced(inputText);
    }
  };

  return (
    <div key={`RowDetails-${row?.id}`}>
      <IssueDetails>
        <IssueDetailsTypeContainer>
          Type: <IssueDetailsType>{row?.type}</IssueDetailsType>
        </IssueDetailsTypeContainer>
      </IssueDetails>

      <IssueInfo>
        <IssueInfoBump># Failed documents​: {row?.failureCount}</IssueInfoBump>
        <IssueInfoSection>
          Recurrence:{" "}
          <RenderRecurrence
            status={row?.status}
            recurrence={row?.reoccurrenceCount}
          />
        </IssueInfoSection>
      </IssueInfo>

      <div style={{ marginBottom: "1rem" }}>
        <h4>Failed Documents</h4>
        <ul>
          {issueDetails?.ReadFailureDocuments.map((fileName) => {
            return (
              <li style={{ padding: ".5rem", paddingLeft: 0 }}>{fileName}</li>
            );
          })}
        </ul>
      </div>

      <SectionSpacer />

      <FormControl>
        <Label>Priority:</Label>
        <StyledSelect
          data-testid={"data-priority-level"}
          className={`react-select-container`}
          classNamePrefix={`react-select`}
          name={`priority`}
          id={`priority`}
          inputId={`priority-input`}
          instanceId={`priority-instance`}
          label="Data Priority Level"
          options={priorityLevelOptions}
          placeholder={`Data Priority`}
          value={priority}
          onChange={(e) => setPriority(e)}
          menuPortalTarget={document.body}
        />
      </FormControl>

      <FormControl>
        <Label>Owner</Label>
        <StyledSelect
          className={`react-select-container`}
          classNamePrefix={`react-select`}
          name={`owner`}
          id={`owner`}
          inputId={`owner-input`}
          instanceId={`owner-instance`}
          label="Owner"
          options={stateUsers}
          placeholder={`Unassigned`}
          value={assignees ?? []}
          menuPlacement="auto"
          isMulti
          inputValue={inputText}
          isLoading={loadingUsers}
          onChange={(e) => setAssignees(e)}
          onInputChange={handleInputChangePrimary}
          menuPortalTarget={document.body}
        />
        {getUserErrors ? <ErrorMessages errors={getUserErrors} /> : null}
      </FormControl>

      <FormControl>
        <Label>Status</Label>
        <StyledSelect
          className={`react-select-container`}
          classNamePrefix={`react-select`}
          name={`status`}
          id={`status`}
          inputId={`status-input`}
          instanceId={`status-instance`}
          label="Status"
          options={statusOptions}
          placeholder={`Active`}
          value={status}
          menuPlacement="auto"
          onChange={(e) => setStatus(e)}
          menuPortalTarget={document.body}
        />
      </FormControl>

      <FormControl>
        <StyledTextArea
          name={`comment`}
          req={
            status?.value.toLocaleLowerCase() !==
            row?.status.toLocaleLowerCase()
          }
          placeholder="Enter a comment"
          label="Comment "
          component="textarea"
          rows="10"
          onChange={(e) => setNote(e)}
        />
      </FormControl>

      <FormActions>
        <Secondary
          type="button"
          list="true"
          onClick={() => {
            toggle(null);
          }}
        >
          Cancel
        </Secondary>

        <Button
          type="submit"
          disabled={
            status?.value.toLocaleLowerCase() !==
              row?.status.toLocaleLowerCase() && !note
          }
          onClick={() => UpdateIssue()}
        >
          {loading ? <Spinner /> : "Save"}
        </Button>
        {errors ? <ErrorMessages errors={errors} /> : null}
      </FormActions>
      {row?.notes?.length ? (
        <RenderNotes
          row={row}
          pinned={row?.pinnedNote}
          notes={row?.notes}
          setRowAfterForm={setRowAfterForm}
          toggle={toggle}
        />
      ) : null}
    </div>
  );
};

export default IssueRowDetails;
