import React from "react";
import SortTable from "../../Table/SortTable";
import { useApi } from "../../../api/useApi";
import { dataSourceColumns } from "../../../api/dataSourceQueries";
import ErrorMessages from "../../Notifications/ErrorMessages";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const DataProfile = ({ sourceId, data }) => {
  const [{ loading, errors, data: apiData }] = useApi(dataSourceColumns, {
    id: Number(sourceId),
  });

  const columns = apiData?.dataSource?.columns ?? [];
  const columnProfiles =
    data?.dataSource?.reportById?.columnProfiles?.profileResults ?? data?.dataSource.reportByIdWithAlert?.columnProfiles?.profileResults ?? [];

  const columnsData = [
    {
      Header: "Column Name",
      id: "name",
      accessor: (d) => d?.name,
    },
    {
      Header: "Data Type",
      id: "datatype",
      accessor: (d) => d.dataType,
    },

    {
      Header: "Non Null Percentage",
      id: "percentNonNull",
      Cell: ({ row: { original } }) => {
        const columnId = original?.id;
        const findPercent = columnProfiles.find(
          (cp) =>
            cp?.columnId === columnId &&
            cp?.valueSubType === "NonNullPercentage"
        );

        return <>{findPercent?.value}%</>;
      },
    },
    {
      Header: "Unique Percentage",
      id: "uniquePercentage",
      Cell: ({ row: { original } }) => {
        const columnId = original?.id;
        const findPercent = columnProfiles.find(
          (cp) =>
            cp?.columnId === columnId && cp?.valueSubType === "UniquePercentage"
        );

        return <>{findPercent?.value}%</>;
      },
    },
  ];

  return (
    <>
      <ErrorMessages errors={[...(errors ?? [])]} />
      <SortTable
        dontReset={true}
        data={columns}
        columns={columnsData}
        loading={loading}
      />
      <ToastContainer />
    </>
  );
};

export default DataProfile;
