import { useContext } from "react";
import { SocketContext } from "../contexts/useSubscriptions";

const UseETLProviderPipelineBrowseNotification = () => {
  const { etlProviderPipelineBrowseCompleted, setEtlProviderBrowseCompleted } =
    useContext(SocketContext);

  return { etlProviderPipelineBrowseCompleted, setEtlProviderBrowseCompleted };
};

export default UseETLProviderPipelineBrowseNotification;
