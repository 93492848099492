import React from "react";
import { useFormikContext } from "formik";
import SortTable from "../../Table/SortTable";
import { removeGroup } from "../../../api/workgroupMutations";
import styled from "styled-components";
import TableButton from "../../Button/TableButton";

const SingleRowFlex = styled.div`
  display: flex;
  align-items: center;
`;

const DisplayName = styled.div`
  flex: 1;
`;

const SelectedGroups = React.memo(({ workGroupId, updateWorkGroup }) => {
  const {
    values: { groups },
  } = useFormikContext();

  const removeSelection = (original) => {
    const variables = {
      workGroupId: workGroupId,
      groupId: original?.id,
    };
    updateWorkGroup({ query: removeGroup, variables: variables });
  };

  const userDataColumns = [
    {
      Header: "Name",
      id: "name",
      accessor: (d) => d.displayName,
      Cell: ({ row: { original } }) => {
        return (
          <>
            <SingleRowFlex>
              <DisplayName>{original.displayName}</DisplayName>{" "}
              <TableButton
                type="button"
                danger={true}
                onClick={() => removeSelection(original)}
              >
                x
              </TableButton>
            </SingleRowFlex>
          </>
        );
      },
    },
  ];

  return <SortTable data={groups} columns={userDataColumns} />;
});

export default SelectedGroups;
