import React, { Component } from "react";
import Button from "../../components/Button";
import {
  FormControl,
  StyledField,
  FormActions,
  ServerError,
  ErrorMessage,
} from "../../components/Form/FormControls";
import { Formik, Form } from "formik";

import Spinner from "../../components/Loaders/Spinner";
import SpinningLoader from "../../components/Loaders/SpinningLoader";
import * as Api from "../../api";
import { isProductConfigured } from "../../api/configurationQueries";
import { updatePlatformConfiguration } from "../../api/configurationMutations";
import Card from "../../components/Card";

export default class AdminPage extends Component {
  constructor() {
    super();
    this.state = {
      error: null,
      config: null,
      loading: true,
      serverStatus: true,
    };
  }

  //Initialize Abort Controller
  abortController = new AbortController();

  componentDidMount() {}

  componentWillUnmount() {
    this.abortController.abort();
  }

  render() {
    const { error, loading, config, serverStatus } = this.state;

    if (loading) return <SpinningLoader text="Loading Config Data" />;
    function Body() {
      return (
        <>
          <p>
            After Editing your application settings, your application will
            restart.
          </p>
          {!serverStatus ? <p>Server is restarting.</p> : null}
          <Formik
            initialValues={{
              adminEmailAddress: config ? config.adminEmailAddress : "",
            }}
            validate={(values) => {
              let errors = {};
              if (!values.adminEmailAddress) {
                errors.adminEmailAddress = "Required";
              }

              return errors;
            }}
            onSubmit={(values) => this.submitForm(values)}
          >
            {({ isValid, isSubmitting }) => {
              return (
                <Form>
                  <FormControl>
                    <StyledField
                      name={`adminEmailAddress`}
                      type="text"
                      placeholder="Admin Email Address"
                      label="Admin Email Address"
                    />
                    <ErrorMessage name={`adminEmailAddress`} />
                  </FormControl>

                  <FormActions>
                    <FormControl>
                      {error ? <ServerError msg={error} /> : null}
                      <Button type="submit" disabled={isSubmitting || !isValid}>
                        {isSubmitting ? <Spinner /> : "Submit"}
                      </Button>
                    </FormControl>
                  </FormActions>
                </Form>
              );
            }}
          </Formik>
        </>
      );
    }
    return (
      <>
        <Card
          title={"Product Configuration"}
          titleDescription={"Update Product Setting"}
          body={Body}
        />
      </>
    );
  }

  submitForm = (values) => {
    Api.request(
      updatePlatformConfiguration,
      { newConfiguration: values },
      this.abortController.signal
    )
      .then(() => {
        this.setState({ serverStatus: false }, this.pollServer);
      })
      .catch((err) => {
        if (!this.abortController.signal.aborted)
          this.setState({ error: err.message });
      });
  };

  pollServer = () => {
    setTimeout(() => {
      poll(
        Api.request(isProductConfigured, null, this.abortController.signal),
        2000,
        150
      )
        .then((res) => {
          if (res.data.platformConfiguration) {
            this.setState({ serverStatus: true });
          }
        })
        .catch((err) => {
          console.log("error", err);
        });
    }, 5000);
  };
}

function poll(func, timeout, interval) {
  let endTime = Number(new Date()) + (timeout || 2000);
  interval = interval || 100;

  const checkCondition = (resolve, reject) => {
    func.then((res) => {
      if (res.data.platformConfiguration) {
        resolve(res.data.platformConfiguration);
      } else if (Number(new Date()) < endTime) {
        setTimeout(checkCondition, interval, resolve, reject);
      } else {
        reject(new Error("timed out"));
      }
    });
  };
  return new Promise(checkCondition);
}
