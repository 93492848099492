import React, { useState, useEffect } from "react";
import { useApi as useApiRedux } from "../../../api/useApiRedux";
import debounce from "lodash.debounce";
import { FormControl, StyledSelect, Label } from "../../Form/FormControls";
import ErrorMessages from "../../Notifications/ErrorMessages";
import { dataSource } from "../../../common/paths";
import { useHistory } from "react-router-dom";

const SearchDataSources = ({ connectionId, action }) => {
  const [stateSources, setStateSources] = useState();
  let history = useHistory();
  const [{ errors, loading, data: sourceData }, getSources] = useApiRedux();

  useEffect(() => {
    if (sourceData) {
      const sourceOptions = sourceData?.data?.map((s) => {
        return {
          label: s?.name,
          value: s?.id,
        };
      });

      setStateSources(sourceOptions);
    } else {
      setStateSources([]);
    }
  }, [sourceData]);

  const [inputText, setInputText] = React.useState("");
  const [searchText, setSearchText] = React.useState("");

  useEffect(() => {
    if (searchText) {
      getSources({
        query: connectionId
          ? `/search/datasources?query=${searchText}&connectionId=${connectionId}`
          : `/search/datasources?query=${searchText}`,
        method: "GET",
      });
    } else {
      setStateSources([]);
    }
  }, [searchText, getSources, setStateSources, connectionId]);

  const setSearchTextDebounced = React.useRef(
    debounce((searchText) => setSearchText(searchText), 500)
  ).current;

  const handleInputChangePrimary = (inputText, event) => {
    // prevent outside click from resetting inputText to ""
    if (event.action !== "input-blur" && event.action !== "menu-close") {
      setInputText(inputText);
      setSearchTextDebounced(inputText);
    }
  };

  return (
    <FormControl style={{ maxWidth: "300px" }}>
      <Label>Search</Label>
      <StyledSelect
        className={`react-select-container`}
        classNamePrefix={`react-select`}
        name={`NameSearch`}
        id={`NameSearch`}
        inputId={`NameSearch-input`}
        instanceId={`NameSearch-instance`}
        label="Sources"
        options={stateSources}
        placeholder={`Source Name`}
        menuPlacement="auto"
        inputValue={inputText}
        isLoading={loading}
        onChange={(e) =>
          action ? action(e?.value) : history.push(dataSource(e?.value))
        }
        onInputChange={handleInputChangePrimary}
        menuPortalTarget={document.body}
      />

      {errors ? <ErrorMessages errors={errors} /> : null}
    </FormControl>
  );
};

export default SearchDataSources;
