import React, { useState, useEffect, useMemo } from "react";
import { Formik, useFormikContext } from "formik";
import {
  FormControl,
  FormActions,
  FieldError,
  StyledField,
  Label,
  StyledInput,
} from "../../Form/FormControls";
import Button from "../../Button";
import { workGroups } from "../../../common/paths";
import { Link } from "react-router-dom";
import Card from "../../Card";
import SortTable from "../../Table/SortTable";
import { useApi } from "../../../api/useApi";
import { createWorkGroup } from "../../../api/workgroupMutations";
import { users } from "../../../api/userQueries";
import PagedTable from "../../Table/PagedTable";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import CTAMessage from "../../Help/CTAMessage";
import { StyledSelect } from "../../Form/FormControls";
import Spinner from "../../Loaders/Spinner";
import { groups } from "../../../api/groupQueries";
import { availableOrganizations } from "../../../api/organizationQueries";
import TableButton from "../../Button/TableButton";
import ErrorMessages from "../../Notifications/ErrorMessages";
import { useApi as useApiRedux } from "../../../api/useApiRedux";
import debounce from "lodash.debounce";
import PagedTableRedux from "../../Table/PagedTableRedux";

const FormSection = styled.div`
  margin-bottom: 1rem;
  display: flex;
  flex-direction: row;

  @media (max-width: 85.375em) {
    flex-direction: column;
  }
`;

const FormColumn = styled.div`
  flex: 1;
  margin-right: 1rem;
`;

const roles = [
  { label: "INACTIVE", value: "INACTIVE" },
  { label: "ACTIVE", value: "ACTIVE" },
  { label: "OWNER", value: "OWNER" },
];

const SearchGroups = ({ SetIsSearching }) => {
  const [userData, setUserData] = useState([]);

  const [
    { errors: getUserErrors, loading: loadingUsers, data: usersDataFetch },
    getUsers,
  ] = useApiRedux();

  const [searchText, setSearchText] = React.useState("");
  const [inputValue, setInputValue] = useState("");

  const debouncedSave = useMemo(
    () => debounce((newValue) => setSearchText(newValue), 1000),
    []
  );

  const updateValue = (newValue) => {
    setInputValue(newValue);
    debouncedSave(newValue);
  };

  //Fetch for Table Paged
  const fetchData = React.useCallback(
    ({ params }) => {
      if (searchText) {
        getUsers({
          query: `/search/groups`,
          params: { query: searchText, ...params },
          method: "GET",
        });
      }
    },
    [getUsers, searchText]
  );

  useEffect(() => {
    if (searchText) {
      SetIsSearching(true);
    } else {
      SetIsSearching(false);
    }
  }, [searchText, SetIsSearching]);

  const totalCount = usersDataFetch?.metadata?.availableItems;

  const {
    setFieldValue,
    values: { groups: selectedItems },
  } = useFormikContext();

  const addGroupId = (original) => {
    const newItems = [...selectedItems, original];
    setFieldValue("groups", newItems);
  };

  const removeSelection = (original) => {
    const newItems = [...selectedItems];
    const filteredItems = newItems.filter(
      (items) => items?.id !== original?.id
    );
    setFieldValue("groups", filteredItems);
  };

  const columnsData = [
    {
      Header: "Name",
      id: "name",
      accessor: (d) => d?.displayName,
      Cell: ({ row: { original } }) => {
        const existingIndex = selectedItems.findIndex(
          (item) => item?.id === original?.id
        );

        return (
          <SingleRowFlex>
            <DisplayName>{original?.displayName}</DisplayName>{" "}
            {existingIndex === -1 ? (
              <TableButton type="button" onClick={() => addGroupId(original)}>
                +
              </TableButton>
            ) : (
              <TableButton
                type="button"
                danger={true}
                onClick={() => removeSelection(original)}
              >
                x
              </TableButton>
            )}
          </SingleRowFlex>
        );
      },
    },
  ];

  const [viewState] = useState({
    page: 1,
    size: 25,
  });

  useEffect(() => {
    if (usersDataFetch) {
      const availUsers = usersDataFetch?.data ?? [];
      setUserData(availUsers);
    }
  }, [usersDataFetch]);

  return (
    <>
      <FormControl>
        <StyledInput
          type="text"
          name="searchGroups"
          label="Search Groups"
          placeholder={`Search by Name`}
          value={inputValue}
          onChange={(input) => updateValue(input.target.value)}
        />

        {getUserErrors ? <ErrorMessages errors={getUserErrors} /> : null}
      </FormControl>
      {inputValue ? (
        <PagedTableRedux
          key={"viewState" + JSON.stringify(viewState)}
          fetchData={fetchData}
          totalCount={totalCount}
          loading={loadingUsers}
          data={userData}
          columns={columnsData}
          noMargin={true}
          defaultPageSize={50}
          initParams={viewState}
        />
      ) : null}
    </>
  );
};

const StaticGroupSelect = () => {
  const {
    setFieldValue,
    values: { groups: selectedItems },
  } = useFormikContext();

  const [{ loading, data }, doFetch] = useApi();

  const availGroups = data?.groups?.edges ?? [];
  const totalCount = data?.groups?.totalCount;
  const pageInfo = data?.groups?.pageInfo;

  //Fetch for Table Paged
  const fetchData = React.useCallback(
    ({ pageSize, cursor }) => {
      doFetch({
        query: groups,
        variables: {
          first: pageSize,
          after: cursor,
          order: {
            displayName: "ASC",
          },
        },
      });
    },
    [doFetch]
  );

  const addGroupId = (original) => {
    const newItems = [...selectedItems, original?.node];
    setFieldValue("groups", newItems);
  };

  const removeSelection = (original) => {
    const newItems = [...selectedItems];
    const filteredItems = newItems.filter(
      (items) => items?.id !== original?.node?.id
    );
    setFieldValue("groups", filteredItems);
  };

  const columnsData = [
    {
      Header: "Name",
      id: "name",
      accessor: (d) => d?.node?.displayName,
      Cell: ({ row: { original } }) => {
        const existingIndex = selectedItems.findIndex(
          (item) => item.id === original?.node?.id
        );

        return (
          <SingleRowFlex>
            <DisplayName>{original?.node?.displayName}</DisplayName>{" "}
            {existingIndex === -1 ? (
              <TableButton type="button" onClick={() => addGroupId(original)}>
                +
              </TableButton>
            ) : (
              <TableButton
                type="button"
                danger={true}
                onClick={() => removeSelection(original)}
              >
                x
              </TableButton>
            )}
          </SingleRowFlex>
        );
      },
    },
  ];

  return (
    <>
      <PagedTable
        fetchData={fetchData}
        loading={loading}
        pageInfo={pageInfo}
        totalCount={totalCount}
        data={availGroups}
        columns={columnsData}
        defaultPageSize={50}
      />
    </>
  );
};

const SelectOrganizationGroup = React.memo(({ workGroupId }) => {
  const [isSearching, SetIsSearching] = useState();
  return (
    <>
      <SearchGroups SetIsSearching={SetIsSearching} />
      {!isSearching ? <StaticGroupSelect /> : null}
    </>
  );
});

const SelectUsers = React.memo(() => {
  const {
    setFieldValue,
    values: { users: selectedUsers },
  } = useFormikContext();

  const [{ loading, data }, doFetch] = useApi();

  const availUsers = data?.users?.edges ?? [];
  const totalCount = data?.users?.totalCount;
  const pageInfo = data?.users?.pageInfo;

  //Fetch for Table Paged
  const fetchData = React.useCallback(
    ({ pageSize, cursor }) => {
      doFetch({
        query: users,
        variables: {
          first: pageSize,
          after: cursor,
          order: {
            displayName: "ASC",
          },
        },
      });
    },
    [doFetch]
  );

  const updateSelection = (e, original) => {
    // IF EDITING OWNERS

    const selectedMember = { member: original?.node, role: e };
    const existingIndex = selectedUsers.findIndex(
      (item) => item.member.id === original?.node?.id
    );

    if (existingIndex === -1) {
      const newSelect = [...selectedUsers, selectedMember];
      setFieldValue("users", newSelect);
    } else {
      const newItems = [...selectedUsers];
      newItems[existingIndex].role = selectedMember.role;
      setFieldValue("users", newItems);
    }
  };

  const columnsData = [
    {
      Header: "Name",
      id: "name",
      accessor: (d) => d?.node?.displayName,
    },
    {
      Header: "Email",
      id: "email",
      accessor: (d) => d?.node?.emailAddress,
    },
    {
      Header: () => (
        <div
          style={{
            minWidth: "165px",
            maxWidth: "165px",
          }}
        >
          Role
        </div>
      ),
      id: "role",

      Cell: ({ row: { original } }) => {
        const selected = selectedUsers.find(
          (e) => e.member.id === original?.node?.id
        );
        return (
          <StyledSelect
            className={`react-select-container`}
            classNamePrefix={`react-select`}
            menuPortalTarget={document.body}
            name={`role`}
            id={`role`}
            inputId={`role-input`}
            instanceId={`role-instance`}
            options={roles}
            onChange={(e) => updateSelection(e, original)}
            placeholder={"Select Role"}
            value={selected ? selected.role : null}
          />
        );
      },
    },
  ];

  return (
    <>
      <PagedTable
        fetchData={fetchData}
        loading={loading}
        pageInfo={pageInfo}
        totalCount={totalCount}
        data={availUsers}
        columns={columnsData}
        defaultPageSize={50}
      />
    </>
  );
});

const SelectedUsersOrgs = React.memo(({ selectedOrg }) => {
  const {
    setFieldValue,
    values: { users: selectedUsers },
  } = useFormikContext();

  const [{ loading, data }, doFetch] = useApi();

  const userData = data?.users?.edges ?? [];
  const totalCount = data?.users?.totalCount;
  const pageInfo = data?.users?.pageInfo;

  //Fetch for Table Paged
  const fetchData = React.useCallback(
    ({ pageSize, cursor }) => {
      doFetch({
        query: users,
        variables: {
          first: pageSize,
          after: cursor,
          where: {
            organizationId: selectedOrg?.value,
          },
          order: {
            displayName: "ASC",
          },
        },
      });
    },
    [doFetch, selectedOrg]
  );

  const updateSelection = (e, original) => {
    // IF EDITING OWNERS

    const selectedMember = { member: original?.node, role: e };
    const existingIndex = selectedUsers.findIndex(
      (item) => item.member.id === original?.node?.id
    );

    if (existingIndex === -1) {
      const newSelect = [...selectedUsers, selectedMember];
      setFieldValue("users", newSelect);
    } else {
      const newItems = [...selectedUsers];
      newItems[existingIndex].role = selectedMember.role;
      setFieldValue("users", newItems);
    }
  };

  const columnsData = [
    {
      Header: "Name",
      id: "name",
      accessor: (d) => d?.node?.displayName,
    },
    {
      Header: "Email",
      id: "emailAddress",
      accessor: (d) => d?.node?.emailAddress,
    },
    {
      Header: () => (
        <div
          style={{
            minWidth: "165px",
            maxWidth: "165px",
          }}
        >
          Role
        </div>
      ),
      id: "role",

      Cell: ({ row: { original } }) => {
        const selected = selectedUsers.find(
          (e) => e.member.id === original?.node?.id
        );
        return (
          <StyledSelect
            className={`react-select-container`}
            classNamePrefix={`react-select`}
            menuPortalTarget={document.body}
            name={`role`}
            id={`role`}
            inputId={`role-input`}
            instanceId={`role-instance`}
            options={roles}
            onChange={(e) => updateSelection(e, original)}
            placeholder={"Select Role"}
            value={selected ? selected.role : null}
          />
        );
      },
    },
  ];

  return (
    <>
      <PagedTable
        fetchData={fetchData}
        loading={loading}
        pageInfo={pageInfo}
        totalCount={totalCount}
        data={userData}
        columns={columnsData}
        defaultPageSize={50}
      />
    </>
  );
});

const SelectedUsers = React.memo(() => {
  const {
    setFieldValue,
    values: { users: selectedUsers },
  } = useFormikContext();

  const updateSelection = (e, original) => {
    const existingIndex = selectedUsers.findIndex(
      (item) => item.member.id === original?.member.id
    );

    if (existingIndex !== -1) {
      const newItems = [...selectedUsers];
      newItems[existingIndex].role = e;
      setFieldValue("users", newItems);
    }
  };

  const removeSelection = (original) => {
    const newItems = [...selectedUsers];
    const filteredItems = newItems.filter(
      (items) => items?.node?.member.id !== original?.node?.member.id
    );
    setFieldValue("users", filteredItems);
  };

  const userDataColumns = [
    {
      Header: "Name",
      id: "name",
      accessor: (d) => d?.member?.displayName,
      Cell: ({ row: { original } }) => {
        return (
          <>
            <SingleRowFlex>
              <DisplayName>{original?.member.displayName}</DisplayName>{" "}
            </SingleRowFlex>
          </>
        );
      },
    },
    {
      Header: "Email",
      id: "emailAddress",
      accessor: (d) => d?.member?.emailAddress,
    },
    {
      Header: () => (
        <div
          style={{
            minWidth: "165px",
            maxWidth: "165px",
          }}
        >
          Role
        </div>
      ),
      id: "role",

      Cell: ({ row: { original } }) => {
        return (
          <StyledSelect
            className={`react-select-container`}
            classNamePrefix={`react-select`}
            menuPortalTarget={document.body}
            name={`role`}
            id={`role`}
            inputId={`role-input`}
            instanceId={`role-instance`}
            options={roles}
            onChange={(e) => updateSelection(e, original)}
            placeholder={"Select Role"}
            value={original?.role}
          />
        );
      },
    },
    {
      Header: "",
      id: "remove",
      width: 40,
      Cell: ({ row: { original } }) => {
        return (
          <>
            <SingleRowFlex>
              <TableButton
                type="button"
                danger={true}
                onClick={() => removeSelection(original)}
              >
                x
              </TableButton>
            </SingleRowFlex>
          </>
        );
      },
    },
  ];

  return <SortTable data={selectedUsers} columns={userDataColumns} />;
});

const SelectedGroups = React.memo(() => {
  const {
    setFieldValue,
    values: { groups },
  } = useFormikContext();

  const removeSelection = (original) => {
    const newItems = [...groups];
    const filteredItems = newItems.filter(
      (items) => items?.id !== original?.id
    );
    setFieldValue("groups", filteredItems);
  };

  const userDataColumns = [
    {
      Header: "Name",
      id: "name",
      accessor: (d) => d?.displayName,
      Cell: ({ row: { original } }) => {
        return (
          <>
            <SingleRowFlex>
              <DisplayName>{original?.displayName}</DisplayName>{" "}
              <TableButton
                type="button"
                danger={true}
                onClick={() => removeSelection(original)}
              >
                x
              </TableButton>
            </SingleRowFlex>
          </>
        );
      },
    },
  ];

  return <SortTable data={groups} columns={userDataColumns} />;
});

const Tabs = styled.div`
  border-bottom: 1px solid ${(props) => props.theme.surfaceAlt};
  background-color: ${(props) => props.theme.surface};
  position: relative;
  display: flex;
  margin-bottom: 1rem;
`;

const TabLink = styled.div`
  font-size: 0.9rem;
  padding: 0.5rem;
  border-bottom: ${(props) =>
    props.active
      ? `2px solid ${props.theme.onSecondarySurface}`
      : "2px solid transparent"};
  opacity: ${(props) => (props.active ? "1" : "0.5")};
  color: ${(props) => props.theme.onSurface};
  &:hover {
    opacity: 1;
  }
  cursor: pointer;
  text-transform: uppercase;
`;

const SingleRowFlex = styled.div`
  display: flex;
  align-items: center;
`;

const DisplayName = styled.div`
  flex: 1;
`;

const initialStateUserOrgs = [
  { label: "Entire Platform", value: "Entire Platform" },
];

const SearchUsers = ({ SetIsSearching }) => {
  const [userData, setUserData] = useState([]);

  const [
    { errors: getUserErrors, loading: loadingUsers, data: usersDataFetch },
    getUsers,
  ] = useApiRedux();

  const [searchText, setSearchText] = React.useState("");
  const [inputValue, setInputValue] = useState("");

  const debouncedSave = useMemo(
    () => debounce((newValue) => setSearchText(newValue), 1000),
    []
  );

  const updateValue = (newValue) => {
    setInputValue(newValue);
    debouncedSave(newValue);
  };

  //Fetch for Table Paged
  const fetchData = React.useCallback(
    ({ params }) => {
      if (searchText) {
        getUsers({
          query: `/search/users`,
          params: { query: searchText, ...params },
          method: "GET",
        });
      }
    },
    [getUsers, searchText]
  );

  useEffect(() => {
    if (searchText) {
      SetIsSearching(true);
    } else {
      SetIsSearching(false);
    }
  }, [searchText, SetIsSearching]);

  const totalCount = usersDataFetch?.metadata?.availableItems;

  const {
    setFieldValue,
    values: { users: selectedUsers },
  } = useFormikContext();

  const updateSelection = (e, original) => {
    // IF EDITING OWNERS
    const selectedMember = { member: original, role: e };
    const existingIndex = selectedUsers.findIndex(
      (item) => item.member.id === original?.id
    );

    if (existingIndex === -1) {
      const newSelect = [...selectedUsers, selectedMember];
      setFieldValue("users", newSelect);
    } else {
      const newItems = [...selectedUsers];
      newItems[existingIndex].role = selectedMember.role;
      setFieldValue("users", newItems);
    }
  };

  const columnsData = [
    {
      Header: "Name",
      id: "name",
      accessor: (d) => d?.displayName,
    },
    {
      Header: "Email",
      id: "emailAddress",
      accessor: (d) => d?.emailAddress,
    },
    {
      Header: () => (
        <div
          style={{
            minWidth: "165px",
            maxWidth: "165px",
          }}
        >
          Role
        </div>
      ),
      id: "role",

      Cell: ({ row: { original } }) => {
        const selected = selectedUsers.find(
          (e) => e.member.id === original?.id
        );
        return (
          <StyledSelect
            className={`react-select-container`}
            classNamePrefix={`react-select`}
            menuPortalTarget={document.body}
            name={`role`}
            id={`role`}
            inputId={`role-input`}
            instanceId={`role-instance`}
            options={roles}
            onChange={(e) => updateSelection(e, original)}
            placeholder={"Select Role"}
            value={selected ? selected.role : null}
          />
        );
      },
    },
  ];
  const [viewState] = useState({
    page: 1,
    size: 25,
  });

  useEffect(() => {
    if (usersDataFetch) {
      const availUsers = usersDataFetch?.data ?? [];
      setUserData(availUsers);
    }
  }, [usersDataFetch]);

  return (
    <>
      <FormControl>
        <StyledInput
          type="text"
          name="searchUsers"
          label="Search Users"
          placeholder={`Search by Name or Email`}
          value={inputValue}
          onChange={(input) => updateValue(input.target.value)}
        />

        {getUserErrors ? <ErrorMessages errors={getUserErrors} /> : null}
      </FormControl>
      {inputValue ? (
        <PagedTableRedux
          key={"viewState" + JSON.stringify(viewState)}
          fetchData={fetchData}
          totalCount={totalCount}
          loading={loadingUsers}
          data={userData}
          columns={columnsData}
          noMargin={true}
          defaultPageSize={50}
          initParams={viewState}
        />
      ) : null}
    </>
  );
};

const StaticUsersSection = () => {
  const [selectedOrg, setSelectedOrg] = useState({
    label: "Entire Platform",
    value: "Entire Platform",
  });

  return (
    <>
      <OrgSelection selectedOrg={selectedOrg} setSelectedOrg={setSelectedOrg} />
      <h4>Available Users</h4>
      <p>Select a role to add user to workgroup</p>
      {selectedOrg?.value === "Entire Platform" ? (
        <SelectUsers />
      ) : (
        <SelectedUsersOrgs
          key={`OrgID${selectedOrg?.value}`}
          selectedOrg={selectedOrg}
        />
      )}
    </>
  );
};

const UserSelect = () => {
  const [isSearching, SetIsSearching] = useState();
  return (
    <>
      <SearchUsers SetIsSearching={SetIsSearching} />
      {!isSearching ? <StaticUsersSection key={"StaticKeyRender"} /> : null}
    </>
  );
};

const OrgSelection = ({ selectedOrg, setSelectedOrg }) => {
  const [userOrgOptions, setUserOrgOptions] = useState(initialStateUserOrgs);
  //TODO: Come up with a better solution that 250 load max for multi-select
  //Available Users Count
  const [{ data }] = useApi(availableOrganizations);

  useEffect(() => {
    if (data) {
      const enabledOrganizations = data?.availableOrganizations?.edges
        .filter((wg) => wg?.node?.enabled)
        .map((wg) => {
          return { label: wg?.node?.displayName, value: wg?.node?.id };
        });

      setUserOrgOptions((prevState) => [...prevState, ...enabledOrganizations]);
    }
  }, [data]);

  return (
    <>
      <FormControl>
        <Label>Filter Users By Organization</Label>
        <StyledSelect
          className={`react-select-container`}
          classNamePrefix={`react-select`}
          menuPortalTarget={document.body}
          name={`Org-Select`}
          id={`Org-Select`}
          inputId={`Org-Select-input`}
          instanceId={`Org-Select-instance`}
          options={userOrgOptions}
          onChange={(e) => setSelectedOrg(e)}
          value={selectedOrg}
        />
      </FormControl>
    </>
  );
};

const WorkgroupPage = () => {
  //Body Section of Widget
  function Body() {
    const [activeTab, setActiveTab] = useState("groups");
    const [{ errors, loading, data }, doFetch] = useApi();
    let history = useHistory();

    useEffect(() => {
      if (data) {
        history.push(workGroups());
      }
    }, [data, history]);

    return (
      <Formik
        initialValues={{
          displayName: "",
          groups: [],
          users: [],
        }}
        validate={(values) => {
          let errors = {};
          if (!values.displayName) {
            errors.displayName = "required.";
          }

          return errors;
        }}
        onSubmit={(values) => {
          const users = values.users.map((user) => {
            return { userId: user.member.id, role: user.role.value };
          });

          const groups = values.groups.map((group) => {
            return { groupId: group?.id, role: "ACTIVE" };
          });
          const members = [...users, ...groups];

          const variables = {
            workgroup: {
              displayName: values.displayName,
              members: members,
            },
          };

          doFetch({ query: createWorkGroup, variables });
        }}
      >
        {(props) => {
          return (
            <form onSubmit={props.handleSubmit}>
              <FormControl>
                <StyledField
                  type="text"
                  name="displayName"
                  label={`Name`}
                  value={props.values.displayName}
                  placeholder={`Name`}
                />
                {props.errors?.displayName && (
                  <FieldError>{props.errors.displayName}</FieldError>
                )}
              </FormControl>

              <Tabs>
                <TabLink
                  onClick={() => setActiveTab("groups")}
                  active={activeTab === "groups"}
                >
                  Groups
                </TabLink>

                <TabLink
                  onClick={() => setActiveTab("users")}
                  active={activeTab === "users"}
                >
                  Users
                </TabLink>
              </Tabs>

              {activeTab === "users" ? (
                <FormSection>
                  <FormColumn>
                    <UserSelect />
                  </FormColumn>
                  <FormColumn>
                    {props?.values?.users?.length ? (
                      <>
                        <h4>Selected Users</h4>
                        <p>Adjust a users role or remove from workgroup</p>
                        <SelectedUsers />
                      </>
                    ) : (
                      <CTAMessage msg={" No Users Added"} />
                    )}
                  </FormColumn>
                </FormSection>
              ) : null}

              {activeTab === "groups" ? (
                <FormSection>
                  <FormColumn>
                    <h4>Available Groups</h4>
                    <p>Select a Group to add</p>
                    <SelectOrganizationGroup selectedName="groups" />
                  </FormColumn>
                  <FormColumn>
                    {props?.values?.groups?.length ? (
                      <>
                        <h4>Selected Groups</h4>
                        <p>Remove a Group from workgroup</p>
                        <SelectedGroups />
                      </>
                    ) : (
                      <CTAMessage msg={" No Groups Added"} />
                    )}
                  </FormColumn>
                </FormSection>
              ) : null}

              <FormActions>
                {errors ? <ErrorMessages errors={errors} /> : null}
                <>
                  <Link to={workGroups}>
                    <Button list="true" type="button">
                      Cancel
                    </Button>
                  </Link>
                  <Button danger type="submit">
                    {loading ? <Spinner /> : "Create"}
                  </Button>
                </>
              </FormActions>
            </form>
          );
        }}
      </Formik>
    );
  }

  return (
    <Card
      title={"Workgroups"}
      titleDescription={"Add Workgroup"}
      body={Body}
      full
    />
  );
};
export default WorkgroupPage;
