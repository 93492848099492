import { useContext } from "react";
import { SocketContext } from "../contexts/useSubscriptions";

const UseETLProviderInstanceCreateNotification = () => {
  const {
    etlProviderInstanceCreateCompleted,
    setEtlProviderInstanceCreateCompleted,
  } = useContext(SocketContext);

  return {
    etlProviderInstanceCreateCompleted,
    setEtlProviderInstanceCreateCompleted,
  };
};

export default UseETLProviderInstanceCreateNotification;
