// Browser ETL Providers
export const browseForEtlProviders = /* GraphQL */ `
  query ($id: Int!) {
    browseForEtlProviders(id: $id)
  }
`;

// Available ETL Providers
export const availableEtlProviders = /* GraphQL */ `
  query {
    availableEtlProviders {
      totalCount
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      edges {
        cursor
        node {
          id
          displayName
          enabled
          instances {
            id
            name
            enabled
            providerId
          }
        }
      }
    }
  }
`;

export const assignedEtlPipelines = /* GraphQL */ `
  query {
    assignedEtlPipelines {
      etlPipelineName
    }
  }
`;

export const browseForEtlProvidersPipelines = /* GraphQL */ `
  query ($browseInfo: BrowseEtlPipelineRequestInputModelInput!) {
    browseForEtlProvidersPipelines(browseInfo: $browseInfo)
  }
`;
