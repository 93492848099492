import React, { Component } from "react";
import styled from "styled-components/macro";

const Container = styled.div`
  display: flex;
`;

class FlexContainer extends Component {
  render() {
    return <Container {...this.props} />;
  }
}

const Section = styled.div`
  flex: ${(props) => (props.flex ? props.flex : "1")};
  padding-right: ${(props) => (props.paddingRight ? props.paddingRight : "0")};
`;

class FlexSection extends Component {
  render() {
    return <Section {...this.props} />;
  }
}

const FlexVert = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  flex-direction: ${(props) => (props.col ? "column" : "inherit")};
  justify-content: center;
  padding: 2em;
  flex: ${(props) => (props.flex ? props.flex : "1")};
  color: ${(props) => props.theme.onSurface};
`;

class FlexVertCenterContainer extends Component {
  render() {
    return <FlexVert {...this.props} />;
  }
}

const ColumnDiv = styled.div`
  max-width: 250px;
  width: 250px;
  margin-right: 10px;
  flex-shrink: 0;
  display: table-cell;
  border-right: 1px solid ${(props) => props.theme.onSecondarySurface};
  padding-right: 10px;
`;

class Column extends Component {
  render() {
    return <ColumnDiv {...this.props} />;
  }
}

const FlexTableDiv = styled.div`
  margin-bottom: 25px;
  margin-top: 25px;
  overflow-x: auto;
  display: flex;
  padding-bottom: 20px;
  width: 100%;
  max-width: calc(100vw - 440px);
  ${Column}:last-child {
    border-right: none;
  }
`;

class FlexTable extends Component {
  render() {
    return <FlexTableDiv {...this.props} />;
  }
}

export {
  FlexContainer,
  FlexSection,
  FlexVertCenterContainer,
  FlexTable,
  Column,
};
