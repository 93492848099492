import React, { useState } from "react";
import Button from "../../../Button";
import {
  FormControl,
  StyledSelect,
  StyledInput,
  Label,
} from "../../../Form/FormControls";

const EntryPoint = ({ dispatch, providers, browseRequest }) => {
  const [name, setName] = useState("");
  const [provider, setProvider] = useState(null);

  const submitEntry = () => {
    dispatch({ type: "ENTRY", payload: { name: name, provider: provider } });
    browseRequest(provider);
  };

  return (
    <>
      <FormControl>
        <StyledInput
          type="text"
          name="workflowName"
          label="Name"
          required={true}
          value={name}
          placeholder={`Name`}
          onChange={(e) => setName(e.target.value)}
        />
      </FormControl>

      <FormControl>
        <Label>Provider Instance</Label>
        <StyledSelect
          className={`react-select-container`}
          classNamePrefix={`react-select`}
          name={`etlProviderInstanceId`}
          id={`etlProviderInstanceId`}
          inputId={`etlProviderInstanceIdSelect-input`}
          instanceId={`etlProviderInstanceIdSelect-instance`}
          label="Instance"
          options={providers}
          placeholder={`Select Instance`}
          value={provider}
          menuPortalTarget={document.body}
          menuPlacement="auto"
          onChange={(e) => {
            setProvider(e);
          }}
        />
      </FormControl>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: "1rem",
        }}
      >
        <Button disabled={!name || !provider} onClick={() => submitEntry()}>
          Continue
        </Button>
      </div>
    </>
  );
};

export default EntryPoint;
