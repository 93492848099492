import React, { useState, useEffect, useCallback, useRef } from "react";
import {
  Box,
  Typography,
  TextField,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  Grid,
  TableRow,
  Paper,
  TablePagination,
} from "@mui/material";
import { MdSearch as SearchIcon } from "react-icons/md";
import { useApi } from "../../../api/useApi";
import {
  recordLevelReportById,
  rlrStatus,
  rlrFailures,
} from "../../../api/reportQueries";
import { formatTime } from "../../../common/formats";
import { Link } from "react-router-dom";
import Loading from "./Loading";
import Error from "./Error";
import { businessRuleStandardById } from "../../../api/ruleQueries";
import { dataSourceFailureInstanceDetails } from "../../../common/paths";
import CustomResizeHandle from "./CustomResizeHandle";

function getPolicyURL(instance) {
  const sourceId = instance?.dataSource?.id;
  const refreshSummaryId = instance?.dataSource?.latestReport?.refreshSummaryId;

  const failedRuleInstanceId = instance?.id;
  const failedRuleInstanceVersionId = instance?.latestVersion?.id;
  const failedRuleStandardVersionId =
    instance?.latestVersion?.standardVersionId;

  const published = instance?.enabledState === "PUBLISHED";

  return published && refreshSummaryId
    ? dataSourceFailureInstanceDetails(
        sourceId,
        refreshSummaryId,
        failedRuleInstanceId,
        failedRuleInstanceVersionId,
        failedRuleStandardVersionId
      )
    : sourceId && failedRuleInstanceId && failedRuleInstanceVersionId
    ? `/sources/${sourceId}/manage/policies/${failedRuleInstanceId}/${failedRuleInstanceVersionId}/update`
    : `#`;
}

const PolicyProfile = ({ data }) => {
  const contentRef = useRef(null);
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(3);

  const [
    { loading: loadingReport, errors: errorsReport, data: policyQuery },
    fetchReportById,
  ] = useApi();

  const policyData = policyQuery?.businessRuleStandardById;

  const isLoading = !policyData;
  const hasError = errorsReport;

  useEffect(() => {
    fetchReportById({
      query: businessRuleStandardById,
      variables: { standardId: data?.policy },
    });
  }, [fetchReportById, data?.policy]);

  const handlePageChange = (event, newPage) => {
    setPageIndex(newPage);
  };

  const handlePageSizeChange = (event) => {
    setPageSize(parseInt(event.target.value, 10));
    setPageIndex(0); // Reset to first page on page size change
  };

  if (hasError && data) {
    return (
      <Box
        sx={{
          marginTop: "2rem",
          padding: 2,
          border: "1px solid #ccc",
          borderRadius: 1,
        }}
      >
        <Error message="There was an error loading the data." />
      </Box>
    );
  }

  if (isLoading) {
    return (
      <Box
        sx={{
          marginTop: "2rem",
          padding: 2,
          border: "1px solid #ccc",
          borderRadius: 1,
        }}
      >
        <Loading />
      </Box>
    );
  }

  const calcTotals = (data) => {
    let totalFailures = 0;
    let totalSuccess = 0;

    data.instances.forEach((instance) => {
      const failCount =
        instance?.dataSource?.latestReport?.ruleFailures?.failures.find(
          (failure) => failure?.failedRuleStandardId === data.id
        )?.failureCount ?? 0;

      if (failCount) {
        totalFailures += failCount;
      }

      const successCount =
        instance?.dataSource?.latestReport?.ruleFailures?.failures.find(
          (failure) => failure?.failedRuleStandardId === data.id
        )?.successCount ?? 0;

      if (successCount) {
        totalSuccess += successCount;
      }
    });

    const totalAttempts = totalFailures + totalSuccess;
    const passRate = totalAttempts
      ? ((totalSuccess / totalAttempts) * 100).toFixed(2)
      : "0.00";
    const failRate = totalAttempts
      ? ((totalFailures / totalAttempts) * 100).toFixed(2)
      : "0.00";

    return { totalFailures, totalSuccess, passRate, failRate };
  };

  const totals = calcTotals(policyData);

  // Calculate the total number of pages
  const totalPages = Math.ceil(policyData.instances.length / pageSize);

  // Slice the data for the current page
  const currentPageData = policyData.instances.slice(
    pageIndex * pageSize,
    pageIndex * pageSize + pageSize
  );

  return (
    <>
      <Typography
        variant="h5"
        gutterBottom
        sx={{ zIndex: 2, position: "relative", top: "-10px" }}
      >
        {data?.widgetName}
      </Typography>

      <Typography
        variant="body2"
        color="primary"
        component={Link}
        to={`/policies/rules/${policyData?.id}`}
      >
        📝{policyData?.name}
      </Typography>

      <Grid container spacing={2} sx={{ marginTop: 2 }}>
        {data?.showFailureTotal && (
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Box
              component={Link}
              to={`/policies/rules/${policyData?.id}`}
              sx={{ textDecoration: "none", color: "inherit" }}
            >
              <Paper
                sx={{
                  padding: 2,
                  backgroundColor: "#fff",
                  borderRadius: 2,
                  boxShadow: 1,
                  color: "inherit",
                }}
              >
                <Typography variant="h6" component="div">
                  TOTAL FAILURES
                </Typography>
                {totals?.totalFailures ?? 0}
              </Paper>
            </Box>
          </Grid>
        )}
        {data?.showFailureRate && (
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Box
              component={Link}
              to={`/policies/rules/${policyData?.id}`}
              sx={{ textDecoration: "none", color: "inherit" }}
            >
              <Paper
                sx={{
                  padding: 2,
                  backgroundColor:
                    totals?.failRate > data.failureRateConcern
                      ? "#f87e7e"
                      : "#fff",
                  borderRadius: 2,
                  boxShadow: 1,
                  color:
                    totals?.failRate > data.failureRateConcern
                      ? "#fff"
                      : "inherit",
                }}
              >
                <Typography variant="h6" component="div">
                  FAILURE RATE
                </Typography>
                {totals?.failRate ?? 0}%
              </Paper>
            </Box>
          </Grid>
        )}
        {data?.showPassingTotal && (
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Box
              component={Link}
              to={`/policies/rules/${policyData?.id}`}
              sx={{ textDecoration: "none", color: "inherit" }}
            >
              <Paper
                sx={{
                  padding: 2,
                  backgroundColor: "#fff",
                  borderRadius: 2,
                  boxShadow: 1,
                  color: "inherit",
                }}
              >
                <Typography variant="h6" component="div">
                  TOTAL PASSED
                </Typography>
                {totals?.totalSuccess ?? 0}
              </Paper>
            </Box>
          </Grid>
        )}
        {data?.showPassingRate && (
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Box
              component={Link}
              to={`/policies/rules/${policyData?.id}`}
              sx={{ textDecoration: "none", color: "inherit" }}
            >
              <Paper
                sx={{
                  padding: 2,
                  backgroundColor:
                    totals?.passRate > data.passingRateConcern
                      ? "#40806A"
                      : "#fff",
                  borderRadius: 2,
                  boxShadow: 1,
                  color:
                    totals?.passRate > data.passingRateConcern
                      ? "#fff"
                      : "#inherit",
                }}
              >
                <Typography variant="h6" component="div">
                  PASSING RATE
                </Typography>
                {totals?.passRate ?? 0}%
              </Paper>
            </Box>
          </Grid>
        )}
      </Grid>
      {data?.showDataTable && (
        <>
          {" "}
          <TableContainer
            component={Paper}
            sx={{ position: "relative", marginTop: "1rem" }}
          >
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Data Source</TableCell>
                  <TableCell>Policy Instance Name</TableCell>
                  <TableCell>Mapping Status</TableCell>
                  <TableCell>Failed</TableCell>
                  <TableCell>Passed</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {currentPageData.length ? (
                  currentPageData.map((instance) => (
                    <TableRow key={instance.id}>
                      <TableCell>
                        <Typography
                          variant="body2"
                          color="primary"
                          component={Link}
                          to={getPolicyURL(instance)}
                        >
                          {instance?.dataSource?.name}
                        </Typography>
                      </TableCell>
                      <TableCell>{instance?.title}</TableCell>
                      <TableCell>{instance?.enabledState}</TableCell>
                      <TableCell>
                        {
                          instance?.dataSource?.latestReport?.ruleFailures?.failures.find(
                            (failure) =>
                              instance?.id === failure.failedRuleInstanceId
                          )?.failureCount
                        }
                      </TableCell>
                      <TableCell>
                        {
                          instance?.dataSource?.latestReport?.ruleFailures?.failures.find(
                            (failure) =>
                              instance?.id === failure.failedRuleInstanceId
                          )?.successCount
                        }
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={2} align="center">
                      <Typography variant="body2" color="textSecondary">
                        No data available
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            component="div"
            page={pageIndex}
            onPageChange={handlePageChange}
            rowsPerPage={pageSize}
            onRowsPerPageChange={handlePageSizeChange}
            rowsPerPageOptions={[3]}
            count={policyData?.instances?.length}
            labelDisplayedRows={({ page }) =>
              `Page ${page + 1} of ${totalPages}`
            }
            labelRowsPerPage="Items per page:"
          />
        </>
      )}
    </>
  );
};

export default PolicyProfile;
