// widgetConfigurations/RecordFinderConfig.js
import React, { useEffect, useState } from "react";
import {
  Box,
  TextField,
  MenuItem,
  Checkbox,
  FormControlLabel,
  Button,
  Modal,
  Typography,
  Slider,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { useApi } from "../../../api/useApi";

import { simpleStandardsList } from "../../../api/ruleQueries";
const PolicyProfileConfig = ({
  data,
  onSubmit,
  onDelete,
  isEditing,
  workspaceId,
}) => {
  const [{ loading, errors, data: policiesData }] = useApi(
    simpleStandardsList,
    {
      first: 9999,
      where: {
        enabled: { eq: true },
      },
    }
  );

  const policies =
    policiesData?.availableBusinessRuleStandards?.edges?.map((e) => e?.node) ??
    [];

  const policyOptions =
    policies?.map((dv) => {
      return { label: dv?.name, value: dv?.id };
    }) ?? [];

  const { control, handleSubmit, watch, setValue } = useForm({
    defaultValues: {
      ...data,
      showDataTable:
        data?.showDataTable !== undefined ? data.showDataTable : true,
      minWidth: 4,
      maxWidth: 6,
      minHeight: 12,
      maxHeight: 12,
      defaultWidth: 4,
      defaultHeight: 12,
    },
  });

  useEffect(() => {
    if (data) {
      Object.entries(data).forEach(([key, value]) => {
        setValue(key, value);
      });
    }
  }, [data, setValue]);

  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [sections, setSections] = useState([]);

  useEffect(() => {
    // Fetch available sections for the specific workspace
    const fetchSections = async () => {
      const savedWorkspaces =
        JSON.parse(localStorage.getItem("workspaces")) || [];
      const workspace = savedWorkspaces.find((ws) => ws.id === workspaceId);

      if (workspace && workspace?.configuration?.sections) {
        setSections(workspace?.configuration?.sections);
      }
    };

    fetchSections();
  }, [workspaceId]);

  const showFailureRate = watch("showFailureRate");
  const showPassingRate = watch("showPassingRate");

  const handleClearSection = () => {
    setValue("sectionId", ""); // Clear the sectionId field
  };

  const handleOpenDeleteModal = () => setOpenDeleteModal(true);
  const handleCloseDeleteModal = () => setOpenDeleteModal(false);
  const handleConfirmDelete = () => {
    handleCloseDeleteModal();
    onDelete();
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box mb={2}>
        <Controller
          name="policy"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <TextField {...field} label="Select Policy" select fullWidth>
              {policyOptions?.map((dvo) => {
                return (
                  <MenuItem key={dvo.value} value={dvo.value}>
                    {dvo.label}
                  </MenuItem>
                );
              })}
            </TextField>
          )}
        />
      </Box>
      <Box mb={2}>
        <Controller
          name="widgetName"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <TextField {...field} label="Widget Name" fullWidth />
          )}
        />
      </Box>

      <Box mb={2}>
        <Controller
          name="showFailureTotal"
          control={control}
          render={({ field }) => (
            <FormControlLabel
              control={<Checkbox {...field} />}
              label="Show Failure Total"
            />
          )}
        />
      </Box>

      <Box mb={2}>
        <Controller
          name="showFailureRate"
          control={control}
          render={({ field }) => (
            <FormControlLabel
              control={<Checkbox {...field} />}
              label="Show Failure Rate %"
            />
          )}
        />
      </Box>
      {showFailureRate && (
        <Box mb={2}>
          <Controller
            name="failureRateConcern"
            control={control}
            defaultValue={50}
            render={({ field }) => (
              <>
                <Typography gutterBottom>Failure Rate Threshold %</Typography>

                <Slider
                  {...field}
                  valueLabelDisplay="auto"
                  step={1}
                  min={0}
                  max={100}
                  sx={{ color: "#f87e7e" }}
                />
              </>
            )}
          />
          <p>
            The background of the section for rate will be red if the threshold
            is met.
          </p>
        </Box>
      )}

      <Box mb={2}>
        <Controller
          name="showPassingTotal"
          control={control}
          render={({ field }) => (
            <FormControlLabel
              control={<Checkbox {...field} />}
              label="Show Passing Total"
            />
          )}
        />
      </Box>
      <Box mb={2}>
        <Controller
          name="showPassingRate"
          control={control}
          render={({ field }) => (
            <FormControlLabel
              control={<Checkbox {...field} />}
              label="Show Passing Rate %"
            />
          )}
        />
      </Box>
      {showPassingRate && (
        <Box mb={2}>
          <Controller
            name="passingRateConcern"
            control={control}
            defaultValue={50}
            render={({ field }) => (
              <>
                <Typography gutterBottom>Passing Rate Threshold %</Typography>

                <Slider
                  {...field}
                  valueLabelDisplay="auto"
                  step={1}
                  min={0}
                  max={100}
                  sx={{ color: "#40806A" }}
                />
              </>
            )}
          />
          <p>
            The background of the section for rate will be green if the
            threshold is met.
          </p>
        </Box>
      )}

      <Box mb={2}>
        <Controller
          name="showDataTable"
          control={control}
          defaultValue={true} // Set the default value to true
          render={({ field }) => (
            <FormControlLabel
              control={<Checkbox {...field} checked={field.value} />}
              label="Show Data Table"
            />
          )}
        />
      </Box>

      {sections.length > 0 && (
        <Box mb={2}>
          <Controller
            name="sectionId"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextField {...field} label="Select Section" select fullWidth>
                {sections.map((section) => (
                  <MenuItem key={section.id} value={section.id}>
                    {section.name}
                  </MenuItem>
                ))}
              </TextField>
            )}
          />
          <Button
            variant="contained"
            onClick={handleClearSection}
            sx={{
              backgroundColor: "rgb(18, 17, 53)",
              "&:hover": {
                backgroundColor: "rgba(18, 17, 53, 0.8)",
              },
            }}
          >
            Clear Section
          </Button>
        </Box>
      )}

      <Button
        variant="contained"
        type="submit"
        sx={{
          backgroundColor: "rgb(18, 17, 53)",
          "&:hover": {
            backgroundColor: "rgba(18, 17, 53, 0.8)",
          },
        }}
      >
        {data && isEditing ? "Save Widget" : "Update Preview"}
      </Button>

      {onDelete && (
        <>
          <Button
            variant="contained"
            type="button"
            onClick={handleOpenDeleteModal}
          >
            Remove Widget
          </Button>

          <Modal
            open={openDeleteModal}
            onClose={handleCloseDeleteModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box
              position="absolute"
              top="50%"
              left="50%"
              transform="translate(-50%, -50%)"
              width={400}
              bgcolor="background.paper"
              boxShadow={24}
              p={4}
            >
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Confirm Delete
              </Typography>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                Are you sure you want to delete this widget?
              </Typography>
              <Box mt={4} display="flex" justifyContent="space-between">
                <Button
                  onClick={handleConfirmDelete}
                  variant="contained"
                  color="secondary"
                  sx={{
                    backgroundColor: "rgb(18, 17, 53)",
                    "&:hover": {
                      backgroundColor: "rgba(18, 17, 53, 0.8)",
                    },
                  }}
                >
                  Confirm
                </Button>
                <Button onClick={handleCloseDeleteModal} variant="outlined">
                  Cancel
                </Button>
              </Box>
            </Box>
          </Modal>
        </>
      )}
    </form>
  );
};

export default PolicyProfileConfig;
