import React, { useState } from "react";
import { Field } from "formik";
import { connectionsList } from "../../../api/connectionQueries";
import { useApi } from "../../../api/useApi";
import ErrorMessages from "../../Notifications/ErrorMessages";
import {
  FormControl,
  StyledField,
  ErrorMessage,
  StyledSelect,
  Label,
} from "../../Form/FormControls";
import { namingConventions } from "../../../common/formOptions";

const formatProviderSelect = ({ label, docProviderType }) => {
  return (
    <div style={{ display: "flex", flexDirection: "column", padding: ".5rem" }}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          fontSize: "1.1rem",
          marginBottom: ".3rem",
        }}
      >
        {label}
      </div>
      {docProviderType && (
        <div
          style={{
            opacity: 0.5,
            fontSize: "1rem",
            position: "relative",
            display: "block",
            maxWidth: "100%",
            whiteSpace: "normal",
          }}
        >
          {docProviderType}
        </div>
      )}
    </div>
  );
};

const SftpConnection = ({ form, isEditing }) => {
  const [showPassword, setShowPassword] = useState(false);

  // Query for Data Providers
  const queryProviders = `
      query(){
        allDocProviders() {
            displayName
            docProviderType
            enabled
            id
        }
    }
  `;
  // Loading the query on component mount for latest configuration
  const [{ errors: providersErrors, data: providersData }] =
    useApi(queryProviders);
  const providerOptions =
    providersData?.allDocProviders
      ?.filter((pd) => pd.enabled)
      .map((provider) => {
        return {
          label: provider?.displayName,
          value: provider?.id,
          docProviderType: provider?.docProviderType,
        };
      }) ?? [];

  const [{ loading, errors, data }] = useApi(connectionsList, {
    first: 9999, //fix to get all with hc update
    where: {
      enabled: { eq: true },
      id: { neq: -1 },
    },
    order_by: {
      name: "ASC",
    },
  });

  const connectionsOptions =
    data?.availableConnections?.edges?.map((connection) => {
      return {
        label: connection?.node?.name,
        value: connection?.node?.id,
      };
    }) ?? [];

  const isDestinationVisible = [
    "EXTRACT_DOCUMENT",
    "COPY_DOCUMENT",
    "XML_LOAN_LOGIC",
    "CUSTOM_VISION_PREDICTION",
  ].includes(
    providerOptions.find(
      (po) => po.value === form?.values?.ocrConnection?.docProviderId
    )?.docProviderType
  );

  const hiddenDueToXMLCopyExtract = [
    "EXTRACT_DOCUMENT",
    "COPY_DOCUMENT",
    "XML_LOAN_LOGIC",
  ].includes(
    providerOptions.find(
      (po) => po.value === form?.values?.ocrConnection?.docProviderId
    )?.docProviderType
  );

  return (
    <>
      <h3>SFTP Connection Details</h3>

      <FormControl>
        {loading ? (
          <div>Loading Connections</div>
        ) : (
          <StyledField
            name={`details.sftpConnectionString.serverAddress`}
            type="text"
            placeholder="Server Address"
            label="Server Address"
          />
        )}
        <ErrorMessage name={`details.sftpConnectionString.serverAddress`} />
      </FormControl>

      <FormControl>
        <StyledField
          name={`details.sftpConnectionString.username`}
          type="text"
          placeholder="Username"
          label="Username"
        />
        <ErrorMessage name={`details.sftpConnectionString.username`} />
      </FormControl>

      <FormControl>
        <StyledField
          name={`details.sftpConnectionString.password`}
          type={showPassword ? "text" : "password"}
          placeholder="Password"
          label="Password"
        />
        <label>
          Show Password
          <Field
            type="checkbox"
            checked={showPassword}
            name={`showPassword`}
            onChange={() => setShowPassword((prevState) => !prevState)}
          />
        </label>
        <ErrorMessage name={`details.sftpConnectionString.password`} />
      </FormControl>

      <FormControl>
        <StyledField
          name={`details.sftpConnectionString.connectionTimeout`}
          type="number"
          placeholder="15"
          label="Connection Timeout (seconds)"
        />
        <ErrorMessage name={`details.sftpConnectionString.connectionTimeout`} />
      </FormControl>

      <FormControl>
        <StyledField
          name={`details.sftpConnectionString.connectionRetries`}
          type="number"
          placeholder="3"
          label="Connection Retry Attempts"
        />
        <ErrorMessage name={`details.sftpConnectionString.connectionRetries`} />
      </FormControl>

      <FormControl>
        <StyledField
          name={`details.sftpConnectionString.privateKey`}
          placeholder="Private Key"
          label="Private Key"
          component="textarea"
          rows="5"
        />

        <ErrorMessage name={`details.sftpConnectionString.privateKey`} />
      </FormControl>

      {isEditing ? null : (
        <FormControl>
          <label>
            Connection with OCR Source Location
            <Field
              type="checkbox"
              checked={form.values.ocrEnabled}
              name={`ocr`}
              onChange={() => {
                form.setFieldValue(`ocrEnabled`, !form.values.ocrEnabled);
              }}
            />
          </label>
        </FormControl>
      )}

      {form.values.ocrEnabled && !isEditing ? (
        <>
          <h3>OCR Source Location</h3>
          <p>
            <small>
              <i>
                Documents will be added on the "OCR" tab on the connection once
                established.
              </i>
            </small>
          </p>
          <FormControl>
            <Label>Document Provider</Label>
            <StyledSelect
              className={`react-select-container`}
              classNamePrefix={`react-select`}
              id={`docProviderId`}
              inputId={`docProviderId-input`}
              instanceId={`docProviderId-instance`}
              name={`docProviderId`}
              label="Document Provider"
              options={providerOptions}
              formatOptionLabel={formatProviderSelect}
              placeholder={`Select Document Provider`}
              value={providerOptions.find(
                (nc) => nc?.value === form?.values?.ocrConnection?.docProviderId
              )}
              menuPortalTarget={document.body}
              menuPlacement="auto"
              onChange={(e) =>
                form.setFieldValue(`ocrConnection.docProviderId`, e?.value)
              }
            />
            <ErrorMessage name={`ocrConnection.docProviderId`} />
          </FormControl>
          {isDestinationVisible && (
            <FormControl>
              <Label>Destination Connection</Label>
              <StyledSelect
                className={`react-select-container`}
                classNamePrefix={`react-select`}
                name={`ocrConnection.destinationConnectionId`}
                id={`destinationConnectionId`}
                inputId={`destinationConnectionIdSelect-input`}
                instanceId={`destinationConnectionIdSelect-instance`}
                label="Destination Connection"
                options={connectionsOptions}
                placeholder={`Select Destination Connection`}
                value={connectionsOptions.find(
                  (nc) =>
                    nc?.value ===
                    form?.values?.ocrConnection?.destinationConnectionId
                )}
                menuPortalTarget={document.body}
                menuPlacement="auto"
                onChange={(e) =>
                  form.setFieldValue(
                    `ocrConnection.destinationConnectionId`,
                    e?.value
                  )
                }
              />
            </FormControl>
          )}

          {/* <FormControl>
            <StyledField
              name={`ocrConnection.containerName`}
              type="text"
              placeholder="Container Name"
              label="Container Name"
            />
            <ErrorMessage name={`ocrConnection.containerName`} />
          </FormControl> */}

          <FormControl>
            <StyledField
              name={`ocrConnection.inputPath`}
              type="text"
              placeholder="Input Path"
              label="Input Path"
            />
            <ErrorMessage name={`ocrConnection.inputPath`} />
          </FormControl>

          {!hiddenDueToXMLCopyExtract && (
            <>
              <FormControl>
                <Label>File Naming Convention</Label>
                <StyledSelect
                  className={`react-select-container`}
                  classNamePrefix={`react-select`}
                  name={`ocrConnection.namingConvention`}
                  id={`namingConvention`}
                  inputId={`namingConventionSelect-input`}
                  instanceId={`namingConventionSelect-instance`}
                  label="Naming Convention"
                  options={namingConventions}
                  placeholder={`Select Convention`}
                  value={namingConventions.find(
                    (nc) =>
                      nc?.value ===
                      form?.values?.ocrConnection?.namingConvention
                  )}
                  menuPortalTarget={document.body}
                  menuPlacement="auto"
                  onChange={(e) =>
                    form.setFieldValue(
                      `ocrConnection.namingConvention`,
                      e?.value
                    )
                  }
                />
              </FormControl>

              {/* Custom Vision Info */}

              <FormControl>
                <StyledField
                  name={`ocrConnection.customVisionProjectId`}
                  type="text"
                  placeholder="Custom Vision Project Id"
                  label="Custom Vision Project Id"
                />
                <ErrorMessage name={`ocrConnection.customVisionProjectId`} />
              </FormControl>

              <FormControl>
                <StyledField
                  name={`ocrConnection.customVisionIteration`}
                  type="text"
                  placeholder="Custom Vision Iteration"
                  label="Custom Vision Project Iteration"
                />
                <ErrorMessage name={`ocrConnection.customVisionIteration`} />
              </FormControl>
            </>
          )}
        </>
      ) : null}
      {errors ? <ErrorMessages errors={errors} /> : null}
      {providersErrors ? <ErrorMessages errors={providersErrors} /> : null}
    </>
  );
};

export default SftpConnection;
