// Set Business Rule standard Name
export const createOrUpdateUserDefinedCleanup = /* GraphQL */ `
  mutation (
    $sourceTimeInHours: Int!
    $sourceType: SourceTypes!
    $description: String
  ) {
    createOrUpdateUserDefinedCleanup(
      sourceTimeInHours: $sourceTimeInHours
      sourceType: $sourceType
      description: $description
    )
  }
`;
