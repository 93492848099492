import React, { useState, useEffect, useContext } from "react";
import Card from "../../components/Card";
import { AuthContext } from "../../contexts/AuthContext";
import {
  Container,
  Button,
  Box,
  Typography,
  TextField,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  CircularProgress,
} from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import { Link } from "react-router-dom";
import { MdStar, MdStarBorder } from "react-icons/md";
import { toast } from "react-toastify";

const WorkspacesMain = () => {
  const Body = () => {
    const { control, handleSubmit, reset } = useForm();
    const { user } = useContext(AuthContext);
    const [workspaces, setWorkspaces] = useState([]);
    const [open, setOpen] = useState(false);
    const [workspaceToDelete, setWorkspaceToDelete] = useState(null);
    const [loading, setLoading] = useState(true);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [importData, setImportData] = useState("");
    const [openImport, setOpenImport] = useState(false);

    const handleOpenImport = () => setOpenImport(true);
    const handleCloseImport = () => setOpenImport(false);

    useEffect(() => {
      if (!user) {
        setLoading(false);
        return;
      }

      const fetchWorkspaces = () => {
        const savedWorkspaces =
          JSON.parse(localStorage.getItem("workspaces")) || [];
        setWorkspaces(savedWorkspaces);
        setLoading(false);
      };

      fetchWorkspaces();
    }, [user]);

    const onSubmit = (data) => {
      const newWorkspace = {
        id: `workspace_${Date.now()}`, // Generate unique workspaceId
        name: data.name,
        description: data.description,
        owner: user?.email, // Adjust owner as needed
        configuration: {
          widgets: [],
          sections: [],
        },
      };

      const updatedWorkspaces = [...workspaces, newWorkspace];
      setWorkspaces(updatedWorkspaces);
      localStorage.setItem("workspaces", JSON.stringify(updatedWorkspaces));
      reset();
      setDialogOpen(false);
    };

    const handleDelete = (workspace) => {
      setWorkspaceToDelete(workspace);
      setOpen(true);
    };

    const confirmDelete = () => {
      const updatedWorkspaces = workspaces.filter(
        (ws) => ws.id !== workspaceToDelete.id
      );
      setWorkspaces(updatedWorkspaces);
      localStorage.setItem("workspaces", JSON.stringify(updatedWorkspaces));
      setOpen(false);
      setWorkspaceToDelete(null);
    };

    const handleClose = () => {
      setOpen(false);
      setWorkspaceToDelete(null);
    };

    const handleFavorite = (workspaceId) => {
      const updatedWorkspaces = workspaces.map((workspace) => {
        if (workspace.id === workspaceId) {
          return { ...workspace, makeFav: !workspace.makeFav };
        }
        return { ...workspace, makeFav: false };
      });

      setWorkspaces(updatedWorkspaces);
      localStorage.setItem("workspaces", JSON.stringify(updatedWorkspaces));
    };

    const handleDialogOpen = () => {
      setDialogOpen(true);
    };

    const handleDialogClose = () => {
      setDialogOpen(false);
    };

    const exportWorkspaces = () => {
      const workspacesLocalData = localStorage.getItem("workspaces");
      navigator.clipboard.writeText(workspacesLocalData).then(
        function () {
          toast.success("Workspaces Copied to Clipboard", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        },
        function (err) {
          toast.error("Failed To Copy", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      );
    };

    const handleImport = () => {
      try {
        const importedWorkspaces = JSON.parse(importData);
        if (!Array.isArray(importedWorkspaces))
          throw new Error("Invalid import format");

        localStorage.setItem("workspaces", JSON.stringify(importedWorkspaces));
        toast.success("Workspaces have been imported successfully");

        setWorkspaces(importedWorkspaces);
      } catch (err) {
        toast.error("Failed to import: " + err.message);
      }
      handleCloseImport();
    };

    if (loading) {
      return (
        <Container maxWidth="md">
          <Box mt={5} display="flex" justifyContent="center">
            <CircularProgress />
          </Box>
        </Container>
      );
    }

    if (!workspaces.length) {
      return (
        <Container maxWidth="md">
          <Box mt={5} component={Paper} p={3}>
            <Typography variant="h4" gutterBottom>
              Welcome to Workspaces
            </Typography>
            <Typography variant="body1" paragraph>
              Workspaces empower you to craft your unique data narratives by
              offering a flexible and customizable environment.
            </Typography>
            <Typography variant="body1" paragraph>
              <strong>Start by Creating Your Workspace:</strong> Begin by giving
              your workspace a meaningful name and a brief description. This
              helps in organizing your data stories and provides context for
              future reference.
            </Typography>
            <Typography variant="body1" paragraph>
              <strong>Workspace View:</strong> Once your workspace is set up,
              you’ll enter the workspace view. Here, you can start adding
              widgets or sections. Sections act as visual organizers within your
              workspace, allowing you to group related widgets together for
              better clarity and structure.
            </Typography>
            <Typography variant="body1" paragraph>
              <strong>Add Widgets:</strong> Choose from a variety of pre-defined
              widgets or dive into our specialized components based on available
              APIs to explore and display the data that matters most to you.
            </Typography>
            <Box mt={4}>
              <Typography variant="h6">Create Your First Workspace</Typography>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Controller
                      name="name"
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <TextField
                          {...field}
                          label="Workspace Name"
                          variant="outlined"
                          fullWidth
                          required
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Controller
                      name="description"
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <TextField
                          {...field}
                          label="Description"
                          variant="outlined"
                          fullWidth
                          multiline
                          rows={4}
                          required
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      fullWidth
                    >
                      Create Workspace
                    </Button>
                    <Button
                      onClick={handleOpenImport}
                      variant="contained"
                      color="primary"
                      fullWidth
                      sx={{ marginTop: "1rem" }}
                    >
                      Import Workspaces
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </Box>
          </Box>

          <Dialog open={openImport} onClose={handleCloseImport}>
            <DialogTitle>Import Workspaces</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Paste the JSON data of your workspaces here. This will overwrite
                any existing workspaces.
              </DialogContentText>
              <TextField
                autoFocus
                margin="dense"
                id="importData"
                label="JSON Data"
                type="text"
                fullWidth
                multiline
                rows={4}
                variant="outlined"
                value={importData}
                onChange={(e) => setImportData(e.target.value)}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseImport} color="primary">
                Cancel
              </Button>
              <Button onClick={handleImport} color="secondary">
                Import and Overwrite
              </Button>
            </DialogActions>
          </Dialog>
        </Container>
      );
    }

    return (
      <Container maxWidth={false}>
        <Box display="flex" justifyContent="flex-end" mb={2}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleOpenImport}
            sx={{
              background: "rgb(0, 159, 212)",
              "&:hover": {
                backgroundColor: "#999",
              },
              marginRight: "1rem",
            }}
          >
            Import
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => exportWorkspaces()}
            sx={{
              background: "rgb(0, 159, 212)",
              "&:hover": {
                backgroundColor: "#999",
              },
              marginRight: "1rem",
            }}
          >
            Export
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleDialogOpen}
            sx={{
              background: "rgb(0, 159, 212)",
              "&:hover": {
                backgroundColor: "#999",
              },
            }}
          >
            Add Workspace
          </Button>
        </Box>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Workspace Name</TableCell>
                <TableCell>Number of Widgets</TableCell>
                <TableCell>Owner</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {workspaces.map((workspace) => (
                <TableRow key={workspace.id}>
                  <TableCell>
                    <Link to={`/workspaces/${workspace.id}`} underline="none">
                      {workspace.name}
                    </Link>
                  </TableCell>
                  <TableCell>
                    {workspace.configuration?.widgets?.length}
                  </TableCell>
                  <TableCell>{workspace.owner}</TableCell>
                  <TableCell>
                    <IconButton
                      title="Toggle as Homepage Workspace"
                      onClick={() => handleFavorite(workspace.id)}
                    >
                      {workspace.makeFav ? (
                        <MdStar
                          style={{
                            color: "rgb(0, 159, 212)",
                            fontSize: "inherit",
                          }}
                        />
                      ) : (
                        <MdStarBorder />
                      )}
                    </IconButton>
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() => handleDelete(workspace)}
                      sx={{
                        marginLeft: "1rem",
                        backgroundColor: "rgb(18, 17, 53)",
                        "&:hover": {
                          backgroundColor: "rgba(18, 17, 53, 0.8)",
                        },
                      }}
                    >
                      Remove
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>Confirm Deletion</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to delete the workspace "
              {workspaceToDelete?.name}"?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button onClick={confirmDelete} color="secondary">
              Delete
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog open={dialogOpen} onClose={handleDialogClose}>
          <DialogTitle>Add New Workspace</DialogTitle>
          <DialogContent>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Controller
                    name="name"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label="Workspace Name"
                        variant="outlined"
                        fullWidth
                        required
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    name="description"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label="Description"
                        variant="outlined"
                        fullWidth
                        multiline
                        rows={4}
                        required
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </form>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDialogClose} color="primary">
              Cancel
            </Button>
            <Button
              onClick={handleSubmit(onSubmit)}
              variant="contained"
              color="primary"
            >
              Add Workspace
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog open={openImport} onClose={handleCloseImport}>
          <DialogTitle>Import Workspaces</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Paste the JSON data of your workspaces here. This will overwrite
              any existing workspaces.
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              id="importData"
              label="JSON Data"
              type="text"
              fullWidth
              multiline
              rows={4}
              variant="outlined"
              value={importData}
              onChange={(e) => setImportData(e.target.value)}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseImport} color="primary">
              Cancel
            </Button>
            <Button onClick={handleImport} color="secondary">
              Import and Overwrite
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
    );
  };

  return (
    <Card
      title={"Workspaces"}
      titleDescription={"craft your data narrative"}
      body={Body}
    />
  );
};

export default WorkspacesMain;
