import React from "react";
import Card from "../../Card";
import DataSourceColumnFailuresBarWidget from "./DataSourceColumnFailuresBarWidget";
import Spinner from "../../../components/Loaders/Spinner";
import StyledLink from "../../../components/StyledLink";
import { useLocation } from "react-router-dom";
import { ReactComponent as CleanSVG } from "../../../assets/images/spotlight-empty-state-clean.svg";
import { ReactComponent as SunSvg } from "../../../assets/images/spotlight-empty-state-sun.svg";

const emptyArray = [CleanSVG, SunSvg];

export default React.memo(
  ({ data, loading, errors, sourceId, disableDrillIn, dataSourceName }) => {
    const sourceName = dataSourceName ?? null;

    //Body Section of Widget
    function Body() {
      if (loading) return <Spinner />;
      if (errors) return <div>Errors</div>;
      const RandomEmpty =
        emptyArray[Math.floor(Math.random() * emptyArray.length)];

      if (!data?.length)
        return (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "100%",
            }}
          >
            <RandomEmpty />
          </div>
        );
      return (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          ></div>

          <DataSourceColumnFailuresBarWidget
            data={data}
            sourceId={sourceId}
            disableDrillIn={disableDrillIn}
          />
        </>
      );
    }

    function Actions() {
      let location = useLocation();
      return (
        <StyledLink to={`${location?.pathname}/failures`}>View All</StyledLink>
      );
    }

    return (
      <Card
        title={"Top 10 Column Failures"}
        titleDescription={sourceName}
        body={Body}
        actions={Actions}
        full={true}
      />
    );
  }
);
