import React from "react";
import { Field } from "formik";

import {
  FormControl,
  StyledField,
  ErrorMessage,
} from "../../Form/FormControls";

const CosmosConnection = ({ form }) => {
  return (
    <>
      <h3>Cosmos Connection Details</h3>
      <FormControl>
        <label>
          Use Advanced Connection String
          <Field
            type="checkbox"
            checked={form.values.advanced}
            name={`advanced`}
            onChange={() => {
              form.setFieldValue(`advanced`, !form.values.advanced);
              form.setFieldValue(`details`, {
                cosmosConnectionString: {
                  connectionString: null,
                  cosmosUri: null,
                  accountKey: null,
                  database: null,
                },
                dataverseConnectionString: null,
                databaseConnectionString: null,
                azureStorageConnectionString: null,
                sftpConnectionString: null,
              });
            }}
          />
        </label>
      </FormControl>

      {form.values.advanced ? (
        <>
          <FormControl>
            <StyledField
              name={`details.cosmosConnectionString.database`}
              type="text"
              placeholder="Database Name"
              label="Database Name"
            />
            <ErrorMessage name={`details.cosmosConnectionString.database`} />
          </FormControl>
          <FormControl>
            <StyledField
              name={`details.cosmosConnectionString.connectionString`}
              type="text"
              placeholder="Connection String"
              label="Connection String"
            />
            <ErrorMessage
              name={`details.cosmosConnectionString.connectionString`}
            />
          </FormControl>
        </>
      ) : (
        <>
          <FormControl>
            <StyledField
              name={`details.cosmosConnectionString.database`}
              type="text"
              placeholder="Database Name"
              label="Database Name"
            />
            <ErrorMessage name={`details.cosmosConnectionString.database`} />
          </FormControl>

          <FormControl>
            <StyledField
              name={`details.cosmosConnectionString.accountKey`}
              type="text"
              placeholder="Account Key"
              label="Account Key"
            />
            <ErrorMessage name={`details.cosmosConnectionString.accountKey`} />
          </FormControl>

          <FormControl>
            <StyledField
              name={`details.cosmosConnectionString.cosmosUri`}
              type="text"
              placeholder="URI"
              label="URI"
            />
            <ErrorMessage name={`details.cosmosConnectionString.cosmosUri`} />
          </FormControl>
        </>
      )}
    </>
  );
};
export default CosmosConnection;
