import styled from "styled-components/macro";

export const TableElement = styled.table`
  width: 100%;
  display: table;
  border-spacing: 0;
  border-collapse: separate;
`;

export const Td = styled.td`
  display: table-cell;
  padding: 16px;
  font-size: 0.875rem;
  text-align: left;
  font-weight: 400;
  line-height: 1.43;
  min-width: ${(props) => (props.left ? "max(180px, 40px)" : "inherit")};
  z-index: ${(props) => (props.left || props.right ? 50 : "inherit")};
  position: ${(props) => (props.left || props.right ? "sticky" : "inherit")};
  left: ${(props) => (props.left ? props.left : "auto")};
  right: ${(props) => (props.right ? props.right : "auto")};
  border-bottom: 1px solid rgba(224, 224, 224, 1);
  letter-spacing: 0.01071em;
  vertical-align: inherit;
  color: rgba(0, 0, 0, 0.87);
  box-shadow: ${(props) =>
    props.left
      ? props.isLast
        ? "rgb(0 0 0 / 10%) -4px 0px 8px -6px inset"
        : "none"
      : props.right
      ? "rgb(0 0 0 / 10%) 4px 0px 8px 6px"
      : "none"};
  clip-path: ${(props) => (props.right ? "inset(0px 0px 0px -350px)" : "none")};
  background-color: ${(props) =>
    props.left ? (props.isOdd ? "#ffffff" : "#f2f2f2") : "inherit"};

  & > div {
    min-width: 0;
    white-space: normal;
    text-align: left;
  }
`;

export const TdExpand = styled.td`
  display: table-cell;

  font-size: 0.875rem;
  text-align: left;
  font-weight: 400;
  line-height: 1.43;

  letter-spacing: 0.01071em;
  vertical-align: inherit;
  color: rgba(0, 0, 0, 0.87);
  & > div {
    min-width: 0;
    white-space: normal;
    text-align: left;
  }
`;
export const Th = styled.th`
  display: table-cell;
  padding: 16px;
  font-size: 0.875rem;
  text-align: left;
  letter-spacing: 0.01071em;
  vertical-align: inherit;
  color: rgba(0, 0, 0, 0.87);
  font-weight: 500;
  line-height: 1.5rem;
  & > div > div {
    min-width: 0;
    white-space: normal;
    text-align: left;
  }
  &:hover {
    border-right: 1px solid #ccc;
  }
  border-bottom: 1px solid #ccc;
`;

export const ThSticky = styled.th`
  display: table-cell;
  padding: 16px;
  font-size: 0.875rem;
  text-align: left;
  letter-spacing: 0.01071em;
  vertical-align: inherit;
  color: rgba(0, 0, 0, 0.87);
  font-weight: 500;
  line-height: 1.5rem;
  min-width: ${(props) => (props.left ? "max(180px, 40px)" : "inherit")};
  position: sticky;
  left: ${(props) => (props.left ? props.left : "auto")};
  right: ${(props) => (props.right ? props.right : "auto")};
  z-index: 40;
  background-color: #ffffff;
  box-shadow: ${(props) =>
    props.left
      ? props.isLast
        ? "rgb(0 0 0 / 10%) -4px 0px 8px -6px inset"
        : "none"
      : props.right
      ? "rgb(0 0 0 / 10%) 4px 0px 8px 6px"
      : "none"};
  clip-path: ${(props) =>
    props.right ? "inset(-1px 0px 0px -350px)" : "none"};
  & > div > div {
    min-width: 0;
    white-space: normal;
    text-align: left;
  }

  &:hover {
    border-right: 1px solid #ccc;
  }
  border-bottom: 1px solid #ccc;
`;

export const Tr = styled.tr`
  &:nth-child(even) {
    background-color: #f2f2f2;
  }
  &:nth-child(odd) {
    background-color: #ffffff;
  }

  color: inherit;
  display: table-row;
  outline: 0;
  vertical-align: middle;
`;

export const TrSub = styled.tr`
  color: inherit;
  display: table-row;
  outline: 0;
  vertical-align: middle;
`;

export const TBody = styled.tbody`
  /* position: relative;
  display: block;
  height: ${(props) =>
    props.tableBodyHeight ? props.tableBodyHeight + `rem` : "auto"};
  overflow: scroll; */
  display: table-row-group;
`;

export const Thead = styled.thead`
  display: table-header-group;
  position: sticky;
  inset-block-start: 0;
  z-index: 99;
`;

export const PageButton = styled.button`
  border: 0;
  background: 0;
  font-family: "Source Sans Pro Semibold";
  font-size: 1.1rem;
  cursor: pointer;
  padding: 0.5rem;
  padding-bottom: 0.2rem;
  &:hover {
    background: #f2f2f2;
  }
`;

export const ActionsIcon = styled.button`
  cursor: pointer;
  font-size: 1.3rem;
  border: 0;
  background: 0;
  padding: 0;
`;

export const TableLoading = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
  display: flex;
  background: rgba(255, 255, 255, 0.7);
`;

export const Resizer = styled.div`
  right: 0;
  width: 10px;
  height: 100%;
  position: absolute;
  top: 0;
  z-index: 1;
  touch-action: none;

  &.isResizing {
  }
`;

export const InLineTitlePin = styled.div`
  display: flex;
  align-items: center;
  word-break: break-word;
`;
