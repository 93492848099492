import React, { useState } from "react";
import Card from "../../Card";
import Spinner from "../../../components/Loaders/Spinner";
import {
  MdExpandMore,
  MdChevronRight,
  MdTrendingFlat,
  MdTrendingDown,
  MdTrendingUp,
  MdNotifications,
} from "react-icons/md";
import { Scrollbars } from "react-custom-scrollbars";
import SmallToggle from "../../Toggle/SmallToggle";
import StyledLink from "../../../components/StyledLink";
import { useLocation } from "react-router-dom";
import styled from "styled-components/macro";
import { ReactComponent as CleanSVG } from "../../../assets/images/spotlight-empty-state-clean.svg";
import { ReactComponent as SunSvg } from "../../../assets/images/spotlight-empty-state-sun.svg";

const emptyArray = [CleanSVG, SunSvg];

const ItemContainer = styled.div``;
const StandardSubItemType = ({ item }) => {
  return (
    <div
      style={{
        display: "flex",
        marginBottom: "1rem",
      }}
    >
      <div
        style={{
          width: "1rem",
          borderBottom: "2px solid #e3e3e3",
        }}
      ></div>
      <div
        style={{
          paddingLeft: ".5rem",
          position: "relative",
          bottom: "-.4rem",
          width: "100%",
          display: "flex",
          color: "#656565",
        }}
      >
        <div style={{ fontSize: ".8125rem" }}>
          {item.columnName} ({item.standardColumnName})
        </div>
        <div
          style={{
            marginLeft: "auto",
            marginRight: ".5rem",
            fontSize: ".8125rem",
          }}
        >
          {item.failureCount} |{" "}
          {item?.percentFailureCount
            ? item?.percentFailureCount.toFixed()
            : null}
          %
        </div>
      </div>
    </div>
  );
};

const ColumSubItemType = ({ item }) => {
  return (
    <div
      style={{
        display: "flex",
        marginBottom: "1rem",
      }}
    >
      <div
        style={{
          width: "1rem",
          borderBottom: "2px solid #e3e3e3",
        }}
      ></div>
      <div
        style={{
          paddingLeft: ".5rem",
          position: "relative",
          bottom: "-.4rem",
          width: "100%",
          display: "flex",
          opacity: 0.9,
          color: "#656565",
        }}
      >
        <div style={{ fontSize: ".8125rem" }}>
          {item.ruleStandardInstancedName} ({item.standardColumnName})
        </div>
        <div
          style={{
            marginLeft: "auto",
            marginRight: ".5rem",
            fontSize: ".8125rem",
          }}
        >
          {item.failureCount} |{" "}
          {item?.percentFailureCount
            ? item?.percentFailureCount.toFixed()
            : null}
          %
        </div>
      </div>
    </div>
  );
};

const RenderChange = ({ change, previouslyTopOrder }) => {
  if (previouslyTopOrder === 2147483647)
    return (
      <span style={{ color: "red" }} title="Policy previously had 0 failures">
        <MdNotifications />
      </span>
    );

  switch (change) {
    case "NOCHANGE":
      return (
        <span style={{ color: "#0099FF" }}>
          <MdTrendingFlat />
        </span>
      );

    case "INCREASED":
      return (
        <span style={{ color: "red" }}>
          <MdTrendingUp />
        </span>
      );

    case "DECREASED":
      return (
        <span style={{ color: "green" }}>
          <MdTrendingDown />
        </span>
      );

    default:
      return null;
  }
};

const CollapseSection = ({ item, type }) => {
  const [open, setOpen] = useState(false);
  return (
    <div style={{ paddingRight: "1rem" }}>
      <ItemContainer
        style={{
          display: "flex",
          marginTop: "1rem",
          paddingBottom: ".3rem",
          borderBottom: "1px solid #e3e3e3",
        }}
      >
        <div
          onClick={() => setOpen((prevState) => !prevState)}
          style={{
            alignItems: "center",
            top: "-1px",
            position: "relative",
            cursor: "pointer",
          }}
        >
          {open ? <MdExpandMore /> : <MdChevronRight />}
        </div>
        <div style={{ flex: 1 }}>
          <div
            style={{ display: "flex", cursor: "pointer" }}
            onClick={() => setOpen((prevState) => !prevState)}
          >
            <div
              style={{
                fontFamily: "Source Sans Pro Semibold",
              }}
            >
              {item.name}
            </div>
            <div
              style={{
                marginLeft: "auto",
                display: "flex",
                alignItems: "center",
              }}
            >
              {item.failureCount}{" "}
              <RenderChange
                change={item?.failureCountChange}
                previouslyTopOrder={item?.previouslyTopOrder}
              />
            </div>
          </div>
          {open ? (
            <div
              style={{
                borderLeft: "2px solid #e3e3e3",
              }}
            >
              {item.details.map((subItem) => {
                if (type === "column") {
                  return <ColumSubItemType item={subItem} />;
                } else {
                  return <StandardSubItemType item={subItem} />;
                }
              })}
            </div>
          ) : null}
        </div>
      </ItemContainer>
    </div>
  );
};

const DataQualityScore = React.memo(
  ({ data, loading, errors, sourceId, spotlightView, setSpotlightView }) => {
    let location = useLocation();
    const toggleData = [
      {
        name: "Policy",
        action: () => setSpotlightView("Policy"),
      },
      {
        name: "Column",
        action: () => setSpotlightView("Column"),
      },
    ];

    //Actions Section of Widget
    function Actions() {
      if (!data) return null;
      return (
        <SmallToggle toggleState={spotlightView} toggleData={toggleData} />
      );
    }

    //Body Section of Widget
    function Body() {
      if (loading) return <Spinner />;
      if (errors) return <div>Errors</div>;
      if (!data) return <div>No Data Available</div>;

      const ruleStandardEntries = data?.ruleStandardEntries ?? [];
      const columnEntries = data?.columnEntries ?? [];
      const topTenRuleStandardEntries = ruleStandardEntries.slice(0, 10);
      const topTenColumnEntries = columnEntries.slice(0, 10);
      const isEmpty =
        !topTenRuleStandardEntries?.length && !topTenColumnEntries?.length;
      const RandomEmpty =
        emptyArray[Math.floor(Math.random() * emptyArray.length)];
      return (
        <div>
          <Scrollbars style={{ height: "290px" }}>
            {spotlightView === "Policy" ? (
              topTenRuleStandardEntries?.length ? (
                topTenRuleStandardEntries.map((rule) => {
                  return <CollapseSection type={"policy"} item={rule} />;
                })
              ) : (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "100%",
                  }}
                >
                  <RandomEmpty />
                </div>
              )
            ) : topTenColumnEntries?.length ? (
              topTenColumnEntries.map((column) => {
                return <CollapseSection type={"column"} item={column} />;
              })
            ) : (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "100%",
                }}
              >
                <RandomEmpty />
              </div>
            )}
          </Scrollbars>
          {!isEmpty ? (
            <div
              style={{
                paddingTop: "1.5rem",
                display: "flex",
                justifyContent: "center",
                borderTop: "1px solid #c9c9c9",
              }}
            >
              <StyledLink to={`${location?.pathname}/failures`}>
                View All
              </StyledLink>
            </div>
          ) : null}
        </div>
      );
    }

    return (
      <Card full={true} actions={Actions} title={"SPOTLIGHT"} body={Body} />
    );
  }
);

export default DataQualityScore;
