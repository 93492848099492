// Create Business Rule standard
export const createRuleStandard = /* GraphQL */ `
  mutation ($newStandard: BusinessRuleStandardInputModelInput!) {
    createRuleStandard(newStandard: $newStandard)
  }
`;

// Set Business Rule standard Name
export const setRuleStandardName = /* GraphQL */ `
  mutation ($id: Int!, $name: String!, $description: String!) {
    setRuleStandardName(id: $id, name: $name, description: $description) {
      id
    }
  }
`;

// Set Business Rule standard Implementation
export const updateBusinessStandardAndFilter = /* GraphQL */ `
  mutation (
    $id: Int!
    $newVersion: BusinessRuleStandardVersionInputModelInput!
    $name: String!
    $description: String!
    $permissions: [BusinessRuleStandardPermissionsInputModelInput!]!
  ) {
    addRuleStandardVersion(id: $id, newVersion: $newVersion) {
      id
    }
    setRuleStandardName(id: $id, name: $name, description: $description) {
      id
    }
    setRuleStandardPermissions(id: $id, permissions: $permissions) {
      id
    }
  }
`;

// Set Business Rule standard Enabled Flag
export const setRuleStandardEnabledFlag = /* GraphQL */ `
  mutation ($id: Int!, $enabled: Boolean!) {
    setRuleStandardEnabledFlag(id: $id, enabled: $enabled) {
      id
    }
  }
`;

// Set Data Source Rule Instances
export const createNewRuleInstance = /* GraphQL */ `
  mutation (
    $dataSourceId: Int!
    $instance: BusinessRuleInstanceInputModelInput!
    $saveAsDraft: Boolean!
  ) {
    createNewRuleInstance(
      dataSourceId: $dataSourceId
      instance: $instance
      saveAsDraft: $saveAsDraft
    ) {
      errors {
        message
        resourceId
        resourceName
        resourceType
      }
      policyId
      policyVersionId
    }
  }
`;

// Set Data Source Rule Instances
export const addRuleInstanceVersion = /* GraphQL */ `
  mutation (
    $instanceId: Long!
    $newVersion: BusinessRuleInstanceVersionInputModelInput!
    $saveAsDraft: Boolean!
  ) {
    addRuleInstanceVersion(
      instanceId: $instanceId
      newVersion: $newVersion
      saveAsDraft: $saveAsDraft
    ) {
      policyId
      errors {
        message
        resourceId
        resourceName
        resourceType
      }
      policyVersionId
    }
  }
`;

// Set Data Source Rule Instance Enable Flag
export const setRuleInstanceEnabledFlag = /* GraphQL */ `
  mutation ($ruleInstanceId: Long!, $enableState: BusinessRuleInstanceState!) {
    setRuleInstanceEnabledFlag(
      ruleInstanceId: $ruleInstanceId
      enableState: $enableState
    ) {
      id
    }
  }
`;

// Set Data Source Rule Instance Name
export const setRuleInstanceName = /* GraphQL */ `
  mutation ($instanceId: Long!, $newName: String!) {
    setRuleInstanceName(instanceId: $instanceId, newName: $newName) {
      id
    }
  }
`;

// Set Data Source Rule Instance Enable Flag
export const setRuleInstancePriority = /* GraphQL */ `
  mutation ($instanceId: Long!, $priority: BusinessRuleInstancePriority!) {
    setRuleInstancePriority(instanceId: $instanceId, priority: $priority) {
      id
    }
  }
`;

// Set Data Source Filter Instances
export const setDataSourceFilterRule = /* GraphQL */ `
  mutation (
    $dataSourceId: Int!
    $instance: BusinessRuleInstanceInputModelInput!
  ) {
    setDataSourceFilterRule(dataSourceId: $dataSourceId, instance: $instance) {
      id
    }
  }
`;

// Set Data Source Filter Instances
export const setFilterRule = /* GraphQL */ `
  mutation (
    $dataSourceId: Int!
    $instance: BusinessRuleInstanceInputModelInput!
  ) {
    setFilterRule(dataSourceId: $dataSourceId, instance: $instance) {
      id
    }
  }
`;

// Validate Policy Mappings
export const validatePolicyMapping = /* GraphQL */ `
  mutation ($mapping: PolicyMappingInput!) {
    validatePolicyMapping(mapping: $mapping) {
      message
      resourceId
      resourceName
      resourceType
    }
  }
`;

// Set  Rule Instance Approval State
export const setRuleInstanceApprovalState = /* GraphQL */ `
  mutation (
    $instanceId: Long!
    $approvalState: BusinessRuleInstanceApprovalState!
  ) {
    setRuleInstanceApprovalState(
      instanceId: $instanceId
      approvalState: $approvalState
    ) {
      id
    }
  }
`;
