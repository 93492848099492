import React, { useRef, useEffect, useState, useCallback } from "react";
import { MdMoreVert } from "react-icons/md";
import styled from "styled-components/macro";

const ColumnActionsWrapper = styled.div`
  position: relative;
  width: 100%;
  text-align: right;
  display: flex;
  justify-content: right;
  user-select: none;
`;

const ActionsButton = styled.div`
  display: block;
  cursor: pointer;
`;

const TableActionsWrapper = ({ children, toggle, showOverride }) => {
  const node = useRef(null);

  const handleClick = useCallback(
    (e) => {
      //find if target is actionsToggle
      if (
        node.current.contains(e.target) ||
        e?.target?.classList.contains("actionsToggle") ||
        e?.target?.parentNode?.classList.contains("actionsToggle")
      ) {
        // click was detected inside the node, handle it here
        e.stopPropagation();
        return;
      }
      // click outside of node detected, close menu
      if (showOverride) {
        toggle(false);
      } else {
        toggle(true);
      }
    },
    [toggle, showOverride]
  );

  useEffect(() => {
    // add listener on mount
    document.addEventListener("mousedown", handleClick);
    // when unmounted this return will be called
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, [handleClick]);

  return <div ref={node}>{children}</div>;
};

const PopoverActions = styled.div`
  position: absolute;
  top: -10px;
  z-index: 6;
  right: 0;
  background: #fff;
  min-width: ${(props) =>
    `${props?.minWidth ? `${props?.minWidth}px` : "320px"}`};
  margin-right: 1.5rem;
  box-shadow: 0px 6px 13px 0px rgb(0 0 0 / 28%);
  padding-top: 0.3rem;
`;

const TableActions = ({
  children,
  minWidth,
  totalColumnsWidth,
  getTableWidth,
}) => {
  const [showActions, setShowActions] = useState(true);
  const [showOverride, setShowOverride] = useState(false);

  const columnActions = useRef(null);

  const actionToggle = () => {
    if (showOverride) {
      setShowOverride(false);
    } else {
      setShowOverride(true);
    }
  };

  useEffect(() => {
    const tableWidth = getTableWidth();
    function handleResize() {
      const tableWidth = getTableWidth();

      if (tableWidth >= totalColumnsWidth) {
        setShowActions(true);
      } else if (columnActions?.current?.offsetWidth <= minWidth) {
        setShowActions(false);
      } else {
        setShowActions(true);
      }
    }

    if (tableWidth >= totalColumnsWidth) {
      setShowActions(true);
    } else if (columnActions?.current?.offsetWidth <= minWidth) {
      setShowActions(false);
    } else {
      setShowActions(true);
    }

    window.addEventListener("resize", handleResize);
  }, [minWidth, totalColumnsWidth, getTableWidth]);

  return (
    <ColumnActionsWrapper className="actionsToggle" ref={columnActions}>
      {showActions ? (
        children
      ) : (
        <>
          <ActionsButton
            className="actionsToggle"
            onClick={() => actionToggle()}
          >
            <MdMoreVert className="actionsToggle" />
          </ActionsButton>
          {showOverride ? (
            <TableActionsWrapper
              toggle={setShowOverride}
              showOverride={showOverride}
            >
              <PopoverActions minWidth={minWidth}>{children}</PopoverActions>
            </TableActionsWrapper>
          ) : null}
        </>
      )}
    </ColumnActionsWrapper>
  );
};

export default TableActions;
