import React, { useContext } from "react";
import { Route, NavLink, Switch } from "react-router-dom";
import styled from "styled-components/macro";
import SourceDetailsForm from "../../components/Forms/Sources/SourceDetailsForm";
import ColumnView from "../../components/Forms/Sources/ColumnView";
import DataSourcePermissions from "./DataSourcePermissions";
import Alerts from "../../components/Forms/Sources/Alerts";
import MappingComponentContainer from "./MappingComponentContainer";
import ViewMapping from "./ViewMapping";
import { AuthContext } from "../../contexts/AuthContext";
import DataSourceActions from "./DataSourceActions";
import ReportingList from "./Reporting/ReportingList";

const TabLink = styled(NavLink)`
  font-size: 0.9rem;
  padding: 0.7rem;
  border-left: 4px solid transparent;
  text-decoration: none;
  opacity: 0.5;
  display: inline-block;
  color: ${(props) => props.theme.onSurface};
  &.active {
    opacity: 1;
    border-left: 4px solid ${(props) => props.theme.onSecondarySurface};
  }
  &:hover {
    opacity: 1;
  }
  text-transform: uppercase;
`;

const ManageContainer = styled.div`
  display: flex;
`;

const ManageContent = styled.div`
  padding: 1rem;
  flex: 1;
  overflow-y: auto;
  display: block;
`;

const ManageSource = ({ sourceId, reportAvailable }) => {
  const { user } = useContext(AuthContext);
  return (
    <ManageContainer>
      <ul style={{ borderRight: "1px solid #efefef" }}>
        <li>
          <TabLink to={`/sources/${sourceId}/manage/policies`}>
            Policies
          </TabLink>
        </li>
        <li>
          <TabLink to={`/sources/${sourceId}/manage/filters`}>Filters</TabLink>
        </li>
        {user && user.role >= 2 && (
          <>
            <li>
              <TabLink exact to={`/sources/${sourceId}/manage/columns`}>
                Columns
              </TabLink>
            </li>

            <li>
              <TabLink exact to={`/sources/${sourceId}/manage/alerts`}>
                Alerts
              </TabLink>
            </li>
            <li>
              <TabLink exact to={`/sources/${sourceId}/manage/configuration`}>
                Configuration
              </TabLink>
            </li>
            <li>
              <TabLink exact to={`/sources/${sourceId}/manage/permissions`}>
                Permissions
              </TabLink>
            </li>
            <li>
              <TabLink to={`/sources/${sourceId}/manage/reporting`}>
                Reporting
              </TabLink>
            </li>
            <li>
              <TabLink exact to={`/sources/${sourceId}/manage/actions`}>
                Actions
              </TabLink>
            </li>
          </>
        )}
      </ul>
      <ManageContent>
        <Switch>
          <Route
            path={`/sources/:sourceId/manage/policies/new/suggestions`}
            exact
            component={() => (
              <MappingComponentContainer
                mappingType={"RULE"}
                sourceId={sourceId}
              />
            )}
          />

          <Route
            path={`/sources/:sourceId/manage/filters/new/suggestions`}
            exact
            component={() => (
              <MappingComponentContainer
                mappingType={"FILTER"}
                sourceId={sourceId}
              />
            )}
          />

          <Route
            path={`/sources/:sourceId/manage/policies/:instanceId/:instanceVersionId`}
            component={() => (
              <ViewMapping sourceId={sourceId} mappingType={"RULE"} />
            )}
          />

          <Route
            path={`/sources/:sourceId/manage/filters/:instanceId/:instanceVersionId`}
            component={() => (
              <ViewMapping sourceId={sourceId} mappingType={"FILTER"} />
            )}
          />

          <Route
            path={`/sources/:sourceId/manage/policies`}
            component={() => (
              <MappingComponentContainer
                mappingType={"RULE"}
                sourceId={sourceId}
              />
            )}
          />

          <Route
            path={`/sources/:sourceId/manage/filters`}
            component={() => (
              <MappingComponentContainer
                mappingType={"FILTER"}
                sourceId={sourceId}
              />
            )}
          />

          <Route
            path={`/sources/:sourceId/manage/columns`}
            component={() => <ColumnView sourceId={sourceId} />}
          />

          <Route
            path={`/sources/:sourceId/manage/alerts`}
            component={() => <Alerts sourceId={sourceId} />}
          />

          <Route
            path={`/sources/:sourceId/manage/configuration`}
            component={() => <SourceDetailsForm sourceId={sourceId} />}
          />

          <Route
            path={`/sources/:sourceId/manage/permissions`}
            component={() => <DataSourcePermissions sourceId={sourceId} />}
          />

          <Route
            path={`/sources/:sourceId/manage/actions`}
            component={() => <DataSourceActions sourceId={sourceId} />}
          />

          <Route
            path={`/sources/:sourceId/manage/reporting`}
            component={() => (
              <ReportingList
                sourceId={sourceId}
                reportAvailable={reportAvailable}
              />
            )}
          />
        </Switch>
      </ManageContent>
    </ManageContainer>
  );
};

export default ManageSource;
