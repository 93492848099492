import React from "react";
import styled from "styled-components/macro";
import { IoIosArrowRoundDown, IoIosArrowRoundUp } from "react-icons/io";

const StatText = styled.div`
  font-size: 1.7em;
  font-family: "Source Sans Pro Semibold";
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
`;

const StatCountAndChange = styled.div`
  display: block;
  align-items: baseline;
`;

const StatDescription = styled.div`
  font-size: 0.9em;
  text-transform: uppercase;
  margin-bottom: 0.5rem;
`;

const StatDetails = styled.div``;

const StatTrend = styled.div`
  font-size: 2.5em;
  text-align: center;
`;

const StatContainer = styled.div`
  display: flex;
  align-items: center;
`;

const TrendIcon = styled.div`
  color: ${(props) =>
    props.direction === null || !props.goodTrend
      ? props.theme.secondary
      : props.direction === props.goodTrend
      ? "#63D600"
      : "#C47676"};
  display: flex;
  align-items: center;
`;

const PercentChangeComponent = styled.div`
  font-size: 0.9rem;
  text-transform: uppercase;
`;

const PercentIcon = styled.div`
  font-size: 1.5rem;
  padding: 0;
`;

const StatTrendContainer = styled.div`
  display: flex;
  align-items: baseline;
`;

const isTrendUpDownEqual = (percentChange, goodTrend) => {
  const sign = Math.sign(percentChange);
  if (sign === 0) {
    return (
      <TrendIcon direction={null} goodTrend={goodTrend}>
        <PercentChangeComponent>{percentChange + "%"}</PercentChangeComponent>
      </TrendIcon>
    );
  } else if (sign > 0) {
    return (
      <TrendIcon direction={"inc"} goodTrend={goodTrend}>
        <PercentChangeComponent>{percentChange + "%"}</PercentChangeComponent>
        <PercentIcon>
          <IoIosArrowRoundUp />
        </PercentIcon>
      </TrendIcon>
    );
  } else {
    return (
      <TrendIcon direction={"dec"} goodTrend={goodTrend}>
        <PercentChangeComponent>{percentChange + "%"}</PercentChangeComponent>
        <PercentIcon>
          <IoIosArrowRoundDown />
        </PercentIcon>
      </TrendIcon>
    );
  }
};

const MinimalStatHoverWidget = ({
  currentStat,
  statDescription,
  percentChange,
  goodTrend,
  trend = true,
}) => {
  return (
    <StatContainer>
      <StatDetails>
        <StatDescription>{statDescription}</StatDescription>
        <StatCountAndChange>
          <StatText>{currentStat}</StatText>
          {statDescription && percentChange && trend ? (
            <StatTrendContainer>
              <StatTrend>
                {isTrendUpDownEqual(percentChange, goodTrend)}
              </StatTrend>
            </StatTrendContainer>
          ) : null}
        </StatCountAndChange>
      </StatDetails>
    </StatContainer>
  );
};

export default MinimalStatHoverWidget;
