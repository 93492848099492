// List Connections
export const tagsPageList = /* GraphQL */ `
  query (
    $first: Int
    $after: String
    $where: TagOutputModelFilterInput
    $order: [TagOutputModelSortInput!]
  ) {
    tagsPAGED(first: $first, after: $after, where: $where, order: $order) {
      totalCount
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      edges {
        cursor
        node {
          id
          name
          description
          tagInstanceCount
          dataSources {
            id
            name
          }
          workflows {
            id
            name
          }
          businessRuleStandards {
            id
            name
          }
        }
      }
    }
  }
`;

// List Tags List
export const tagsList = /* GraphQL */ `
  query (
    $where: TagOutputModelFilterInput
    $order: [TagOutputModelSortInput!]
  ) {
    allTags(where: $where, order: $order) {
      id
      name
      description
      dataSources {
        id
        name
      }
      workflows {
        id
        name
      }
    }
  }
`;
