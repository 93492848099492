import React, { useRef, useCallback, useEffect } from "react";
import styled from "styled-components/macro";
import Spacer from "../Layout/Spacer";
import { MdClose } from "react-icons/md";

const ViewRow = styled.div`
  z-index: 999;
  background: #fff;
  padding: 2rem;
  padding-top: 2.5rem;
  overflow-y: auto;
  position: absolute;
  width: 80%;
  right: 48%;
  top: 50%;
  -webkit-transform: translate(51%, -50%);
  -moz-transform: translate(51%, -50%); /* Older Gecko browser */
  -ms-transform: translate(51%, -50%); /* IE9+ */
  -o-transform: translate(51%, -50%);
  transform: translate(51%, -50%);
`;

const Opaque = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  z-index: 998;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.3);
`;

const CloseButton = styled.button`
  border: none;
  background: none;
  cursor: pointer;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.6rem;
  position: absolute;
  top: 0;
  right: 0;
  padding: 0;
  margin: 0.5rem;
`;

const ModalContent = styled.div`
  background: #fff;
  overflow: auto;
  color: ${(props) => props.theme.onSurface};
`;

const ModalHeader = styled.div`
  display: flex;
`;

const ModalTitle = styled.div`
  flex: 1;
`;

const TableRowView = ({ title, disableHide, children, toggle }) => {
  const node = useRef(null);

  const handleClick = useCallback(
    (e) => {
      //find if target is select option
      if (
        node.current.contains(e.target) ||
        e?.target?.classList.contains("react-select__option")
      ) {
        // click was detected inside the node, handle it here
        e.stopPropagation();
        return;
      }
      // click outside of node detected, close menu
      toggle(null);
    },
    [toggle]
  );

  useEffect(() => {
    // add listener on mount
    document.addEventListener("mousedown", handleClick);
    // when unmounted this return will be called
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, [handleClick]);

  return (
    <Opaque>
      <ViewRow ref={node}>
        <ModalContent>
          <ModalHeader>
            <ModalTitle>{title ? <h3>{title}</h3> : null}</ModalTitle>
            {disableHide ? null : (
              <CloseButton onClick={() => toggle(null)} type="button">
                <MdClose />
              </CloseButton>
            )}
          </ModalHeader>
          <Spacer />
          {children}
        </ModalContent>
      </ViewRow>
    </Opaque>
  );
};

export default TableRowView;
