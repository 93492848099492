import React, { useEffect, useState } from "react";
import { Formik, Form, yupToFormErrors, validateYupSchema } from "formik";
import {
  FormControl,
  FormActions,
  StyledField,
  StyledSelect,
  Label,
  ErrorMessage,
  StyledTextArea,
} from "../../Form/FormControls";
import Button from "../../Button";
import Spinner from "../../Loaders/Spinner";
import ErrorMessages from "../../Notifications/ErrorMessages";
import { useApi } from "../../../api/useApi";
import { updateOcrConnection } from "../../../api/connectionMutations";
import * as Yup from "yup";
import { connectionsList } from "../../../api/connectionQueries";

import { namingConventions } from "../../../common/formOptions";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Modal from "../../Modal";

const prebuiltModels = [
  {
    label: "Business Card",
    value: "BUSINESS_CARD",
  },
  {
    label: "Receipt",
    value: "RECEIPT",
  },
  {
    label: "Invoice",
    value: "INVOICE",
  },
  {
    label: "Id Document",
    value: "IDDOC",
  },
  {
    label: "Custom",
    value: "", //comes back from server as GUID
  },
];

const IdentificationTypes = [
  {
    label: "Identification",
    text: "NONE",
    value: 0,
  },
  {
    label: "Process",
    text: "IMAGE_CLASSIFICATION_MSFT_CUSTOM_VISION",
    value: 3,
  },
  // {
  //   label: "Text",
  //   text: "TEXT_CLASSIFICATION_COMPUTER_VISION_READ_API",
  //   value: 4,
  // },
  // {
  //   label: "Title Pages",
  //   text: "EMBEDDED_TEXT_DOCUMENT_MATCH",
  //   value: 5,
  // },
  {
    label: "Filename",
    text: "FILENAME_CONTAINS_MATCH",
    value: 8,
  },
  {
    label: "IgnoreOutput",
    text: "FILENAME_IGNORE",
    value: 16,
  },
  {
    label: "Candidate",
    text: "CONTAINS_CANDIDATES",
    value: 32,
  },
  {
    label: "LocatePageOnly",
    text: "LOCATE_PAGE_ONLY",
    value: 64,
  },
];

const formatProviderSelect = ({ label, docProviderType }) => {
  return (
    <div style={{ display: "flex", flexDirection: "column", padding: ".5rem" }}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          fontSize: "1.1rem",
          marginBottom: ".3rem",
        }}
      >
        {label}
      </div>
      {docProviderType && (
        <div
          style={{
            opacity: 0.5,
            fontSize: "1rem",
            position: "relative",
            display: "block",
            maxWidth: "100%",
            whiteSpace: "normal",
          }}
        >
          {docProviderType}
        </div>
      )}
    </div>
  );
};

const ImportContainer = ({ importMultiDocuments, setShowImport }) => {
  const [importState, setImportState] = useState(null);
  const [importError, setImportError] = useState(null);
  const [replaceAll, setReplaceAll] = useState(false);
  return (
    <>
      <StyledTextArea
        name={`standardImport`}
        placeholder="Paste JSON Import Here"
        label="JSON Import"
        component="textarea"
        rows="5"
        onChange={(e) => {
          try {
            const parsedObj = JSON.parse(e);
            if (parsedObj?.ocrDocuments && parsedObj?.ocrDocuments?.length) {
              setImportState(parsedObj);
              setImportError(false);
            } else {
              setImportError(true);
            }
          } catch (e) {
            setImportError(true);
          }
        }}
      />

      {importError ? (
        <ErrorMessages
          errors={[
            {
              message: "Error With Import Format",
            },
          ]}
        />
      ) : null}

      <FormActions>
        <label style={{ cursor: "pointer", marginRight: "1rem" }}>
          <input
            type="checkbox"
            name={`Replace All Documents`}
            label="Replace All Documents"
            checked={replaceAll}
            onChange={() => setReplaceAll((prev) => !prev)}
          />
          Replace Existing Documents
        </label>

        <Button
          type="button"
          onClick={() => {
            importMultiDocuments(importState, replaceAll);
            setShowImport(false);
          }}
          disabled={!importState}
        >
          Import
        </Button>
      </FormActions>
    </>
  );
};

const ImportSingleContainer = ({
  importSingleDocument,
  setShowSingleImport,
}) => {
  const [importState, setImportState] = useState(null);
  const [importError, setImportError] = useState(null);

  return (
    <>
      <StyledTextArea
        name={`standardImport`}
        placeholder="Paste JSON Import Here"
        label="JSON Import"
        component="textarea"
        rows="5"
        onChange={(e) => {
          try {
            const parsedObj = JSON.parse(e);
            if (parsedObj?.modelName) {
              setImportState(parsedObj);
              setImportError(false);
            } else {
              setImportError(true);
            }
          } catch (e) {
            setImportError(true);
          }
        }}
      />

      {importError ? (
        <ErrorMessages
          errors={[
            {
              message:
                "Error With Import Format, be sure the document has a name",
            },
          ]}
        />
      ) : null}

      <FormActions>
        <Button
          type="button"
          onClick={() => {
            importSingleDocument(importState);
            setShowSingleImport(false);
          }}
          disabled={!importState}
        >
          Import
        </Button>
      </FormActions>
    </>
  );
};

const OCRSettingsForm = ({ connection, getUpdatedConnection }) => {
  // Query for Data Providers
  const queryProviders = `
      query(){
        allDocProviders() {
            displayName
            docProviderType
            enabled
            id
        }
    }
  `;
  // Loading the query on component mount for latest configuration
  const [{ errors: providersErrors, data: providersData }] =
    useApi(queryProviders);

  const providerOptions =
    providersData?.allDocProviders
      ?.filter((pd) => pd.enabled)
      .map((provider) => {
        return {
          label: provider?.displayName,
          value: provider?.id,
          docProviderType: provider?.docProviderType,
        };
      }) ?? [];

  const [showImport, setShowImport] = useState(false);
  const [showSingleImport, setShowSingleImport] = useState(false);
  const [ocrEnabled, setOcrEnabled] = useState(connection?.ocrConnection);

  //Prime GraphAPI For Permissions Updates
  const [{ loading, errors, data }, doUpdateOCR] = useApi();

  const [{ data: connectionData }] = useApi(connectionsList, {
    first: 9999, //fix to get all with hc update
    where: {
      enabled: { eq: true },
      id: { neq: -1 },
    },
    order: {
      name: "ASC",
    },
  });

  // TODO: We already have the new form data, lets move the connection into state
  // and update from there instead of circling back, for now we hit the server to get valid data
  useEffect(() => {
    if (data) {
      getUpdatedConnection();
    }
  }, [data, getUpdatedConnection]);

  //callback
  const updateOCR = (values) => {
    const variables = {
      connectionId: connection.id,
      ocrConnection: {
        identificationType: values?.ocrConnection?.identificationType,
        inputPath: values?.ocrConnection?.inputPath,
        isAutoSource: values?.ocrConnection?.isAutoSource,
        containerName: values?.ocrConnection?.containerName,
        docProviderId: values?.ocrConnection?.docProviderId,
        destinationConnectionId: values?.ocrConnection?.destinationConnectionId,
        customVisionProjectId:
          values?.ocrConnection?.customVisionProjectId ?? "",
        customVisionIteration:
          values?.ocrConnection?.customVisionIteration ?? "",
        ocrDocuments: values?.ocrConnection?.ocrDocuments.map((docs) => {
          return {
            ...docs,
            minimumPredictionThreshold: Number(
              docs?.minimumPredictionThreshold
            ),
            id: docs?.id ?? 0,
            minimumPageCount:
              docs?.minimumPageCount === "" || docs?.minimumPageCount === 0
                ? null
                : docs?.minimumPageCount,
            modelId: docs?.modelId?.value,
            pagesToRead: docs?.pagesToRead ?? "",
            locateOnlyTypeIds: docs?.locateOnlyTypeIds ?? "",
          };
        }),
        namingConvention: values?.ocrConnection?.namingConvention?.value,
      },
    };
    doUpdateOCR({ query: updateOcrConnection, variables });
  };

  const connectionsOptions =
    connectionData?.availableConnections?.edges?.map((connection) => {
      return {
        label: connection?.node?.name,
        value: connection?.node?.id,
      };
    }) ?? [];
  if (!ocrEnabled)
    return (
      <>
        <FormControl>
          <label>
            Connection with OCR Source Location
            <input
              type="checkbox"
              checked={ocrEnabled}
              name={`ocr`}
              onChange={() => {
                setOcrEnabled((prev) => !prev);
              }}
            />
          </label>
        </FormControl>
      </>
    );
  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={{
          ocrConnection: connection?.ocrConnection
            ? {
                containerName: connection?.ocrConnection?.containerName,
                inputPath: connection?.ocrConnection?.inputPath,
                isAutoSource: connection?.ocrConnection?.isAutoSource,
                docProviderId: providerOptions.find(
                  (nc) => nc?.value === connection?.ocrConnection?.docProviderId
                )?.value,
                identificationType: IdentificationTypes.find(
                  (it) =>
                    it.text === connection?.ocrConnection?.identificationType
                )?.value,
                destinationConnectionId:
                  connection?.ocrConnection?.destinationConnectionId,
                customVisionProjectId:
                  connection?.ocrConnection?.customVisionProjectId,
                customVisionIteration:
                  connection?.ocrConnection?.customVisionIteration,
                ocrDocuments:
                  connection?.ocrConnection?.ocrDocuments.map((docs) => {
                    const modelIdToLower = docs?.modelId?.toLowerCase() ?? "";
                    return {
                      ...docs,
                      identificationType: IdentificationTypes.find(
                        (it) => it.text === docs?.identificationType
                      )?.value,
                      modelId: docs?.modelId
                        ? prebuiltModels.find(
                            (pbm) => pbm?.value.toLowerCase() === modelIdToLower
                          ) ?? {
                            label: "Custom",
                            value: docs?.modelId,
                          }
                        : prebuiltModels[4],
                    };
                  }) ?? [],
                namingConvention: namingConventions.find(
                  (nc) =>
                    nc?.value === connection?.ocrConnection?.namingConvention
                ),
              }
            : {
                docProviderId: null,
                containerName: null,
                inputPath: null,
                destinationConnectionId: null,
                identificationType: null,
                ocrDocuments: [],
                namingConvention: 2,
              },
        }}
        validate={(values) => {
          let schema;
          schema = Yup.object().shape({
            ocrConnection: Yup.object().shape({
              ocrDocuments: Yup.array().of(
                Yup.object().shape({
                  typeId: Yup.string(),
                  // modelId: Yup.string().required("Required"),
                  modelName: Yup.string(),
                  docIdOnly: Yup.bool(),
                  versions: Yup.string(),
                  // pagesToRead: Yup.string(),
                })
              ),
            }),
          });
          const promise = validateYupSchema(values, schema);

          return new Promise((resolve, reject) => {
            promise.then(
              () => {
                resolve({});
              },
              (err) => {
                if (err.name === "ValidationError") {
                  resolve(yupToFormErrors(err));
                } else {
                  reject(err);
                }
              }
            );
          });
        }}
        validateOnMount={true}
        onSubmit={(values) => updateOCR(values)}
      >
        {({ values, setFieldValue }) => {
          const importMultiDocuments = (ocrDocuments, replaceAll) => {
            setFieldValue(
              `ocrConnection.ocrDocuments`,
              replaceAll
                ? [...ocrDocuments.ocrDocuments]
                : [
                    ...values?.ocrConnection?.ocrDocuments,
                    ...ocrDocuments.ocrDocuments,
                  ]
            );

            toast.success("Documents Imported", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          };

          const importSingleDocument = (document) => {
            setFieldValue(`ocrConnection.ocrDocuments`, [
              ...values?.ocrConnection?.ocrDocuments,
              document,
            ]);

            toast.success("Document Imported", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          };

          const isCustomVision = [
            "CUSTOM_VISION_PREDICTION",
            "CUSTOM_VISION_TRAINING",
          ].includes(
            providerOptions.find(
              (nc) => nc?.value === values?.ocrConnection?.docProviderId
            )?.docProviderType
          );

          const isDestinationVisible = [
            "EXTRACT_DOCUMENT",
            "COPY_DOCUMENT",
            "XML_LOAN_LOGIC",
            "CUSTOM_VISION_PREDICTION",
          ].includes(
            providerOptions.find(
              (po) => po.value === values?.ocrConnection?.docProviderId
            )?.docProviderType
          );

          const hiddenDueToXMLCopyExtract = [
            "EXTRACT_DOCUMENT",
            "COPY_DOCUMENT",
            "XML_LOAN_LOGIC",
          ].includes(
            providerOptions.find(
              (po) => po.value === values?.ocrConnection?.docProviderId
            )?.docProviderType
          );

          return (
            <Form>
              {showImport ? (
                <Modal
                  title={"Import Documents"}
                  hide={() => setShowImport((prevState) => !prevState)}
                >
                  <ImportContainer
                    importMultiDocuments={importMultiDocuments}
                    setShowImport={setShowImport}
                  />
                </Modal>
              ) : null}
              {showSingleImport ? (
                <Modal
                  title={"Import Single Document"}
                  hide={() => setShowSingleImport((prevState) => !prevState)}
                >
                  <ImportSingleContainer
                    importSingleDocument={importSingleDocument}
                    setShowSingleImport={setShowSingleImport}
                  />
                </Modal>
              ) : null}

              <>
                <h3>OCR Source Location</h3>
                <p>
                  <small>
                    <i>
                      Documents will be added on the "OCR" tab on the connection
                      once established.
                    </i>
                  </small>
                </p>

                <FormControl>
                  <label style={{ cursor: "pointer", marginRight: "1rem" }}>
                    <input
                      id={`autocreatefromCSV`}
                      type="checkbox"
                      name={`Automatically create source from CSV outputs`}
                      label="Automatically create source from CSV outputs"
                      checked={values?.ocrConnection?.isAutoSource}
                      onChange={(e) =>
                        setFieldValue(
                          `ocrConnection.isAutoSource`,
                          e?.target?.checked
                        )
                      }
                    />
                    Automatically create source from CSV outputs
                  </label>
                </FormControl>

                <FormControl>
                  <Label>Document Provider</Label>
                  <StyledSelect
                    className={`react-select-container`}
                    classNamePrefix={`react-select`}
                    name={`docProviderId`}
                    id={`docProviderId`}
                    inputId={`docProviderId-input`}
                    instanceId={`docProviderId-instance`}
                    label="Document Provider"
                    options={providerOptions}
                    formatOptionLabel={formatProviderSelect}
                    placeholder={`Select Document Provider`}
                    value={providerOptions.find(
                      (nc) => nc?.value === values?.ocrConnection?.docProviderId
                    )}
                    menuPortalTarget={document.body}
                    menuPlacement="auto"
                    onChange={(e) =>
                      setFieldValue(`ocrConnection.docProviderId`, e?.value)
                    }
                  />
                </FormControl>

                {providerOptions.find(
                  (nc) => nc?.value === values?.ocrConnection?.docProviderId
                )?.docProviderType !== "XMLLOANLOGIC" && (
                  <FormControl>
                    <Label>Identification Type</Label>
                    <StyledSelect
                      className={`react-select-container`}
                      classNamePrefix={`react-select`}
                      name={`ocrConnection.identificationType`}
                      id={`identificationType`}
                      inputId={`identificationTypeSelect-input`}
                      instanceId={`identificationTypeSelect-instance`}
                      label="Destination Connection"
                      options={IdentificationTypes}
                      placeholder={`Select Identification Type`}
                      value={IdentificationTypes.find(
                        (nc) =>
                          nc?.value ===
                          values?.ocrConnection?.identificationType
                      )}
                      menuPortalTarget={document.body}
                      menuPlacement="auto"
                      onChange={(e) =>
                        setFieldValue(
                          `ocrConnection.identificationType`,
                          e?.value
                        )
                      }
                    />
                    <ErrorMessage name={`ocrConnection.identificationType`} />
                  </FormControl>
                )}

                <FormControl>
                  <StyledField
                    name={`ocrConnection.containerName`}
                    type="text"
                    placeholder="Container Name"
                    label="Container Name"
                  />
                  <ErrorMessage name={`ocrConnection.containerName`} />
                </FormControl>

                <FormControl>
                  <StyledField
                    name={`ocrConnection.inputPath`}
                    type="text"
                    placeholder="Input Path"
                    label="Input Path (case sensitive)"
                  />
                  <ErrorMessage name={`ocrConnection.inputPath`} />
                </FormControl>

                {!hiddenDueToXMLCopyExtract && (
                  <>
                    <FormControl>
                      <Label>File Naming Convention</Label>
                      <StyledSelect
                        className={`react-select-container`}
                        classNamePrefix={`react-select`}
                        name={`ocrConnection.namingConvention`}
                        id={`namingConvention`}
                        inputId={`namingConventionSelect-input`}
                        instanceId={`namingConventionSelect-instance`}
                        label="Naming Convention"
                        options={namingConventions}
                        placeholder={`Select Convention`}
                        value={values?.ocrConnection?.namingConvention}
                        menuPortalTarget={document.body}
                        menuPlacement="auto"
                        onChange={(e) =>
                          setFieldValue(`ocrConnection.namingConvention`, e)
                        }
                      />
                    </FormControl>
                  </>
                )}

                {isDestinationVisible && (
                  <FormControl>
                    <Label>Destination Connection</Label>
                    <StyledSelect
                      className={`react-select-container`}
                      classNamePrefix={`react-select`}
                      name={`ocrConnection.destinationConnectionId`}
                      id={`destinationConnectionId`}
                      inputId={`destinationConnectionIdSelect-input`}
                      instanceId={`destinationConnectionIdSelect-instance`}
                      label="Destination Connection"
                      options={connectionsOptions}
                      placeholder={`Select Destination Connection`}
                      value={connectionsOptions.find(
                        (nc) =>
                          nc?.value ===
                          values?.ocrConnection?.destinationConnectionId
                      )}
                      menuPortalTarget={document.body}
                      menuPlacement="auto"
                      onChange={(e) =>
                        setFieldValue(
                          `ocrConnection.destinationConnectionId`,
                          e?.value
                        )
                      }
                    />
                  </FormControl>
                )}

                {isCustomVision && (
                  <div
                    style={{
                      padding: "1rem",
                      border: "1px solid #ccc",
                      borderRadius: "1rem",
                      marginBottom: "1rem",
                    }}
                  >
                    <h4 style={{ marginBottom: "1rem" }}>
                      Custom Vision Settings
                    </h4>

                    {/* Custom Vision Info */}

                    <FormControl>
                      <StyledField
                        name={`ocrConnection.customVisionProjectId`}
                        type="text"
                        placeholder="Custom Vision Project Id"
                        label="Custom Vision Project Id"
                      />
                      <ErrorMessage
                        name={`ocrConnection.customVisionProjectId`}
                      />
                    </FormControl>

                    <FormControl>
                      <StyledField
                        name={`ocrConnection.customVisionIteration`}
                        type="text"
                        placeholder="Custom Vision Iteration"
                        label="Custom Vision Project Iteration"
                      />
                      <ErrorMessage
                        name={`ocrConnection.customVisionIteration`}
                      />
                    </FormControl>
                  </div>
                )}
              </>

              <FormActions>
                <FormControl>
                  {errors ? <ErrorMessages errors={errors} /> : null}
                  {providersErrors ? (
                    <ErrorMessages errors={providersErrors} />
                  ) : null}

                  {/* TODO: Add Toasts */}
                  {data && <div>Updated!</div>}

                  <Button type="submit" disabled={loading}>
                    {loading ? <Spinner /> : "Save OCR Settings"}
                  </Button>
                </FormControl>
              </FormActions>
            </Form>
          );
        }}
      </Formik>
      <ToastContainer />
    </>
  );
};

export default OCRSettingsForm;
