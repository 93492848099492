import React from "react";
import styled from "styled-components/macro";
import { MdWarning } from "react-icons/md";

const SettingsContainerWithBorders = styled.div`
  border-top: 1px solid #cccccc;
  border-bottom: 1px solid #cccccc;
  margin-bottom: 1rem;
  margin-top: 0.5rem;
  padding: 0.5rem;
  display: block;
  width: 100%;
`;

const ModalLink = styled.button`
  text-decoration: none;
  display: flex;
  align-items: center;
  transition: all ${(props) => props.theme.transitionSpeed} linear;
  font-family: "Source Sans Pro Semibold";
  background: none;
  border: 0;
  padding: 0;
  color: ${(props) => props.theme.linkPrimary};
  &:hover {
    text-decoration: underline;
    color: ${(props) => props.theme.linkPrimary};
    cursor: pointer;
  }
  &:active {
    text-decoration: underline;
    color: ${(props) => props.theme.linkSecondary};
  }
`;

const ColumnWrapper = styled.div`
  border-radius: 4px;
  padding: 0.3rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  display: inline-block;
  border: 1px solid #ccc;
`;

const WarningIcon = styled.div`
  color: orange;
`;

const ReconciliationMappingSettings = React.memo(
  ({ matchingColumns, asOfDateColumns, setShowReconciliationSettings }) => {
    return (
      <SettingsContainerWithBorders>
        {matchingColumns.length === 0 || asOfDateColumns === 0 ? (
          <div style={{ display: "flex", width: "100%" }}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <WarningIcon>
                <MdWarning />
              </WarningIcon>{" "}
              You must map Primary keys and a "Most Recent Date" Column for
              Reconciliation
            </div>
            <div style={{ marginLeft: "auto" }}>
              <ModalLink onClick={() => setShowReconciliationSettings(true)}>
                Manage Reconciliation Settings
              </ModalLink>
            </div>
          </div>
        ) : (
          <div style={{ display: "flex", width: "100%" }}>
            <div style={{ flex: 1 }}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <div style={{ marginRight: ".5rem" }}>
                  <b>Primary Keys</b>
                </div>
                {matchingColumns.map((col) => {
                  return (
                    <ColumnWrapper style={{ marginRight: ".5rem" }}>
                      {col.name}
                    </ColumnWrapper>
                  );
                })}
              </div>
            </div>
            <div
              style={{
                marginLeft: "auto",
                textAlign: "right",
                display: "flex",
                alignItems: "center",
              }}
            >
              <div style={{ marginRight: ".5rem" }}>
                <b>"Most Recent Date" Column</b>
              </div>
              {asOfDateColumns.map((col) => {
                return <ColumnWrapper>{col.name}</ColumnWrapper>;
              })}
              <div style={{ marginLeft: "1rem" }}>
                <ModalLink onClick={() => setShowReconciliationSettings(true)}>
                  edit
                </ModalLink>
              </div>
            </div>
          </div>
        )}
      </SettingsContainerWithBorders>
    );
  }
);

export default ReconciliationMappingSettings;
