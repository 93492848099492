import React from "react";
import Card from "../../components/Card";
import { Route, NavLink } from "react-router-dom";
import styled from "styled-components/macro";
import IssuesList from "./IssuesList";

const Tabs = styled.div`
  border-bottom: 1px solid ${(props) => props.theme.surfaceAlt};
  background-color: ${(props) => props.theme.surface};
  position: relative;
  padding: 1.2rem;
`;

const TabLink = styled(NavLink)`
  padding: 1rem;
  border-bottom: 2px solid transparent;
  text-decoration: none;
  opacity: 0.5;
  color: ${(props) => props.theme.onSurface};
  &.active {
    opacity: 1;
    border-bottom: 2px solid ${(props) => props.theme.onSecondarySurface};
  }
  &:hover {
    opacity: 1;
  }
`;

const IssuesPage = () => {
  const Body = () => {
    return (
      <>
        <Tabs>
          <TabLink to="/issues">Issues</TabLink>
          {/* <TabLink to="/issues/stats">Statistics</TabLink> */}
        </Tabs>
        <Route exact path={`/issues`} component={IssuesList} />
      </>
    );
  };

  //Actions Section of Widget
  return <Card title={"Issues"} titleDescription={""} body={Body} />;
};

export default IssuesPage;
