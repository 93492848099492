import React from "react";
import DataSourceRulesFormWidget from "../../components/Widgets/DataSourceWidgets/DataSourceRulesFormWidget";

const RulesForm = (props) => {
  const { match, instances, triggerRefresh } = props;
  const isFilter =
    (match && match.path && match.path === "/policies/filters/create") ||
    (match && match.path && match.path === "/policies/filters/:ruleId/edit");
  const isEditing = match && match.params && match.params.ruleId;
  return (
    <DataSourceRulesFormWidget
      isFilter={isFilter}
      isEditing={isEditing}
      sourceInstances={instances}
      triggerRefresh={triggerRefresh}
    />
  );
};

export default RulesForm;
