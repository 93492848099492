import React, {
  useCallback,
  useState,
  useReducer,
  useEffect,
  useContext,
} from "react";
import Button from "../../components/Button";
import { FormControl } from "../../components/Form/FormControls";
import { StyledSelect, StyledInput } from "../../components/Form/FormControls";
import { MdExpandMore, MdExpandLess } from "react-icons/md";
import { FaDatabase } from "react-icons/fa";
import {
  getServicerTransferFeedById,
  servicerTransferDictionaryByCollectionIds,
} from "../../api/serviceTransferQueries";
import { useApi } from "../../api/useApi";
import { getUniqueValuesByColumnId } from "../../api/serviceTransferQueries";
import SortTable from "../../components/Table/SortTable";
import { createServicerTransferDictionary } from "../../api/serviceTransferMutations";
import Spinner from "../../components/Loaders/Spinner";
import ErrorMessages from "../../components/Notifications/ErrorMessages";
import SplashLoader from "../../components/Loaders/SplashLoader";
import { SocketContext } from "../../contexts/useSubscriptions";
import ExpandLink from "../../components/Button/ExpandLink";
import SortExpandTable from "../../components/Table/SortExpandTable";
import { ToastContainer, toast } from "react-toastify";

const ColumnUniques = ({
  column,
  inputColumn,
  cb,
  breakerState,
  breakerDispatch,
}) => {
  const [{ loading }] = useApi(getUniqueValuesByColumnId, {
    id: column?.id,
  });

  const [{ loading: inputColumnLoading }] = useApi(getUniqueValuesByColumnId, {
    id: inputColumn?.id,
  });

  const { retrieveUVCNotification, setRetrieveUVCNotification } =
    useContext(SocketContext);

  // const uniqueValues = apiData?.uniqueValuesByColumnId?.uniqueValues ?? [];

  // const inputColumnUniqueValues =
  //   inputColumnData?.uniqueValuesByColumnId?.uniqueValues ?? [];

  const [fetchingValues, setFetchingValues] = useState(true);
  const [fetchingValuesErrors, setFetchingValuesErrors] = useState();
  const [uniqueValues, setUniqueValues] = useState([]);
  const [inputColumnUniqueValues, setInputColumnUniqueValuess] = useState([]);

  //Seller Column

  useEffect(() => {
    const payload = retrieveUVCNotification?.payload;

    if (payload?.AlertType === 31) {
      //succeeded
      if (payload?.ColumnId === column?.id) {
        if (payload?.Values) {
          setUniqueValues(
            payload?.Values.map((v) => {
              return {
                // DEV: not found in update
                // collection: v.Collection,
                collectionId: v.CollectionId,
                description: v.Description,
                id: v.Id,
                // DEV: not found in update
                //keyMapping: v.keyMapping,
                value: v.Value,
                // DEV: not found in update
                // valueMappings: v.ValueMappings,
              };
            }).sort((a, b) => a.id - b.id) ?? []
          );
          setFetchingValues(false);
          setRetrieveUVCNotification(null);
        } else if (payload.ErrorMessage) {
          setFetchingValuesErrors([{ message: payload.ErrorMessage }]);
          setFetchingValues(false);
          setRetrieveUVCNotification(null);
        }
      } else if (payload?.ColumnId === inputColumn?.id) {
        if (payload?.Values) {
          setInputColumnUniqueValuess(
            payload?.Values.map((v) => {
              return {
                // DEV: not found in update
                // collection: v.Collection,
                collectionId: v.CollectionId,
                description: v.Description,
                id: v.Id,
                // DEV: not found in update
                //keyMapping: v.keyMapping,
                value: v.Value,
                // DEV: not found in update
                // valueMappings: v.ValueMappings,
              };
            }).sort((a, b) => a.id - b.id) ?? []
          );
          setFetchingValues(false);
          setRetrieveUVCNotification(null);
        } else if (payload.ErrorMessage) {
          setFetchingValuesErrors([{ message: payload.ErrorMessage }]);
          setFetchingValues(false);
          setRetrieveUVCNotification(null);
        }
      }
    }
  }, [
    retrieveUVCNotification,
    setRetrieveUVCNotification,
    inputColumn?.id,
    column?.id,
  ]);

  const inputColumnOptions = inputColumnUniqueValues.map((icuv) => {
    return {
      ...icuv,
      label: icuv?.value,
      value: {
        collectionId: icuv?.collectionId,
        description: icuv?.friendlyId,
        id: icuv?.id,
        value: icuv?.value,
      },
    };
  });

  const columnsData = [
    {
      Header: `Mapped Input Values from ${inputColumn?.name}`,
      id: "fn",
      Cell: ({ row: { original } }) => {
        const value = {
          collectionId: original?.collectionId,
          description: original?.friendlyId,
          id: original?.id,
          value: original?.value,
        };
        const foundValues = breakerState?.dictionary?.entries ?? [];

        let actualValues = [];

        const foundFilters = foundValues.filter(
          (ce) => ce?.value?.id === value?.id
        );

        foundFilters.forEach((ff) => {
          const findOption = inputColumnOptions.find((o) => o.id === ff.key.id);

          if (findOption) {
            actualValues.push(findOption);
          }
        });
        if (fetchingValues)
          return <SplashLoader text={"Loading Unique Values"} />;
        if (loading || inputColumnLoading)
          return <SplashLoader text={"Loading Values"} />;

        const modifiedOptions =
          inputColumnOptions.map((ico) => {
            return {
              ...ico,
              isDisabled: foundValues.find((av) => av.key.id === ico.id)
                ? true
                : false,
            };
          }) ?? [];

        return (
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <StyledSelect
              className={`react-select-container`}
              classNamePrefix={`react-select`}
              name={`${original?.id}-breakerValues`}
              id={`${original?.id}-breakerValues`}
              inputId={`${original?.id}-breakerValues-input`}
              instanceId={`${original?.id}-breakerValues-instance`}
              label="Naming Convention"
              options={modifiedOptions.sort(
                (a, b) => a?.isDisabled - b?.isDisabled
              )}
              isMulti
              placeholder={`Select values to map`}
              value={actualValues}
              menuPortalTarget={document.body}
              menuPlacement="auto"
              onChange={(e) =>
                breakerDispatch({
                  type: "UPDATE_ENTRIES",
                  payload: {
                    keyCollectionId: inputColumn?.uniqueValueCollectionId,
                    valueCollectionId: column?.uniqueValueCollectionId,
                    keys: e,
                    value: value,
                  },
                })
              }
            />

            <div style={{ marginLeft: "auto" }}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "row",
                  minWidth: "50px",
                }}
              >
                <div
                  style={{
                    flex: 1,
                    height: "1px",
                    backgroundColor: "#ccc",
                  }}
                ></div>
                <div
                  style={{
                    flex: 0,
                    width: 0,
                    height: 0,
                    borderTop: "5px solid transparent",
                    borderBottom: "5px solid transparent",
                    borderLeft: "10px solid #ccc",
                  }}
                ></div>
              </div>
            </div>
          </div>
        );
      },
    },
    {
      Header: "Output Value",
      id: "description",
      accessor: (d) => d.description,
      Cell: ({ row: { original } }) => {
        return (
          <div>
            <h4>{original?.description}</h4>
            {original?.value}
          </div>
        );
      },
    },
  ];

  return (
    <>
      {fetchingValuesErrors ? (
        <ErrorMessages errors={fetchingValuesErrors} />
      ) : null}{" "}
      <SortTable data={uniqueValues} columns={columnsData} />
    </>
  );
};

const CodeBreakdown = ({ props, cb }) => {
  const columnArguments = [...cb?.transformerArguments];

  const lastIndex = cb?.transformerArguments?.length - 1;
  //Buyer Column
  const outputColumn = cb?.transformerArguments[lastIndex];

  // remove the last item from the column
  columnArguments.pop();

  return (
    <>
      {columnArguments.map((arg, i) => {
        return (
          <>
            <CodeBreak inputColumn={arg} outputColumn={outputColumn} cb={cb} />
            {columnArguments?.length > 1 && <hr />}
          </>
        );
      })}
    </>
  );
};

const CodeBreak = ({ cb, inputColumn, outputColumn }) => {
  const [
    {
      loading: dictionaryLoading,
      errors: dictionaryErrors,
      data: dictionaryData,
    },
    createDictionary,
  ] = useApi();

  useEffect(() => {
    if (dictionaryData) {
      toast.info("Code Break Saved", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }, [dictionaryData]);

  const reducer = (state, action) => {
    switch (action.type) {
      //First State called when a query is passed to the component.
      default:
        return {
          ...state,
        };

      // UPDATE Name
      case "UPDATE_NAME": {
        let clonePrev = { ...state?.dictionary };

        //filter out current keys

        let currentEntries = clonePrev?.entries ?? [];

        clonePrev = {
          id: clonePrev?.id,
          name: action?.payload?.name,
          keyCollectionId: action?.payload?.keyCollectionId,
          valueCollectionId: action?.payload?.valueCollectionId,
          entries: [...currentEntries],
        };

        return {
          ...state,
          dictionary: clonePrev,
        };
      }

      // UPDATE State
      case "SET_INIT": {
        return {
          ...state,
          dictionary: action?.payload,
        };
      }
      // UPDATE State
      case "UPDATE_ENTRIES": {
        let clonePrev = { ...state?.dictionary };

        //filter out current keys
        let currentEntries = clonePrev?.entries ?? [];

        currentEntries = currentEntries.filter(
          (ce) => ce?.value?.id !== action?.payload?.value?.id
        );

        const updatedEntries =
          action?.payload?.keys.map((k) => {
            return {
              key: {
                collectionId: k?.value?.collectionId,
                description: k?.value?.friendlyId,
                id: k?.value?.id,
                value: k?.value?.value,
              },
              value: {
                collectionId: action?.payload?.value?.collectionId,
                description: action?.payload?.value?.friendlyId,
                id: action?.payload?.value?.id,
                value: action?.payload?.value?.value,
              },
            };
          }) ?? [];

        clonePrev = {
          id: clonePrev?.id,
          name: clonePrev?.name,
          keyCollectionId: action?.payload?.keyCollectionId,
          valueCollectionId: action?.payload?.valueCollectionId,
          entries: [...currentEntries, ...updatedEntries],
        };

        return {
          ...state,
          dictionary: clonePrev,
        };
      }
    }
  };

  const initialState = {
    dictionary: {
      keyCollectionId: inputColumn?.column?.uniqueValueCollectionId, //Seller Value
      valueCollectionId: outputColumn?.column?.uniqueValueCollectionId, //Output/buyer value
      entries: [],
      name: "",
    },
  };

  const [breakerState, breakerDispatch] = useReducer(reducer, initialState);

  const dictionary = breakerState?.dictionary;

  const createDictionaryCall = useCallback(() => {
    createDictionary({
      query: createServicerTransferDictionary,
      variables: {
        dictionary: breakerState.dictionary,
      },
    });
  }, [createDictionary, breakerState]);

  const [{ loading, errors, data }] = useApi(
    servicerTransferDictionaryByCollectionIds,
    {
      valueCollectionId: outputColumn?.column?.uniqueValueCollectionId,
      keyCollectionId: inputColumn?.column?.uniqueValueCollectionId,
    }
  );

  useEffect(() => {
    const dictionaryData =
      data?.servicerTransferDictionaryByCollectionIds?.entries ?? [];
    const dictionaryName =
      data?.servicerTransferDictionaryByCollectionIds?.name;
    const dictionaryId = data?.servicerTransferDictionaryByCollectionIds?.id;
    const arrayUniqueByKey = [
      ...new Map(
        dictionaryData.map((item) => [item["value"].id, item.value])
      ).values(),
    ];

    let keyValues = [];
    arrayUniqueByKey.forEach((aubk) => {
      const findaubk = dictionaryData.filter((dd) => dd.value.id === aubk?.id);
      keyValues.push(...findaubk);
    });

    breakerDispatch({
      type: "SET_INIT",
      payload: {
        valueCollectionId: outputColumn?.column?.uniqueValueCollectionId,
        keyCollectionId: inputColumn?.column?.uniqueValueCollectionId,
        entries: keyValues,
        name: dictionaryName,
        id: dictionaryId,
      },
    });
  }, [
    data?.servicerTransferDictionaryByCollectionIds?.entries,
    breakerDispatch,
    outputColumn?.column?.uniqueValueCollectionId,
    inputColumn?.column?.uniqueValueCollectionId,
    data?.servicerTransferDictionaryByCollectionIds?.name,
    data?.servicerTransferDictionaryByCollectionIds?.id,
  ]);

  if (loading) return <SplashLoader text="Loading Code Break" />;

  const outputColumnSourceName =
    outputColumn?.column?.fullyQualifiedName?.split(/\|/) ?? "";
  const inputColumnColumnSourceName =
    inputColumn?.column?.fullyQualifiedName?.split(/\|/) ?? "";

  //Validate Each Column is Code Broken
  if (
    outputColumn?.column?.uniqueValueCollectionId &&
    inputColumn?.column?.uniqueValueCollectionId
  ) {
    return (
      <div style={{ marginTop: "1rem" }}>
        {dictionaryErrors?.length ? (
          <ErrorMessages errors={dictionaryErrors} />
        ) : null}
        {errors ? <ErrorMessages errors={errors} /> : null}
        <h4>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "1rem",
            }}
          >
            <div style={{ marginRight: "1rem" }}>
              <div> {inputColumn?.column?.name}</div>

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  opacity: ".4",
                }}
              >
                <FaDatabase style={{ marginRight: ".2rem" }} />{" "}
                {inputColumnColumnSourceName?.[0]}
              </div>
            </div>
            <div>
              <div style={{ marginLeft: "auto" }}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "row",
                    minWidth: "50px",
                  }}
                >
                  <div
                    style={{
                      flex: 1,
                      height: "1px",
                      backgroundColor: "#ccc",
                    }}
                  ></div>
                  <div
                    style={{
                      flex: 0,
                      width: 0,
                      height: 0,
                      borderTop: "5px solid transparent",
                      borderBottom: "5px solid transparent",
                      borderLeft: "10px solid #ccc",
                    }}
                  ></div>
                </div>
              </div>
            </div>
            <div style={{ marginLeft: "1rem" }}>
              <div>{outputColumn?.column?.name}</div>

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  opacity: ".4",
                }}
              >
                <FaDatabase style={{ marginRight: ".2rem" }} />{" "}
                {outputColumnSourceName?.[0]}
              </div>
            </div>
          </div>
        </h4>

        <FormControl>
          <StyledInput
            type="text"
            name="name"
            label="Code Break Name"
            required={true}
            value={dictionary?.name}
            placeholder={`Code Break Name`}
            onChange={(e) =>
              breakerDispatch({
                type: "UPDATE_NAME",
                payload: {
                  valueCollectionId:
                    outputColumn?.column?.uniqueValueCollectionId,
                  keyCollectionId: inputColumn?.column?.uniqueValueCollectionId,
                  name: e.target.value,
                },
              })
            }
          />
        </FormControl>
        <ColumnUniques
          cb={cb}
          isOutput={true}
          column={outputColumn?.column}
          inputColumn={inputColumn?.column}
          breakerState={breakerState}
          breakerDispatch={breakerDispatch}
        />
        <div style={{ display: "flex" }}>
          <div style={{ marginLeft: "auto" }}>
            <Button onClick={() => createDictionaryCall()}>
              Save Code Break {dictionaryLoading ? <Spinner /> : null}
            </Button>
          </div>
        </div>
        <ToastContainer />
      </div>
    );
  } else {
    //TODO: Determine which is missing

    return (
      <div>
        <h4>
          {inputColumn?.column?.name} {" -> "} {outputColumn?.column?.name}
        </h4>
        <div>
          Columns do not have unique Values selected, please go to the inputs,
          and output to validate. If this is intentional for Coalesce you can
          ignore this warning.
        </div>
      </div>
    );
  }
};

const ColumnConfirmations = ({ state, dispatch }) => {
  const feedId = state?.feedId;

  // const ingressSources = [...state?.ingestSources];

  // const [confirmedSources, setConfirmedSources] = useState(0);

  // const feed = state?.feed;

  const [{ loading, errors, data: apiData }] = useApi(
    getServicerTransferFeedById,
    {
      id: feedId,
    }
  );

  const feedData = apiData?.servicerTransferFeedById;

  const transformers = feedData?.transformers ?? [];

  let codebreaks = [];
  transformers.forEach((t) => {
    if (
      t.transformationType === "DICTIONARY" ||
      t.transformationType === "COALESCE_DICTIONARY" ||
      t.transformationType === "DICTIONARY_PASS_THROUGH"
    ) {
      codebreaks.push(t);
    }
  });

  useEffect(() => {
    if (feedData) {
      dispatch({
        type: "SET_FEED",
        payload: feedData,
      });
    }
  }, [feedData, dispatch]);

  //Build up dictionaries from Mapped Columns that include at least one code-breaker column

  // const editEntry = useCallback(
  //   (dictionaryIndex, entryIndex, value, entryType) => {
  //     breakerDispatch({
  //       type: "EDIT_ENTRY",
  //       payload: {
  //         entryIndex: entryIndex,
  //         dictionaryIndex: dictionaryIndex,
  //         value: value,
  //         entryType: entryType,
  //       },
  //     });
  //   },
  //   [breakerDispatch]
  // );

  const columnsData = [
    {
      Header: "Code Breakers",
      columns: [
        {
          Header: "Input",
          id: "InputColumns",
          // The cell can use the individual row's getToggleRowSelectedProps method
          // to the render a checkbox
          Cell: ({ row }) => {
            const columnArguments = [...row?.original?.transformerArguments];

            // remove the last item from the column
            columnArguments.pop();

            return (
              <>
                <div style={{ display: "flex" }}>
                  <div {...row.getToggleRowExpandedProps()}>
                    {row.isExpanded ? (
                      <ExpandLink title={row.original.failedRuleInstanceName}>
                        <div>
                          <MdExpandLess />
                        </div>
                        <div>
                          {columnArguments.map((inputColumn, i) => {
                            const fullName =
                              inputColumn?.column?.fullyQualifiedName;
                            const sourceColumnName =
                              fullName?.split(/\|/) ?? "";

                            return (
                              <>
                                [{inputColumn?.column?.name} |{" "}
                                {sourceColumnName?.[0]}]
                              </>
                            );
                          })}
                        </div>
                      </ExpandLink>
                    ) : (
                      <ExpandLink title={row.original.failedRuleInstanceName}>
                        <div>
                          <MdExpandMore />
                        </div>
                        <div>
                          {columnArguments.map((inputColumn, i) => {
                            const fullName =
                              inputColumn?.column?.fullyQualifiedName;
                            const sourceColumnName =
                              fullName?.split(/\|/) ?? "";

                            return (
                              <>
                                [{inputColumn?.column?.name} |{" "}
                                {sourceColumnName?.[0]}]
                              </>
                            );
                          })}
                        </div>
                      </ExpandLink>
                    )}
                  </div>
                  <div style={{ marginLeft: "auto" }}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "row",
                        minWidth: "50px",
                      }}
                    >
                      <div
                        style={{
                          flex: 1,
                          height: "1px",
                          backgroundColor: "#ccc",
                        }}
                      ></div>
                      <div
                        style={{
                          flex: 0,
                          width: 0,
                          height: 0,
                          borderTop: "5px solid transparent",
                          borderBottom: "5px solid transparent",
                          borderLeft: "10px solid #ccc",
                        }}
                      ></div>
                    </div>
                  </div>
                </div>
              </>
            );
          },
        },
        {
          Header: "Output",
          accessor: "lastName",
          Cell: ({ row: { original } }) => {
            const columnArguments = [...original?.transformerArguments];
            const argumentsLength = original?.transformerArguments?.length;
            const last = columnArguments[argumentsLength - 1];

            // remove the last item from the column
            columnArguments.pop();
            const fullName = last?.column?.fullyQualifiedName;
            const sourceColumnName = fullName?.split(/\|/) ?? "";

            return (
              <>
                [{last?.column?.name} | {sourceColumnName?.[0]}]
              </>
            );
          },
        },
      ],
    },
  ];

  if (loading) return <SplashLoader text={"Loading Code Break"} />;
  return (
    <>
      {/* <SortExpandTableV2
        // dontReset={true}
        data={selectedValues}
        columns={columnsData}
        defaultPageSize={50}
        defaultSort={[]}
        SubRowComponent={(props) => {
          const cb = props?.row?.original;

          return (
            <CodeBreak
            props={props}
            cb={cb}
            breakerState={breakerState}
            breakerDispatch={breakerDispatch}
          />
          );
        }}
      /> */}
      <h3>Code Breaking</h3>
      <h4>
        Expand the rows below to manage and save each code breaker dictionary
      </h4>
      {errors ? <ErrorMessages errors={errors} /> : null}
      <SortExpandTable
        loading={loading}
        data={codebreaks}
        columns={columnsData}
        defaultPageSize={50}
        defaultSort={[]}
        SubRowComponent={(props) => (
          <CodeBreakdown props={props} cb={props?.row?.original} />
        )}
      />

      <Button list onClick={() => dispatch({ type: "GO_BACK", payload: 10 })}>
        back
      </Button>
      <Button onClick={() => dispatch({ type: "FINISH" })}>View Summary</Button>

      {/* {confirmedSources >= ingressSources?.length - 1 ? (
        <Button onClick={() => dispatch({ type: "FINISH" })}>Finish</Button>
      ) : (
        <Button
          onClick={() => {
            setConfirmedSources((prev) => prev + 1);
          }}
        >
          Next
        </Button> */}
      {/* )} */}
    </>
  );
};

export default ColumnConfirmations;
