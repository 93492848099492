import React, { useEffect, useState } from "react";
import { useApi } from "../api/useApi";
import { availableOrganizations } from "../api/organizationQueries";

const useAllOrgs = () => {
  const [stateOrgs, setStateOrgs] = useState([]);
  const [totalCount, setTotalCount] = useState();

  //Available Users Via Graph
  const [{ data: groupData }, getGroups] = useApi();

  //Fetch for Table Paged
  const fetchGroups = React.useCallback(
    ({ cursor }) => {
      getGroups({
        query: availableOrganizations,
        variables: {
          first: 10,
          after: cursor ?? null,
        },
      });
    },
    [getGroups]
  );

  //Effect for User Count and Users Update
  useEffect(() => {
    if (groupData) {
      const totalCount = groupData?.availableOrganizations?.totalCount ?? 0;
      setTotalCount(totalCount);
      if (totalCount && groupData?.availableOrganizations?.edges?.length) {
        setStateOrgs((prevState) => [
          ...prevState,
          ...groupData.availableOrganizations.edges,
        ]);
      }
    }
  }, [groupData]);

  //Effect for User Count and Users Update
  useEffect(() => {
    if (
      (totalCount && (totalCount > stateOrgs.length ?? 0)) ||
      !stateOrgs?.length
    ) {
      fetchGroups({
        cursor: stateOrgs?.length ? stateOrgs.slice(-1).pop().cursor : null,
      });
    }
  }, [fetchGroups, stateOrgs, totalCount]);

  const groupOptions = stateOrgs.map((org) => {
    return {
      label: org?.node?.displayName,
      value: org?.node?.id,
      type: "org",
    };
  });

  return [{ stateOrgs: groupOptions }];
};

export default useAllOrgs;
