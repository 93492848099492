import update from "immutability-helper";
import { buildingBlocks } from "./buildingBlocks";

const RulesReducer = (state, action) => {
  switch (action.type) {
    //First State called when a query is passed to the component.
    default:
      return {
        ...state,
      };

    // SetLoad
    case "SET_SAVED_LOADING": {
      return {
        ...state,
        loading: true,
      };
    }

    case "DONE_LOADING": {
      return {
        ...state,
        errors: null,
        loading: false,
      };
    }

    // IMPORT
    case "IMPORT_STANDARD": {
      const standard = action.payload?.standard;
      const standardName = action.payload?.name;
      const standardDescription = action.payload?.description;
      return {
        ...state,
        standard: standard,
        name: standardName,
        description: standardDescription,
      };
    }

    // ENABLE EDIT
    case "SET_EDIT_ELEMENT": {
      const index = action.payload.index;
      return {
        ...state,
        editStandardElement: { element: action.payload.element, index },
      };
    }

    //Source Reducer Updates
    // Fragment Updating
    case "UPDATE_SOURCE_TYPE": {
      const index = action.payload.index;
      return {
        ...state,
        standard: update(state.standard, {
          [index]: {
            source: {
              typeInformation: {
                1: { typeValue: { $set: action.payload.value } },
              },
            },
          },
        }),
      };
    }

    case "UPDATE_MODIFIER_TARGET": {
      const index = action.payload.index;

      const currentSource = state.standard[index].target;
      const currentSourceWithoutModifier =
        currentSource?.typeInformation.filter(
          (ti) => ti?.typeHierarchyLevel !== 3
        );

      const fragmentValue = action?.payload?.fragmentValue ?? null;

      const clean = {
        typeInformation: {
          $set: [
            ...currentSourceWithoutModifier,
            {
              typeHierarchyLevel: 3,
              typeValue: action.payload.value,
              fragmentValue: fragmentValue,
            },
          ],
        },
      };

      return {
        ...state,
        standard: update(state.standard, {
          [index]: {
            target: { ...clean },
          },
        }),
      };
    }

    case "REMOVE_MODIFIER_TARGET": {
      const index = action.payload.index;

      const currentSource = state.standard[index].target;
      const currentSourceWithoutModifier =
        currentSource?.typeInformation.filter(
          (ti) => ti?.typeHierarchyLevel !== 3
        );

      const clean = {
        typeInformation: {
          $set: [...currentSourceWithoutModifier],
        },
      };

      return {
        ...state,
        standard: update(state.standard, {
          [index]: {
            target: { ...clean },
          },
        }),
      };
    }

    case "UPDATE_MODIFIER_SOURCE": {
      const index = action.payload.index;

      const currentSource = state.standard[index].source;
      const currentSourceWithoutModifier =
        currentSource?.typeInformation.filter(
          (ti) => ti?.typeHierarchyLevel !== 2
        );
      const fragmentValue = action?.payload?.fragmentValue ?? null;
      const clean = {
        typeInformation: {
          $set: [
            ...currentSourceWithoutModifier,
            {
              typeHierarchyLevel: 2,
              typeValue: action.payload.value,
              fragmentValue: fragmentValue,
            },
          ],
        },
      };
      // If its a count, default the operation to less than else do the do
      if (index === 0 && action?.payload?.value?.value === 3) {
        return {
          ...state,
          standard: update(state.standard, {
            [index]: {
              source: { ...clean },
              operation: {
                // clean the operation of any modifiers and set less than
                typeInformation: {
                  $set: [
                    {
                      typeHierarchyLevel: 0,
                      fragmentValue: null,
                      typeValue: 3,
                    },
                    {
                      typeHierarchyLevel: 1,
                      typeValue: { label: "<", value: 4 },
                      fragmentValue: null,
                    },
                  ],
                },
              },
            },
          }),
        };
      } else {
        return {
          ...state,
          standard: update(state.standard, {
            [index]: {
              source: { ...clean },
            },
          }),
        };
      }
    }

    case "REMOVE_MODIFIER_SOURCE": {
      const index = action.payload.index;

      const currentSource = state.standard[index].source;
      const currentSourceWithoutModifier =
        currentSource?.typeInformation.filter(
          (ti) => ti?.typeHierarchyLevel !== 2
        );

      const clean = {
        typeInformation: {
          $set: [...currentSourceWithoutModifier],
        },
      };

      return {
        ...state,
        standard: update(state.standard, {
          [index]: {
            source: { ...clean },
          },
        }),
      };
    }

    case "UPDATE_SOURCE_NAME": {
      const index = action.payload.index;
      return {
        ...state,
        standard: update(state.standard, {
          [index]: {
            source: {
              typeInformation: {
                1: { fragmentValue: { $set: action.payload.value } },
              },
            },
          },
        }),
      };
    }

    //Operation Reducer Updates
    case "UPDATE_OPERATION_TYPE": {
      const index = action.payload.index;
      const currentOperation = state.standard[index].operation;
      const hasTarget = state.standard[index].target;

      if (action.payload.value.value === 14) {
        return {
          ...state,
          standard: update(state.standard, {
            [index]: {
              operation: {
                typeInformation: {
                  1: { typeValue: { $set: action.payload.value } },
                },
              },
              target: { $set: null },
            },
          }),
        };
      } else if (action.payload.value.value === 15) {
        return {
          ...state,
          standard: update(state.standard, {
            [index]: {
              operation: {
                typeInformation: {
                  1: { typeValue: { $set: action.payload.value } },
                },
              },
              target: { $set: null },
            },
          }),
        };
      } else if (action.payload.value.value === 13) {
        return {
          ...state,
          standard: update(state.standard, {
            [index]: {
              operation: {
                typeInformation: {
                  1: { typeValue: { $set: action.payload.value } },
                },
              },
              target: { $set: buildingBlocks.Fragment[0].target },
            },
          }),
        };
      } else if (action.payload.value.value === 6) {
        return {
          ...state,
          standard: update(state.standard, {
            [index]: {
              operation: {
                typeInformation: {
                  1: { typeValue: { $set: action.payload.value } },
                },
              },
              target: { $set: null },
            },
          }),
        };
      } else if (currentOperation.typeInformation[1].typeValue.value === 6) {
        return {
          ...state,
          standard: update(state.standard, {
            [index]: {
              operation: {
                typeInformation: {
                  1: { typeValue: { $set: action.payload.value } },
                },
              },
              target: { $set: buildingBlocks.Fragment[0].target },
            },
          }),
        };
      } else if (
        action.payload.value.value === 16 ||
        action.payload.value.value === 17
      ) {
        const clean = {
          typeInformation: {
            $set: [
              { ...currentOperation.typeInformation[0] },
              {
                ...currentOperation.typeInformation[1],
                typeValue: action.payload.value,
              },
            ],
          },
        };

        return {
          ...state,
          standard: update(state.standard, {
            [index]: {
              operation: {
                ...clean,
              },
              target: {
                $set: {
                  typeInformation: [
                    {
                      typeHierarchyLevel: 0,
                      typeValue: 5,
                      fragmentValue: null,
                    },
                    {
                      typeHierarchyLevel: 1,
                      typeValue: { label: "Column", value: 1 },
                      fragmentValue: null,
                    },
                    {
                      typeHierarchyLevel: 2,
                      //Variable type would be 3, enum would be 0 for none still
                      typeValue: { label: "None", value: 0 },
                      //context for type (target), value, name
                      fragmentValue: "0",
                    },
                  ],
                },
              },
            },
          }),
        };
      } else if (!hasTarget) {
        return {
          ...state,
          standard: update(state.standard, {
            [index]: {
              operation: {
                typeInformation: {
                  1: { typeValue: { $set: action.payload.value } },
                },
              },
              target: { $set: buildingBlocks.Fragment[0].target },
            },
          }),
        };
      } else {
        return {
          ...state,
          standard: update(state.standard, {
            [index]: {
              operation: {
                typeInformation: {
                  1: { typeValue: { $set: action.payload.value } },
                },
              },
            },
          }),
        };
      }
    }

    case "UPDATE_MODIFIED_VARIABLE": {
      const { index, modifierType, node, value, name } = action.payload;

      const isAmount = name === "ByAnAmount";
      const isPercentage = name === "ByAPercentage";

      let isModified;
      let addTarget;
      if (value === false) {
        const modifierIdxToRemove =
          isAmount || isPercentage
            ? 2
            : node.operation.typeInformation.findIndex(
                (ti) => ti.typeHierarchyLevel === modifierType + 1
              );

        isModified = {
          $splice: [[modifierIdxToRemove, 1]],
        };
        addTarget = false;
      } else {
        if (isAmount || isPercentage) {
          const rebuildTypeInfo = [
            node.operation.typeInformation[0],
            node.operation.typeInformation[1],
          ];

          isModified = {
            $set: [
              ...rebuildTypeInfo,
              {
                typeHierarchyLevel: modifierType + 1,
                typeValue: modifierType,
                fragmentValue: null,
              },
            ],
          };
          addTarget = true;
        } else {
          isModified = {
            $set: [
              ...node.operation.typeInformation,
              {
                typeHierarchyLevel: modifierType + 1,
                typeValue: modifierType,
                fragmentValue: null,
              },
            ],
          };
          addTarget = false;
        }
      }
      addTarget = addTarget
        ? {
            $set: node?.target?.typeInformation[2]
              ? node.target
              : buildingBlocks.Fragment[0].target,
          }
        : { $set: null };

      if (isAmount || isPercentage) {
        return {
          ...state,
          standard: update(state.standard, {
            [index]: {
              operation: {
                typeInformation: isModified,
              },
              target: addTarget,
            },
          }),
        };
      } else {
        return {
          ...state,
          standard: update(state.standard, {
            [index]: {
              operation: {
                typeInformation: isModified,
              },
            },
          }),
        };
      }
    }

    //Update Target

    case "UPDATE_TARGET_TYPE": {
      const index = action.payload.index;

      //check if this is a variable type
      //if so we set the default fragment value for the render
      const isVariable = action.payload.value.value === 3;

      const currentSource = state.standard[index].target;
      const currentSourceWithoutModifier =
        currentSource?.typeInformation.filter(
          (ti) => ti?.typeHierarchyLevel !== 3
        );

      const clean = [...currentSourceWithoutModifier];

      const updateFirstInClean = clean.map((ct) => {
        if (ct?.typeHierarchyLevel === 1) {
          return {
            typeHierarchyLevel: ct?.typeHierarchyLevel,
            typeValue: action.payload.value,
            fragmentValue: isVariable
              ? { label: "Current Date", value: 1 }
              : null,
          };
        } else {
          return ct;
        }
      });

      const updatedTarget = {
        typeInformation: {
          $set: [...updateFirstInClean],
        },
      };

      return {
        ...state,
        standard: update(state.standard, {
          [index]: {
            target: {
              ...updatedTarget,
              testInfo: { $set: null },
            },
          },
        }),
      };
    }

    case "UPDATE_RECONCILIATION_TYPE": {
      return {
        ...state,
        standard: update(state.standard, {
          [action.payload.index]: {
            target: {
              typeInformation: {
                1: {
                  typeValue: { $set: action.payload.value },
                },
              },
            },
          },
        }),
      };
    }

    case "UPDATE_TARGET_NAME": {
      const index = action.payload.index;
      return {
        ...state,
        standard: update(state.standard, {
          [index]: {
            target: {
              typeInformation: {
                1: { fragmentValue: { $set: action.payload.value } },
              },
            },
          },
        }),
      };
    }

    case "UPDATE_TARGET_VARIABLE_TYPE": {
      const index = action.payload.index;
      return {
        ...state,
        standard: update(state.standard, {
          [index]: {
            target: {
              typeInformation: {
                1: { fragmentValue: { $set: action.payload.value } },
              },
            },
          },
        }),
      };
    }

    case "UPDATE_TARGET_MODIFIED_TYPE": {
      const index = action.payload.index;
      return {
        ...state,
        standard: update(state.standard, {
          [index]: {
            target: {
              typeInformation: {
                2: {
                  typeValue: { $set: action.payload.value },
                  fragmentValue: { $set: "" },
                },
              },
            },
          },
        }),
      };
    }
    case "UPDATE_TARGET_MODIFIED_VALUE": {
      const index = action.payload.index;
      return {
        ...state,
        standard: update(state.standard, {
          [index]: {
            target: {
              typeInformation: {
                2: { fragmentValue: { $set: action.payload.value } },
              },
            },
          },
        }),
      };
    }

    case "UPDATE_TARGET_MODIFIER_VALUE": {
      const index = action.payload.index;
      return {
        ...state,
        standard: update(state.standard, {
          [index]: {
            target: {
              typeInformation: {
                3: { fragmentValue: { $set: action.payload.value } },
              },
            },
          },
        }),
      };
    }

    case "UPDATE_SOURCE_MODIFIER_VALUE": {
      const index = action.payload.index;
      return {
        ...state,
        standard: update(state.standard, {
          [index]: {
            source: {
              typeInformation: {
                2: { fragmentValue: { $set: action.payload.value } },
              },
            },
          },
        }),
      };
    }

    // Add Condition
    case "ADD_CONDITION": {
      return {
        ...state,
        standard: update(state.standard, {
          $push: action.payload,
        }),
      };
    }
    case "UPDATE_NAME": {
      return {
        ...state,
        name: action.payload,
      };
    }
    case "UPDATE_DESCRIPTION": {
      return {
        ...state,
        description: action.payload,
      };
    }
    case "SET_PERMISSIONS": {
      return {
        ...state,
        permissions: action.payload,
      };
    }
    case "SET_TAGS": {
      return {
        ...state,
        tagInstances: action.payload,
      };
    }
    // Clear Form
    case "CLEAR_FORM": {
      return {
        ...state,
        standard: update(state.standard, {
          $set: [],
        }),
      };
    }

    // Remove Element
    case "REMOVE_ELEMENT": {
      return {
        ...state,
        standard: update(state.standard, {
          $splice: [[action.payload.index, 1]],
        }),
      };
    }

    // Attach Before
    case "ATTACH_BEFORE": {
      return {
        ...state,
        standard: update(state.standard, {
          $splice: [[action.payload.index, 0, action.payload.attachment]],
        }),
      };
    }
    // Attach Before
    case "ATTACH_AFTER": {
      if (action.payload.attachment.name === ")") {
        return {
          ...state,
          standard: update(state.standard, {
            $splice: [[action.payload.index + 1, 0, action.payload.attachment]],
          }),
        };
      } else {
        return {
          ...state,
          standard: update(state.standard, {
            $splice: [
              [action.payload.index + 1, 0, action.payload.attachment],
              // [action.payload.index + 2, 0, buildingBlocks.Fragment[0]]
            ],
          }),
        };
      }
    }

    // Toggle Edit View
    case "TOGGLE_EDIT": {
      return {
        ...state,
        editView: update(state.editView, {
          $set: !state.editView,
        }),
      };
    }

    // Toggle Edit View
    case "UPDATE_TEST_ROW": {
      try {
        JSON.parse(action.payload);

        const isArray = Array.isArray(JSON.parse(action.payload));
        if (!isArray) {
          throw "";
        }

        return {
          ...state,
          testRow: update(state.testRow, {
            $set: action.payload,
          }),
          goodTestParse: { status: true },
        };
      } catch (e) {
        return {
          ...state,
          testRow: update(state.testRow, {
            $set: action.payload,
          }),
          goodTestParse: {
            status: false,
            message:
              "Be sure to include [ and ] brackets around your list of values. Match the example above.",
          },
        };
      }
    }

    case "UPDATE_TEST_VALUE": {
      const index = action.payload.index;
      return {
        ...state,
        standard: update(state.standard, {
          [index]: {
            source: {
              testInfo: { $set: action.payload.value },
            },
          },
        }),
      };
    }

    case "UPDATE_TEST_VALUE_TARGET": {
      const index = action.payload.index;
      return {
        ...state,
        standard: update(state.standard, {
          [index]: {
            target: {
              testInfo: { $set: action.payload.value },
            },
          },
        }),
      };
    }

    // Toggle Testing
    case "ENABLE_TEST": {
      return {
        ...state,
        enableTest: update(state.enableTest, {
          $set: !state.enableTest,
        }),
      };
    }

    // Set Errors
    case "SET_ERRORS": {
      return {
        ...state,
        errors: update(state.errors, {
          $set: action.payload,
        }),
        loading: false,
      };
    }

    // Set Passed
    case "SET_PASSED": {
      return {
        ...state,
        passed: update(state.passed, {
          $set: action.payload,
        }),
        loading: false,
      };
    }
  }
};

export default RulesReducer;
