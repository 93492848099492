import React, { useContext } from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { ThemeProvider } from "styled-components";
// import theme from './theme';
// import media from './media';
import CurrentThemeProvider, {
  ThemeContext,
} from "./components/Layout/ThemeProviderToggle";

function ThemedApp() {
  const { currentTheme } = useContext(ThemeContext);
  return (
    <ThemeProvider theme={currentTheme.selectedTheme}>
      <App />
    </ThemeProvider>
  );
}

//Theme Provider for styled-components, reference props.theme within a styled component
//Adding styled-theme later.

const container = document.getElementById("root");
const root = createRoot(container);
root.render(
  <CurrentThemeProvider>
    <ThemedApp />
  </CurrentThemeProvider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
