import React, { Component } from "react";
import styled from "styled-components";
import SpinningLoader from "./SpinningLoader";

const LoaderContainer = styled.div`
  display: flex;
  align-items: center;
  min-height: 100%;
  width: 100%;
  justify-content: center;
`;

export default class SplashLoader extends Component {
  render() {
    const Loader = this.props.loader || SpinningLoader;
    const text = this.props.text ? this.props.text : "Loading";
    return (
      <LoaderContainer>
        <Loader text={text} />
      </LoaderContainer>
    );
  }
}
