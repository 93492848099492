import React from "react";
import { useApi } from "../../../api/useApi";
import ErrorMessages from "../../Notifications/ErrorMessages";
import { connectionsList } from "../../../api/connectionQueries";

import * as paths from "../../../common/paths";
import PagedTable from "../../Table/PagedTable";
import StyledLink from "../../StyledLink";

const ConnectionsTable = () => {
  //Init Data Fetch
  const [{ errors, loading, data: apiData }, doFetch] = useApi();

  const connections = apiData?.availableConnections?.edges ?? [];
  const totalCount = apiData?.availableConnections?.totalCount;
  const pageInfo = apiData?.availableConnections?.pageInfo;

  //Fetch for Table Paged
  const fetchData = React.useCallback(
    ({ pageSize, cursor }) => {
      doFetch({
        query: connectionsList,
        variables: {
          first: pageSize,
          after: cursor,
          where: {
            enabled: { eq: true },
          },
          order: {
            name: "ASC",
          },
        },
      });
    },
    [doFetch]
  );

  const columnsData = [
    {
      Header: "Name",
      id: "name",
      accessor: (d) => d?.node?.name,
      Cell: ({ row: { original } }) => {
        if (original?.node?.context === "INGRESSTARGET")
          return <>{original?.node?.name}</>;
        else {
          return (
            <>
              <StyledLink
                to={paths.dataConnectionsSources(original?.node?.id)}
                data-testid="connectionLink"
              >
                {original?.node?.name}
              </StyledLink>
            </>
          );
        }
      },
    },
    {
      Header: "Source Count",
      id: "availableSources",
      accessor: (d) => d?.node?.dataSources.length,
    },
  ];

  return (
    <>
      <PagedTable
        fetchData={fetchData}
        pageInfo={pageInfo}
        totalCount={totalCount}
        loading={loading}
        data={connections}
        columns={columnsData}
      />
      {errors && <ErrorMessages errors={errors} />}
    </>
  );
};

export default ConnectionsTable;
