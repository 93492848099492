import React, { Component } from "react";
import { DragSource } from "react-dnd";
import styled from "styled-components";

const ColumnItem = styled.div`
  padding: 10px;
  background: ${(props) => props.theme.secondarySurface};
  color: ${(props) => props.theme.onSurface};
  margin-bottom: 10px;
  cursor: grab;
  display: flex;
  align-items: center;
  border-left: 4px solid
    ${(props) =>
      props.isSelected
        ? props.theme.onSecondarySurface
        : props.theme.surfaceAlt};
  opacity: ${(props) => props.opacity};
`;

const ColumnItemPush = styled.div`
  flex: 1;
`;

const DataType = styled.div`
  font-size: 0.5em;
  padding-bottom: 5px;
`;

function collect(connect, monitor) {
  return {
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging(),
  };
}

const boxSource = {
  beginDrag(props) {
    return {
      name: props.name,
      item: props.item,
    };
  },
};

class DraggableColumn extends Component {
  render() {
    const {
      name,
      isDropped,
      isDragging,
      connectDragSource,
      item,
      dataType,
      currentFragments,
    } = this.props;
    const opacity = isDragging ? 0.5 : 1;
    const isSelected = currentFragments?.find((cf) => cf.columnId === item?.id);

    return (
      <ColumnItem
        opacity={opacity}
        ref={(instance) => connectDragSource(instance)}
        item={item}
        isSelected={isSelected}
      >
        <ColumnItemPush>
          <DataType>{dataType}</DataType>
          {isDropped ? <s>{name}</s> : name}
        </ColumnItemPush>
      </ColumnItem>
    );
  }
}

export default DragSource(
  (props) => props.type,
  boxSource,
  collect
)(DraggableColumn);
