import React, { useState } from "react";

export const NavContext = React.createContext();

const NavStateProvider = (props) => {
  const [menuState, setMenuState] = useState(true);

  const toggleMenu = () => {
    setMenuState(!menuState);
  };

  const closeMenu = () => {
    setMenuState(false);
  };

  return (
    <NavContext.Provider
      value={{
        toggleMenu,
        menuState,
        closeMenu,
      }}
    >
      {props.children}
    </NavContext.Provider>
  );
};

export default NavStateProvider;
