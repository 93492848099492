import React, { Component } from 'react';
import styled, { keyframes } from 'styled-components';

const SpinnerContainer = styled.div`
  display: flex;
  align-items: center;
`;

const rotate360 = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

const Rotate = styled.div`
  animation: ${rotate360} 1s linear infinite;
  box-sizing: border-box;
  width: 25px;
  height: 25px;
  border-radius: 100%;
  border: 2px solid ${props => props.theme.secondarySurface};
  border-top-color: ${props => props.theme.onSecondarySurface};
  margin-right: 10px;
`;

const LoaderContainer = styled.div`
  display: inline-block;
`;

const LoadingText = styled.div`
  margin-left: 10px;
  color: ${props => props.theme.onSecondarySurface};
`;

export default class SpinningLoader extends Component {
  render() {
    return (
      <LoaderContainer>
        <SpinnerContainer>
          <Rotate />
          <LoadingText>
            {this.props.text ? this.props.text : 'Loading'}
          </LoadingText>
        </SpinnerContainer>
      </LoaderContainer>
    );
  }
}
