import React from "react";
import DataSourceRulesListWidget from "../../components/Widgets/DataSourceWidgets/DataSourceRulesListWidget";
import DataSourceRuleInstances from "../../components/Widgets/DataSourceWidgets/DataSourceRuleInstances";
import Card from "../../components/Card";
import DataSourceFiltersListWidget from "../../components/Widgets/DataSourceWidgets/DataSourceFiltersListWidget";
import {
  Route,
  NavLink,
  useLocation,
  Redirect,
  Switch,
} from "react-router-dom";
import styled from "styled-components/macro";
import { filterCreation, ruleCreation } from "../../common/paths";
import { MdAdd } from "react-icons/md";
import ActionWrapper from "../../components/ActionsWrapper";
import StyledLink from "../../components/StyledLink";

const Tabs = styled.div`
  border-bottom: 1px solid ${(props) => props.theme.surfaceAlt};
  background-color: ${(props) => props.theme.surface};
  position: relative;
  padding: 1.2rem;
`;

const TabLink = styled(NavLink)`
  padding: 1rem;
  border-bottom: 2px solid transparent;
  text-decoration: none;
  opacity: 0.5;
  color: ${(props) => props.theme.onSurface};
  &.active {
    opacity: 1;
    border-bottom: 2px solid ${(props) => props.theme.onSecondarySurface};
  }
  &:hover {
    opacity: 1;
  }
`;
const AllInstances = () => {
  return <DataSourceRuleInstances />;
};

const Standards = () => {
  return <DataSourceRulesListWidget />;
};

const Filters = () => {
  return <DataSourceFiltersListWidget />;
};

const Body = () => {
  return (
    <>
      <Tabs>
        <TabLink exact to="/policies/rules">
          POLICIES
        </TabLink>
        <TabLink to="/policies/filters">FILTERS</TabLink>
        <TabLink exact to="/policies/rules/mapped">
          All MAPPED POLICIES
        </TabLink>
      </Tabs>
      <Switch>
        <Route exact path="/policies">
          <Redirect to="/policies/rules" />
        </Route>

        <Route exact path={`/policies/rules/mapped`} component={AllInstances} />
        <Route path={`/policies/filters`} component={Filters} />
        <Route exact path={`/policies/rules`} component={Standards} />
      </Switch>
    </>
  );
};

//Actions Section of Widget
function Actions(isFilter) {
  return (
    <>
      <ActionWrapper>
        <StyledLink
          data-testid="add-standard"
          to={isFilter ? filterCreation() : ruleCreation()}
        >
          <MdAdd />
        </StyledLink>
      </ActionWrapper>
    </>
  );
}

const CardWrapper = () => {
  let location = useLocation();

  const isFilter = location?.pathname === "/policies/filters";
  return (
    <Card
      title={isFilter ? "FILTERS" : "POLICIES"}
      titleDescription={
        isFilter
          ? "MANAGE FILTER LOGIC THAT CAN BE APPLIED TO DATA SOURCES"
          : "MANAGE POLICIES THAT CAN BE APPLIED TO DATA SOURCES"
      }
      body={Body}
      actions={() => Actions(isFilter)}
    />
  );
};

export default CardWrapper;
