import { useEffect, useReducer, useState } from "react";
import Cookies from "js-cookie";
import qs from "qs";
//TODO: Implement this over old useGraph and PromiseCallback API implementation
//get the secured token for api auth
function getSecuredToken() {
  return Cookies.get("token");
}

function paramObjectToString(params) {
  return qs.stringify(params, { encode: false });
}

//Reducer States for this component, Details what part of the state updates pending dispatch
const reducer = (state, action) => {
  switch (action.type) {
    //First State called when a query is passed to the component.
    default:
      return {
        ...state,
        loading: true,
        errors: null,
      };
    //State dispatched after a query has been completed with a valid response
    //We handle error's here as well for partially incomplete data.
    case "FETCH_STARTED":
      return {
        ...state,
        errors: null,
        loading: true,
      };
    //State dispatched after a query has been completed with a valid response
    //We handle error's here as well for partially incomplete data.
    case "FETCH_COMPLETE":
      return {
        ...state,
        data: action?.payload,
        errors: null,
        loading: false,
      };
    //State where the query has failed, this also handles server error state
    case "FETCH_FAILURE":
      return {
        ...state,
        loading: false,
        errors: action.payload
          ? [{ message: action.payload }]
          : [{ message: "Error Occurred" }],
      };
  }
};

export const useApi = (
  query = null,
  method = null,
  params = null,
  options = { secure: true },
  operationName = undefined,
  body = undefined
) => {
  //Set Initial Query When Hook is Called
  //Set Query takes in a query, variable and an optional option param
  //These are defaulted in the useEffect below if we don't see them passed in.
  const [queryParams, setQuery] = useState({
    query,
    params,
    options,
    operationName,
    body,
    method,
  });

  const initialState = {
    data: null,
    errors: null,
    loading: query ? true : false,
  };

  //initialize the reducer state
  const [state, dispatch] = useReducer(reducer, initialState);

  //On initial load of this component WHEN a query has been passed in or changed.
  useEffect(() => {
    const query = queryParams.query || null;
    const options = queryParams.options || { secure: true };
    const method = queryParams.method || "POST";
    const body = queryParams.body || null;
    const params = queryParams.params
      ? `?${paramObjectToString(queryParams.params)}`
      : "";

    if (!query) return;

    //Start Fetch
    dispatch({
      type: "FETCH_STARTED",
    });

    let abortController = new AbortController();

    const apiUrl = `/api/v1`;

    //Create Header object with valid auth token if secure (secure by default)
    const header = {
      "Content-Type": "application/json",
      Accept: "application/json",
      ...(options.secure && { Authorization: `Bearer ${getSecuredToken()}` }),
    };

    //Since we are using graph and this is a useGraph Hook, we'll default to post method
    const defaultOptions = {
      method: method,
      headers: header,
      body: body ? JSON.stringify(body) : undefined,
    };

    //merge options passed into function
    let actualOptions = Object.assign({}, defaultOptions, options);

    // Create a function to handle the file download
    const handleFileDownload = async (response) => {
      const contentDisposition = response.headers.get("Content-Disposition");
      if (contentDisposition && contentDisposition.includes("attachment")) {
        const blob = await response.blob();
        const downloadUrl = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = downloadUrl;
        const filename = contentDisposition
          .split("filename=")[1]
          .split(";")[0]
          .replace(/['"]/g, "");
        a.setAttribute("download", filename);
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      } else {
        // Handle non-file download but non-JSON responses
        const textData = await response.text();
        console.log("Response received:", textData);
      }
    };

    //async function calls itself on load here
    (async () => {
      let data = null;

      //Create the actual fetch
      try {
        const response = await fetch(`${apiUrl}${query}${params}`, {
          ...actualOptions,
          signal: abortController.signal,
        });

        //handle a response
        if (!response.ok) {
          throw response.statusText;
        }

        //check for json type in the content type to handle the two types of responses

        const contentType = response.headers.get("content-type");
        if (contentType && contentType.includes("application/json")) {
          data = await response.json();
          dispatch({
            type: "FETCH_COMPLETE",
            payload: data,
          });
        } else if (response.ok) {
          // For non-JSON responses that are OK, assume it's a file or a simple text response
          await handleFileDownload(response);
          dispatch({
            type: "FETCH_COMPLETE",
            payload: "Success", // You can adjust this message as needed
          });
        } else if (response.status === 204) {
          dispatch({
            type: "FETCH_COMPLETE",
            payload: "No content",
          });
        } else {
          throw new Error("Response not OK");
        }
      } catch (err) {
        //if the singal has not been aborted (component unmounted) set
        // an error to the current component state
        if (!abortController.signal.aborted) {
          dispatch({ type: "FETCH_FAILURE", payload: err });
          return;
        } else {
          return;
        }
      }
    })();

    // abort any signal left over on unmount
    return () => {
      abortController.abort();
    };
  }, [queryParams]);

  return [state, setQuery];
};
