import React, { useState, useEffect, useContext, useRef } from "react";
import Button from "../../components/Button";
import { Link } from "react-router-dom";
import { useApi } from "../../api/useApi";
import { manageInputSource } from "../../common/paths";
import PagedTable from "../../components/Table/PagedTable";
import { availableDataSourcesPermissionCheck } from "../../api/dataSourceQueries";
import { MdArchive, MdCheckCircle, MdCircle } from "react-icons/md";
import Modal from "../../components/Modal";
import { setDataSourceEnabledFlag } from "../../api/dataSourceMutations";
import Spinner from "../../components/Loaders/Spinner";
import { AuthContext } from "../../contexts/AuthContext";
import TableButton from "../../components/Button/TableButton";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { InLineTitlePin } from "../../components/Table/elements";
import PinButton from "../../components/Pin/PinButton";
import TableActions from "../../components/Table/TableActions";
import columnSort from "../../components/Table/helpers/columnSort";
import ColumnFilter from "../../components/Table/ColumnFilter";
import getFilteredObject from "../../components/Table/helpers/columnFilter";
import NavButton from "../../components/Button/NavigationButton";
import { formatTime } from "../../common/formats";

const SourceTable = () => {
  const ref = useRef(null);

  //Set Function to enable child component to fetch parent reference for width
  const getTableWidth = () => {
    return ref?.current?.offsetWidth ?? 0;
  };

  const [showConfirm, setShowConfirm] = useState(false);
  const [sourceToDelete, setSourceToDelete] = useState(null);
  //currentPage
  const [currentView, setCurrentView] = useState(null);

  const [filter, setFilter] = useState(null);
  //Fetch Count

  const { user } = useContext(AuthContext);
  const [{ loading: removalLoading, data: removalData }, remove] = useApi();
  const toggleSourceEnabled = ({ id, enabled }) => {
    remove({
      query: setDataSourceEnabledFlag,
      variables: { id: id, enabled: !enabled },
    });
  };

  //Init Data Fetch
  const [{ loading, data: apiData }, doFetch] = useApi();
  const dataSources = apiData?.availableDataSourcesPermissionCheck?.edges ?? [];
  const totalCount = apiData?.availableDataSourcesPermissionCheck?.totalCount;
  const pageInfo = apiData?.availableDataSourcesPermissionCheck?.pageInfo;

  //   const isPk = (flags & 4) > 0;
  //   const isAsOfDate = (flags & 32) > 0;

  // /// <summary>The platform default source type</summary>
  // Default = 0,

  // /// <summary>A reference data source</summary>
  // Reference = 1,

  // /// <summary>A Source for Servicer Transfer Inputs</summary>
  // ServicerTransferInput = 2,

  // /// <summary>A Source for Servicer Transfer Outputs</summary>
  // ServicerTransferOutput = 4,

  // /// <summary>A Configured Source for Servicer Transfer Outputs</summary>
  // Configured = 8,

  // /// <summary>A Source for OCR csv output</summary>
  // Ocr = 16,

  //Fetch for Table Paged

  const fetchData = React.useCallback(
    ({ pageSize, cursor, sortBy }) => {
      const sortedObject = columnSort(sortBy);
      const filteredObject = getFilteredObject(filter);
      setCurrentView(cursor);
      doFetch({
        query: availableDataSourcesPermissionCheck,
        variables: {
          first: pageSize,
          after: cursor,
          permissionCode: 1,
          where: {
            enabled: { eq: true },
            tagInstances: {
              some: { tagId: { eq: -2 } },
            },
            ...(filteredObject && {
              ...filteredObject,
            }),
          },
          ...(sortedObject
            ? {
                order: {
                  ...sortedObject,
                },
              }
            : {
                order: {
                  name: "ASC",
                },
              }),
        },
      });
    },
    [doFetch, setCurrentView, filter]
  );

  const columnsData = [
    {
      Header: "Name",
      id: "name",
      accessor: "name",
      Cell: ({ row: { original } }) => {
        return <InLineTitlePin>{original?.node?.name}</InLineTitlePin>;
      },
    },
    {
      Header: "Updated",
      id: "latest",
      Cell: ({ row: { original } }) => {
        if (!original?.node?.latestReport) return null;
        return (
          <div>
            {original?.node?.latestReport?.timestamp ? (
              <InLineTitlePin>
                <div style={{ fontSize: "0.875rem" }}>
                  {formatTime(
                    new Date(original?.node?.latestReport?.timestamp)
                  )}
                </div>
              </InLineTitlePin>
            ) : null}
          </div>
        );
      },
    },
    {
      Header: "Configured",
      id: `tagInstances`,
      Cell: ({ row: { original } }) => {
        let currentTagIds =
          original?.node?.tagInstances?.map((ti) => ti?.tagId) ?? [];
        const isConfigured = currentTagIds.includes(-4);

        return (
          <div>
            {isConfigured ? (
              <div
                style={{
                  marginLeft: "auto",
                  color: "#009fd4",
                  fontSize: "1.4rem",
                }}
                title="configured"
              >
                <MdCheckCircle />
              </div>
            ) : (
              <div
                style={{
                  marginLeft: "auto",
                  color: "#ccc",
                  fontSize: "1.4rem",
                }}
                title="not configured"
              >
                <MdCircle />
              </div>
            )}
          </div>
        );
      },
    },
    {
      Header: " ",
      id: "actions",
      resizable: false,
      sortable: false,
      Cell: ({ totalColumnsWidth, row: { original } }) => {
        const enabled = original?.node?.enabled;

        return (
          <div style={{ textAlign: "right" }}>
            <TableActions
              minWidth={340}
              totalColumnsWidth={totalColumnsWidth}
              getTableWidth={getTableWidth}
            >
              {enabled && (
                <Link to={manageInputSource(original?.node?.id)}>
                  <TableButton
                    type="button"
                    title="Configure"
                    list="true"
                    data-testid="configureSource"
                  >
                    Configure
                  </TableButton>
                </Link>
              )}

              {user && user.role >= 2 && (
                <TableButton
                  danger={enabled}
                  list="true"
                  type="button"
                  title="Archive Toggle"
                  id={`${original?.node?.name}-toggleInputSourceArchiveReactivate`}
                  data-testid={`${original?.node?.name}-toggleInputSourceArchiveReactivate`}
                  bumpdown={true}
                  onClick={() =>
                    setSourceToDelete({
                      id: original?.node?.id,
                      enabled: enabled,
                    })
                  }
                >
                  {enabled ? <MdArchive /> : "Reactivate"}
                </TableButton>
              )}
              <PinButton type="sources" item={original?.node?.id} />
            </TableActions>
          </div>
        );
      },
    },
  ];

  //handle remove rule update
  useEffect(() => {
    if (removalData) {
      fetchData({ pageSize: 10, cursor: currentView ?? null });
      setShowConfirm(false);
      setSourceToDelete(null);
    }
  }, [removalData, fetchData, currentView]);

  useEffect(() => {
    if (sourceToDelete) {
      setShowConfirm(true);
    } else {
      setShowConfirm(false);
    }
  }, [sourceToDelete]);

  return (
    <>
      {showConfirm ? (
        <Modal
          title={`Confirm Source ${
            sourceToDelete?.enabled ? "Archival" : "Reactivation"
          }`}
          hide={() => setSourceToDelete(null)}
        >
          <p>
            Are you sure you wish to{" "}
            {sourceToDelete?.enabled ? "archive" : "reactivate"} this source?
          </p>
          <div>
            <Button
              type="button"
              list="true"
              disabled={removalLoading}
              danger
              onClick={() => toggleSourceEnabled(sourceToDelete)}
            >
              {removalLoading ? <Spinner /> : "Yes"}
            </Button>
            <Button
              type="button"
              disabled={removalLoading}
              onClick={() => {
                setSourceToDelete(null);
              }}
            >
              Cancel
            </Button>
          </div>
        </Modal>
      ) : null}

      <div ref={ref}>
        <div style={{ margin: "1rem" }}>
          <NavButton
            title="Add New Output Source"
            to={"/feeds/inputsources/new"}
          >
            Add New Input Source
          </NavButton>
        </div>

        <ColumnFilter column={"name"} setFilter={setFilter} />
        <PagedTable
          fetchData={fetchData}
          loading={loading}
          pageInfo={pageInfo}
          totalCount={totalCount}
          data={dataSources}
          columns={columnsData}
          defaultPageSize={50}
        />
      </div>
      <ToastContainer />
    </>
  );
};

export default SourceTable;
