import React from "react";
import { ReactComponent as HighPriority } from "../assets/images/ic-priority-high.svg";
import { ReactComponent as LowPriority } from "../assets/images/ic-priority-low.svg";
import { ReactComponent as NormalPriority } from "../assets/images/ic-priority-normal.svg";

const RenderPriority = ({ priorityLevel, bump }) => {
  const priorityLevelLC = priorityLevel?.toLowerCase();

  if (priorityLevelLC === "high") {
    return (
      <>
        <div
          style={{
            display: "flex",
            alignItems: "top",
            position: "relative",
            top: bump ? "-.3rem" : "",
          }}
        >
          <HighPriority />
        </div>
        High
      </>
    );
  } else if (priorityLevelLC === "normal") {
    return (
      <>
        <div
          style={{
            display: "flex",
            alignItems: "top",
            position: "relative",
            top: bump ? "-.3rem" : "",
          }}
        >
          <NormalPriority />
        </div>
        Normal
      </>
    );
  } else if (priorityLevelLC === "informational") {
    return (
      <>
        <div
          style={{
            display: "flex",
            alignItems: "top",
            position: "relative",
            top: bump ? "-.3rem" : "",
          }}
        >
          <LowPriority />
        </div>
        Informational
      </>
    );
  } else {
    return (
      <>
        <div
          style={{
            display: "flex",
            alignItems: "top",
            position: "relative",
            top: bump ? "-.3rem" : "",
          }}
        >
          <LowPriority />
        </div>
        Low
      </>
    );
  }
};

export default RenderPriority;
