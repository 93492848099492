import React from "react";
import Source from "./Source";
import Target from "./Target";
import Operation from "./Operation";
import styled from "styled-components/macro";

const FragmentContainer = styled.div`
  padding: 1rem;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;

const FragmentForm = ({ element, dispatch, index, isEditing }) => {
  return (
    <FragmentContainer>
      <Source node={element} dispatch={dispatch} index={index} />
      <Operation
        node={element}
        dispatch={dispatch}
        index={index}
        isEditing={isEditing}
      />
      {element?.target ? (
        <Target node={element} dispatch={dispatch} index={index} />
      ) : null}
    </FragmentContainer>
  );
};
export default FragmentForm;
