// Create Servicer Transfer Feed
export const createServicerTransferFeed = /* GraphQL */ `
  mutation (
    $inputFeed: ServicerTransferFeedModelInput!
    $generatedOutputSource: GeneratedDataSourceInput
    $refreshInfo: SchedulingInputModelInput
  ) {
    createServicerTransferFeed(
      inputFeed: $inputFeed
      generatedOutputSource: $generatedOutputSource
      refreshInfo: $refreshInfo
    )
  }
`;

// Create Servicer Transfer Dictionary
export const createServicerTransferDictionary = /* GraphQL */ `
  mutation ($dictionary: ServicerTransferDictionaryModelInput!) {
    createServicerTransferDictionary(dictionary: $dictionary) {
      id
      keyCollectionId
      valueCollectionId
    }
  }
`;

// Cancel Servicer Transfer Feed
export const endFeedRefreshSummaryQuery = /* GraphQL */ `
  mutation ($feedId: Int!) {
    endFeedRefreshSummary(feedId: $feedId) {
      id
      feedId
    }
  }
`;

// Start Servicer Transfer Feed
export const startServicerTransferFeed = /* GraphQL */ `
  mutation ($feedId: Int!, $validationRun: Boolean) {
    startServicerTransferFeed(feedId: $feedId, validationRun: $validationRun) {
      id
      feedId
    }
  }
`;

// Start All Servicer Transfer Feed
export const startAllServicerTransferFeeds = /* GraphQL */ `
  mutation () {
    startAllServicerTransferFeeds {
      id
    }
  }
`;

// Sets unique values for a collection
export const setUniqueValuesByCollection = /* GraphQL */ `
  mutation ($inputModel: UniqueCollectionModelInput!, $columnId: Int) {
    setUniqueValuesByCollection(inputModel: $inputModel, columnId: $columnId)
  }
`;

// Clones a feed
export const cloneServicerTransferFeed = /* GraphQL */ `
  mutation ($cloneModel: ServicerTransferFeedCloneModelInput!) {
    cloneServicerTransferFeed(cloneModel: $cloneModel) {
      id
      name
      destinationDataSourceId
      sourceDataSourceIds
      primarySourceDataSourceId
      createdOn
      enabled
      inputCollectionId
      outputCollectionId
      permissions {
        accessingWorkGroupId
        permissionCode
      }
      transformers {
        id
        inputSubtype
        outputSubtype
        transformationType
        inputSubtype
        outputSubtype
        formattingString
        transformerArguments {
          argumentInstanceId
          argumentOrder
          instanceMappingId
          dataSourceColumnId
          column {
            id
            name
            flags
            uniqueValueCollectionId
            fullyQualifiedName
            dataSourceId
            dataSource {
              id
              tagInstances {
                id
                remoteObjectId
                tag {
                  name
                  description
                  id
                }
                tagId
                type
              }
            }
          }
        }
      }
    }
  }
`;

// Clones a feed
export const generateServicerTransferFeed = /* GraphQL */ `
  mutation ($feedModel: ServicerTransferFeedCloneModelInput!) {
    generateServiceTransferFeedFormat(feedModel: $feedModel) {
      id
      name
      destinationDataSourceId
      sourceDataSourceIds
      createdOn
      enabled
      inputCollectionId
      outputCollectionId
      permissions {
        accessingWorkGroupId
        permissionCode
      }
      transformers {
        id
        inputSubtype
        outputSubtype
        transformationType
        inputSubtype
        outputSubtype
        formattingString
        transformerArguments {
          argumentInstanceId
          argumentOrder
          instanceMappingId
          dataSourceColumnId
          column {
            id
            name
            flags
            uniqueValueCollectionId
            fullyQualifiedName
            dataSourceId
          }
        }
      }
    }
  }
`;

// Set ServicerTransferFeed Enabled Flag
export const setServicerTransferFeedEnabledFlag = /* GraphQL */ `
  mutation ($id: Int!, $enabled: Boolean!) {
    setServicerTransferFeedEnabledFlag(id: $id, enabled: $enabled) {
      id
      enabled
    }
  }
`;

// Start Servicer Transfer Feed
export const previewServicerTransferTransformer = /* GraphQL */ `
  mutation (
    $feedId: Long!
    $inputDataSourceIds: [Long!]!
    $inputTransformer: ServicerTransferTransformerModelInput!
    $outputDataSourceId: Long!
  ) {
    previewServicerTransferTransformer(
      feedId: $feedId
      inputDataSourceIds: $inputDataSourceIds
      outputDataSourceId: $outputDataSourceId
      inputTransformer: $inputTransformer
    )
  }
`;

// Start All Servicer Transfer Feed
export const setUniqueValuesFromRefreshByCollection = /* GraphQL */ `
  mutation ($columnId: Int) {
    setUniqueValuesFromRefreshByCollection(columnId: $columnId)
  }
`;
