import React, { useEffect } from "react";
import CodeMirror from "@uiw/react-codemirror";
import { javascript } from "@codemirror/lang-javascript";
import { EditorView } from "@codemirror/view";

function NCalcEditor({ node, index, value, dispatch }) {
  const [expression, setExpression] = React.useState(value);

  useEffect(() => {
    dispatch({
      type: "UPDATE_TARGET_NAME",
      payload: {
        node: node,
        value: expression ?? "",
        index: index,
      },
    });
  }, [expression, dispatch, index, node]);

  const onChange = React.useCallback((value, viewUpdate) => {
    setExpression(value);
  }, []);

  return (
    <div className="ncalc-ide">
      <CodeMirror
        value={expression}
        extensions={[javascript(), EditorView.lineWrapping]}
        onChange={onChange}
        basicSetup={{
          autocompletion: false,
        }}
      />
    </div>
  );
}

export default NCalcEditor;
