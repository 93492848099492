import React from "react";
import styled, { withTheme } from "styled-components/macro";
import Card from "../../../components/Card";
import DataSourceScoreHistory from "../../../components/Widgets/DataSourceWidgets/DataSourceScoreHistory";
import DataQualityScore from "../../../components/Widgets/DataSourceWidgets/DataQualityScore";
import { format } from "date-fns";
import { findThresholdScore } from "../../../common/thresholdScores";
import ScoreHover from "../../../components/Widgets/DataSourceWidgets/ScoreHover";
import DataQualityFailuresCountHover from "../../../components/Widgets/DataSourceWidgets/DataQualityFailuresCountHover";
import DataQualityOpportunitiesForFailuresCountHover from "../../../components/Widgets/DataSourceWidgets/DataQualityOpportunitiesForFailuresCountHover";
import DataSourceRowsMeasuredHover from "../../../components/Widgets/DataSourceWidgets/DataSourceRowsMeasuredHover";

const OverviewBlock = styled.div`
  display: flex;
  @media (max-width: 1200px) {
    display: block;
  }
`;

const ScoreBlock = styled.div`
  min-width: 325px;
  @media (max-width: 1080px) {
    margin-bottom: 1.5rem;
  }
`;

const UpperDeckContainer = styled.div`
  display: flex;
  border-right: 1px solid #ccc;
  padding-right: 1rem;
  margin-right: 1rem;
  @media (max-width: 1200px) {
    margin-bottom: 1rem;
    border-right: none;
  }
  @media (max-width: 800px) {
    display: block;
  }
`;

const ReportScoreOverview = React.memo(
  ({ loading, sourceId, apiData, theme }) => {
    function body() {
      const scoreHistoryData = apiData?.scoreHistory ?? null;

      const qualityScoreData = apiData?.qualityScore ?? null;

      const date =
        (qualityScoreData &&
          format(new Date(qualityScoreData?.date), "MMM dd/yyyy")) ||
        null;

      function checkNumberValue(value) {
        return Number.isInteger(value) ? value : null;
      }
      const scoreValue = checkNumberValue(qualityScoreData?.score);
      const scoreObject = findThresholdScore(scoreValue, theme);

      const failureOpportunity = apiData?.failureOpportunities ?? null;
      const dataRowCount = apiData?.rowCount ?? null;
      const failureCount = apiData?.totalFailures ?? null;

      return (
        <OverviewBlock>
          <UpperDeckContainer>
            <ScoreBlock>
              <div
                style={{
                  display: "flex",
                  height: "100%",
                  position: "relative",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <div
                    style={{
                      fontFamily: "Source Sans Pro Semibold",
                    }}
                  >
                    QUALITY SCORE
                  </div>
                  <div style={{ marginTop: "auto" }}>
                    <div
                      style={{
                        fontSize: "2.5rem",
                        marginBottom: "1rem",
                        color: scoreObject.color,
                      }}
                    >
                      {scoreObject.name}
                    </div>
                    <div
                      style={{
                        fontSize: "1.1875rem",
                        fontFamily: "Source Sans Pro Semibold",
                        opacity: 0.7,
                      }}
                    >
                      {date}
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    position: "relative",
                    width: "calc(120px + 4rem)",
                    borderRight: "1px solid #ccc",
                  }}
                >
                  <ScoreHover
                    sourceId={sourceId}
                    loading={loading}
                    data={apiData}
                  >
                    <DataQualityScore
                      sourceId={sourceId}
                      loading={loading}
                      data={qualityScoreData}
                    />
                  </ScoreHover>
                </div>
              </div>
            </ScoreBlock>
            <div
              style={{
                display: "flex",
              }}
            >
              <DataSourceRowsMeasuredHover
                sourceId={sourceId}
                loading={loading}
                data={dataRowCount}
              />

              <DataQualityOpportunitiesForFailuresCountHover
                sourceId={sourceId}
                data={failureOpportunity}
                loading={loading}
              />

              <DataQualityFailuresCountHover
                sourceId={sourceId}
                data={failureCount}
                loading={loading}
              />
            </div>
          </UpperDeckContainer>
          <div style={{ width: "100%" }}>
            <DataSourceScoreHistory
              sourceId={sourceId}
              loading={loading}
              data={scoreHistoryData}
            />
          </div>
        </OverviewBlock>
      );
    }

    return (
      <div style={{ marginBottom: "1rem" }}>
        <Card headless body={body} />
      </div>
    );
  }
);

export default withTheme(ReportScoreOverview);
