import React, { useState, useEffect, useCallback, useRef } from "react";
import { useApi } from "../../../api/useApi";
import Spinner from "../../Loaders/Spinner";
import { standardsList } from "../../../api/ruleQueries";
import { setRuleStandardEnabledFlag } from "../../../api/ruleMutations";
import ErrorMessages from "../../Notifications/ErrorMessages";
import { Link } from "react-router-dom";
import Button from "../../Button";
import { filterDisabled } from "../../../common/helpers/util";
import Modal from "../../Modal";
import PagedTable from "../../Table/PagedTable";
import { ruleStandardById } from "../../../common/paths";
import TableButton from "../../Button/TableButton";
import SearchPolicies from "./SeachPolicies";
import PinButton from "../../Pin/PinButton";
import TableActions from "../../Table/TableActions";
import FilterByTags from "../../Tags/FilterByTags";
import columnSort from "../../Table/helpers/columnSort";
import StyledLink from "../../StyledLink";

const DataSourceRulesListWidget = () => {
  const [showConfirm, setShowConfirm] = useState(false);
  const [ruleToDelete, setruleToDelete] = useState(null);

  //Filter Tags
  const [tagFilter, setTagFilter] = useState([]);
  const [exactMatchTagFilter, setExactMatchTagFilter] = useState(false);
  const [manuallySelected, setManuallySelected] = useState(null);

  const ref = useRef(null);

  //Set Function to enable child component to fetch parent reference for width
  const getTableWidth = () => {
    return ref?.current?.offsetWidth ?? 0;
  };

  //Init Data Fetch
  const [
    {
      loading: availableStandardsLoading,
      errors: availableStandardsErrors,
      data: standards,
    },
    doFetch,
  ] = useApi();

  const totalCount = standards?.availableBusinessRuleStandards?.totalCount;
  const pageInfo = standards?.availableBusinessRuleStandards?.pageInfo;

  //Fetch for Table Paged
  const fetchData = React.useCallback(
    ({ pageSize, cursor, sortBy }) => {
      const sortedObject = columnSort(sortBy);
      doFetch({
        query: standardsList,
        variables: {
          first: pageSize,
          tagFilter: {
            tagIds: [...tagFilter?.map((tf) => tf.tagId)],
            exactMatch: exactMatchTagFilter,
          },
          after: cursor,
          standardType: "RULE",
          where: {
            enabled: { eq: true },
          },
          ...(sortedObject
            ? {
                order: {
                  ...sortedObject,
                },
              }
            : {
                order: {
                  name: "ASC",
                },
              }),
        },
      });
    },
    [doFetch, tagFilter, exactMatchTagFilter]
  );

  useEffect(() => {
    if (ruleToDelete) {
      setShowConfirm(true);
    } else {
      setShowConfirm(false);
    }
  }, [ruleToDelete]);

  //remove rules state

  const [
    { loading: removeRuleDataLoading, data: removeRuleData },
    removeRuleApi,
  ] = useApi();

  //handle remove rule update
  useEffect(() => {
    if (removeRuleData) {
      //set filtered rule
      setRules((prevRules) =>
        prevRules.filter(
          (rule) =>
            rule?.node?.id !== removeRuleData?.setRuleStandardEnabledFlag?.id
        )
      );
      setruleToDelete(null);
    }
  }, [removeRuleData]);

  //define initial rule state
  const [rules, setRules] = useState([]);

  //callback for removing a row
  const removeRuleStandard = useCallback(
    (templateId) => {
      const variables = { id: templateId, enabled: false };
      removeRuleApi({ query: setRuleStandardEnabledFlag, variables });
    },
    [removeRuleApi]
  );

  //set rules into local state so we can mutate on remove
  useEffect(() => {
    if (standards) {
      const availableStandards =
        standards?.availableBusinessRuleStandards?.edges ?? [];
      setRules(filterDisabled(availableStandards));
    }
  }, [standards]);

  if (availableStandardsErrors)
    return <ErrorMessages errors={availableStandardsErrors} />;

  const columnsData = [
    {
      Header: "Name",
      id: "name",
      accessor: (d) => d?.node?.name,
      Cell: ({ row: { original } }) => {
        return (
          <StyledLink
            to={ruleStandardById(original?.node?.id)}
            data-testid="policyLink"
          >
            {original?.node?.name}
          </StyledLink>
        );
      },
    },
    {
      Header: "Instances Mapped",
      id: "PIM",
      Cell: ({ row: { original } }) => {
        const instances = original?.node?.instances.filter(
          (ni) =>
            ni?.enabledState !== "DRAFT" &&
            ni?.enabledState !== "DISABLED" &&
            ni?.dataSource?.enabled
        );

        return <div>{instances?.length}</div>;
      },
    },
    {
      Header: "Draft Instances",
      id: "PDM",
      Cell: ({ row: { original } }) => {
        const instances = original?.node?.instances.filter(
          (ni) =>
            ni?.enabledState !== "PUBLISHED" &&
            ni?.enabledState !== "DISABLED" &&
            ni?.dataSource?.enabled
        );

        return <div>{instances?.length}</div>;
      },
    },
    {
      Header: "Tags",
      id: "tags",
      Cell: ({ row: { original } }) => {
        return (
          <div>
            {original?.node?.tagInstances?.map((ti, i) => {
              const isLast = original?.node?.tagInstances?.length - 1 <= i;
              return (
                <div
                  onClick={() => setManuallySelected([ti])}
                  style={{
                    display: "inline-block",
                    background: "#e6e6e6",
                    padding: "0.2rem",
                    paddingLeft: ".4rem",
                    paddingRight: ".4rem",
                    fontSize: ".8rem",
                    marginRight: isLast ? "" : ".5rem",
                    marginBottom: isLast ? "" : ".5rem",
                    cursor: "pointer",
                  }}
                >
                  {ti?.tag?.name}
                </div>
              );
            })}
          </div>
        );
      },
    },
    {
      Header: " ",
      id: "actions",
      width: 220,
      sortable: false,
      Cell: ({ totalColumnsWidth, row: { original } }) => {
        return (
          <div style={{ textAlign: "right" }}>
            <TableActions
              minWidth={220}
              totalColumnsWidth={totalColumnsWidth}
              getTableWidth={getTableWidth}
            >
              <Link to={ruleStandardById(original?.node?.id)}>
                <TableButton list="true" type="button">
                  Manage
                </TableButton>
              </Link>
              {original?.node?.id > 0 ? (
                <TableButton
                  danger
                  list
                  onClick={() => setruleToDelete(original?.node?.id)}
                  type="button"
                >
                  Remove
                </TableButton>
              ) : null}

              <PinButton type="policies" item={original?.node?.id} />
            </TableActions>
          </div>
        );
      },
    },
  ];

  return (
    <>
      {showConfirm ? (
        <Modal
          title={`Confirm Rule Removal`}
          hide={() => setruleToDelete(null)}
        >
          <p>Are you sure you wish to remove this Rule?</p>
          <div>
            <Button
              type="button"
              list="true"
              disabled={removeRuleDataLoading}
              danger
              onClick={() => removeRuleStandard(ruleToDelete)}
            >
              {removeRuleDataLoading ? <Spinner /> : "Yes"}
            </Button>
            <Button
              type="button"
              disabled={removeRuleDataLoading}
              onClick={() => {
                setruleToDelete(null);
              }}
            >
              Cancel
            </Button>
          </div>
        </Modal>
      ) : null}

      <div style={{ display: "flex" }}>
        <div style={{ flex: 1, marginRight: "1rem" }}>
          <SearchPolicies ruleType={1} />
        </div>
        <div style={{ flex: 1, marginTop: "1rem", marginBottom: "1rem" }}>
          <FilterByTags
            currentTags={manuallySelected}
            updateTags={setTagFilter}
            tagType="DATA_SOURCE"
            exactMatch={setExactMatchTagFilter}
          />
        </div>
      </div>

      <div ref={ref}>
        <PagedTable
          fetchData={fetchData}
          loading={availableStandardsLoading}
          pageInfo={pageInfo}
          totalCount={totalCount}
          data={rules}
          columns={columnsData}
        />
      </div>
    </>
  );
};

export default DataSourceRulesListWidget;
