import React from "react";
import ToggleButton from "../Button/Toggle";
import styled from "styled-components/macro";

const ToggleBorder = styled.div`
  /* border: 2px solid ${(props) => props.theme.onSurfaceLight}; */
  display: inline-block;
  margin-right: 1rem;
`;

const Toggle = ({ toggleData, toggleState, bool, hasCounts }) => {
  return (
    <ToggleBorder>
      {toggleData.map((td) => {
        return (
          <ToggleButton
            active={bool ? toggleState : td.name === toggleState}
            onClick={td.action}
          >
            {td.name}
            {hasCounts ? ` (${td?.data})` : null}
          </ToggleButton>
        );
      })}
    </ToggleBorder>
  );
};

export default Toggle;
