import React, { useState } from "react";
import ErrorMessages from "../../components/Notifications/ErrorMessages";
import { useApi } from "../../api/useApi";
import styled, { keyframes } from "styled-components/macro";
import { dataSourceColumnsSimple } from "../../api/dataSourceQueries";
import useAllRuleStandards from "../../Hooks/useAllRuleStandards";
import Fuse from "fuse.js";
import SplashLoader from "../../components/Loaders/SplashLoader";
import SortTable from "../../components/Table/SortTable";
import TableButton from "../../components/Button/TableButton";
import { IoIosGitNetwork } from "react-icons/io";
import { GiArtificialHive } from "react-icons/gi";

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const zoomInZoomOut = keyframes`
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1.2, 1.2);
  }
  100% {
    transform: scale(1, 1);
  }
`;

const Animation = styled.div`
  font-size: 4rem;
  animation: ${spin} 3s linear infinite;
  transform-origin: 50% 50%;
  animation-duration: 5s;
  animation-timing-function: ease;
  animation-iteration-count: infinite;
`;

const SpinContainer = styled.div`
  margin: 50px;
  position: absolute;
  animation: ${zoomInZoomOut} 5s infinite;
`;

const TableLoading = styled.div`
  position: relative;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 3;
  justify-content: center;
  align-items: center;
  display: flex;
  background: rgba(255, 255, 255, 0.7);
`;

const SpinnerText = styled.div`
  display: block;
  margin-top: 8rem;
`;

const LoadingAI = () => {
  return (
    <TableLoading>
      <SpinContainer>
        <Animation>
          <GiArtificialHive />
        </Animation>
      </SpinContainer>
      <SpinnerText>Analyzing Data</SpinnerText>
    </TableLoading>
  );
};

const LoadingStandardsForSuggestions = React.memo(({ sourceId, setMapId }) => {
  const [{ stateStandards, isLoading }] = useAllRuleStandards();

  const [{ loading, errors, data: dataSourceData }] = useApi(
    dataSourceColumnsSimple,
    {
      id: Number(sourceId),
    }
  );

  const columns = dataSourceData?.dataSource?.columns ?? [];

  if (isLoading || loading)
    return <SplashLoader text={"Fetching Available Standards"} />;

  const keyedStateStandards = stateStandards?.map((standard) => {
    //replace any non char with spaces
    const regex = /[^A-Za-z0-9]/g;
    const nameSplit = standard?.name.replace(regex, " ");

    //splits into array with spaces
    const nameArray = nameSplit.split(/([A-Z][a-z]+)/).filter(function (e) {
      return e;
    });

    const cleanNameArray = nameArray.filter(function (str) {
      return /\S/.test(str);
    });

    return {
      keyed: cleanNameArray,
      ...standard,
    };
  });

  const keyedColumnNames = columns?.map((column) => {
    //replace any non char with spaces
    const regex = /[^A-Za-z0-9]/g;
    const nameSplit = column?.name.replace(regex, " ");

    //splits into array with spaces
    const nameArray = nameSplit.split(/([A-Z][a-z]+)/).filter(function (e) {
      return e;
    });

    const removeSecondSpace = nameArray.map((cl) => {
      return cl.split(" ").join("");
    });

    const cleanNameArray = removeSecondSpace.filter(function (str) {
      return /\S/.test(str);
    });

    return {
      keyed: cleanNameArray,
      ...column,
    };
  });

  if (errors) return <ErrorMessages errors={errors} />;
  return (
    <Suggestions
      isLoading={isLoading}
      stateStandards={keyedStateStandards}
      sourceId={sourceId}
      setMapId={setMapId}
      columns={keyedColumnNames}
    />
  );
});

const Suggestions = React.memo(({ setMapId, stateStandards, columns }) => {
  const [longLoad, setLongLoad] = useState(true);

  let weightedUniqueSuggestions = [];

  const options = {
    includeScore: true,
    // Search in 'name', 'description'
    keys: ["keyed"],
    useExtendedSearch: true,
  };

  const fuse = new Fuse(stateStandards, options);

  let suggestions = [];

  //Loop through each column
  columns.forEach((c) => {
    c.keyed.forEach((keyedColumn) => {
      const result = fuse.search(`'${keyedColumn}`);
      if (result?.length) {
        result.forEach((r) => {
          suggestions.push(r?.item);
        });
      }
    });
  });

  const uniqueSuggestions = suggestions.filter((v, i, a) => a.indexOf(v) === i);

  weightedUniqueSuggestions = uniqueSuggestions
    .map((us) => {
      return {
        ...us,
        weight: suggestions.filter((s) => s?.id === us?.id)?.length,
      };
    })
    .sort(function (a, b) {
      return a.weight - b.weight;
    });

  setTimeout(() => {
    setLongLoad(false);
  }, 3000);

  if (longLoad) return <LoadingAI />;

  if (!suggestions?.length)
    return <div>No suggestions found at this time.</div>;

  const columnsData = [
    {
      Header: "Title",
      id: "title",
      accessor: (d) => d?.name,
    },
    {
      Header: "",
      id: "actions",
      accessor: "actions",
      Cell: ({ row: { original } }) => {
        return (
          <>
            <TableButton
              onClick={() => setMapId(original?.id)}
              iconBump={true}
              data-testid={`mapping-rule-${original?.id}`}
            >
              <IoIosGitNetwork /> Map
            </TableButton>
          </>
        );
      },
    },
  ];

  return (
    <div>
      <h4>
        Suggested Policies Found{" "}
        {weightedUniqueSuggestions?.length
          ? `(${weightedUniqueSuggestions?.length})`
          : `(0)`}
      </h4>
      <SortTable
        data={weightedUniqueSuggestions}
        columns={columnsData}
        // loading={loading}
      />
    </div>
  );
});

export default LoadingStandardsForSuggestions;
