import { useContext } from "react";
import { SocketContext } from "../contexts/useSubscriptions";

const UseEtlPipelineAddCompleteEvent = () => {
  const { etlPipelineAddCompleteEvent, setEtlPipelineAddCompleteEvent } =
    useContext(SocketContext);

  return { etlPipelineAddCompleteEvent, setEtlPipelineAddCompleteEvent };
};

export default UseEtlPipelineAddCompleteEvent;
