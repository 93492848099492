import React from "react";
import { useFormikContext } from "formik";
import { useApi } from "../../../api/useApi";
import {
  updateUserWorkGroupRole,
  addUser,
} from "../../../api/workgroupMutations";
import { users } from "../../../api/userQueries";
import PagedTable from "../../Table/PagedTable";
import { StyledSelect } from "../../Form/FormControls";

const roles = [
  { label: "INACTIVE", value: "INACTIVE" },
  { label: "ACTIVE", value: "ACTIVE" },
  { label: "OWNER", value: "OWNER" },
];

const SelectUsers = React.memo(
  ({ workGroupId, updateWorkGroup, selectedOrg }) => {
    const {
      values: { users: selectedUsers },
    } = useFormikContext();

    const [{ loading, data }, doFetch] = useApi();

    const userData = data?.users?.edges ?? [];
    const totalCount = data?.users?.totalCount;
    const pageInfo = data?.users?.pageInfo;

    //Fetch for Table Paged
    const fetchData = React.useCallback(
      ({ pageSize, cursor }) => {
        doFetch({
          query: users,
          variables: {
            first: pageSize,
            after: cursor,
            where: {
              organizationId: selectedOrg?.value,
            },
            order: {
              displayName: "ASC",
            },
          },
        });
      },
      [doFetch, selectedOrg]
    );

    const updateSelection = (e, original) => {
      // IF EDITING OWNERS
      const existingIndex = selectedUsers.findIndex(
        (item) => item.member.id === original?.node?.id
      );

      if (existingIndex === -1) {
        const variables = {
          workGroupId: workGroupId,
          newUserId: original?.node?.id,
          role: e.value,
        };
        updateWorkGroup({ query: addUser, variables: variables });
      } else {
        const variables = {
          workGroupId: workGroupId,
          userToUpdate: original?.node?.id,
          newRole: e.value,
        };
        updateWorkGroup({
          query: updateUserWorkGroupRole,
          variables: variables,
        });
      }
    };

    const columnsData = [
      {
        Header: "Name",
        id: "name",
        accessor: (d) => d?.node?.displayName,
      },
      {
        Header: "Email",
        id: "email",
        accessor: (d) => d?.node?.emailAddress,
      },
      {
        Header: () => (
          <div
            style={{
              minWidth: "165px",
              maxWidth: "165px",
            }}
          >
            Role
          </div>
        ),
        id: "role",
        width: 200,
        maxWidth: 200,
        Cell: ({ row: { original } }) => {
          const selected = selectedUsers.find(
            (e) => e.member.id === original?.node?.id
          );
          return (
            <StyledSelect
              className={`react-select-container`}
              classNamePrefix={`react-select`}
              menuPortalTarget={document.body}
              name={`role`}
              id={`role`}
              inputId={`role-input`}
              instanceId={`role-instance`}
              options={roles}
              onChange={(e) => updateSelection(e, original)}
              placeholder={"Select Role"}
              value={selected ? selected.role : null}
            />
          );
        },
      },
    ];

    return (
      <>
        <PagedTable
          fetchData={fetchData}
          loading={loading}
          pageInfo={pageInfo}
          totalCount={totalCount}
          data={userData}
          columns={columnsData}
          defaultPageSize={50}
        />
      </>
    );
  }
);

export default SelectUsers;
