import React, { useContext } from "react";
import Card from "../../Card";
import { MdAdd } from "react-icons/md";
import ActionWrapper from "../../ActionsWrapper";
import StyledLink from "../../StyledLink";
import ConnectionsTable from "./ConnectionsTable";
import { AuthContext } from "../../../contexts/AuthContext";

const ConnectionListWidget = () => {
  const { user } = useContext(AuthContext);

  //Body Section of Widget
  function Body() {
    // connections
    return <ConnectionsTable />;
  }

  //Actions Section of Widget
  function Actions() {
    if (user && user?.role <= 2) return null;
    return (
      <>
        <ActionWrapper>
          <StyledLink to={"/connections/create"} title="Add New Connection">
            <MdAdd />
          </StyledLink>
        </ActionWrapper>
      </>
    );
  }

  return (
    <Card
      title={"Connections"}
      titleDescription={"Available Data Connections"}
      body={Body}
      actions={Actions}
    />
  );
};

export default ConnectionListWidget;
