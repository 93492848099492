import { useContext } from "react";
import { SocketContext } from "../contexts/useSubscriptions";

const UseConnectionCreated = () => {
  const { lastConnectionCreated, setLastConnectionCreated } =
    useContext(SocketContext);

  return { lastConnectionCreated, setLastConnectionCreated };
};
export default UseConnectionCreated;
