import React, { useCallback, useEffect, useState } from "react";
import ETLForm from "../Forms/ETL";
import { useApi } from "../../api/useApi";
import ETLBrowse from "../Forms/ETLBrowse";
import ErrorMessages from "../Notifications/ErrorMessages";
import styled from "styled-components/macro";
import Button from "../../components/Button";
import SortTable from "../../components/Table/SortTable";
import SplashLoader from "../../components/Loaders/SplashLoader";
import { MdArchive } from "react-icons/md";
import { updateEtlProviderInstanceEnabledFlag } from "../../api/etlProviderMutations";
import Modal from "../../components/Modal";
import Spinner from "../../components/Loaders/Spinner";
import { InLineTitlePin } from "../../components/Table/elements";

const ProvidersLoading = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 3;
  display: flex;
  background: rgba(255, 255, 255, 0.7);
`;

const ProviderButton = styled.div`
  padding-right: 0.5rem;
`;

const Instances = ({ etlProviderData, fetchLatestETLProvider }) => {
  const instances = etlProviderData?.instances ?? [];
  const enabledInstances =
    etlProviderData?.instances?.filter((instances) => instances?.enabled) ?? [];
  const disabledInstances =
    etlProviderData?.instances?.filter((instances) => !instances?.enabled) ??
    [];

  const [showBrowse, setShowBrowse] = useState(false);
  const [showArchive, setShowArchive] = useState(false);
  const [showManageProvider, setShowManageProvider] = useState(false);

  if (instances?.length) {
    return (
      <>
        <div style={{ display: "flex" }}>
          <div style={{ flex: "1" }}>
            {!showManageProvider && (
              <Button
                type="button"
                onClick={() => {
                  setShowBrowse((prevState) => !prevState);
                  setShowArchive(false);
                }}
              >
                {showBrowse ? "Cancel" : "Add Instance"}
              </Button>
            )}
          </div>
          <ProviderButton>
            <Button
              type="button"
              onClick={() => {
                setShowManageProvider((prevState) => !prevState);
                setShowBrowse(false);
                setShowArchive(false);
              }}
            >
              {showManageProvider ? "Cancel" : "Manage Provider"}
            </Button>
          </ProviderButton>
          <div style={{ marginLeft: "auto" }}>
            {disabledInstances?.length && !showManageProvider ? (
              <Button
                type="button"
                onClick={() => {
                  setShowArchive((prevState) => !prevState);
                  setShowBrowse(false);
                }}
              >
                {showArchive ? (
                  <>Active List</>
                ) : (
                  <>
                    <MdArchive /> Archive List{" "}
                  </>
                )}
              </Button>
            ) : null}
          </div>
        </div>

        {showManageProvider && (
          <>
            <ETLForm
              etlProviderData={etlProviderData}
              fetchLatestETLProvider={fetchLatestETLProvider}
            />
          </>
        )}

        {showArchive && !showManageProvider && (
          <>
            <InstanceList
              instances={disabledInstances}
              fetchLatestETLProvider={fetchLatestETLProvider}
            />
          </>
        )}

        {showBrowse && !showArchive && !showManageProvider ? (
          <ETLBrowse
            etlProviderData={etlProviderData}
            fetchLatestETLProvider={fetchLatestETLProvider}
          />
        ) : (
          !showArchive &&
          !showManageProvider && (
            <InstanceList
              instances={enabledInstances}
              fetchLatestETLProvider={fetchLatestETLProvider}
            />
          )
        )}
      </>
    );
  } else {
    return (
      <ETLBrowse
        etlProviderData={etlProviderData}
        fetchLatestETLProvider={fetchLatestETLProvider}
      />
    );
  }
};

const InstanceList = ({ instances, fetchLatestETLProvider }) => {
  const [showConfirm, setShowConfirm] = useState(false);
  const [instanceToDelete, setInstanceToDelete] = useState(null);

  const [
    { loading: removalLoading, errors: removalErrors, data: removalData },
    remove,
  ] = useApi();

  const toggleInstanceEnabled = ({ id, enabled }) => {
    remove({
      query: updateEtlProviderInstanceEnabledFlag,
      variables: { id: id, newEnabledState: !enabled },
    });
  };

  const columnsData = [
    {
      Header: "Name",
      id: "Name",
      accessor: "name",
      Cell: ({ row: { original } }) => {
        return <InLineTitlePin>{original.name}</InLineTitlePin>;
      },
    },
    {
      Header: "Provider",
      id: "Provider",
      accessor: "provider.displayName",
      Cell: ({ row: { original } }) => {
        return (
          <InLineTitlePin>{original?.provider?.displayName}</InLineTitlePin>
        );
      },
    },
    {
      Header: " ",
      id: "actions",
      width: 250,
      resizable: false,
      sortable: false,
      Cell: ({ row: { original } }) => {
        const enabled = original?.enabled;
        return (
          <div style={{ textAlign: "right" }}>
            <Button
              danger={enabled}
              list="true"
              type="button"
              id={`${original.name}-toggleInstanceArchiveReactivate`}
              data-testid={`${original.name}-toggleInstanceArchiveReactivate`}
              onClick={() =>
                setInstanceToDelete({ id: original.id, enabled: enabled })
              }
            >
              {enabled ? <MdArchive /> : "Reactivate"}
            </Button>
          </div>
        );
      },
    },
  ];

  // Handle remove rule update
  useEffect(() => {
    if (removalData) {
      fetchLatestETLProvider();
    }
  }, [removalData, fetchLatestETLProvider]);

  useEffect(() => {
    if (instanceToDelete) {
      setShowConfirm(true);
    } else {
      setShowConfirm(false);
    }
  }, [instanceToDelete, setShowConfirm]);

  return (
    <>
      {showConfirm ? (
        <Modal
          title={`Confirm Instance ${
            instanceToDelete?.enabled ? "Archival" : "Reactivation"
          }`}
          hide={() => setInstanceToDelete(null)}
        >
          <p>
            Are you sure you wish to{" "}
            {instanceToDelete?.enabled ? "archive" : "reactivate"} this
            instance?
          </p>
          <div>
            <Button
              type="button"
              list="true"
              disabled={removalLoading}
              danger
              onClick={() => {
                setInstanceToDelete(null);
                toggleInstanceEnabled(instanceToDelete);
              }}
            >
              {removalLoading ? <Spinner /> : "Yes"}
            </Button>
            <Button
              type="button"
              disabled={removalLoading}
              onClick={() => {
                setInstanceToDelete(null);
              }}
            >
              Cancel
            </Button>
          </div>
        </Modal>
      ) : null}

      {removalErrors && <ErrorMessages errors={removalErrors} />}
      <SortTable data={instances} columns={columnsData} />
    </>
  );
};

const ETLManager = () => {
  // Query for ETL Configuration
  const query = `
      query($first: Int, $after: String){
        availableEtlProviders(first: $first, after: $after) {
              totalCount
              pageInfo {
                endCursor
                hasNextPage
                hasPreviousPage
                startCursor
              }
              edges {
                cursor
                node {

            id,
            displayName,
            enabled
            instances {
              id,
              name,
              enabled
              provider {
                displayName
              }
            }
          }
        }
      }
    }
  `;

  // Loading the query on component mount for latest configuration
  const [state, fetchLatestETL] = useApi(query);

  const fetchLatestETLProvider = useCallback(() => {
    // Fetch latest ETL Provider here
    fetchLatestETL({ query: query });
  }, [fetchLatestETL, query]);

  // TEMP FETCH AVAILABLE ETL CONNECTION

  const hasETL = state?.data?.availableEtlProviders?.edges?.length > 0;
  if (state.loading)
    return (
      <ProvidersLoading>
        <SplashLoader text="Loading Results" />
      </ProvidersLoading>
    );

  if (!state.loading && state.errors)
    return <ErrorMessages errors={state.errors} />;

  return hasETL ? (
    <Instances
      etlProviderData={state?.data?.availableEtlProviders?.edges?.[0]?.node}
      fetchLatestETLProvider={fetchLatestETLProvider}
    />
  ) : (
    <ETLForm
      etlProviderData={state?.data?.availableEtlProviders?.edges?.[0]?.node}
      fetchLatestETLProvider={fetchLatestETLProvider}
    />
  );
};

export default ETLManager;
