import React from "react";
import styled from "styled-components/macro";

const ButtonContainer = styled.button`
  position: relative;
  white-space: nowrap;
  top: ${(props) => (props.bumpdown ? "2px" : "auto")};
  /* Adapt the colours based on primary prop */
  background: ${(props) =>
    props.primary
      ? props.theme.onSurfaceLight
      : props.danger
      ? props.theme.danger
      : props.theme.onSurfaceLight};
  color: ${(props) => props.theme.onSurfaceLightSecondary};
  cursor: pointer;
  text-transform: uppercase;
  width: ${(props) => (props.span ? "100%" : "auto")};
  margin-right: ${(props) => (props.list ? ".8rem" : "0")};
  padding: 0.1875rem 0.5rem;
  margin-bottom: 0.4rem;
  display: inline-flex;
  align-items: center;
  border: none;
  transition: all ${(props) => props.theme.transitionSpeed} linear;
  min-height: 24px;
  text-decoration: none;
  &:hover {
    background: ${(props) =>
      props.primary
        ? props.theme.surfaceAlt
        : props.danger
        ? props.theme.dangerAlt
        : props.theme.surfaceAlt};
  }
  &:disabled {
    opacity: 0.3;
    cursor: auto;
    background: ${(props) =>
      props.primary
        ? props.theme.onSurfaceLight
        : props.danger
        ? props.theme.danger
        : props.theme.onSurfaceLight};
  }
`;

const ButtonCenter = styled.div`
  width: 100%;
  text-align: center;
`;

const ButtonInner = styled.div`
  display: flex;
  align-items: center;
  font-size: 0.8125rem;
  letter-spacing: 0.35px;
`;

const TableButton = (props) => {
  return props.center ? (
    <ButtonCenter>
      <ButtonContainer {...props}>
        <ButtonInner>{props.children}</ButtonInner>
      </ButtonContainer>
    </ButtonCenter>
  ) : (
    <ButtonContainer {...props}>
      <ButtonInner>{props.children}</ButtonInner>
    </ButtonContainer>
  );
};

export default TableButton;
