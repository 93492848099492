import React from "react";
import {
  ResponsiveContainer,
  Tooltip,
  XAxis,
  Line,
  LineChart,
  CartesianGrid,
  YAxis,
} from "recharts";
import Spinner from "../../../components/Loaders/Spinner";
import { format } from "date-fns";
import styled, { withTheme } from "styled-components";

const StyledToolTip = styled.div`
  background: #555555;
  padding: 0.5em;
`;

const Title = styled.div`
  color: #ffffff;
  font-size: 0.8rem;
`;

const DataSourceWithOpportunitiesHistory = React.memo(
  ({ data, loading, errors }) => {
    //Body Section of Widget
    const historyData =
      (data &&
        data.history &&
        data.history.map((hist) => {
          return {
            ...hist,
            date: new Date(hist.time).getTime(),
          };
        })) ||
      [];

    const sortedHistoryData = historyData
      .slice()
      .sort((a, b) => b.time - a.time);

    if (loading)
      return (
        <div
          style={{
            position: "relative",
            minHeight: "300px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Spinner />
        </div>
      );
    if (errors)
      return (
        <div
          style={{
            position: "relative",
            minHeight: "300px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          Errors
        </div>
      );
    if (!sortedHistoryData.length)
      return (
        <div
          style={{
            position: "relative",
            minHeight: "300px",
            display: "flex",
            alignItems: "center",
          }}
        >
          No Historical Failure Data To Display
        </div>
      );

    function CustomTooltip(props) {
      if (props.active && props.payload && props.payload.length) {
        const { payload } = props;

        const date = payload && payload.length && payload[0].payload.date;

        return (
          <StyledToolTip>
            {payload.map((item, i) => {
              return (
                <div
                  key={`tooltipFailureItem-${i}`}
                  style={{ color: "#ffffff", fontSize: "1.5rem" }}
                >
                  {item.value}
                </div>
              );
            })}

            <Title>{format(date, "MMM dd, HH:mm")}</Title>
          </StyledToolTip>
        );
      } else {
        return null;
      }
    }

    return (
      <div style={{ position: "relative", minHeight: "120px" }}>
        <div
          style={{
            position: "absolute",
            left: 0,
            right: 0,
            bottom: 0,
            top: 0,
          }}
        >
          <div
            style={{
              fontFamily: "Source Sans Pro Semibold",
            }}
          >
            SCORE HISTORY
          </div>
          <ResponsiveContainer width="99.9%" height={100}>
            <LineChart
              data={sortedHistoryData}
              margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                dataKey="date"
                domain={["auto", "auto"]}
                name="Time"
                tickLine={false}
                tickFormatter={(date) => format(date, "MMM dd, HH:mm")}
                type="number"
                fontSize={".8rem"}
                dy={5}
              />
              <YAxis dx={-5} tickLine={false} />
              <YAxis
                dx={5}
                tickLine={false}
                dataKey="score"
                yAxisId="right"
                orientation="right"
              />
              <Tooltip content={<CustomTooltip />} />
              <Line
                type="monotone"
                dataKey="score"
                stroke={"#0092FF"}
                fill={"#0092FF"}
              />
            </LineChart>
          </ResponsiveContainer>
        </div>
      </div>
    );
  }
);

export default withTheme(DataSourceWithOpportunitiesHistory);
