import React from "react";
import Card from "../../components/Card";
import Create from "../../components/Forms/ETL/Create";
import { useParams } from "react-router-dom";
import ErrorMessages from "../../components/Notifications/ErrorMessages";
import { useApi } from "../../api/useApi";
import { workflowById } from "../../api/workflowQueries";
import Spinner from "../../components/Loaders/Spinner";

const EditForm = ({ workflowId }) => {
  //Available Connections Graph
  const [{ loading, errors, data }] = useApi(workflowById, {
    workflowId: Number(workflowId),
  });

  if (loading) return <Spinner />;
  if (errors) return <ErrorMessages errors={errors} />;

  const workflowData = data?.workflowById;
  return <Create workflowId={workflowId} workflowData={workflowData} />;
};

const WorkflowCreate = () => {
  //Body Section of Widget
  function Body() {
    let params = useParams();
    const workflowId = params?.workflowId;

    if (workflowId) {
      return <EditForm workflowId={workflowId} />;
    } else {
      return <Create />;
    }
  }

  return (
    <Card
      title={"Workflows"}
      titleDescription={"Create Data Transit Workflow"}
      body={Body}
    />
  );
};

export default WorkflowCreate;
