import { createContext, useContext } from "react";
import { useEffect, useMemo } from "react";
import { useApi } from "../../api/useApiRedux";
import { AuthContext } from "../../contexts/AuthContext";

export const AiSettingsContext = createContext();

const AiSettingsContextProvider = (props) => {
  const defaultSettings = useMemo(() => {
    return {
      enabled: false,
    };
  }, []);

  const [{ errors, data: settingsResponse }, doFetch] = useApi();

  const { user } = useContext(AuthContext);

  useEffect(() => {
    if (user) {
      doFetch({
        query: "/ai/settings",
        method: "GET",
      });
    }
  }, [user, doFetch]);

  const value = useMemo(() => {
    if (errors) {
      console.error(errors);
    }
    return settingsResponse ?? defaultSettings;
  }, [defaultSettings, settingsResponse, errors]);

  return (
    <AiSettingsContext.Provider value={value}>
      {props.children}
    </AiSettingsContext.Provider>
  );
};

export default AiSettingsContextProvider;
