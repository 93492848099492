// Import ETL Provider Instances
export const importEtlProviderInstances = /* GraphQL */ `
  mutation ($id: Int!, $instances: [EtlProviderInstanceInputModelInput!]!) {
    importEtlProviderInstances(providerId: $id, providerInstances: $instances)
  }
`;

// Enable / Disable ETL Provider Instances
export const updateEtlProviderInstanceEnabledFlag = /* GraphQL */ `
  mutation ($id: Int!, $newEnabledState: Boolean!) {
    updateEtlProviderInstanceEnabledFlag(
      id: $id
      enabledState: $newEnabledState
    ) {
      id
    }
  }
`;

// Enable / Disable ETL Provider Instances
export const updateEtlProviderEnabledFlag = /* GraphQL */ `
  mutation ($id: Int!, $newEnabledState: Boolean!) {
    updateEtlProviderEnabledFlag(id: $id, enabledState: $newEnabledState) {
      id
    }
  }
`;

// Create ETL Prvider
export const createEtlProvider = /* GraphQL */ `
  mutation ($provider: EtlProviderInputModelInput!) {
    createEtlProvider(etlProvider: $provider)
  }
`;

// Update ETL Prvider
export const updateEtlProvider = /* GraphQL */ `
  mutation ($etlProvider: EtlProviderUpdateInputModelInput!) {
    updateEtlProvider(etlProvider: $etlProvider)
  }
`;

// Set Pipeline On DataSource
export const setEtlPipelineOnDataSource = /* GraphQL */ `
  mutation (
    $dataSourceId: Int!
    $providerInstanceId: Int!
    $pipeline: String!
    $createPipeline: Boolean!
  ) {
    setEtlPipelineOnDataSource(
      dataSourceId: $dataSourceId
      providerInstanceId: $providerInstanceId
      pipeline: $pipeline
      createPipeline: $createPipeline
    )
  }
`;

// Create a Pipeline
export const createEtlPipeline = /* GraphQL */ `
  mutation (
    $providerInstanceId: Int!
    $pipeline: String!
    $createPipeline: Boolean!
  ) {
    createEtlPipeline(
      providerInstanceId: $providerInstanceId
      pipeline: $pipeline
      createPipeline: $createPipeline
    )
  }
`;

// Run ETL
export const runEtlPipeline = /* GraphQL */ `
  mutation ($pipelineName: String!) {
    runEtlPipeline(pipelineName: $pipelineName)
  }
`;

// Run ETL on a DS *LEGACY*
export const runEtlPipelineOnDataSource = /* GraphQL */ `
  mutation ($refreshSummaryId: UUID!, $pipelineId: Int!) {
    runEtlPipeline(refreshSummaryId: $refreshSummaryId, pipelineId: $pipelineId)
  }
`;

// Create a full Workflow
export const createWorkflow = /* GraphQL */ `
  mutation ($workflow: WorkflowModelInput!) {
    createWorkflow(workflow: $workflow, providerInstanceId: $providerInstanceId)
  }
`;

export const updateWorkflow = /* GraphQL */ `
  mutation ($workflow: WorkflowModelInput!) {
    updateWorkflow(workflow: $workflow, providerInstanceId: $providerInstanceId)
  }
`;

// Set workflow enabled flag
export const setWorkflowEnabledFlag = /* GraphQL */ `
  mutation ($workflowId: Int!, $enabled: Boolean!) {
    setWorkflowEnabledFlag(id: $workflowId, enabled: $enabled) {
      id
    }
  }
`;

// Create Doc Provider
export const createDocProvider = /* GraphQL */ `
  mutation ($docProviderInput: DocProviderInputModelInput!) {
    createDocProvider(docProviderInput: $docProviderInput)
  }
`;

// Update Doc Provider
export const updateDocProvider = /* GraphQL */ `
  mutation (
    $docProviderId: Int!
    $docProviderInput: DocProviderInputModelInput!
  ) {
    updateDocProvider(
      docProviderId: $docProviderId
      docProviderInput: $docProviderInput
    )
  }
`;
