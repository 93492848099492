import React, { useEffect } from "react";
import {
  ResponsiveContainer,
  Tooltip,
  XAxis,
  Line,
  LineChart,
  CartesianGrid,
  YAxis,
  Legend,
} from "recharts";
import AbsoluteHeadingCard from "../../Card/AbsoluteHeadingCard";
import SplashLoader from "../../../components/Loaders/SplashLoader";
import { format } from "date-fns";
import styled, { withTheme } from "styled-components";
import { useApi } from "../../../api/useApi";
import {
  ruleInstanceHistory,
  ruleInstanceHistoryLatestReport,
} from "../../../api/dataSourceQueries";
import ErrorMessages from "../../../components/Notifications/ErrorMessages";

const StyledToolTip = styled.div`
  background: #555555;
  padding: 1em;
`;

const Title = styled.div`
  color: #ffffff;
  font-size: 0.8rem;
`;

const LegendContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 50px;
  text-align: center;
  left: 0px;
  top: 10px;
`;

const LegendIcon = ({ color }) => {
  return (
    <>
      <div
        style={{
          backgroundColor: color,
          display: "block",
          width: "1rem",
          marginRight: ".2rem",
          height: ".5rem",
          borderRadius: ".5rem",
        }}
      />
    </>
  );
};

const InstanceHistory = React.memo(
  ({ barData, loading, reportId, sourceId, dataSourceName }) => {
    const sourceName = dataSourceName ?? null;

    const failedRuleInstanceVersionId =
      barData?.bar?.failedRuleInstanceVersionId;

    const failedBusinessRuleInstancePriority =
      barData?.bar?.failedBusinessRuleInstancePriority;

    const [{ loading: historyLoading, errors, data }, updateReport] = useApi(
      reportId ? ruleInstanceHistory : ruleInstanceHistoryLatestReport,
      reportId
        ? {
            id: sourceId,
            reportId: reportId,
            instanceVersionId: failedRuleInstanceVersionId,
          }
        : {
            id: sourceId,
            instanceVersionId: failedRuleInstanceVersionId,
          }
    );

    useEffect(() => {
      updateReport({
        query: reportId ? ruleInstanceHistory : ruleInstanceHistoryLatestReport,
        variables: reportId
          ? {
              id: sourceId,
              reportId: reportId,
              instanceVersionId: failedRuleInstanceVersionId,
            }
          : {
              id: sourceId,
              instanceVersionId: failedRuleInstanceVersionId,
            },
      });
    }, [
      barData,
      updateReport,
      failedRuleInstanceVersionId,
      reportId,
      sourceId,
    ]);

    const ruleInstanceData = reportId
      ? data?.dataSource?.reportById?.ruleInstanceHistory ?? data?.dataSource?.reportByIdWithAlert?.ruleInstanceHistory
      : data?.dataSource?.latestReport?.ruleInstanceHistory;
    const ruleInstanceName = ruleInstanceData?.ruleInstanceName;
    const ruleInstanceMetrics = ruleInstanceData?.metrics ?? [];
    let fillColor;
    switch (failedBusinessRuleInstancePriority) {
      case "HIGH":
        fillColor = "#008CF6";
        break;
      case "NORMAL":
        fillColor = "#6ABCFA";
        break;
      case "LOW":
        fillColor = "#A9D8FC";
        break;
      default:
        fillColor = "#008CF6";
        break;
    }

    //Body Section of Widget
    function Body() {
      if (!barData) return <div>No Data Available</div>;
      if (loading || historyLoading)
        return (
          <div style={{ height: "300px" }}>
            <SplashLoader text="Loading Historical Data" />
          </div>
        );

      const historyData =
        ruleInstanceMetrics.map((metric) => {
          return {
            failures: metric?.value,
            date: metric?.key,
          };
        }) ?? [];

      const sortedHistoryData = historyData
        .slice()
        .sort((a, b) => b.date - a.date);

      if (errors) return <ErrorMessages errors={errors} />;
      if (!sortedHistoryData.length)
        return (
          <div
            style={{
              position: "relative",
              minHeight: "300px",
              display: "flex",
              alignItems: "center",
            }}
          >
            No Historical Failure Data To Display
          </div>
        );

      function CustomTooltip(props) {
        if (props.active && props.payload && props.payload.length) {
          const { payload } = props;

          const date = payload && payload.length && payload[0].payload.date;

          return (
            <StyledToolTip>
              {payload.map((item, i) => {
                return (
                  <div
                    key={`tooltipFailureItem-${i}`}
                    style={{ color: "#ffffff", fontSize: "2rem" }}
                  >
                    {item.value}
                  </div>
                );
              })}

              <Title>{format(date, "MMM dd, HH:mm")}</Title>
            </StyledToolTip>
          );
        } else {
          return null;
        }
      }

      const renderLegend = () => {
        return (
          <LegendContainer>
            <div
              style={{
                display: "flex",
                marginRight: "1rem",
                alignItems: "center",
                marginBottom: "1rem",
                justifyContent: "center",
              }}
            >
              <LegendIcon color={fillColor} /> <div>{ruleInstanceName}</div>
            </div>
          </LegendContainer>
        );
      };

      return (
        <div style={{ position: "relative" }}>
          <ResponsiveContainer width="99.9%" height={300}>
            <LineChart
              data={sortedHistoryData}
              margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
            >
              <Legend height={50} verticalAlign="top" content={renderLegend} />
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                dataKey="date"
                domain={["auto", "auto"]}
                name="Time"
                tickLine={false}
                tickFormatter={(date) => format(date, "MMM dd, HH:mm")}
                type="number"
                fontSize={".8rem"}
                dy={5}
              />
              <YAxis dx={-5} tickLine={false} />
              <YAxis
                dx={5}
                tickLine={false}
                dataKey="failures"
                yAxisId="right"
                orientation="right"
              />
              <Tooltip content={<CustomTooltip />} />
              <Line
                type="monotone"
                dataKey="failures"
                stroke={"#0092FF"}
                fill={"#0092FF"}
              />
            </LineChart>
          </ResponsiveContainer>
        </div>
      );
    }

    return (
      <AbsoluteHeadingCard
        title={"Historical Trends"}
        titleDescription={sourceName}
        // actions={Actions}
        body={Body}
        active={"topRight"}
      />
    );
  }
);

export default withTheme(InstanceHistory);
