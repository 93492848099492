import React, { useState, useEffect, useMemo, useContext } from "react";
import { useApi } from "../../api/useApi";
import {
  usersInEnabledWorkGroups,
  availableDataSourcesForUserId,
} from "../../api/userQueries";
import { useApi as useApiRedux } from "../../api/useApiRedux";
import PagedTable from "../../components/Table/PagedTable";
import {
  StyledSelect,
  Label,
  FormControl,
  StyledInput,
} from "../../components/Form/FormControls";
import { setUsersRole } from "../../api/userMutations";
import update from "immutability-helper";
import { availableOrganizations } from "../../api/organizationQueries";
import debounce from "lodash.debounce";
import PagedTableRedux from "../../components/Table/PagedTableRedux";
import ErrorMessages from "../../components/Notifications/ErrorMessages";
import { formatTime } from "../../common/formats";
import Modal from "../../components/Modal";
import TableButton from "../../components/Button/TableButton";
import { AuthContext } from "../../contexts/AuthContext";
import SpinningLoader from "../../components/Loaders/SpinningLoader";
import SortTable from "../../components/Table/SortTable";
import Button from "../../components/Button/index";
import Spinner from "../../components/Loaders/Spinner";

// availableDataSourcesForUserId
const ViewUsersDataSourcePermission = ({ viewUser }) => {
  const { user } = useContext(AuthContext);

  const [{ loading, data: apiData }, doFetch] = useApi();

  useEffect(() => {
    if (viewUser && user) {
      doFetch({
        query: availableDataSourcesForUserId,
        variables: {
          userId: Number(viewUser?.id),
          first: 10000,
          where: {
            enabled: { eq: true },
          },
        },
      });
    }
  }, [viewUser, user, doFetch]);

  const data =
    apiData?.availableDataSourcesForUserId?.edges?.filter(
      (source) => source?.node?.id !== -2
    ) ?? [];

  const permissionRoles = [
    { label: "Full", value: 15 },
    { label: "Elevated", value: 7 },
    { label: "Read Only", value: 1 },
    { label: "None", value: 0 },
  ];
  const columnsData = [
    {
      Header: "Name",
      id: "name",
      accessor: (d) => d?.node?.name,
    },
    {
      Header: "Permission",
      id: "permission",
      Cell: ({ row: { original } }) => {
        return (
          <div>
            {original?.node?.permissionsForUser?.map((perm) => {
              return (
                <div>
                  <div>
                    Group: {perm?.accessingWorkGroup?.displayName ?? "Admin"}
                  </div>
                  <div>
                    Permission:{" "}
                    {
                      permissionRoles.find(
                        (pr) => pr?.value === perm?.permissionCode
                      )?.label
                    }
                  </div>
                </div>
              );
            })}
            {!original?.node?.permissionsForUser?.length ? (
              <div>
                <div>Admin Role</div>
                <div>Permission: Full</div>
              </div>
            ) : null}
          </div>
        );
      },
    },
  ];

  if (loading)
    return <SpinningLoader text="Loading Users Source Permissions" />;
  return (
    <div>
      <div>
        <div>{viewUser?.displayName}</div>
        <div>{viewUser?.emailAddress}</div>
      </div>

      <SortTable
        dontReset={true}
        data={data}
        columns={columnsData}
        loading={loading}
        hidePagination={true}
        defaultPageSize={9999}
      />
    </div>
  );
};

const StaticUsersSection = () => {
  const [selectedOrg, setSelectedOrg] = useState({
    label: "Entire Platform",
    value: "Entire Platform",
  });

  return (
    <>
      <OrgSelection selectedOrg={selectedOrg} setSelectedOrg={setSelectedOrg} />
      <ManageUsers selectedOrg={selectedOrg} />
    </>
  );
};

const roles = [
  { label: "None", value: "NONE" },
  { label: "Regular User", value: "REGULAR_USER" },
  { label: "Supervisor", value: "SUPERVISOR" },
  { label: "Administrator", value: "ADMINISTRATOR" },
];

const rolesIntEnum = [
  { label: "Administrator", value: 3 },
  { label: "Supervisor", value: 2 },
  { label: "Regular User", value: 1 },
  { label: "None", value: 0 },
];

const initialStateUserOrgs = [
  { label: "Entire Platform", value: "Entire Platform" },
];

const OrgSelection = ({ selectedOrg, setSelectedOrg }) => {
  const [userOrgOptions, setUserOrgOptions] = useState(initialStateUserOrgs);
  //TODO: Get max 250 orgs until we have a solution for selecting these options with large lists
  //Available Users Count
  const [{ data }] = useApi(availableOrganizations);

  useEffect(() => {
    if (data) {
      const enabledOrganizations = data?.availableOrganizations?.edges
        .filter((wg) => wg?.node?.enabled)
        .map((wg) => {
          return { label: wg?.node?.displayName, value: wg?.node?.id };
        });

      setUserOrgOptions((prevState) => [...prevState, ...enabledOrganizations]);
    }
  }, [data]);

  return (
    <>
      <FormControl>
        <Label>Filter Users By Organization</Label>
        <StyledSelect
          className={`react-select-container`}
          classNamePrefix={`react-select`}
          menuPortalTarget={document.body}
          name={`Org-Select`}
          id={`Org-Select`}
          inputId={`Org-Select-input`}
          instanceId={`Org-Select-instance`}
          options={userOrgOptions}
          onChange={(e) => setSelectedOrg(e)}
          value={selectedOrg}
        />
      </FormControl>
    </>
  );
};

const UsersSelected = () => {
  const [userData, setUserData] = useState([]);
  const [showUserSourcePermissions, setShowUserSourcePermissions] = useState();
  //TODO: Role UPDATE API is Broken currently, UI needs to indicate Loading and Completed Change
  const [{ errors: roleError, data: roleUpdateData }, doUpdateRole] = useApi();

  const updateRole = React.useCallback(
    (updateRoleEvent, userId) => {
      const updateUserRole = { id: userId, role: updateRoleEvent.value };
      const rolesToUpdate = updateUserRole;

      const variables = {
        userAccount: rolesToUpdate,
      };

      doUpdateRole({ query: setUsersRole, variables });
    },
    [doUpdateRole]
  );
  useEffect(() => {
    if (roleUpdateData && !roleError) {
      const affectedUser = roleUpdateData?.setUsersRole;

      setUserData((prevUsers) => {
        const userIndex = prevUsers.findIndex(
          (user) => user?.node?.id === affectedUser.id
        );

        if (userIndex !== -1) {
          const newUsers = update(prevUsers, {
            [userIndex]: { node: { $merge: { role: affectedUser.role } } },
          });
          return newUsers;
        } else {
          return prevUsers;
        }
      });
    }
  }, [roleUpdateData, setUserData, roleError]);

  const columnsData = [
    {
      Header: "Name",
      id: "name",
      accessor: (d) => d?.node?.displayName,
    },
    {
      Header: "Email",
      id: "emailAddress",
      accessor: (d) => d?.node?.emailAddress,
      Cell: ({ row: { original } }) => {
        return (
          <div
            style={{
              maxWidth: "300px",
              textOverflow: "ellipsis",
              overflow: "hidden",
            }}
            title={original?.node?.emailAddress}
          >
            {original?.node?.emailAddress}
          </div>
        );
      },
    },
    {
      Header: "Last Sign On Date",
      id: "lastSignOnDate",
      accessor: (d) => d?.node?.lastSignOnDate,
      Cell: ({ row: { original } }) => {
        return (
          original?.node?.lastSignOnDate &&
          formatTime(new Date(original?.node?.lastSignOnDate))
        );
      },
    },
    {
      Header: "Source Permissions",
      id: "sourcePermissions",
      Cell: ({ row: { original } }) => {
        return (
          <>
            <TableButton
              type="button"
              onClick={() =>
                setShowUserSourcePermissions({
                  id: original?.node?.id,
                  displayName: original?.node?.displayName,
                  emailAddress: original?.node?.emailAddress,
                })
              }
            >
              Source Permissions
            </TableButton>
          </>
        );
      },
    },
    {
      Header: () => (
        <div
          style={{
            minWidth: "165px",
            maxWidth: "165px",
          }}
        >
          Role
        </div>
      ),
      id: "role",
      style: {
        minWidth: "165px",
      },
      Cell: ({ row: { original } }) => {
        return (
          <StyledSelect
            className={`react-select-container`}
            classNamePrefix={`react-select`}
            menuPortalTarget={document.body}
            name={`${original?.node?.emailAddress}-role`}
            id={`${original?.node?.emailAddress}-role`}
            inputId={`${original?.node?.emailAddress}-role-input`}
            instanceId={`${original?.node?.emailAddress}-role-instance`}
            options={roles}
            onChange={(e) => updateRole(e, original?.node?.id)}
            placeholder={original?.node?.role}
            value={roles.find((role) => role.value === original?.node?.role)}
          />
        );
      },
    },
  ];

  //Init Data Fetch
  const [{ loading, data: apiData }, doFetch] = useApi();

  const totalCount = apiData?.usersInEnabledWorkGroups?.totalCount;
  const pageInfo = apiData?.usersInEnabledWorkGroups?.pageInfo;

  //Fetch for Table Paged
  const fetchData = React.useCallback(
    ({ pageSize, cursor }) => {
      doFetch({
        query: usersInEnabledWorkGroups,
        variables: {
          first: pageSize,
          after: cursor,
          order: {
            displayName: "ASC",
          },
        },
      });
    },
    [doFetch]
  );

  useEffect(() => {
    if (apiData) {
      const availUsers = apiData?.usersInEnabledWorkGroups?.edges ?? [];
      setUserData(availUsers);
    }
  }, [apiData]);

  // useEffect(() => {
  //   if (selectedOrg) {
  //     setUserData([]);
  //   }
  // }, [selectedOrg]);

  return (
    <>
      {showUserSourcePermissions ? (
        <Modal
          title={"View Source Permissions"}
          hide={() => setShowUserSourcePermissions()}
        >
          <ViewUsersDataSourcePermission
            viewUser={showUserSourcePermissions}
            setShowUserSourcePermissions={setShowUserSourcePermissions}
          />
        </Modal>
      ) : null}

      <PagedTable
        fetchData={fetchData}
        loading={loading}
        pageInfo={pageInfo}
        totalCount={totalCount}
        data={userData}
        columns={columnsData}
        defaultPageSize={50}
      />
    </>
  );
};

const OrgUsers = ({ selectedOrg }) => {
  const [userData, setUserData] = useState([]);
  const [showUserSourcePermissions, setShowUserSourcePermissions] = useState();
  //TODO: Role UPDATE API is Broken currently, UI needs to indicate Loading and Completed Change
  const [{ errors: roleError, data: roleUpdateData }, doUpdateRole] = useApi();

  const updateRole = React.useCallback(
    (updateRoleEvent, userId) => {
      const updateUserRole = { id: userId, role: updateRoleEvent.value };
      const rolesToUpdate = updateUserRole;

      const variables = {
        userAccount: rolesToUpdate,
      };

      doUpdateRole({ query: setUsersRole, variables });
    },
    [doUpdateRole]
  );

  useEffect(() => {
    if (roleUpdateData && !roleError) {
      const affectedUser = roleUpdateData?.setUsersRole;

      setUserData((prevUsers) => {
        const userIndex = prevUsers.findIndex(
          (user) => user?.node?.id === affectedUser.id
        );

        if (userIndex !== -1) {
          const newUsers = update(prevUsers, {
            [userIndex]: { node: { $merge: { role: affectedUser.role } } },
          });
          return newUsers;
        } else {
          return prevUsers;
        }
      });
    }
  }, [roleUpdateData, setUserData, roleError]);

  const columnsData = [
    {
      Header: "Name",
      id: "name",
      accessor: (d) => d?.node?.displayName,
    },
    {
      Header: "Email",
      id: "emailAddress",
      accessor: (d) => d?.node?.emailAddress,
      Cell: ({ row: { original } }) => {
        return (
          <div
            style={{
              maxWidth: "300px",
              textOverflow: "ellipsis",
              overflow: "hidden",
            }}
            title={original?.node?.emailAddress}
          >
            {original?.node?.emailAddress}
          </div>
        );
      },
    },
    {
      Header: "Last Sign On Date",
      id: "lastSignOnDate",
      accessor: (d) => d?.node?.lastSignOnDate,
      Cell: ({ row: { original } }) => {
        return (
          original?.node?.lastSignOnDate &&
          formatTime(new Date(original?.node?.lastSignOnDate))
        );
      },
    },
    {
      Header: "Source Permissions",
      id: "sourcePermissions",
      Cell: ({ row: { original } }) => {
        return (
          <>
            <TableButton
              type="button"
              onClick={() =>
                setShowUserSourcePermissions({
                  id: original?.node?.id,
                  displayName: original?.node?.displayName,
                  emailAddress: original?.node?.emailAddress,
                })
              }
            >
              Source Permissions
            </TableButton>
          </>
        );
      },
    },
    {
      Header: () => (
        <div
          style={{
            minWidth: "165px",
            maxWidth: "165px",
          }}
        >
          Role
        </div>
      ),
      id: "role",
      Cell: ({ row: { original } }) => {
        return (
          <StyledSelect
            className={`react-select-container`}
            classNamePrefix={`react-select`}
            menuPortalTarget={document.body}
            name={`${original?.node?.emailAddress}-role`}
            id={`${original?.node?.emailAddress}-role`}
            inputId={`${original?.node?.emailAddress}-role-input`}
            instanceId={`${original?.node?.emailAddress}-role-instance`}
            options={roles}
            onChange={(e) => updateRole(e, original?.node?.id)}
            placeholder={original?.node?.role}
            value={roles.find((role) => role.value === original?.node?.role)}
          />
        );
      },
    },
  ];

  //Init Data Fetch
  const [{ loading, data: apiData }, doFetch] = useApi();

  const totalCount = apiData?.usersInEnabledWorkGroups?.totalCount;
  const pageInfo = apiData?.usersInEnabledWorkGroups?.pageInfo;

  //Fetch for Table Paged
  const fetchData = React.useCallback(
    ({ pageSize, cursor }) => {
      doFetch({
        query: usersInEnabledWorkGroups,
        variables: {
          first: pageSize,
          after: cursor,
          where: {
            organizationId: {
              eq: selectedOrg?.value,
            },
          },
          order: {
            displayName: "ASC",
          },
        },
      });
    },
    [doFetch, selectedOrg]
  );

  useEffect(() => {
    if (apiData) {
      const availUsers = apiData?.usersInEnabledWorkGroups?.edges ?? [];
      setUserData(availUsers);
    }
  }, [apiData]);

  return (
    <>
      {showUserSourcePermissions ? (
        <Modal
          title={"View Source Permissions"}
          hide={() => setShowUserSourcePermissions()}
        >
          <ViewUsersDataSourcePermission
            viewUser={showUserSourcePermissions}
            setShowUserSourcePermissions={setShowUserSourcePermissions}
          />
        </Modal>
      ) : null}

      <PagedTable
        fetchData={fetchData}
        loading={loading}
        pageInfo={pageInfo}
        totalCount={totalCount}
        data={userData}
        columns={columnsData}
        defaultPageSize={50}
      />
    </>
  );
};

const ManageUsers = ({ selectedOrg }) => {
  if (selectedOrg?.value === "Entire Platform") {
    return (
      <>
        <UsersSelected />
      </>
    );
  } else {
    return (
      <>
        <OrgUsers
          key={`OrgID${selectedOrg?.value}`}
          selectedOrg={selectedOrg}
        />
      </>
    );
  }
};

const SearchUsers = ({ SetIsSearching }) => {
  const [userData, setUserData] = useState([]);

  //TODO: Role UPDATE API is Broken currently, UI needs to indicate Loading and Completed Change
  const [{ errors: roleError, data: roleUpdateData }, doUpdateRole] = useApi();

  const updateRole = React.useCallback(
    (updateRoleEvent, userId) => {
      const updateUserRole = {
        id: userId,
        role: roles[updateRoleEvent.value]?.value,
      };
      const rolesToUpdate = updateUserRole;

      const variables = {
        userAccount: rolesToUpdate,
      };

      doUpdateRole({ query: setUsersRole, variables });
    },
    [doUpdateRole]
  );

  useEffect(() => {
    if (roleUpdateData && !roleError) {
      const affectedUser = roleUpdateData?.setUsersRole;

      setUserData((prevUsers) => {
        const userIndex = prevUsers.findIndex(
          (user) => user?.id === affectedUser.id
        );

        if (userIndex !== -1) {
          const newUsers = update(prevUsers, {
            [userIndex]: { $merge: { role: affectedUser.role } },
          });
          return newUsers;
        } else {
          return prevUsers;
        }
      });
    }
  }, [roleUpdateData, setUserData, roleError]);

  const [
    { errors: getUserErrors, loading: loadingUsers, data: usersDataFetch },
    getUsers,
  ] = useApiRedux();

  const [searchText, setSearchText] = React.useState("");
  const [inputValue, setInputValue] = useState("");
  const [showUserSourcePermissions, setShowUserSourcePermissions] = useState();

  const debouncedSave = useMemo(
    () => debounce((newValue) => setSearchText(newValue), 1000),
    []
  );

  const updateValue = (newValue) => {
    setInputValue(newValue);
    debouncedSave(newValue);
  };

  //Fetch for Table Paged
  const fetchData = React.useCallback(
    ({ params }) => {
      getUsers({
        query: `/search/users`,
        params: { query: searchText, ...params },
        method: "GET",
      });
    },
    [getUsers, searchText]
  );

  useEffect(() => {
    if (searchText) {
      SetIsSearching(true);
    } else {
      SetIsSearching(false);
    }
  }, [searchText, SetIsSearching]);

  const totalCount = usersDataFetch?.metadata?.availableItems;

  const columnsData = [
    {
      Header: "Name",
      id: "name",
      accessor: (d) => d?.displayName,
    },
    {
      Header: "Email",
      id: "emailAddress",
      accessor: (d) => d?.emailAddress,
      Cell: ({ row: { original } }) => {
        return (
          <div
            style={{
              maxWidth: "300px",
              textOverflow: "ellipsis",
              overflow: "hidden",
            }}
            title={original?.emailAddress}
          >
            {original?.emailAddress}
          </div>
        );
      },
    },
    {
      Header: "Last Sign On Date",
      id: "lastSignOnDate",
      accessor: (d) => d?.lastSignOnDate,
      Cell: ({ row: { original } }) => {
        return (
          original?.lastSignOnDate &&
          formatTime(new Date(original?.lastSignOnDate))
        );
      },
    },
    {
      Header: "Source Permissions",
      id: "sourcePermissions",
      Cell: ({ row: { original } }) => {
        return (
          <>
            <TableButton
              type="button"
              onClick={() =>
                setShowUserSourcePermissions({
                  id: original?.id,
                  displayName: original?.displayName,
                  emailAddress: original?.emailAddress,
                })
              }
            >
              Source Permissions
            </TableButton>
          </>
        );
      },
    },
    {
      Header: () => (
        <div
          style={{
            minWidth: "165px",
            maxWidth: "165px",
          }}
        >
          Role
        </div>
      ),
      id: "roles",

      Cell: ({ row: { original } }) => {
        return (
          <StyledSelect
            className={`react-select-container`}
            classNamePrefix={`react-select`}
            menuPortalTarget={document.body}
            name={`${original?.emailAddress}-role`}
            id={`${original?.emailAddress}-role`}
            inputId={`${original?.emailAddress}-role-input`}
            instanceId={`${original?.emailAddress}-role-instance`}
            options={rolesIntEnum}
            onChange={(e) => updateRole(e, original?.id)}
            placeholder={original?.role}
            value={rolesIntEnum.find((role) => role.value === original?.role)}
          />
        );
      },
    },
  ];
  const [viewState] = useState({
    page: 1,
    size: 25,
  });

  useEffect(() => {
    if (usersDataFetch) {
      const availUsers = usersDataFetch?.data ?? [];
      setUserData(availUsers);
    }
  }, [usersDataFetch]);

  return (
    <>
      {showUserSourcePermissions ? (
        <Modal
          title={"View Source Permissions"}
          hide={() => setShowUserSourcePermissions()}
        >
          <ViewUsersDataSourcePermission
            viewUser={showUserSourcePermissions}
            setShowUserSourcePermissions={setShowUserSourcePermissions}
          />
        </Modal>
      ) : null}

      <FormControl>
        <StyledInput
          type="text"
          name="searchUsers"
          label="Search Users"
          placeholder={`Search by Name or Email`}
          value={inputValue}
          onChange={(input) => updateValue(input.target.value)}
        />

        {getUserErrors ? <ErrorMessages errors={getUserErrors} /> : null}
      </FormControl>
      {inputValue ? (
        <PagedTableRedux
          key={"viewState" + JSON.stringify(viewState)}
          fetchData={fetchData}
          totalCount={totalCount}
          loading={loadingUsers}
          data={userData}
          columns={columnsData}
          noMargin={true}
          defaultPageSize={50}
          initParams={viewState}
        />
      ) : null}
    </>
  );
};

const ExportDataSourcePermissions = () => {
    const [{ errors, loading }, doFetch ] = useApiRedux();
    const exportDataSourcePermissions = () => {
        doFetch({
            query: '/datasources/permissions',
            method: 'GET'
        })
    }
    return (
        <div style={{ marginBottom: "16px" }}>
            {loading
                ? <Spinner />
                : (
                    <Button
                        width="250px"
                        onClick={exportDataSourcePermissions}
                        type="button"
                        list="true"
                        disabled={loading}
                    >
                        Export All Data Source Permissions
                    </Button>
                )
            }
            {errors && <ErrorMessages errors={errors} />}
        </div>
    )
}

const ManageUsersPage = () => {
  const [isSearching, SetIsSearching] = useState();
  return (
      <>
        <ExportDataSourcePermissions />
        <SearchUsers SetIsSearching={SetIsSearching} />
        {!isSearching ? <StaticUsersSection key={"StaticKeyRender"} /> : null}
    </>
  );
};

export default ManageUsersPage;
