import React, {
  useCallback,
  useState,
  useEffect,
  useContext,
  useRef,
} from "react";
import Button from "../../Button";
import { Link } from "react-router-dom";
import { useApi } from "../../../api/useApi";
import {
  dataSourceReport,
  manageDataSource,
  dataConnectionsSources,
  dataSourceReports,
  dataSource,
} from "../../../common/paths";
import StyledLink from "../../StyledLink";
import { withRouter } from "react-router-dom";
import PagedTable from "../../Table/PagedTable";
import { availableDataSourcesBare } from "../../../api/dataSourceQueries";
import columnSort from "../../Table/helpers/columnSort";
import {
  startDataSourceIngress,
  endRefreshSummary,
} from "../../../api/dataSourceMutations";
import ErrorMessages from "../../Notifications/ErrorMessages";
import { MdArchive, MdHistory, MdWarning } from "react-icons/md";
import Modal from "../../Modal";
import { setDataSourceEnabledFlag } from "../../../api/dataSourceMutations";
import Spinner from "../../Loaders/Spinner";
import { AuthContext } from "../../../contexts/AuthContext";
import TableButton from "../../../components/Button/TableButton";
import { failureEnums } from "../../../common/failureEnums";
import { formatTime } from "../../../common/formats";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import RenderPriority from "../../../common/RenderPriority";
import useDataSourceNotifications from "../../../Hooks/useDataSourceNotifications";
import { useHistory } from "react-router-dom";
import SearchDataSources from "./SearchDataSources";
import { InLineTitlePin } from "../../../components/Table/elements";
import PinButton from "../../Pin/PinButton";
import TableActions from "../../Table/TableActions";
import FilterByTags from "../../Tags/FilterByTags";
import DataSourceProcessing from "./DataSourceProcessing";
import { canUseBCA } from "../../../common/helpers/auth";
import { MdKey } from "react-icons/md";

const useIsRunning = (original) => {
  const [isRunning, setIsRunning] = useState(
      original?.node?.latestRefreshSummaryStatus === "NONE" ||
          original?.node?.latestRefreshSummaryStatus === "REFERENCE_REPORT_QUEUED"
      ? true
      : false
  );

  const { lastDataSourceNotification } = useDataSourceNotifications();

  useEffect(() => {
    const payload = lastDataSourceNotification?.payload;
    if (
      payload?.AlertType === 11 &&
      payload?.SourceId === original?.node?.id &&
      payload?.Status === 2
    ) {
      setIsRunning(true);
    } else if (
      payload?.AlertType === 11 &&
      payload?.SourceId === original?.node?.id &&
      payload?.Status === 1
    ) {
      setIsRunning(false);
    }
  }, [original, lastDataSourceNotification, setIsRunning]);

  return isRunning;
};

const Updated = ({ original }) => {
    const [resultState, setResultState] = useState(original?.node?.latestRefreshSummaryStatus);
  const { lastDataSourceNotification, setLastDataSourceNotification } =
    useDataSourceNotifications();

  useEffect(() => {
    const resultStateEnums = [
      { value: -7, name: "User_Cancelled" },
      { value: -5, name: "Connection_Failure" },
      { value: -4, name: "Cancelled" },
      {
        value: -3,
        name: "Invalid_Rule_Mapping",
      },
      {
        value: -2,
        name: "Ingress_Source_Not_Found",
      },
      {
        value: -1,
        name: "Unexpected_Failure",
      },
      { value: 0, name: "None" },
      { value: 1, name: "Ready" },
      { value: 100, name: "Reference_Report_Queued" },
      { value: 101, name: "Reference_Report_Ready" },
      {
        value: 2,
        name: "Report_Ready_Incomplete",
      },
      {
        value: 127,
        name: "Regenerating_Cached_Report",
      },
    ];

    const payload = lastDataSourceNotification?.payload;
    if (
      payload?.AlertType === 11 &&
      payload?.SourceId === original?.node?.id &&
      payload?.Status === 3
    ) {
      setResultState("NONE");
    } else if (
      payload?.AlertType === 11 &&
      payload?.SourceId === original?.node?.id &&
      payload?.Status === 1 &&
      payload?.ResultState === 1
    ) {
      setResultState("ViewUpdated");
    } else if (
      payload?.AlertType === 11 &&
      payload?.SourceId === original?.node?.id &&
      payload?.Status === 1 &&
      payload?.ResultState !== 1
    ) {
      const stateName = resultStateEnums.find(
        (rse) => rse?.value === payload?.ResultState
      )?.name;

      setResultState(stateName ?? "GENERIC_FAILURE");
    }
  }, [original, lastDataSourceNotification, setResultState]);

  let history = useHistory();
  const goToDataSource = () => {
    setLastDataSourceNotification(null);
    setResultState(original?.node?.latestRefreshSummaryStatus);

    history.push(dataSource(original?.node?.id));
  };

  const ruleInstancesCount = original?.node?.ruleInstancesCount;

  if (resultState === "ViewUpdated") {
    return (
      <div
        style={{
          cursor: "pointer",
          color: " #007ea8",
          fontFamily: "Source Sans Pro Semibold",
          display: "block",
          fontSize: "0.875rem",
        }}
        onClick={() => goToDataSource()}
        data-testid="refreshLink"
      >
        View Updated
      </div>
    );
  } else if (
    resultState === "USER_CANCELLED" ||
    resultState === "User Cancelled"
  ) {
    return (
      <div
        style={{ display: "flex", alignItems: "center", fontSize: "0.875rem" }}
      >
        User Cancelled
      </div>
    );
  } else if (resultState === "GENERIC_FAILURE") {
    return (
      (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            fontSize: "0.875rem",
          }}
        >
          {original?.node?.latestReport?.timestamp
            ? formatTime(new Date(original?.node?.latestReport?.timestamp))
            : null}
          <div
            style={{
              display: "flex",
              alignItems: "center",
              top: "3px",
              marginLeft: ".5rem",
              position: "relative",
            }}
          >
            <MdWarning style={{ color: "red" }} />
          </div>
        </div>
      ) ?? "N/A"
    );
  } else if (resultState === "NONE" && ruleInstancesCount) {
    return (
      <div
        style={{ display: "flex", alignItems: "center", fontSize: "0.875rem" }}
      >
        In Progress <Spinner style={{ marginLeft: ".5rem" }} />
      </div>
    );
  } else if (resultState && ruleInstancesCount) {
    if (
      resultState === "REPORT_READY" ||
      resultState === "REFERENCE_REPORT_READY"
    ) {
      return original?.node?.latestReport?.timestamp ? (
        <div style={{ fontSize: "0.875rem" }}>
          {formatTime(new Date(original?.node?.latestReport?.timestamp))}
        </div>
      ) : (
        "N/A"
      );
    } else {
      return (
        <div style={{ fontSize: "0.875rem" }}>
          {failureEnums.find(
            (fe) => fe.value.toLowerCase() === resultState.toLowerCase()
          )?.label ?? "N/A"}
        </div>
      );
    }
  } else {
    if (
      resultState === "REFERENCE_REPORT_READY" ||
      resultState === "REFERENCE_REPORT_QUEUED"
    ) {
      return original?.node?.latestReport?.timestamp ? (
        <div style={{ fontSize: "0.875rem" }}>
          {formatTime(new Date(original?.node?.latestReport?.timestamp))}
        </div>
      ) : (
        "N/A"
      );
    } else if (resultState === "REGENERATING_CACHED_REPORT") {
      <div style={{ fontSize: "0.875rem" }}>Regenerating Cached Report</div>;
    } else {
      return <div style={{ fontSize: "0.875rem" }}>N/A</div>;
    }
  }
};

const SourceTable = () => {
  const ref = useRef(null);

  //Set Function to enable child component to fetch parent reference for width
  const getTableWidth = () => {
    return ref?.current?.offsetWidth ?? 0;
  };

  const [showConfirm, setShowConfirm] = useState(false);
  const [sourceToDelete, setSourceToDelete] = useState(null);
  //currentPage
  const [currentView, setCurrentView] = useState(null);

  //Filter Tags
  const [tagFilter, setTagFilter] = useState([]);
  const [exactMatchTagFilter, setExactMatchTagFilter] = useState(false);
  const [manuallySelected, setManuallySelected] = useState(null);

  //Fetch Count

  const { user } = useContext(AuthContext);

  const [{ loading: removalLoading, data: removalData }, remove] = useApi();

  const toggleSourceEnabled = ({ id, enabled }) => {
    remove({
      query: setDataSourceEnabledFlag,
      variables: { id: id, enabled: !enabled },
    });
  };

  //Init Data Fetch
  const [{ loading, data: apiData }, doFetch] = useApi();

  const dataSources = apiData?.availableDataSources?.edges ?? [];
  const totalCount = apiData?.availableDataSources?.totalCount;
  const pageInfo = apiData?.availableDataSources?.pageInfo;

  //Fetch for Table Paged
  const fetchData = React.useCallback(
    ({ pageSize, cursor, sortBy }) => {
      const sortedObject = columnSort(sortBy);
      setCurrentView(cursor);
      doFetch({
        query: availableDataSourcesBare,
        variables: {
          tagFilter: {
            tagIds: [...tagFilter?.map((tf) => tf.tagId)],
            exactMatch: exactMatchTagFilter,
          },
          first: pageSize,
          after: cursor,
          ...(sortedObject
            ? {
                order: {
                  ...sortedObject,
                },
              }
            : {
                order: {
                  name: "ASC",
                },
              }),
          where: {
            enabled: { eq: true },
          },
        },
      });
    },
    [doFetch, setCurrentView, tagFilter, exactMatchTagFilter]
  );

  const [
    {
      errors: startIngressErrors,
      // loading: startLoadingIngressData,
      data: startIngressData,
    },
    startIngressProcess,
  ] = useApi();

  const [
    {
      errors: cancelRefreshSummaryErrors,
      // loading: loadingCancelRefreshSummaryData,
      data: cancelRefreshSummaryData,
    },
    cancelRefreshSummaryApi,
  ] = useApi();

  const [processLog, setProcessLog] = useState();
  const [processCancelLog, setProcessCancelLog] = useState();

  const startIngress = useCallback(
    (id) => {
      startIngressProcess({
        query: startDataSourceIngress,
        variables: { dataSourceId: id },
      });
    },
    [startIngressProcess]
  );

  const cancelRefreshSummary = useCallback(
    (id) => {
      cancelRefreshSummaryApi({
        query: endRefreshSummary,
        variables: { dataSourceId: id },
      });
    },
    [cancelRefreshSummaryApi]
  );

  useEffect(() => {
    if (startIngressData && !startIngressErrors) {
      if (startIngressData?.startDataSourceIngress?.id !== processLog)
        toast.success("Report Started", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      setProcessLog(startIngressData?.startDataSourceIngress?.id);
    }
  }, [startIngressErrors, startIngressData, setProcessLog, processLog]);

  useEffect(() => {
    if (cancelRefreshSummaryData && !cancelRefreshSummaryErrors) {
      if (cancelRefreshSummaryData?.endRefreshSummary?.id !== processCancelLog)
        toast.success("Report Cancelled", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      setProcessCancelLog(cancelRefreshSummaryData?.endRefreshSummary?.id);
    }
  }, [
    cancelRefreshSummaryErrors,
    cancelRefreshSummaryData,
    setProcessCancelLog,
    processCancelLog,
  ]);

  const columnsData = [
    {
      Header: "Name",
      id: "name",
      accessor: "name",
      Cell: ({ row: { original } }) => {
        const modifiedName =
          original?.node?.schema === ""
            ? original?.node?.name
            : `${original?.node?.schema}.${original?.node?.name}`;
        return (
          <InLineTitlePin>
            {canUseBCA(user?.email) ? (
              original?.node?.primaryKeyConfigured ? (
                <div style={{ marginRight: "1rem" }}>
                  <MdKey
                    style={{ color: "green" }}
                    title={"Has Primary Key Configured"}
                  />
                </div>
              ) : (
                <div style={{ marginRight: "1rem" }}>
                  <MdKey
                    style={{ color: "orange" }}
                    title={"Does Not Have Primary Key Configured"}
                  />
                </div>
              )
            ) : null}
            {original?.node?.ruleInstancesCount &&
            original?.node?.latestReport?.refreshSummaryId &&
            original?.node?.latestReport?.refreshSummary?.resultState !==
              "REGENERATING_CACHED_REPORT" ? (
              <StyledLink
                to={dataSourceReport(
                  original?.node?.id,
                  original?.node?.latestReport?.refreshSummaryId
                )}
                data-testid="sourceLink"
              >
                {modifiedName}
              </StyledLink>
            ) : (
              modifiedName
            )}
          </InLineTitlePin>
        );
      },
    },
    {
      Header: "Score",
      id: "score",
      sortable: false,
      Cell: ({ row: { original } }) => {
        if (original?.node?.latestReport) {
          return (
            <div style={{ fontSize: "0.875rem" }}>
              {original?.node?.ruleInstancesCount
                ? original?.node?.latestReport?.qualityScore?.score ?? 0
                : "N/A"}
            </div>
          );
        } else {
          return <div style={{ fontSize: "0.875rem" }}>N/A</div>;
        }
      },
    },
    {
      Header: "Impact",
      id: "priorityLevel",
      accessor: "priorityLevel",
      Cell: ({ row: { original } }) => {
        return (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              fontSize: "0.875rem",
            }}
          >
            <RenderPriority
              priorityLevel={original?.node?.priorityLevel}
              bump={false}
            />
          </div>
        );
      },
    },
    {
      Header: "Updated",
      id: "updated",
      Cell: ({ row: { original } }) => {
        return (
          <>
            <DataSourceProcessing sourceId={original?.node?.id} />
            <Updated original={original} />
          </>
        );
      },
    },
    {
      Header: "Connection",
      id: "connection.name",
      accessor: (d) => d?.node?.connection?.name,
      Cell: ({ row: { original } }) => {
        return (
          <InLineTitlePin>
            {user && user.role >= 2 ? (
              <StyledLink
                style={{ fontSize: "0.875rem" }}
                to={dataConnectionsSources(original?.node?.connection?.id)}
              >
                {original?.node?.connection?.name}
              </StyledLink>
            ) : (
              <div style={{ fontSize: "0.875rem" }}>
                {original?.node?.connection?.name}
              </div>
            )}
          </InLineTitlePin>
        );
      },
    },
    {
      Header: "Tags",
      id: "tags",
      Cell: ({ row: { original } }) => {
        return (
          <div>
            {original?.node?.tagInstances?.map((ti, i) => {
              const isLast = original?.node?.tagInstances?.length - 1 <= i;
              return (
                <div
                  onClick={() => setManuallySelected([ti])}
                  style={{
                    display: "inline-block",
                    background: "#e6e6e6",
                    padding: "0.2rem",
                    paddingLeft: ".4rem",
                    paddingRight: ".4rem",
                    fontSize: ".8rem",
                    marginRight: isLast ? "" : ".5rem",
                    marginBottom: isLast ? "" : ".5rem",
                    cursor: "pointer",
                  }}
                >
                  {ti?.tag?.name}
                </div>
              );
            })}
          </div>
        );
      },
    },
    {
      Header: " ",
      id: "actions",
      resizable: false,
      sortable: false,
      Cell: ({ totalColumnsWidth, row: { original } }) => {
        const enabled = original?.node?.enabled;
        const resultState = original?.node?.latestRefreshSummaryStatus;
        const ruleInstancesCount = original?.node?.ruleInstancesCount;
        const isRunning = useIsRunning(original);
        const hasReport = original?.node?.latestReport?.refreshSummaryId;
        const isReferenceSource = original?.node?.tagInstances?.some(
          (ti) => ti.tagId == -1
        );
        const isDisabled =
          isRunning || (!ruleInstancesCount && !isReferenceSource);
        return (
          <div style={{ textAlign: "right" }}>
            <TableActions
              minWidth={340}
              totalColumnsWidth={totalColumnsWidth}
              getTableWidth={getTableWidth}
            >
              {enabled && (
                <Link to={manageDataSource(original?.node?.id)}>
                  <TableButton
                    type="button"
                    title="Manage"
                    list="true"
                    data-testid="manageSource"
                  >
                    Manage
                  </TableButton>
                </Link>
              )}
              {enabled && (
                <Link
                  style={{ pointerEvents: !hasReport ? "none" : "inherit" }}
                  to={dataSourceReports(original?.node?.id)}
                >
                  <TableButton
                    title={"Report History"}
                    data-testid="sourceHistoryLink"
                    iconBump={false}
                    disabled={!hasReport}
                    type="button"
                    list={"true"}
                    bumpdown={true}
                  >
                    <MdHistory />
                  </TableButton>
                </Link>
              )}
              {isRunning && user && user.role >= 1 ? (
                <TableButton
                  list="true"
                  type="button"
                  danger={true}
                  onClick={() => cancelRefreshSummary(original?.node?.id)}
                >
                  Cancel Report
                </TableButton>
              ) : enabled && user && user.role >= 1 ? (
                <TableButton
                  list="true"
                  type="button"
                  onClick={() => {
                    if (!isDisabled) startIngress(original?.node?.id);
                  }}
                  disabled={isDisabled}
                >
                  Run Report
                </TableButton>
              ) : null}
              {user && user.role >= 2 && (
                <TableButton
                  danger={enabled}
                  list="true"
                  type="button"
                  title="Archive Toggle"
                  bumpdown={true}
                  id={`${original?.node?.name}-toggleSourceArchiveReactivate`}
                  data-testid={`${original?.node?.name}-toggleSourceArchiveReactivate`}
                  onClick={() =>
                    setSourceToDelete({
                      id: original?.node?.id,
                      enabled: enabled,
                    })
                  }
                >
                  {enabled ? <MdArchive /> : "Reactivate"}
                </TableButton>
              )}
              <PinButton type="sources" item={original?.node?.id} />
            </TableActions>
          </div>
        );
      },
    },
  ];

  //handle remove rule update
  useEffect(() => {
    if (removalData) {
      fetchData({ pageSize: 10, cursor: currentView ?? null });
      setShowConfirm(false);
      setSourceToDelete(null);
    }
  }, [removalData, fetchData, currentView]);

  useEffect(() => {
    if (sourceToDelete) {
      setShowConfirm(true);
    } else {
      setShowConfirm(false);
    }
  }, [sourceToDelete]);

  return (
    <>
      {showConfirm ? (
        <Modal
          title={`Confirm Source ${
            sourceToDelete?.enabled ? "Archival" : "Reactivation"
          }`}
          hide={() => setSourceToDelete(null)}
        >
          <p>
            Are you sure you wish to{" "}
            {sourceToDelete?.enabled ? "archive" : "reactivate"} this source?
          </p>
          <div>
            <Button
              type="button"
              list="true"
              disabled={removalLoading}
              danger
              onClick={() => toggleSourceEnabled(sourceToDelete)}
            >
              {removalLoading ? <Spinner /> : "Yes"}
            </Button>
            <Button
              type="button"
              disabled={removalLoading}
              onClick={() => {
                setSourceToDelete(null);
              }}
            >
              Cancel
            </Button>
          </div>
        </Modal>
      ) : null}

      {startIngressErrors && <ErrorMessages errors={startIngressErrors} />}

      <div style={{ display: "flex" }}>
        <div style={{ flex: 1, marginRight: "1rem" }}>
          <SearchDataSources />
        </div>
        <div style={{ flex: 1 }}>
          <FilterByTags
            currentTags={manuallySelected}
            updateTags={setTagFilter}
            tagType="DATA_SOURCE"
            exactMatch={setExactMatchTagFilter}
          />
        </div>
      </div>

      <div ref={ref}>
        <PagedTable
          fetchData={fetchData}
          loading={loading}
          pageInfo={pageInfo}
          totalCount={totalCount}
          data={dataSources}
          columns={columnsData}
          defaultPageSize={10}
        />
      </div>
      <ToastContainer />
    </>
  );
};

const SourceList = () => {
  return <SourceTable />;
};

const SourceListWithRouter = withRouter(SourceList);
export default SourceListWithRouter;
