import React, { useRef } from "react";
import { useApi } from "../../api/useApi";
import PagedTable from "../../components/Table/PagedTable";
import { availableDataSourcesPermissionCheck } from "../../api/dataSourceQueries";
import SearchDataSources from "../../components/Widgets/DataSourceWidgets/SearchDataSources";
import styled from "styled-components";
import TableButton from "../../components/Button/TableButton";

const SingleRowFlex = styled.div`
  display: flex;
  align-items: center;
`;

const DisplayName = styled.div`
  flex: 1;
`;

const SourceTable = ({ state, selectSource, sourceType, feed }) => {
  const ref = useRef(null);

  //Init Data Fetch
  const [{ loading, data: apiData }, doFetch] = useApi();

  const dataSources = apiData?.availableDataSourcesPermissionCheck?.edges ?? [];
  const totalCount = apiData?.availableDataSourcesPermissionCheck?.totalCount;
  const pageInfo = apiData?.availableDataSourcesPermissionCheck?.pageInfo;

  // IF EDITING We Should Render this component
  // IF Type is Input, only render 0

  //Fetch for Table Paged
  const fetchData = React.useCallback(
    ({ pageSize, cursor }) => {
      if (feed) {
        doFetch({
          query: availableDataSourcesPermissionCheck,
          variables: {
            first: pageSize,
            after: cursor,
            order: {
              name: "ASC",
            },
            permissionCode: 1,
            where: {
              or: [
                {
                  tagInstances: {
                    some: {
                      tagId: { eq: sourceType },
                    },
                  },
                },
              ],
              and: [{ enabled: { eq: true } }],
            },
          },
        });
      } else {
        doFetch({
          query: availableDataSourcesPermissionCheck,
          variables: {
            first: pageSize,
            after: cursor,
            order: {
              name: "ASC",
            },
            permissionCode: 1,
            where: {
              and: [
                { enabled: { eq: true } },
                {
                  tagInstances: {
                    none: {
                      tagId: { eq: sourceType },
                    },
                  },
                },
                //filter configured
                {
                  tagInstances: {
                    none: {
                      tagId: { eq: -4 },
                    },
                  },
                },
              ],
            },
          },
        });
      }
    },
    [doFetch, sourceType, feed]
  );

  const columnsData = [
    {
      Header: "Name",
      id: "Name",
      accessor: "name",
      Cell: ({ row: { original } }) => {
        return (
          <SingleRowFlex>
            <TableButton
              disabled={
                state?.selectedSourceId === original?.node?.id ||
                state?.generatedOutputSource?.connectionName
              }
              type="button"
              onClick={() => selectSource(original?.node?.id)}
              style={{ marginRight: "1rem", marginBottom: 0 }}
            >
              Select
            </TableButton>
            <DisplayName>{original?.node?.name}</DisplayName>{" "}
          </SingleRowFlex>
        );
      },
    },
    {
      Header: "Id",
      id: "SourceId",
      accessor: "node.id",
    },
    {
      Header: "Tags",
      id: "tags",
      Cell: ({ row: { original } }) => {
        return (
          <div>
            {original?.node?.tagInstances?.map((ti, i) => {
              const isLast = original?.node?.tagInstances?.length - 1 <= i;
              return (
                <div
                  style={{
                    display: "inline-block",
                    background: "#e6e6e6",
                    padding: "0.2rem",
                    paddingLeft: ".4rem",
                    paddingRight: ".4rem",
                    fontSize: ".8rem",
                    marginRight: isLast ? "" : ".5rem",
                    marginBottom: isLast ? "" : ".5rem",
                  }}
                >
                  {ti?.tag?.name}
                </div>
              );
            })}
          </div>
        );
      },
    },
  ];

  return (
    <>
      <SearchDataSources action={selectSource} />
      <div ref={ref}>
        <PagedTable
          fetchData={fetchData}
          loading={loading}
          pageInfo={pageInfo}
          totalCount={totalCount}
          data={dataSources}
          columns={columnsData}
          defaultPageSize={50}
        />
      </div>
    </>
  );
};

export default SourceTable;
