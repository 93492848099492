import React, { useState, useEffect, useCallback } from "react";
import styled from "styled-components/macro";
import { useApi } from "../../api/useApi";
import DataSourceAvailableRulesTable from "./DataSourceAvailableRulesTable";
import MappingForm from "../../components/RuleImplementations/MappingForm";
import CTAMessage from "../../components/Help/CTAMessage";
import Button from "../../components/Button";
import SplashLoader from "../../components/Loaders/SplashLoader";
import AppliedRules from "./AppliedRules";
import AppliedRuleInstances from "./AppliedRuleInstances";
import {
  dataSourceFilters,
  dataSourceStandardsCount,
} from "../../api/dataSourceQueries";
import ErrorMessages from "../../components/Notifications/ErrorMessages";
import { Route, Switch, useHistory } from "react-router-dom";
import {
  dataSourceManagePolicies,
  dataSourceManagePoliciesNew,
  dataSourceManageFilters,
  dataSourceManageFiltersNew,
} from "../../common/paths";
import ViewMapping from "./ViewMapping";
import SearchPolicyMap from "./SearchPolicyMap";
import Suggestions from "./Suggestions";
import { GiArtificialHive } from "react-icons/gi";
const TableHeader = styled.div`
  display: flex;
  align-items: center;
`;
const TableHeaderActions = styled.div`
  flex: 1;
  display: flex;
`;

const TableHeaderActionItems = styled.div`
  margin-left: auto;
`;

const ShowSuggestionsBtn = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  &:hover {
    opacity: 0.6;
  }
`;

const MappingComponentContainer = ({ mappingType, sourceId }) => {
  //Get DataSource By Id
  const [{ loading, errors, data: dataSourceData }, getSource] = useApi(
    mappingType === "FILTER" ? dataSourceFilters : dataSourceStandardsCount,
    {
      id: Number(sourceId),
    }
  );

  const data = dataSourceData?.dataSource;
  const [showMapping, setShowMapping] = useState(false);
  const [showAddNewNewMapping, setShowAddNewNewMapping] = useState(false);
  const [mapId, setMapId] = useState(showMapping?.selectedInstance ?? null);
  const [showSuggestions, setShowSuggestions] = useState(false);
  let history = useHistory();

  const getUpdatedSource = useCallback(() => {
    getSource({
      query:
        mappingType === "FILTER" ? dataSourceFilters : dataSourceStandardsCount,
      variables: { id: Number(sourceId) },
    });
    setShowMapping(false);
    setShowAddNewNewMapping(false);
    setMapId(null);
  }, [sourceId, getSource, mappingType]);

  useEffect(() => {
    if (showMapping) {
      setShowAddNewNewMapping(false);
    }
  }, [showMapping, setShowAddNewNewMapping]);

  useEffect(() => {
    if (showAddNewNewMapping) {
      setShowMapping(false);
    }
  }, [showAddNewNewMapping, setShowMapping]);

  let currentlyMappedRules = [];
  if (mappingType === "FILTER") {
    currentlyMappedRules = data?.filterRuleInstance?.enabledState
      ? [data?.filterRuleInstance]
      : [];
  } else {
    currentlyMappedRules = [];
  }

  const isTypeFilterNoResults =
    mappingType === "FILTER" && !data?.filterRuleInstance?.enabledState;

  const isNotFiltersAndNoRules =
    mappingType !== "FILTER" && !data?.ruleInstancesCount;

  const noResults =
    mappingType === "FILTER" ? isTypeFilterNoResults : isNotFiltersAndNoRules;

  if (loading) return <SplashLoader text={"Loading Policies"} />;
  if (errors) return <ErrorMessages errors={errors} />;
  const isSuggestionDisabled = window.localStorage.getItem(
    "disable-suggestions"
  );

  return (
    <>
      <Switch>
        <Route
          path={`/sources/:sourceId/manage/policies/:instanceId/:instanceVersionId`}
          component={() => (
            <ViewMapping sourceId={sourceId} mappingType={"RULE"} />
          )}
        />

        <Route
          path={`/sources/:sourceId/manage/(policies|filters)/new`}
          component={() => {
            return (
              <>
                {!mapId ? (
                  <>
                    <TableHeader>
                      <h3>
                        Select {mappingType === "FILTER" ? "Filter" : "Policy"}{" "}
                        To Map
                      </h3>
                      <TableHeaderActions>
                        <TableHeaderActionItems>
                          <Button
                            type="button"
                            danger
                            onClick={() =>
                              history.push(
                                mappingType === "FILTER"
                                  ? dataSourceManageFilters(sourceId)
                                  : dataSourceManagePolicies(sourceId)
                              )
                            }
                          >
                            Cancel Mapping
                          </Button>
                        </TableHeaderActionItems>
                      </TableHeaderActions>
                    </TableHeader>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <div style={{ flex: 1 }}>
                        <SearchPolicyMap
                          ruleType={mappingType === "FILTER" ? 2 : 1}
                          setMapId={setMapId}
                        />
                      </div>
                      {mappingType === "RULE" && !isSuggestionDisabled ? (
                        <div>
                          <ShowSuggestionsBtn
                            onClick={() => setShowSuggestions((prev) => !prev)}
                          >
                            {showSuggestions ? "Hide " : "Show "}
                            Suggestions{" "}
                            <GiArtificialHive style={{ marginLeft: ".5rem" }} />
                          </ShowSuggestionsBtn>
                        </div>
                      ) : null}
                    </div>

                    {showSuggestions &&
                    mappingType === "RULE" &&
                    !isSuggestionDisabled ? (
                      <Suggestions
                        sourceId={sourceId}
                        setMapId={setMapId}
                        mappingType={mappingType}
                      />
                    ) : (
                      <DataSourceAvailableRulesTable
                        setMapId={setMapId}
                        mappingType={mappingType}
                      />
                    )}
                  </>
                ) : null}

                {mapId && (
                  <>
                    <TableHeader>
                      <h3>Map Form</h3>
                      <TableHeaderActions>
                        <TableHeaderActionItems>
                          <Button
                            type="button"
                            danger
                            onClick={() => {
                              setMapId(null);
                              setShowAddNewNewMapping(false);
                              setShowMapping(false);
                            }}
                          >
                            Cancel Mapping
                          </Button>
                        </TableHeaderActionItems>
                      </TableHeaderActions>
                    </TableHeader>

                    <MappingForm
                      standardId={mapId}
                      sourceId={data.id}
                      getUpdatedSource={getUpdatedSource}
                      mappingType={mappingType}
                    />
                  </>
                )}
              </>
            );
          }}
        />

        <Route
          path={`/sources/:sourceId/manage/(policies|filters)`}
          component={() => {
            return (
              <>
                {mapId && (
                  <>
                    <TableHeader>
                      <h3>Map Form</h3>
                      <TableHeaderActions>
                        <TableHeaderActionItems>
                          <Button
                            type="button"
                            danger
                            onClick={() => {
                              setMapId(null);
                              setShowAddNewNewMapping(false);
                              setShowMapping(false);
                            }}
                          >
                            Cancel Mapping
                          </Button>
                        </TableHeaderActionItems>
                      </TableHeaderActions>
                    </TableHeader>

                    <MappingForm
                      standardId={mapId}
                      sourceId={data.id}
                      getUpdatedSource={getUpdatedSource}
                      mappingType={mappingType}
                    />
                  </>
                )}

                {noResults && !showAddNewNewMapping && !mapId ? (
                  <>
                    {mappingType === "RULE" ? (
                      <>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginBottom: "1.5rem",
                          }}
                        >
                          <div style={{ flex: 1, fontSize: "1.3rem" }}>
                            No Current{" "}
                            {mappingType === "FILTER" ? "Filter" : "Rule"}{" "}
                            Mappings Found.
                          </div>
                          <div>
                            <Button
                              onClick={() =>
                                history.push(
                                  mappingType === "FILTER"
                                    ? dataSourceManageFiltersNew(sourceId)
                                    : dataSourceManagePoliciesNew(sourceId)
                                )
                              }
                            >
                              Map New{" "}
                              {mappingType === "FILTER" ? "Filter" : "Rule"}
                            </Button>
                          </div>
                        </div>
                        {!isSuggestionDisabled && (
                          <Suggestions
                            sourceId={sourceId}
                            setMapId={setMapId}
                            mappingType={mappingType}
                          />
                        )}
                      </>
                    ) : (
                      <CTAMessage
                        msg={
                          <div style={{ textAlign: "center" }}>
                            <p style={{ textAlign: "center" }}>
                              No Current{" "}
                              {mappingType === "FILTER" ? "Filter" : "Rule"}{" "}
                              Mappings Found.
                            </p>
                            <Button
                              onClick={() =>
                                history.push(
                                  mappingType === "FILTER"
                                    ? dataSourceManageFiltersNew(sourceId)
                                    : dataSourceManagePoliciesNew(sourceId)
                                )
                              }
                            >
                              Map {mappingType === "FILTER" ? "Filter" : "Rule"}
                            </Button>
                          </div>
                        }
                      />
                    )}
                  </>
                ) : null}

                {!noResults &&
                !showAddNewNewMapping &&
                !showMapping &&
                !mapId ? (
                  <>
                    <TableHeader>
                      <h3>
                        Applied{" "}
                        {mappingType === "FILTER" ? "Filter" : "Policies"}
                      </h3>
                      <TableHeaderActions>
                        <TableHeaderActionItems>
                          {mappingType === "FILTER" ? null : (
                            <Button
                              type="button"
                              onClick={() =>
                                history.push(
                                  mappingType === "FILTER"
                                    ? dataSourceManageFiltersNew(sourceId)
                                    : dataSourceManagePoliciesNew(sourceId)
                                )
                              }
                            >
                              Create New Mapping
                            </Button>
                          )}
                        </TableHeaderActionItems>
                      </TableHeaderActions>
                    </TableHeader>
                    {mappingType === "FILTER" ? (
                      <AppliedRules
                        rules={currentlyMappedRules}
                        setShowMapping={setShowMapping}
                        getUpdatedSource={getUpdatedSource}
                        sourceId={sourceId}
                        mappingType={mappingType}
                      />
                    ) : (
                      <AppliedRuleInstances
                        setShowMapping={setShowMapping}
                        getUpdatedSource={getUpdatedSource}
                        sourceId={sourceId}
                        mappingType={mappingType}
                      />
                    )}
                  </>
                ) : null}
              </>
            );
          }}
        />
      </Switch>
    </>
  );
};

export default MappingComponentContainer;
