import React from "react";
import "./App.css";
import { Router } from "react-router-dom";
import Nav from "./components/Nav";
import { createBrowserHistory } from "history";
import ContentPage from "./views/ContentPage";
import AuthContext from "./contexts/AuthContext";
import NavStateProvider from "./components/Layout/NavStateProvider";
import MainContainer from "./components/Layout/MainContainer";
import SocketContext from "./contexts/useSubscriptions";
import BannerNotifications from "./components/BannerNotifications";
import PinContext from "./contexts/PinContext";
import IngressActionsContext from "./contexts/IngressActionsContext";
import PolicyChatContext from "./contexts/ai/PolicyChatContext";
import AiSettingsContext from "./contexts/ai/AiSettingsContext";
import GenericToastNotification from "./components/GenericToastNotification";

const history = createBrowserHistory();

function App() {
  return (
    <Router history={history}>
      <AuthContext history={history}>
        <IngressActionsContext>
          <PinContext>
            <SocketContext>
              <AiSettingsContext>
                <PolicyChatContext>
                  <NavStateProvider>
                    <MainContainer>
                      <BannerNotifications />
                      <GenericToastNotification />
                      <Nav />

                      <main>
                        <ContentPage />
                      </main>
                    </MainContainer>
                  </NavStateProvider>
                </PolicyChatContext>
              </AiSettingsContext>
            </SocketContext>
          </PinContext>
        </IngressActionsContext>
      </AuthContext>
    </Router>
  );
}

export default App;
