import { useContext } from "react";
import { SocketContext } from "../contexts/useSubscriptions";

const UseWorkflowCompleteEvent = () => {
  const { workflowCreateCompleteEvent, setWorkflowCreateCompleteEvent } =
    useContext(SocketContext);

  return { workflowCreateCompleteEvent, setWorkflowCreateCompleteEvent };
};

export default UseWorkflowCompleteEvent;
