import React from "react";
import Card from "../../Card";
// import RulesForm from '../../Forms/Rules';
import RulesForm from "../../Forms/Rules";
import { MdArrowBack, MdHistory } from "react-icons/md";
import ActionWrapper from "../../ActionsWrapper";
import StyledLink from "../../StyledLink";
import {
  dataSourceFilters,
  dataQualityRules,
  standardsHistory,
  filtersHistory,
} from "../../../common/paths";
import PolicyChat from "../../AI/PolicyChat";

const DataSourceRulesFormWidget = ({
  isFilter,
  isEditing,
  sourceInstances,
  triggerRefresh,
}) => {
  const ruleTypeText = isFilter ? "Filter" : "Rule";
  const ruleFormTypeText = isEditing ? "Edit" : "Create";
  //Body Section of Widget
  function Body() {
    return (
      <>
        <RulesForm
          isFilter={isFilter}
          isEditing={isEditing}
          sourceInstances={sourceInstances}
          triggerRefresh={triggerRefresh}
        />
        <PolicyChat />
      </>
    );
  }

  //Actions Section of Widget
  function Actions({ isFilter }) {
    return (
      <>
        <ActionWrapper>
          <StyledLink
            title="Back to List"
            to={isFilter ? dataSourceFilters() : dataQualityRules()}
          >
            <MdArrowBack />
          </StyledLink>
        </ActionWrapper>
        {isEditing && (
          <ActionWrapper>
            <StyledLink
              title="History"
              to={
                isFilter
                  ? filtersHistory(isEditing)
                  : standardsHistory(isEditing)
              }
            >
              <MdHistory />
            </StyledLink>
          </ActionWrapper>
        )}
      </>
    );
  }

  return (
    <Card
      title={`${ruleFormTypeText} ${ruleTypeText}`}
      titleDescription={`Data Source ${ruleTypeText}`}
      body={Body}
      menuFlyInRight={true}
      headless={isEditing}
      actions={() => <Actions isFilter={isFilter} />}
    />
  );
};

export default DataSourceRulesFormWidget;
