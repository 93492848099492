// Set Data Source Alert
export const setAlert = /* GraphQL */ `
  mutation ($alert: AlertInputModelInput!) {
    createAlert(alert: $alert) {
      id
    }
  }
`;

// Update Data Source Alert
export const updateAlert = /* GraphQL */ `
  mutation ($alertId: Int!, $alertInfo: AlertInputModelInput!) {
    updateAlert(alertId: $alertId, alertInfo: $alertInfo) {
      id
    }
  }
`;

// Set Data Source Alert Flag Enabled
export const updateAlertEnableFlag = /* GraphQL */ `
  mutation ($alertId: Int!, $enabled: Boolean!) {
    updateAlertEnableFlag(alertId: $alertId, enabled: $enabled) {
      id
    }
  }
`;
