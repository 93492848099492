// Set Data Source Metadata
export const setDataSourceMetadata = /* GraphQL */ `
  mutation (
    $id: Int!
    $description: String!
    $qualityImpact: String
    $priorityLevel: DataSourcePriorityLevel
    $refreshInfo: SchedulingInputModelInput
  ) {
    setDataSourceMetadata(
      id: $id
      description: $description
      qualityImpact: $qualityImpact
      priorityLevel: $priorityLevel
      refreshInfo: $refreshInfo
    ) {
      id
    }
  }
`;
// Set Data Source Rule Instances
export const setDataSourceRemoteInformation = /* GraphQL */ `
  mutation (
    $id: Int!
    $ingressInfo: DataSourceIoInformationInputModelInput
    $egressInfo: [DataSourceIoInformationInputModelInput!]!
  ) {
    setDataSourceRemoteInformation(
      id: $id
      ingressInfo: $ingressInfo
      egressInfo: $egressInfo
    ) {
      id
    }
  }
`;

// Set Data Source Permissions
export const setDataSourcePermissions = /* GraphQL */ `
  mutation ($id: Int!, $permissions: [DataSourcePermissionsInputModelInput!]!) {
    setDataSourcePermissions(id: $id, permissions: $permissions) {
      id
    }
  }
`;

// create Custom Report
export const createCustomReport = /* GraphQL */ `
  mutation ($input: CreateOrUpdateCustomReportInput!) {
    createCustomReport(input: $input) {
      id
    }
  }
`;

// initiate Custom Report
export const initiateCustomReport = /* GraphQL */ `
  mutation ($reportId: Int!, $refreshSummaryId: UUID!) {
    initiateCustomReport(
      reportId: $reportId
      refreshSummaryId: $refreshSummaryId
    )
  }
`;

// create Custom Report
export const updateCustomReport = /* GraphQL */ `
  mutation ($input: CreateOrUpdateCustomReportInput!, $reportId: Int!) {
    updateCustomReport(input: $input, reportId: $reportId) {
      id
    }
  }
`;

// Set Custom Report Enabled Flag
export const setCustomReportEnabledFlag = /* GraphQL */ `
  mutation ($reportId: Int!, $enabled: Boolean!) {
    setCustomReportEnabledFlag(reportId: $reportId, enabled: $enabled) {
      id
      enabled
    }
  }
`;

// Set Data Source Enabled Flag
export const setDataSourceEnabledFlag = /* GraphQL */ `
  mutation ($id: Int!, $enabled: Boolean!) {
    setDataSourceEnabledFlag(id: $id, enabled: $enabled) {
      id
      enabled
    }
  }
`;

// Create Data Source Row Retrieval Request
export const createDataSourceRowRetrievalRequest = /* GraphQL */ `
  mutation ($request: DataSourceRowRetrievalRequestInputModelInput!) {
    createDataSourceRowRetrievalRequest(request: $request) {
      calculationFailureId
      exceptionInformation
    }
  }
`;

// Create Data Source column meta data
export const setDataSourceColumnMetadata = /* GraphQL */ `
  mutation ($metadata: DataSourceColumnInputModelInput!) {
    setDataSourceColumnMetadata(metadata: $metadata) {
      id
    }
  }
`;

// Ends a stalled refresh summary
export const endRefreshSummary = /* GraphQL */ `
  mutation ($dataSourceId: Int!) {
    endRefreshSummary(dataSourceId: $dataSourceId) {
      id
    }
  }
`;

// Start Data Source Ingress proc
export const startDataSourceIngress = /* GraphQL */ `
  mutation ($dataSourceId: Int!, $referenceRefresh: Boolean, $ingestionSummaryId: UUID) {
    startDataSourceIngress(
      dataSourceId: $dataSourceId
      referenceRefresh: $referenceRefresh
      ingestionSummaryId: $ingestionSummaryId
    ) {
      id
    }
  }
`;

// Start CSV download
export const createCsvReportFailureFile = /* GraphQL */ `
  mutation (
    $dataSourceRefreshSummaryId: UUID!
    $failureType: CsvReportFailureTypes!
    $ruleInstanceVersionId: Long
  ) {
    createCsvReportFailureFile(
      dataSourceRefreshSummaryId: $dataSourceRefreshSummaryId
      failureType: $failureType
      ruleInstanceVersionId: $ruleInstanceVersionId
    )
  }
`;

// map Flag to data source columns
export const setReconciliationDataSourceColumns = /* GraphQL */ `
  mutation (
    $mappingInstruction: ReconciliationDataSourceColumnInputModelInput!
  ) {
    setReconciliationDataSourceColumns(
      mappingInstruction: $mappingInstruction
    ) {
      id
    }
  }
`;

export const retrieveFailureContext = /* GraphQL */ `
  mutation (
    $dataSourceRefreshSummaryId: UUID!
    $failureType: CsvReportFailureTypes!
    $cursor: CursorInput!
    $ruleInstanceVersionId: Long!
    $limitColumns: Boolean!
  ) {
    retrieveFailureContext(
      dataSourceRefreshSummaryId: $dataSourceRefreshSummaryId
      failureType: $failureType
      ruleInstanceVersionId: $ruleInstanceVersionId
      cursor: $cursor
      limitColumns: $limitColumns
    )
  }
`;

// Clear Recon Data Source Columns
export const clearReconciliationDataSourceColumns = /* GraphQL */ `
  mutation ($dataSourceId: Int!) {
    clearReconciliationDataSourceColumns(dataSourceId: $dataSourceId) {
      id
    }
  }
`;
