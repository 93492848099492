const brand = "#121135";
const brandTint = "#292849";
const brandTintHover = "#41405d";

//DARK THEME COLORS
const danger = "#f87e7e";
const dangerAlt = "#f64747";
const darkBackground = "#121212";
const darkPrimary = "#212121";
const darkAlt = "#c6c6c6";
const darkPrimaryLight = "#2D2D2D";
const darkSecondary = "#999999";
const darkSecondaryDark = "#666666";
const darkThirdDark = "#444444";
const darkSecondaryAlt = "#000000";
const darkAccent = "#00AAAA";
const darkAccent2 = "#fefefe";
const darkAccent3 = "#009fd4";
const darkAccent4 = "#a2ded0";
const darkGreen = "#00ff7f";
const darkBoxShadow = "rgba(0, 0, 0, 0.4)";
const darkWarn = "#F6C600";
const darkBad = "#E76E3C";
const darkOverlay = "0, 0, 0";
const darkOverlayOpacity = "0.15";
const darkBasic = "#3a3a3a";
const darkLink1 = "#007ea8";
const darkLink2 = "#0398cc";

//LIGHT THEME COLORS
const lightBackground = "#e8e8e8";
const lightPrimary = "#ffffff";
const lightAlt = "#555555";
const lightPrimaryLight = "#efefef";
const lightSecondary = "#999999";
const lightSecondaryDark = "#cccccc";
const lightSecondaryAlt = "#000000";
const lightAccent = "#21204B";
const lightAccent2 = "#fefefe";
const lightAccent3 = "#009fd4";
const lightAccent4 = "#A74165";
const lightGreen = "#40806A";
const lightBoxShadow = "rgba(0, 0, 0, 0.4)";
const lightWarn = "#F6C600";
const lightBad = "#E76E3C";
const lightOverlay = "255, 255, 255";
const lightOverlayOpacity = "0.03";
const lightBasic = "#f7f7f7";
const lightLink1 = "#007ea8";
const lightLink2 = "#0398cc";

const theme = {
  light: {
    surface: lightPrimary,
    fourthSurface: lightBasic,
    secondarySurface: lightPrimaryLight,
    onSecondarySurface: lightAccent3,
    surfaceAlt: lightSecondaryDark,
    thirdSurface: lightSecondary,
    onSurface: lightAlt,
    onSurfaceLight: lightAccent,
    onSurfaceLightSecondary: lightAccent2,
    secondary: lightSecondary,
    onSecondary: lightSecondaryAlt,
    background: lightBackground,
    onBackground: lightAlt,
    onBackgroundAlt: lightAccent4,
    brand: brand,
    danger: danger,
    dangerAlt: dangerAlt,
    bad: lightBad,
    good: lightGreen,
    warn: lightWarn,
    transitionSpeed: "150ms",
    headerHeight: "80px",
    boxShadow: lightBoxShadow,
    overlayBg: darkOverlay,
    overlayBgAlt: lightOverlay,
    overlayOpacity: lightOverlayOpacity,
    headerNav: brand,
    menuColor: brandTint,
    menuHoverActive: brandTintHover,
    menuText: lightBackground,
    fieldBorder: lightSecondaryDark,
    onSurfaceLightSecondaryText: lightAlt,
    linkPrimary: lightLink1,
    linkSecondary: lightLink2,
    dataTypes: [
      "#0088FE",
      "#00C49F",
      "#FFBB28",
      "#FF8042",
      "#A1D36E",
      "#EFEE69",
      "#532E22",
      "#4794AD",
      "#D7AB9D",
      "#9DC9D7",
    ],
  },
  dark: {
    surface: darkPrimary,
    fourthSurface: darkBasic,
    secondarySurface: darkPrimaryLight,
    onSecondarySurface: darkAccent3,
    surfaceAlt: darkSecondaryDark,
    thirdSurface: darkThirdDark,
    onSurface: darkAlt,
    onSurfaceLight: darkAccent,
    onSurfaceLightSecondary: darkAccent2,
    secondary: darkSecondary,
    onSecondary: darkSecondaryAlt,
    background: darkBackground,
    onBackground: darkAlt,
    onBackgroundAlt: darkAccent4,
    brand: brand,
    danger: danger,
    dangerAlt: dangerAlt,
    bad: darkBad,
    good: darkGreen,
    warn: darkWarn,
    transitionSpeed: "150ms",
    headerHeight: "80px",
    boxShadow: darkBoxShadow,
    overlayBg: darkOverlay,
    overlayBgAlt: darkOverlay,
    overlayOpacity: darkOverlayOpacity,
    headerNav: darkPrimary,
    menuColor: darkPrimary,
    menuHoverActive: darkPrimaryLight,
    menuText: darkAlt,
    fieldBorder: darkAlt,
    onSurfaceLightSecondaryText: darkAccent2,
    linkPrimary: darkLink1,
    linkSecondary: darkLink2,
    dataTypes: [
      "#0088FE",
      "#00C49F",
      "#FFBB28",
      "#FF8042",
      "#A1D36E",
      "#EFEE69",
      "#532E22",
      "#4794AD",
      "#D7AB9D",
      "#9DC9D7",
    ],
  },
};
export default theme;
