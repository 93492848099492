import React, { useCallback, useEffect, useState } from "react";
import { Formik, Form, Field } from "formik";
import {
  FormControl,
  StyledField,
  FormActions,
  ErrorMessage,
  StyledSelect,
  Label,
} from "../../Form/FormControls";
import Button from "../../Button";
import SplashLoader from "../../Loaders/SplashLoader";
import ErrorMessages from "../../Notifications/ErrorMessages";
import { useApi } from "../../../api/useApi";
import styled from "styled-components/macro";
import {
  createDocProvider,
  updateDocProvider,
} from "../../../api/etlProviderMutations";
import Spinner from "../../../components/Loaders/Spinner";
import * as Yup from "yup";

const docProviderTypes = [
  {
    label: "Custom Vision Prediction",
    value: "CUSTOM_VISION_PREDICTION",
  },
  {
    label: "Form Recon",
    value: "FORM_RECON",
  },
  {
    label: "Custom Vision Training",
    value: "CUSTOM_VISION_TRAINING",
  },
  {
    label: "Loan Logics Xml",
    value: "XML_LOAN_LOGIC",
  },
  {
    label: "Copy Document",
    value: "COPY_DOCUMENT",
  },
  {
    label: "Extract Document",
    value: "EXTRACT_DOCUMENT",
  },
];

const ProviderLoading = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 3;
  display: flex;
  background: rgba(255, 255, 255, 0.7);
`;

const DocProviderForm = ({ provider, fetchLatest }) => {
  // Init the create API state
  const [
    {
      loading: loadingCreateProvider,
      errors: createProviderErrors,
      data: dataCreateProvider,
    },
    createProvider,
  ] = useApi();

  useEffect(() => {
    if (dataCreateProvider && !createProviderErrors) {
      fetchLatest();
    }
  }, [dataCreateProvider, createProviderErrors, fetchLatest]);

  // Init the update API state
  const [
    {
      errors: updateProviderErrors,
      loading: loadingProviderUpdate,
      data: updateProviderData,
    },
    updateProvider,
  ] = useApi();

  useEffect(() => {
    if (updateProviderData && !updateProviderErrors) {
      fetchLatest();
    }
  }, [updateProviderData, updateProviderErrors, fetchLatest]);

  const [showPassword, setShowPassword] = useState(false);

  // Query for Data Providers
  const queryProviders = `
      query(){
        allDocProviders() {
            displayName
            docProviderType
            enabled
            id
        }
    }
  `;
  // Loading the query on component mount for latest configuration
  const [{ errors: providersErrors, data: providersData }] =
    useApi(queryProviders);
  const providerOptions =
    providersData?.allDocProviders
      ?.filter((pd) => pd.enabled)
      .map((provider) => {
        return {
          label: provider?.displayName,
          value: provider?.id,
          docProviderType: provider?.docProviderType,
        };
      }) ?? [];

  // Initial formik values
  const initialValues = {
    displayName: provider?.displayName ?? "",
    docProviderType: provider?.docProviderType
      ? docProviderTypes.find((dpt) => dpt.value === provider?.docProviderType)
          ?.value
      : "FORM_RECON",
    connectionApiKey: "",
    connectionEndpoint: "",
    enabled: provider?.enabled ?? true,
  };

  const submitCreate = useCallback(
    (values) => {
      createProvider({
        query: createDocProvider,
        variables: {
          docProviderInput: {
            displayName: values.displayName,
            docProviderType: values.docProviderType,
            connectionApiKey: values.connectionApiKey,
            connectionEndpoint: values.connectionEndpoint,
            connectionInputPattern: values.connectionInputPattern,
            connectionControlInputPattern: values.connectionControlInputPattern,
            enabled: true,
          },
        },
      });
    },
    [createProvider]
  );

  const updateProviderCall = useCallback(
    (values) => {
      updateProvider({
        query: updateDocProvider,
        variables: {
          docProviderId: provider?.id,
          docProviderInput: {
            displayName: values.displayName,
            docProviderType: values.docProviderType,
            connectionApiKey: values.connectionApiKey,
            connectionEndpoint: values.connectionEndpoint,
            connectionInputPattern: values.connectionInputPattern,
            connectionControlInputPattern: values.connectionControlInputPattern,
            connectionArchiveFolder: values.connectionArchiveFolder,
            enabled: true,
          },
        },
      });
    },
    [updateProvider, provider?.id]
  );

  return (
    <>
      {loadingCreateProvider && (
        <ProviderLoading>
          <SplashLoader text={"Processing Request"} />
        </ProviderLoading>
      )}

      <Formik
        enableReinitialize={true}
        validateOnMount={true}
        initialValues={{
          ...initialValues,
        }}
        validationSchema={Yup.object().shape({
          displayName: Yup.string().required("Required"),
          docProviderType: Yup.mixed().required("Required"),
        })}
      >
        {(form) => {
          const isNotXmlOrCopy = ["XML_LOAN_LOGIC", "COPY_DOCUMENT"].includes(
            providerOptions.find(
              (po) => po.value === form?.values?.ocrConnection?.docProviderId
            )?.docProviderType
          );

          // Matt G - please review this at some point, DEV
          const isCopyDoc = ["EXTRACT_DOCUMENT", "COPY_DOCUMENT"].includes(
            providerOptions.find(
              (po) => po.value === form?.values?.ocrConnection?.docProviderId
            )?.docProviderType
          );

          return (
            <Form>
              {!provider?.displayName ? (
                <h3>Create Document Provider</h3>
              ) : null}

              {
                <>
                  <FormControl>
                    <StyledField
                      name={`displayName`}
                      type="text"
                      placeholder="Name"
                      label="Name"
                    />
                    <ErrorMessage name={`displayName`} />
                  </FormControl>
                  {!isNotXmlOrCopy && (
                    <>
                      <FormControl>
                        <StyledField
                          name={`connectionEndpoint`}
                          type="text"
                          placeholder="Api Endpoint"
                          label="Api Endpoint"
                        />
                        <ErrorMessage name={`connectionEndpoint`} />
                      </FormControl>

                      <FormControl>
                        <StyledField
                          name={`connectionApiKey`}
                          type={showPassword ? "text" : "password"}
                          placeholder="Api Key"
                          label="Api Key"
                        />
                        <label>
                          Show Key
                          <Field
                            type="checkbox"
                            checked={showPassword}
                            name={`showApiKey`}
                            onChange={() =>
                              setShowPassword((prevState) => !prevState)
                            }
                          />
                        </label>
                        <ErrorMessage name={`connectionApiKey`} />
                      </FormControl>
                    </>
                  )}

                  {!isCopyDoc && (
                    <>
                      <FormControl>
                        <StyledField
                          name={`connectionInputPattern`}
                          type="text"
                          placeholder="Input File Pattern"
                          label="Input File Pattern"
                        />
                        <ErrorMessage name={`connectionInputPattern`} />
                      </FormControl>

                      <FormControl>
                        <StyledField
                            name={`connectionControlInputPattern`}
                            type="text"
                            placeholder="Control Input File Pattern"
                            label="Control Input File Pattern"
                        />
                        <ErrorMessage name={`connectionControlInputPattern`} />
                      </FormControl>

                      <FormControl>
                        <StyledField
                          name={`connectionArchiveFolder`}
                          type="text"
                          placeholder="Archive Folder"
                          label="Archive Folder"
                        />
                        <ErrorMessage name={`connectionArchiveFolder`} />
                      </FormControl>
                    </>
                  )}

                  <FormControl>
                    <Label>Document Provider Type</Label>
                    <StyledSelect
                      className={`react-select-container`}
                      classNamePrefix={`react-select`}
                      name={`docProviderType`}
                      id={`docProviderType`}
                      inputId={`docProviderType-input`}
                      instanceId={`docProviderType-instance`}
                      label="Document Provider Type"
                      options={docProviderTypes}
                      placeholder={`Select Document Provider Type`}
                      value={docProviderTypes.find(
                        (nc) => nc?.value === form?.values?.docProviderType
                      )}
                      menuPortalTarget={document.body}
                      menuPlacement="auto"
                      onChange={(e) =>
                        form?.setFieldValue(`docProviderType`, e?.value)
                      }
                    />
                  </FormControl>
                </>
              }

              <FormActions>
                <FormControl>
                  {createProviderErrors ? (
                    <ErrorMessages errors={createProviderErrors} />
                  ) : null}
                  {updateProviderErrors ? (
                    <ErrorMessages errors={updateProviderErrors} />
                  ) : null}

                  {!provider?.displayName ? (
                    <Button
                      type="button"
                      disabled={!form.isValid}
                      onClick={() => submitCreate(form.values)}
                    >
                      {loadingCreateProvider ? <Spinner /> : "Submit"}
                    </Button>
                  ) : (
                    <Button
                      type="button"
                      disabled={!form.isValid}
                      onClick={() => updateProviderCall(form.values)}
                    >
                      {loadingProviderUpdate ? <Spinner /> : "Update"}
                    </Button>
                  )}
                </FormControl>
              </FormActions>
            </Form>
          );
        }}
      </Formik>
      {providersErrors ? <ErrorMessages errors={providersErrors} /> : null}
    </>
  );
};

export default DocProviderForm;
