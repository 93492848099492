import React, { useState, useCallback, useEffect } from "react";
import * as AuthHelpers from "../common/helpers/auth";
import { withRouter, useHistory } from "react-router-dom";
import jwtDecode from "jwt-decode";
import * as Api from "../api";
import Cookies from "js-cookie";
import { userQuery } from "../api/userQueries";
import { useApi as useApiRedux } from "../api/useApiRedux";
import { usersInEnabledWorkGroups } from "../api/userQueries";
import { useApi } from "../api/useApi";
export const AuthContext = React.createContext();

const AuthProvider = (props) => {
  const [{ errors, data }, doFetch] = useApiRedux();

  //Init Data Fetch
  const [
    { loading: groupDataLoading, data: groupData, errors: checkGroupErrors },
    doCheckUserGroup,
  ] = useApi();

  const userObject = AuthHelpers.buildUser();
  const [user, setUser] = useState(userObject ? userObject : null);
  const [userPermissioned, setUserPermissioned] = useState(false);
  const [loginErrors, setLoginErrors] = useState(null);
  let history = useHistory();
  useEffect(() => {
    if (data) {
      Cookies.set("token", data);
      const token = jwtDecode(data);
      const userId = { id: Number(token.actort) };
      const abortController = new AbortController();

      Api.request(userQuery, userId, abortController.signal)
        .then((res) => {
          if (res?.data?.userById) {
            let redirectUrl;
            const localRedirectUrl =
              window.localStorage.getItem("redirect-url");
            if (localRedirectUrl && localRedirectUrl !== "/") {
              redirectUrl = localRedirectUrl;
            } else {
              redirectUrl = "/welcome";
            }

            history.push(redirectUrl);

            if (res?.data?.userById?.profilePhoto) {
              window.localStorage.setItem(
                "sessionPhoto",
                res.data.userById.profilePhoto
              );
            }
            setUser(AuthHelpers.buildUser());
          }
        })
        .catch((err) => {
          if (process.env.NODE_ENV !== "production") {
            console.log("err - login:" + err);
          }
          if (!abortController.signal.aborted)
            setLoginErrors([{ message: err.message }]);
        });
    }
  }, [data, history]);

  useEffect(() => {
    if (errors) {
      setUser(null);
      setLoginErrors([{ message: "Login Error, Please Try Again Later." }]);
    }
  }, [errors]);

  const login = useCallback(() => {
    const stateObject = JSON.stringify({
      origin: window.location.origin,
      redirect: `${window.location.origin}/login_redirect`,
    }).replace(/\\n/g, "");

    // Get the full URL with the query string (search parameters)
    const fullRedirectUrl = `${props.location.pathname}${props.location.search}`;
    // Store it in localStorage
    window.localStorage.setItem("redirect-url", fullRedirectUrl);

    const nonce = Math.floor(1000000000 + Math.random() * 900000);
    const loginURL = `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?response_type=id_token&scope=User.Read.All%20Group.Read.All%20openid%20profile&client_id=${
      process.env.REACT_APP_APPCLIENTID
    }&redirect_uri=${process.env.REACT_APP_LOGINURL}&state=${encodeURIComponent(
      stateObject
    )}&nonce=${nonce}&prompt=select_account&response_mode=form_post`;

    window.location.href = loginURL;
  }, [props.location.pathname]);

  const logout = useCallback(() => {
    const logoutURL = `https://login.microsoftonline.com/common/oauth2/v2.0/logout?post_logout_redirect_uri=${encodeURIComponent(
      window.location.protocol + "//" + window.location.hostname
    )}`;
    Cookies.remove("token");

    localStorage.clear();
    sessionStorage.clear();
    setUser(null);
    window.location.href = logoutURL;
  }, []);

  const doLogin = useCallback(
    (authToken) => {
      const adToken = jwtDecode(authToken);

      const variables = { email: adToken.preferred_username, authToken };

      doFetch({ query: "/authentication", body: variables });
    },
    [doFetch]
  );

  const CheckGroup = useCallback(
    (userId) => {
      doCheckUserGroup({
        query: usersInEnabledWorkGroups,
        variables: {
          where: {
            id: { eq: Number(userId) },
            enabled: { eq: true },
          },
        },
      });
    },
    [doCheckUserGroup]
  );

  useEffect(() => {
    if (user?.id) {
      CheckGroup(user.id);
    }
  }, [CheckGroup, user]);

  useEffect(() => {
    if (groupData) {
      const workgroupTotal = groupData?.usersInEnabledWorkGroups?.totalCount;
      const isFoundInWorkgroup = workgroupTotal >= 1;
      setUserPermissioned(isFoundInWorkgroup);
    }
  }, [groupData, setUserPermissioned]);

  const value = React.useMemo(() => {
    return {
      user,
      loginErrors,
      login,
      logout,
      doLogin,
      userPermissioned,
      groupDataLoading,
      checkGroupErrors,
    };
  }, [
    user,
    doLogin,
    loginErrors,
    login,
    logout,
    userPermissioned,
    groupDataLoading,
    checkGroupErrors,
  ]);

  return (
    <AuthContext.Provider value={value}>{props.children}</AuthContext.Provider>
  );
};

const AuthProviderWithRouter = withRouter(AuthProvider);
export default AuthProviderWithRouter;
