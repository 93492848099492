import React, { useRef, useState } from "react";
import {
  useTable,
  useSortBy,
  usePagination,
  useExpanded,
  useFilters,
} from "react-table";
import {
  MdNavigateNext,
  MdNavigateBefore,
  MdLastPage,
  MdFirstPage,
  MdExpandMore,
  MdExpandLess,
  MdPushPin,
} from "react-icons/md";
import {
  TableElement,
  Td,
  TrSub,
  Th,
  ThSticky,
  Tr,
  PageButton,
  TableLoading,
  Thead,
  TdExpand,
  TBody,
} from "./elements";
import SplashLoader from "../Loaders/SplashLoader";
import { StyledSelect, FormControl, Label } from "../Form/FormControls";

const Cell = ({ row, sticky, header, i, setIsEditing }) => {
  const cell = row.cells.find((c) => c.column.Header === header);
  const isSticky = sticky.find((s) => s.label === cell.column.Header);
  const existingIndex = sticky.findIndex((s) => s.label === cell.column.Header);
  const isActions = cell.column?.id === "actions";
  return (
    <Td
      {...cell.getCellProps()}
      style={{
        cursor: row.cells.length - 1 !== i ? "pointer" : "inherit",
      }}
      isLast={isSticky ? existingIndex + 1 === sticky.length : null}
      left={isSticky ? `${existingIndex * 180}px` : null}
      isOdd={i % 2 !== 1}
      right={isActions ? `0` : null}
      onClick={() => setIsEditing(row)}
    >
      {cell.render("Cell")}
    </Td>
  );
};

const HeaderCell = ({ headerGroup, sticky, setSticky, header, pinning }) => {
  const column = headerGroup.headers.find((h) => h.Header === header);
  const isSticky = sticky.find((s) => s.label === column.Header);
  const existingIndex = sticky.findIndex((s) => s.label === column.Header);
  const isActions = column?.id === "actions";
  const ColumnSelector = isSticky || isActions ? ThSticky : Th;

  return (
    <ColumnSelector
      {...column.getHeaderProps(column.getSortByToggleProps())}
      left={isSticky ? `${existingIndex * 180}px` : null}
      isLast={isSticky ? existingIndex + 1 === sticky.length : null}
      right={isActions ? `0` : null}
    >
      <div style={{ display: "flex", alignItems: "center" }}>
        <div style={{ display: "flex", flexDirection: "column" }}>
          {column.render("Header")}
          {column.canFilter ? <div>{column.render("Filter")} </div> : null}
        </div>

        {/* Add a sort direction indicator */}
        <span>
          {column.isSorted ? (
            <div style={{ fontSize: "1.5rem" }}>
              {column.isSortedDesc ? <MdExpandMore /> : <MdExpandLess />}
            </div>
          ) : (
            ""
          )}
        </span>
        {pinning && (
          <div
            style={{
              marginLeft: "auto",
              paddingLeft: "1rem",
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
            }}
          >
            <MdPushPin
              style={{ opacity: isSticky ? 1 : 0.4 }}
              onClick={() =>
                setSticky((prev) => {
                  const clone = [...prev];
                  if (existingIndex > -1) {
                    clone.splice(existingIndex, 1);
                  } else {
                    clone.push({
                      label: column?.Header,
                      value: column?.id,
                    });
                  }

                  return clone;
                })
              }
            />
          </div>
        )}
      </div>
    </ColumnSelector>
  );
};

// Define a default UI for filtering
function DefaultColumnFilter({
  column: { filterValue, preFilteredRows, setFilter, Header },
}) {
  return (
    <input
      style={{ width: "120px" }}
      value={filterValue || ""}
      placeholder={`Filter by ${Header}`}
      onClick={(e) => {
        e.stopPropagation();
      }}
      onChange={(e) => {
        setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
      }}
    />
  );
}

function Table({
  columns,
  data,
  loading,
  renderRowSubComponent,
  defaultSort,
  defaultPageSize,
  setIsEditing,
  pinning,
  pinned,
}) {
  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page
    //Page information
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    visibleColumns,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      disableSortRemove: true,
      autoResetExpanded: false,
      autoResetPage: false,
      initialState: {
        pageIndex: 0,
        sortBy: defaultSort,
        pageSize: defaultPageSize,
      },
      autoResetFilters: false,
    },
    useFilters,
    useSortBy,
    useExpanded,
    usePagination
  );

  const [sticky, setSticky] = useState(pinned ?? []);

  const tableContainerRef = useRef(null);

  return (
    <div ref={tableContainerRef}>
      {pinning && (
        <FormControl>
          <Label>Quick Column Pin</Label>
          <StyledSelect
            className={`react-select-container`}
            classNamePrefix={`react-select`}
            name={`qpn`}
            id={`qpn`}
            inputId={`qpn-input`}
            instanceId={`qpn-instance`}
            label="Members"
            options={columns.map((c) => {
              return {
                label: c?.Header,
                value: c?.id,
              };
            })}
            placeholder={`Select Columns to Pin Left`}
            value={sticky ?? []}
            menuPlacement="auto"
            isMulti
            onChange={(e) => setSticky(e)}
            menuPortalTarget={document.body}
          />
        </FormControl>
      )}

      {/* overflowX: "auto", maxHeight: "100vh" */}
      <div
        style={{ position: "relative", maxHeight: "70vh", overflow: "auto" }}
      >
        <TableElement {...getTableProps()}>
          <Thead>
            {headerGroups.map((headerGroup) => {
              return (
                <Tr {...headerGroup.getHeaderGroupProps()}>
                  {sticky.map((s) => (
                    <HeaderCell
                      headerGroup={headerGroup}
                      sticky={sticky}
                      setSticky={setSticky}
                      header={s?.label}
                      pinning={pinning}
                    />
                  ))}
                  {headerGroup.headers
                    .filter((h) => {
                      const isPinned = sticky.some(
                        (s) => s.label === h?.Header
                      );
                      return !isPinned;
                    })
                    .map((column) => (
                      <HeaderCell
                        headerGroup={headerGroup}
                        sticky={sticky}
                        setSticky={setSticky}
                        header={column?.Header}
                        pinning={pinning}
                      />
                    ))}
                </Tr>
              );
            })}
          </Thead>
          <TBody {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <>
                  <Tr {...row.getRowProps()}>
                    {/* LOOP THROUGH STICKY COLUMNS */}
                    {sticky.map((s) => (
                      <Cell
                        row={row}
                        sticky={sticky}
                        header={s?.label}
                        i={i}
                        setIsEditing={setIsEditing}
                      />
                    ))}
                    {/* FILTER AND LOOP THROUGH THE REST */}
                    {row.cells
                      .filter((c) => {
                        const isPinned = sticky.some(
                          (s) => s.label === c.column?.Header
                        );

                        return !isPinned;
                      })
                      .map((cell) => (
                        <Cell
                          row={row}
                          sticky={sticky}
                          header={cell?.column?.Header}
                          i={i}
                          setIsEditing={setIsEditing}
                        />
                      ))}
                  </Tr>
                  {row.isExpanded ? (
                    <TrSub
                      style={{
                        borderLeft: row.isExpanded
                          ? "2px solid #009fd4"
                          : "2px solid transparent",
                      }}
                    >
                      <TdExpand colSpan={visibleColumns.length}>
                        {renderRowSubComponent({ row, tableContainerRef })}
                      </TdExpand>
                    </TrSub>
                  ) : null}
                </>
              );
            })}
          </TBody>
        </TableElement>
        {loading ? (
          <TableLoading>
            <SplashLoader text="Loading Results" />
          </TableLoading>
        ) : null}
      </div>

      <div style={{ display: "flex", alignItems: "center" }}>
        <>{!loading && page && !page.length ? <div>No Results</div> : null}</>
        <div style={{ marginLeft: "auto", marginRight: "1rem" }}>
          <span>
            Page{" "}
            <strong>
              {pageIndex + 1} of {pageOptions.length}
            </strong>{" "}
          </span>
        </div>
        <div style={{ marginRight: "1rem" }}>
          <select
            value={pageSize}
            onChange={(e) => {
              setPageSize(Number(e.target.value));
            }}
          >
            {[10, 25, 50].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </select>
        </div>
        <div style={{ marginRight: "1rem" }}>
          <PageButton
            type="button"
            onClick={() => gotoPage(0)}
            disabled={!canPreviousPage}
          >
            <MdFirstPage />
          </PageButton>{" "}
          <PageButton
            type="button"
            onClick={() => previousPage()}
            disabled={!canPreviousPage}
          >
            <MdNavigateBefore />
          </PageButton>{" "}
          <PageButton
            type="button"
            onClick={() => nextPage()}
            disabled={!canNextPage}
          >
            <MdNavigateNext />
          </PageButton>{" "}
          <PageButton
            type="button"
            onClick={() => gotoPage(pageCount - 1)}
            disabled={!canNextPage}
          >
            <MdLastPage />
          </PageButton>
        </div>
      </div>
    </div>
  );
}

const SortExpandTableV2 = ({
  data,
  columns,
  loading,
  SubRowComponent,
  defaultSort,
  defaultPageSize = 50,
  setIsEditing,
  pinning,
  pinned,
}) => {
  // Create a function that will render our row sub components
  const renderRowSubComponent = React.useCallback(({ row }) => {
    return (
      <div>
        <SubRowComponent row={row} />
      </div>
    );
  }, []);

  return (
    <Table
      defaultSort={defaultSort}
      columns={columns}
      data={data && data.length ? data : []}
      loading={loading}
      defaultPageSize={defaultPageSize}
      renderRowSubComponent={renderRowSubComponent}
      setIsEditing={setIsEditing}
      pinning={pinning}
      pinned={pinned}
    />
  );
};

export default SortExpandTableV2;
