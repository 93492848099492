import { useContext } from "react";
import { SocketContext } from "../contexts/useSubscriptions";

const TagUpdateComplete = () => {
  const { tagUpdateComplete, setTagUpdateComplete } = useContext(SocketContext);

  return {
    tagUpdateComplete,
    setTagUpdateComplete,
  };
};
export default TagUpdateComplete;
