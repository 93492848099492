import React, { PureComponent } from 'react';
import { FlexVertCenterContainer } from '../Layout/Flex/FlexContainers';
export default class CTAMessage extends PureComponent {
  render() {
    const { msg } = this.props;
    return (
      <FlexVertCenterContainer col>{msg ? msg : null}</FlexVertCenterContainer>
    );
  }
}
