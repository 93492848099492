import React, {Component} from 'react';
import bcalogo from '../../assets/images/BasecapLogoBlue.png';

export default class BCALogo extends Component {
  render() {
    return (
      <div>
        <img src={bcalogo} {...this.props} alt="BaseCap Analytics" />
      </div>
    );
  }
}
