import React from "react";
import { Box, Typography } from "@mui/material";

const Error = ({ message }) => {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="100%"
      width="100%"
      flexDirection="column"
    >
      <Typography variant="h6" color="error">
        Error
      </Typography>
      <Typography variant="body1">{message}</Typography>
    </Box>
  );
};

export default Error;
