import React, { useCallback, useState } from "react";
import Button from "../../components/Button";
import { availableCrosstableDataSources } from "../../api/dataSourceQueries";
import { useApi } from "../../api/useApi";
import PagedTable from "../../components/Table/PagedTable";
import { FormActions } from "../../components/Form/FormControls";
import { Scrollbars } from "react-custom-scrollbars";

const SelectSources = ({ dispatch }) => {
  const [locallySelected, setLocallySelected] = useState([]);

  //Init Data Fetch
  const [{ loading, data: apiData }, doFetch] = useApi();

  const dataSources = apiData?.availableCrosstableDataSources?.edges ?? [];
  const totalCount = apiData?.availableCrosstableDataSources?.totalCount;
  const pageInfo = apiData?.availableCrosstableDataSources?.pageInfo;

  const updateLocally = useCallback(
    (source) => {
      const foundInternally = locallySelected.find(
        (ds) => ds?.id === source?.id
      );

      if (foundInternally) {
        setLocallySelected((prevState) => {
          return prevState.filter((s) => s?.id !== source?.id);
        });
      } else {
        setLocallySelected((prevState) => [...prevState, source]);
      }
    },
    [setLocallySelected, locallySelected]
  );

  //Fetch for Table Paged
  const fetchData = React.useCallback(
    ({ pageSize, cursor }) => {
      doFetch({
        query: availableCrosstableDataSources,
        variables: {
          first: pageSize,
          after: cursor,
          where: {
            enabled: true,
            id_not: -2,
          },
          order: {
            name: "ASC",
          },
        },
      });
    },
    [doFetch]
  );

  const columnsData = [
    {
      Header: "",
      id: "Selected",
      accessor: "Selected",
      width: "100px",
      Cell: ({ row: { original } }) => {
        const foundInternally = locallySelected.find(
          (ds) => ds?.id === original?.node?.id
        );
        const isSelected = foundInternally;

        return (
          <div>
            <input
              type="checkbox"
              name={`datasources.${original?.node?.id}`}
              label="Select"
              checked={isSelected}
              onChange={() => updateLocally(original?.node)}
            />
          </div>
        );
      },
    },
    {
      Header: "Id",
      id: "id",
      accessor: "node.id",
    },
    {
      Header: "Name",
      id: "Name",
      accessor: "name",
      Cell: ({ row: { original } }) => {
        return original?.node?.name;
      },
    },
    {
      Header: "Tags",
      id: "tags",
      Cell: ({ row: { original } }) => {
        return (
          <div>
            {original?.node?.tagInstances?.map((ti, i) => {
              const isLast = original?.node?.tagInstances?.length - 1 <= i;
              return (
                <div
                  style={{
                    display: "inline-block",
                    background: "#e6e6e6",
                    padding: "0.2rem",
                    paddingLeft: ".4rem",
                    paddingRight: ".4rem",
                    fontSize: ".8rem",
                    marginRight: isLast ? "" : ".5rem",
                    marginBottom: isLast ? "" : ".5rem",
                  }}
                >
                  {ti?.tag?.name}
                </div>
              );
            })}
          </div>
        );
      },
    },
  ];

  return (
    <>
      <Scrollbars
        hideTracksWhenNotNeeded={true}
        autoHeightMax={window.innerHeight * 0.7}
        autoHeight
      >
        <PagedTable
          fetchData={fetchData}
          loading={loading}
          pageInfo={pageInfo}
          totalCount={totalCount}
          data={dataSources}
          columns={columnsData}
          defaultPageSize={50}
        />

        <div>
          <FormActions>
            <Button
              list
              onClick={() => dispatch({ type: "BACK_FROM_SOURCES" })}
            >
              back
            </Button>

            <Button
              type="button"
              disabled={!locallySelected?.length}
              onClick={() => {
                dispatch({
                  type: "APPLY_TO_SOURCES",
                  payload: { selectedSources: locallySelected },
                });
              }}
            >
              Apply Pack To Sources
            </Button>
          </FormActions>
        </div>
      </Scrollbars>
    </>
  );
};

export default SelectSources;
