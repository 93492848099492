import React, { Component } from "react";
import styled from "styled-components";

const SpacerSection = styled.div`
  margin-top: 1em;
`;

export default class Spacer extends Component {
  render() {
    return <SpacerSection {...this.props} />;
  }
}
