import React, { useState, useEffect, useContext, useCallback } from "react";
import { useApi } from "../../api/useApiRedux";
import ErrorMessages from "../../components/Notifications/ErrorMessages";
import PagedTableRedux from "../../components/Table/PagedTableRedux";
import {
  MdNotifications,
  MdExpandMore,
  MdExpandLess,
  MdDoneAll,
} from "react-icons/md";
import RenderPriority from "../../common/RenderPriority";
import { formatTime } from "../../common/formats";
import styled from "styled-components/macro";

import TableRowView from "../../components/TableRowView/TableRowView";
import IssueRowDetailsRule from "./IssueRowDetailsRule";
import IssueRowDetailsOcr from "./IssueRowDetailsOcr";
import { ToastContainer, toast } from "react-toastify";
import { AuthContext } from "../../contexts/AuthContext";
import { sortByAlpha } from "../../common/helpers/util";

import TableButtonSecondary from "../../components/Button/TableButtonSecondary";

const ResolvedIcon = styled.div`
  display: flex;
  align-items: center;
  color: rgb(1, 152, 255);
  margin-right: 0.1rem;
  margin-left: 0.5rem;
`;

const DataSourceName = styled.div`
  font-size: 0.6875rem;
  text-transform: uppercase;
  letter-spacing: 0.4px;
  color: #6d727c;
`;

const IssueNameViewLink = styled.div`
  text-decoration: none;
  transition: all ${(props) => props.theme.transitionSpeed} linear;
  font-family: "Source Sans Pro Semibold";
  color: ${(props) => props.theme.linkPrimary};
  cursor: pointer;
  word-break: break-word;
  &:hover {
    text-decoration: underline;
    color: ${(props) => props.theme.linkPrimary};
  }
  &:active {
    text-decoration: underline;
    color: ${(props) => props.theme.linkSecondary};
  }
`;

// Issues Tab on Datasource - Click - Filter issues by data source id active/unassigned

const IssuesList = ({ filterSource }) => {
  const { user } = useContext(AuthContext);

  const initFilters = {
    page: 1,
    size: 10,
    filters: [
      ...(filterSource ? [{ DataSourceId: filterSource }] : []),
      { UserId: user?.id },
    ],
    Sorting: [{ Name: "asc" }],
  };
  const [viewState, setViewState] = useState(initFilters);

  const [showRow, setShowRow] = useState(null);

  const [tableState, setTableState] = useState([]);
  const [toggledMute, setToggledMute] = useState(null);

  // Init Data Fetch
  const [{ errors, loading, data: apiData }, doFetch] = useApi();

  const [
    { errors: muteErrors, loading: muteLoading, data: apiMute },
    doUpdateMute,
  ] = useApi();

  const updateIssueRow = useCallback((issue, prevState) => {
    let cloneTableState = { ...prevState, data: [...prevState?.data] };

    const foundIndex = cloneTableState?.data?.findIndex(
      (item) => item?.id === issue?.id
    );

    //Build Assignees in table thats cloned
    if (foundIndex !== -1) {
      cloneTableState.data[foundIndex] = issue;
      cloneTableState.data[foundIndex].assignees = issue.assignees.map(
        (assignee) => {
          return { label: assignee?.emailAddress, value: assignee?.userId };
        }
      );
    }

    toast.success("Issue Updated", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

    return cloneTableState;
  }, []);

  //HANDLE MUTE TOGGLE
  useEffect(() => {
    if (apiMute && !muteErrors?.length) {
      setTableState((prevState) => {
        return updateIssueRow(apiMute, prevState);
      });
      setToggledMute(null);
    }
  }, [apiMute, setTableState, muteErrors, updateIssueRow]);

  const toggleMute = ({ original }) => {
    const cleanAssigned = original?.assignees?.length
      ? original?.assignees.map((user) => ({
          id: user?.id ?? 0,
          userId: user?.value,
        }))
      : [];

    const body = {
      ...original,
      notify: !original?.notify,
      assignees: cleanAssigned,
    };
    setToggledMute(body);
    doUpdateMute({
      query: `/Issues/${original?.id}`,
      method: "POST",
      body: body,
    });
  };

  function buildAssignees(assignees) {
    const newMappedUsers = assignees.map((user) => {
      return { label: user?.emailAddress, value: user?.userId };
    });
    const sortedMappedUsers = sortByAlpha(newMappedUsers, "label");

    return sortedMappedUsers;
  }

  // Handle init load of table data and store it to state
  useEffect(() => {
    if (apiData && !errors?.length) {
      const cleanData = apiData?.data.map((cd) => ({
        ...cd,
        assignees: buildAssignees(cd?.assignees),
      }));

      const setInitialData = {
        data: cleanData,
        metadata: apiData?.metadata,
      };

      setTableState(setInitialData);
    }
  }, [setTableState, apiData, errors]);

  const handleFormSuccessStateSwap = (dataObj) => {
    setTableState((prevState) => {
      return updateIssueRow(dataObj, prevState);
    });
  };

  const issues = tableState?.data ?? [];
  const totalCount = tableState?.metadata?.availableItems;

  //Fetch for Table Paged
  const fetchData = React.useCallback(
    ({ params }) => {
      doFetch({
        query: `/Issues`,
        params: params,
        method: "GET",
      });
    },
    [doFetch]
  );

  const columnsData = [
    {
      Header: () => {
        const isSortedAndAsc = viewState?.Sorting?.find(
          (s) => s["Name"] === "asc"
        );
        const isSortedAndDesc = viewState?.Sorting?.find(
          (s) => s["Name"] === "desc"
        );

        return (
          <div
            style={{ cursor: "pointer" }}
            onClick={() =>
              setViewState((prev) => {
                let newName;
                if (prev?.Sorting?.find((s) => s["Name"] === "desc")) {
                  newName = {
                    ...prev,
                    Sorting: [{ Name: "asc" }],
                  };
                } else {
                  newName = {
                    ...prev,
                    Sorting: [{ Name: "desc" }],
                  };
                }

                return newName;
              })
            }
          >
            Name {isSortedAndAsc && <MdExpandMore />}
            {isSortedAndDesc && <MdExpandLess />}
          </div>
        );
      },
      id: "name",
      accessor: (d) => d?.name,
      Cell: ({ row: { original } }) => {
        const issueDetails = JSON.parse(original?.issueDetails);
        const showLink = original?.type === "RuleInstanceVersion";
        return (
          <>
            {showLink ? (
              <IssueNameViewLink
                data-testid="datasourcelink"
                onClick={() => setShowRow(original)}
              >
                {original?.name}
              </IssueNameViewLink>
            ) : (
              <div> {original?.name}</div>
            )}

            <DataSourceName>{issueDetails?.DataSourceName}</DataSourceName>
          </>
        );
      },
    },
    {
      Header: () => {
        const isSortedAndAsc = viewState?.Sorting?.find(
          (s) => s["Priority"] === "asc"
        );
        const isSortedAndDesc = viewState?.Sorting?.find(
          (s) => s["Priority"] === "desc"
        );

        return (
          <div
            style={{ cursor: "pointer" }}
            onClick={() =>
              setViewState((prev) => {
                let newPriority;
                if (prev?.Sorting?.find((s) => s["Priority"] === "asc")) {
                  newPriority = {
                    ...prev,
                    Sorting: [{ Priority: "desc" }],
                  };
                } else {
                  newPriority = {
                    ...prev,
                    Sorting: [{ Priority: "asc" }],
                  };
                }

                return newPriority;
              })
            }
          >
            Priority {isSortedAndAsc && <MdExpandMore />}
            {isSortedAndDesc && <MdExpandLess />}
          </div>
        );
      },
      id: "priority",
      width: 150,
      accessor: (d) => d?.priority,
      Cell: ({ row: { original } }) => {
        return (
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <RenderPriority priorityLevel={original?.priority} bump={false} />
          </div>
        );
      },
    },
    {
      Header: () => {
        const isSortedAndAsc = viewState?.Sorting?.find(
          (s) => s["DateOfCheck"] === "asc"
        );
        const isSortedAndDesc = viewState?.Sorting?.find(
          (s) => s["DateOfCheck"] === "desc"
        );

        return (
          <div
            style={{ cursor: "pointer" }}
            onClick={() =>
              setViewState((prev) => {
                let newDateOfCheck;
                if (prev?.Sorting?.find((s) => s["DateOfCheck"] === "desc")) {
                  newDateOfCheck = {
                    ...prev,
                    Sorting: [{ DateOfCheck: "asc" }],
                  };
                } else {
                  newDateOfCheck = {
                    ...prev,
                    Sorting: [{ DateOfCheck: "desc" }],
                  };
                }

                return newDateOfCheck;
              })
            }
          >
            Date of Check {isSortedAndAsc && <MdExpandLess />}
            {isSortedAndDesc && <MdExpandMore />}
          </div>
        );
      },
      id: "date",
      width: 150,
      accessor: (d) => d?.dateOfCheck,
      Cell: ({ row: { original } }) => {
        return original?.dateOfCheck ? (
          <div style={{ minWidth: "130px" }}>
            {formatTime(new Date(original?.dateOfCheck))}
          </div>
        ) : null;
      },
    },
    {
      Header: () => {
        const isSortedAndAsc = viewState?.Sorting?.find(
          (s) => s["FailureCount"] === "asc"
        );
        const isSortedAndDesc = viewState?.Sorting?.find(
          (s) => s["FailureCount"] === "desc"
        );

        return (
          <div
            style={{ cursor: "pointer" }}
            onClick={() =>
              setViewState((prev) => {
                let newFailureCount;
                if (prev?.Sorting?.find((s) => s["FailureCount"] === "desc")) {
                  newFailureCount = {
                    ...prev,
                    Sorting: [{ FailureCount: "asc" }],
                  };
                } else {
                  newFailureCount = {
                    ...prev,
                    Sorting: [{ FailureCount: "desc" }],
                  };
                }

                return newFailureCount;
              })
            }
          >
            Failures {isSortedAndAsc && <MdExpandLess />}
            {isSortedAndDesc && <MdExpandMore />}
          </div>
        );
      },
      id: "alertTime",
      width: 100,
      accessor: (d) => d?.failureCount,
    },
    {
      Header: () => {
        const isSortedAndAsc = viewState?.Sorting?.find(
          (s) => s["ReoccurrenceCount"] === "asc"
        );
        const isSortedAndDesc = viewState?.Sorting?.find(
          (s) => s["ReoccurrenceCount"] === "desc"
        );

        return (
          <div
            style={{ cursor: "pointer" }}
            onClick={() =>
              setViewState((prev) => {
                let newReoccurrenceCount;
                if (
                  prev?.Sorting?.find((s) => s["ReoccurrenceCount"] === "desc")
                ) {
                  newReoccurrenceCount = {
                    ...prev,
                    Sorting: [{ ReoccurrenceCount: "asc" }],
                  };
                } else {
                  newReoccurrenceCount = {
                    ...prev,
                    Sorting: [{ ReoccurrenceCount: "desc" }],
                  };
                }

                return newReoccurrenceCount;
              })
            }
          >
            Recurrence {isSortedAndAsc && <MdExpandLess />}
            {isSortedAndDesc && <MdExpandMore />}
          </div>
        );
      },
      id: "recurrence",
      accessor: (d) => d?.reoccurrenceCount,
      width: 100,
      Cell: ({ row: { original } }) => {
        if (
          original?.reoccurrenceCount === 1 &&
          original?.status !== "Closed"
        ) {
          return (
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  color: "#0198FF",
                  marginRight: ".1rem",
                }}
              >
                <MdNotifications />
              </div>
              <div>New</div>
              {original?.pinnedNote ? (
                <ResolvedIcon title="Has Resolution">
                  <MdDoneAll />
                </ResolvedIcon>
              ) : null}
            </div>
          );
        } else {
          return (
            <div>
              {original?.reoccurrenceCount}
              {original?.pinnedNote ? (
                <ResolvedIcon title="Has Resolution">
                  <MdDoneAll />
                </ResolvedIcon>
              ) : null}
            </div>
          );
        }
      },
    },
    {
      Header: () => {
        const isSortedAndAsc = viewState?.Sorting?.find(
          (s) => s["Status"] === "asc"
        );
        const isSortedAndDesc = viewState?.Sorting?.find(
          (s) => s["Status"] === "desc"
        );

        return (
          <div
            style={{ cursor: "pointer" }}
            onClick={() =>
              setViewState((prev) => {
                let newStatus;
                if (prev?.Sorting?.find((s) => s["Status"] === "desc")) {
                  newStatus = {
                    ...prev,
                    Sorting: [{ Status: "asc" }],
                  };
                } else {
                  newStatus = {
                    ...prev,
                    Sorting: [{ Status: "desc" }],
                  };
                }

                return newStatus;
              })
            }
          >
            Status {isSortedAndAsc && <MdExpandLess />}
            {isSortedAndDesc && <MdExpandMore />}
          </div>
        );
      },
      id: "status",
      width: 100,
      accessor: (d) => d?.status,
    },
    {
      Header: " ",
      id: "actions",
      width: 210,
      accessor: (d) => d?.node?.name,
      Cell: ({ row: { original } }) => {
        const isMuting = toggledMute?.id === original?.id && muteLoading;
        return (
          <>
            {original?.status === "Closed" ? null : (
              <TableButtonSecondary
                type="button"
                list={"true"}
                onClick={() => toggleMute({ original })}
                disabled={isMuting}
              >
                {original?.notify ? "Snooze" : "Un-snooze"}
              </TableButtonSecondary>
            )}

            <TableButtonSecondary
              type="button"
              list={"true"}
              onClick={() => setShowRow(original)}
            >
              Edit
            </TableButtonSecondary>
          </>
        );
      },
    },
  ];

  function getDetails(showRow) {
    switch (showRow?.type) {
      case "RuleInstanceVersion":
        return IssueRowDetailsRule;
      case "OcrScanError":
        return IssueRowDetailsOcr;
      default:
        return IssueRowDetailsRule;
    }
  }

  const IssueComponent = getDetails(showRow);

  return (
    <>
      <h3>My Issues</h3>

      {showRow ? (
        <TableRowView toggle={setShowRow}>
          <IssueComponent
            toggle={setShowRow}
            row={showRow}
            setRowAfterForm={handleFormSuccessStateSwap}
          />
        </TableRowView>
      ) : null}

      <PagedTableRedux
        key={"viewState" + JSON.stringify(viewState)}
        fetchData={fetchData}
        totalCount={totalCount}
        loading={loading}
        data={issues}
        columns={columnsData}
        noMargin={true}
        initParams={viewState}
      />

      {errors && <ErrorMessages errors={errors} />}
      {muteErrors && <ErrorMessages errors={muteErrors} />}
      <ToastContainer />
    </>
  );
};

export default IssuesList;
