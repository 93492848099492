import React from "react";
import styled from "styled-components/macro";

const ContentContainer = styled.div`
  padding: 0.8rem;
  background: ${(props) => props.theme.background};
  position: relative;
  display: flex;
  height: 100%;
`;

const ContentContainerComponent = (props) => {
  return <ContentContainer>{props.children}</ContentContainer>;
};

export default ContentContainerComponent;
