// ------ Audit Log --------//

// Fragment object with variable data
export const auditLogEntriesFragment = /* GraphQL */ {
  variables: [],
  fragment: /* GraphQL */ `
      auditLogEntries(first: $first, after: $after, where: $where, order: $order){
        totalCount
        pageInfo {
          endCursor
          hasNextPage
          hasPreviousPage
          startCursor
        }
        edges {
          cursor
            node {
              action
              additionalData
              id
              performingUserId
              timestamp
              performingUser {
                displayName
                id
                emailAddress
              }
              remoteObjectType
              remoteObjectName
              remoteObjectId
              remoteObjectGuid
          }
        }
      }
    `,
};

// Log
export const auditLogEntriesQuery = /* GraphQL */ `
    query($first: Int, $after: String, $where: AuditLogEntryFilterInput, $order: [AuditLogEntrySortInput!]) {
        ${auditLogEntriesFragment.fragment}
    }
  `;

export const auditLogEntriesQueryUserActions = /* GraphQL */ `
  query (
    $first: Int
    $action: AuditLogEntryActions
    $remoteType: AuditRemoteObjectTypes
    $after: String
    $where: AuditLogEntryFilterInput
    $order: [AuditLogEntrySortInput!]
  ) {
    auditLogEntries(
      first: $first
      action: $action
      remoteType: $remoteType
      after: $after
      where: $where
      order: $order
    ) {
      totalCount
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      edges {
        cursor
        node {
          action
          additionalData
          id
          performingUserId
          timestamp
          performingUser {
            displayName
            id
            emailAddress
          }
          remoteObjectType
          remoteObjectName
          remoteObjectId
          remoteObjectGuid
        }
      }
    }
  }
`;

export const allSystemPermissions = /* GraphQL */ `
  query () {
    allSystemPermissions() {
        entityName
        entityType
        readOnlyGroups
        elevatedGroups
        fullControlGroups
    }
  }
`;
