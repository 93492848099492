import React from "react";
import styled from "styled-components/macro";

const StandardElement = styled.div`
  margin-bottom: 1rem;
  margin-left: ${(props) => (props.indent ? "1rem" : "")};
`;

const ConditionalContainer = styled.div`
  padding: 1rem;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  border: 1px solid ${(props) => props.theme.surfaceAlt};
`;

const Break = styled.div`
  width: 100%;
`;

const ConditionOperation = ({ fragment, i, template }) => {
  const isLastFirstBracket =
    fragment.name === "(" && template[i + 1] && template[i + 1].name !== "(";

  const isLastLastBracket =
    fragment.name === ")" && template[i - 1] && template[i - 1].name !== ")";

  const isBlockedElement =
    fragment.name === "Then" ||
    fragment.name === "End If" ||
    fragment.name === "Else If";

  const isOrStatement = fragment.name === "Or";
  const isAndStatement = fragment.name === "And";

  const indent = isOrStatement || isAndStatement;

  return (
    <>
      {isBlockedElement ||
      isLastLastBracket ||
      isOrStatement ||
      isAndStatement ? (
        <Break />
      ) : null}
      <StandardElement isBlockedElement={isBlockedElement} indent={indent}>
        <ConditionalContainer>{fragment.name}</ConditionalContainer>
      </StandardElement>

      {isBlockedElement || isLastFirstBracket ? <Break /> : null}
    </>
  );
};

export default ConditionOperation;
