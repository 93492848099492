import React, { useState, useEffect } from "react";
import { useApi as useApiRedux } from "../../api/useApiRedux";
import debounce from "lodash.debounce";
import {
  FormControl,
  StyledSelect,
  Label,
} from "../../components/Form/FormControls";
import ErrorMessages from "../../components/Notifications/ErrorMessages";

const SearchPolicies = ({ ruleType, setMapId }) => {
  const [stateSources, setStateSources] = useState();
  const [{ errors, loading, data: sourceData }, getSources] = useApiRedux();

  useEffect(() => {
    if (sourceData) {
      const sourceOptions = sourceData?.data?.map((s) => {
        return {
          label: s?.name,
          value: s?.id,
        };
      });

      setStateSources(sourceOptions);
    } else {
      setStateSources([]);
    }
  }, [sourceData]);

  const [inputText, setInputText] = React.useState("");
  const [searchText, setSearchText] = React.useState("");

  useEffect(() => {
    if (searchText && ruleType) {
      getSources({
        query: `/search/businessrulestandards?query=${searchText}&ruleType=${ruleType}`,
        method: "GET",
      });
    } else {
      setStateSources([]);
    }
  }, [searchText, getSources, setStateSources, ruleType]);

  const setSearchTextDebounced = React.useRef(
    debounce((searchText) => setSearchText(searchText), 500)
  ).current;

  const handleInputChangePrimary = (inputText, event) => {
    // prevent outside click from resetting inputText to ""
    if (event.action !== "input-blur" && event.action !== "menu-close") {
      setInputText(inputText);
      setSearchTextDebounced(inputText);
    }
  };

  return (
    <div style={{ marginTop: "1rem" }}>
      <FormControl style={{ maxWidth: "300px" }}>
        <Label>Search</Label>
        <StyledSelect
          className={`react-select-container`}
          classNamePrefix={`react-select`}
          name={`source`}
          id={`searchName`}
          inputId={`searchName-input`}
          instanceId={`searchName-instance`}
          label="Sources"
          options={stateSources}
          placeholder={`Name`}
          menuPlacement="auto"
          inputValue={inputText}
          isLoading={loading}
          onChange={(e) => setMapId(e?.value)}
          onInputChange={handleInputChangePrimary}
          menuPortalTarget={document.body}
        />

        {errors ? <ErrorMessages errors={errors} /> : null}
      </FormControl>
    </div>
  );
};

export default SearchPolicies;
