import React from "react";
import { Route, Link, Switch, useParams, useLocation } from "react-router-dom";
import styled from "styled-components/macro";
import { useApi } from "../../api/useApi";
import MappingForm from "../../components/RuleImplementations/MappingForm";
import ViewMappedRule from "../../components/RuleFragment/ViewMappedRule";
import Button from "../../components/Button";
import SplashLoader from "../../components/Loaders/SplashLoader";
import { dataSourceStandardInstanceVersion } from "../../api/dataSourceQueries";
import ErrorMessages from "../../components/Notifications/ErrorMessages";
import {
  dataSourceManageFilters,
  dataSourceManagePolicies,
} from "../../common/paths";

const TableHeader = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;
const TableHeaderActions = styled.div`
  flex: 1;
  display: flex;
`;

const TableHeaderActionItems = styled.div`
  margin-left: auto;
`;

const ViewMapping = ({ sourceId, mappingType }) => {
  let params = useParams();
  let location = useLocation();

  const instanceVersionId = params?.instanceVersionId;

  //Get DataSource By Id
  const [{ loading, errors, data }] = useApi(
    dataSourceStandardInstanceVersion,
    {
      id: Number(sourceId),
      instanceVersionId: Number(instanceVersionId),
    }
  );

  if (loading) return <SplashLoader text={"Loading Policy"} />;
  if (errors) return <ErrorMessages errors={errors} />;

  //Standard Version
  const standardVersion =
    data?.dataSource?.ruleInstanceVersionById?.standardVersion;

  //Standard
  const standardId =
    data?.dataSource?.ruleInstanceVersionById?.standardVersion?.standard?.id;
  //Instance
  const instance = data?.dataSource?.ruleInstanceVersionById;

  // mapping instance title
  const instanceTitle =
    data?.dataSource?.ruleInstanceVersionById?.instance?.title;

  //Mappings
  const mappings = data?.dataSource?.ruleInstanceVersionById?.mappings;

  const calcMappings =
    data?.dataSource?.ruleInstanceVersionById?.calculationMappings;

  //Columns
  const columns = data?.dataSource?.columns;

  return (
    <>
      <Switch>
        <Route
          path={`/sources/:sourceId/manage/policies/:instanceId/:instanceVersionId/update`}
          component={() => {
            return (
              <>
                <TableHeader>
                  <h3>Map Form</h3>
                  <TableHeaderActions>
                    <TableHeaderActionItems>
                      <Link
                        to={dataSourceManagePolicies(
                          sourceId,
                          params?.reportId
                        )}
                      >
                        <Button type="button" danger>
                          Cancel Mapping
                        </Button>
                      </Link>
                    </TableHeaderActionItems>
                  </TableHeaderActions>
                </TableHeader>

                <MappingForm
                  columns={columns}
                  standardId={standardId}
                  sourceId={sourceId}
                  selectedInstance={instance}
                  mappingType={mappingType}
                />
              </>
            );
          }}
        />

        <Route
          path={`/sources/:sourceId/manage/filters/:instanceId/:instanceVersionId/update`}
          component={() => {
            return (
              <>
                <TableHeader>
                  <h3>Map Form</h3>
                  <TableHeaderActions>
                    <TableHeaderActionItems>
                      <Link
                        to={dataSourceManageFilters(sourceId, params?.reportId)}
                      >
                        <Button type="button" danger>
                          Cancel Mapping
                        </Button>
                      </Link>
                    </TableHeaderActionItems>
                  </TableHeaderActions>
                </TableHeader>

                <MappingForm
                  columns={columns}
                  standardId={standardId}
                  sourceId={sourceId}
                  selectedInstance={instance}
                  mappingType={mappingType}
                />
              </>
            );
          }}
        />

        <Route
          path={`/`}
          component={() => {
            return (
              <>
                <Link
                  to={
                    mappingType === "FILTER"
                      ? dataSourceManageFilters(sourceId, params?.reportId)
                      : dataSourceManagePolicies(sourceId, params?.reportId)
                  }
                >
                  <Button list type="button">
                    Back
                  </Button>
                </Link>

                <TableHeader>
                  <h3>{instanceTitle}</h3>
                  <TableHeaderActions>
                    <TableHeaderActionItems>
                      <Link to={`${location?.pathname}/update`}>
                        Update Mapping
                      </Link>
                    </TableHeaderActionItems>
                  </TableHeaderActions>
                </TableHeader>

                <ViewMappedRule
                  standard={standardVersion}
                  instance={instance}
                  mappings={mappings}
                  calcMappings={calcMappings}
                  isCrossTable={instance?.instance?.isACrosstabRuleInstance}
                  sourceId={sourceId}
                />
              </>
            );
          }}
        />
      </Switch>
    </>
  );
};

export default ViewMapping;
