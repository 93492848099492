import React, { useState, useContext, useEffect, useRef } from "react";
import { notifications } from "../api/subscriptions";
import Cookies from "js-cookie";
import { v4 as uuid } from "uuid";
import { AuthContext } from "../contexts/AuthContext";
import styled from "styled-components/macro";
import SpinningLoader from "../components/Loaders/SpinningLoader";

const ConnectionStatus = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100vw;
  z-index: 4;
`;

const ConnectionStatusWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ConnectionStatusMessage = styled.div`
  background: ${(props) => props.theme.onSurfaceLightSecondary};
  padding: 1rem;
  display: block;
`;

const ReconnectingServices = () => {
  return (
    <ConnectionStatus>
      <ConnectionStatusWrapper>
        <ConnectionStatusMessage>
          <SpinningLoader text="Connecting to Services" />
        </ConnectionStatusMessage>
      </ConnectionStatusWrapper>
    </ConnectionStatus>
  );
};

const protocolMessageTypes = {
  GQL_CONNECTION_INIT: "connection_init", // Client -> Server
  GQL_CONNECTION_ACK: "connection_ack", // Server -> Client
  GQL_CONNECTION_ERROR: "connection_error", // Server -> Client
  GQL_CONNECTION_TERMINATE: "connection_terminate", // Client -> Server
  GQL_CONNECTION_KEEP_ALIVE: "connection_keep_alive", // Client -> Server
  GQL_START: "start", // Client -> Server
  GQL_DATA: "data", // Server -> Client
  GQL_ERROR: "error", // Server -> Client
  GQL_COMPLETE: "complete", // Server -> Client
  GQL_STOP: "stop", // Client -> Server
};

export const SocketContext = React.createContext();

const sub = React.memo((props) => {
  const [lastDataSourceNotification, setLastDataSourceNotification] =
    useState(null);

  const [dataSourcePercentageComplete, setDataSourcePercentageComplete] =
    useState(null);

  //generic toast
  const [toastNotification, setToastNotification] = useState(null);

  //Tag State
  const [tagUpdateComplete, setTagUpdateComplete] = useState(null);

  //FEED Notifications
  const [feedNotification, setFeedNotification] = useState(null);
  const [feedExportNotification, setFeedExportNotification] = useState(null);
  const [exportNotification, setExportNotification] = useState(null);
  const [feedPercentNotification, setFeedPercentNotification] = useState(null);

  const [feedPreviewNotification, setFeedPreviewNotification] = useState(null);

  const [retrieveUVCNotification, setRetrieveUVCNotification] = useState(null);
  const [
    servicerTransferUniqueCollectionComplete,
    setServicerTransferUniqueCollectionComplete,
  ] = useState(null);

  const [failureContextNotification, setFailureContextNotification] =
    useState(null);

  const [browseNotification, setBrowseNotification] = useState(null);
  const [lastConnectionCreated, setLastConnectionCreated] = useState(null);

  //Workflow
  const [workflowCreateCompleteEvent, setWorkflowCreateCompleteEvent] =
    useState(null);

  const [workflowUpdateCompleteEvent, setWorkflowUpdateCompleteEvent] =
    useState(null);

  const [workflowPipelineCompleteEvent, setWorkflowPipelineCompleteEvent] =
    useState(null);

  const [workflowDataSourceCompleteEvent, setWorkflowDataSourceCompleteEvent] =
    useState(null);

  const [workflowPipelineFailEvent, setWorkflowPipelineFailEvent] =
    useState(null);

  const [workflowDataSourceFailEvent, setWorkflowDataSourceFailEvent] =
    useState(null);

  //ETL
  const [etlProviderBrowseCompleted, setEtlProviderBrowseCompleted] =
    useState(null);

  const [
    etlProviderPipelineBrowseCompleted,
    setEtlProviderPipelineBrowseCompleted,
  ] = useState(null);

  const [etlProviderCreateCompleted, setEtlProviderCreateCompleted] =
    useState(null);

  const [etlProviderUpdateCompleted, setEtlProviderUpdateCompleted] =
    useState(null);

  const [
    etlProviderInstanceCreateCompleted,
    setEtlProviderInstanceCreateCompleted,
  ] = useState(null);

  const [etlPipelineAddCompleteEvent, setEtlPipelineAddCompleteEvent] =
    useState(null);

  const [connectionImportFinished, setConnectionImportFinished] =
    useState(null);

  // Connections
  const [ocrRateLimitNotification, setOcrRateLimitNotification] =
    useState(null);

  // set default ready state to closed
  const [readyState, setReadyState] = useState(3);

  const { user } = useContext(AuthContext);

  const websocket = useRef(null);
  let timer = useRef(null);

  useEffect(() => {
    const token = Cookies.get("token");
    const setNotification = (event) => {
      const eventData = JSON.parse(event.data);

      if (eventData?.payload?.data?.onNotification?.payload) {
        const notification = eventData.payload.data.onNotification;
        notification.payload = JSON.parse(notification.payload);

        // Example, type reference file:
        // src/Libraries/DataAccess/Database/Schema/Types/AlertSourceTypes.cs
        // src/Libraries/DataAccess/Database/Schema/Types/AlertTypes.cs
        switch (notification.payload.SourceType) {
          // DataSource
          case 1: {
            switch (notification.payload.AlertType) {
              case 9: /* RetrieveRuleFailureContext */ {
                setFailureContextNotification(notification);
                break;
              }
              case 41: {
                setDataSourcePercentageComplete(notification);
                break;
              }
              default:
                setLastDataSourceNotification(notification);
                break;
            }
            break;
          }
          // Workflow
          case 2: {
            switch (notification.payload.AlertType) {
              case 22: {
                // workflowCreateCompleteEvent
                setWorkflowCreateCompleteEvent(notification);
                break;
              }
              case 15: {
                // workflowPipelineCompleteEvent
                setWorkflowPipelineCompleteEvent(notification);
                break;
              }
              default:
                break;
            }
            break;
          }
          // Provider 3
          // Provider Instance 4
          case 3 || 4: {
            switch (notification.payload.AlertType) {
              //EtlProviderBrowseCompleteEvent
              case 16: {
                setEtlProviderBrowseCompleted(notification);
                break;
              }
              //EtlProviderPipelineBrowseCompleteEvent
              case 19: {
                setEtlProviderPipelineBrowseCompleted(notification);
                break;
              }
              //etlproviderinstancecreatecompleteevent
              case 18: {
                setEtlProviderInstanceCreateCompleted(notification);
                break;
              }
              //etlprovidercreatecompleteevent
              case 17: {
                setEtlProviderCreateCompleted(notification);
                break;
              }
              //etlproviderupdatecompleteevent
              case 20: {
                setEtlProviderUpdateCompleted(notification);
                break;
              }
              default:
                break;
            }
            break;
          }
          // Connection
          case 5: {
            switch (notification.payload.AlertType) {
              case 5: {
                // browse complete
                setBrowseNotification(notification);
                break;
              }
              case 6: {
                // creation complete
                setLastConnectionCreated(notification);
                break;
              }
              case 7: {
                // source import finished
                setConnectionImportFinished(notification);
                break;
              }
              case 25: {
                // ocr rate limit hit
                setOcrRateLimitNotification(notification);
                break;
              }
              default:
                break;
            }
            break;
          }
          // Management
          case 6: {
            switch (notification.payload.AlertType) {
              default:
                break;
            }
            break;
          }
          // Servicer Transfer
          case 7: {
            switch (notification.payload.AlertType) {
              case 29: {
                // Processed Row Count
                setFeedPercentNotification(notification);
                break;
              }
              case 30: {
                // browse complete
                setFeedNotification(notification);
                break;
              }
              case 33: {
                // Feed Ready
                setFeedExportNotification(notification);
                break;
              }
              case 34: {
                // Feed Mapping Preview Result
                setFeedPreviewNotification(notification);
                break;
              }
              default:
                break;
            }
            break;
          }
          // Unique Value Collections
          case 8: {
            switch (notification.payload.AlertType) {
              // retrieve unique values collection
              case 31: {
                notification.payload.Values.map(
                  (v) => (v.Value = v.Value.Value)
                );
                setRetrieveUVCNotification(notification);
                break;
              }
              case 51: {
                notification.payload.Values.map(
                  (v) => (v.Value = v.Value.Value)
                );
                setServicerTransferUniqueCollectionComplete(notification);
                break;
              }
              default:
                break;
            }
            break;
          }
          // Tagging Source Type
          case 10: {
            // TagSourceTypes:  ds, policy, workflow and feed || 1,2,3,4
            switch (notification.payload.AlertType) {
              case 39: {
                // SourceTagUpdateCompleted
                setTagUpdateComplete(notification);
                // Updated Tags
                break;
              }
              default:
                break;
            }
            break;
          }
          // Shared
          case 12: {
            switch (notification.payload.AlertType) {
              case 50: {
                // Export Ready
                setExportNotification(notification);
                break;
              }
              case 53: {
                // Generic Toast
                setToastNotification(notification);
                break;
              }

              default:
                break;
            }
            break;
          }
          default:
            break;
        }
      } else {
        if (eventData.type === protocolMessageTypes.GQL_CONNECTION_ACK) {
          const UINotif = JSON.stringify({
            id: uuid(),
            type: protocolMessageTypes.GQL_START,
            payload: {
              query: notifications,
              variables: { authToken: token },
            },
          });
          setReadyState(websocket.current.readyState);
          websocket.current.send(UINotif);
        }
      }
    };

    function setupWebSocket() {
      let wssPath;

      if (process.env.NODE_ENV === "production") {
        wssPath = window.location.host;
      } else {
        wssPath = "localhost:40467";
      }

      const subscriptionURL = `wss://${wssPath}/graphql`;

      websocket.current = new WebSocket(subscriptionURL, ["graphql-ws"]);
      websocket.current.addEventListener("message", setNotification);
      websocket.current.addEventListener("open", function () {
        const join = JSON.stringify({
          type: protocolMessageTypes.GQL_CONNECTION_INIT,
          payload: {
            Authorization: token,
          },
        });

        websocket.current.send(join);
        //websocket.current.close();
      });

      websocket.current.addEventListener("close", function () {
        setReadyState(websocket.current.readyState);
        timer.current = setTimeout(() => {
          setupWebSocket();
        }, 10000);
      });
    }

    if (user) {
      setupWebSocket();

      return () => {
        if (websocket !== null && websocket.current) {
          websocket.current.removeEventListener("message", setNotification);
        }
        clearTimeout(timer.current);
      };
    }
  }, [user]);

  const value = React.useMemo(() => {
    return {
      lastDataSourceNotification,
      setLastDataSourceNotification,
      dataSourcePercentageComplete,
      setDataSourcePercentageComplete,
      readyState,
      setBrowseNotification,
      browseNotification,
      lastConnectionCreated,
      setLastConnectionCreated,
      etlProviderBrowseCompleted,
      etlProviderPipelineBrowseCompleted,
      etlProviderCreateCompleted,
      etlProviderUpdateCompleted,
      etlProviderInstanceCreateCompleted,
      setEtlProviderBrowseCompleted,
      setEtlProviderPipelineBrowseCompleted,
      setEtlProviderCreateCompleted,
      setEtlProviderUpdateCompleted,
      setEtlProviderInstanceCreateCompleted,
      etlPipelineAddCompleteEvent,
      setEtlPipelineAddCompleteEvent,
      connectionImportFinished,
      setConnectionImportFinished,
      workflowCreateCompleteEvent,
      setWorkflowCreateCompleteEvent,
      workflowUpdateCompleteEvent,
      setWorkflowUpdateCompleteEvent,
      workflowPipelineCompleteEvent,
      setWorkflowPipelineCompleteEvent,
      workflowDataSourceCompleteEvent,
      setWorkflowDataSourceCompleteEvent,
      workflowPipelineFailEvent,
      setWorkflowPipelineFailEvent,
      workflowDataSourceFailEvent,
      setWorkflowDataSourceFailEvent,
      failureContextNotification,
      setFailureContextNotification,
      ocrRateLimitNotification,
      setOcrRateLimitNotification,
      setFeedNotification,
      feedNotification,
      feedExportNotification,
      setFeedExportNotification,
      exportNotification,
      setExportNotification,
      feedPreviewNotification,
      setFeedPreviewNotification,
      feedPercentNotification,
      setFeedPercentNotification,
      retrieveUVCNotification,
      setRetrieveUVCNotification,
      tagUpdateComplete,
      setTagUpdateComplete,
      servicerTransferUniqueCollectionComplete,
      setServicerTransferUniqueCollectionComplete,
      toastNotification,
      setToastNotification,
    };
  }, [
    lastDataSourceNotification,
    setLastDataSourceNotification,
    dataSourcePercentageComplete,
    setDataSourcePercentageComplete,
    readyState,
    setBrowseNotification,
    browseNotification,
    lastConnectionCreated,
    setLastConnectionCreated,
    etlProviderBrowseCompleted,
    etlProviderPipelineBrowseCompleted,
    etlProviderCreateCompleted,
    etlProviderUpdateCompleted,
    etlProviderInstanceCreateCompleted,
    setEtlProviderBrowseCompleted,
    setEtlProviderPipelineBrowseCompleted,
    setEtlProviderCreateCompleted,
    setEtlProviderUpdateCompleted,
    setEtlProviderInstanceCreateCompleted,
    etlPipelineAddCompleteEvent,
    setEtlPipelineAddCompleteEvent,
    connectionImportFinished,
    setConnectionImportFinished,
    workflowCreateCompleteEvent,
    setWorkflowCreateCompleteEvent,
    workflowUpdateCompleteEvent,
    setWorkflowUpdateCompleteEvent,
    workflowPipelineCompleteEvent,
    setWorkflowPipelineCompleteEvent,
    workflowDataSourceCompleteEvent,
    setWorkflowDataSourceCompleteEvent,
    workflowPipelineFailEvent,
    setWorkflowPipelineFailEvent,
    workflowDataSourceFailEvent,
    setWorkflowDataSourceFailEvent,
    failureContextNotification,
    setFailureContextNotification,
    ocrRateLimitNotification,
    setOcrRateLimitNotification,
    setFeedNotification,
    feedNotification,
    feedExportNotification,
    setFeedExportNotification,
    exportNotification,
    setExportNotification,
    feedPreviewNotification,
    setFeedPreviewNotification,
    feedPercentNotification,
    setFeedPercentNotification,
    retrieveUVCNotification,
    setRetrieveUVCNotification,
    tagUpdateComplete,
    setTagUpdateComplete,
    servicerTransferUniqueCollectionComplete,
    setServicerTransferUniqueCollectionComplete,
    toastNotification,
    setToastNotification,
  ]);

  //TEMP NOT RENDER CHILDREN UNLESS WE HAVE A SOCKET CONNECTION
  const canRender = !user || (user && readyState < 3);

  return (
    <SocketContext.Provider value={value}>
      {!canRender && <ReconnectingServices />}
      {props.children}
    </SocketContext.Provider>
  );
});

export default sub;
