import { useContext } from "react";
import { SocketContext } from "../contexts/useSubscriptions";

const UseFailureContextNotifications = () => {
  const { failureContextNotification, setFailureContextNotification } =
    useContext(SocketContext);

  return { failureContextNotification, setFailureContextNotification };
};

export default UseFailureContextNotifications;
