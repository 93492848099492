import React, { useEffect } from "react";
import { useApi } from "../../api/useApi";
import { startDataSourceIngress } from "../../api/dataSourceMutations";
import SplashLoader from "../../components/Loaders/SplashLoader";
import styled from "styled-components/macro";
import useDataSourceNotifications from "../../Hooks/useDataSourceNotifications";
import ErrorMessages from "../../components/Notifications/ErrorMessages";
import DataSourceProcessing from "../../components/Widgets/DataSourceWidgets/DataSourceProcessing";

const UpdatingLoading = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 3;
  display: flex;
  background: rgba(255, 255, 255, 0.7);
`;

const StartDataSourceIngress = ({ sourceId, nextStep }) => {
  //Init Data Fetch
  const [{ errors }] = useApi(startDataSourceIngress, {
    dataSourceId: sourceId,
    referenceRefresh: true,
  });

  const { lastDataSourceNotification, setLastDataSourceNotification } =
    useDataSourceNotifications();

  useEffect(() => {
    const payload = lastDataSourceNotification?.payload;

    if (
      payload?.AlertType === 11 &&
      payload?.SourceId === sourceId &&
      payload?.Status === 1 &&
      payload?.ResultState === 101
    ) {
      //succeeded

      nextStep();
      setLastDataSourceNotification(null);
    } else if (
      payload?.AlertType === 11 &&
      payload?.SourceId === sourceId &&
      payload?.Status === 1 &&
      payload?.ResultState !== 101
    ) {
      //failed
      nextStep();
      //leave in for prod
      console.log("Ingress Runner failed to complete, please re-run config.");
      //HAndle Errors
      setLastDataSourceNotification(null);
    }
  }, [
    sourceId,
    lastDataSourceNotification,
    setLastDataSourceNotification,
    nextStep,
  ]);

  return (
    <>
      {errors ? <ErrorMessages errors={errors} /> : null}
      <UpdatingLoading>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <SplashLoader text="Processing Configuration" />
          <div
            style={{ display: "flex", justifyContent: "center", width: "100%" }}
          >
            <DataSourceProcessing sourceId={sourceId} />
          </div>
        </div>
      </UpdatingLoading>
    </>
  );
};

export default StartDataSourceIngress;
