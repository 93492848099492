import React, { useState } from "react";
import { MdChevronRight, MdExpandLess } from "react-icons/md";
import styled from "styled-components/macro";
import RenderPriority from "../../common/RenderPriority";
import PinButton from "../../components/Pin/PinButton";

const ImportantTextContainer = styled.div`
  padding: ${(props) => (props.showFull ? "0.25rem" : "")};
  padding-left: 0.5rem;
  position: relative;
  width: 100%;
  margin-bottom: 0.5rem;
  font-size: 0.85rem;
  overflow: ${(props) => (props.showFull ? "auto" : "hidden")};
  text-overflow: ${(props) => (props.showFull ? "auto" : "ellipsis")};
  white-space: ${(props) => (props.showFull ? "" : "nowrap")};
`;

const Importance = styled.div`
  width: 55%;
  height: ${(props) => (props.showFull ? "auto" : "45px")};
  position: absolute;
  top: 1rem;
  right: 13px;
  background: #ffffff;
  padding: 0.25rem;
  display: flex;
  z-index: 3;
  border-bottom: 1px solid #cccccc;
`;
const ImportanceOuter = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;
  border-left: 2px solid #ccc;
  padding-right: 3rem;
`;

const DataSourceImportance = ({
  priorityLevel,
  description,
  qualityImpact,
  sourceId,
}) => {
  const [showFullImportance, setShowFullImportance] = useState(false);
  return (
    <Importance showFull={showFullImportance}>
      <div
        onClick={() => setShowFullImportance((prevState) => !prevState)}
        style={{
          fontSize: "1.6rem",
          cursor: "pointer",
          paddingTop: ".25rem",
        }}
      >
        {showFullImportance ? <MdExpandLess /> : <MdChevronRight />}
      </div>
      <div
        style={{
          display: "flex",
          marginTop: ".65rem",
          marginRight: "1rem",
        }}
      >
        <div>Importance:</div>{" "}
        <div
          style={{
            display: "flex",
          }}
        >
          <RenderPriority priorityLevel={priorityLevel} bump={true} />
        </div>
      </div>
      <ImportanceOuter showFull={showFullImportance}>
        <div
          style={{
            position: "absolute",
            right: 0,
            zIndex: 2,
            background: "#ffffff",
          }}
        >
          <PinButton type="sources" item={sourceId} slim={true} />
        </div>

        <ImportantTextContainer showFull={showFullImportance}>
          <b style={{ fontSize: ".85rem" }}>Description:</b> {description}
        </ImportantTextContainer>

        <ImportantTextContainer showFull={showFullImportance}>
          <b style={{ fontSize: ".85rem" }}>Business Impact:</b> {qualityImpact}
        </ImportantTextContainer>
      </ImportanceOuter>
    </Importance>
  );
};

export default DataSourceImportance;
