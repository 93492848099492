import React from "react";
import Card from "../../components/Card";
import { MdArrowBack } from "react-icons/md";
import ActionWrapper from "../../components/ActionsWrapper";
import StyledLink from "../../components/StyledLink";
import { useApi } from "../../api/useApi";
import Spinner from "../../components/Loaders/Spinner";
import { workflowById } from "../../api/workflowQueries";
import ErrorMessages from "../../components/Notifications/ErrorMessages";
import WorkflowTabs from "./WorkflowTabs";

const BodyComponent = ({ workflowId, workflowData }) => {
  return <WorkflowTabs workflowId={workflowId} data={workflowData} />;
};

const WorkflowByIdComponent = (props) => {
  const workflowId = props?.match?.params?.workflowId;

  //Available Connections Graph
  const [{ loading, errors, data }, getWorkflow] = useApi(workflowById, {
    workflowId: Number(workflowId),
  });

  if (!workflowId) return;
  if (loading) return <Spinner />;
  if (errors) return <ErrorMessages errors={errors} />;

  const workflowData = data?.workflowById;
  const workflowTitle = workflowData?.name;

  //Body Section of Widget
  function Body() {
    return (
      <BodyComponent
        workflowId={workflowId}
        getWorkflow={getWorkflow}
        workflowData={workflowData}
      />
    );
  }

  function Actions() {
    return (
      <>
        <ActionWrapper>
          <StyledLink title="Back to List" to={`/workflows`}>
            <MdArrowBack />
          </StyledLink>
        </ActionWrapper>
      </>
    );
  }

  return (
    <Card
      title={workflowTitle && workflowTitle}
      titleDescription={"Workflow"}
      body={Body}
      actions={Actions}
    />
  );
};

export default WorkflowByIdComponent;
