// Get All Users
export const users = /* GraphQL */ `
  query (
    $first: Int
    $after: String
    $where: PlatformUserFilterInput
    $order: [PlatformUserSortInput!]
  ) {
    users(first: $first, after: $after, where: $where, order: $order) {
      totalCount
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      edges {
        cursor
        node {
          displayName
          emailAddress
          enabled
          externalUserId
          hasLoggedIn
          id
          isSystem
          role
          profilePhoto
          lastSignOnDate
        }
      }
    }
  }
`;

// Get All Users contained within an Enabled Workgroups
export const usersInEnabledWorkGroups = /* GraphQL */ `
  query (
    $first: Int
    $after: String
    $where: PlatformUserFilterInput
    $order: [PlatformUserSortInput!]
  ) {
    usersInEnabledWorkGroups(
      first: $first
      after: $after
      where: $where
      order: $order
    ) {
      totalCount
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      edges {
        cursor
        node {
          displayName
          emailAddress
          enabled
          externalUserId
          hasLoggedIn
          id
          isSystem
          role
          profilePhoto
          lastSignOnDate
        }
      }
    }
  }
`;

// Available Data Sources For User Id

// Get All Users contained within an Enabled Workgroups
export const availableDataSourcesForUserId = /* GraphQL */ `
  query (
    $first: Int
    $after: String
    $where: DataSourceFilterInput
    $order: [DataSourceSortInput!]
    $userId: Int!
  ) {
    availableDataSourcesForUserId(
      first: $first
      after: $after
      where: $where
      order: $order
      userId: $userId
    ) {
      totalCount
      edges {
        cursor
        node {
          id
          name
          permissionsForUser(userId: $userId) {
            accessingWorkGroup {
              displayName
            }
            permissionCode
          }
        }
      }
    }
  }
`;

// Get Available Data Sources Count
export const usersCount = /* GraphQL */ `
  query {
    usersCount
  }
`;

export const userQuery = /* GraphQL */ `
  query ($id: Int!) {
    userById(id: $id) {
      displayName
      emailAddress
      enabled
      externalUserId
      hasLoggedIn
      id
      isSystem
      role
      profilePhoto
      lastSignOnDate
    }
  }
`;

// Get Available Data Sources Count In Org
export const usersCountInOrganization = /* GraphQL */ `
  query ($organizationId: Int!) {
    usersCountInOrganization(organizationId: $organizationId)
  }
`;

// Get All Users
export const usersInOrganization = /* GraphQL */ `
  query ($organizationId: Int!, $cursor: CursorInput!) {
    usersInOrganization(organizationId: $organizationId, cursor: $cursor) {
      displayName
      emailAddress
      enabled
      externalUserId
      hasLoggedIn
      id
      isSystem
      role
      profilePhoto
      lastSignOnDate
    }
  }
`;

// Get Results From SQL Query User Account, basically an is Basecap user.
export const isBaseCapUser = /* GraphQL */ `
  query () {
    resultFromSqlQueryUserAccount()
  }
`;
