// Get Available Workgroups
export const availableWorkGroups = /* GraphQL */ `
  query(
    $first: Int
    $after: String
    $order: [WorkGroupSortInput!]
    $where: WorkGroupFilterInput
  ) {
    availableWorkGroups(
      first: $first
      after: $after
      order: $order
      where: $where
    ) {
      totalCount
      edges {
        cursor
        node {
          displayName
          enabled
          id
          type
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
    }
  }
`;

// Get Workgroup by ID
export const workGroupById = /* GraphQL */ `
  query($id: Int!) {
    workGroupById(id: $id) {
      displayName
      enabled
      id
      type
      excludedUsers {
        displayName
        emailAddress
        enabled
        externalUserId
        hasLoggedIn
        id
        isSystem
        role
        profilePhoto
      }
      users {
        displayName
        emailAddress
        enabled
        externalUserId
        hasLoggedIn
        id
        isSystem
        role
        profilePhoto
      }
      owners {
        displayName
        emailAddress
        enabled
        externalUserId
        hasLoggedIn
        id
        isSystem
        role
        profilePhoto
      }
      groups {
        displayName
        id
      }
    }
  }
`;
