// Get Platform Configuration
export const ConfigurationQueries = /* GraphQL */ `
  query {
    isProductConfigured
  }
`;

// Get Platform Configuration
export const platformConfiguration = /* GraphQL */ `
  query {
    platformConfiguration {
      adminEmailAddress
    }
  }
`;

// Is Product Configured
export const isProductConfigured = /* GraphQL */ `
  query {
    isProductConfigured
  }
`;

// Get All Working Day Holidays
export const allWorkingDayHolidays = /* GraphQL */ `
  query (
    $first: Int
    $after: String
    $order: [WorkingDayHolidayInputModelSortInput!]
  ) {
    allWorkingDayHolidays(first: $first, after: $after, order: $order) {
      totalCount
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      edges {
        cursor
        node {
          type
          baseHolidayName
          count
          cultureInfo
          day
          dayOfWeek
          direction
          enabled
          id
          month
          name
        }
      }
    }
  }
`;

// Get All Working Days
export const allWorkingDays = /* GraphQL */ `
  query (
    $first: Int
    $after: String
    $order: [WorkingDayInputModelSortInput!]
  ) {
    allWorkingDays(first: $first, after: $after, order: $order) {
      totalCount
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      edges {
        cursor
        node {
          dayOfWeek
          enabled
        }
      }
    }
  }
`;
