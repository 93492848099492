import React, { useState, useEffect } from "react";

import RenderDependentPipelines from "./RenderDependentPipelines";
import DataSources from "./DataSources";
import {
  MdCancel,
  MdCached,
  MdCheckCircle,
  MdArrowDownward,
  MdHourglassEmpty,
} from "react-icons/md";
import { AiOutlineCluster } from "react-icons/ai";
import styled from "styled-components/macro";
import {
  PipelineContainer,
  SegmentContainer,
  Segment,
  SegmentHeader,
  SegmentBody,
  DataSourceHeading,
  FieldLabel,
  DataSourceWrapper,
} from "./ViewWorkflowStyles";

import { runEtlPipeline } from "../../../api/etlProviderMutations";
import { useApi } from "../../../api/useApi";
import ErrorMessages from "../../../components/Notifications/ErrorMessages";
import Spinner from "../../../components/Loaders/Spinner";
import useSetWorkflowPipelineCompleteEvent from "../../../Hooks/useSetWorkflowPipelineCompleteEvent";
import useSetWorkflowPipelineFailEvent from "../../../Hooks/useSetWorkflowPipelineFailEvent";
import Secondary from "../../../components/Button/Secondary";

const Indicators = styled.div`
  align-items: center;
  display: flex;
  font-size: 0.875rem;
`;

const resultStateEnums = [
  { value: "NONE", label: "Waiting on Dependencies" },
  { value: "PIPELINE_QUEUED", label: "Queued" },
  { value: "PIPELINE_QUEUED_AND_ALERTED", label: "Queued And Alerted" },
  { value: "PIPELINE_STARTED", label: "Started" },
  { value: "PIPELINE_SUCCEEDED", label: "Success" },
  { value: "CONNECTION_FAILURE", label: "Connection Failure" },
  { value: "CANCELLED", label: "Cancelled" },
  { value: "PIPELINE_FAILED", label: "Failed" },
  { value: "GENERIC_FAILURE", label: "Failure" },
];

function showStateIcon(resultState) {
  switch (resultState) {
    case "NONE":
      return (
        <Indicators title="Failure">
          <div
            style={{
              marginRight: ".2rem",
              display: "flex",
              alignItems: "center",
            }}
          >
            <MdHourglassEmpty />
          </div>
          {
            resultStateEnums.find((resEnum) => resEnum.value === resultState)
              ?.label
          }
        </Indicators>
      );
    case "CONNECTION_FAILURE":
    case "CANCELLED":
    case "PIPELINE_FAILED":
    case "GENERIC_FAILURE":
      return (
        <Indicators style={{ color: "#f87e7e" }} title="Failure">
          <div
            style={{
              marginRight: ".2rem",
              display: "flex",
              alignItems: "center",
            }}
          >
            <MdCancel />
          </div>
          {
            resultStateEnums.find((resEnum) => resEnum.value === resultState)
              ?.label
          }
        </Indicators>
      );
    case "PIPELINE_QUEUED":
    case "PIPELINE_QUEUED_AND_STARTED":
    case "PIPELINE_STARTED":
      return (
        <Indicators style={{ color: "#009fd4" }} title="In Progress">
          <div
            style={{
              marginRight: ".2rem",
              display: "flex",
              alignItems: "center",
            }}
          >
            <MdCached />
          </div>

          {
            resultStateEnums.find((resEnum) => resEnum.value === resultState)
              ?.label
          }
        </Indicators>
      );
    case "PIPELINE_SUCCEEDED":
      return (
        <Indicators style={{ color: "#349004" }} title="Success">
          <div
            style={{
              marginRight: ".2rem",
              display: "flex",
              alignItems: "center",
            }}
          >
            <MdCheckCircle />
          </div>

          {
            resultStateEnums.find((resEnum) => resEnum.value === resultState)
              ?.label
          }
        </Indicators>
      );
    default:
      return null;
  }
}

const RenderSegment = ({ dependencies, segment, dispatch, stateSources }) => {
  const [sentETL, setSentETL] = useState(false);
  const [etlErrors, setEtlErrors] = useState(null);

  const [{ errors: runEtlErrors }, runEtl] = useApi();

  const { workflowPipelineCompleteEvent } =
    useSetWorkflowPipelineCompleteEvent();

  const { workflowPipelineFailEvent } = useSetWorkflowPipelineFailEvent();

  //updated event
  useEffect(() => {
    if (workflowPipelineFailEvent) {
      if (
        workflowPipelineFailEvent?.payload?.EtlPipelineName === segment?.name
      ) {
        setEtlErrors([
          {
            message: workflowPipelineFailEvent?.payload?.ErrorMessage,
          },
        ]);

        setSentETL(false);
      }
    }
  }, [workflowPipelineFailEvent, segment]);

  useEffect(() => {
    if (runEtlErrors?.length) {
      setSentETL(false);
    }
  }, [runEtlErrors]);

  //updated event
  useEffect(() => {
    if (workflowPipelineCompleteEvent) {
      if (
        workflowPipelineCompleteEvent?.payload?.EtlPipelineName ===
        segment?.name
      ) {
        setSentETL(false);
      }
    }
  }, [workflowPipelineCompleteEvent, segment]);

  return (
    <>
      {segment?.dependentPipelines?.length ? (
        <PipelineContainer>
          <RenderDependentPipelines
            dependencies={dependencies}
            pipelines={segment.dependentPipelines}
            dispatch={dispatch}
            stateSources={stateSources}
          />
        </PipelineContainer>
      ) : null}

      <SegmentContainer>
        <Segment>
          <SegmentHeader>
            <div style={{ flex: 1, display: "flex", alignItems: "center" }}>
              {showStateIcon(segment?.resultState)}
            </div>
            <div
              style={{
                marginLeft: "auto",
              }}
            >
              <Secondary
                title={"Start ETL"}
                disabled={sentETL}
                onClick={() => {
                  runEtl({
                    query: runEtlPipeline,
                    variables: { pipelineName: segment?.name },
                  });
                  setSentETL(true);
                }}
              >
                {sentETL ? <Spinner /> : " Run ETL"}
              </Secondary>
            </div>
          </SegmentHeader>
          <SegmentBody>
            {segment?.dependentDataSources?.length ? (
              <DataSources
                sources={segment?.dependentDataSources}
                dispatch={dispatch}
                segment={segment}
                stateSources={stateSources}
              />
            ) : null}
            <div
              style={{
                padding: "0",
                display: "flex",
                justifyContent: "center",
                fontSize: "1.25rem",
              }}
            >
              <MdArrowDownward />
            </div>
            <div style={{ padding: ".4rem" }}>
              <DataSourceWrapper>
                <DataSourceHeading>
                  <FieldLabel>
                    <AiOutlineCluster /> ETL
                  </FieldLabel>
                </DataSourceHeading>
                {segment?.name}
              </DataSourceWrapper>
            </div>
          </SegmentBody>
        </Segment>
      </SegmentContainer>
      {runEtlErrors && <ErrorMessages errors={runEtlErrors} />}
      {etlErrors && <ErrorMessages errors={etlErrors} />}
    </>
  );
};

export default RenderSegment;
