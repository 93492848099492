export default class ApiService {
  constructor() {
    // HTTP array of methods for posting to an endpoint.
    const methods = ["get", "post", "put", "patch", "delete"];
    methods.forEach((method) => {
      this[method] = (path, options) => {
        return new Promise((resolve, reject) => {
          const opts = {
            method,
          };
          const { query, headers, isJson, variables, signal } = options;

          opts.signal = signal;

          if (query) {
            if (isJson) {
              opts.body = query;
            } else {
              opts.body = JSON.stringify({
                query,
                variables: variables ? variables : undefined,
              });
            }
          }
          if (headers) {
            opts.headers = headers;
          }

          fetch(path, opts)
            .then(parseJSON)
            .then((response) => {
              if (response.ok) {
                return resolve(response.json);
              }
              // get the error
              return reject(response.json);
            })
            .catch((error) => {
              return reject(error ? error : "Error: Contact Support.");
            });
        });
      };
    });
  }
}

function parseJSON(response) {
  //handle empty responses
  const contentType = response.headers.get("content-type");
  if (!response.ok) {
    return new Promise((reject) => {
      if (!response.ok) {
        reject({
          ok: response.ok,
          json: { success: true, status: response.status },
          status: response.status,
        });
      }
    });
  } else if (contentType && contentType.indexOf("application/json") !== -1) {
    return new Promise((resolve) =>
      response.json().then((json) =>
        resolve({
          status: response.status,
          ok: response.ok,
          json,
        })
      )
    );
  } else {
    return new Promise((resolve) => {
      if (response.ok) {
        resolve({
          ok: response.ok,
          json: { success: true, status: response.status },
          status: response.status,
        });
      }
    });
  }
}
