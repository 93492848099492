import React, { useState, useEffect, useCallback, useContext } from "react";

import styled from "styled-components/macro";
import { MdError } from "react-icons/md";

import { useApi } from "../../../api/useApi";

import SortTable from "../../../components/Table/SortTable";

import Modal from "../../../components/Modal";

import { ActionsIcon } from "../../../components/Table/elements";
import SplashLoader from "../../../components/Loaders/SplashLoader";
import {
  dataSourceManagePoliciesStandardVersion,
  dataSourceFilterVersion,
} from "../../../common/paths";
import { dataSourceStandardsListWithColumns } from "../../../api/dataSourceQueries";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import columnSort from "../../../components/Table/helpers/columnSort";
import { AuthContext } from "../../../contexts/AuthContext";
import ErrorMessages from "../../../components/Notifications/ErrorMessages";
import StyledLink from "../../../components/StyledLink";
import TableButton from "../../../components/Button/TableButton";
import ViewMappingModal from "../../../views/RuleStandardPage/ViewMappingModal";

const WarningIcon = styled.span`
  color: orange;
`;
const PublishedIcon = styled.span`
  color: green;
`;

const ErrorIcon = styled.span`
  color: red;
`;

const AppliedRules = ({
  data: reportData,
  getUpdatedSource,
  sourceId,
  reportId,
  mappingType = "RULE",
}) => {
  //Available rule instances paged Via Graph

  const [{ errors, loading, data }] = useApi(
    dataSourceStandardsListWithColumns,
    {
      // first 9999 also fixes 3767 due to HC change of defaults
      first: 9999, //added as a hack to enable filtering until we get case-insens filtering
      id: Number(sourceId),
      reportId: reportId,
      where: { enabledState: { in: ["DRAFT", "PUBLISHED"] } },
    }
  );

  //currentPage

  const availRules = data?.dataSource?.ruleInstances?.edges ?? [];
  const totalCount = data?.dataSource?.ruleInstances?.totalCount;
  const pageInfo = data?.dataSource?.ruleInstances?.pageInfo;
  const columns = reportData?.dataSource?.columns ?? [];

  //Fetch for Table Paged
  // const fetchData = React.useCallback(
  //   ({ pageSize, cursor, sortBy }) => {
  //     const sortedObject = columnSort(sortBy);
  //     setCurrentView(cursor);
  //     getPagedInstances();
  //   },
  //   [getPagedInstances, sourceId, setCurrentView]
  // );

  const [showConfirm, setShowConfirm] = useState(false);

  let columnsData = [
    {
      Header: "Title",
      id: "title",
      accessor: (d) => d.node.title,

      Cell: ({ row: { original } }) => {
        const isRuleEnabled =
          original?.node?.latestVersion?.standardVersion?.standard?.enabled;

        return (
          <>
            <div style={{ fontSize: "1.2rem" }}>
              {!isRuleEnabled && (
                <ErrorIcon>
                  <MdError title="Policy has been Disabled" />
                </ErrorIcon>
              )}

              <StyledLink
                to={
                  mappingType === "FILTER"
                    ? dataSourceFilterVersion(
                        sourceId,
                        original?.node?.id,
                        original?.node?.latestVersion?.id
                      )
                    : dataSourceManagePoliciesStandardVersion(
                        sourceId,
                        original?.node?.id,
                        original?.node?.latestVersion?.id
                      )
                }
              >
                {original?.node?.title}
              </StyledLink>
              <p> {original?.node?.standard?.description}</p>
            </div>
          </>
        );
      },
    },
    {
      Header: "State",
      id: "enabledState",
      accessor: (d) => d.node.enabledState,
      Cell: ({ row: { original } }) => {
        const stateOfPolicy = original?.node?.enabledState;

        if (stateOfPolicy === "DRAFT") {
          return (
            <>
              <div style={{ fontSize: "1.2rem" }}>
                <ActionsIcon>
                  <WarningIcon>
                    <div title={"A Draft that will not run"}>
                      {stateOfPolicy}
                    </div>
                  </WarningIcon>
                </ActionsIcon>
              </div>
            </>
          );
        } else if (stateOfPolicy === "PUBLISHED") {
          return (
            <>
              <div style={{ fontSize: "1.2rem" }}>
                <ActionsIcon>
                  <PublishedIcon>
                    <div title={"A Production Rule"}>{stateOfPolicy}</div>
                  </PublishedIcon>
                </ActionsIcon>
              </div>
            </>
          );
        } else {
          return (
            <>
              <div style={{ fontSize: "1.2rem" }}>
                <ActionsIcon>
                  <div title={"A Disabled Rule"}>{stateOfPolicy}</div>
                </ActionsIcon>
              </div>
            </>
          );
        }
      },
    },
    {
      Header: "Mapped Columns",
      id: "columns",
      accessor: (d) => {
        //get the instance version latest
        const getVersionInstanceId = d?.node?.latestVersion?.id;
        //get the version
        const getVersion = d?.node?.versions.find(
          (v) => v?.id === getVersionInstanceId
        );
        //get the mappings
        const getVersionMappings = getVersion?.mappings ?? [];
        //get the mappings
        const getVersionCalcMappings = getVersion?.calculationMappings ?? [];
        // Merge the mappings
        const combinedMappings = [
          ...getVersionMappings,
          ...getVersionCalcMappings,
        ];

        const uniqueMappings = Array.from(
          new Set(combinedMappings.map((m) => m.columnId))
        ).map((id) => combinedMappings.find((m) => m.columnId === id));

        // Get column IDs
        const getColumnIds = uniqueMappings.map((m) => m.columnId);

        const getColumns =
          getColumnIds.map((cid) => {
            const col = columns.find((c) => c.id === cid);

            return col?.name;
          }) ?? [];

        return getColumns.join(", ");
      },
      Cell: ({ row: { original } }) => {
        //get the instance version latest
        const getVersionInstanceId = original?.node?.latestVersion?.id;
        //get the version
        const getVersion = original?.node?.versions.find(
          (v) => v?.id === getVersionInstanceId
        );
        //get the mappings
        const getVersionMappings = getVersion?.mappings ?? [];
        //get the mappings
        const getVersionCalcMappings = getVersion?.calculationMappings ?? [];
        // Merge the mappings
        const combinedMappings = [
          ...getVersionMappings,
          ...getVersionCalcMappings,
        ];

        const uniqueMappings = Array.from(
          new Set(combinedMappings.map((m) => m.columnId))
        ).map((id) => combinedMappings.find((m) => m.columnId === id));

        // Get column IDs
        const getColumnIds = uniqueMappings.map((m) => m.columnId);

        const getColumns =
          getColumnIds.map((cid) => {
            const col = columns.find((c) => c.id === cid);

            return col?.name;
          }) ?? [];

        return (
          <>
            <div style={{ fontSize: "1.2rem" }}>{getColumns.join(", ")}</div>
          </>
        );
      },
    },
    {
      Header: "",
      id: "manageMapping",
      sortable: false,
      Cell: ({ row: { original } }) => {
        const dataSourceId = original?.node?.dataSourceId;
        const ruleVersionId = original?.node?.latestVersion?.id;
        const ruleInstanceId = original?.node?.id;
        const failedRuleStandardId = original?.node?.standardId;

        return (
          <>
            <TableButton
              list
              onClick={() =>
                setShowConfirm({
                  sourceId: dataSourceId,
                  instanceVersionId: ruleVersionId,
                  ruleInstanceId: ruleInstanceId,
                  title: original?.title,
                  ruleId: failedRuleStandardId,
                })
              }
            >
              View Mapping
            </TableButton>
          </>
        );
      },
    },
  ];

  return (
    <>
      {errors ? <ErrorMessages errors={errors} /> : null}
      {showConfirm ? (
        <Modal title={showConfirm?.title} hide={() => setShowConfirm(null)}>
          <ViewMappingModal
            sourceId={showConfirm?.sourceId}
            instanceVersionId={showConfirm?.instanceVersionId}
            ruleInstanceId={showConfirm?.ruleInstanceId}
            ruleId={showConfirm?.ruleId}
            editEnabled={true}
          />
        </Modal>
      ) : null}

      <SortTable
        loading={loading}
        pageInfo={pageInfo}
        totalCount={totalCount}
        data={availRules}
        columns={columnsData}
        defaultPageSize={50}
      />
      <ToastContainer />
    </>
  );
};

export default AppliedRules;
