import { useEffect, useReducer, useState } from "react";
import Cookies from "js-cookie";

//get the secured token for api auth
function getSecuredToken() {
  return Cookies.get("token");
}

export function queryParamParser(variables) {
  const arragedVariables = variables.map((variable) => {
    return `$${variable.name}: ${variable.type}`;
  });
  return arragedVariables.toString();
}

export default function useGraphApi() {
  const [queryParams, setQueryParams] = useState({
    query: null,
    options: {},
    variables: undefined,
  });

  const initialState = {
    data: null,
    errors: null,
    loading: false,
  };

  //Reducer States for this component, Details what part of the state updates pending dispatch
  const reducer = (state, action) => {
    switch (action.type) {
      //First State called when a query is passed to the component.
      default:
        return {
          ...state,
          loading: true,
          errors: null,
        };
      //State dispatched after a query has been completed with a valid response
      //We handle error's here as well for partially incomplete data.
      case "FETCH_COMPLETE":
        return {
          ...state,
          data: action.payload.data,
          errors: action.payload && action.payload.errors,
          loading: false,
        };
      //State where the query has failed, this also handles server error state
      case "FETCH_FAILURE":
        return {
          ...state,
          loading: false,
          errors: action.payload
            ? [{ message: action.payload }]
            : [{ message: "Error Occurred" }],
        };
    }
  };

  //initialize the reducer state
  const [state, dispatch] = useReducer(reducer, initialState);

  //On initial load of this component WHEN a query has been passed in or changed.
  useEffect(() => {
    const { query, options, variables } = queryParams;
    if (!query) return;

    let abortController = new AbortController();

    const graphUrl = `/graphql`;

    //Create Header object with valid auth token if secure (secure by default)
    const header = {
      "Content-Type": "application/json",
      Accept: "application/json",
      ...(options.secure && { Authorization: `Bearer ${getSecuredToken()}` }),
    };

    //Since we are using graph and this is a useGraph Hook, we'll default to post method
    const defaultOptions = {
      method: "POST",
      headers: header,
      body: JSON.stringify({
        query,
        variables: variables ? variables : undefined,
      }),
    };

    //merge options passed into function
    let actualOptions = Object.assign({}, defaultOptions, options);

    //async function calls itself on load here
    (async () => {
      let data = null;
      //Create the actual fetch
      try {
        dispatch({
          type: "INIT_FETCH",
          payload: data,
        });
        const response = await fetch(graphUrl, {
          ...actualOptions,
          signal: abortController.signal,
        });

        //handle a response
        if (!response.ok || !response.body) {
          throw response.statusText;
        }

        //check for json type in the content type to handle the two types of responses
        const contentType = response.headers.get("content-type");

        if (contentType.indexOf("application/json") !== -1) {
          data = await response.json();
        } else {
          data = await response.text();
        }

        //call the complete function, update the state
        dispatch({
          type: "FETCH_COMPLETE",
          payload: data,
        });
      } catch (err) {
        //if the singal has not been aborted (component unmounted) set
        // an error to the current component state
        if (!abortController.signal.aborted)
          dispatch({ type: "FETCH_FAILURE", payload: err });
      }
    })();

    // abort any signal left over on unmount
    return () => {
      abortController.abort();
    };
  }, [queryParams]);

  const doFetch = (query, variables, options = { secure: true }) => {
    setQueryParams({ query, variables, options });
  };

  return {
    apiData: state.data,
    errors: state.errors,
    loading: state.loading,
    doFetch,
  };
}
