import { AiChat, AiChatToggle } from ".";
import { useEffect, useState } from "react";
import { useContext } from "react";
import { PolicyChatContext } from "../../contexts/ai/PolicyChatContext";

const PolicyChat = () => {
  const { isLoading, messages, generatePolicy, reset } =
    useContext(PolicyChatContext);
  const [isVisible, setIsVisible] = useState(false);
  const toggle = () => setIsVisible((prev) => !prev);

  useEffect(() => reset, []);

  return (
    <>
      <AiChat
        messages={messages}
        isLoading={isLoading}
        isVisible={isVisible}
        sendMessage={generatePolicy}
        reset={reset}
      />
      <AiChatToggle isVisible={isVisible} toggle={toggle} />
    </>
  );
};

export default PolicyChat;
