import React from "react";

import {
  actions,
  functionalUpdate,
  ensurePluginOrder,
  useGetLatest,
  useMountedLayoutEffect,
} from "react-table";

function expandRows(
  rows,
  { manualExpandedKey, expanded, expandSubRows = true }
) {
  const expandedRows = [];

  const handleRow = (row) => {
    row.isExpanded =
      (row.original && row.original[manualExpandedKey]) || expanded[row.id];

    row.canExpand = row.subRows && !!row.subRows.length;

    expandedRows.push(row);

    if (expandSubRows && row.subRows && row.subRows.length && row.isExpanded) {
      row.subRows.forEach(handleRow);
    }
  };

  rows.forEach(handleRow);

  return expandedRows;
}

const pluginName = "usePageCursor";

// Actions
actions.resetPage = "resetPage";
actions.gotoPage = "gotoPage";
actions.setPageSize = "setPageSize";
actions.setPreviousCursors = "setPreviousCursors";

export const usePageCursor = (hooks) => {
  hooks.stateReducers.push(reducer);
  hooks.useInstance.push(useInstance);
};

usePageCursor.pluginName = pluginName;

function reducer(state, action, previousState, instance) {
  if (action.type === actions.init) {
    return {
      pageIndex: 0,
      next: null,
      previousCursors: [],
      nextCursor: null,
      ...state,
    };
  }

  if (action.type === actions.resetPage) {
    return {
      ...state,
      pageIndex: instance.initialState.pageIndex || 0,
      next: null,
    };
  }

  if (action.type === actions.gotoPage) {
    const { pageCount, rows } = instance;
    const newPageIndex = functionalUpdate(action.pageIndex, state.pageIndex);

    if (newPageIndex < 0 || newPageIndex > pageCount - 1) {
      return state;
    }

    //handle lastItem's
    let itemCursor;
    if (instance?.endCursor) {
      itemCursor = instance?.endCursor ?? null;
    } else {
      itemCursor = rows[rows.length - 1]?.original.cursor ?? null;
    }

    if (action.next) {
      return {
        ...state,
        pageIndex: newPageIndex,
        cursor: itemCursor,
        previousCursors: [...state.previousCursors, state.cursor],
      };
    } else {
      return {
        ...state,
        pageIndex: newPageIndex,
        cursor: newPageIndex
          ? state.previousCursors[state.previousCursors.length - 1]
          : null,
        previousCursors: [...state.previousCursors]
          .reverse()
          .slice(1)
          .reverse(),
      };
    }
  }

  if (action.type === actions.setPageSize) {
    const { pageSize } = action;
    const topRowIndex = state.pageSize * state.pageIndex;
    const pageIndex = Math.floor(topRowIndex / pageSize);

    return {
      ...state,
      pageIndex,
      pageSize,
    };
  }
}

function useInstance(instance) {
  const {
    rows,
    autoResetPage = true,
    manualExpandedKey = "expanded",
    plugins,
    totalCount: userTotalCount,
    paginateExpandedRows = true,
    expandSubRows = true,
    state: {
      pageSize,
      pageIndex,
      expanded,
      globalFilter,
      filters,
      groupBy,
      sortBy,
    },
    dispatch,
    data,
    manualPagination,
    manualGlobalFilter,
    manualFilters,
    manualGroupBy,
    manualSortBy,
  } = instance;

  ensurePluginOrder(
    plugins,
    ["useGlobalFilter", "useFilters", "useGroupBy", "useSortBy", "useExpanded"],
    "usePageCursor"
  );

  const getAutoResetPage = useGetLatest(autoResetPage);

  useMountedLayoutEffect(() => {
    if (getAutoResetPage()) {
      dispatch({ type: actions.resetPage });
    }
  }, [
    dispatch,
    manualPagination ? null : data,
    manualPagination || manualGlobalFilter ? null : globalFilter,
    manualPagination || manualFilters ? null : filters,
    manualPagination || manualGroupBy ? null : groupBy,
    manualPagination || manualSortBy ? null : sortBy,
  ]);

  const pageCount = manualPagination
    ? Math.ceil(userTotalCount / pageSize)
    : Math.ceil(rows.length / pageSize);

  const pageOptions = React.useMemo(
    () => (pageCount > 0 ? [...new Array(pageCount)].map((d, i) => i) : []),
    [pageCount]
  );

  const page = React.useMemo(() => {
    let page;

    if (manualPagination) {
      page = rows;
    } else {
      const pageStart = pageSize * pageIndex;
      const pageEnd = pageStart + pageSize;

      page = rows.slice(pageStart, pageEnd);
    }

    if (paginateExpandedRows) {
      return page;
    }

    return expandRows(page, { manualExpandedKey, expanded, expandSubRows });
  }, [
    expandSubRows,
    expanded,
    manualExpandedKey,
    manualPagination,
    pageIndex,
    pageSize,
    paginateExpandedRows,
    rows,
  ]);

  const canPreviousPage = pageIndex > 0;
  const canNextPage = pageCount === -1 || pageIndex < pageCount - 1;

  const gotoPage = React.useCallback(
    ({ pageIndex, next }) => {
      dispatch({ type: actions.gotoPage, pageIndex, next });
    },
    [dispatch]
  );

  const previousPage = React.useCallback(() => {
    return gotoPage({ pageIndex: (old) => old - 1, next: null });
  }, [gotoPage]);

  const nextPage = React.useCallback(() => {
    return gotoPage({ pageIndex: (old) => old + 1, next: true });
  }, [gotoPage]);

  const setPageSize = React.useCallback(
    (pageSize) => {
      dispatch({ type: actions.setPageSize, pageSize });
    },
    [dispatch]
  );

  Object.assign(instance, {
    pageOptions,
    pageCount,
    page,
    canPreviousPage,
    canNextPage,
    gotoPage,
    previousPage,
    nextPage,
    setPageSize,
  });
}
