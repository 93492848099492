import React, { useState, useCallback, useMemo, useEffect } from "react";
import { useApi } from "../../api/useApi";
import {
  StyledSelect,
  FormControl,
  StyledInput,
} from "../../components/Form/FormControls";
import PagedTable from "../../components/Table/PagedTable";
import { availableWorkGroups } from "../../api/workgroupQueries";
import { useApi as useApiRedux } from "../../api/useApiRedux";
import debounce from "lodash.debounce";
import ErrorMessages from "../../components/Notifications/ErrorMessages";
import PagedTableRedux from "../../components/Table/PagedTableRedux";

// ENUM for permissions
// None = 0,
// Read = 1,
// Write = 2,
// Execute = 4,
// Grant = 8
const permissionRoles = [
  { label: "Full", value: 15 },
  { label: "Elevated", value: 7 },
  { label: "Read Only", value: 1 },
  { label: "None", value: 0 },
];

const StaticGroupSelect = ({ props }) => {
  const { permissions, dispatch, callFn } = props;

  const setUpPermissions = (permissionUpdate) => {
    const { value, groupId } = permissionUpdate;
    const existingPermissions = [...permissions];
    const existingIndex = permissions.findIndex(
      (perm) => perm.accessingWorkGroupId === groupId
    );

    if (existingIndex === -1) {
      existingPermissions.push({
        accessingWorkGroupId: groupId,
        permissionCode: value.value,
      });
    } else {
      existingPermissions[existingIndex].permissionCode = value.value;
    }
    return existingPermissions;
  };

  const updateField = (permissionUpdate) => {
    const permissionsUpdated = setUpPermissions(permissionUpdate);
    if (callFn) {
      callFn(permissionsUpdated);
    } else {
      dispatch({ type: "SET_PERMISSIONS", payload: permissionsUpdated });
    }
  };

  const columnsData = [
    {
      Header: "Permission Groups",
      id: "group",
      accessor: (d) => d?.node?.displayName,
    },
    {
      Header: "Access",
      id: "access",
      accessor: (d) => {
        const foundPermissions = permissions.find(
          (perm) => perm.accessingWorkGroupId === d?.node?.id
        );

        return foundPermissions ? foundPermissions.permissionCode : null;
      },
      Cell: ({ row: { original } }) => {
        const foundPermissions = permissions.find(
          (perm) => perm.accessingWorkGroupId === original?.node?.id
        );

        let selectedRole;
        if (foundPermissions) {
          selectedRole = permissionRoles.find(
            (pr) => pr.value === foundPermissions.permissionCode
          );
        } else {
          selectedRole = null;
        }

        return (
          <StyledSelect
            id={`${original?.node?.displayName}`}
            inputId={`${original?.node?.displayName}-PermissionSelect`}
            instanceId={`${original?.node?.displayName}-PermissionSelect-instance`}
            className={`react-select-container`}
            classNamePrefix={`react-select`}
            name={`permission`}
            menuPortalTarget={document.body}
            placeholder={`Permission`}
            label="Permission"
            value={selectedRole}
            data-testid={`${original?.node?.displayName}-PermissionSelect`}
            options={permissionRoles}
            onChange={(e) =>
              updateField({ value: e, groupId: original?.node?.id })
            }
          />
        );
      },
    },
  ];

  const [{ loading, data }, doFetch] = useApi();

  const groups = data?.availableWorkGroups?.edges ?? [];
  const totalCount = data?.availableWorkGroups?.totalCount;
  const pageInfo = data?.availableWorkGroups?.pageInfo;

  const workgroups = groups.sort(function (a, b) {
    if (a.type > b.type) {
      return -1;
    }
    if (a.type < b.type) {
      return 1;
    }
    return 0;
  });

  const enabledWorkgroups = workgroups.filter(({ node }) => node?.enabled);

  //Fetch for Table Paged
  const fetchData = useCallback(
    ({ pageSize, cursor }) => {
      doFetch({
        query: availableWorkGroups,
        variables: {
          first: pageSize,
          after: cursor,
          order: {
            displayName: "ASC",
          },
        },
      });
    },
    [doFetch]
  );

  return (
    <PagedTable
      fetchData={fetchData}
      loading={loading}
      pageInfo={pageInfo}
      totalCount={totalCount}
      data={enabledWorkgroups}
      columns={columnsData}
      defaultPageSize={50}
    />
  );
};

const SearchGroups = React.memo(({ SetIsSearching, props }) => {
  const [userData, setUserData] = useState([]);

  const [
    { errors: getUserErrors, loading: loadingUsers, data: usersDataFetch },
    getUsers,
  ] = useApiRedux();

  const [searchText, setSearchText] = useState("");
  const [inputValue, setInputValue] = useState("");

  const debouncedSave = useMemo(
    () => debounce((newValue) => setSearchText(newValue), 1000),
    []
  );

  const updateValue = (newValue) => {
    setInputValue(newValue);
    debouncedSave(newValue);
  };

  //Fetch for Table Paged
  const fetchData = useCallback(
    ({ params }) => {
      if (searchText) {
        getUsers({
          query: `/search/workgroups`,
          params: { query: searchText, ...params },
          method: "GET",
        });
      }
    },
    [getUsers, searchText]
  );

  useEffect(() => {
    if (searchText) {
      SetIsSearching(true);
    } else {
      SetIsSearching(false);
    }
  }, [searchText, SetIsSearching]);

  const totalCount = usersDataFetch?.metadata?.availableItems;

  const { permissions, dispatch, callFn } = props;

  const setUpPermissions = (permissionUpdate) => {
    const { value, groupId } = permissionUpdate;
    const existingPermissions = [...permissions];
    const existingIndex = permissions.findIndex(
      (perm) => perm.accessingWorkGroupId === groupId
    );

    if (existingIndex === -1) {
      existingPermissions.push({
        accessingWorkGroupId: groupId,
        permissionCode: value.value,
      });
    } else {
      existingPermissions[existingIndex].permissionCode = value.value;
    }
    return existingPermissions;
  };

  const updateField = (permissionUpdate) => {
    const permissionsUpdated = setUpPermissions(permissionUpdate);
    if (callFn) {
      callFn(permissionsUpdated);
    } else {
      dispatch({ type: "SET_PERMISSIONS", payload: permissionsUpdated });
    }
  };

  const columnsData = [
    {
      Header: "Permission Groups",
      id: "group",
      accessor: (d) => d?.displayName,
    },
    {
      Header: "Access",
      id: "access",
      accessor: (d) => {
        const foundPermissions = permissions.find(
          (perm) => perm.accessingWorkGroupId === d?.id
        );

        return foundPermissions ? foundPermissions.permissionCode : null;
      },
      Cell: ({ row: { original } }) => {
        const foundPermissions = permissions.find(
          (perm) => perm.accessingWorkGroupId === original?.id
        );

        let selectedRole;
        if (foundPermissions) {
          selectedRole = permissionRoles.find(
            (pr) => pr.value === foundPermissions.permissionCode
          );
        } else {
          selectedRole = null;
        }

        return (
          <StyledSelect
            id={`${original?.node?.displayName}`}
            inputId={`${original?.node?.displayName}-PermissionSelect`}
            instanceId={`${original?.node?.displayName}-PermissionSelect-instance`}
            className={`react-select-container`}
            classNamePrefix={`react-select`}
            name={`permission`}
            menuPortalTarget={document.body}
            placeholder={`Permission`}
            label="Permission"
            value={selectedRole}
            options={permissionRoles}
            onChange={(e) => updateField({ value: e, groupId: original?.id })}
          />
        );
      },
    },
  ];

  const [viewState] = useState({
    page: 1,
    size: 25,
  });

  useEffect(() => {
    if (usersDataFetch) {
      const availUsers = usersDataFetch?.data ?? [];
      setUserData(availUsers);
    }
  }, [usersDataFetch]);

  return (
    <>
      <FormControl>
        <StyledInput
          type="text"
          name="searchGroups"
          label="Search Groups"
          placeholder={`Search by Name`}
          value={inputValue}
          onChange={(input) => updateValue(input.target.value)}
        />

        {getUserErrors ? <ErrorMessages errors={getUserErrors} /> : null}
      </FormControl>
      {inputValue ? (
        <PagedTableRedux
          key={"viewState" + JSON.stringify(viewState)}
          fetchData={fetchData}
          totalCount={totalCount}
          loading={loadingUsers}
          data={userData}
          columns={columnsData}
          noMargin={true}
          defaultPageSize={50}
          initParams={viewState}
        />
      ) : null}
    </>
  );
});

export default React.memo((props) => {
  const [isSearching, SetIsSearching] = useState();
  return (
    <>
      <SearchGroups SetIsSearching={SetIsSearching} props={props} />
      {!isSearching ? <StaticGroupSelect props={props} /> : null}
    </>
  );
});
