import React, { useEffect, useState } from "react";
import { Formik, Field } from "formik";
import {
  FormControl,
  FormActions,
  StyledSelect,
  Label,
} from "../../Form/FormControls";
import Button from "../../Button";
import Spinner from "../../Loaders/Spinner";
import { setDataSourceMetadata } from "../../../api/dataSourceMutations";
import SpinningLoader from "../../Loaders/SpinningLoader";
import { useApi } from "../../../api/useApi";
import ErrorMessages from "../../Notifications/ErrorMessages";
import styled from "styled-components/macro";
import { format } from "date-fns-tz";

const RadialSelectInline = styled.label`
  display: inline-block;
  margin-right: 0.8rem;
`;

const RadialSelectBlock = styled.label`
  display: grid;
  text-align: center;
`;

const RadialSelect = styled.label`
  display: block;
  margin-bottom: 1rem;
`;

const ActiveMonitoringNote = styled.div`
  font-size: 0.8125rem;
  margin: 0.25rem 0 0 1.35rem;
  color: #070707;
`;

//a bit of a mismatch here between whats used and what is available.
const freqOptions = [
  { incomingValue: 1, value: "HOURLY", label: "Hourly" },
  { incomingValue: 24, value: "DAILY", label: "Daily" },
  { incomingValue: 168, value: "WEEKLY", label: "Weekly" },
  { incomingValue: 720, value: "MONTHLY", label: "Monthly" },
  { incomingValue: 256204778.80152154, value: "NEVER", label: "Never" },
];

const schedOptions = [
  { value: "HOURLY", label: "Hourly" },
  { value: "DAILY", label: "Daily" },
  { value: "WEEKLY", label: "Weekly" },
  { value: "NEVER", label: "Never" },
];

const holidayOptions = [
  { value: "DEFAULT", label: "Default" },
  { value: "WORKING_DAYS_ONLY", label: "Observes Working Days Only" },
  { value: "HOLIDAYS_ONLY", label: "Observes Holidays Only" },
  {
    value: "BOTH",
    label: "Observes Both Working Days and Holidays",
  },
];

const minuteOptions = [
  { value: "0", label: ":00" },
  { value: "1", label: ":15" },
  { value: "2", label: ":30" },
  { value: "3", label: ":45" },
];

const ampmOptions = [
  { value: true, label: "AM" },
  { value: false, label: "PM" },
];

const hourOptions = [
  { value: "1", label: "1" },
  { value: "2", label: "2" },
  { value: "3", label: "3" },
  { value: "4", label: "4" },
  { value: "5", label: "5" },
  { value: "6", label: "6" },
  { value: "7", label: "7" },
  { value: "8", label: "8" },
  { value: "9", label: "9" },
  { value: "10", label: "10" },
  { value: "11", label: "11" },
  { value: "12", label: "12" },
];

const formatFreqDate = [
  { value: "HOURLY", label: ":mm" },
  { value: "DAILY", label: "h:mm a" },
  { value: "WEEKLY", label: "iii, h:mm a" },
  { value: "MONTHLY", label: "Monthly" },
  { value: "NONE", label: "Disable" },
];

const archiveActions = [
  { value: "NONE", label: "NONE" },
  { value: "DELETE", label: "DELETE" },
  { value: "MOVE", label: "MOVE" },
];

const DataRefreshForm = ({ apiData, getUpdatedSource }) => {
  const [startingAdd, setStartingAdd] = useState(false);

  const currentActiveMonitoringSetting = apiData?.activeMonitoring?.toString();

  const nextScheduledReportTime = apiData?.nextScheduledReport
    ? new Date(apiData?.nextScheduledReport)
    : null;

  const currentFreq = freqOptions.find(
    (option) =>
      option.incomingValue === apiData?.scheduledJob?.recurrenceInHours
  );

  const nextScheduledReport = apiData?.nextScheduledReport;
  const description = (apiData && apiData.description) || "";
  const dataSourceId = (apiData && apiData.id) || null;

  //check for ingress info
  let ingressInfo = apiData?.ingressInfo
    ? Object.assign({}, apiData.ingressInfo)
    : null;

  if (ingressInfo?.archiveAction) {
    ingressInfo.archiveAction = archiveActions.find(
      (aa) => aa.value === ingressInfo.archiveAction
    );
  }

  const [
    { errors: setErrors, loading: setLoading, data: setData },
    updateSettings,
  ] = useApi();

  useEffect(() => {
    if (!setData && setErrors) {
      setStartingAdd(false);
    }
  }, [setData, setErrors, setStartingAdd]);

  useEffect(() => {
    if (setData && !setErrors) {
      getUpdatedSource();
    }
  }, [setData, setErrors, getUpdatedSource]);

  if (setLoading) return <SpinningLoader text="Updating Data Refresh" />;

  const getZone = format(new Date(), "XXX", {
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  });

  return (
    <>
      <h3>DATA REFRESH INTERVAL</h3>

      <Formik
        initialValues={{
          activeMonitoring: currentActiveMonitoringSetting,
          freq: currentFreq ?? { value: "HOURLY", label: "Hourly" },
          zone: getZone,
          isAm: nextScheduledReportTime
            ? format(nextScheduledReportTime, "aaaa")?.toString() === "a.m."
              ? { value: true, label: "AM" }
              : { value: false, label: "PM" }
            : { value: false, label: "PM" },
          hour: nextScheduledReportTime
            ? hourOptions.find(
                (o) =>
                  o.value === format(nextScheduledReportTime, "h")?.toString()
              )
            : { value: "1", label: "1" },
          min: nextScheduledReportTime
            ? minuteOptions.find(
                (j) =>
                  j.label.slice(1) ===
                  format(nextScheduledReportTime, "m")?.toString()
              ) ?? { value: "0", label: ":00" }
            : { value: "0", label: ":00" },
          dayofweek: nextScheduledReportTime
            ? format(nextScheduledReportTime, "EEEE")?.toString() ?? "SUNDAY"
            : "SUNDAY",
          edit: true,
          ingressInfo: ingressInfo,
          workingDayObservationTypes:
            apiData?.scheduledJob?.workingDayObservation ?? "DEFAULT",
        }}
        enableReinitialize
        // validateOnMount={true}
        // validationSchema={Yup.object().shape({
        //   freq: Yup.string().when("activeMonitoring", {
        //     is: "false",
        //     then: Yup.string().required("Required"),
        //   }),
        // })}
        onSubmit={(values) => {
          let ingressInfo = null;
          if (values.ingressInfo) {
            const { targetConnection, sourceId, id, ...cleanIngress } =
              values.ingressInfo;
            ingressInfo = values.ingressInfo
              ? Object.assign(cleanIngress)
              : null;

            ingressInfo.archiveAction = ingressInfo?.archiveAction?.value;

            if (
              ingressInfo?.ingressDetailsType === "CHARACTER_SEPARATED_FILE"
            ) {
              delete ingressInfo.fileIngressDetails;
              ingressInfo.fileIngressDetails = null;
              ingressInfo.characterSeparatedFileInfo =
                ingressInfo.characterFileIngressDetails;
              delete ingressInfo.characterFileIngressDetails;
            } else if (ingressInfo?.ingressDetailsType === "EXCEL_FILE") {
              ingressInfo.fileIngressDetails = ingressInfo.fileIngressDetails;
              ingressInfo.characterSeparatedFileInfo = null;
              delete ingressInfo.characterFileIngressDetails;
            }
          }

          let variables;
          if (values?.activeMonitoring === "true") {
            //Set to Active Monitoring
            variables = {
              id: dataSourceId,
              ingressInfo: ingressInfo,
              description: description,
              refreshInfo: {
                activeMonitoring: values?.activeMonitoring ? true : false,
              },
            };
          } else if (values?.freq?.value === "HOURLY") {
            //Set to Hourly

            variables = {
              id: dataSourceId,
              ingressInfo: ingressInfo,
              description: description,
              refreshInfo: {
                activeMonitoring: false,
                frequency: values?.freq?.value,
                minutes: Number(values?.min?.value),
                timeZone: values?.zone,
                workingDayObservationTypes: values?.workingDayObservationTypes,
              },
            };
          } else if (values?.freq?.value === "DAILY") {
            //Set to Daily

            variables = {
              id: dataSourceId,
              ingressInfo: ingressInfo,
              description: description,
              refreshInfo: {
                activeMonitoring: false,
                frequency: values?.freq?.value,
                hour: values?.hour?.value ? Number(values?.hour?.value) : 0,
                isAM: values?.isAm?.value,
                minutes: Number(values?.min?.value),
                timeZone: values?.zone,
                workingDayObservationTypes: values?.workingDayObservationTypes,
              },
            };
          } else if (values?.freq?.value === "WEEKLY") {
            //Set to Weekly

            variables = {
              id: dataSourceId,
              ingressInfo: ingressInfo,
              description: description,
              refreshInfo: {
                activeMonitoring: false,
                frequency: values?.freq?.value,
                hour: values?.hour?.value ? Number(values?.hour?.value) : 0,
                isAM: values?.isAm?.value,
                minutes: Number(values?.min?.value),
                timeZone: values?.zone,
                weekday: values?.dayofweek,
                workingDayObservationTypes: values?.workingDayObservationTypes,
              },
            };
          } else if (values?.freq?.value === "NEVER") {
            //Set to Never

            variables = {
              id: dataSourceId,
              ingressInfo: ingressInfo,
              description: description,
              refreshInfo: {
                activeMonitoring: false,
                frequency: values?.freq?.value,
                hour: values?.hour?.value ? Number(values?.hour?.value) : 0,
                isAM: values?.isAm?.value,
                minutes: Number(values?.min?.value),
                timeZone: values?.zone,
                weekday: values?.dayofweek,
                workingDayObservationTypes: values?.workingDayObservationTypes,
              },
            };
          }

          updateSettings({ query: setDataSourceMetadata, variables });
        }}
      >
        {(props) => {
          return (
            <form onSubmit={props.handleSubmit}>
              <FormControl>
                <div role="group" aria-labelledby="freq-group">
                  {props.values.ingressInfo === null ? null : (
                    <RadialSelect>
                      <Field
                        type="radio"
                        name="activeMonitoring"
                        value={"true"}
                      />
                      Active Monitoring{" "}
                      <ActiveMonitoringNote>
                        Archive Action defaults to "Move" unless "Delete" is
                        selected
                      </ActiveMonitoringNote>
                    </RadialSelect>
                  )}
                  <RadialSelect>
                    <Field
                      type="radio"
                      name="activeMonitoring"
                      value={"false"}
                    />
                    Scheduled Refresh{" "}
                    {props.values.activeMonitoring === "false"
                      ? currentFreq && (
                          <div
                            style={{
                              marginLeft: "1.35rem",
                              marginTop: "1rem",
                              fontWeight: "bold",
                            }}
                          >
                            {currentFreq?.label}
                            {currentFreq?.value !== "NEVER" && (
                              <>
                                {" - "}
                                {format(
                                  new Date(nextScheduledReport),
                                  formatFreqDate.find(
                                    (fd) => fd.value === currentFreq?.value
                                  )?.label ?? "iii, h:mm a"
                                )}
                              </>
                            )}
                          </div>
                        )
                      : null}
                  </RadialSelect>
                </div>
              </FormControl>
              <div style={{ marginLeft: "1.35rem" }}>
                {props.values.activeMonitoring === "false" &&
                props.values.edit ? (
                  <FormControl style={{ maxWidth: "20rem" }}>
                    <Label>Refresh Frequency</Label>
                    <StyledSelect
                      className={`react-select-container`}
                      classNamePrefix={`react-select`}
                      name={`freq`}
                      id={`freq`}
                      inputId={`freq-freqSelect-input`}
                      instanceId={`freq-freqSelect-instance`}
                      label="Select Schedule Frequency"
                      options={schedOptions}
                      menuPortalTarget={document.body}
                      placeholder={`Select Schedule Frequency`}
                      value={props.values.freq}
                      menuPlacement="auto"
                      onChange={(e) => props.setFieldValue(`freq`, e)}
                    />
                  </FormControl>
                ) : null}

                {props.values.activeMonitoring === "false" &&
                props.values.edit &&
                props.values.freq.value === "HOURLY" &&
                props.values.freq.value !== "NEVER" ? (
                  <div>
                    <FormControl style={{ maxWidth: "10rem" }}>
                      <Label>Time</Label>
                      <StyledSelect
                        className={`react-select-container`}
                        classNamePrefix={`react-select`}
                        name={`min`}
                        id={`min`}
                        inputId={`min-minSelect-input`}
                        instanceId={`min-minSelect-instance`}
                        label="Minutes"
                        options={minuteOptions}
                        menuPortalTarget={document.body}
                        placeholder={`Select Minutes`}
                        value={props.values.min}
                        menuPlacement="auto"
                        onChange={(e) => props.setFieldValue(`min`, e)}
                      />
                    </FormControl>

                    <FormControl style={{ maxWidth: "10rem" }}>
                      <Label>Holidays</Label>
                      <StyledSelect
                        className={`react-select-container`}
                        classNamePrefix={`react-select`}
                        name={`workingDayObservationTypes`}
                        id={`workingDayObservationTypes`}
                        inputId={`workingDayObservationTypes-input`}
                        instanceId={`workingDayObservationTypes-instance`}
                        label="Holidays"
                        options={holidayOptions}
                        menuPortalTarget={document.body}
                        placeholder={`Select Holiday Config`}
                        value={
                          holidayOptions.find(
                            (o) =>
                              o.value ===
                              props.values.workingDayObservationTypes
                          ) ?? { value: "DEFAULT", label: "Default" }
                        }
                        menuPlacement="auto"
                        onChange={(e) =>
                          props.setFieldValue(
                            `workingDayObservationTypes`,
                            e?.value
                          )
                        }
                      />
                    </FormControl>
                  </div>
                ) : null}

                {props.values.activeMonitoring === "false" &&
                props.values.edit &&
                props.values.freq.value === "WEEKLY" ? (
                  <FormControl>
                    <Label>Day</Label>
                    <div
                      style={{ marginLeft: "1.35rem" }}
                      role="group"
                      aria-labelledby="dayofweek"
                    >
                      <RadialSelectInline>
                        <RadialSelectBlock>
                          Sun
                          <Field
                            type="radio"
                            name="dayofweek"
                            value={"Sunday"}
                          />
                        </RadialSelectBlock>
                      </RadialSelectInline>
                      <RadialSelectInline>
                        <RadialSelectBlock>
                          Mon
                          <Field
                            type="radio"
                            name="dayofweek"
                            value={"Monday"}
                          />
                        </RadialSelectBlock>
                      </RadialSelectInline>
                      <RadialSelectInline>
                        <RadialSelectBlock>
                          Tue
                          <Field
                            type="radio"
                            name="dayofweek"
                            value={"Tuesday"}
                          />
                        </RadialSelectBlock>
                      </RadialSelectInline>
                      <RadialSelectInline>
                        <RadialSelectBlock>
                          Wed
                          <Field
                            type="radio"
                            name="dayofweek"
                            value={"Wednesday"}
                          />
                        </RadialSelectBlock>
                      </RadialSelectInline>
                      <RadialSelectInline>
                        <RadialSelectBlock>
                          Thu
                          <Field
                            type="radio"
                            name="dayofweek"
                            value={"Thursday"}
                          />
                        </RadialSelectBlock>
                      </RadialSelectInline>
                      <RadialSelectInline>
                        <RadialSelectBlock>
                          Fri
                          <Field
                            type="radio"
                            name="dayofweek"
                            value={"Friday"}
                          />
                        </RadialSelectBlock>
                      </RadialSelectInline>
                      <RadialSelectInline>
                        <RadialSelectBlock>
                          Sat
                          <Field
                            type="radio"
                            name="dayofweek"
                            value={"Saturday"}
                          />
                        </RadialSelectBlock>
                      </RadialSelectInline>
                    </div>
                  </FormControl>
                ) : null}

                {props.values.activeMonitoring === "false" &&
                props.values.edit &&
                props.values.freq.value !== "HOURLY" &&
                props.values.freq.value !== "NEVER" ? (
                  <>
                    <FormControl>
                      <Label>Time</Label>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <div
                          style={{
                            width: "100px",
                            marginRight: "1rem",
                            display: "inline-block",
                          }}
                        >
                          <StyledSelect
                            className={`react-select-container`}
                            classNamePrefix={`react-select`}
                            name={`hour`}
                            id={`hour`}
                            inputId={`hour-hourSelect-input`}
                            instanceId={`hour-hourSelect-instance`}
                            label="Hours"
                            options={hourOptions}
                            menuPortalTarget={document.body}
                            placeholder={`Select Hours`}
                            value={props.values.hour}
                            menuPlacement="auto"
                            onChange={(e) => props.setFieldValue(`hour`, e)}
                          />
                        </div>
                        <div
                          style={{
                            width: "110px",
                            marginRight: "1rem",
                            display: "inline-block",
                          }}
                        >
                          <StyledSelect
                            className={`react-select-container`}
                            classNamePrefix={`react-select`}
                            name={`min`}
                            id={`min`}
                            inputId={`min-minSelect-input`}
                            instanceId={`min-minSelect-instance`}
                            label="Minutes"
                            options={minuteOptions}
                            menuPortalTarget={document.body}
                            placeholder={`Select Minutes`}
                            value={props.values.min}
                            menuPlacement="auto"
                            onChange={(e) => props.setFieldValue(`min`, e)}
                          />
                        </div>
                        <div
                          style={{
                            width: "110px",
                            marginRight: "1rem",
                            display: "inline-block",
                          }}
                        >
                          <StyledSelect
                            className={`react-select-container`}
                            classNamePrefix={`react-select`}
                            name={`isAm`}
                            id={`isAm`}
                            inputId={`isAm-isAmSelect-input`}
                            instanceId={`isAm-isAmSelect-instance`}
                            label="Is Am"
                            options={ampmOptions}
                            menuPortalTarget={document.body}
                            placeholder={`Select AM/PM`}
                            value={props.values.isAm}
                            menuPlacement="auto"
                            onChange={(e) => props.setFieldValue(`isAm`, e)}
                          />
                        </div>
                        <div
                          style={{
                            display: "inline-block",
                          }}
                        >
                          {Intl.DateTimeFormat().resolvedOptions().timeZone}
                        </div>
                      </div>
                    </FormControl>

                    {props.values.activeMonitoring === "false" &&
                    props.values.edit ? (
                      <FormControl style={{ maxWidth: "10rem" }}>
                        <Label>Holidays</Label>
                        <StyledSelect
                          className={`react-select-container`}
                          classNamePrefix={`react-select`}
                          name={`workingDayObservationTypes`}
                          label="Holidays"
                          id={`holidays`}
                          inputId={`holidays-holidaysSelect-input`}
                          instanceId={`holidays-holidaysSelect-instance`}
                          options={holidayOptions}
                          menuPortalTarget={document.body}
                          placeholder={`Select Holiday Config`}
                          value={
                            holidayOptions.find(
                              (o) =>
                                o.value ===
                                props.values.workingDayObservationTypes
                            ) ?? { value: "DEFAULT", label: "Default" }
                          }
                          menuPlacement="auto"
                          onChange={(e) =>
                            props.setFieldValue(
                              `workingDayObservationTypes`,
                              e?.value
                            )
                          }
                        />
                      </FormControl>
                    ) : null}
                  </>
                ) : null}
              </div>
              <FormActions>
                <Button type="submit" disabled={setLoading || !props.isValid}>
                  {startingAdd ? <Spinner /> : "Update Refresh Interval"}
                </Button>
                {setErrors ? <ErrorMessages errors={setErrors} /> : null}
              </FormActions>
            </form>
          );
        }}
      </Formik>
    </>
  );
};

export default DataRefreshForm;
