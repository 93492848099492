import React from "react";
import Card from "../../components/Card";
import { MdAdd, MdArrowBack } from "react-icons/md";
import ActionWrapper from "../../components/ActionsWrapper";
import StyledLink from "../../components/StyledLink";
import WorkflowsArchiveList from "./WorkflowsArchiveList";

const WorkflowsArchivePage = () => {
  //Body Section of Widget
  function Body() {
    // connections
    return <WorkflowsArchiveList />;
  }

  //Actions Section of Widget
  function Actions() {
    return (
      <>
        <ActionWrapper>
          <StyledLink to={"/workflows/create"} title="Add New Workflow">
            <MdAdd />
          </StyledLink>
        </ActionWrapper>
        <ActionWrapper>
          <StyledLink to={"/workflows"} title="Back">
            <MdArrowBack />
          </StyledLink>
        </ActionWrapper>
      </>
    );
  }

  return (
    <Card
      title={"Workflows Archive"}
      titleDescription={"Data Transit Archived Workflows"}
      body={Body}
      actions={Actions}
    />
  );
};

export default WorkflowsArchivePage;
