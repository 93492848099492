export const allWorkflows = /* GraphQL */ `
  query (
    $first: Int
    $after: String
    $tagFilter: CustomTagFilterInput!
    $order: [WorkflowSortInput!]
    $where: WorkflowFilterInput
    $customSort: [CustomSortInputModelInput!]
  ) {
    allWorkflows(
      first: $first
      after: $after
      tagFilter: $tagFilter
      order: $order
      where: $where
      customSort: $customSort
    ) {
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      totalCount
      edges {
        cursor
        node {
          id
          enabled
          scheduledJob {
            recurrenceInHours
          }
          nextScheduledReport
          name
          headPipelineId
          etlPipeline {
            etlPipelineName
            etlProviderInstance {
              name
              id
            }
          }
          tagInstances {
            id
            remoteObjectId
            tag {
              name
              description
              id
            }
            tagId
            type
          }
        }
      }
    }
  }
`;

export const workflowById = /* GraphQL */ `
  query ($workflowId: Int!, $batchName: String) {
    workflowById(workflowId: $workflowId, batchName: $batchName) {
      name
      batchName
      alertTriggerTime {
        frequency
        weekday
        timeZone
        hour
        minutes
        isAM
      }
      alertTriggerTimestamp
      etlProviderInstanceId
      dependencies {
        name
        resultState
        dependentPipelines
        dependentDataSources {
          id
          name
          etlParameterName
          dataQualityThreshold
          resultState
          dataScore
        }
      }
      tagInstances {
        id
        remoteObjectId
        tag {
          name
          description
          id
        }
        tagId
        type
      }
    }
  }
`;

export const workflowByIdTags = /* GraphQL */ `
  query ($workflowId: Int!, $batchName: String) {
    workflowById(workflowId: $workflowId, batchName: $batchName) {
      etlProviderInstanceId
      tagInstances {
        id
        remoteObjectId
        tag {
          name
          description
          id
        }
        tagId
        type
      }
    }
  }
`;

export const allBatchesByWorkflowId = /* GraphQL */ `
  query ($workflowId: Int!) {
    allBatchesByWorkflowId(workflowId: $workflowId) {
      batchNames
    }
  }
`;

export const workflowAlerts = /* GraphQL */ `
  query ($workflowId: Int!) {
    workflowById(workflowId: $workflowId) {
      alerts {
        id
        enabled
        instances {
          id
          alertId
          notificationType
          enabled
          members {
            id
            alertInstanceId
            userId
            user {
              displayName
              emailAddress
              id
              profilePhoto
            }
            workGroupId
            group {
              displayName
              id
            }
          }
        }
        alertType
      }
    }
  }
`;

export const allBatchNamesByWorkflowId = /* GraphQL */ `
  query (
    $first: Int
    $after: String
    $order: [WorkflowBatchSummarySortInput!]
    $where: WorkflowBatchSummaryFilterInput
    $workflowId: Int!
  ) {
    allBatchNamesByWorkflowId(
      first: $first
      after: $after
      order: $order
      where: $where
      workflowId: $workflowId
    ) {
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      totalCount
      edges {
        cursor
        node {
          id
          batchName
          resultState
          createdOn
          workflowId
        }
      }
    }
  }
`;
