import React from "react";
import styled from "styled-components/macro";

const StyledLink = styled.div`
  text-decoration: none;
  transition: all ${(props) => props.theme.transitionSpeed} linear;
  font-family: "Source Sans Pro Semibold";
  color: ${(props) => props.theme.onSurface};
  display: flex;
  &:visited {
    color: ${(props) => props.theme.onSurface};
  }
  &:hover {
    text-decoration: underline;
    color: ${(props) => props.theme.surfaceAlt};
  }
  &:active {
    text-decoration: underline;
    color: ${(props) => props.theme.surfaceAlt};
  }
  &:disabled {
    cursor: pointer;
    opacity: 0.5;
    color: ${(props) => props.theme.onSurface};
  }
`;

const ExpandLink = (props) => {
  return <StyledLink {...props}>{props.children}</StyledLink>;
};

export default ExpandLink;
