import jwtDecode from "jwt-decode";
import Cookies from "js-cookie";

export function isTokenValid(tokenExp) {
  const time = Date.now().valueOf() / 1000;
  if (time > tokenExp) {
    return false;
  } else {
    return true;
  }
}

function clearUser() {
  window.localStorage.removeItem("sessionPhoto");
  Cookies.remove("token");
}

export function buildUser() {
  const apiToken = Cookies.get("token");
  if (apiToken) {
    const decodedApiToken = jwtDecode(apiToken);

    if (decodedApiToken.exp) {
      const tokenValid = isTokenValid(decodedApiToken.exp);
      if (tokenValid) {
        return {
          name: decodedApiToken.unique_name,
          email: decodedApiToken.email,
          id: decodedApiToken.actort,
          role: Number(decodedApiToken.role),
          jobTitle: decodedApiToken.jobTitle,
        };
      } else {
        clearUser();
        return null;
      }
    } else {
      clearUser();
      return null;
    }
  } else {
    clearUser();
    return null;
  }
}

export function canUseBCA(email) {
  return (
    email.endsWith("@basecapanalytics.com") || email.endsWith("@basecap.dev")
  );
}
