export function home() {
  return "/";
}

export function welcome() {
  return "/welcome";
}

// Data Quality Rules
export function dataQualityRules() {
  return "/policies/rules";
}

export function ruleStandardById(ruleId) {
  return `/policies/rules/${ruleId}`;
}

export function editDataQualityRules(ruleId) {
  return `/policies/rules/${ruleId}/edit`;
}

export function standardsHistory(ruleId) {
  return `/policies/rules/${ruleId}/history`;
}

export function ruleCreation() {
  return `/policies/rules/create`;
}

export function filterCreation() {
  return `/policies/filters/create`;
}

export function dataSourceFilters() {
  return `/policies/filters`;
}

export function dataSourceFilterById(filterId) {
  return `/policies/filters/${filterId}`;
}

export function dataSourceFiltersEdit(filterId) {
  return `/policies/filters/${filterId}/edit`;
}

export function filtersHistory(ruleId) {
  return `/policies/filters/${ruleId}/history`;
}

export function dataSourcFiltersMap(sourceId) {
  return `/sources/${sourceId}/filters`;
}

// Data Connection
export function dataConnections() {
  return `/connections`;
}

export function createDataConnections() {
  return `/connections/create`;
}

export function dataConnectionById(connectionId) {
  return `/connections/${connectionId}`;
}

export function editDataConnections(connectionId) {
  return `/connections/${connectionId}/edit`;
}

export function dataConnectionsSources(connectionId) {
  return `/connections/${connectionId}/sources`;
}

// Data Sources
export function dataSources() {
  return `/sources`;
}

export function addSources() {
  return `/sources/add`;
}

export function dataSource(sourceId) {
  return `/sources/${sourceId}`;
}

export function dataSourceStandardVersion(
  sourceId,
  ruleInstance,
  ruleInstanceVersionId
) {
  return `/sources/${sourceId}/manage/policies/${ruleInstance}/${ruleInstanceVersionId}`;
}

export function dataSourceFailureInstanceDetails(
  sourceId,
  refreshSummaryId,
  failedRuleInstanceId,
  failedRuleInstanceVersionId,
  failedRuleStandardVersionId
) {
  return `/sources/${sourceId}/reports/${refreshSummaryId}/failures/${failedRuleInstanceId}/${failedRuleInstanceVersionId}/${failedRuleStandardVersionId}`;
}

export function dataSourceFailureDetails(sourceId, refreshSummaryId) {
  return `/sources/${sourceId}/reports/${refreshSummaryId}/failures`;
}

export function dataSourceStandardVersionUpdate(
  sourceId,
  ruleInstance,
  ruleInstanceVersionId
) {
  return `/sources/${sourceId}/manage/policies/${ruleInstance}/${ruleInstanceVersionId}/update`;
}

export function dataSourceFilterVersionUpdate(
  sourceId,
  ruleInstance,
  ruleInstanceVersionId
) {
  return `/sources/${sourceId}/manage/filters/${ruleInstance}/${ruleInstanceVersionId}/update`;
}

export function dataSourceFilterVersion(
  sourceId,
  ruleInstance,
  ruleInstanceVersionId
) {
  return `/sources/${sourceId}/manage/filters/${ruleInstance}/${ruleInstanceVersionId}`;
}

export function dataSourceManagePoliciesStandardVersion(
  sourceId,
  ruleInstance,
  ruleInstanceVersionId
) {
  return `/sources/${sourceId}/manage/policies/${ruleInstance}/${ruleInstanceVersionId}`;
}

export function dataSourceManageFilters(sourceId) {
  return `/sources/${sourceId}/manage/filters/`;
}

export function dataSourceManagePolicies(sourceId) {
  return `/sources/${sourceId}/manage/policies/`;
}

export function dataSourceManagePoliciesNew(sourceId) {
  return `/sources/${sourceId}/manage/policies/new`;
}

export function dataSourceManageFiltersNew(sourceId) {
  return `/sources/${sourceId}/manage/filters/new`;
}

export function dataSourceReport(sourceId, reportId) {
  return `/sources/${sourceId}/reports/${reportId}`;
}

export function dataSourceReports(sourceId) {
  return `/sources/${sourceId}/reports`;
}

export function manageDataSource(sourceId) {
  return `/sources/${sourceId}/manage/policies`;
}

//Admin
export function admin() {
  return `/admin/audit`;
}

export function downloadAdminAuditLog() {
  return `/admin/audit/download`;
}

export function editProductConfig() {
  return `/admin/production-config`;
}

export function manageUsers() {
  return `/admin/users`;
}

export function organizations() {
  return `/admin/organizations`;
}

// Admin Organizations
export function organizationAdd() {
  return `/admin/organizations/add`;
}

export function workGroupAdd() {
  return `/admin/organizations/workgroups/add`;
}

export function organizationManage(orgId) {
  return `/admin/organizations/${orgId}/manage`;
}

export function organizationUsers(orgId) {
  return `/admin/organizations/${orgId}/users`;
}

export function organization(orgId) {
  return `/admin/organizations/${orgId}`;
}

export function workGroupManage(orgId, workGroupId) {
  return `/admin/organizations/${orgId}/workgroups/${workGroupId}/manage`;
}

export function workGroupsAdd() {
  return `/workgroups/add`;
}

export function manageWorkGroup(workgroupId) {
  return `/workgroups/${workgroupId}/manage`;
}

export function workGroup(workgroupId) {
  return `/workgroups/${workgroupId}`;
}

export function workGroups() {
  return `/workgroups`;
}

// Workflows
export function workflows() {
  return "/workflows";
}
export function workflowById(workflowId) {
  return `/workflows/${workflowId}`;
}

export function workflowByIdAndBatch(workflowId, batchName) {
  return `/workflows/${workflowId}/history/${batchName}`;
}

// Issues
export function issues() {
  return "/issues";
}

// Loan Feeds

export function feeds() {
  return "/feeds";
}

export function feedById(feedId) {
  return `/feeds/${feedId}`;
}

export function newFeed() {
  return "/feeds/new";
}

export function manageInputSource(sourceId) {
  return `/feeds/inputsources/${sourceId}`;
}

export function manageOutputSource(sourceId) {
  return `/feeds/outputsources/${sourceId}`;
}

// Dynamic Views & Record Level Reporting

export function dynamicViews() {
  return `/dynamicviews`;
}

export function dynamicViewsById(viewId) {
  return `/dynamicviews/${viewId}`;
}

export function dynamicViewsEdit(viewId) {
  return `/dynamicviews/${viewId}/edit`;
}

// Workspaces

export function workSpaces() {
  return `/workspaces`;
}

export function workSpacesCreate() {
  return "/workspaces/new";
}

export function workSpacesById(spaceId) {
  return `/workspaces/${spaceId}`;
}

export function workSpacesEdit(spaceId) {
  return `/workspaces/${spaceId}/edit`;
}
