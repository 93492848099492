import React, { useEffect, useState, useReducer, useContext } from "react";
import { useApi } from "../../api/useApi";
import {
    dataSourcesByIdColumns,
    getMatchingColumns,
} from "../../api/dataSourceQueries";
import { cloneServicerTransferFeed } from "../../api/serviceTransferMutations";
import { FormControl, Label } from "../../components/Form/FormControls";
import { StyledSelect, StyledInput } from "../../components/Form/FormControls";
import {
    MdCircle,
    MdContentCopy,
    MdLibraryBooks,
    MdCalendarToday,
    MdCallSplit,
    MdWarning,
    MdCalculate,
    MdOutlineHelp,
    MdClear,
    MdDelete,
} from "react-icons/md";
import { BsUiChecksGrid } from "react-icons/bs";
import { FaDatabase } from "react-icons/fa";
import { HiOutlineIdentification } from "react-icons/hi";
import { VscReplace } from "react-icons/vsc";
import "react-toastify/dist/ReactToastify.css";
import Button from "../../components/Button";
import styled from "styled-components/macro";
import SortExpandTableV2 from "../../components/Table/SortExpandTableV2";
import ErrorMessages from "../../components/Notifications/ErrorMessages";
import SplashLoader from "../../components/Loaders/SplashLoader";
import { previewServicerTransferTransformer } from "../../api/serviceTransferMutations";
import useFeedNotifications from "../../Hooks/useFeedNotifications";
import SortTable from "../../components/Table/SortTable";
import Spinner from "../../components/Loaders/Spinner";
import TransformEdit from "../../components/TableRowView/TransformEdit";
import MarkdownFetcher from "./transformerFunctions/MarkDownFetcher";
import { Scrollbars } from "react-custom-scrollbars";
import { NavContext } from "../../components/Layout/NavStateProvider";
import { v4 as uuid } from "uuid";
import SelectTags from "../../components/Tags/SelectTags";
import { getServicerTransferFeedTagsById } from "../../api/serviceTransferQueries";
import RefreshInfoForm from "./RefreshInfoForm";
import { format } from "date-fns-tz";
import Modal from "../../components/Modal";

const FeedTags = React.memo(({ dispatch, stateStep, feedId }) => {
    //Preview
    const [{ loading, errors, data }, getTagData] = useApi();

    useEffect(() => {
        if (stateStep === 10 && feedId) {
            getTagData({
                query: getServicerTransferFeedTagsById,
                variables: {
                    id: Number(feedId),
                },
            });
        }
    }, [stateStep, feedId, getTagData]);

    const initialTags =
        data?.servicerTransferFeedById?.tagInstances?.map((ti) => {
            const createTagOption = {
                label: ti?.tag?.name,
                value: ti?.tagId,
                ...ti,
            };
            return createTagOption;
        }) ?? [];

    return (
        <>
            <div style={{ marginBottom: "1rem" }}>
                <h3>Tags</h3>
                <p>Select the appropriate tags below.</p>
                {errors && <ErrorMessages errors={errors} />}
                {loading ? (
                    "Loading Tags"
                ) : (
                    <SelectTags
                        currentTags={initialTags ?? []}
                        updateTags={dispatch}
                        tagType={"SERVICER_TRANSFER_FEED"}
                        dispatchType={"SET_TAGS"}
                    />
                )}
            </div>
        </>
    );
});

const StaticContainer = styled.div`
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  font-size: 1rem;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  line-height: 1.5;
  position: relative;
  vertical-align: top;
  background-color: #ffffff;
  color: #555555;
  max-width: 100%;
  width: 100%;
  border-width: 1px;
  border-style: solid;
  border-image: initial;
  border-radius: 4px;
  box-shadow: none;
  border-color: #cccccc;
  min-height: 75px;
  display: flex;
  padding: 0.3rem;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
`;

const GoingRightBasic = styled(StaticContainer)`
  &:after {
    content: " ";
    display: block;
    width: 0;
    height: 0;
    border-top: 18px solid transparent;
    border-bottom: 18px solid transparent;
    border-left: 20px solid #b7b7b7;
    position: absolute;
    top: 50%;
    margin-top: -18px;
    left: 100%;
    z-index: 2;
  }
`;

const GoingRightSelect = styled(StyledSelect)`
  &:after {
    content: " ";
    display: block;
    width: 0;
    height: 0;
    border-top: 18px solid transparent;
    border-bottom: 18px solid transparent;
    border-left: 20px solid #b7b7b7;
    position: absolute;
    top: 50%;
    margin-top: -18px;
    left: 100%;
    z-index: 2;
  }
`;

const CommaSelect = styled(StyledSelect)`
  &:after {
    content: " ";
    display: block;
    width: 0;
    height: 0;
    position: absolute;
    top: 50%;
    margin-top: -20px;
    left: 100%;
    z-index: 2;
  }
`;

const CoalesceSelect = styled(StyledSelect)`
  &:after {
    content: " ? ";
    display: block;
    font-size: 1.5rem;
    text-align: center;
    height: 0;
    top: -30px;
    width: 29px;
    position: relative;
    margin-top: 0px;
    left: 100%;
    z-index: 2;
  }
`;

// Define IO for transformations
const transformationTypeOptions = [
    {
        label: "Date Time Format",
        value: "DATE_TIME_FORMAT",
        io: 2,
        description:
            "Used to transform a date string from one date format into a different date format.",
    },
    {
        label: "Force Match",
        value: "STRING_FORMAT",
        description:
            "Used to copy or join the values from the source column(s) to the destination column.",
    },
    {
        label: "Decimal Format",
        value: "DECIMAL_FORMAT",
        io: 1,
        description:
            "Used to transform a decimal value from one decimal format into a different decimal format.",
    },
    {
        label: "First non-null",
        value: "COALESCE",
        io: 3,
        description:
            "Used to copy the first non-null column found in a list containing one or more source columns.",
    },
    {
        label: "First non-null Code Break",
        value: "COALESCE_DICTIONARY",
        io: 3,
        description:
            "Used to copy the first non-null column found in a list containing one or more source columns with the additional functionality of allowing the result of a code break mapping to be applied.",
    },
    {
        label: "Code Break",
        value: "DICTIONARY",
        description:
            "Code Break Transformations (also known as Dictionary) are used to translate a key/value pair from the supplied key to the supplied value.",
    },
    {
        label: "Code Break (pass-through)",
        value: "DICTIONARY_PASS_THROUGH",
        description:
            "Code Break (pass-through) Transformation will copy the original source value to the destination, where as the original Code Break Transformation will output a blank (null) value in the destination in that same case.",
    },
    {
        label: "Replace",
        value: "STRING_REPLACE",
        io: 5,
        description:
            "Used to replace a specific character, a set of character(s), or a substring with a new string.",
    },
    {
        label: "String Split",
        value: "STRING_SPLIT",
        io: 5,
        description:
            "Used to split apart a single source string into multiple destination columns.",
    },
    {
        label: "Name Split",
        value: "FULL_NAME_PARSE",
        io: 0,
        description:
            "Used to split apart a string containing a name into individual pieces which can be copied into distinct destination columns.",
    },
    {
        label: "Phone Number Split",
        value: "PHONE_NUMBER_PARSE",
        io: 0,
        description:
            "Used to split apart a string containing a phone number into individual pieces which can be copied into distinct destination columns.",
    },
    {
        label: "Address Split",
        value: "ADDRESS_PARSE",
        io: 3,
        description:
            "Used to split apart a string containing an address into individual pieces which can be copied into distinct destination columns.",
    },
    {
        label: "Month +/-",
        value: "MONTH_ADJUSTMENT",
        io: 4,
        description:
            "Used to first check if the first input column is non-null. If it is non-null, the value is copied to the destination column as-is. If the first input column is null, then the second input column is parsed for a DateTime/DateTimeOffset object, and the number of months specified are added or subtracted from that parsed date before returning the output.",
    },
    {
        label: "Custom Transform",
        value: "CALCULATOR",
        io: 4,
        description:
            "Used to evaluate simple math expressions, using either column combinations or literal values.",
    },
    {
        label: "Advanced Calculation Transform",
        value: "N_CALC",
        io: 4,
        description:
            "Used to evaluate advanced math expressions, using either column combinations or literal values.",
    },
];

const transformationSubTypes = [
    {
        parentTypes: ["DATE_TIME_FORMAT"],
        subTypeOptions: [
            { label: "MMddyyyy", value: "SERVICER_TRANSFER_DATE_SUBTYPE72" },
            { label: "Mdyyyy", value: "SERVICER_TRANSFER_DATE_SUBTYPE74" },
            { label: "ddMMyyyy", value: "SERVICER_TRANSFER_DATE_SUBTYPE73" },
            { label: "dMyyyy", value: "SERVICER_TRANSFER_DATE_SUBTYPE75" },
            { label: "MM/dd/yyyy", value: "AMERICAN_DATE_TIME" },
            { label: "MM/dd/yyyy hh:mm", value: "AMERICAN_DATE_TIME_INCLUDE_TIME" },
            { label: "MM/dd/yyyy h:mm", value: "SERVICER_TRANSFER_DATE_SUBTYPE48" },
            { label: "MM/dd/yyyy hh:mm zzz", value: "UTC" },
            {
                label: "MM/dd/yyyy h:mm zzz",
                value: "SERVICER_TRANSFER_DATE_SUBTYPE49",
            },
            { label: "M/d/yy", value: "AMERICAN_DATE_TIME_SHORT" },
            { label: "M/d/yyyy", value: "AMERICAN_DATE_TIME_LONG_YEAR_SHORT" },
            {
                label: "M/d/yyyy hh:mm",
                value: "AMERICAN_DATE_TIME_INCLUDE_TIME_SHORT",
            },
            {
                label: "M/d/yyyy h:mm",
                value: "AMERICAN_DATE_TIME_INCLUDE_TIME_SHORT_SINGLE_HOUR",
            },
            { label: "M/d/yyyy hh:mm:ss zzz", value: "UTC_SHORT" },
            { label: "M/d/yyyy h:mm:ss zzz", value: "UTC_SHORT_SINGLE_HOUR" },
            { label: "dd/MM/yyyy", value: "REVERSE_DATE_TIME" },
            { label: "dd/MM/yyyy hh:mm", value: "REVERSE_DATE_TIME_INCLUDE_TIME" },
            {
                label: "dd/MM/yyyy h:mm",
                value: "REVERSE_DATE_TIME_INCLUDE_TIME_SINGLE_HOUR",
            },
            { label: "dd/MM/yyyy hh:mm zzz", value: "REVERSE_UTC" },
            { label: "dd/MM/yyyy h:mm:ss zzz", value: "REVERSE_UTC_SINGLE_HOUR" },
            { label: "d/M/yy", value: "REVERSE_DATE_TIME_SHORT" },
            { label: "d/M/yyyy", value: "REVERSE_DATE_TIME_LONG_YEAR_SHORT" },
            {
                label: "d/M/yyyy h:mm",
                value: "REVERSE_DATE_TIME_INCLUDE_TIME_SINGLE_HOUR_SHORT",
            },
            { label: "d/M/yyyy h:mm:ss zzz", value: "REVERSE_UTC_SINGLE_HOUR_SHORT" },
            { label: "hh:mm:ss", value: "TIME_HOURS_MINUTES_SECONDS" },
            { label: "hh:mm", value: "TIME_HOURS_MINUTES" },
            { label: "hh:mm am/pm", value: "TIME_HOURS_MINUTES_AM_PM" },
            { label: "24hh:mm:ss", value: "TIME_24_HOURS_MINUTES_SECONDS" },
            { label: "24hh:mm", value: "TIME_24_HOURS_MINUTES" },
            { label: "24hhmmss", value: "SERVICER_TRANSFER_TIME_ONLY_79" },
            { label: "24hhmm", value: "SERVICER_TRANSFER_TIME_ONLY_77" },
            { label: "hhmmss", value: "SERVICER_TRANSFER_TIME_ONLY_80" },
            { label: "hhmm", value: "SERVICER_TRANSFER_TIME_ONLY_78" },
            { label: "yy/MM/dd hh:mm", value: "SERVICER_TRANSFER_DATE_SUBTYPE59" },
            { label: "yy/MM/dd h:mm", value: "SERVICER_TRANSFER_DATE_SUBTYPE61" },
            {
                label: "yy/MM/dd h:mm:ss zzz",
                value: "SERVICER_TRANSFER_DATE_SUBTYPE62",
            },
            { label: "yy/M/d", value: "SERVICER_TRANSFER_DATE_SUBTYPE63" },
            { label: "yy/M/d hh:mm", value: "SERVICER_TRANSFER_DATE_SUBTYPE64" },
            { label: "yy/M/d h:mm", value: "SERVICER_TRANSFER_DATE_SUBTYPE65" },
            {
                label: "yy/M/d hh:mm:ss zzz",
                value: "SERVICER_TRANSFER_DATE_SUBTYPE66",
            },
            {
                label: "yy/M/d h:mm:ss zzz",
                value: "SERVICER_TRANSFER_DATE_SUBTYPE67",
            },
            { label: "yyyy/MM/dd", value: "YEARFIRSTDATETIME" },
            { label: "yyyy/MM/dd hh:mm", value: "SERVICER_TRANSFER_DATE_SUBTYPE50" },
            { label: "yyyy/MM/dd h:mm", value: "SERVICER_TRANSFER_DATE_SUBTYPE51" },
            {
                label: "yyyy/MM/dd hh:mm:ss zzz",
                value: "SERVICER_TRANSFER_DATE_SUBTYPE52",
            },
            {
                label: "yyyy/MM/dd h:mm:ss zzz",
                value: "SERVICER_TRANSFER_DATE_SUBTYPE53",
            },
            { label: "yyyy/M/d", value: "SERVICER_TRANSFER_DATE_SUBTYPE54" },
            { label: "yyyy/M/d hh:mm", value: "SERVICER_TRANSFER_DATE_SUBTYPE55" },
            { label: "yyyy/M/d h:mm", value: "SERVICER_TRANSFER_DATE_SUBTYPE56" },
            {
                label: "yyyy/M/d hh:mm:ss zzz",
                value: "SERVICER_TRANSFER_DATE_SUBTYPE57",
            },
            {
                label: "yyyy/M/d h:mm:ss zzz",
                value: "SERVICER_TRANSFER_DATE_SUBTYPE58",
            },
            { label: "yyyyMMdd", value: "SERVICER_TRANSFER_DATE_SUBTYPE68" },
        ],
    },
    {
        parentTypes: ["STRING_SPLIT"],
        subTypeOptions: [
            {
                label: "STRING SPLIT COMMON SEPARATORS",
                value: "STRING_SPLIT_COMMON_SEPARATORS",
            },
            {
                label: "STRING SPLIT COMMON SEPARATORS INCLUDING PARENTHESIS",
                value: "STRING_SPLIT_COMMON_SEPARATORS_INC_PARENTHESIS",
            },
            {
                label: "STRING SPLIT COMMA SPACE ONLY",
                value: "STRING_SPLIT_COMMA_SPACE_ONLY",
            },
            {
                label: "STRING SPLIT PIPE DELIMITER",
                value: "STRING_SPLITPIPE_DELIMITER",
            },
            {
                label: "Custom Entry",
                value: "CUSTOM_ENTRY",
            },
        ],
    },
    {
        parentTypes: ["STRING_FORMAT", "CALCULATOR", "MONTH_ADJUSTMENT"],
        subTypeOptions: [
            {
                label: "Custom Entry",
                value: "CUSTOM_ENTRY",
            },
        ],
    },
    {
        parentTypes: ["STRING_REPLACE"],
        subTypeOptions: [
            {
                label: "REPLACE REMOVE HYPHEN",
                value: "STRING_REPLACE_REMOVE_HYPHEN",
            },
            {
                label: "REPLACE NON-DIGITS",
                value: "STRING_REPLACE_REMOVE_NON_DIGITS",
            },
            {
                label: "REPLACE . ,  / [ ] ! < > _ ( )",
                value: "CLEAN_BORROWER_NAME",
            },
            {
                label: "REPLACE 2+ whitespace characters with single space",
                value: "REPLACE_MULTIPLE_SPACES",
            },
            {
                label: "CUSTOM ENTRY",
                value: "CUSTOM_ENTRY",
            },
        ],
    },
    {
        parentTypes: ["DECIMAL_FORMAT", "DECIMAL_FORMAT_PARSE"],
        subTypeOptions: [
            {
                label: "None",
                value: "NONE",
            },
            {
                label: "DECIMAL ROUNDED NUMBER #",
                value: "DECIMAL_NO_DECIMALS",
            },
            {
                label: "DECIMAL ONE DIGIT #.#",
                value: "DECIMAL_ONE_DECIMALS",
            },
            {
                label: "DECIMAL TWO DIGITS #.##",
                value: "DECIMAL_TWO_DECIMALS",
            },
            {
                label: "DECIMAL CURRENCY $#.##",
                value: "DECIMAL_CURRENCY",
            },
            {
                label: "DECIMAL ROUNDED CURRENCY $#",
                value: "DECIMAL_CURRENCY_NO_DECIMAL",
            },
            {
                label: "DECIMAL PERCENT NO DIGITS 100%",
                value: "DECIMAL_PERCENT_NO_DECIMAL",
            },
            {
                label: "DECIMAL PERCENT ONE DIGIT 100.0%",
                value: "DECIMAL_PERCENT_ONE_DECIMALS",
            },
            {
                label: "DECIMAL PERCENT TWO DIGITS 100.00%",
                value: "DECIMAL_PERCENT_TWO_DECIMALS",
            },
            {
                label: "DECIMAL BASIS POINTS 10 == 0.01%",
                value: "DECIMAL_BPS",
            },
        ],
    },
    {
        parentTypes: ["FULL_NAME_PARSE"],
        subTypeOptions: [
            {
                label: "Auto Detect Format",
                value: "FULL_NAME_PARSE_AUTO_DETECT",
            },
            {
                label: "First Middle Last",
                value: "FULL_NAME_PARSE_FIRST_MIDDLE_LAST",
            },
            {
                label: "Last, First Middle",
                value: "FULL_NAME_PARSE_LAST_MIDDLE_FIRST",
            },
        ],
    },
    {
        parentTypes: ["PHONE_NUMBER_PARSE"],
        subTypeOptions: [
            {
                label: "Default",
                value: "PHONE_NUMBER_DEFAULT",
            },
            {
                label: "Remove Leading +1 for US, Only Digits for others",
                value: "PHONE_NUMBER_US_NATIONAL_OTHER_COPY",
            },
            {
                label:
                    "Remove any non-digit characters, if ten digits are left return digits, otherwise return 'Invalid Format'",
                value: "PHONE_NUMBER_TEN_DIGITS_ONLY",
            },
        ],
    },
    {
        parentTypes: ["ADDRESS_PARSE"],
        subTypeOptions: [],
    },
];

function isInverse(transformType) {
    switch (transformType) {
        case "FULL_NAME_PARSE":
            return true;
        case "STRING_SPLIT":
            return true;
        case "ADDRESS_PARSE":
            return true;
        case "PHONE_NUMBER_PARSE":
            return true;
        default:
            return false;
    }
}

function renderTypeTransformIcon(typeOfTransform) {
    switch (typeOfTransform) {
        case "DATE_TIME_FORMAT": {
            return <MdCalendarToday title={typeOfTransform} />;
        }
        case "DECIMAL_FORMAT_PARSE":
        case "DECIMAL_FORMAT": {
            return <div title={typeOfTransform}>0.0</div>;
        }
        case "STRING_SPLIT": {
            return <MdCallSplit title={typeOfTransform} />;
        }
        case "COALESCE_DICTIONARY":
        case "COALESCE": {
            return <BsUiChecksGrid title={typeOfTransform} />;
        }
        case "DICTIONARY_PASS_THROUGH":
        case "DICTIONARY": {
            return <MdLibraryBooks title={typeOfTransform} />;
        }
        case "FULL_NAME_PARSE": {
            return <HiOutlineIdentification title={typeOfTransform} />;
        }
        case "STRING_REPLACE": {
            return <VscReplace title={typeOfTransform} />;
        }
        case "N_CALC":
        case "CALCULATOR": {
            return <MdCalculate title={typeOfTransform} />;
        }
        case "MONTH_ADJUSTMENT": {
            return <MdCalendarToday title={typeOfTransform} />;
        }
        case "STRING_FORMAT": {
            return <MdContentCopy title={typeOfTransform} />;
        }
        default: {
            return null;
        }
    }
}

const PreviewTransformation = ({
    transformation,
    preview,
    dispatch,
    error,
}) => {
    const columnsData = [
        {
            Header: () => {
                const columnArguments = [...transformation?.transformerArguments];
                const first = columnArguments[0];
                const isInverseColumns = isInverse(
                    transformation?.transformationType?.value
                );

                if (isInverseColumns) {
                    // remove the first item from column args
                    columnArguments.shift();
                } else {
                    // remove the last item from the column
                    columnArguments.pop();
                }

                return (
                    <div>
                        <div style={{ fontWeight: "bold" }}>Input</div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            {isInverseColumns ? (
                                <>[{first?.column?.name}]</>
                            ) : (
                                columnArguments.map((inputColumn, i) => {
                                    return <>[{inputColumn?.column?.name}]</>;
                                })
                            )}
                        </div>
                    </div>
                );
            },
            id: "InputColumns",
            // The cell can use the individual row's getToggleRowSelectedProps method
            // to the render a checkbox
            Cell: ({ row: { original } }) => {
                const previewArray = [...original];
                const first = previewArray[0];
                const isInverseColumns = isInverse(
                    transformation?.transformationType?.value
                );

                if (isInverseColumns) {
                    // remove the first item from column args
                    previewArray.shift();
                } else {
                    // remove the last item from the column
                    previewArray.pop();
                }

                return (
                    <div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            {isInverseColumns ? (
                                <>[{first}]</>
                            ) : (
                                previewArray.map((inputColumn) => {
                                    return <>[{inputColumn}]</>;
                                })
                            )}
                            <div style={{ marginLeft: "auto" }}>
                                <div
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        flexDirection: "row",
                                        minWidth: "50px",
                                    }}
                                >
                                    <div
                                        style={{
                                            flex: 1,
                                            height: "1px",
                                            backgroundColor: "#ccc",
                                        }}
                                    ></div>
                                    <div
                                        style={{
                                            flex: 0,
                                            width: 0,
                                            height: 0,
                                            borderTop: "5px solid transparent",
                                            borderBottom: "5px solid transparent",
                                            borderLeft: "10px solid #ccc",
                                        }}
                                    ></div>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            },
        },
        {
            Header: () => {
                const columnArguments = [...transformation.transformerArguments];
                const argumentsLength = transformation.transformerArguments?.length;
                const last = columnArguments[argumentsLength - 1];
                const isInverseColumns = isInverse(
                    transformation.transformationType?.value
                );

                if (isInverseColumns) {
                    // remove the first item from column args
                    columnArguments.shift();
                } else {
                    // remove the last item from the column
                    columnArguments.pop();
                }

                return (
                    <>
                        <div style={{ fontWeight: "bold" }}>Output</div>
                        {isInverseColumns ? (
                            columnArguments.map((inputColumn, i) => {
                                return <>[{inputColumn?.column?.name}]</>;
                            })
                        ) : (
                            <>[{last?.column?.name}]</>
                        )}
                    </>
                );
            },
            accessor: "lastName",
            Cell: ({ row: { original } }) => {
                const previewArray = [...original];
                const argumentsLength = transformation.transformerArguments?.length;
                const last = previewArray[argumentsLength - 1];
                const isInverseColumns = isInverse(
                    transformation.transformationType?.value
                );

                if (isInverseColumns) {
                    // remove the first item from column args
                    previewArray.shift();
                } else {
                    // remove the last item from the column
                    previewArray.pop();
                }

                return (
                    <>
                        {isInverseColumns ? (
                            previewArray.map((inputColumn, i) => {
                                return <>[{inputColumn}]</>;
                            })
                        ) : (
                            <>[{last}]</>
                        )}
                    </>
                );
            },
        },
    ];

    return (
        <div>
            <div
                style={{ display: "flex", alignItems: "center", marginBottom: "1rem" }}
            >
                <div>
                    <h3>Transformation Preview</h3>
                </div>
                <div style={{ marginLeft: "auto" }}>
                    <Button danger onClick={() => dispatch({ type: "CLOSE_PREVIEW" })}>
                        Close Preview
                    </Button>
                </div>
            </div>

            {error && <ErrorMessages errors={[{ message: error }]} />}

            <SortTable data={preview} columns={columnsData} />
        </div>
    );
};

const EditName = ({ feedName, setFeedName }) => {
    const [value, setValue] = React.useState(feedName);
    const onChange = (e) => {
        setValue(e.target.value);
    };

    const onBlur = () => {
        setFeedName(value);
    };

    React.useEffect(() => {
        setValue(feedName);
    }, [feedName]);

    return (
        <FormControl>
            <Label style={{ fontSize: "1.2rem" }}>Transformation Name</Label>
            <p style={{ margin: ".5rem" }}>
                Provide a name for your Transformation. This will be the name of the
                file that gets exported with a timestamp appended at the end.
            </p>
            <StyledInput
                type="text"
                name="feedName"
                label=""
                value={value}
                placeholder={`Name of The Transformation`}
                onChange={onChange}
                onBlur={onBlur}
            />
        </FormControl>
    );
};

const PrimarySourceSelection = ({
    primarySourceDataSourceId,
    setPrimarySourceDataSourceId,
    inputSources,
}) => {
    const inputOptions = inputSources?.map((is) => {
        return {
            value: is?.id,
            label: is?.name,
        };
    });

    const onChange = (e) => {
        setPrimarySourceDataSourceId(e?.value);
    };

    return (
        <FormControl>
            <Label style={{ fontSize: "1.2rem" }}>Primary Input Source</Label>
            <p style={{ margin: ".5rem" }}>
                Due to having multiple Input sources, you need to select which source
                should be the primary.
            </p>

            <StyledSelect
                className={`react-select-container`}
                classNamePrefix={`react-select`}
                menuPortalTarget={document.body}
                options={inputOptions}
                placeholder={`Input Source Select`}
                id={`primaryInputSelect`}
                inputId={`primaryInputSelect-input`}
                instanceId={`primaryInputSelect-instance`}
                value={inputOptions.find(
                    (io) => io?.value === primarySourceDataSourceId
                )}
                menuPlacement="auto"
                onChange={(e) => onChange(e)}
            />
        </FormControl>
    );
};

const TransformerArguments = ({
    transformIndex,
    argument,
    transformerArguments,
    argumentIndex,
    removeArgument,
    clearArgument,
    ingestColumnOptions,
    sourceColumnOptions,
    updateArgumentValue,
    isDictionary,
    isStringFormat,
    transformationType,
}) => {
    // check if the columnId is 0 or if its datasource has a tagId of -7
    const isGenerated =
        argument?.dataSourceColumnId <= 0 ||
        argument?.dataSource?.tagInstances?.some(
            (tagInstance) => tagInstance.tagId === -7
        );

    const isLast = transformerArguments?.length - 1 === argumentIndex;
    const isFirst = argumentIndex === 0;
    const isSecondToLast = transformerArguments?.length - 2 === argumentIndex;
    const sourceColumnName = argument?.fullyQualifiedName?.split(/\|/) ?? "";

    const flags = argument?.flags;
    const isCodeBreak = argument && (flags & 64) > 0;
    const warnDictionary =
        isDictionary &&
        argument &&
        !isCodeBreak &&
        !transformationType === "COALESCE_DICTIONARY";

    const inverseLogicForSplit =
        transformationType === "STRING_SPLIT" ||
        transformationType === "FULL_NAME_PARSE" ||
        transformationType === "ADDRESS_PARSE" ||
        transformationType === "PHONE_NUMBER_PARSE";

    const InputColumnSelect =
        (isStringFormat && !isSecondToLast) || inverseLogicForSplit
            ? CommaSelect
            : transformationType === "COALESCE" ||
                transformationType === "COALESCE_DICTIONARY"
                ? CoalesceSelect
                : GoingRightSelect;

    const nameCase = [
        "First Name",
        "Middle Name",
        "Last Name",
        "Prefix",
        "Suffix",
    ];

    const phoneCase = [
        "Country Code",
        "National Number",
        "Extension",
        "Country + National + Extension (digits)",
        "Subtype",
    ];

  const addressCase = [
    "Street Address",
    "Street Address2",
    "City",
    "State",
    "ZipCode",
    "ZipCode (only 5-digits)",
    "Country",
    "PrimaryStreetNumber",
    "StreetPreDirection",
    "StreetName",
    "StreetSuffix",
    "StreetPostDirection",
    "StreetFullName",
  ];

    const monthAdjustment = ["Null Column Check", "Adjustment Date"];

    const specialNameCase = transformationType === "FULL_NAME_PARSE";
    const specialAddressCase = transformationType === "ADDRESS_PARSE";
    const specialPhoneCase = transformationType === "PHONE_NUMBER_PARSE";
    const monthAdjustmentCase = transformationType === "MONTH_ADJUSTMENT";
    // Handle the stringsplit case where we need to inverse the columns,
    // being that managed e.g. sources become selectable
    if (inverseLogicForSplit) {
        return (
            <div style={{ display: "flex", alignItems: "center" }}>
                <div style={{ marginRight: "2rem" }}>
                    {/*Debug info: argumentIndex: {argumentIndex} transformIndex:{transformIndex} */}
                    <FormControl>
                        {isFirst ? (
                            <>
                                <Label style={{ display: "flex" }}>
                                    Input Column{" "}
                                    {isCodeBreak && (
                                        <MdLibraryBooks style={{ marginLeft: ".3rem" }} />
                                    )}
                                    {warnDictionary && <MdWarning />}
                                </Label>

                                <GoingRightBasic
                                    style={{
                                        display: "flex",
                                        justifyContent: "left",
                                        flexDirection: "column",
                                        padding: "1rem",
                                        alignItems: "start",
                                    }}
                                >
                                    {argument?.label}{" "}
                                    {sourceColumnName ? (
                                        <div
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                                opacity: ".4",
                                            }}
                                        >
                                            <FaDatabase style={{ marginRight: ".2rem" }} />{" "}
                                            {sourceColumnName?.[0]}
                                        </div>
                                    ) : null}
                                </GoingRightBasic>
                            </>
                        ) : (
                            <>
                                <Label style={{ display: "flex" }}>
                                    {specialNameCase || specialAddressCase || specialPhoneCase
                                        ? specialNameCase
                                            ? nameCase[argumentIndex - 1]
                                            : specialAddressCase
                                                ? addressCase[argumentIndex - 1]
                                                : phoneCase[argumentIndex - 1]
                                        : "Output Column"}{" "}
                                    {isCodeBreak && (
                                        <MdLibraryBooks style={{ marginLeft: ".3rem" }} />
                                    )}{" "}
                                    <div
                                        style={{
                                            marginLeft: "auto",
                                            display: "flex",
                                        }}
                                    >
                                        {transformerArguments?.length > 2 &&
                                            !specialNameCase &&
                                            !specialAddressCase &&
                                            !specialPhoneCase ? (
                                            <div
                                                style={{
                                                    cursor: "pointer",
                                                    color: "#f87e7e",
                                                }}
                                                onClick={() =>
                                                    removeArgument(transformIndex, argumentIndex)
                                                }
                                            >
                                                <MdDelete />
                                            </div>
                                        ) : null}
                                        {inverseLogicForSplit ? (
                                            <div
                                                style={{
                                                    cursor: "pointer",
                                                    color: "#f87e7e",
                                                }}
                                                title="Clear Selection"
                                                onClick={() =>
                                                    clearArgument(transformIndex, argumentIndex)
                                                }
                                            >
                                                <MdClear />
                                            </div>
                                        ) : null}
                                        {warnDictionary && <MdWarning />}
                                    </div>
                                </Label>

                                <InputColumnSelect
                                    className={`react-select-container`}
                                    classNamePrefix={`react-select`}
                                    label={`Column`}
                                    data-testid={`${transformIndex}-${argumentIndex}-columnInputSelection`}
                                    inputId={`${transformIndex}-${argumentIndex}-columnInputSelection`}
                                    instanceId={`${transformIndex}-${argumentIndex}-columnInputSelection-instance`}
                                    menuPortalTarget={document.body}
                                    formatOptionLabel={formatOptionLabel}
                                    options={sourceColumnOptions.map((sourceColumnOption) => {
                                        return {
                                            ...sourceColumnOption,
                                            value: sourceColumnOption.value,
                                            label: sourceColumnOption.label,
                                        };
                                    })}
                                    placeholder={`Select Input Column`}
                                    value={argument?.id ? argument : null}
                                    menuPlacement="auto"
                                    onChange={(e) =>
                                        updateArgumentValue(
                                            transformIndex,
                                            argumentIndex,
                                            e,
                                            inverseLogicForSplit
                                        )
                                    }
                                    styles={{
                                        control: (provided) => ({
                                            ...provided,
                                            height: "75px",
                                        }),
                                        container: (provided) => ({
                                            ...provided,
                                            minWidth: "300px",
                                        }),
                                    }}
                                />
                            </>
                        )}
                    </FormControl>
                </div>
            </div>
        );
    }

    return (
        <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ marginRight: "2rem" }}>
                {/*Debug info: argumentIndex: {argumentIndex} transformIndex:{transformIndex} */}
                <FormControl>
                    {isLast ? (
                        <>
                            {isGenerated ? (
                                <>
                                    <Label style={{ display: "flex" }}>
                                        Custom Output Column Name
                                        {isCodeBreak && (
                                            <MdLibraryBooks style={{ marginLeft: ".3rem" }} />
                                        )}
                                        {warnDictionary && <MdWarning />}
                                    </Label>

                                    <FormControl>
                                        <StyledInput
                                            type="text"
                                            name="name"
                                            value={argument?.name}
                                            placeholder={"Formatting String"}
                                            onChange={(e) =>
                                                updateArgumentValue(transformIndex, argumentIndex, {
                                                    ...argument,
                                                    name: e?.target?.value,
                                                    label: e?.target?.value,
                                                    column: {
                                                        ...argument?.column,
                                                        name: e?.target?.value,
                                                    },
                                                })
                                            }
                                        />
                                    </FormControl>
                                </>
                            ) : (
                                <>
                                    <Label style={{ display: "flex" }}>
                                        Output Column{" "}
                                        {isCodeBreak && (
                                            <MdLibraryBooks style={{ marginLeft: ".3rem" }} />
                                        )}
                                        {warnDictionary && <MdWarning />}
                                    </Label>

                                    <StaticContainer
                                        style={{
                                            display: "flex",
                                            justifyContent: "left",
                                            flexDirection: "column",
                                            padding: "1rem",
                                            alignItems: "start",
                                        }}
                                    >
                                        {argument?.label}{" "}
                                        {sourceColumnName ? (
                                            <div
                                                style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    opacity: ".4",
                                                }}
                                            >
                                                <FaDatabase style={{ marginRight: ".2rem" }} />{" "}
                                                {sourceColumnName?.[0]}
                                            </div>
                                        ) : null}
                                    </StaticContainer>
                                </>
                            )}
                        </>
                    ) : (
                        <>
                            <Label style={{ display: "flex" }}>
                                {monthAdjustmentCase
                                    ? monthAdjustment[argumentIndex]
                                    : "Input Column"}{" "}
                                {isCodeBreak && (
                                    <MdLibraryBooks style={{ marginLeft: ".3rem" }} />
                                )}{" "}
                                <div
                                    style={{
                                        marginLeft: "auto",
                                        display: "flex",
                                    }}
                                >
                                    {transformerArguments?.length > 2 && !monthAdjustmentCase ? (
                                        <div
                                            style={{
                                                marginLeft: "auto",
                                                cursor: "pointer",
                                                color: "#f87e7e",
                                            }}
                                            title="Remove Column"
                                            onClick={() =>
                                                removeArgument(transformIndex, argumentIndex)
                                            }
                                        >
                                            <MdDelete />
                                        </div>
                                    ) : null}
                                    <div
                                        style={{
                                            marginLeft: "auto",
                                            cursor: "pointer",
                                            color: "#f87e7e",
                                        }}
                                        title="Clear Selection"
                                        onClick={() => clearArgument(transformIndex, argumentIndex)}
                                    >
                                        <MdClear />
                                    </div>
                                </div>
                                {warnDictionary && <MdWarning />}
                            </Label>

                            <InputColumnSelect
                                className={`react-select-container`}
                                classNamePrefix={`react-select`}
                                label={`Column`}
                                menuPortalTarget={document.body}
                                data-testid={`${transformIndex}-${argumentIndex}-columnInputSelection`}
                                inputId={`${transformIndex}-${argumentIndex}-columnInputSelection`}
                                instanceId={`${transformIndex}-${argumentIndex}-columnInputSelection-instance`}
                                formatOptionLabel={formatOptionLabel}
                                options={ingestColumnOptions.map((aa) => {
                                    return {
                                        ...aa,
                                        value: aa.value,
                                        label: aa.label,
                                    };
                                })}
                                placeholder={`Select Input Column`}
                                value={argument?.id ? argument : null}
                                menuPlacement="auto"
                                onChange={(e) =>
                                    updateArgumentValue(transformIndex, argumentIndex, e)
                                }
                                styles={{
                                    control: (provided) => ({
                                        ...provided,
                                        height: "75px",
                                    }),
                                    container: (provided) => ({
                                        ...provided,
                                        minWidth: "300px",
                                    }),
                                }}
                            />
                        </>
                    )}
                </FormControl>
            </div>
        </div>
    );
};

const SelectTransformType = ({
    setSelectedValues,
    transformIndex,
    uuid,
    ingestColumnOptions,
    sourceColumnOptions,
    collapse,
    inputDataSourceIds,
    outputDataSourceId,
    selectedValues,
    isGenerated,
}) => {
    let cloneSelectedValues = JSON.parse(JSON.stringify(selectedValues));
    //Initial State of Transformation
    const [transformations, setTransformations] = useState([
        ...cloneSelectedValues,
    ]);
    const [showHelper, setShowHelper] = useState(false);

    const selectedValue = transformations.find((tfm) => tfm.uuid === uuid);
    const isNotGeneratedYet =
        outputDataSourceId == null || outputDataSourceId < 0;

    const CloseAndSave = () => {
        setSelectedValues(transformations);
        collapse();
    };

    //Preview
    const [
        { loading: previewLoading, errors: previewErrors, data: previewData },
        getPreview,
    ] = useApi();

    //Reducer States for this component, Details what part of the state updates pending dispatch
    const reducer = (state, action) => {
        switch (action.type) {
            //First State called when a query is passed to the component.
            default:
                return {
                    ...state,
                };
            case "START_PREVIEW": {
                const t = action?.payload?.transformer;
                // DEBUG Don't Submit to API, log and Save to State
                const transformer = {
                    transformationType: t?.transformationType?.value,
                    ...(t?.formattingString !== "" || null || undefined
                        ? {
                            formattingString: t?.formattingString,
                        }
                        : null),
                    ...(t?.inputSubtype?.value && {
                        inputSubtype: t?.inputSubtype?.value,
                    }),
                    ...(t?.outputSubtype?.value && {
                        outputSubtype: t?.outputSubtype?.value,
                    }),
                    ...(t?.flags && {
                        flags: t?.flags,
                    }),
                    transformerArguments: t?.transformerArguments.map((ta, i) => {
                        return {
                            argumentOrder: i,
                            dataSourceColumnId: ta?.id ?? null,
                            ...(ta?.argumentInstanceId && {
                                argumentInstanceId: ta?.argumentInstanceId,
                            }),
                            ...(ta?.instanceMappingId && {
                                instanceMappingId: ta?.instanceMappingId,
                            }),
                        };
                    }),
                };

                getPreview({
                    query: previewServicerTransferTransformer,
                    variables: {
                        feedId: 0,
                        inputDataSourceIds: inputDataSourceIds,
                        outputDataSourceId: outputDataSourceId,
                        inputTransformer: transformer,
                    },
                });

                return {
                    ...state,
                    preview: null,
                    requestPreview: true,
                    previewError: null,
                    // step: 11,
                    // feed: action?.payload?.feed,
                };
            }

            case "CLOSE_PREVIEW": {
                return {
                    ...state,
                    preview: null,
                    requestPreview: false,
                    previewError: null,
                    previewId: null,
                };
            }

            case "SET_FEED_PREVIEW": {
                return {
                    ...state,
                    preview: action?.payload?.preview,
                    previewError: action?.payload?.previewError,
                    requestPreview: false,
                    previewId: null,
                };
            }

            case "SET_PREVIEW_ID": {
                return {
                    ...state,
                    previewId: action?.payload?.feedId,
                };
            }

            case "CREATE_FEED_PREVIEW": {
                return {
                    ...state,
                    previewId: action?.payload?.previewId,
                    requestPreview: true,
                };
            }

            case "CREATE_FEED_PREVIEW_FAILED": {
                return {
                    ...state,
                    requestPreview: false,
                };
            }
        }
    };

    const initialState = {
        requestPreview: false,
        previewId: null,
        preview: null,
        previewError: null,
    };

    const [state, dispatch] = useReducer(reducer, initialState);

    const { feedPreviewNotification, setFeedPreviewNotification } =
        useFeedNotifications();

    useEffect(() => {
        if (previewErrors?.length) {
            dispatch({
                type: "CREATE_FEED_PREVIEW_FAILED",
            });
        } else {
            if (previewData?.previewServicerTransferTransformer) {
                dispatch({
                    type: "CREATE_FEED_PREVIEW",
                    payload: {
                        previewId: previewData?.previewServicerTransferTransformer,
                    },
                });
            }
        }
    }, [previewData, previewErrors]);

    useEffect(() => {
        const payload = feedPreviewNotification?.payload;

        const matchLastRequest = state?.previewId === payload?.RequestId;

        if (state.previewId && matchLastRequest) {
            if (payload?.AlertType === 34 && payload?.SourceType === 7) {
                //succeeded
                dispatch({
                    type: "SET_FEED_PREVIEW",
                    payload: {
                        preview: JSON.parse(payload?.SerializedPreviewData),
                        previewError: payload?.ErrorMessage,
                    },
                });
                setFeedPreviewNotification(null);
            }
        }
    }, [feedPreviewNotification, setFeedPreviewNotification, state]);

    const addArguments = React.useCallback(
        (isSplit) => {
            setTransformations((prev) => {
                let values = [...prev];

                // find current index of transformation
                const currentIndex = values.map((e) => e.uuid).indexOf(uuid);

                let currentTransformations = [
                    ...values?.[currentIndex]?.transformations,
                ];

                if (isSplit) {
                    currentTransformations[transformIndex].transformerArguments = [
                        ...currentTransformations[transformIndex]?.transformerArguments.map(
                            (ta) => {
                                return { ...ta };
                            }
                        ),
                        {
                            dataSourceColumnId: null,
                            column: null,
                        },
                    ];
                } else {
                    currentTransformations[transformIndex].transformerArguments = [
                        {
                            dataSourceColumnId: null,
                            column: null,
                        },
                        ...currentTransformations[transformIndex]?.transformerArguments.map(
                            (ta) => {
                                return { ...ta };
                            }
                        ),
                    ];
                }

                values[currentIndex] = {
                    ...values?.[currentIndex],
                    transformations: currentTransformations,
                };
                return values;
            });
        },
        [setTransformations, transformIndex, uuid]
    );

    const removeArgument = React.useCallback(
        (transformIndex, argumentIndex) => {
            setTransformations((prev) => {
                let values = [...prev];
                // find current index of transformation
                const currentIndex = values.map((e) => e.uuid).indexOf(uuid);

                let currentTransformations = [
                    ...values?.[currentIndex]?.transformations,
                ];

                currentTransformations[transformIndex].transformerArguments.splice(
                    argumentIndex,
                    1
                );

                values[currentIndex] = {
                    ...values?.[currentIndex],
                    transformations: currentTransformations,
                };

                return values;
            });
        },
        [uuid, setTransformations]
    );

    const clearArgument = React.useCallback(
        (transformIndex, argumentIndex) => {
            setTransformations((prev) => {
                // clone values from transformations
                let values = [...prev];

                // find current index of transformation
                const currentIndex = values.map((e) => e.uuid).indexOf(uuid);

                // clone transformations
                let currentTransformations = [
                    ...values?.[currentIndex]?.transformations,
                ];

                // clear selections
                currentTransformations[transformIndex].transformerArguments[
                    argumentIndex
                ] = {
                    dataSourceColumnId: null,
                    column: null,
                };

                // re-insert the empty values
                values[currentIndex] = {
                    ...values?.[currentIndex],
                    transformations: currentTransformations,
                };

                return values;
            });
        },
        [setTransformations, uuid]
    );

    const updateArgumentValue = React.useCallback(
        (transformIndex, argumentIndex, value) => {
            setTransformations((prev) => {
                let values = [...prev];
                // find current index of transformation
                const currentIndex = values.map((e) => e.uuid).indexOf(uuid);

                let currentTransformations = [
                    ...values?.[currentIndex]?.transformations,
                ];

                const currentType =
                    currentTransformations[transformIndex].transformationType?.value;

                if (isInverse(currentType)) {
                    function checkIfValueExistsInOutput() {
                        const exists = currentTransformations[
                            transformIndex
                        ].transformerArguments.find(
                            (arg) => arg?.column?.id === value?.column?.id
                        );

                        if (exists) {
                            // find current existing index of transformation
                            const indexToRemove = currentTransformations[
                                transformIndex
                            ].transformerArguments
                                .map((e) => e?.column?.id)
                                .indexOf(exists?.column?.id);

                            // remove the existing duplicates
                            currentTransformations[transformIndex].transformerArguments[
                                indexToRemove
                            ] = {
                                dataSourceColumnId: null,
                                column: null,
                            };

                            // Move the value over
                            currentTransformations[transformIndex].transformerArguments[
                                argumentIndex
                            ] = { ...value };

                            values[currentIndex] = {
                                ...values?.[currentIndex],
                                transformations: currentTransformations,
                            };
                        }
                    }

                    checkIfValueExistsInOutput();

                    const syncMissingOutputs = () => {
                        let used = [];

                        values.forEach((v) => {
                            v?.transformations?.forEach((st) => {
                                if (!isInverse(st?.transformationType?.value)) {
                                    st?.transformerArguments?.forEach((sta) => {
                                        if (value?.fullyQualifiedName === sta?.fullyQualifiedName) {
                                            used.push({
                                                uuid: v?.uuid,
                                                transformation: { ...st },
                                            });
                                        }
                                    });
                                }
                            });
                        });

                        used.forEach((sco) => {
                            if (
                                window.confirm(
                                    `This mapping will overwrite the single mapping: [${sco?.transformation?.transformerArguments?.[0]?.column?.name
                                        ? sco?.transformation?.transformerArguments?.[0]?.column
                                            ?.name
                                        : ""
                                    }] -> [${sco?.transformation?.transformerArguments?.[1]?.column?.name
                                    }] please confirm this action. If you want to remap this single transaction you can clear the column and the template will bring back the single mapping.`
                                )
                            ) {
                                currentTransformations[transformIndex].transformerArguments[
                                    argumentIndex
                                ] = { ...value };

                                // find current index of transformation
                                const currentIndexAdjustment = values
                                    .map((e) => e.uuid)
                                    .indexOf(uuid);

                                values[currentIndexAdjustment] = {
                                    ...values?.[currentIndexAdjustment],
                                    transformations: currentTransformations,
                                };

                                // find index to remove transformation
                                const indexToRemove = values
                                    .map((e) => e.uuid)
                                    .indexOf(sco?.uuid);

                                values?.splice(indexToRemove, 1);
                            }
                        });

                        if (!used?.length) {
                            // Move the value over
                            currentTransformations[transformIndex].transformerArguments[
                                argumentIndex
                            ] = { ...value };

                            values[currentIndex] = {
                                ...values?.[currentIndex],
                                transformations: currentTransformations,
                            };
                        }
                    };

                    syncMissingOutputs();

                    return values;
                } else {
                    currentTransformations[transformIndex].transformerArguments[
                        argumentIndex
                    ] = { ...value };

                    // find current index of transformation
                    const currentIndex = values.map((e) => e.uuid).indexOf(uuid);

                    values[currentIndex] = {
                        ...values?.[currentIndex],
                        transformations: currentTransformations,
                    };

                    return values;
                }
            });
        },
        [uuid, setTransformations]
    );

    const updateRequiresData = React.useCallback(
        (transformIndex) => {
            setTransformations((prev) => {
                let values = [...prev];

                // find current index of transformation
                const currentIndex = values.map((e) => e.uuid).indexOf(uuid);

                let currentTransformations = [
                    ...values?.[currentIndex]?.transformations,
                ];
                currentTransformations[transformIndex].flags =
                    currentTransformations[transformIndex].flags === 1 ? 0 : 1;
                values[currentIndex] = {
                    ...values?.[currentIndex],
                    transformations: currentTransformations,
                };
                return values;
            });
        },
        [uuid, setTransformations]
    );

    const InputIo = ({ inputOptions, selectedValue, updateTransformFields }) => {
        return (
            <FormControl>
                <Label>Input Sub Type</Label>

                <StyledSelect
                    className={`react-select-container`}
                    classNamePrefix={`react-select`}
                    id={`inputSubType`}
                    inputId={`inputSubType-input`}
                    instanceId={`inputSubType-instance`}
                    menuPortalTarget={document.body}
                    options={inputOptions}
                    placeholder={`Input Sub Type`}
                    value={selectedValue?.transformations[transformIndex]?.inputSubtype}
                    menuPlacement="auto"
                    onChange={(e) => updateTransformFields(e, "inputSubtype")}
                />
            </FormControl>
        );
    };

    const OutputIo = ({
        outputOptions,
        selectedValue,
        updateTransformFields,
    }) => {
        return (
            <FormControl>
                <Label>Output Sub Type</Label>

                <StyledSelect
                    className={`react-select-container`}
                    classNamePrefix={`react-select`}
                    menuPortalTarget={document.body}
                    options={outputOptions}
                    id={`outputSubType`}
                    inputId={`outputSubType-input`}
                    instanceId={`outputSubType-instance`}
                    placeholder={`Output Sub Type`}
                    value={selectedValue?.transformations[transformIndex]?.outputSubtype}
                    menuPlacement="auto"
                    onChange={(e) => updateTransformFields(e, "outputSubtype")}
                />
            </FormControl>
        );
    };

    const TextInput = ({ selectedValue, updateTransformFields }) => {
        const initialValue =
            selectedValue?.transformations[transformIndex]?.formattingString;
        const transformationType =
            selectedValue?.transformations[transformIndex]?.transformationType?.value;
        const isMonthly = transformationType === "MONTH_ADJUSTMENT";

        const [inputText, setInputText] = useState(initialValue);

        const handleChange = (e) => {
            setInputText(e.target.value);
        };

        const onBlur = () => {
            updateTransformFields(inputText, "formattingString");
        };

        useEffect(() => {
            setInputText(initialValue);
        }, [initialValue]);

        return (
            <FormControl>
                <StyledInput
                    type="text"
                    name="formattingString"
                    label={isMonthly ? "Monthly Adjustment Value" : "Formatting String"}
                    value={inputText}
                    placeholder={
                        isMonthly ? "Monthly Adjustment Value" : "Formatting String"
                    }
                    onBlur={onBlur}
                    onChange={handleChange}
                />
            </FormControl>
        );
    };

    const updateTransformFields = (value, field) => {
        setTransformations((prev) => {
            let values = [...prev];
            const currentIndex = values.map((e) => e.uuid).indexOf(uuid);
            let currentTransformations = [...values?.[currentIndex]?.transformations];
            currentTransformations[transformIndex][field] = value;

            if (field === "transformationType") {
                currentTransformations[transformIndex].formattingString = "";
                currentTransformations[transformIndex].outputSubtype = "";
                currentTransformations[transformIndex].inputSubtype = "";
                currentTransformations[transformIndex].transformerArguments = [
                    currentTransformations[transformIndex]?.transformerArguments[0],
                    currentTransformations[transformIndex]?.transformerArguments[1],
                ];
            }

            if (
                value?.value === "CALCULATOR" ||
                value?.value === "MONTH_ADJUSTMENT"
            ) {
                currentTransformations[transformIndex].inputSubtype = {
                    label: "Custom Entry",
                    value: "CUSTOM_ENTRY",
                };
            }

            if (value?.value === "MONTH_ADJUSTMENT") {
                currentTransformations[transformIndex].transformerArguments = [
                    {
                        dataSourceColumnId: null,
                        column: null,
                    },
                    ...currentTransformations[transformIndex]?.transformerArguments,
                ];
            }

            if (value?.value === "FULL_NAME_PARSE") {
                currentTransformations[transformIndex].transformerArguments = [
                    ...currentTransformations[transformIndex]?.transformerArguments,
                    {
                        dataSourceColumnId: null,
                        column: null,
                    },
                    {
                        dataSourceColumnId: null,
                        column: null,
                    },
                    {
                        dataSourceColumnId: null,
                        column: null,
                    },
                    {
                        dataSourceColumnId: null,
                        column: null,
                    },
                ];
                currentTransformations[transformIndex].inputSubtype = {
                    label: "Auto Detect Format",
                    value: "FULL_NAME_PARSE_AUTO_DETECT",
                };
            }

            if (value?.value === "PHONE_NUMBER_PARSE") {
                currentTransformations[transformIndex].transformerArguments = [
                    ...currentTransformations[transformIndex]?.transformerArguments,
                    {
                        dataSourceColumnId: null,
                        column: null,
                    },
                    {
                        dataSourceColumnId: null,
                        column: null,
                    },
                    {
                        dataSourceColumnId: null,
                        column: null,
                    },
                    {
                        dataSourceColumnId: null,
                        column: null,
                    },
                ];
                currentTransformations[transformIndex].inputSubtype = {
                    label: "Default",
                    value: "PHONE_NUMBER_DEFAULT",
                };
            }

            if (
                value?.value === "DECIMAL_FORMAT" ||
                value?.value === "DECIMAL_FORMAT_PARSE"
            ) {
                currentTransformations[transformIndex].outputSubtype = {
                    label: "DECIMAL TWO DIGITS #.##",
                    value: "DECIMAL_TWO_DECIMALS",
                };
            }

            if (value?.value === "STRING_SPLIT") {
                currentTransformations[transformIndex].inputSubtype = {
                    label: "STRING SPLIT COMMON SEPARATORS",
                    value: "STRING_SPLIT_COMMON_SEPARATORS",
                };
            }

            if (value?.value === "STRING_REPLACE") {
                currentTransformations[transformIndex].inputSubtype = {
                    label: "STRING REPLACE REMOVE HYPHEN",
                    value: "STRING_REPLACE_REMOVE_HYPHEN",
                };
            }

      if (value?.value === "ADDRESS_PARSE") {
        currentTransformations[transformIndex].transformerArguments = [
          ...currentTransformations[transformIndex]?.transformerArguments,
          {
            dataSourceColumnId: null,
            column: null,
          },
          {
            dataSourceColumnId: null,
            column: null,
          },
          {
            dataSourceColumnId: null,
            column: null,
          },
          {
            dataSourceColumnId: null,
            column: null,
          },
          {
            dataSourceColumnId: null,
            column: null,
          },
          {
            dataSourceColumnId: null,
            column: null,
          },
          {
            dataSourceColumnId: null,
            column: null,
          },
          {
            dataSourceColumnId: null,
            column: null,
          },
          {
            dataSourceColumnId: null,
            column: null,
          },
          {
            dataSourceColumnId: null,
            column: null,
          },
          {
            dataSourceColumnId: null,
            column: null,
          },
          {
            dataSourceColumnId: null,
            column: null,
          },
        ];
      }

            values[currentIndex] = {
                ...values?.[currentIndex],
                transformations: currentTransformations,
            };

            return values;
        });
    };

    const inputOptions = transformationSubTypes.find((tst) =>
        tst?.parentTypes.includes(
            selectedValue?.transformations[transformIndex]?.transformationType?.value
        )
    )?.subTypeOptions;
    const outputOptions = transformationSubTypes.find((tst) =>
        tst?.parentTypes.includes(
            selectedValue?.transformations[transformIndex]?.transformationType?.value
        )
    )?.subTypeOptions;

    function renderIO(transformType, transformation) {
        switch (transformType?.io) {
            case 0: //only input
                return (
                    <>
                        <InputIo
                            inputOptions={inputOptions}
                            selectedValue={selectedValue}
                            updateTransformFields={updateTransformFields}
                        />
                    </>
                );
            case 1: //only output
                return (
                    <>
                        <OutputIo
                            outputOptions={outputOptions}
                            selectedValue={selectedValue}
                            updateTransformFields={updateTransformFields}
                        />
                    </>
                );
            case 2: //required
                return (
                    <>
                        <InputIo
                            inputOptions={inputOptions}
                            selectedValue={selectedValue}
                            updateTransformFields={updateTransformFields}
                        />
                        <OutputIo
                            outputOptions={outputOptions}
                            selectedValue={selectedValue}
                            updateTransformFields={updateTransformFields}
                        />
                    </>
                );
            case 3: //available but not required
                return <></>;
            case 4: //only output
                return (
                    <>
                        <TextInput
                            selectedValue={selectedValue}
                            updateTransformFields={updateTransformFields}
                        />
                    </>
                );
            case 5: //only output
                return (
                    <>
                        <InputIo
                            inputOptions={inputOptions}
                            selectedValue={selectedValue}
                            updateTransformFields={updateTransformFields}
                        />
                        {transformation?.inputSubtype?.value === "CUSTOM_ENTRY" && (
                            <TextInput
                                selectedValue={selectedValue}
                                updateTransformFields={updateTransformFields}
                            />
                        )}
                    </>
                );
            default:
                return null;
        }
    }
    const transformationType =
        selectedValue?.transformations[transformIndex]?.transformationType?.value;

    const isDictionary =
        transformationType === "DICTIONARY" ||
            transformationType === "DICTIONARY_PASS_THROUGH" ||
            transformationType === "COALESCE_DICTIONARY"
            ? true
            : false;

    const isStringFormat = transformationType === "STRING_FORMAT";
    const anOutputIsDefined =
        selectedValue?.transformations[transformIndex]?.transformerArguments[1]
            ?.fullyQualifiedName || isGenerated;

    //Preview
    // previewServicerTransferTransformer

    return (
        <div style={{ width: "100%" }}>
            <Scrollbars
                hideTracksWhenNotNeeded={true}
                autoHeightMax={window.innerHeight * 0.7}
                autoHeight
            >
                <div
                    style={{
                        background: "#f6f6f6",
                        padding: "1rem",
                        display: "flex",
                        flexDirection: "column",
                    }}
                >
                    {/* If you want to toggle the advanced transform this is where to do it */}
                    <div>
                        <FormControl
                            style={{ flex: 1, width: "100%", display: "inline-block" }}
                        >
                            <div style={{ display: "flex" }}>
                                <Label
                                    style={{ flex: 1, display: "flex", alignItems: "center" }}
                                >
                                    Select Transform Type
                                    <MdOutlineHelp
                                        onClick={() => setShowHelper((prev) => !prev)}
                                        style={{
                                            marginLeft: ".5rem",
                                            fontSize: "1.2rem",
                                            cursor: "pointer",
                                        }}
                                        title="Transformation Help"
                                    />
                                </Label>
                            </div>
                            <StyledSelect
                                className={`react-select-container`}
                                classNamePrefix={`react-select`}
                                menuPortalTarget={document.body}
                                options={transformationTypeOptions.map((to) => {
                                    return {
                                        isDisabled:
                                            (to?.value === "STRING_SPLIT" &&
                                                !selectedValue?.transformations[transformIndex]
                                                    ?.transformerArguments[0]?.fullyQualifiedName) ||
                                                (to?.value === "FULL_NAME_PARSE" &&
                                                    !selectedValue?.transformations[transformIndex]
                                                        ?.transformerArguments[0]?.fullyQualifiedName) ||
                                                (to?.value === "PHONE_NUMBER_PARSE" &&
                                                    !selectedValue?.transformations[transformIndex]
                                                        ?.transformerArguments[0]?.fullyQualifiedName) ||
                                                (to?.value === "ADDRESS_PARSE" &&
                                                    !selectedValue?.transformations[transformIndex]
                                                        ?.transformerArguments[0]?.fullyQualifiedName) ||
                                                !anOutputIsDefined
                                                ? true
                                                : false,
                                        ...to,
                                    };
                                })}
                                formatOptionLabel={formatTransformOptionLabel}
                                placeholder={`Transform Type`}
                                id={`transformType`}
                                inputId={`transformType-input`}
                                instanceId={`transformType-instance`}
                                value={
                                    selectedValue?.transformations[transformIndex]
                                        ?.transformationType
                                }
                                menuPlacement="auto"
                                onChange={(e) => updateTransformFields(e, "transformationType")}
                                styles={{
                                    control: (provided) => ({
                                        ...provided,
                                    }),
                                }}
                            />
                        </FormControl>

                        {renderIO(
                            selectedValue?.transformations[transformIndex]
                                ?.transformationType,
                            selectedValue?.transformations[transformIndex]
                        )}
                        {showHelper && (
                            <div
                                style={{
                                    padding: "1rem",
                                    background: "#fbfbfb",
                                    marginBottom: "1rem",
                                }}
                            >
                                <MarkdownFetcher
                                    MdFile={
                                        selectedValue?.transformations[transformIndex]
                                            ?.transformationType?.value
                                    }
                                    close={setShowHelper}
                                />
                            </div>
                        )}

                        <div
                            style={{
                                display: "flex",
                                marginBottom: "1rem",
                            }}
                        >
                            <label style={{ fontSize: ".9rem", cursor: "pointer" }}>
                                <input
                                    type="checkbox"
                                    name={`requiresData`}
                                    label="Requires Data"
                                    checked={
                                        selectedValue?.transformations[transformIndex]?.flags === 1
                                    }
                                    onChange={() => updateRequiresData(transformIndex)}
                                />
                                Requires Data
                            </label>
                        </div>

                        {isStringFormat ||
                            transformationType === "STRING_SPLIT" ||
                            transformationType === "COALESCE" ||
                            transformationType === "COALESCE_DICTIONARY" ||
                            transformationType === "N_CALC" ||
                            transformationType === "CALCULATOR" ? (
                            <div style={{ marginBottom: "1rem", display: "flex" }}>
                                <div style={{ minWidth: "120px" }}>
                                    <Button
                                        onClick={() =>
                                            addArguments(transformationType === "STRING_SPLIT")
                                        }
                                    >
                                        Add Column
                                    </Button>
                                </div>

                                <p style={{ marginLeft: "1rem" }}>
                                    Columns are in the order of Input, Output, if you need to add
                                    more columns, they will prepend the Output Column except for
                                    when using a splitting transform.
                                </p>
                            </div>
                        ) : null}

                        <div
                            style={{ display: "flex", marginTop: "1rem", flexWrap: "wrap" }}
                        >
                            {selectedValue?.transformations[
                                transformIndex
                            ]?.transformerArguments.map((ta, argumentIndex) => {
                                return (
                                    <TransformerArguments
                                        argument={ta}
                                        transformerArguments={
                                            selectedValue?.transformations[transformIndex]
                                                ?.transformerArguments
                                        }
                                        argumentIndex={argumentIndex}
                                        transformIndex={transformIndex}
                                        removeArgument={removeArgument}
                                        clearArgument={clearArgument}
                                        setSelectedValues={setSelectedValues}
                                        ingestColumnOptions={ingestColumnOptions}
                                        sourceColumnOptions={sourceColumnOptions}
                                        updateArgumentValue={updateArgumentValue}
                                        isDictionary={isDictionary}
                                        isStringFormat={isStringFormat}
                                        transformationType={transformationType}
                                    />
                                );
                            })}
                        </div>
                    </div>
                </div>

                {state?.preview || state?.previewError ? (
                    <div
                        style={{
                            background: "#f6f6f6",
                            padding: "1rem",
                            display: "flex",
                            flexDirection: "column",
                            marginTop: "1rem",
                        }}
                    >
                        <PreviewTransformation
                            transformation={selectedValue?.transformations[transformIndex]}
                            preview={state?.preview}
                            error={state?.previewError}
                            dispatch={dispatch}
                        />
                    </div>
                ) : null}

                {previewErrors && <ErrorMessages errors={previewErrors} />}
            </Scrollbars>

            {/* If we need to support multiple transforms this can be utilized */}
            <div
                style={{ display: "flex", justifyContent: "center", marginTop: "2rem" }}
            >
                <Button
                    danger
                    list={true}
                    onClick={() => {
                        setSelectedValues((prev) => {
                            let values = [...prev];
                            // find current index of transformation
                            const currentIndex = values.map((e) => e.uuid).indexOf(uuid);
                            values?.splice(currentIndex, 1);
                            return values;
                        });
                        collapse();
                    }}
                    title="Clear and Close Transformation"
                >
                    {isGenerated ? "Remove" : "Clear Transformation"}
                </Button>

                <Button list={true} danger onClick={() => collapse()}>
                    Cancel
                </Button>

                {!isDictionary && !isNotGeneratedYet && (
                    <Button
                        list={true}
                        onClick={() =>
                            dispatch({
                                type: "START_PREVIEW",
                                payload: {
                                    transformer: selectedValue?.transformations[transformIndex],
                                },
                            })
                        }
                    >
                        {previewLoading || state?.requestPreview ? <Spinner /> : "Preview"}
                    </Button>
                )}

                <Button onClick={() => CloseAndSave()}>Continue</Button>
            </div>

            {isNotGeneratedYet && (
                <p
                    style={{ marginTop: "1rem", fontSize: ".8rem", textAlign: "center" }}
                >
                    *This transformation involves a generated source that has not been
                    created yet, no preview is available at this time.
                </p>
            )}
        </div>
    );
};

const formatTransformOptionLabel = ({ label, description }) => {
    return (
        <div style={{ display: "flex", flexDirection: "column", padding: ".5rem" }}>
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    fontSize: "1.1rem",
                    marginBottom: ".3rem",
                }}
            >
                {label}
            </div>
            {description && (
                <div
                    style={{
                        opacity: 0.5,
                        fontSize: "1rem",
                        position: "relative",
                        display: "block",
                        maxWidth: "100%",
                        whiteSpace: "normal",
                    }}
                >
                    {description}
                </div>
            )}
        </div>
    );
};

const formatOptionLabel = ({ label, fullyQualifiedName, column }) => {
    const flags = column?.flags;
    const isCodeBreak = (flags & 64) > 0;
    const sourceColumnName = fullyQualifiedName?.split(/\|/) ?? "";
    return (
        <div style={{ display: "flex" }}>
            <div
                style={{
                    display: "flex",
                    justifyContent: "left",
                    flexDirection: "column",
                    padding: "1rem",
                }}
            >
                <div style={{ fontSize: "1.1rem", marginBottom: ".3rem" }}>
                    {label}{" "}
                </div>

                {sourceColumnName ? (
                    <div
                        style={{
                            display: "flex",
                            opacity: ".4",
                        }}
                    >
                        {isCodeBreak && (
                            <MdLibraryBooks
                                style={{ marginRight: ".2rem" }}
                                title="Code Break Column"
                            />
                        )}{" "}
                        <FaDatabase style={{ marginRight: ".2rem" }} />{" "}
                        {sourceColumnName?.[0]}
                    </div>
                ) : null}
            </div>
        </div>
    );
};

const MappingNew = ({
    ingestColumnOptions,
    editingTransfer,
    setSelectedValues,
    sourceColumnOptions,
    collapse,
    inputDataSourceIds,
    outputDataSourceId,
    selectedValues,
    isGenerated,
}) => {
    return (
        <>
            <div style={{ position: "relative" }}>
                <div
                    style={{
                        display: "flex",
                        alignItems: "top",
                        minHeight: "125px",
                    }}
                >
                    <SelectTransformType
                        uuid={editingTransfer?.uuid}
                        setSelectedValues={setSelectedValues}
                        transformIndex={0}
                        sourceColumnOptions={sourceColumnOptions}
                        ingestColumnOptions={ingestColumnOptions}
                        collapse={collapse}
                        inputDataSourceIds={inputDataSourceIds}
                        outputDataSourceId={outputDataSourceId}
                        selectedValues={selectedValues}
                        isGenerated={isGenerated}
                    />
                </div>
            </div>
        </>
    );
};

function validateSourceExistence(sourceId, ingestSources, column) {
    const sourceIds = [sourceId, ...ingestSources.map((a) => a.id)];

    const exists = sourceIds.includes(column?.dataSourceId);
    return exists ? true : false;
}

const SmartMapping = ({
    sourceId,
    sourceColumnOptions,
    ingestColumnOptions,
    finalSuggestions,
    dispatch,
    state,
    ingestSources,
    outputSource,
    isGeneratedOutput,
}) => {
  const [configureSchedule, setConfigureSchedule] = useState();
  function checkOrderBound(type) {
    // we add plus one because of the input transform col count
    if (type === "PHONE_NUMBER_PARSE") {
      return 5 + 1;
    } else if (type === "FULL_NAME_PARSE") {
      return 5 + 1;
    } else if (type === "ADDRESS_PARSE") {
      return 13 + 1;
    } else {
      return false;
    }
  }

    function buildSelected() {
        if (state?.feedId && state?.isClone) {
            //handle re-building the saved feed.
            const transformersData = state?.cloneSuggested?.feed?.transformers ?? [];

            const transformers = transformersData.map((tf) => {
                const hasMissingArgument = tf.transformerArguments?.length <= 1;

                const isOrderBound = checkOrderBound(tf.transformationType);

                return {
                    uuid: uuid(),
                    transformations: [
                        {
                            id: tf.id,
                            transformationType: transformationTypeOptions.find(
                                (t) => t.value === tf.transformationType
                            ),
                            flags: tf.flags,
                            inputSubtype: transformationSubTypes
                                .find((tst) => tst?.parentTypes.includes(tf.transformationType))
                                ?.subTypeOptions.find((sto) => sto.value === tf.inputSubtype),
                            outputSubtype: transformationSubTypes
                                .find((tst) => tst?.parentTypes.includes(tf.transformationType))
                                ?.subTypeOptions.find((sto) => sto.value === tf.outputSubtype),
                            formattingString: tf.formattingString ?? "",
                            transformerArguments: isOrderBound
                                ? Array.apply(null, Array(isOrderBound)).map(function (_, i) {
                                    const findArgument = tf.transformerArguments.find(
                                        (a) => a.argumentOrder === i
                                    );

                                    if (i === 0) {
                                        return {
                                            argumentOrder:
                                                tf.transformerArguments[0]?.argumentOrder,
                                            argumentInstanceId:
                                                tf.transformerArguments[0]?.argumentInstanceId,
                                            instanceMappingId:
                                                tf.transformerArguments[0]?.instanceMappingId,
                                            dataSourceColumnId:
                                                tf.transformerArguments[0].dataSourceColumnId,
                                            column: tf.transformerArguments[0]?.column,
                                            label: tf.transformerArguments[0]?.column?.name,
                                            value: tf.transformerArguments[0]?.column?.id,
                                            ...tf.transformerArguments[0]?.column,
                                        };
                                    } else if (
                                        findArgument &&
                                        validateSourceExistence(
                                            sourceId,
                                            state?.ingestSources,
                                            findArgument?.column
                                        )
                                    ) {
                                        return {
                                            argumentOrder: findArgument?.argumentOrder,
                                            argumentInstanceId: findArgument?.argumentInstanceId,
                                            instanceMappingId: findArgument?.instanceMappingId,
                                            dataSourceColumnId: findArgument.dataSourceColumnId,
                                            column: findArgument?.column,
                                            label: findArgument?.column?.name,
                                            value: findArgument?.column?.id,
                                            ...findArgument?.column,
                                        };
                                    } else {
                                        return { dataSourceColumnId: null, column: null };
                                    }
                                })
                                : [
                                    ...(hasMissingArgument
                                        ? [{ dataSourceColumnId: null }]
                                        : []),
                                    ...tf.transformerArguments.map((ta, i) => {
                                        if (
                                            validateSourceExistence(
                                                sourceId,
                                                state?.ingestSources,
                                                ta?.column
                                            )
                                        ) {
                                            return {
                                                argumentOrder: ta?.argumentOrder,
                                                argumentInstanceId: ta?.argumentInstanceId,
                                                instanceMappingId: ta?.instanceMappingId,
                                                dataSourceColumnId: ta.dataSourceColumnId,
                                                column: ta?.column,
                                                label: ta?.column?.name,
                                                value: ta?.column?.id,
                                                ...ta?.column,
                                            };
                                        } else {
                                            return {
                                                dataSourceColumnId: null,
                                                column: null,
                                            };
                                        }
                                    }),
                                ],
                        },
                    ],
                };
            });

            return transformers;
        } else if (state?.feedId) {
            //handle re-building the saved feed.
            const transformersData = state?.feed?.transformers ?? [];

            // check if its a generated output
            const isGeneratedOutputSource =
                state?.feed?.destinationDataSource?.tagInstances?.some(
                    (tagInstance) => tagInstance.tagId === -7
                );
            let negativeColumnId = 0;

            const transformers = transformersData.map((tf) => {
                const isOrderBound = checkOrderBound(tf.transformationType);

                const hasMissingArgument = tf.transformerArguments?.length <= 1;
                return {
                    uuid: uuid(),
                    transformations: [
                        {
                            id: tf.id,
                            transformationType: transformationTypeOptions.find(
                                (t) => t.value === tf.transformationType
                            ),
                            flags: tf.flags,
                            inputSubtype: transformationSubTypes
                                .find((tst) => tst?.parentTypes.includes(tf.transformationType))
                                ?.subTypeOptions.find((sto) => sto.value === tf.inputSubtype),
                            outputSubtype: transformationSubTypes
                                .find((tst) => tst?.parentTypes.includes(tf.transformationType))
                                ?.subTypeOptions.find((sto) => sto.value === tf.outputSubtype),
                            formattingString: tf.formattingString ?? "",
                            transformerArguments: isOrderBound
                                ? Array.apply(null, Array(isOrderBound)).map(function (_, i) {
                                    const findArgument = tf.transformerArguments.find(
                                        (a) => a.argumentOrder === i
                                    );

                                    if (i === 0) {
                                        return {
                                            argumentOrder:
                                                tf.transformerArguments[0]?.argumentOrder,
                                            argumentInstanceId:
                                                tf.transformerArguments[0]?.argumentInstanceId,
                                            instanceMappingId:
                                                tf.transformerArguments[0]?.instanceMappingId,
                                            dataSourceColumnId:
                                                tf.transformerArguments[0].dataSourceColumnId,
                                            column: tf.transformerArguments[0]?.column,
                                            label: tf.transformerArguments[0]?.column?.name,
                                            value: tf.transformerArguments[0]?.column?.id,
                                            ...tf.transformerArguments[0]?.column,
                                        };
                                    } else if (
                                        findArgument &&
                                        validateSourceExistence(
                                            sourceId,
                                            state?.ingestSources,
                                            findArgument?.column
                                        )
                                    ) {
                                        return {
                                            argumentOrder: findArgument?.argumentOrder,
                                            argumentInstanceId: findArgument?.argumentInstanceId,
                                            instanceMappingId: findArgument?.instanceMappingId,
                                            dataSourceColumnId: findArgument.dataSourceColumnId,
                                            column: findArgument?.column,
                                            label: findArgument?.column?.name,
                                            value: findArgument?.column?.id,
                                            ...findArgument?.column,
                                        };
                                    } else {
                                        return { dataSourceColumnId: null, column: null };
                                    }
                                })
                                : [
                                    ...(hasMissingArgument
                                        ? [{ dataSourceColumnId: null, column: null }]
                                        : []),
                                    ...tf.transformerArguments.map((ta, i) => {
                                        if (
                                            validateSourceExistence(
                                                sourceId,
                                                state?.ingestSources,
                                                ta?.column
                                            )
                                        ) {
                                            return {
                                                argumentInstanceId: ta?.argumentInstanceId,
                                                instanceMappingId: ta?.instanceMappingId,
                                                dataSourceColumnId: ta.dataSourceColumnId,
                                                column: ta?.column,
                                                label: ta?.column?.name,
                                                value: ta?.column?.id,
                                                ...ta?.column,
                                            };
                                        } else {
                                            if (isGeneratedOutputSource) {
                                                negativeColumnId = negativeColumnId - 1;

                                                return {
                                                    dataSourceColumnId: negativeColumnId, //Output Generated Selected Column ID
                                                    name: tf.transformerArguments[0]?.column?.name, //Output Generated Name Default to the Input
                                                    id: negativeColumnId,
                                                };
                                            } else {
                                                return {
                                                    dataSourceColumnId: null,
                                                    column: null,
                                                };
                                            }
                                        }
                                    }),
                                ],
                        },
                    ],
                };
            });

            return transformers;
        } else {
            // generated output source
            if (!sourceColumnOptions?.length) {
                if (!ingestColumnOptions?.length) {
                    // if the incoming data source is empty something has gone bad
                    return [];
                } else {
                    const emptyOutputTransforms = ingestColumnOptions.map((tsf, i) => {
                        const flags = tsf?.flags;
                        const isCodeBreak = (flags & 64) > 0;
                        const isDictionary = isCodeBreak;
                        return {
                            uuid: uuid(),
                            transformations: [
                                {
                                    transformationType: isDictionary
                                        ? {
                                            label: "Code Break",
                                            value: "DICTIONARY",
                                        }
                                        : {
                                            label: "Force Match",
                                            value: "STRING_FORMAT",
                                        },
                                    transformerArguments: [
                                        {
                                            dataSourceColumnId: tsf?.id, //Input column Id
                                            column: tsf, //Input Column
                                            label: tsf?.name,
                                            value: tsf?.id,
                                            ...tsf,
                                        },
                                        {
                                            dataSourceColumnId: -i - 1, //Output Generated Selected Column ID
                                            name: tsf?.name, //Output Generated Name Default to the Input
                                            id: -i - 1,
                                        },
                                    ],
                                },
                            ],
                        };
                    });
                    return emptyOutputTransforms;
                }
            } else {
                // legacy Output UX
                //if this not editing do the smart mapping
                const selected = sourceColumnOptions.map((tsf, i) => {
                    const foundSuggestion = finalSuggestions.find(
                        (fs) => fs.key.id === tsf?.column?.id
                    )?.value?.[0]?.key;

                    const flags = tsf?.flags;
                    const isCodeBreak = (flags & 64) > 0;
                    let sourceColumnFlags = foundSuggestion?.flags;
                    const sourceHasCodeBreak = (sourceColumnFlags & 64) > 0;

                    const isDictionary = isCodeBreak || sourceHasCodeBreak;

                    return foundSuggestion
                        ? {
                            uuid: uuid(),
                            transformations: [
                                {
                                    transformationType: isDictionary
                                        ? {
                                            label: "Code Break",
                                            value: "DICTIONARY",
                                        }
                                        : {
                                            label: "Force Match",
                                            value: "STRING_FORMAT",
                                        },
                                    transformerArguments: [
                                        {
                                            dataSourceColumnId: foundSuggestion?.id, //external Selected Column ID
                                            column: foundSuggestion, // Column
                                            label: foundSuggestion?.name,
                                            value: foundSuggestion.id,
                                            ...foundSuggestion,
                                        },
                                        {
                                            dataSourceColumnId: tsf?.id, //Output column Id
                                            column: tsf, // Column
                                            label: tsf?.name,
                                            value: tsf?.id,
                                            ...tsf,
                                        },
                                    ],
                                },
                            ],
                        }
                        : {
                            uuid: uuid(),
                            transformations: [
                                {
                                    transformationType: isDictionary
                                        ? {
                                            label: "Code Break",
                                            value: "DICTIONARY",
                                        }
                                        : {
                                            label: "Force Match",
                                            value: "STRING_FORMAT",
                                        },
                                    transformerArguments: [
                                        {
                                            dataSourceColumnId: null, //external Selected Column ID
                                        },
                                        {
                                            dataSourceColumnId: tsf?.id, //Output column Id
                                            column: tsf, //Ouput Column
                                            label: tsf?.name,
                                            value: tsf?.id,
                                            ...tsf,
                                        },
                                    ],
                                },
                            ],
                        };
                });
                return selected;
            }
        }
    }

    function validatePrimaryAvail(feed, sources) {
        const isFound = sources.find(
            (s) => s?.id === feed?.primarySourceDataSourceId
        );
        return isFound ? feed?.primarySourceDataSourceId : null ?? null;
    }

    const [selectedValues, setSelectedValues] = useState(buildSelected());

    const [feedName, setFeedName] = useState(state?.feed?.name ?? "");
    const [feedSchedule, setFeedSchedule] = useState(
        state?.feed?.scheduledJob
            ? {
                activeMonitoring:
                    state?.feed?.scheduledJob?.activeMonitoring ?? "false",
                frequency: state?.feed?.scheduledJob?.recurrenceInHours,
                workingDayObservationTypes:
                    state?.feed?.scheduledJob?.workingDayObservation,
            }
            : null
    );
    const [touched, setTouched] = useState(false);

    const freqOptions = [
        { incomingValue: 1, value: "HOURLY", label: "Hourly" },
        { incomingValue: 24, value: "DAILY", label: "Daily" },
        { incomingValue: 168, value: "WEEKLY", label: "Weekly" },
        { incomingValue: 720, value: "MONTHLY", label: "Monthly" },
        { incomingValue: 256204778.80152154, value: "NEVER", label: "Never" },
    ];

    const holidayOptions = [
        { value: "DEFAULT", label: "Default" },
        { value: "WORKING_DAYS_ONLY", label: "Observes Working Days Only" },
        { value: "HOLIDAYS_ONLY", label: "Observes Holidays Only" },
        {
            value: "BOTH",
            label: "Observes Both Working Days and Holidays",
        },
    ];

    const holidayValue = holidayOptions.find(
        (o) => o.value === feedSchedule?.workingDayObservationTypes
    ) ?? { value: "DEFAULT", label: "Default" };

    const currentFreq =
        freqOptions.find(
            (option) =>
                option.incomingValue === feedSchedule?.frequency ||
                option.value === feedSchedule?.frequency
        ) ?? "";

    const [primarySourceDataSourceId, setPrimarySourceDataSourceId] = useState(
        validatePrimaryAvail(state?.feed, state?.ingestSources) ?? null
    );

    const [isEditing, setIsEditing] = useState(null);
    const { closeMenu } = useContext(NavContext);

    useEffect(() => {
        if (isEditing) {
            closeMenu();
        }
    }, [isEditing, closeMenu]);

    // Check for any missing output values that may have been removed from
    // a stringsplit usecase etc.

    //  TODO: broken with generated outputs, need to revisit but
    useEffect(() => {
        const syncMissingOutputs = () => {
            let used = [];

            selectedValues.forEach((v, vi) => {
                v?.transformations?.forEach((st, si) => {
                    st?.transformerArguments?.forEach((sta, stai) => {
                        used.push(sta?.fullyQualifiedName);
                    });
                });
            });

            let missing = [];

            sourceColumnOptions.forEach((sco) => {
                const isFound = used.find((u, i) => {
                    return u === sco?.fullyQualifiedName;
                });
                if (!isFound) {
                    missing.push({
                        uuid: uuid(),
                        transformations: [
                            {
                                transformationType: {
                                    label: "Force Match",
                                    value: "STRING_FORMAT",
                                },
                                transformerArguments: [
                                    {
                                        dataSourceColumnId: null, //external Selected Column ID
                                    },
                                    {
                                        dataSourceColumnId: sco?.id, //Output column Id
                                        column: sco, //Ouput Column
                                        label: sco?.name,
                                        value: sco?.id,
                                        ...sco,
                                    },
                                ],
                            },
                        ],
                    });
                }
            });

            if (missing?.length) {
                setSelectedValues((prev) => {
                    const values = [...prev];

                    const updatedMappings = missing.map((m, mi) => {
                        return {
                            ...m,
                        };
                    });
                    const newValues = [...values, ...updatedMappings];
                    return newValues;
                });
            }
        };
        if (sourceColumnOptions?.length && !isGeneratedOutput) {
            //call the above function to update our selected state
            syncMissingOutputs();
        }
    }, [selectedValues, sourceColumnOptions, isGeneratedOutput]);

    const columnsData = [
        {
            Header: "Transformations",
            columns: [
                {
                    Header: "Status",
                    width: 100,
                    maxWidth: 100,
                    accessor: (originalRow) => {
                        const hasManual =
                            originalRow?.transformations?.[0]?.transformerArguments?.[0]?.id;

                        return hasManual;
                    },
                    Cell: ({ row }) => {
                        function checkValid(type, transformation) {
                            switch (type) {
                                default:
                                    return true;
                                case "CALCULATOR": {
                                    const hasString = transformation?.formattingString;
                                    if (hasString !== "") {
                                        return true;
                                    } else {
                                        return false;
                                    }
                                }
                            }
                        }
                        const transformation = row?.original?.transformations?.[0];
                        const length = transformation?.transformerArguments?.length;

                        const lastItem = transformation?.transformerArguments[length - 1];

                        const hasAutoFilledId = finalSuggestions.find(
                            (fs) => fs.key.id === lastItem?.column?.id
                        )?.value?.[0]?.key?.id;

                        const isAutoFilled = hasAutoFilledId
                            ? transformation?.transformerArguments?.[0]?.id ===
                            hasAutoFilledId
                            : false;

                        const hasManual = transformation?.transformerArguments?.[0]?.id;

                        const type = transformation?.transformationType?.value;

                        const isValid = checkValid(type, transformation);

                        const isGeneratedOutput = outputSource?.node?.tagInstances?.some(
                            (tagInstance) => tagInstance.tagId === -7
                        );

                        const isMissingRequired =
                            (transformation?.flags === 1 && !hasManual) ||
                            !isValid ||
                            (isGeneratedOutput && !lastItem?.id);

                        return (
                            <>
                                {" "}
                                <div
                                    style={{
                                        position: "relative",
                                        fontSize: "1.5rem",
                                        display: "flex",
                                        alignItems: "center",
                                    }}
                                >
                                    {isMissingRequired ? (
                                        <div
                                            style={{
                                                color: "red",
                                                fontSize: "1.5rem",
                                                display: "flex",
                                                alignItems: "center",
                                            }}
                                        >
                                            <MdCircle title="Required And/Or Incomplete" />
                                        </div>
                                    ) : isAutoFilled ? (
                                        <div
                                            style={{
                                                color: "green",
                                                fontSize: "1.5rem",
                                                display: "flex",
                                                alignItems: "center",
                                            }}
                                        >
                                            <MdCircle title="Smart Mapping" />
                                        </div>
                                    ) : hasManual ? (
                                        <div
                                            style={{
                                                color: "#009fd4",
                                                fontSize: "1.5rem",
                                                display: "flex",
                                                alignItems: "center",
                                            }}
                                        >
                                            <MdCircle title="manual" />
                                        </div>
                                    ) : (
                                        <div
                                            style={{
                                                color: "#ccc",
                                                fontSize: "1.5rem",
                                                display: "flex",
                                                alignItems: "center",
                                            }}
                                        >
                                            <MdCircle title="Missing" />
                                        </div>
                                    )}

                                    {renderTypeTransformIcon(
                                        row?.original?.transformations[0]?.transformationType?.value
                                    )}
                                </div>
                            </>
                        );
                    },
                },
                {
                    Header: "Input",
                    id: "InputColumns",
                    accessor: (originalRow) => {
                        const columnArguments = [
                            ...originalRow?.transformations[0].transformerArguments,
                        ];
                        const first = columnArguments[0];
                        return first?.column?.name;
                    },
                    Cell: ({ row }) => {
                        const columnArguments = [
                            ...row?.original?.transformations[0]?.transformerArguments,
                        ];
                        const first = columnArguments[0];
                        const isInverseColumns = isInverse(
                            row?.original?.transformations[0]?.transformationType?.value
                        );

                        if (isInverseColumns) {
                            // remove the first item from column args
                            columnArguments.shift();
                        } else {
                            // remove the last item from the column
                            columnArguments.pop();
                        }

                        return (
                            <div>
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    {isInverseColumns ? (
                                        <>[{first?.column?.name}]</>
                                    ) : (
                                        columnArguments.map((inputColumn, i) => {
                                            return <>[{inputColumn?.column?.name}]</>;
                                        })
                                    )}
                                    <div style={{ marginLeft: "auto" }}>
                                        <div
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                                flexDirection: "row",
                                                minWidth: "50px",
                                            }}
                                        >
                                            <div
                                                style={{
                                                    flex: 1,
                                                    height: "1px",
                                                    backgroundColor: "#ccc",
                                                }}
                                            ></div>
                                            <div
                                                style={{
                                                    flex: 0,
                                                    width: 0,
                                                    height: 0,
                                                    borderTop: "5px solid transparent",
                                                    borderBottom: "5px solid transparent",
                                                    borderLeft: "10px solid #ccc",
                                                }}
                                            ></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        );
                    },
                },
                {
                    Header: "Output",
                    accessor: (originalRow) => {
                        const columnArguments = [
                            ...originalRow?.transformations[0].transformerArguments,
                        ];
                        const argumentsLength =
                            originalRow?.transformations[0].transformerArguments?.length;
                        const last = columnArguments[argumentsLength - 1];
                        return last?.column?.name;
                    },
                    Cell: ({ row: { original } }) => {
                        const columnArguments = [
                            ...original?.transformations[0].transformerArguments,
                        ];

                        const argumentsLength =
                            original?.transformations[0].transformerArguments?.length;

                        const last = columnArguments[argumentsLength - 1];

                        const isInverseColumns = isInverse(
                            original?.transformations[0].transformationType?.value
                        );

                        if (isInverseColumns) {
                            // remove the first item from column args
                            columnArguments.shift();
                        } else {
                            // remove the last item from the column
                            columnArguments.pop();
                        }
                        const isGeneratedOutputSource =
                            state?.feed?.destinationDataSource?.tagInstances?.some(
                                (tagInstance) => tagInstance.tagId === -7
                            );

                        if (isGeneratedOutputSource && last?.dataSourceColumnId <= 0) {
                            return <>[{last?.name}]</>;
                        } else {
                            return (
                                <>
                                    {isInverseColumns ? (
                                        columnArguments.map((inputColumn, i) => {
                                            return <>[{inputColumn?.column?.name}]</>;
                                        })
                                    ) : (
                                        <>[{last?.column?.name}]</>
                                    )}
                                </>
                            );
                        }
                    },
                },
                {
                    Header: "Type",
                    accessor: (originalRow) => {
                        return originalRow?.transformations[0]?.transformationType?.label;
                    },
                    Cell: ({ row: { original, ...props } }) => {
                        return (
                            <>{original?.transformations[0]?.transformationType?.label}</>
                        );
                    },
                },
                {
                    Header: "Requires Data",
                    accessor: (originalRow) => {
                        return originalRow?.transformations[0]?.flags;
                    },
                    Cell: ({ row: { original } }) => {
                        return (
                            <>
                                {original?.transformations[0]?.flags === 1 ? "Required" : "No"}
                            </>
                        );
                    },
                },
            ],
        },
    ];

    function sortTransformations(selectedValues) {
        selectedValues.sort((a, b) => {
            const bArray = b?.transformations?.[0]?.transformerArguments ?? [];
            const bLength = bArray?.length;
            const aArray = a?.transformations?.[0]?.transformerArguments ?? [];
            const aLength = aArray?.length;
            const bLast = bArray[bLength - 1];
            const aLast = aArray[aLength - 1];
            return aLast?.column?.name
                ?.toLowerCase()
                .localeCompare(bLast?.column?.name?.toLowerCase());
        });
        return selectedValues;
    }

    const addRowForOutputSource = () => {
        setSelectedValues((prev) => {
            const values = [...prev];

            const newRow = {
                uuid: uuid(),
                transformations: [
                    {
                        transformationType: {
                            label: "Force Match",
                            value: "STRING_FORMAT",
                        },
                        transformerArguments: [
                            {
                                dataSourceColumnId: null, //external Selected Column ID
                                id: null,
                            },
                            {
                                dataSourceColumnId: -values?.length - 1, //Output column Id
                                id: -values?.length - 1, //Output column Id
                                name: "",
                            },
                        ],
                    },
                ],
            };

            const newValues = [newRow, ...values];

            return newValues;
        });
    };

    const formatFreqDate = [
        { value: "HOURLY", label: ":mm" },
        { value: "DAILY", label: "h:mm a" },
        { value: "WEEKLY", label: "iii, h:mm a" },
        { value: "MONTHLY", label: "Monthly" },
        { value: "NONE", label: "Disable" },
    ];
    const nextScheduledReport = state?.feed?.nextScheduledReport;

    const minuteOptions = [
        { value: "0", label: ":00" },
        { value: "1", label: ":15" },
        { value: "2", label: ":30" },
        { value: "3", label: ":45" },
    ];

    return (
        <div>
            <div style={{ textAlign: "center" }}>
                <div style={{ marginBottom: ".5rem" }}>
                    Name your Transformation, then manage mappings by clicking on the rows
                    below.
                </div>
                <div
                    style={{
                        display: "flex",
                        marginBottom: "1.5rem",
                        justifyContent: "center",
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            marginRight: "1rem",
                        }}
                    >
                        <div
                            style={{
                                color: "green",
                                fontSize: "1rem",
                                marginRight: ".5rem",
                            }}
                        >
                            <MdCircle />
                        </div>
                        <div>Smart Suggestion</div>
                    </div>
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            marginRight: "1rem",
                        }}
                    >
                        <div
                            style={{
                                color: "#009fd4",
                                fontSize: "1rem",
                                marginRight: ".5rem",
                            }}
                        >
                            <MdCircle />
                        </div>
                        <div>Manual</div>
                    </div>
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            marginRight: "1rem",
                        }}
                    >
                        <div
                            style={{
                                color: "#ccc",
                                fontSize: "1rem",
                                marginRight: ".5rem",
                            }}
                        >
                            <MdCircle />
                        </div>
                        <div>Missing</div>
                    </div>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <div
                            style={{
                                color: "red",
                                fontSize: "1rem",
                                marginRight: ".5rem",
                            }}
                        >
                            <MdCircle />
                        </div>
                        <div>Required And/Or Incomplete</div>
                    </div>
                </div>
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
                <div
                    style={{
                        display: "inline-flex",
                        background: "#f9f9f9",
                        marginBottom: "2rem",
                        alignItems: "top",
                        padding: "1rem",
                    }}
                >
                    <div>
                        <h4 style={{ fontSize: "1.2rem" }}>Input Sources</h4>
                        {ingestSources.map((is) => {
                            return <div>{is?.node?.name}</div>;
                        })}
                    </div>

                    <div
                        style={{
                            marginLeft: "2rem",
                            marginRight: "2rem",
                            marginTop: "1.8rem",
                        }}
                    >
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                flexDirection: "row",
                                minWidth: "50px",
                            }}
                        >
                            <div
                                style={{
                                    flex: 1,
                                    height: "1px",
                                    backgroundColor: "#ccc",
                                }}
                            ></div>
                            <div
                                style={{
                                    flex: 0,
                                    width: 0,
                                    height: 0,
                                    borderTop: "5px solid transparent",
                                    borderBottom: "5px solid transparent",
                                    borderLeft: "10px solid #ccc",
                                }}
                            ></div>
                        </div>
                    </div>

                    <div>
                        <h4 style={{ fontSize: "1.2rem" }}>Output Source</h4>
                        {outputSource?.node?.name}
                    </div>
                </div>
            </div>
            <EditName feedName={feedName} setFeedName={setFeedName} />

            {!state?.feed?.activeMonitoring ? (
                feedSchedule ? (
                    <>
                        <div
                            style={{
                                marginTop: "1rem",
                                marginBottom: "1rem",
                            }}
                        >
                            <span style={{ fontWeight: "bold" }}>Scheduled:</span>{" "}
                            {currentFreq?.label}
                            {currentFreq?.value !== "NEVER" && (
                                <>
                                    {" - "}
                                    {nextScheduledReport && !touched ? (
                                        format(
                                            new Date(nextScheduledReport),
                                            formatFreqDate.find(
                                                (fd) => fd.value === currentFreq?.value
                                            )?.label ?? "iii, h:mm a"
                                        )
                                    ) : currentFreq ? (
                                        <>
                                            {feedSchedule?.weekday && `${feedSchedule?.weekday} `}
                                            {feedSchedule?.hour}
                                            {feedSchedule?.minutes
                                                ? minuteOptions.find(
                                                    (mo) =>
                                                        mo.value === feedSchedule?.minutes?.toString()
                                                )?.label
                                                : null}
                                            {feedSchedule?.isAM === undefined ||
                                                feedSchedule?.isAM === null
                                                ? null
                                                : feedSchedule?.isAM
                                                    ? "AM"
                                                    : "PM"}
                                        </>
                                    ) : (
                                        ""
                                    )}
                                </>
                            )}
                            <div>
                                <span style={{ fontWeight: "bold" }}> Holidays: </span>
                                {holidayValue?.label}
                            </div>
                        </div>

                        <Button onClick={() => setConfigureSchedule((prev) => !prev)}>
                            Manage Schedule
                        </Button>
                    </>
                ) : (
                    <Button onClick={() => setConfigureSchedule((prev) => !prev)}>
                        Manage Schedule
                    </Button>
                )
            ) : null}

            {configureSchedule && (
                <Modal
                    title={"Manage Schedule"}
                    hide={() => setConfigureSchedule((prev) => !prev)}
                >
                    <RefreshInfoForm
                        feed={state?.feed}
                        feedSchedule={feedSchedule}
                        setFeedSchedule={setFeedSchedule}
                        setConfigureSchedule={setConfigureSchedule}
                        setTouched={setTouched}
                    />
                </Modal>
            )}

            {state?.ingestSources?.length >= 2 ? (
                <PrimarySourceSelection
                    primarySourceDataSourceId={primarySourceDataSourceId}
                    setPrimarySourceDataSourceId={setPrimarySourceDataSourceId}
                    inputSources={state?.ingestSources ?? []}
                />
            ) : null}
            <hr />
            {isEditing ? (
                <TransformEdit toggle={() => null}>
                    <MappingNew
                        collapse={() => setIsEditing(null)}
                        editingTransfer={isEditing?.original}
                        ingestColumnOptions={ingestColumnOptions}
                        setSelectedValues={setSelectedValues}
                        sourceColumnOptions={sourceColumnOptions}
                        inputDataSourceIds={
                            state?.ingestSources?.map((isource) => isource?.id) ?? []
                        }
                        outputDataSourceId={state?.selectedSourceId}
                        selectedValues={selectedValues}
                        isGenerated={
                            state?.generatedOutputSource?.name ||
                            state?.feed?.destinationDataSource?.tagInstances?.some(
                                (tagInstance) => tagInstance.tagId === -7
                            )
                        }
                    />
                </TransformEdit>
            ) : null}
            <SortExpandTableV2
                // dontReset={true}
                data={sortTransformations(selectedValues)}
                columns={columnsData}
                defaultPageSize={50}
                defaultSort={[]}
                setIsEditing={setIsEditing}
            />
            {(state?.generatedOutputSource?.name ||
                state?.feed?.destinationDataSource?.tagInstances?.some(
                    (tagInstance) => tagInstance.tagId === -7
                )) && (
                    <>
                        <Button type="button" onClick={() => addRowForOutputSource()}>
                            Add Output Column
                        </Button>
                    </>
                )}

            <FeedTags
                dispatch={dispatch}
                stateStep={state.step}
                feedId={state.feedId}
            />

            <Button list onClick={() => dispatch({ type: "GO_BACK", payload: 7 })}>
                back
            </Button>
            <Button
                onClick={() =>
                    dispatch({
                        type: "START_SUBMISSION",
                        payload: {
                            destinationDataSourceId: sourceId,
                            feed: selectedValues,
                            name: feedName,
                            primarySourceDataSourceId: primarySourceDataSourceId,
                            refreshInfo: touched ? feedSchedule : null,
                        },
                    })
                }
            >
                Save & Continue
            </Button>
        </div>
    );
};

const FeedMapping = ({ sourceId, state, dispatch }) => {
    const ingestSourceIds = state?.ingestSources.map((is) => is?.id) ?? [];

    const [{ loading: loadingMatches, data: columnMatches }] = useApi(
        getMatchingColumns,
        {
            model: {
                bestMatchOnly: true,
                dataSourceId: sourceId,
                dataSourceIds: [...ingestSourceIds],
            },
        }
    );

    const [{ loading: dataSourceColumnsLoading, data: dataSourceColumns }] =
        useApi(dataSourcesByIdColumns, {
            where: sourceId
                ? { id: { in: [sourceId, ...ingestSourceIds] } }
                : { id: { in: [...ingestSourceIds] } },
        });

    const outputSource = dataSourceColumns?.availableDataSources?.edges?.find(
        (s) => s?.node?.id === sourceId
    );

    const isGeneratedOutput = outputSource?.node?.tagInstances?.some(
        (tagInstance) => tagInstance.tagId === -7
    );

    const mappingsSources = dataSourceColumns?.availableDataSources?.edges ?? [];

    const sourceColumns =
        mappingsSources.find((ms) => ms?.node?.id === sourceId)?.node?.columns ??
        [];

    const sourceColumnOptions =
        sourceColumns
            .filter((c) => c?.enabled)
            .map((c) => {
                return { ...c, value: c.id, label: c.name, column: { ...c } };
            }) ?? [];

    const ingestSources = mappingsSources.filter(
        (ms) => ms?.node?.id !== sourceId
    );

    let ingestColumns = [];

    ingestSources.forEach((is) => {
        is?.node?.columns.forEach((c) => {
            ingestColumns.push(c);
        });
    });

    const ingestColumnOptions = ingestColumns.map((c) => {
        return { ...c, value: c.id, label: c.name, column: { ...c } };
    });

    const finalSuggestions = state?.feedId
        ? []
        : columnMatches?.matchingColumns?.matchingColumns ?? [];

    if (dataSourceColumnsLoading || loadingMatches)
        return <SplashLoader text={"Loading Mapping"} />;

    return (
        <>
            {ingestColumns?.length ? (
                <SmartMapping
                    state={state}
                    sourceId={sourceId}
                    ingestColumns={ingestColumns}
                    ingestColumnOptions={ingestColumnOptions}
                    sourceColumnOptions={sourceColumnOptions}
                    finalSuggestions={finalSuggestions}
                    dispatch={dispatch}
                    outputSource={outputSource}
                    ingestSources={ingestSources}
                    isGeneratedOutput={isGeneratedOutput}
                />
            ) : !ingestColumns?.length ? ( // removed sourceId to allow manually adding
                <div>Select Output and Input to begin.</div>
            ) : (
                "Loading"
            )}
        </>
    );
};

const FeedMappingContainer = ({ state, dispatch }) => {
    // Main Source selected or Buyer Source
    const sourceId = state?.selectedSourceId;

    const [{ loading, errors, data }, getClone] = useApi();

    useEffect(() => {
        if (data && !state?.cloneSuggested?.feed) {
            dispatch({
                type: "SET_CLONE_DATA",
                payload: data?.cloneServicerTransferFeed,
            });
        }
    }, [data, dispatch, state?.cloneSuggested?.feed]);

    useEffect(() => {
        if (state?.isClone && !state?.cloneSuggested?.feed) {
            getClone({
                query: cloneServicerTransferFeed,
                variables: {
                    cloneModel: {
                        destinationDataSourceId: sourceId,
                        feedId: state?.feedId,
                        sourceDataSourceIds: state?.ingestSources.map((is) => is?.id) ?? [],
                    },
                },
            });
        }
    }, [
        getClone,
        sourceId,
        state?.cloneSuggested?.feed,
        state?.isClone,
        state?.feedId,
        state?.ingestSources,
    ]);

    if (loading) return <div>Cloning Mapping</div>;
    if (errors?.length)
        return (
            <div>
                <ErrorMessages errors={errors} />
            </div>
        );
    if (state?.isClone && !state?.cloneSuggested?.feed) {
        return null;
    }

    return (
        <>
            <FeedMapping sourceId={sourceId} state={state} dispatch={dispatch} />
        </>
    );
};

export default FeedMappingContainer;
