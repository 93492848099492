import React from "react";
import styled from "styled-components/macro";
import { PieChart, Pie, Cell } from "recharts";

const QualityScoreLabel = styled.div`
  position: absolute;
  width: 100%;
  text-align: center;
  top: ${(props) => (props.hasLabel ? "calc(50% - 35px)" : "calc(50% - 25px)")};
  animation: label 1.5s ease-in;
`;

const Score = styled.div`
  font-size: ${(props) => (props.hasLabel ? "2.3rem" : "2.8rem")};
  margin-bottom: 0.1em;
  color: ${(props) => props.theme.onSurface};
`;

const DataQualityScore = React.memo(({ data, failureCount, dataPoint }) => {
  if (!data) return null;

  let piePercentage;
  let pieStat;
  let pieLabel;
  let fillColor;

  if (dataPoint === "nullFailures") {
    piePercentage = data?.nullValueFailurePercentage;
    pieStat = data?.nullFailureCount;
    pieLabel = "Null Value";
  } else if (dataPoint === "incorrectValues") {
    piePercentage = data?.incorrectValueFailurePercentage;
    pieStat = data?.incorrectValueFailureCount;
    pieLabel = "Incorrect Value";
  } else if (dataPoint === "noMatchingRows") {
    piePercentage = data?.noMatchingRowFailurePercentage;
    pieStat = data?.noMatchingRowFailureCount;
    pieLabel = "No Matching Rows";
  } else if (dataPoint === "allFailures") {
    piePercentage = (
      (data?.failureCount / failureCount?.failuresCount) *
      100
    ).toFixed(0);

    pieStat = piePercentage;
    pieLabel = "";
  }

  switch (data?.failedBusinessRuleInstancePriority) {
    case "HIGH":
      fillColor = "#008CF6";
      break;
    case "NORMAL":
      fillColor = "#6ABCFA";
      break;
    case "LOW":
      fillColor = "#A9D8FC";
      break;
    default:
      fillColor = "#008CF6";
      break;
  }

  const pieData = [
    { name: "Quality Score", value: Number(piePercentage) },
    { name: "", value: 100 - Number(piePercentage) },
  ];

  return (
    <div>
      <div style={{ position: "relative" }}>
        <PieChart width={150} height={150}>
          <Pie data={pieData} innerRadius={60} outerRadius={70} dataKey="value">
            <Cell key={`cell-0`} fill={fillColor} stroke={0} />
            <Cell key={`cell-1`} fill={"#D0DFEA"} stroke={0} />
          </Pie>
        </PieChart>

        <QualityScoreLabel hasLabel={pieLabel}>
          <Score hasLabel={pieLabel}>
            {pieStat}
            {pieLabel ? null : "%"}
          </Score>
          <div
            style={{
              fontSize: 0.8 + "em",
              marginBottom: 0.1 + "em",
              color: "#555555",
            }}
          >
            {pieLabel}
          </div>
        </QualityScoreLabel>
      </div>
    </div>
  );
});

export default DataQualityScore;
