import React, { useEffect, useReducer, useState } from "react";
import { Formik, Form } from "formik";
import {
  FormControl,
  StyledField,
  FormActions,
  ErrorMessage,
} from "../../Form/FormControls";
import Button from "../../Button";
import SplashLoader from "../../Loaders/SplashLoader";
import ErrorMessages from "../../Notifications/ErrorMessages";
import { useApi } from "../../../api/useApi";
import styled from "styled-components/macro";
import { createEtlProvider } from "../../../api/etlProviderMutations";
import useETLProviderCreateNotification from "../../../Hooks/useETLProviderCreateNotification";
import useETLProviderUpdateNotification from "../../../Hooks/useETLProviderUpdateNotification";
import {
  updateEtlProviderEnabledFlag,
  updateEtlProvider,
} from "../../../api/etlProviderMutations";
import Modal from "../../../components/Modal";
import Spinner from "../../../components/Loaders/Spinner";
import * as Yup from "yup";

const ProviderLoading = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 3;
  display: flex;
  background: rgba(255, 255, 255, 0.7);
`;

const ETLFormComponent = ({ etlProviderData, fetchLatestETLProvider }) => {
  const { etlProviderCreateCompleted, setEtlProviderCreateCompleted } =
    useETLProviderCreateNotification();

  const { etlProviderUpdateCompleted, setEtlProviderUpdateCompleted } =
    useETLProviderUpdateNotification();

  const etlProviderId = etlProviderData?.id ?? null;
  const etlProviderName = etlProviderData?.displayName ?? null;

  const [showConfirm, setShowConfirm] = useState(false);

  // Intial reducer state
  const initialState = {
    createError: "",
    creatingProvider: false,
    created: false,
  };

  // Init the create API state
  const [{ errors: creatProviderErrors }, createProvider] = useApi();

  // Init the update API state
  const [{ errors: updateProviderErrors }, updateProvider] = useApi();

  const [
    {
      loading: removeProviderLoading,
      errors: removeProviderErrors,
      data: removeProviderData,
    },
    removeProvider,
  ] = useApi();

  //Reducer States for this component, Details what part of the state updates pending dispatch
  const reducer = (state, action) => {
    switch (action.type) {
      //First State called when a query is passed to the component.
      default:
        return {
          ...state,
          createError: "",
        };

      // Connection created
      case "PROVIDED_CREATED": {
        return {
          ...state,
          created: true,
          creatingProvider: false,
          createError: "",
        };
      }

      // Connection failed to initialize test
      case "FAILED_TEST": {
        return {
          ...state,
          creatingProvider: false,
        };
      }

      // CREATE An error state in the UI and clear creating state
      case "CREATE_ERROR": {
        setEtlProviderCreateCompleted(null);
        setEtlProviderUpdateCompleted(null);
        return {
          ...state,
          createError: action.payload,
          creatingProvider: false,
        };
      }

      // Edit ETL Provider Connection Info
      case "EDIT": {
        const values = action.payload.values;

        updateProvider({
          query: updateEtlProvider,
          variables: {
            etlProvider: {
              etlProviderId: etlProviderId,
              updateConnectionInputModel: {
                dataFactoryUpdateConnectionInputModel: {
                  applicationId: values.applicationId,
                  authenticationKey: values.authenticationKey,
                },
              },
            },
          },
        });

        return {
          ...state,
          creatingProvider: true,
          createError: "",
        };
      }

      case "REMOVE": {
        const variables = { id: etlProviderId, newEnabledState: false };
        removeProvider({
          query: updateEtlProviderEnabledFlag,
          variables: variables,
        });
        return {
          ...state,
          createError: "",
        };
      }

      //Create ETL Connection
      case "SUBMIT": {
        const values = action.payload.values;

        createProvider({
          query: createEtlProvider,
          variables: {
            provider: {
              name: values.name,
              type: "DATA_FACTORY",
              connectionInputModel: {
                dataFactoryExplorerConnection: {
                  tentantId: values.tentantId,
                  subscriptionId: values.subscriptionId,
                  applicationId: values.applicationId,
                  authenticationKey: values.authenticationKey,
                },
              },
            },
          },
        });

        return {
          ...state,
          creatingProvider: true,
        };
      }
    }
  };

  //initialize the reducer state
  const [state, dispatch] = useReducer(reducer, initialState);

  // IF the initial create fails, throw an error in the UI and stop the test
  useEffect(() => {
    if (creatProviderErrors) dispatch({ type: "FAILED_TEST" });
  }, [creatProviderErrors, dispatch]);

  useEffect(() => {
    if (removeProviderData?.updateEtlProviderEnabledFlag?.id) {
      fetchLatestETLProvider();
    }
  }, [removeProviderData, fetchLatestETLProvider]);

  useEffect(() => {
    if (etlProviderCreateCompleted) {
      const { SourceId, ErrorMessage } = etlProviderCreateCompleted.payload;

      if (SourceId) {
        dispatch({
          type: "PROVIDED_CREATED",
        });

        // Tell parent component to get latest provider
        fetchLatestETLProvider();

        // Clear out the context state
        setEtlProviderCreateCompleted(null);
      } else {
        dispatch({
          type: "CREATE_ERROR",
          payload: ErrorMessage ?? "",
        });
      }
    }
  }, [
    etlProviderCreateCompleted,
    dispatch,
    setEtlProviderCreateCompleted,
    fetchLatestETLProvider,
  ]);

  useEffect(() => {
    if (etlProviderUpdateCompleted) {
      const { SourceId, ErrorMessage } = etlProviderUpdateCompleted.payload;

      if (SourceId) {
        dispatch({
          type: "PROVIDED_CREATED",
        });

        // Tell parent component to get latest provider
        fetchLatestETLProvider();

        // Clear out the context state
        setEtlProviderUpdateCompleted(null);
      } else {
        dispatch({
          type: "CREATE_ERROR",
          payload: ErrorMessage ?? "",
        });
      }
    }
  }, [
    etlProviderUpdateCompleted,
    dispatch,
    setEtlProviderUpdateCompleted,
    fetchLatestETLProvider,
  ]);

  // Initial formik values
  const initialValues = {
    name: etlProviderName ?? "",
    type: "DATA_FACTORY",
    tentantId: "",
    subscriptionId: "",
    applicationId: "",
    authenticationKey: "",
    isEditting: etlProviderId != null,
  };

  return (
    <>
      {(state.creatingProvider || removeProviderLoading) && (
        <ProviderLoading>
          <SplashLoader text={"Testing Connection"} />
        </ProviderLoading>
      )}

      {showConfirm ? (
        <Modal
          title={`Confirm Provider Removal`}
          hide={() => setShowConfirm(false)}
        >
          <p>Are you sure you wish to remove this provider?</p>
          <div>
            <Button
              type="button"
              list
              disabled={removeProviderLoading}
              danger
              onClick={() => {
                setShowConfirm(false);
                dispatch({
                  type: "REMOVE",
                  payload: null,
                });
              }}
            >
              {removeProviderLoading ? <Spinner /> : "Yes"}
            </Button>
            <Button
              type="button"
              disabled={removeProviderLoading}
              onClick={() => {
                setShowConfirm(false);
              }}
            >
              Cancel
            </Button>
          </div>
        </Modal>
      ) : null}

      <Formik
        enableReinitialize={true}
        validateOnMount={true}
        initialValues={{
          ...initialValues,
        }}
        validationSchema={Yup.object().shape({
          name: Yup.string().when("isEditting", {
            is: false,
            then: Yup.string().required("Required"),
          }),
          tentantId: Yup.string().when("isEditting", {
            is: false,
            then: Yup.string().required("Required"),
          }),
          subscriptionId: Yup.string().when("isEditting", {
            is: false,
            then: Yup.string().required("Required"),
          }),
          applicationId: Yup.string().required("Required"),
          authenticationKey: Yup.string().required("Required"),
        })}
      >
        {(form) => {
          return (
            <Form>
              <h3>
                {!etlProviderName ? "Create ETL Provider" : "Edit ETL Provider"}
              </h3>
              {
                <>
                  {!etlProviderName && (
                    <FormControl>
                      <StyledField
                        name={`name`}
                        type="text"
                        placeholder="Provider Name"
                        label="Name"
                      />
                      <ErrorMessage name={`name`} />
                    </FormControl>
                  )}

                  {!etlProviderName && (
                    <FormControl>
                      <StyledField
                        name={`tentantId`}
                        type="text"
                        placeholder="Tentant Id"
                        label="Tentant Id"
                      />
                      <ErrorMessage name={`tentantId`} />
                    </FormControl>
                  )}

                  {!etlProviderName && (
                    <FormControl>
                      <StyledField
                        name={`subscriptionId`}
                        type="text"
                        placeholder="Subscription Id"
                        label="Subscription Id"
                      />
                      <ErrorMessage name={`subscriptionId`} />
                    </FormControl>
                  )}

                  <FormControl>
                    <StyledField
                      name={`applicationId`}
                      type="text"
                      placeholder="Application Id"
                      label="ApplicationId Id"
                    />
                    <ErrorMessage name={`applicationId`} />
                  </FormControl>

                  <FormControl>
                    <StyledField
                      name={`authenticationKey`}
                      type="password"
                      placeholder="Application Secret"
                      label="Application Secret"
                    />
                    <ErrorMessage name={`authenticationKey`} />
                  </FormControl>
                </>
              }

              <FormActions>
                <FormControl>
                  {creatProviderErrors ? (
                    <ErrorMessages errors={creatProviderErrors} />
                  ) : null}
                  {updateProviderErrors ? (
                    <ErrorMessages errors={updateProviderErrors} />
                  ) : null}
                  {removeProviderErrors ? (
                    <ErrorMessages errors={removeProviderErrors} />
                  ) : null}
                  {state.createError ? (
                    <ErrorMessages errors={[{ message: state.createError }]} />
                  ) : null}

                  {etlProviderName && (
                    <Button
                      list="true"
                      danger="true"
                      type="button"
                      onClick={() => setShowConfirm(true)}
                    >
                      Remove
                    </Button>
                  )}

                  {!etlProviderName ? (
                    <Button
                      type="button"
                      disabled={!form.isValid}
                      onClick={() =>
                        dispatch({
                          type: "SUBMIT",
                          payload: {
                            values: form.values,
                          },
                        })
                      }
                    >
                      Submit
                    </Button>
                  ) : (
                    <Button
                      type="button"
                      disabled={!form.isValid}
                      onClick={() =>
                        dispatch({
                          type: "EDIT",
                          payload: {
                            values: form.values,
                          },
                        })
                      }
                    >
                      Update
                    </Button>
                  )}
                </FormControl>
              </FormActions>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default ETLFormComponent;
