import { useContext } from "react";
import { SocketContext } from "../contexts/useSubscriptions";

const UseFeedNotifications = () => {
  const { feedExportNotification, setFeedExportNotification } =
    useContext(SocketContext);

  return { feedExportNotification, setFeedExportNotification };
};
export default UseFeedNotifications;
