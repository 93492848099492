import React from "react";
import { useTable } from "react-table";
import { MdNavigateNext, MdNavigateBefore } from "react-icons/md";
import {
  TableElement,
  Td,
  Th,
  ThSticky,
  Tr,
  PageButton,
  TableLoading,
  Thead,
  TBody,
} from "./elements";
import { usePageCursorRedux } from "./plugins/usePageCursorRedux";
import SplashLoader from "../Loaders/SplashLoader";

function Table({
  columns,
  data,
  fetchData,
  pageInfo,
  loading,
  totalCount,
  defaultPageSize,
  maxSize,
  endCursor,
  maxHeight,
  noMargin,
  initParams,
}) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page
    //Page information
    canPreviousPage,
    canNextPage,
    pageOptions,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize, cursor },
  } = useTable(
    {
      columns,
      data,
      disableSortRemove: true,
      initialState: {
        pageIndex: 0,
        pageSize: defaultPageSize,
        cursor: initParams,
        initParams: initParams,
      }, // Pass our hoisted table state
      manualPagination: true, // Tell the usePagination
      // hook that we'll handle our own data fetching
      // This means we'll also have to provide our own
      // pageCount.
      totalCount: totalCount,
      pageInfo: pageInfo,
      endCursor: endCursor,
    },
    usePageCursorRedux
  );

  // Now we can get our table state from the hoisted table state tuple
  // Listen for changes in pagination and use the state to fetch our new data

  React.useEffect(() => {
    fetchData({
      pageSize,
      params: cursor,
    });
  }, [fetchData, pageIndex, pageSize, cursor, initParams]);

  const max = maxSize ? [maxSize] : [10, 50, 100, 150, 200];

  return (
    <div>
      <div
        style={{ position: "relative", maxHeight: "70vh", overflow: "auto" }}
      >
        <TableElement
          noMargin={noMargin}
          className="tableFixHead"
          {...getTableProps()}
        >
          <Thead className="thead">
            {headerGroups.map((headerGroup, i) => (
              <Tr key={i + `headerView`} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => {
                  const isActions = column?.id === "actions";
                  const ColumnSelector = isActions ? ThSticky : Th;
                  return (
                    // Add the sorting props to control sorting. For this example
                    // we can add them into the header props
                    <ColumnSelector
                      className="th"
                      {...column.getHeaderProps()}
                      right={isActions ? `0` : null}
                    >
                      {/* Sort With Indicator */}
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <div style={{ marginRight: "auto" }}>
                          {column.render("Header")}
                        </div>
                      </div>
                    </ColumnSelector>
                  );
                })}
              </Tr>
            ))}
          </Thead>
          <TBody {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <Tr key={i + `tableView`} {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    const isActions = cell.column?.id === "actions";
                    return (
                      <Td
                        key={i + `rowView`}
                        {...cell.getCellProps()}
                        right={isActions ? `0` : null}
                        isOdd={i % 2 !== 1}
                      >
                        {cell.render("Cell")}
                      </Td>
                    );
                  })}
                </Tr>
              );
            })}
          </TBody>
        </TableElement>
        {loading ? (
          <TableLoading>
            <SplashLoader text="Loading Results" />
          </TableLoading>
        ) : null}
      </div>
      <div style={{ display: "flex", alignItems: "center" }}>
        <>{!loading && page && !page.length ? <div>No Results</div> : null}</>
        <div style={{ marginLeft: "auto", marginRight: "1rem" }}>
          <span>
            Page{" "}
            <strong>
              {pageIndex + 1} of {pageOptions.length}
            </strong>{" "}
          </span>
        </div>
        <div style={{ marginRight: "1rem" }}>
          <select
            value={pageSize}
            onChange={(e) => {
              setPageSize(Number(e.target.value));
            }}
          >
            {max.map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </select>
        </div>
        <div style={{ marginRight: "1rem" }}>
          {/* <PageButton onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
              <MdFirstPage />
            </PageButton>{" "} */}
          <PageButton
            type="button"
            onClick={() => previousPage()}
            disabled={!canPreviousPage || loading}
          >
            <MdNavigateBefore />
          </PageButton>{" "}
          <PageButton
            type="button"
            onClick={() => nextPage()}
            disabled={!canNextPage || loading}
          >
            <MdNavigateNext />
          </PageButton>{" "}
          {/* <PageButton
              onClick={() => gotoPage(pageCount - 1)}
              disabled={!canNextPage}
            >
              <MdLastPage />
            </PageButton> */}
        </div>
      </div>
    </div>
  );
}

const PagedTableRedux = ({
  fetchData,
  pageInfo,
  data = [],
  loading,
  columns,
  totalCount,
  defaultPageSize = 10,
  maxSize,
  endCursor,
  maxHeight,
  noMargin,
  initParams,
}) => {
  return (
    <>
      <Table
        columns={columns}
        endCursor={endCursor}
        data={data}
        totalCount={totalCount}
        pageInfo={pageInfo}
        fetchData={fetchData}
        loading={loading}
        defaultPageSize={defaultPageSize}
        maxSize={maxSize}
        maxHeight={maxHeight}
        noMargin={noMargin}
        initParams={initParams}
      />
    </>
  );
};

export default PagedTableRedux;
