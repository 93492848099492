import React, { useState } from "react";
import Button from "../../../Button";
import { FormControl, StyledInput } from "../../../Form/FormControls";

const AddPipelineForm = ({ dispatch, setInstanceNames, data }) => {
  const [currentName, setCurrentName] = useState("");

  return (
    <>
      <FormControl>
        <StyledInput
          type="text"
          name="PipelineName"
          label="Pipeline Name"
          required={true}
          value={currentName}
          placeholder={`Name`}
          onChange={(e) => setCurrentName(e.target.value)}
        />
      </FormControl>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Button
          onClick={() => {
            setInstanceNames((prevState) => [
              ...prevState,
              { label: currentName, value: currentName },
            ]);
            dispatch({
              type: "SET_NEW_ETL",
              payload: {
                segment: data?.segment,
                newPipelineName: { label: currentName, value: currentName },
              },
            });
          }}
        >
          Add Pipeline
        </Button>
      </div>
    </>
  );
};

export default AddPipelineForm;
