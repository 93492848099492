import { format } from "date-fns";

export function formatTime(time) {
  return format(time, "MMM d yyyy, h:mm aaaa");
}

export function formatMinutes(time) {
  return format(time, "h:mm aaaa");
}

export function simplifiedDate(time) {
  return format(time, "MM-dd-yyyy");
}
