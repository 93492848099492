import { Field, ErrorMessage as Error } from "formik";
import React, {
  Component,
  useContext,
  useState,
  useCallback,
  useEffect,
  useRef,
  forwardRef,
} from "react";
import styled, { ThemeContext, keyframes } from "styled-components/macro";
import Select from "react-select";

// const StyledSelectComponent = styled(Select)`
//   .react-select__control {
//     align-items: center;
//     display: inline-flex;
//     font-size: 1rem;
//     justify-content: flex-start;
//     line-height: 1.5;
//     position: relative;
//     vertical-align: top;
//     background-color: ${(props) => props.theme.surface};
//     color: ${(props) => props.theme.onSurface};
//     max-width: 100%;
//     width: 100%;
//     border-width: 1px;
//     border-style: solid;
//     border-image: initial;
//     border-radius: 4px;
//     box-shadow: none;
//     border-color: ${(props) => props.theme.fieldBorder};
//     &:focus {
//       border-color: ${(props) => props.theme.onSurface};
//       outline: none;
//     }
//     &:hover {
//       border-color: ${(props) => props.theme.onSurface};
//       outline: none;
//     }
//   }
//   .react-select__clear-indicator {
//     display: none;
//   }
//   .react-select__value-container {
//     color: ${(props) => props.theme.onSurface};
//     &:focus {
//       border-color: ${(props) => props.theme.onSurface};
//       outline: none;
//     }
//     .react-select__input {
//       color: ${(props) => props.theme.onSurface};
//     }
//   }

//   .react-select__indicators {
//   }

//   .react-select__menu {
//     background-color: ${(props) => props.theme.secondarySurface};
//     color: ${(props) => props.theme.onSurface};
//     box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
//   }

//   .react-select__menu-list {
//   }

//   .react-select__option {
//   }

//   .react-select__option--is-focused {
//     background-color: ${(props) => props.theme.onSecondarySurface};
//   }

//   .react-select__option--is-selected {
//     background-color: ${(props) => props.theme.thirdSurface};
//   }

//   .react-select__option--is-active {
//     background-color: ${(props) => props.theme.onSecondarySurface};
//   }

//   .react-select__option:active {
//     background-color: ${(props) => props.theme.onSecondarySurface};
//   }

//   .react-select__option--is-disabled {
//     opacity: 0.5;
//   }

//   .react-select__single-value {
//     color: ${(props) => props.theme.onSurface};
//   }
// `;

export function StyledSelect(props) {
  const themeContext = useContext(ThemeContext);

  const customMenuTheme = {
    border: `1px solid ${themeContext.fieldBorder}`,
    backgroundColor: themeContext.surface,
    color: themeContext.onSurface,
    boxShadow: "none",
    zIndex: 2,
  };

  return (
    <Select
      className={`react-select-container`}
      classNamePrefix={`react-select`}
      styles={{
        menu: (base) => ({
          ...base,
          ...customMenuTheme,
        }),
        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
        option: (base, state) => ({
          ...base,
          opacity: state.isDisabled ? 0.5 : 1,
          backgroundColor: state.isActive
            ? themeContext.onSecondarySurface
            : state.isFocused
            ? themeContext.onSecondarySurface
            : state.isSelected
            ? themeContext.thirdSurface
            : "transparent",
        }),
        control: (provided) => ({
          ...provided,
          minWidth: props?.minWidth ? props.minWidth : 300,
        }),
      }}
      {...props}
    />
  );
}

const FieldWrapper = styled(Field)`
  align-items: center;
  display: inline-flex;
  font-size: 1rem;
  height: 2.25em;
  justify-content: flex-start;
  line-height: 1.5;
  padding-bottom: calc(0.375em - 1px);
  padding-left: calc(0.625em - 1px);
  padding-right: calc(0.625em - 1px);
  padding-top: calc(0.375em - 1px);
  position: relative;
  vertical-align: top;
  background-color: ${(props) => props.theme.surface};
  color: ${(props) => props.theme.onSurface};
  max-width: 100%;
  width: 100%;
  border-width: 1px;
  border-style: solid;
  border-image: initial;
  border-radius: 4px;
  border-color: ${(props) => props.theme.fieldBorder};
  &:focus {
    border-color: ${(props) => props.theme.onSurface};
    outline: none;
  }
`;

const InlineFieldWrapper = styled(Field)`
  align-items: center;
  display: flex;
  height: 2.25em;
  flex: 1;
  justify-content: flex-start;
  line-height: 1.5;
  padding-bottom: calc(0.375em - 1px);
  padding-left: calc(0.625em - 1px);
  padding-right: calc(0.625em - 1px);
  padding-top: calc(0.375em - 1px);
  position: relative;
  vertical-align: top;
  background-color: ${(props) => props.theme.secondarySurface};
  color: ${(props) => props.theme.onSurface};
  border-width: 1px;
  border-style: solid;
  border-image: initial;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  border-color: ${(props) => props.theme.fieldBorder};
  &:focus {
    border-color: ${(props) => props.theme.onSurface};
    outline: none;
  }
`;

const Label = styled.label`
  margin-bottom: 0.5em;
  margin-left: 0.5em;
  display: block;
  font-size: 0.8em;
  font-family: "Source Sans Pro Semibold";
`;

const InlineButton = styled.button`
  background: #21204b;
  color: #fefefe;
  cursor: pointer;
  width: auto;
  margin-right: 0;
  height: 30px;
  padding-right: 0.8rem;
  padding-left: 0.8rem;
  border: none;
  transition: all 150ms linear;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  position: relative;
`;

const rotate360 = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

const Rotate = styled.div`
  &:before {
    content: "";
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 18px;
    height: 18px;
    margin-top: -9px;
    margin-left: -9px;
    border-radius: 50%;
    border: 2px solid transparent;
    border-top-color: ${(props) => props.theme.onSecondarySurface};
    border-bottom-color: ${(props) => props.theme.onSecondarySurface};
    animation: ${rotate360} 0.8s ease infinite;
  }
`;

class InlineFormField extends Component {
  render() {
    return (
      <>
        <InlineFieldWrapper {...this.props} />
        <InlineButton type="submit" disabled={this.props.loading}>
          {this.props.loading ? <Rotate /> : this.props.submitText}
        </InlineButton>
      </>
    );
  }
}

class StyledField extends Component {
  render() {
    return (
      <>
        {this.props.label ? <Label>{this.props.label}</Label> : null}
        <FieldWrapper {...this.props} />
      </>
    );
  }
}

const InputWrapper = styled.input`
  align-items: center;
  display: inline-flex;
  font-size: 1rem;
  height: 2.25em;
  justify-content: flex-start;
  line-height: 1.5;
  padding-bottom: calc(0.375em - 1px);
  padding-left: calc(0.625em - 1px);
  padding-right: calc(0.625em - 1px);
  padding-top: calc(0.375em - 1px);
  position: relative;
  vertical-align: top;
  background-color: ${(props) => props.theme.surface};
  color: ${(props) => props.theme.onSurface};
  max-width: 100%;
  width: 100%;
  border-width: 1px;
  border-style: solid;
  border-image: initial;
  border-radius: 4px;
  border-color: ${(props) => props.theme.fieldBorder};
  &:focus {
    border-color: ${(props) => props.theme.onSurface};
    outline: none;
  }
`;

const StyledInput = forwardRef(({ label, ...props }, ref) => {
  return (
    <div>
      {label ? <Label>{label}</Label> : null}
      <InputWrapper ref={ref} {...props} />
    </div>
  );
});

const TextWrapper = styled.textarea`
  align-items: center;
  display: inline-flex;
  font-size: 1rem;

  justify-content: flex-start;
  line-height: 1.5;
  padding-bottom: calc(0.375em - 1px);
  padding-left: calc(0.625em - 1px);
  padding-right: calc(0.625em - 1px);
  padding-top: calc(0.375em - 1px);
  position: relative;
  vertical-align: top;
  background-color: ${(props) =>
    props.lightBackground ? props.theme.surface : props.theme.secondarySurface};
  color: ${(props) => props.theme.onSurface};
  max-width: 100%;
  width: 100%;
  border-width: 1px;
  border-style: solid;
  border-image: initial;
  border-radius: 4px;
  border-color: ${(props) => props.theme.fieldBorder};
  &:focus {
    border-color: ${(props) => props.theme.onSurface};
    outline: none;
  }
  min-height: 100px;
  resize: none;
`;

const StyledTextArea = ({ onChange, rows, ...props }) => {
  const minRows = 1;
  const maxRows = 8;
  const [usedRows, setUsedRows] = useState(rows || 1);

  const node = useRef(null);

  function getRows(target) {
    if (!target) return 1;
    const textareaLineHeight = 24;
    const previousRows = target.rows;
    //Reset Number of Rows in TextArea
    target.rows = minRows;

    const currentRows = ~~(target.scrollHeight / textareaLineHeight);

    if (currentRows === previousRows) {
      target.rows = currentRows;
    }

    if (currentRows >= maxRows) {
      target.rows = maxRows;
      target.scrollTop = target.scrollHeight;
    }

    return currentRows < maxRows ? currentRows : maxRows;
  }

  const handleChange = useCallback(
    (e) => {
      setUsedRows(getRows(e.target));
      onChange(e.target.value);
    },
    [onChange]
  );

  useEffect(() => {
    if (node && node.current) setUsedRows(getRows(node.current));
  }, [node]);

  return (
    <div>
      {props.label ? (
        <Label>
          {props.label}
          {props?.req ? (
            <span style={{ color: "red", fontSize: "0.8125rem" }}>*</span>
          ) : null}
        </Label>
      ) : null}
      <TextWrapper
        ref={node}
        rows={usedRows}
        onChange={handleChange}
        {...props}
      />
    </div>
  );
};

const Control = styled.div`
  margin-bottom: 1rem;
  display: ${(props) => (props.flex ? "flex" : "block")};
  ${FieldWrapper} {
    margin-right: ${(props) => (props.flex ? "1em" : "inherit")};
  }
`;

class FormControl extends Component {
  render() {
    return <Control {...this.props} />;
  }
}

const ErrorWrap = styled.div`
  color: ${(props) => props.theme.danger};
  margin-bottom: 15px;
  margin-top: 15px;
  margin-left: 10px;
`;

class FieldError extends Component {
  render() {
    return <ErrorWrap {...this.props} />;
  }
}

const Actions = styled.div`
  text-align: right;
  margin-top: 1.5rem;
`;

class FormActions extends Component {
  render() {
    return <Actions {...this.props} />;
  }
}

const ServerErrorContainer = styled.div`
  text-align: right;
  color: ${(props) => props.theme.surfaceAlt};
  margin-bottom: 15px;
  margin-top: 15px;
  margin-left: 10px;
`;

class ServerError extends Component {
  render() {
    return <ServerErrorContainer>{this.props.msg}</ServerErrorContainer>;
  }
}

const ErrorMessage = ({ name }) => (
  <FieldError>
    <Error name={name} />
  </FieldError>
);

export {
  StyledInput,
  StyledField,
  FormControl,
  FieldError,
  InlineFormField,
  FormActions,
  ServerError,
  ErrorMessage,
  Label,
  StyledTextArea,
};
