import React from "react";
import { BusinessRuleOperations } from "../Forms/Rules/buildingBlocks";

const RenderOperation = ({ operationDetails, isView }) => {
  if (isView) {
    const slicedArray = operationDetails?.typeInformation?.slice(2);

    const not = slicedArray.find(
      (slice) => slice?.typeHierarchyLevel === 2 && slice?.typeValue === 1
    );
    const fuzzy = slicedArray.find(
      (slice) => slice?.typeHierarchyLevel === 3 && slice?.typeValue === 2
    );
    const caseSen = slicedArray.find(
      (slice) => slice?.typeHierarchyLevel === 4 && slice?.typeValue === 3
    );
    const charMatch = slicedArray.find(
      (slice) => slice?.typeHierarchyLevel === 7 && slice?.typeValue === 6
    );

    if (fuzzy && not) {
      //NOT AND FUZZY
      return (
        <>
          <div
            style={{ fontSize: "1.1rem", marginRight: ".4rem", color: "red" }}
          >
            !
          </div>
          <div>{"\u2248"}</div>
        </>
      );
    } else if (caseSen && not) {
      //NOT AND FUZZY
      return (
        <>
          <div
            style={{ fontSize: "1.1rem", marginRight: ".4rem", color: "red" }}
          >
            !*
          </div>
          <div>
            {BusinessRuleOperations.find(
              (sv) =>
                sv.value === operationDetails?.typeInformation[1]?.typeValue
            )?.label ?? ""}
          </div>
        </>
      );
    } else if (not) {
      //NOT
      return (
        <>
          <div
            style={{ fontSize: "1.1rem", marginRight: ".4rem", color: "red" }}
          >
            !
          </div>
          <div>
            {BusinessRuleOperations.find(
              (sv) =>
                sv.value === operationDetails?.typeInformation[1]?.typeValue
            )?.label ?? ""}
          </div>
        </>
      );
    } else if (fuzzy) {
      //FUZZY
      return <div>{"\u2248"}</div>;
    } else if (caseSen) {
      //CASE INSENSITIVE
      return (
        <>
          <div
            style={{ fontSize: "1.1rem", marginRight: ".4rem", color: "red" }}
          >
            *
          </div>
          <div>
            {BusinessRuleOperations.find(
              (sv) =>
                sv.value === operationDetails?.typeInformation[1]?.typeValue
            )?.label ?? ""}
          </div>
        </>
      );
    } else if (charMatch) {
      // CHAR MATCH
      return <div>{"~"}</div>;
    } else {
      //REGULAR
      return (
        BusinessRuleOperations.find(
          (sv) => sv.value === operationDetails?.typeInformation[1]?.typeValue
        )?.label ?? ""
      );
    }
  } else {
    const slicedArray = operationDetails?.typeInformation?.slice(2);

    const not = slicedArray.find(
      (slice) => slice?.typeHierarchyLevel === 2 && slice?.typeValue === 1
    );
    const fuzzy = slicedArray.find(
      (slice) => slice?.typeHierarchyLevel === 3 && slice?.typeValue === 2
    );
    const caseSen = slicedArray.find(
      (slice) => slice?.typeHierarchyLevel === 4 && slice?.typeValue === 3
    );
    const charMatch = slicedArray.find(
      (slice) => slice?.typeHierarchyLevel === 7 && slice?.typeValue === 6
    );

    if (fuzzy && not) {
      //NOT AND FUZZY
      return (
        <>
          <div
            style={{ fontSize: "1.1rem", marginRight: ".4rem", color: "red" }}
          >
            !
          </div>
          <div>{"\u2248"}</div>
        </>
      );
    } else if (caseSen && not) {
      //NOT AND FUZZY
      return (
        <>
          <div
            style={{ fontSize: "1.1rem", marginRight: ".4rem", color: "red" }}
          >
            !*
          </div>
          <div>
            {BusinessRuleOperations.find(
              (sv) =>
                sv.value ===
                operationDetails?.typeInformation[1]?.typeValue?.value
            )?.label ?? ""}
          </div>
        </>
      );
    } else if (not) {
      //NOT
      return (
        <>
          <div
            style={{ fontSize: "1.1rem", marginRight: ".4rem", color: "red" }}
          >
            !
          </div>
          <div>
            {BusinessRuleOperations.find(
              (sv) =>
                sv.value ===
                operationDetails?.typeInformation[1]?.typeValue?.value
            )?.label ?? ""}
          </div>
        </>
      );
    } else if (fuzzy) {
      //FUZZY
      return <div>{"\u2248"}</div>;
    } else if (caseSen) {
      //CASE INSENSITIVE
      return (
        <>
          <div
            style={{ fontSize: "1.1rem", marginRight: ".4rem", color: "red" }}
          >
            *
          </div>
          <div>
            {BusinessRuleOperations.find(
              (sv) =>
                sv.value ===
                operationDetails?.typeInformation[1]?.typeValue?.value
            )?.label ?? ""}
          </div>
        </>
      );
    } else if (charMatch) {
      return <div>{"~"}</div>;
    } else {
      //REGULAR
      return (
        BusinessRuleOperations.find(
          (sv) =>
            sv.value === operationDetails?.typeInformation[1]?.typeValue?.value
        )?.label ?? ""
      );
    }
  }
};

export default RenderOperation;
