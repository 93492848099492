import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { withTheme } from "styled-components";
import styled from "styled-components/macro";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
  Cell,
  LabelList,
} from "recharts";

const Tooltip = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 3;
  .tooltip-body {
    width: 100%;
    position: relative;
    color: ${(props) => props.theme.onSurfaceLightSecondary};
    background: ${(props) => props.theme.onSurfaceLight};
    text-align: center;
    padding: 0.4rem;
    font-size: 0.8rem;
    border-radius: 8px;
    text-transform: uppercase;
    &:after {
      content: "";
      position: absolute;
      left: 45%;
      width: 0;
      bottom: -10px;
      height: 0;
      border-style: solid;
      border-width: 10px 7.5px 0 7.5px;
      border-color: ${(props) => props.theme.onSurfaceLight} transparent
        transparent transparent;
    }
  }
`;

const DataSourceBusinessLogicFailuresBarWidget = (props) => {
  const [selectedIndex, setSelectedIndex] = useState();

  const renderTooltip = (props) => {
    const { x, y, index, value } = props;

    const isPositive = true;

    const yValue = isPositive ? y - 40 : y + 10;

    return selectedIndex === index ? (
      <g>
        <foreignObject x={x - 65} y={yValue} width={150} height={60}>
          <Tooltip isPositive={isPositive} xmlns="http://www.w3.org/1999/xhtml">
            <div className="tooltip-body">{value}</div>
          </Tooltip>
        </foreignObject>
      </g>
    ) : (
      <g />
    );
  };

  const height = 324;
  // const failureData = props.data ?? [];

  function handleClick(data, index, e) {
    e.stopPropagation();

    if (selectedIndex === index) {
      setSelectedIndex(null);
    } else {
      setSelectedIndex(index);
    }
  }

  function handleClickOff(data, e) {
    e.stopPropagation();

    setSelectedIndex(null);
  }

  function CustomXAxisTick(props) {
    return (
      <foreignObject {...props} style={{ overflow: "visible" }}>
        <div
          xmlns="http://www.w3.org/1999/xhtml"
          style={{
            width: "100px",
            transform: "rotate(45deg)",
            transformOrigin: "0 0",
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap",
            fontSize: ".7rem",
          }}
          title={props.payload.value}
        >
          {props.payload.value}
        </div>
      </foreignObject>
    );
  }

  return (
    <div
      style={{
        width: "100%",
        position: "relative",
        height: height,
      }}
      className={"businessLogicFailuresBar"}
    >
      <div
        style={{
          position: "absolute",
          left: 0,
          right: 0,
          bottom: 0,
          top: 0,
        }}
      >
        <ResponsiveContainer height={height}>
          <BarChart
            onClick={(data, e) => handleClickOff(data, e)}
            data={props.data}
            layout="horizontal"
          >
            <CartesianGrid strokeDasharray="3 3" />

            <XAxis
              angle={45}
              interval={0}
              height={80}
              textAnchor="start"
              dataKey="name"
              tick={<CustomXAxisTick />}
            />

            <YAxis type="number" />
            <YAxis
              dx={5}
              type="number"
              dataKey="failureCount"
              yAxisId="right"
              orientation="right"
            />

            <Bar
              style={{ cursor: "pointer" }}
              barSize={25}
              dataKey="failureCount"
              radius={[5, 5, 0, 0]}
              onClick={(data, index, e) => handleClick(data, index, e)}
            >
              {" "}
              {props.data.map((entry, index) => {
                return <Cell key={index} fill={"#0099FF"} />;
              })}
              <LabelList dataKey="name" value="test" content={renderTooltip} />
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default withRouter(withTheme(DataSourceBusinessLogicFailuresBarWidget));
