import React, { useEffect, useReducer } from "react";
import {
  Formik,
  Field,
  Form,
  yupToFormErrors,
  validateYupSchema,
} from "formik";
import * as paths from "../../../common/paths";
import {
  FormControl,
  StyledField,
  FormActions,
  ErrorMessage,
  StyledSelect,
  Label,
} from "../../Form/FormControls";
import Button from "../../Button";
import Spinner from "../../Loaders/Spinner";
import SplashLoader from "../../Loaders/SplashLoader";
import ErrorMessages from "../../Notifications/ErrorMessages";
import { createConnection } from "../../../api/connectionMutations";
import PermissionsSelect from "../../Form/PermissionsSelect";
import SqlConnection from "./SqlConnection";
import CosmosConnection from "./CosmosConnection";
import SftpConnection from "./SftpConnection";
import BlobConnection from "./BlobConnection";
import DataverseConnection from "./DataverseConnection";
import { useParams } from "react-router-dom";
import { useApi } from "../../../api/useApi";
import useConnectionCreated from "../../../Hooks/useConnectionCreated";
import styled from "styled-components/macro";
import * as Yup from "yup";
import FtpsConnection from "./FtpsConnection";
import CubeConnection from "./CubeConnection";

//TODO put this in a config to grab for more utilities.
const serverTypes = [
  { value: "SQL_SERVER", label: "SqlServer" },
  { value: "ORACLE", label: "Oracle" },
  { value: "MY_SQL", label: "MySql" },
  { value: "POSTGRE_SQL", label: "PostgreSql" },
  { value: "AZURE_BLOB_STORAGE", label: "AzureBlobStorage" },
  { value: "COSMOS_DB", label: "Cosmos DB" },
  { value: "SFTP", label: "SFTP" },
  { value: "DATAVERSE", label: "Microsoft Dataverse" },
  { value: "FTPS", label: "FTPS" },
  { value: "CUBE", label: "Cube" },
];

const ConnectionLoading = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 3;
  display: flex;
  background: rgba(255, 255, 255, 0.7);
`;

const ConnectionTypeSection = ({ form }) => {
  switch (form.values?.serverType?.value) {
    case "SQL_SERVER":
    case "MY_SQL":
    case "ORACLE":
    case "POSTGRE_SQL":
      return <SqlConnection form={form} />;
    case "AZURE_BLOB_STORAGE":
      return <BlobConnection form={form} />;
    case "SFTP":
      return <SftpConnection form={form} />;
    case "FTPS":
      return <FtpsConnection form={form} />;
    case "COSMOS_DB":
      return <CosmosConnection form={form} />;
    case "DATAVERSE":
      return <DataverseConnection form={form} />;
    case "CUBE":
      return <CubeConnection form={form} />;
    default:
      return null;
  }
};

const ConnectionsComponent = (props) => {
  let params = useParams();
  const connectionId = params?.connectionId;

  const initialState = {
    createError: "",
    creatingConnection: false,
  };

  const { lastConnectionCreated, setLastConnectionCreated } =
    useConnectionCreated();

  useEffect(() => {
    return setLastConnectionCreated(null);
  }, [setLastConnectionCreated]);

  const [{ errors: createErrors }, create] = useApi();

  //Reducer States for this component, Details what part of the state updates pending dispatch
  const reducer = (state, action) => {
    switch (action.type) {
      case "FAILED_TEST": {
        return {
          ...state,
          creatingConnection: false,
        };
      }

      case "CREATE_ERROR": {
        setLastConnectionCreated(null);
        return {
          ...state,
          createError: action.payload,
          creatingConnection: false,
        };
      }

      case "SUBMIT": {
        const values = action.payload.values;

        const formValues = {
          ...action.payload.values,
          serverType: values.serverType.value,
        };

        const { advanced, ocrEnabled, ocrConnection, ...cleanForm } =
          formValues;
        let cleanerForm = cleanForm;
        if (
          (values.serverType.value === "AZURE_BLOB_STORAGE" ||
            values.serverType.value === "FTPS" ||
            values.serverType.value === "SFTP") &&
          ocrEnabled
        ) {
          cleanerForm = {
            ...(action.payload.values?.ocrEnabled && {
              ocrConnection: action.payload.values?.ocrConnection,
            }),
            ...cleanForm,
          };
        }

        //removes advanced from object
        const variables = {
          connection: {
            ...cleanerForm,
          },
        };

        create({ query: createConnection, variables });

        return {
          ...state,
          creatingConnection: true,
          createError: "",
        };
      }

      default:
        return {
          ...state,
        };
    }
  };

  //initialize the reducer state
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    if (createErrors) dispatch({ type: "FAILED_TEST" });
  }, [createErrors, dispatch]);

  useEffect(() => {
    if (lastConnectionCreated) {
      const { SourceId, ErrorMessage } = lastConnectionCreated.payload;

      if (SourceId) {
        props.history.push(paths.dataConnectionsSources(SourceId));
        setLastConnectionCreated(null);
      } else {
        dispatch({
          type: "CREATE_ERROR",
          payload: ErrorMessage ?? "",
        });
      }
    }
  }, [lastConnectionCreated, props, dispatch, setLastConnectionCreated]);

  const initialValues = {
    name: "",
    serverType: { value: "SQL_SERVER", label: "SqlServer" },
    context: "NONE",
    details: {
      databaseConnectionString: {
        connectionString: null,
        defaultDatabase: null,
        password: null,
        host: null,
        port: null,
        username: null,
      },
    },
    ocrConnection: null,
    //TEMP: UNDO PRESET
    permissions: [],
    ocrEnabled: false,
  };

  return (
    <>
      {state.creatingConnection && (
        <ConnectionLoading>
          <SplashLoader text={"Testing Connection"} />
        </ConnectionLoading>
      )}

      <Formik
        enableReinitialize={true}
        initialValues={{
          advanced: false,
          ...initialValues,
        }}
        validateOnMount={true}
        validate={(values) => {
          let schema;
          if (
            values.serverType.value === "SQL_SERVER" ||
            values.serverType.value === "MY_SQL" ||
            values.serverType.value === "ORACLE" ||
            values.serverType.value === "POSTGRE_SQL"
          ) {
            schema = Yup.object().shape({
              advanced: Yup.boolean(),
              name: Yup.string().min(2, "Too Short!").required("Required"),
              serverType: Yup.object().required("Required"),
              permissions: Yup.array().min(1),
              details: Yup.object().when("advanced", {
                is: true,
                then: Yup.object({
                  databaseConnectionString: Yup.object({
                    connectionString: Yup.mixed().required("Required"),
                  }),
                }),
                otherwise: Yup.object({
                  databaseConnectionString: Yup.object({
                    defaultDatabase: Yup.mixed().required("Required"),
                    password: Yup.mixed().required("Required"),
                    host: Yup.mixed().required("Required"),
                    port: Yup.mixed().required("Required"),
                    username: Yup.mixed().required("Required"),
                  }),
                }),
              }),
            });
          } else if (
            values.serverType.value === "AZURE_BLOB_STORAGE" &&
            values.ocrEnabled
          ) {
            schema = Yup.object().shape({
              advanced: Yup.boolean(),
              name: Yup.string().min(2, "Too Short!").required("Required"),
              serverType: Yup.object().required("Required"),
              permissions: Yup.array().min(1),
              details: Yup.object().when("advanced", {
                is: true,
                then: Yup.object({
                  azureStorageConnectionString: Yup.object({
                    connectionString: Yup.mixed().required("Required"),
                  }),
                }),
                otherwise: Yup.object({
                  azureStorageConnectionString: Yup.object({
                    accountName: Yup.mixed().required("Required"),
                    accountKey: Yup.mixed().required("Required"),
                    blobUri: Yup.mixed().required("Required"),
                  }),
                }),
              }),
              ocrConnection: Yup.object().when("ocrEnabled", {
                is: true,
                then: Yup.object({
                  docProviderId: Yup.mixed().required("Required"),
                  containerName: Yup.mixed().required("Required"),
                  inputPath: Yup.mixed().required("Required"),
                  ocrDocuments: null,
                  namingConvention: Yup.mixed().required("Required"),
                }),
                otherwise: null,
              }),
            });
          } else if (values.serverType.value === "AZURE_BLOB_STORAGE") {
            schema = Yup.object().shape({
              advanced: Yup.boolean(),
              name: Yup.string().min(2, "Too Short!").required("Required"),
              serverType: Yup.object().required("Required"),
              permissions: Yup.array().min(1),
              details: Yup.object().when("advanced", {
                is: true,
                then: Yup.object({
                  azureStorageConnectionString: Yup.object({
                    connectionString: Yup.mixed().required("Required"),
                  }),
                }),
                otherwise: Yup.object({
                  azureStorageConnectionString: Yup.object({
                    accountName: Yup.mixed().required("Required"),
                    accountKey: Yup.mixed().required("Required"),
                    blobUri: Yup.mixed().required("Required"),
                  }),
                }),
              }),
            });
          } else if (values.serverType.value === "COSMOS_DB") {
            schema = Yup.object().shape({
              advanced: Yup.boolean(),
              name: Yup.string().min(2, "Too Short!").required("Required"),
              serverType: Yup.object().required("Required"),
              permissions: Yup.array().min(1),
              details: Yup.object().when("advanced", {
                is: true,
                then: Yup.object({
                  cosmosConnectionString: Yup.object({
                    connectionString: Yup.mixed().required("Required"),
                  }),
                }),
                otherwise: Yup.object({
                  cosmosConnectionString: Yup.object({
                    cosmosUri: Yup.mixed().required("Required"),
                    accountKey: Yup.mixed().required("Required"),
                    database: Yup.mixed().required("Required"),
                  }),
                }),
              }),
            });
          } else if (values.serverType.value === "SFTP") {
            schema = Yup.object().shape({
              name: Yup.string().min(2, "Too Short!").required("Required"),
              serverType: Yup.object().required("Required"),
              permissions: Yup.array().min(1),
              details: Yup.object({
                sftpConnectionString: Yup.object({
                  serverAddress: Yup.mixed().required("Required"),
                  username: Yup.mixed().required("Required"),
                  password: Yup.mixed().required("Required"),
                  privateKey: Yup.mixed().nullable(),
                }),
              }),
            });
          } else if (values.serverType.value === "SFTP" && values.ocrEnabled) {
            schema = Yup.object().shape({
              name: Yup.string().min(2, "Too Short!").required("Required"),
              serverType: Yup.object().required("Required"),
              permissions: Yup.array().min(1),
              details: Yup.object({
                sftpConnectionString: Yup.object({
                  serverAddress: Yup.mixed().required("Required"),
                  username: Yup.mixed().required("Required"),
                  password: Yup.mixed().required("Required"),
                  privateKey: Yup.mixed().nullable(),
                }),
              }),
              ocrConnection: Yup.object().when("ocrEnabled", {
                is: true,
                then: Yup.object({
                  docProviderId: Yup.mixed().required("Required"),
                  containerName: Yup.mixed().required("Required"),
                  inputPath: Yup.mixed().required("Required"),
                  ocrDocuments: null,
                  namingConvention: Yup.mixed().required("Required"),
                  destinationConnectionId: Yup.mixed().required("Required"),
                  // Custom Vision Project
                  customVisionIteration: null,
                  customVisionProjectId: null,
                }),
                otherwise: null,
              }),
            });
          } else if (values.serverType.value === "DATAVERSE") {
            schema = Yup.object().shape({
              name: Yup.string().min(2, "Too Short!").required("Required"),
              serverType: Yup.object().required("Required"),
              permissions: Yup.array().min(1),
              details: Yup.object().when("advanced", {
                is: true,
                then: Yup.object({
                  dataverseConnectionString: Yup.object({
                    connectionString: Yup.mixed().required("Required"),
                  }),
                }),
                otherwise: Yup.object({
                  dataverseConnectionString: Yup.object({
                    environmentUri: Yup.mixed().required("Required"),
                  }),
                }),
              }),
            });
          } else if (values.serverType.value === "FTPS") {
            schema = Yup.object().shape({
              name: Yup.string().min(2, "Too Short!").required("Required"),
              serverType: Yup.object().required("Required"),
              permissions: Yup.array().min(1),
              details: Yup.object({
                ftpsConnectionString: Yup.object({
                  serverAddress: Yup.mixed().required("Required"),
                  username: Yup.mixed().required("Required"),
                  password: Yup.mixed().required("Required"),
                }),
              }),
            });
          } else if (values.serverType.value === "FTPS" && values.ocrEnabled) {
            schema = Yup.object().shape({
              name: Yup.string().min(2, "Too Short!").required("Required"),
              serverType: Yup.object().required("Required"),
              permissions: Yup.array().min(1),
              details: Yup.object({
                ftpsConnectionString: Yup.object({
                  serverAddress: Yup.mixed().required("Required"),
                  username: Yup.mixed().required("Required"),
                  password: Yup.mixed().required("Required"),
                }),
              }),
              ocrConnection: Yup.object().when("ocrEnabled", {
                is: true,
                then: Yup.object({
                  docProviderId: Yup.mixed().required("Required"),
                  containerName: Yup.mixed().required("Required"),
                  inputPath: Yup.mixed().required("Required"),
                  ocrDocuments: null,
                  namingConvention: Yup.mixed().required("Required"),
                  destinationConnectionId: Yup.mixed().required("Required"),
                  // Custom Vision Project
                  customVisionIteration: null,
                  customVisionProjectId: null,
                }),
                otherwise: null,
              }),
            });
          } else if (values.serverType.value === "CUBE") {
            schema = Yup.object().shape({
              advanced: Yup.boolean(),
              name: Yup.string().min(2, "Too Short!").required("Required"),
              serverType: Yup.object().required("Required"),
              permissions: Yup.array().min(1),
              details: Yup.object().when("advanced", {
                is: true,
                then: Yup.object({
                  cubeConnectionString: Yup.object({
                    connectionString: Yup.mixed().required("Required"),
                  }),
                }),
                otherwise: Yup.object({
                  cubeConnectionString: Yup.object({
                    database: Yup.mixed().required("Required"),
                    region: Yup.mixed().required("Required"),
                    serverName: Yup.mixed().required("Required"),
                    tenantId: Yup.mixed(),
                    userId: Yup.mixed(),
                    password: Yup.mixed(),
                  }),
                }),
              }),
            });
          }

          const promise = validateYupSchema(values, schema);

          return new Promise((resolve, reject) => {
            promise.then(
              () => {
                resolve({});
              },
              (err) => {
                if (err.name === "ValidationError") {
                  resolve(yupToFormErrors(err));
                } else {
                  reject(err);
                }
              }
            );
          });
        }}
      >
        {(form) => {
          return (
            <Form>
              <FormControl>
                <StyledField
                  name={`name`}
                  type="text"
                  placeholder="Name"
                  label="Name"
                  value={form?.values?.name}
                />
                <ErrorMessage name={`name`} />
              </FormControl>

              <FormControl>
                <Label>Data Connection Type</Label>
                <StyledSelect
                  className={`react-select-container`}
                  classNamePrefix={`react-select`}
                  name={`serverType`}
                  id={`serverType`}
                  inputId={`serverTypeSelect-input`}
                  instanceId={`serverTypeSelect-instance`}
                  placeholder={`Select Data Connection Type`}
                  label="Data Type"
                  value={form.values.serverType}
                  menuPortalTarget={document.body}
                  options={serverTypes.map((type, i) => {
                    return {
                      value: type.value,
                      label: type.label,
                      // isDisabled: i > 1 && i !== 4
                    };
                  })}
                  onChange={(e) => {
                    form.setFieldValue(`serverType`, e);
                    switch (e.value) {
                      case "SQL_SERVER":
                      case "MY_SQL":
                      case "ORACLE":
                      case "POSTGRE_SQL":
                        form.setFieldValue(`serverType`, e);
                        form.setFieldValue(`context`, "NONE");
                        form.setFieldValue(`ocrConnection`, null);
                        form.setFieldValue(`ocrEnabled`, false);
                        form.setFieldValue(`details`, {
                          databaseConnectionString: {
                            connectionString: null,
                            defaultDatabase: null,
                            password: null,
                            host: null,
                            port: null,
                            username: null,
                          },
                          azureStorageConnectionString: null,
                          sftpConnectionString: null,
                          cosmosConnectionString: null,
                          dataverseConnectionString: null,
                          cubeConnectionString: null,
                        });
                        break;
                      case "AZURE_BLOB_STORAGE":
                        form.setFieldValue(`serverType`, e);
                        form.setFieldValue(`context`, "INGRESS_SOURCE");
                        form.setFieldValue(`ocrEnabled`, false);
                        form.setFieldValue(`ocrConnection`, {
                          docProviderId: null,
                          containerName: null,
                          inputPath: null,
                          ocrDocuments: null,
                          namingConvention: "ID_TYPE_VERSION",
                        });
                        form.setFieldValue(`details`, {
                          azureStorageConnectionString: {
                            connectionString: null,
                            accountName: null,
                            accountKey: null,
                            blobUri: null,
                          },
                          databaseConnectionString: null,
                          sftpConnectionString: null,
                          cosmosConnectionString: null,
                          dataverseConnectionString: null,
                          cubeConnectionString: null,
                        });
                        break;
                      case "SFTP":
                        form.setFieldValue(`serverType`, e);
                        form.setFieldValue(`context`, "INGRESS_SOURCE");
                        form.setFieldValue(`ocrConnection`, null);
                        form.setFieldValue(`ocrEnabled`, false);
                        form.setFieldValue(`details`, {
                          sftpConnectionString: {
                            serverAddress: null,
                            username: null,
                            password: null,
                            privateKey: null,
                          },
                          databaseConnectionString: null,
                          azureStorageConnectionString: null,
                          cosmosConnectionString: null,
                          dataverseConnectionString: null,
                          cubeConnectionString: null,
                        });
                        break;
                      case "FTPS":
                        form.setFieldValue(`serverType`, e);
                        form.setFieldValue(`context`, "INGRESS_SOURCE");
                        form.setFieldValue(`ocrConnection`, null);
                        form.setFieldValue(`ocrEnabled`, false);
                        form.setFieldValue(`details`, {
                          ftpsConnectionString: {
                            serverAddress: null,
                            username: null,
                            password: null,
                          },
                          sftpConnectionString: null,
                          databaseConnectionString: null,
                          azureStorageConnectionString: null,
                          cosmosConnectionString: null,
                          dataverseConnectionString: null,
                          cubeConnectionString: null,
                        });
                        break;
                      case "COSMOS_DB":
                        form.setFieldValue(`serverType`, e);
                        form.setFieldValue(`context`, "NONE");
                        form.setFieldValue(`ocrConnection`, {
                          docProviderId: null,
                          containerName: null,
                          inputPath: null,
                          ocrDocuments: null,
                          namingConvention: "ID_TYPE_VERSION",
                        });
                        form.setFieldValue(`ocrEnabled`, false);
                        form.setFieldValue(`details`, {
                          cosmosConnectionString: {
                            connectionString: null,
                            cosmosUri: null,
                            accountKey: null,
                            database: null,
                          },
                          databaseConnectionString: null,
                          azureStorageConnectionString: null,
                          sftpConnectionString: null,
                          dataverseConnectionString: null,
                          cubeConnectionString: null,
                        });
                        break;
                      case "DATAVERSE":
                        form.setFieldValue(`serverType`, e);
                        form.setFieldValue(`context`, "NONE");
                        form.setFieldValue(`ocrConnection`, null);
                        form.setFieldValue(`ocrEnabled`, false);
                        form.setFieldValue(`details`, {
                          dataverseConnectionString: {
                            connectionString: null,
                            environmentUri: null,
                          },
                          databaseConnectionString: null,
                          azureStorageConnectionString: null,
                          sftpConnectionString: null,
                          cosmosConnectionString: null,
                          cubeConnectionString: null,
                        });
                        break;
                      case "CUBE":
                        form.setFieldValue(`serverType`, e);
                        form.setFieldValue(`context`, "NONE");
                        form.setFieldValue(`ocrConnection`, null);
                        form.setFieldValue(`ocrEnabled`, false);
                        form.setFieldValue(`details`, {
                          cubeConnectionString: {
                            connectionString: null,
                            database: null,
                            region: null,
                            serverName: null,
                            tenantId: null,
                            userId: null,
                            password: null,
                          },
                          azureStorageConnectionString: null,
                          sftpConnectionString: null,
                          cosmosConnectionString: null,
                          dataverseConnectionString: null,
                        });
                        break;
                      default:
                        break;
                    }
                  }}
                />
                <ErrorMessage name={`serverType`} />
              </FormControl>

              <ConnectionTypeSection form={form} />
              <hr />
              <h3>Permissions</h3>
              <Field
                name={`permissions`}
                component={PermissionsSelect}
                //Editing flag passes in if we are updating/editing form
              />

              <FormActions>
                <FormControl>
                  {createErrors ? (
                    <ErrorMessages errors={createErrors} />
                  ) : null}

                  {state.createError ? (
                    <ErrorMessages errors={[{ message: state.createError }]} />
                  ) : null}

                  {!connectionId ? (
                    <Button
                      type="button"
                      disabled={form.isSubmitting || !form.isValid}
                      onClick={() =>
                        dispatch({
                          type: "SUBMIT",
                          payload: {
                            values: form.values,
                          },
                        })
                      }
                    >
                      {form.isSubmitting ? <Spinner /> : "Submit"}
                    </Button>
                  ) : null}
                </FormControl>
              </FormActions>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default ConnectionsComponent;
