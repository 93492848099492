import React, { useState, useEffect, useCallback } from "react";
import { useApi } from "../../api/useApi";
import Button from "../Button";
import ErrorMessages from "../../components/Notifications/ErrorMessages";
import { exportPermissions } from "../../api/auditMutations";
import { NotificationLoading } from "../../components/Notification/NotificationLoading";
import Notification from "../../components/Notification";

function ExportPermissionsAuditLog({ cancel }) {
  const [showExport, setShowExport] = useState(false);

  const resetExport = useCallback(() => {
    setShowExport(false);
    cancel();
  }, [setShowExport, cancel]);

  const [{ errors, data }, createExport] = useApi();

  useEffect(() => {
    if (data) {
      setShowExport(true);
    }
  }, [data, setShowExport]);

  const callCreateExport = useCallback(() => {
    createExport({
      query: exportPermissions,
    });
  }, [createExport]);

  return (
    <>
      {showExport ? (
        <NotificationLoading>
          <Notification
            closeCallBack={resetExport}
            text="Your audit log is being prepared; an email will be sent to you when the extract is ready to download."
          />
        </NotificationLoading>
      ) : null}
      {errors && <ErrorMessages errors={errors} />}
      <p>
        Export your permissions audit log by clicking export below, a url will
        be emailed to you for download as well as show up in your notifications
        center when ready.
      </p>

      <div style={{ marginTop: "1rem" }}>
        <Button
          type="button"
          list
          danger
          onClick={() => {
            cancel();
          }}
        >
          Cancel
        </Button>
        <Button
          type="button"
          onClick={() => {
            callCreateExport();
          }}
        >
          Export
        </Button>
      </div>
    </>
  );
}

export default ExportPermissionsAuditLog;
