import React, { useState, useEffect, useCallback } from "react";
import { useApi } from "../../api/useApi";
import { groups } from "../../api/groupQueries";

import { updateCreateWorkGroupFlag } from "../../api/workgroupMutations";

import SortTable from "../../components/Table/SortTable";
import Button from "../../components/Button";
import { MdArchive } from "react-icons/md";
import Modal from "../../components/Modal";
import Spinner from "../../components/Loaders/Spinner";
import ErrorMessages from "../../components/Notifications/ErrorMessages";
import TableButton from "../../components/Button/TableButton";
import AddGroups from "./AddGroups";

const Organization = () => {
  const [{ loading, errors, data }, doFetch] = useApi(groups, { first: 9999 });
  const [
    { loading: removalLoading, errors: removalErrors, data: removalData },
    remove,
  ] = useApi();
  const [{ errors: syncErrors, data: syncData }] = useApi();

  const [showConfirm, setShowConfirm] = useState(false);
  const [orgToDelete, setOrgToDelete] = useState(null);
  const [showArchived, setShowArchived] = useState(false);
  const [showAddGroup, setShowAddGroup] = useState(false);

  const workgroups = data?.groups?.edges ?? [];
  const enabledWorkgroups =
    workgroups
      .filter((wg) => wg?.node?.createWorkGroup)
      .sort((a, b) =>
        a?.node?.displayName.localeCompare(b?.node?.displayName)
      ) ?? [];
  const disabledWorkgroups =
    workgroups
      .filter((wg) => !wg?.node?.createWorkGroup)
      .sort((a, b) =>
        a?.node?.displayName.localeCompare(b?.node?.displayName)
      ) ?? [];

  const columnsData = [
    {
      Header: "Name",
      id: "name",
      accessor: (d) => d?.node?.displayName,
    },
    {
      Header: "Enabled",
      id: "enabled",
      accessor: (d) => d?.node?.createWorkGroup,
      Cell: ({ row: { original } }) => {
        return original?.node?.createWorkGroup?.toString();
      },
    },
    {
      Header: "",
      id: "actions",
      maxWidth: 150,
      disableResizing: true,
      Cell: ({ row: { original } }) => {
        const enabled = original?.node?.createWorkGroup;

        return (
          <>
            <TableButton
              title="Toggle Enabled"
              type="button"
              danger={enabled}
              onClick={() =>
                setOrgToDelete({
                  id: original?.node?.id,
                  enabled: enabled,
                  name: original?.node?.displayName,
                })
              }
            >
              <MdArchive />
            </TableButton>
          </>
        );
      },
    },
  ];

  const fetchOrgs = useCallback(() => {
    doFetch({
      query: groups,
      variables: {
        first: 9999,
        order: {
          displayName: "ASC",
        },
      },
    });
  }, [doFetch]);

  const toggleEnabled = ({ id, enabled }) => {
    remove({
      query: updateCreateWorkGroupFlag,
      variables: { groupId: id, createWorkGroupState: !enabled },
    });
  };

  //handle remove rule update
  useEffect(() => {
    if (removalData) {
      fetchOrgs();
      setShowConfirm(false);
      setOrgToDelete(null);
      setShowArchived(false);
    }
  }, [removalData, fetchOrgs]);

  useEffect(() => {
    if (orgToDelete) {
      setShowConfirm(true);
    } else {
      setShowConfirm(false);
    }
  }, [orgToDelete]);

  useEffect(() => {
    if (syncData) {
      fetchOrgs();
      setShowConfirm(false);
      setShowArchived(false);
    }
  }, [syncData, fetchOrgs]);

  return (
    <>
      {showAddGroup ? (
        <Modal
          title={`Add Group to Platform`}
          hide={() => setShowAddGroup(null)}
        >
          <AddGroups setShowAddGroup={setShowAddGroup} />
        </Modal>
      ) : null}

      {showConfirm ? (
        <Modal
          title={`Confirm ${
            orgToDelete?.enabled ? "Disable" : "Activation"
          } of ${orgToDelete?.name}`}
          hide={() => setOrgToDelete(null)}
        >
          <p>
            Are you sure you wish to{" "}
            {orgToDelete?.enabled ? "disable" : "activate"} {orgToDelete?.name}?
          </p>
          {orgToDelete?.enabled ? (
            <p>
              This action will remove all Permissions for the selected group.
            </p>
          ) : null}

          <div>
            <Button
              type="button"
              list="true"
              disabled={removalLoading}
              danger
              onClick={() => toggleEnabled(orgToDelete)}
            >
              {removalLoading ? <Spinner /> : "Yes"}
            </Button>
            <Button
              type="button"
              disabled={removalLoading}
              onClick={() => {
                setOrgToDelete(null);
              }}
            >
              Cancel
            </Button>
          </div>
        </Modal>
      ) : null}

      {!showAddGroup && (
        <Button
          onClick={() => setShowAddGroup((prev) => !prev)}
          list={"true"}
          type="button"
        >
          Find Group
        </Button>
      )}

      {disabledWorkgroups && disabledWorkgroups.length ? (
        <Button
          type="button"
          onClick={() => setShowArchived((prevState) => !prevState)}
        >
          {showArchived ? "Hide " : "Show "} Disabled
        </Button>
      ) : null}

      {syncErrors ? <ErrorMessages errors={syncErrors} /> : null}
      {removalErrors ? <ErrorMessages errors={removalErrors} /> : null}
      {errors ? <ErrorMessages errors={errors} /> : null}
      {/* TODO: Update this to support pagination defaults max 250 orgs also filter based on call  */}
      <h3>Groups</h3>
      <SortTable
        loading={loading}
        data={showArchived ? disabledWorkgroups : enabledWorkgroups}
        columns={columnsData}
      />
    </>
  );
};

export default Organization;
