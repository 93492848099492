import React from "react";
import { Route, NavLink } from "react-router-dom";
import styled from "styled-components/macro";
import WorkflowView from "./WorkflowView/WorkflowView";
import Alerts from "./WorkflowAlerts";
import WorkflowHistory from "./WorkflowHistory";

const Tabs = styled.div`
  border-bottom: 1px solid ${(props) => props.theme.surfaceAlt};
  background-color: ${(props) => props.theme.surface};
  position: relative;
  padding: 1.2rem;
  margin-bottom: 1rem;
`;

const TabLink = styled(NavLink)`
  padding: 1rem;
  border-bottom: 2px solid transparent;
  text-decoration: none;
  opacity: 0.5;
  color: ${(props) => props.theme.onSurface};
  &.active {
    opacity: 1;
    border-bottom: 2px solid ${(props) => props.theme.onSecondarySurface};
  }
  &:hover {
    opacity: 1;
  }
`;
const WorkflowTabs = ({ workflowId, data }) => {
  return (
    <>
      <Tabs>
        <TabLink exact to={`/workflows/${workflowId}`}>
          Workflow
        </TabLink>
        <TabLink exact to={`/workflows/${workflowId}/alerts`}>
          Alerts
        </TabLink>
        <TabLink exact to={`/workflows/${workflowId}/history`}>
          History
        </TabLink>
      </Tabs>

      {/* <Route
        path={`/workflows/:workflowId/history/:batchName`}
        exact
        component={() => <div>BatchName</div>}
      /> */}
      <Route
        path={`/workflows/:workflowId/history`}
        exact
        component={() => <WorkflowHistory workflowId={workflowId} />}
      />
      <Route
        path={`/workflows/:workflowId/alerts`}
        exact
        component={() => <Alerts workflowId={workflowId} />}
      />
      <Route
        exact
        path={`/workflows/:workflowId`}
        component={() => <WorkflowView data={data} workflowId={workflowId} />}
      />
    </>
  );
};

export default WorkflowTabs;
