import React from "react";
import styled from "styled-components/macro";
import { MdWarning } from "react-icons/md";
import { Link } from "react-router-dom";

const Warning = styled(Link)`
  color: ${(props) => props.theme.onSecondary};
  font-size: 0.8em;
  margin-right: 20px;
  display: inline-block;
  padding: 12px;
  background: ${(props) => props.theme.surfaceAlt};
  text-decoration: none;
`;

const WarningNoPath = styled.div`
  color: ${(props) => props.theme.onSecondary};
  font-size: 0.8em;
  margin-right: 20px;
  display: inline-block;
  padding: 12px;
  background: ${(props) => props.theme.surfaceAlt};
  text-decoration: none;
`;

const WarningContainer = styled.div`
  display: flex;
  align-items: center;
`;

const WarningIcon = styled.div`
  color: ${(props) => props.theme.warn};
`;

const CTAWarn = ({ path, msg }) => {
  const WarnWrapper = path ? Warning : WarningNoPath;
  return (
    <WarnWrapper to={path}>
      <WarningContainer>
        <WarningIcon>
          <MdWarning />
        </WarningIcon>
        {msg}
      </WarningContainer>
    </WarnWrapper>
  );
};

export default CTAWarn;
