const caseInsenSort = (prev, curr, columnId) => {
  if (
    prev.original[columnId]?.toLowerCase() >
    curr.original[columnId]?.toLowerCase()
  ) {
    return 1;
  } else if (
    prev.original[columnId]?.toLowerCase() <
    curr.original[columnId]?.toLowerCase()
  ) {
    return -1;
  } else {
    return 0;
  }
};

export default caseInsenSort;
