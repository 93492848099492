import React, { useCallback, useEffect, useState } from "react";
import DocProviderForm from "../Forms/DocProviderForm";
import { useApi } from "../../api/useApi";
import ErrorMessages from "../Notifications/ErrorMessages";
import styled from "styled-components/macro";
import Button from "../../components/Button";
import SortTable from "../../components/Table/SortTable";
import SplashLoader from "../../components/Loaders/SplashLoader";
import { MdArchive } from "react-icons/md";
import { updateDocProvider } from "../../api/etlProviderMutations";
import Modal from "../../components/Modal";
import Spinner from "../../components/Loaders/Spinner";
import { InLineTitlePin } from "../../components/Table/elements";
import TableButton from "../Button/TableButton";

const ProvidersLoading = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 3;
  display: flex;
  background: rgba(255, 255, 255, 0.7);
`;

const InstanceList = ({ docProviders, fetchLatest }) => {
  const [showConfirm, setShowConfirm] = useState(false);
  const [instanceToDelete, setInstanceToDelete] = useState(null);
  const [showEdit, setShowEdit] = useState(false);
  const [providerToEdit, setProviderToEdit] = useState(null);

  const [
    { loading: removalLoading, errors: removalErrors, data: removalData },
    remove,
  ] = useApi();

  const toggleEnabled = (provider) => {
    remove({
      query: updateDocProvider,
      variables: {
        docProviderId: provider?.id,
        docProviderInput: {
          displayName: provider?.displayName,
          docProviderType: provider?.docProviderType,
          connectionApiKey: provider?.connectionApiKey ?? "",
          connectionEndpoint: provider?.connectionEndpoint ?? "",
          enabled: !provider?.enabled,
        },
      },
    });
  };

  const columnsData = [
    {
      Header: "Name",
      id: "displayName",
      accessor: "displayName",
      Cell: ({ row: { original } }) => {
        return <InLineTitlePin>{original.displayName}</InLineTitlePin>;
      },
    },
    {
      Header: "Provider Type",
      id: "docProviderType",
      accessor: "docProviderType",
    },
    {
      Header: " ",
      id: "actions",
      width: 250,
      resizable: false,
      sortable: false,
      Cell: ({ row: { original } }) => {
        const enabled = original?.enabled;
        return (
          <div style={{ textAlign: "right" }}>
            <TableButton
              list="true"
              type="button"
              onClick={() => setProviderToEdit(original)}
            >
              Edit
            </TableButton>
            <TableButton
              danger={enabled}
              list="true"
              id={`toggleInstanceArchiveReactivate`}
              data-testid={`toggleInstanceArchiveReactivate`}
              type="button"
              onClick={() => setInstanceToDelete(original)}
            >
              {enabled ? <MdArchive /> : "Reactivate"}
            </TableButton>
          </div>
        );
      },
    },
  ];

  // Handle remove rule update
  useEffect(() => {
    if (removalData) {
      fetchLatest();
      setProviderToEdit(null);
    }
  }, [removalData, fetchLatest]);

  useEffect(() => {
    if (instanceToDelete) {
      setShowConfirm(true);
    } else {
      setShowConfirm(false);
    }
  }, [instanceToDelete, setShowConfirm]);

  useEffect(() => {
    if (providerToEdit) {
      setShowEdit(true);
    } else {
      setShowEdit(false);
    }
  }, [providerToEdit, setShowEdit]);

  const updateOrSave = useCallback(() => {
    fetchLatest();
    setProviderToEdit(null);
  }, [setProviderToEdit, fetchLatest]);

  return (
    <>
      {showEdit ? (
        <Modal
          title={`Edit ${providerToEdit?.displayName}`}
          hide={() => setProviderToEdit(null)}
        >
          <DocProviderForm
            fetchLatest={updateOrSave}
            provider={providerToEdit}
          />
        </Modal>
      ) : null}

      {showConfirm ? (
        <Modal
          title={`Confirm Instance ${
            instanceToDelete?.enabled ? "Archival" : "Reactivation"
          }`}
          hide={() => setInstanceToDelete(null)}
        >
          <p>
            Are you sure you wish to{" "}
            {instanceToDelete?.enabled ? "archive" : "reactivate"} this
            provider?
          </p>
          <div>
            <Button
              type="button"
              list="true"
              disabled={removalLoading}
              danger
              onClick={() => {
                setInstanceToDelete(null);
                toggleEnabled(instanceToDelete);
              }}
            >
              {removalLoading ? <Spinner /> : "Yes"}
            </Button>
            <Button
              type="button"
              disabled={removalLoading}
              onClick={() => {
                setInstanceToDelete(null);
              }}
            >
              Cancel
            </Button>
          </div>
        </Modal>
      ) : null}

      {removalErrors && <ErrorMessages errors={removalErrors} />}
      <SortTable data={docProviders} columns={columnsData} />
    </>
  );
};

const OcrManager = () => {
  // Query for Data Providers
  const query = `
      query(){
        allDocProviders() {
            displayName
            docProviderType
            enabled
            id
        }
    }
  `;

  // Loading the query on component mount for latest configuration
  const [{ loading, errors, data }, fetchProviders] = useApi(query);

  const [addProvider, setAddProvider] = useState(false);

  const fetchLatestDataProviders = useCallback(() => {
    // Fetch latest ETL Provider here
    fetchProviders({ query: query });
    setAddProvider(false);
  }, [fetchProviders, query]);

  // TEMP FETCH AVAILABLE ETL CONNECTION

  const hasProviders = data?.allDocProviders?.length > 0;

  if (loading)
    return (
      <ProvidersLoading>
        <SplashLoader text="Loading Results" />
      </ProvidersLoading>
    );

  if (!loading && errors) return <ErrorMessages errors={errors} />;

  return hasProviders && !addProvider ? (
    <>
      <Button
        type="button"
        onClick={() => {
          setAddProvider(true);
        }}
      >
        Add Data Provider
      </Button>
      <InstanceList
        docProviders={data?.allDocProviders}
        fetchLatest={fetchLatestDataProviders}
      />
    </>
  ) : (
    <>
      {hasProviders?.length ? (
        <Button
          type="button"
          danger={true}
          onClick={() => {
            setAddProvider(false);
          }}
        >
          Cancel
        </Button>
      ) : null}

      <DocProviderForm fetchLatest={fetchLatestDataProviders} />
    </>
  );
};

export default OcrManager;
