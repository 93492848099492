import React, { useState } from "react";
import Button from "../../../Button";
import Secondary from "../../../Button/Secondary";
import { FormControl, StyledInput } from "../../../Form/FormControls";
import { SmallText } from "./CreateFormStyles";

const UpdateThreshold = ({ dispatch, data }) => {
  const [currentThreshold, setCurrentThreshold] = useState(
    data?.dataSource?.dataQualityThreshold
  );

  const setMaxThreshold = (threshold) => {
    let setThreshold = threshold;
    if (threshold > 100) {
      setThreshold = 100;
    } else if (threshold < 0) {
      setThreshold = 0;
    }
    setCurrentThreshold(setThreshold);
  };

  return (
    <>
      <FormControl>
        <StyledInput
          type="number"
          name="dqthreshold"
          label="Data Quality Threshold"
          required={true}
          max={"100"}
          min={"1"}
          value={currentThreshold}
          placeholder={`Set Data Quality Threshold`}
          onChange={(e) => setMaxThreshold(e.target.value)}
        />
      </FormControl>
      <div style={{}}>
        {" "}
        <SmallText>
          Threshold updates will take place across every instance of this Data
          Source once Workflow is saved.
        </SmallText>
      </div>

      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <Secondary
          list={true}
          onClick={() =>
            dispatch({
              type: "TOGGLE_CHANGE_DATASOURCE_THRESHOLD",
            })
          }
        >
          Cancel
        </Secondary>
        <Button
          onClick={() =>
            dispatch({
              type: "UPDATE_DATASOURCE_THRESHOLD",
              payload: {
                dataSource: data?.dataSource,
                newThreshold: currentThreshold,
              },
            })
          }
        >
          Save
        </Button>
      </div>
    </>
  );
};

export default UpdateThreshold;
