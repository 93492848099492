import { useContext } from "react";
import { SocketContext } from "../contexts/useSubscriptions";

const UseFeedPercentNotification = () => {
  const { feedPercentNotification, setFeedPercentNotification } =
    useContext(SocketContext);

  return { feedPercentNotification, setFeedPercentNotification };
};
export default UseFeedPercentNotification;
