import React, { useState, useRef, useEffect } from "react";
import {
  DataSourceContainer,
  DataSourceHeading,
  FieldLabel,
  ThresholdContainer,
  FormContainers,
  DataSourceWrapper,
} from "./ViewWorkflowStyles";
import styled from "styled-components/macro";
import { MdCancel, MdCheckCircle } from "react-icons/md";
import Copy from "../../../components/Copy";

const Indicators = styled.div`
  font-size: 1rem;
  align-items: center;
  display: flex;
  font-size: 0.875rem;
`;

//generic deBouncer
function debounce(fn, ms) {
  let timer;
  return (_) => {
    clearTimeout(timer);
    timer = setTimeout((_) => {
      timer = null;
      fn.apply(this, arguments);
    }, ms);
  };
}

const resultStateEnums = [
  { value: "NONE", label: "Report Running" },
  { value: "REPORT_READY", label: "Completed" },
  { value: "REFERENCE_REPORT_READY", label: "Completed (Reference)" },
  { value: "REPORT_READY_INCOMPLETE", label: "Incomplete" },
  { value: "TIMED_OUT", label: "Timed Out" },
  { value: "CONNECTION_FAILURE", label: "Connection Failure" },
  { value: "CANCELLED", label: "Cancelled" },
  { value: "INVALID_RULE_MAPPING", label: "Invalid Rule Mapping" },
  { value: "INGRESS_SOURCE_NOT_FOUND", label: "Waiting for New File" },
  { value: "GENERIC_FAILURE", label: "Failed" },
];

function showStateIcon(dataScore, thresholdScore, resultState) {
  switch (resultState) {
    case "NONE":
    case "CANCELLED":
    case "INVALID_RULE_MAPPING":
    case "INGRESS_SOURCE_NOT_FOUND":
    case "GENERIC_FAILURE":
    case "CONNECTION_FAILURE":
    case "TIMED_OUT":
    case "REPORT_READY_INCOMPLETE":
      return (
        <div style={{ display: "flex" }}>
          <Indicators
            style={{
              color: "#fff",
              background: "#f87e7e",
              padding: ".2rem",
              borderRadius: "4px",
              paddingLeft: ".4rem",
              paddingRight: ".4rem",
              fontSize: ".875rem",
            }}
            title="Failure"
          >
            <div
              style={{
                marginRight: ".2rem",
                display: "flex",
                alignItems: "center",
              }}
            >
              <MdCancel />
            </div>
            {resultStateEnums.find((resEnum) => resEnum.value === resultState)
              ?.label ?? "No Run Available"}
          </Indicators>
          {dataScore !== null ? (
            <Indicators
              style={{
                marginLeft: ".5rem",
                color: thresholdScore > dataScore ? "#333" : "#274B86",
                background: thresholdScore > dataScore ? "#f87e7e" : "#d2dcee",
                padding: "0.2rem 0.4rem",
                borderRadius: "4px",
                border: `1px solid ${
                  thresholdScore > dataScore ? "#333" : "#274B86"
                }`,
                fontSize: "0.875rem",
              }}
              title={"score"}
            >
              Score: {dataScore}
            </Indicators>
          ) : null}
        </div>
      );

    case "REPORT_READY":
      return (
        <div style={{ display: "flex" }}>
          <Indicators
            style={{
              color: "#349004",
              background: "#dfedda none repeat scroll 0% 0%",
              padding: " 0.2rem 0.4rem",
              borderRadius: "4px",
              border: "1px solid #349004",
              fontSize: ".875rem",
            }}
            title="Success"
          >
            <div
              style={{
                marginRight: ".2rem",
                display: "flex",
                alignItems: "center",
              }}
            >
              <MdCheckCircle />
            </div>

            {resultStateEnums.find((resEnum) => resEnum.value === resultState)
              ?.label ?? null}
          </Indicators>

          {dataScore !== null ? (
            <Indicators
              style={{
                marginLeft: ".5rem",
                color: thresholdScore > dataScore ? "#333" : "#274B86",
                background: thresholdScore > dataScore ? "#f87e7e" : "#d2dcee",
                padding: "0.2rem 0.4rem",
                borderRadius: "4px",
                border: `1px solid ${
                  thresholdScore > dataScore ? "#333" : "#274B86"
                }`,
                fontSize: "0.875rem",
              }}
              title={"score"}
            >
              Score: {dataScore}
            </Indicators>
          ) : null}
        </div>
      );
    default:
      return null;
  }
}

const DataSource = ({
  sources,
  itemWidth,
  isLastSource,
  dataScore,
  resultState,
  thresholdScore,
  ds,
}) => {
  return (
    <DataSourceContainer
      isLastSource={isLastSource}
      itemWidth={itemWidth}
      sourceCount={sources?.length}
    >
      <DataSourceWrapper itemWidth={itemWidth}>
        <DataSourceHeading>
          <FieldLabel>Data Source</FieldLabel>

          <ThresholdContainer>
            Min. DQ Score: {thresholdScore}
          </ThresholdContainer>
        </DataSourceHeading>
        <div style={{ display: "flex", alignItems: "center" }}>
          <div style={{ marginRight: "1rem" }}>
            <div>{ds.label}</div>

            <div
              style={{
                display: "flex",
                alignItems: "center",
                fontSize: "0.8125rem",
                margin: ".5rem",
              }}
            >
              ETL Parameter Name:&nbsp; <Copy>{ds?.etlParameterName}</Copy>
            </div>
          </div>
          <div style={{ marginLeft: "auto" }}>
            {showStateIcon(dataScore, thresholdScore, resultState)}
          </div>
        </div>
      </DataSourceWrapper>
    </DataSourceContainer>
  );
};

const DataSources = ({ sources }) => {
  const itemRef = useRef(null);
  const [itemWidth, setItemWidth] = useState(-1);

  useEffect(() => {
    // Handler to call on window resize
    const debouncedHandleResize = debounce(function handleResize() {
      setItemWidth(itemRef.current.offsetWidth);
    }, 300);

    function handleResize() {
      // Set window width/height to state
      setItemWidth(itemRef.current.offsetWidth);
    }

    // Add event listener
    window.addEventListener("resize", debouncedHandleResize);

    // Call handler right away so state gets updated with initial window size
    handleResize();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", debouncedHandleResize);
  }, [itemRef]); // Empty array ensures that effect is only run handleResize mount

  return (
    <FormContainers
      ref={itemRef}
      itemWidth={itemWidth}
      sourceCount={sources?.length}
    >
      {sources.map((ds, i) => {
        const isLastSource = sources?.length === i + 1;
        const dataScore = ds?.dataScore;
        const resultState = ds?.resultState;
        const thresholdScore = ds?.dataQualityThreshold;

        return (
          <DataSource
            key={`dataSourceContainer-${i}-${ds?.id?.value}`}
            ds={ds}
            sources={sources}
            itemWidth={itemWidth}
            isLastSource={isLastSource}
            dataScore={dataScore}
            resultState={resultState}
            thresholdScore={thresholdScore}
          />
        );
      })}
    </FormContainers>
  );
};

export default DataSources;
