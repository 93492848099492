// Export The Audit Log
export const exportAuditLogMutation = /* GraphQL */ `
  mutation ($startTime: DateTime, $endTime: DateTime, $userFilter: Int) {
    exportAuditLog(
      startTime: $startTime
      endTime: $endTime
      userFilter: $userFilter
    )
  }
`;

// Export The Permissions Audit Log
export const exportPermissions = /* GraphQL */ `
  mutation () {
    exportPermissions()
  }
`;
