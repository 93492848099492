import React, { useContext } from "react";
import styled from "styled-components/macro";
import { AuthContext } from "../../contexts/AuthContext";
import { NavContext } from "../Layout/NavStateProvider";

const Container = styled.div`
  background: ${(props) => props.theme.background};
  display: flex;
  body.home-page & {
    background: transparent;
  }
`;

const MainContainer = (props) => {
  const { user } = useContext(AuthContext);
  const { menuState } = useContext(NavContext);
  return (
    <Container menuState={menuState} hasUser={user}>
      {props.children}
    </Container>
  );
};

export default MainContainer;
