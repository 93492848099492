import React from "react";
import { MdClose, MdArrowDownward, MdAdd } from "react-icons/md";
import RenderDependentPipelines from "./RenderDependentPipelines";
import DataSources from "./DataSources";
import { StyledSelect } from "../../../Form/FormControls";
import {
  PipelineContainer,
  SegmentContainer,
  SegmentAddControlContainer,
  SegmentAdd,
  Segment,
  SegmentHeader,
  SegmentBody,
  DataSourceContainer,
  DataSourceHeading,
  FieldLabel,
  ThresholdContainer,
  DataSourceWrapper,
  SegmentAddRight,
  SegmentAddControlContainerBottom,
  SegmentAddBottom,
} from "./CreateFormStyles";

const RenderSegment = ({
  dependencies,
  segment,
  dispatch,
  isLast,
  instanceNames,
  stateSources,
}) => {
  //Find If Segment is Parent of segment
  const foundDependent = dependencies.find(
    (x) => x.dependentPipelines.filter((dp) => dp === segment?.uid).length
  );

  return (
    <>
      {segment?.dependentPipelines?.length ? (
        <PipelineContainer>
          <RenderDependentPipelines
            dependencies={dependencies}
            pipelines={segment.dependentPipelines}
            dispatch={dispatch}
            instanceNames={instanceNames}
            stateSources={stateSources}
          />
        </PipelineContainer>
      ) : null}

      <SegmentContainer>
        <SegmentAddControlContainer>
          <SegmentAdd
            onClick={() =>
              dispatch({
                type: "ADD_SEGMENT_PARENT",
                payload: segment,
              })
            }
          >
            <MdAdd />
          </SegmentAdd>
        </SegmentAddControlContainer>
        <Segment>
          <SegmentHeader>
            <div style={{ flex: 1 }}></div>
            {dependencies?.length === 1 ||
            (segment?.dependentPipelines?.length > 1 &&
              !foundDependent) ? null : (
              <div
                title={"Remove Segment"}
                style={{ marginLeft: "auto", cursor: "pointer" }}
                onClick={() =>
                  dispatch({
                    type: "REMOVE_SEGMENT",
                    payload: segment,
                  })
                }
              >
                <MdClose />
              </div>
            )}
          </SegmentHeader>
          <SegmentBody>
            {segment?.dependentDataSources?.length ? (
              <DataSources
                sources={segment?.dependentDataSources}
                dispatch={dispatch}
                segment={segment}
                stateSources={stateSources}
              />
            ) : (
              <>
                <DataSourceContainer>
                  <DataSourceWrapper>
                    <DataSourceHeading>
                      <FieldLabel>Data Source</FieldLabel>
                      <ThresholdContainer>Min. DQ Score:</ThresholdContainer>
                    </DataSourceHeading>
                    <StyledSelect
                      className={`react-select-container`}
                      classNamePrefix={`react-select`}
                      name={`datasources`}
                      id={`datasources`}
                      inputId={`datasourcesSelect-input`}
                      instanceId={`datasourcesSelect-instance`}
                      label="Data Source"
                      options={stateSources}
                      placeholder={`Select Data Source`}
                      menuPortalTarget={document.body}
                      value={null}
                      menuPlacement="auto"
                      onChange={(e) =>
                        dispatch({
                          type: "SELECT_DATA_SOURCE",
                          payload: {
                            pipeline: segment,
                            dataSource: e,
                            sourceIndex: 0,
                          },
                        })
                      }
                    />
                  </DataSourceWrapper>
                </DataSourceContainer>
              </>
            )}
            <div
              style={{
                padding: "0",
                display: "flex",
                justifyContent: "center",
                fontSize: "1.25rem",
              }}
            >
              <MdArrowDownward />
            </div>
            <div style={{ padding: ".4rem" }}>
              <DataSourceWrapper>
                <DataSourceHeading>
                  <FieldLabel>ETL</FieldLabel>
                </DataSourceHeading>
                <StyledSelect
                  className={`react-select-container`}
                  classNamePrefix={`react-select`}
                  name={`etl-${segment?.id}`}
                  id={`etl-${segment?.id}`}
                  inputId={`etl-${segment?.id}Select-input`}
                  instanceId={`etl-${segment?.id}Select-instance`}
                  label="ETL"
                  options={instanceNames.map((instanceName) => {
                    return {
                      ...instanceName,
                      isDisabled:
                        dependencies?.find(
                          (d) => d?.name?.value === instanceName?.value
                        ) ?? false,
                    };
                  })}
                  placeholder={`Select ETL`}
                  menuPortalTarget={document.body}
                  value={segment?.name}
                  menuPlacement="auto"
                  onChange={(e) =>
                    dispatch({
                      type: "SELECT_ETL",
                      payload: { pipeline: segment, etl: e },
                    })
                  }
                />
              </DataSourceWrapper>
            </div>
          </SegmentBody>

          {isLast ? (
            <SegmentAddRight
              onClick={() =>
                dispatch({
                  type: "ADD_LINK_SEGMENT",
                  payload: segment,
                })
              }
            >
              <MdAdd />
            </SegmentAddRight>
          ) : null}
        </Segment>
        <SegmentAddControlContainerBottom>
          <SegmentAddBottom
            onClick={() =>
              dispatch({
                type: "ADD_SEGMENT_CHILD",
                payload: segment,
              })
            }
          >
            <MdAdd />
          </SegmentAddBottom>
        </SegmentAddControlContainerBottom>
      </SegmentContainer>
    </>
  );
};

export default RenderSegment;
