import { useContext } from "react";
import { SocketContext } from "../contexts/useSubscriptions";

const UseConnectionImportFinished = () => {
  const { connectionImportFinished, setConnectionImportFinished } =
    useContext(SocketContext);

  return { connectionImportFinished, setConnectionImportFinished };
};

export default UseConnectionImportFinished;
