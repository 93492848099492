import React, { useEffect, useState } from "react";
import { useApi } from "../../api/useApi";
import { tagsList } from "../../api/tagQueries";
import { Label, StyledSelect } from "../Form/FormControls";
import styled from "styled-components/macro";
import Modal from "../Modal";
import CreateTag from "./CreateTag";

const CreateNewButton = styled.div`
  margin-bottom: 0.5em;
  display: block;
  font-size: 0.8em;
  font-family: "Source Sans Pro Semibold";
  cursor: pointer;
  margin-left: auto;
  &:hover {
    opacity: 0.5;
  }
  color: ${(props) => props.theme.onSecondarySurface};
`;

const formatTransformOptionLabel = (options, { context }) => {
  const isValue = context === "value";
  return (
    <div style={{ display: "flex", flexDirection: "column", padding: ".5rem" }}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          fontSize: "1.1rem",
          marginBottom: isValue ? "" : ".3rem",
        }}
      >
        {options?.label}
      </div>
      {options?.tag?.description && !isValue && (
        <div
          style={{
            opacity: 0.5,
            fontSize: "1rem",
            position: "relative",
            display: "block",
            maxWidth: "100%",
            whiteSpace: "normal",
          }}
        >
          {options?.tag?.description}
        </div>
      )}
    </div>
  );
};

const SelectTags = ({
  currentTags = [],
  updateTags,
  fieldName,
  tagType = "DATA_SOURCE",
  loadingIndicator,
  dispatchType,
  variables,
}) => {
  //Available Users Via Graph
  const [{ loading, errors, data }, refreshTags] = useApi(tagsList, variables);
  const [selectedTags, setSelectedTags] = useState([]);

  useEffect(() => {
    if (currentTags && currentTags?.length) {
      const tags = currentTags.map((ct) => {
        const createTagOption = {
          label: ct?.tag?.name,
          value: ct?.tagId,
          ...ct,
        };

        return createTagOption;
      });
      setSelectedTags(tags);
    }
  }, [currentTags]);

  const [showCreateModal, setShowCreateModal] = useState(false);
  const allTags = data?.allTags ?? [];

  const setTags = React.useCallback(
    (values) => {
      setSelectedTags(values);
    },
    [setSelectedTags]
  );

  useEffect(() => {
    if (updateTags && selectedTags) {
      function setTagsObj(tags) {
        const builtTagsObj = tags.map((t) => {
          return {
            name: t?.tag?.name,
            tagId: t?.tag?.id,
            type: tagType,
          };
        });

        return builtTagsObj ?? [];
      }

      //Dealing with Formik Updates
      if (updateTags && fieldName) {
        updateTags(fieldName, setTagsObj(selectedTags));
      } else if (updateTags && dispatchType) {
        updateTags({ type: dispatchType, payload: setTagsObj(selectedTags) });
      } else {
        updateTags(setTagsObj(selectedTags));
      }
    }
  }, [selectedTags, updateTags, fieldName, tagType, dispatchType]);

  const createdNewTag = React.useCallback(
    (tag) => {
      refreshTags({
        query: tagsList,
      });
      const createTagOption = {
        label: tag?.name,
        value: tag?.id,
        tag: { ...tag },
      };

      setSelectedTags((preTags) => {
        let clonedTags = [...preTags, createTagOption];
        return clonedTags;
      });
      setShowCreateModal(null);
    },
    [refreshTags]
  );

  const tagOptions = allTags.map((tag) => {
    return {
      label: tag?.name,
      value: tag?.id,
      tag: { ...tag },
    };
  });

  return (
    <>
      {showCreateModal ? (
        <Modal title={`Create New Tag`} hide={() => setShowCreateModal(null)}>
          <div>
            <CreateTag
              isInModal={true}
              modalAction={createdNewTag}
              modalClose={() => setShowCreateModal(null)}
            />
          </div>
        </Modal>
      ) : null}

      <div style={{ display: "flex", alignItems: "center" }}>
        <Label>Tags</Label>
        <CreateNewButton onClick={() => setShowCreateModal(true)}>
          Create New Tag
        </CreateNewButton>
      </div>

      <StyledSelect
        className={`react-select-container`}
        classNamePrefix={`react-select`}
        name={`Tags`}
        id={`Tags`}
        inputId={`Tags-TagsSelect-input`}
        instanceId={`Tags-TagsSelect-instance`}
        menuPortalTarget={document.body}
        placeholder={
          loading || loadingIndicator
            ? `Loading Tags`
            : errors
            ? "Error loading Tags"
            : `Select Tags`
        }
        label="Select Tags"
        value={selectedTags}
        isMulti={true}
        options={tagOptions}
        formatOptionLabel={formatTransformOptionLabel}
        onChange={(e) => setTags(e)}
      />
    </>
  );
};
export default SelectTags;
