function expand(str, val = {}) {
  return str.split(".").reduceRight((acc, currentValue) => {
    return { [currentValue]: acc };
  }, val);
}

export default function getSortedObject(sortBy) {
  const sortedObject = sortBy?.[0]
    ? expand(sortBy[0]?.id, sortBy[0]?.desc ? "DESC" : "ASC")
    : null;
  return sortedObject;
}
