import update from "immutability-helper";
import { v4 as uuid } from "uuid";
import { format } from "date-fns";

const CreateReducer = (state, action) => {
  switch (action.type) {
    //First State called when a query is passed to the component.
    default:
      return {
        ...state,
      };

    case "ADD_SEGMENT_PARENT": {
      //Add a parent to bottom segment

      //Create new ID
      const newID = uuid();

      //New Segment
      let newSegment = {
        name: null,
        dependentDataSources: [],
        dependentPipelines: [],
        uid: newID,
      };

      //Clone Array
      const clone = [...state?.dependencies];

      // Get Current Segment
      const currentSegment = action?.payload;

      // Need to know parent, and child to update deps

      // Look at the PARENTS of Selected, Add New Segment as Child

      const updatedArray = clone.map((p, i) => {
        const findCurrentIndex = currentSegment.dependentPipelines.indexOf(
          p.uid
        );

        if (p.uid === currentSegment.uid) {
          return { ...p, dependentPipelines: [newID] };
        } else if (findCurrentIndex !== -1) {
          newSegment.dependentPipelines.push(p.uid);
          return p;
        } else {
          return p;
        }
      });

      updatedArray.push(newSegment);

      return {
        ...state,
        dependencies: updatedArray,
      };
    }

    case "ADD_SEGMENT_CHILD": {
      //Add a child to bottom segment

      //Create new ID
      const newID = uuid();

      //Clone Array
      const clone = [...state?.dependencies];

      // Get Current Segment
      const currentSegment = action?.payload;

      //New Segment
      let newSegment = {
        name: null,
        dependentDataSources: [],
        dependentPipelines: [],
        uid: newID,
        isTermination: currentSegment?.isTermination,
      };

      // Need to know parent, and child to update deps

      const updatedArray = clone.map((p, i) => {
        //is the current segment found within the looped dep pipeline
        const findCurrentIndex = p.dependentPipelines.indexOf(
          currentSegment.uid
        );

        //if the selected segment set new dep to have the current seg uid as a dep
        if (p.uid === currentSegment.uid) {
          newSegment.dependentPipelines = [p.uid];

          return { ...p, isTermination: false };
        } else if (findCurrentIndex !== -1) {
          // if looped segment has the current segment as a dep, change the current
          // dep to be the newly created uui for new segment
          let newPipelines = [...p?.dependentPipelines];

          //Replace UID with new UID at index
          newPipelines[findCurrentIndex] = newID;

          return { ...p, dependentPipelines: newPipelines };
        } else {
          return p;
        }
      });

      //push new segment into array since order beyond [0] doesn't matter
      updatedArray.push(newSegment);

      return {
        ...state,
        dependencies: updatedArray,
      };
    }

    case "ADD_LINK_SEGMENT": {
      //Add a Link to side segment

      //Create new ID
      const newID = uuid();

      //Clone Array
      const clone = [...state?.dependencies];

      // Get Current Segment
      const currentSegment = action?.payload;

      //New Segment
      let newSegment = {
        name: null,
        dependentDataSources: [],
        dependentPipelines: [],
        uid: newID,
        isTermination: false,
      };

      // Need to know parent, and child to update deps

      const updatedArray = clone.map((p, i) => {
        //is the current segment found within the looped dep pipeline
        const findCurrentIndex = p.dependentPipelines.indexOf(
          currentSegment.uid
        );

        if (findCurrentIndex !== -1) {
          // if looped segment has the current segment as a dep, change the current
          // dep to be the newly created uui for new segment add new id to array
          let newPipelines = [...p?.dependentPipelines, newID];

          return { ...p, dependentPipelines: newPipelines };
        } else {
          return p;
        }
      });

      //push new segment into array since order beyond [0] doesn't matter
      updatedArray.push(newSegment);

      return {
        ...state,
        dependencies: updatedArray,
      };
    }

    case "REMOVE_SEGMENT": {
      //Add a parent to bottom segment

      //Clone Array
      const clone = [...state?.dependencies];

      // Get Current Segment
      const currentSegment = action?.payload;

      const isTermination = currentSegment?.isTermination;

      // IF SEGMENT WHEN LOOPED IS FOUND TO BE IN THE
      // SELECTED SEGMENTS DEPENDPIPELINE WE GIVE IT TERMINATION

      //FIND SEGMENT THAT DEPENDS ON SELECTED

      //TAKE SELECTED DEPENDENCY ARRAY, ADD TO SEGMENT THAT DEPENDED ON SELECTED

      //REMOVE SELECTED FROM ANY AND ALL SEGMENTS DEPENDENT

      const updatedArray = clone.map((p, i) => {
        //Find Segments that depend on selected.

        //is the current segment found within the looped dep pipeline
        const findCurrentIndex = p.dependentPipelines.indexOf(
          currentSegment.uid
        );
        //is Found in the Selected Segments dep pipelines
        const foundAsParent = currentSegment.dependentPipelines.indexOf(p.uid);

        if (findCurrentIndex !== -1) {
          // if looped segment has the current segment as a dep, change the current
          // dep to be the newly created uuid for new segment
          let newPipelines = [
            ...p?.dependentPipelines,
            ...currentSegment.dependentPipelines,
          ];
          newPipelines = newPipelines.filter((_, i) => i !== findCurrentIndex);

          return {
            ...p,
            dependentPipelines: newPipelines,
          };
        } else if (foundAsParent !== -1) {
          return {
            ...p,
            isTermination: isTermination ? true : false,
          };
        } else {
          return p;
        }
      });

      const cleanArray = updatedArray.filter(
        (segment) => segment.uid !== currentSegment.uid
      );

      return {
        ...state,
        dependencies: cleanArray,
      };
    }

    case "SELECT_DATA_SOURCE": {
      // Find Current Segment
      const currentSegment = action?.payload?.pipeline;

      // Current Index of Pipeline
      const findCurrentIndex = state?.dependencies.findIndex(
        (dp) => dp.uid === currentSegment?.uid
      );

      // Find The Index of the dataSource within the pipeline
      const sourceIndex = action?.payload?.sourceIndex;

      //Update Deps based on pipeline index and dataSource index
      return {
        ...state,
        dependencies: update(state?.dependencies, {
          [findCurrentIndex]: {
            dependentDataSources: {
              [sourceIndex]: { $set: action?.payload?.dataSource },
            },
          },
        }),
      };
    }

    case "SELECT_ETL": {
      // Find Current Segment
      const currentSegment = action?.payload?.pipeline;

      // Current Index of Pipeline
      const findCurrentIndex = state?.dependencies.findIndex(
        (dp) => dp.uid === currentSegment?.uid
      );

      //Update Deps based on pipeline index with ETL Id object
      return {
        ...state,
        dependencies: update(state?.dependencies, {
          [findCurrentIndex]: {
            name: { $set: action?.payload?.etl },
          },
        }),
      };
    }

    case "SET_NEW_ETL": {
      // Find Current Segment
      const currentSegment = action?.payload?.segment;

      // Current Index of Pipeline
      const findCurrentIndex = state?.dependencies.findIndex(
        (dp) => dp.uid === currentSegment?.uid
      );

      //Update Deps based on pipeline index with ETL Id object
      return {
        ...state,
        addNewPipeline: false,
        dependencies: update(state?.dependencies, {
          [findCurrentIndex]: {
            name: { $set: action?.payload?.newPipelineName },
          },
        }),
      };
    }

    case "REMOVE_DATA_SOURCE": {
      // Find Current Segment
      const currentSegment = action?.payload?.pipeline;

      const isTermination = currentSegment?.isTermination;
      const sourceLength = currentSegment?.dependentDataSources?.length;

      // Current Index of Pipeline
      const findCurrentIndex = state?.dependencies.findIndex(
        (dp) => dp.uid === currentSegment?.uid
      );

      // Find The Index of the dataSource within the pipeline
      const sourceIndex = action?.payload?.sourceIndex;

      if (isTermination && sourceLength === 1) {
        //Update Deps based on pipeline index and remove dataSource index
        return {
          ...state,
          dependencies: update(state?.dependencies, {
            [findCurrentIndex]: {
              dependentDataSources: { $set: [] },
            },
          }),
        };
      } else {
        //Update Deps based on pipeline index and remove dataSource index
        return {
          ...state,
          dependencies: update(state?.dependencies, {
            [findCurrentIndex]: {
              dependentDataSources: { $splice: [[sourceIndex, 1]] },
            },
          }),
        };
      }
    }

    case "ADD_DATA_SOURCE": {
      // Find Current Segment
      const currentSegment = action?.payload?.pipeline;

      // Current Index of Pipeline
      const findCurrentIndex = state?.dependencies.findIndex(
        (dp) => dp.uid === currentSegment?.uid
      );

      const newDataSource = {
        label: "",
        value: "",
        dataQualityThreshold: "",
      };

      //Update Deps based on pipeline index and add dataSource
      return {
        ...state,
        dependencies: update(state?.dependencies, {
          [findCurrentIndex]: {
            dependentDataSources: {
              $push: [newDataSource],
            },
          },
        }),
      };
    }

    case "UPDATE_FIELD": {
      const fieldName = action?.payload?.field;
      const fieldValue = action?.payload?.value;

      return {
        ...state,
        [fieldName]: fieldValue,
      };
    }

    case "SET_TAGS": {
      return {
        ...state,
        tagInstanceInputs: action?.payload,
      };
    }

    case "SET_AVAILABLE_PIPELINES": {
      return {
        ...state,
        availablePipelines: action?.payload?.pipelines,
      };
    }

    case "TOGGLE_CHANGE_DATASOURCE_THRESHOLD": {
      return {
        ...state,
        showChangeThreshold: action?.payload ?? false,
      };
    }

    case "TOGGLE_ADD_NEW_PIPELINE": {
      return {
        ...state,
        addNewPipeline: action?.payload ?? false,
      };
    }

    case "UPDATE_DATASOURCE_THRESHOLD": {
      const currentDataSource = action?.payload?.dataSource;

      const cleanDeps = state?.dependencies.map((dep) => {
        return {
          ...dep,
          dependentDataSources: dep.dependentDataSources.map((dd) => {
            if (dd?.value === currentDataSource?.value) {
              return {
                ...dd,
                dataQualityThreshold: Number(action?.payload?.newThreshold),
              };
            } else {
              return dd;
            }
          }),
        };
      });

      return {
        ...state,
        dependencies: cleanDeps,
        showChangeThreshold: false,
      };
    }

    case "ENTRY": {
      const name = action?.payload?.name;
      const provider = action?.payload?.provider;

      return {
        ...state,
        workflowName: name,
        etlProviderInstanceId: provider,
      };
    }

    case "UPDATE_PROVIDER": {
      const provider = action?.payload?.provider;

      const cleanDeps = state?.dependencies.map((dep) => {
        return {
          ...dep,
          name: "",
        };
      });

      return {
        ...state,
        dependencies: cleanDeps,
        etlProviderInstanceId: provider,
      };
    }

    case "RESET_FORM": {
      const getZone = format(new Date(), "XXX", {
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      });

      //initialize the reducer state
      const initialState = {
        showChangeThreshold: false,
        addNewPipeline: false,
        etlProviderInstanceId: null,
        workflowName: "",
        zone: getZone,
        hour: { value: "1", label: "1" },
        min: { value: "0", label: ":00" },
        isAm: { value: false, label: "PM" },
        dependencies: [
          {
            name: "",
            dependentDataSources: [],
            dependentPipelines: [],
            isTermination: true,
            uid: uuid(),
          },
        ],
      };

      return {
        ...initialState,
      };
    }
  }
};

export default CreateReducer;
