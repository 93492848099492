import { useContext } from "react";
import { SocketContext } from "../contexts/useSubscriptions";

const UseSetOcrRateLimitNotification = () => {
  const { workflowPipelineFailEvent, setSetWorkflowPipelineFailEvent } =
    useContext(SocketContext);

  return { workflowPipelineFailEvent, setSetWorkflowPipelineFailEvent };
};

export default UseSetOcrRateLimitNotification;
