import React from "react";
import { useTable, useSortBy, usePagination, useFilters } from "react-table";
import {
  MdNavigateNext,
  MdNavigateBefore,
  MdLastPage,
  MdFirstPage,
  MdExpandMore,
  MdExpandLess,
} from "react-icons/md";
import {
  TableElement,
  Td,
  Th,
  ThSticky,
  Tr,
  PageButton,
  TableLoading,
  Thead,
  TBody,
} from "./elements";
import SplashLoader from "../Loaders/SplashLoader";
import { StyledInput } from "../Form/FormControls";

// Create a default prop getter
const defaultPropGetter = () => ({});

function Table({
  columns,
  data,
  loading,
  getRowProps = defaultPropGetter,
  dontReset,
  maxHeight,
  defaultPageSize,
  hidePagination,
  removeOverflow,
  noScroll,
}) {
  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  );
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page
    //Page information
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      defaultColumn,
      autoResetPage: dontReset ? false : true,
      data,
      disableSortRemove: true,
      initialState: { pageIndex: 0, pageSize: defaultPageSize },
    },
    useFilters,
    useSortBy,
    usePagination
  );

  return (
    <>
      <div>
        <div
          style={{
            position: "relative",
            maxHeight: noScroll ? "none" : "70vh",
            overflow: removeOverflow ? "inherit" : "auto",
          }}
        >
          <TableElement {...getTableProps()}>
            <Thead>
              {headerGroups.map((headerGroup, i) => (
                <Tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => {
                    const isActions = column?.id === "actions";
                    const ColumnSelector = isActions ? ThSticky : Th;
                    return (
                      // Add the sorting props to control sorting. For this example
                      // we can add them into the header props
                      <ColumnSelector
                        {...column.getHeaderProps(
                          column.getSortByToggleProps()
                        )}
                        right={isActions ? `0` : null}
                      >
                        {/* Sort With Indicator */}
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <div style={{ marginRight: "auto" }}>
                            {column.render("Header")}
                            {column.canFilter ? (
                              <div>{column.render("Filter")} </div>
                            ) : null}
                          </div>
                          {column.isSorted ? (
                            <div style={{ fontSize: "1.5rem" }}>
                              {column.isSortedDesc ? (
                                <MdExpandMore />
                              ) : (
                                <MdExpandLess />
                              )}
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </ColumnSelector>
                    );
                  })}
                </Tr>
              ))}
            </Thead>
            <TBody {...getTableBodyProps()}>
              {page.map((row, i) => {
                prepareRow(row);
                return (
                  <Tr {...row.getRowProps(getRowProps(row))}>
                    {row.cells.map((cell, i) => {
                      const isActions = cell.column?.id === "actions";
                      return (
                        <Td
                          {...cell.getCellProps()}
                          right={isActions ? `0` : null}
                          isOdd={i % 2 !== 1}
                        >
                          {cell.render("Cell")}
                        </Td>
                      );
                    })}
                  </Tr>
                );
              })}
            </TBody>
          </TableElement>
          {loading ? (
            <TableLoading>
              <SplashLoader text="Loading Results" />
            </TableLoading>
          ) : null}
        </div>

        {!hidePagination ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <>
              {!loading && page && !page.length ? <div>No Results</div> : null}
            </>
            <div style={{ marginLeft: "auto", marginRight: "1rem" }}>
              <span>
                Page{" "}
                <strong>
                  {pageIndex + 1} of {pageOptions.length}
                </strong>{" "}
              </span>
            </div>
            <div style={{ marginRight: "1rem" }}>
              <select
                value={pageSize}
                onChange={(e) => {
                  setPageSize(Number(e.target.value));
                }}
              >
                {[10, 25, 50].map((pageSize) => (
                  <option key={pageSize} value={pageSize}>
                    Show {pageSize}
                  </option>
                ))}
              </select>
            </div>
            <div style={{ marginRight: "1rem" }}>
              <PageButton
                type="button"
                onClick={() => gotoPage(0)}
                disabled={!canPreviousPage}
              >
                <MdFirstPage />
              </PageButton>{" "}
              <PageButton
                type="button"
                onClick={() => previousPage()}
                disabled={!canPreviousPage}
              >
                <MdNavigateBefore />
              </PageButton>{" "}
              <PageButton
                type="button"
                onClick={() => nextPage()}
                disabled={!canNextPage}
              >
                <MdNavigateNext />
              </PageButton>{" "}
              <PageButton
                type="button"
                onClick={() => gotoPage(pageCount - 1)}
                disabled={!canNextPage}
              >
                <MdLastPage />
              </PageButton>
            </div>
          </div>
        ) : null}
      </div>
    </>
  );
}

// Define a default UI for filtering
function DefaultColumnFilter({
  column: { filterValue, preFilteredRows, setFilter, Header },
}) {
  return (
    <StyledInput
      type="text"
      value={filterValue || ""}
      placeholder={`Filter by ${Header}`}
      onClick={(e) => {
        e.stopPropagation();
      }}
      onChange={(e) => {
        setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
      }}
    />
  );
}

const SortTable = ({
  data,
  columns,
  loading,
  getRowProps,
  dontReset,
  defaultPageSize = 50,
  hidePagination,
  removeOverflow,
  noScroll,
}) => {
  return (
    <Table
      noScroll={noScroll}
      columns={columns}
      dontReset={dontReset}
      data={data && data.length ? data : []}
      loading={loading}
      getRowProps={getRowProps}
      hidePagination={hidePagination}
      defaultPageSize={
        hidePagination && data?.length ? data?.length : defaultPageSize
      }
      removeOverflow={removeOverflow}
    />
  );
};

export default SortTable;
