import { Link } from "react-router-dom";
import React from "react";
import styled from "styled-components/macro";

const StyledLink = styled(Link)`
  text-decoration: none;
  transition: all ${(props) => props.theme.transitionSpeed} linear;
  font-family: "Source Sans Pro Semibold";
  color: ${(props) => props.theme.linkPrimary};
  &:visited {
    color: ${(props) => props.theme.linkSecondary};
  }
  &:hover {
    text-decoration: underline;
    color: ${(props) => props.theme.linkPrimary};
  }
  &:active {
    text-decoration: underline;
    color: ${(props) => props.theme.linkSecondary};
  }
  &:disabled {
    cursor: pointer;
    opacity: 0.5;
    color: ${(props) => props.theme.linkPrimary};
  }
`;

const StyledLinkComponent = (props) => {
  return <StyledLink {...props}>{props.children}</StyledLink>;
};

export default StyledLinkComponent;
