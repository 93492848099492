import { useContext } from "react";
import { SocketContext } from "../contexts/useSubscriptions";

const UseETLProviderBrowseNotification = () => {
  const { etlProviderBrowseCompleted, setEtlProviderBrowseCompleted } =
    useContext(SocketContext);

  return { etlProviderBrowseCompleted, setEtlProviderBrowseCompleted };
};
export default UseETLProviderBrowseNotification;
