import React from "react";
import styled, { withTheme } from "styled-components/macro";
import { PieChart, Pie, ResponsiveContainer, Cell } from "recharts";
import { findThresholdScore } from "../../common/thresholdScores";
import Spinner from "../../components/Loaders/Spinner";

const QualityScoreLabel = styled.div`
  position: absolute;
  width: 100%;
  text-align: center;
  top: calc(50% - 0.45rem);
  animation: label 1.5s ease-in;
`;

const ScoreContainer = styled.div`
  font-size: 1em;
  margin-bottom: 0.1em;
  color: ${(props) => props.theme.onSurface};
`;

const DataQualityScore = React.memo(({ data, loading, errors, theme }) => {
  if (loading) return <Spinner />;
  if (errors) return <div>Errors</div>;
  if (!data) return <div>No Data Available</div>;
  const { score } = data;

  function checkNumberValue(value) {
    return Number.isInteger(value) ? value : null;
  }

  const scoreValue = checkNumberValue(score);

  const pieData = [
    { name: "Quality Score", value: scoreValue },
    { name: "", value: 100 - scoreValue },
  ];

  const scoreObject = findThresholdScore(scoreValue, theme);

  return (
    <div
      style={{
        position: "relative",
        height: "45px",
        width: "45px",
        marginRight: ".5rem",
      }}
    >
      <div
        style={{
          position: "absolute",
          inset: 0,
          width: "45px",
        }}
      >
        <div style={{ width: "45px", height: 45, position: "relative" }}>
          <ResponsiveContainer>
            <PieChart>
              <Pie
                data={pieData}
                innerRadius={15}
                outerRadius={20}
                dataKey="value"
              >
                <Cell key={`cell-0`} stroke={0} fill={scoreObject.color} />
                <Cell key={`cell-1`} fill={theme.secondarySurface} stroke={0} />
              </Pie>
            </PieChart>
          </ResponsiveContainer>
          <QualityScoreLabel>
            <ScoreContainer>{scoreValue}</ScoreContainer>
          </QualityScoreLabel>
        </div>
      </div>
    </div>
  );
});

export default withTheme(DataQualityScore);
