import styled from "styled-components";
import { MdKeyboardArrowRight } from "react-icons/md";
import { AiOutlineRobot } from "react-icons/ai";
import { useContext } from "react";
import { AiSettingsContext } from "../../contexts/ai/AiSettingsContext";

const ToggleAiChatButtonContainer = styled.div`
  position: fixed;
  bottom: 10rem;
  right: 0rem;
  z-index: 999;
`;

const ToggleAiChatButton = styled.button`
  position: relative;
  top: 0px;
  left: 0px;
  background: #121135;
  width: 40px;

  cursor: pointer;
  backface-visibility: hidden;
  overflow: hidden;

  transform: translateY(-50%); /* Center the button vertically */

  height: 100px; /* Height of the button */

  color: white; /* Text color */
  border: none; /* No border */
  cursor: pointer; /* Cursor to pointer when hovering over the button */
  z-index: 1000; /* Ensure it's above other elements */
  border-top-left-radius: 10px; /* Rounded corners on the left side */
  border-bottom-left-radius: 10px; /* Rounded corners on the left side */
`;

const ToggleAiChatButtonIcon = styled.div`
  user-select: none;
  display: flex;
  align-items: center;
  position: absolute;
  top: 0px;
  bottom: 0px;
  width: 100%;
  transition: transform 0.16s linear 0s, opacity 0.08s linear 0s;
  opacity: 1;
  transform: rotate(0deg);
  color: #fff;
  font-size: 1.5rem;
  padding-left: 0.25rem;
`;

const AiChatToggle = ({ isVisible, toggle }) => {
  const { enabled } = useContext(AiSettingsContext);

  if (!enabled) {
    return;
  }
  return (
    <ToggleAiChatButtonContainer>
      <ToggleAiChatButton onClick={toggle}>
        <ToggleAiChatButtonIcon>
          {isVisible ? <MdKeyboardArrowRight /> : <AiOutlineRobot />}
        </ToggleAiChatButtonIcon>
      </ToggleAiChatButton>
    </ToggleAiChatButtonContainer>
  );
};

export default AiChatToggle;
