import React, { useEffect, useCallback } from "react";
import { FormActions } from "../../components/Form/FormControls";
import Button from "../../components/Button";
import useGraphApi from "../../api/useGraphApi";
import Spinner from "../../components/Loaders/Spinner";
import PermissionsSelect from "../../components/Form/PermissionsSelect";
import { Formik, Field, Form } from "formik";
import { setDataSourcePermissions } from "../../api/dataSourceMutations";
import { dataSourcePermissions } from "../../api/dataSourceQueries";
import { useApi } from "../../api/useApi";
import SplashLoader from "../../components/Loaders/SplashLoader";
import ErrorMessages from "../../components/Notifications/ErrorMessages";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const DataSourcePermissionsComponent = ({ sourceId }) => {
  const [
    {
      loading: loadingPermissions,
      errors: permissionErrors,
      data: permissionData,
    },
  ] = useApi(dataSourcePermissions, {
    id: Number(sourceId),
  });

  const apiData = permissionData?.dataSource;

  const { loading, apiData: data, doFetch } = useGraphApi();

  const permissions = (apiData && apiData.permissions) || [];

  const updatePermissions = useCallback(
    (values, setSubmitting) => {
      const variables = {
        id: Number(sourceId),
        permissions: values.permissions,
      };
      setSubmitting(true);
      doFetch(setDataSourcePermissions, variables);
    },
    [doFetch, sourceId]
  );

  useEffect(() => {
    if (data) {
      toast.success("Permissions Updated", {
        position: "top-right",
        autoClose: 2500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }, [data]);

  if (loadingPermissions) return <SplashLoader text={"Loading Permissions"} />;
  if (permissionErrors) return <ErrorMessages errors={permissionErrors} />;

  return (
    <div>
      <Formik
        enableReinitialize={true}
        initialValues={{
          permissions: permissions ? permissions : [],
        }}
        onSubmit={(values, { setSubmitting }) =>
          updatePermissions(values, setSubmitting)
        }
      >
        <Form>
          <Field name={`permissions`} component={PermissionsSelect} />
          <FormActions>
            <Button type="submit" disabled={loading}>
              {loading ? <Spinner /> : "Update Permissions"}
            </Button>
          </FormActions>
        </Form>
      </Formik>
      <ToastContainer />
    </div>
  );
};

export default DataSourcePermissionsComponent;
