import React, { useEffect, useState, useContext } from "react";
import {
  Formik,
  Form,
  Field,
  yupToFormErrors,
  validateYupSchema,
} from "formik";
import {
  FormControl,
  FormActions,
  StyledField,
  StyledSelect,
  Label,
  ErrorMessage,
  StyledTextArea,
  StyledInput,
} from "../../Form/FormControls";
import Button from "../../Button";
import Spinner from "../../Loaders/Spinner";
import ErrorMessages from "../../Notifications/ErrorMessages";
import { useApi } from "../../../api/useApi";
import {
  updateOcrConnection,
  startCustomVisionIterationTraining,
} from "../../../api/connectionMutations";
import * as Yup from "yup";
import {
  connectionsList,
  customVisionLabels,
} from "../../../api/connectionQueries";
import TableButton from "../../Button/TableButton";
import { MdDelete } from "react-icons/md";
import TransformEdit from "../../TableRowView/TransformEdit";
import SortExpandTableV2Filters from "../../Table/SortExpandTableV2Filters";
import { NavContext } from "../../Layout/NavStateProvider";
import { namingConventions } from "../../../common/formOptions";
import caseInsenSort from "../../Table/helpers/caseInsenSort";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Modal from "../../Modal";
import csvDownload from "json-to-csv-export";
import { AuthContext } from "../../../contexts/AuthContext";
import SpinningLoader from "../../Loaders/SpinningLoader";
import { sortByAlpha } from "../../../common/helpers/util";

const prebuiltModels = [
  {
    label: "Business Card",
    value: "BUSINESS_CARD",
  },
  {
    label: "Receipt",
    value: "RECEIPT",
  },
  {
    label: "Invoice",
    value: "INVOICE",
  },
  {
    label: "Id Document",
    value: "IDDOC",
  },
  {
    label: "Custom",
    value: "", //comes back from server as GUID
  },
];

const IdentificationTypes = [
  {
    label: "Identification",
    text: "NONE",
    value: 0,
  },
  {
    label: "Process",
    text: "IMAGE_CLASSIFICATION_MSFT_CUSTOM_VISION",
    value: 3,
  },
  // {
  //   label: "Text",
  //   text: "TEXT_CLASSIFICATION_COMPUTER_VISION_READ_API",
  //   value: 4,
  // },
  // {
  //   label: "Title Pages",
  //   text: "EMBEDDED_TEXT_DOCUMENT_MATCH",
  //   value: 5,
  // },
  {
    label: "Filename",
    text: "FILENAME_CONTAINS_MATCH",
    value: 8,
  },
  {
    label: "IgnoreOutput",
    text: "FILENAME_IGNORE",
    value: 16,
  },
  {
    label: "Candidate",
    text: "CONTAINS_CANDIDATES",
    value: 32,
  },
  {
    label: "LocatePageOnly",
    text: "LOCATE_PAGE_ONLY",
    value: 64,
  },
];

const TrainModelLabels = ({ providerOptions }) => {
  const [selectedProvider, setSelectedProvider] = useState();
  const [projectId, setProjectId] = useState();
  const [email, setEmail] = useState("");
  const [hours, setHours] = useState(4);
  const [selectedLabels, setSelectedLabels] = useState([]);
  // Fetch Labels
  const [{ errors, loading, data }, fetchLabels] = useApi();

  // Begin training
  const [
    { errors: errorsBegin, loading: loadingBegin, data: beginData },
    startTraining,
  ] = useApi();

  const sortedItems = data?.customVisionLabels?.length
    ? sortByAlpha(data?.customVisionLabels, "label")
    : [];

  const labelsOptions =
    sortedItems?.map((cvl) => {
      return {
        value: cvl?.label,
        label: cvl?.label,
      };
    }) ?? [];

  const getLabels = () => {
    fetchLabels({
      query: customVisionLabels,
      variables: {
        projectId: projectId,
        docProviderId: selectedProvider,
      },
    });
  };

  const beginTraining = () => {
    startTraining({
      query: startCustomVisionIterationTraining,
      variables: {
        projectId: projectId,
        hours: hours,
        notificationEmail: email,
        docProviderId: selectedProvider,
        selectedTags: selectedLabels?.map((sl) => sl?.label),
      },
    });
  };
  const reset = () => {
    setSelectedLabels([]);
  };

  useEffect(() => {
    setSelectedLabels([]);
  }, [selectedProvider]);

  useEffect(() => {
    if (beginData && !errorsBegin?.length) {
      toast.success("Training Started", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }, [beginData, errorsBegin]);

  return (
    <div>
      <div>
        <p>Training a new model</p>

        <div>
          <h4>Provide Project ID & Select Provider</h4>
          <p>Select Doc Provider to load labels.</p>

          <FormControl>
            <StyledInput
              type="text"
              name="projectId"
              label="Project ID"
              required={true}
              value={projectId}
              placeholder={`Project Id`}
              onChange={(e) => setProjectId(e.target.value)}
            />
          </FormControl>

          <FormControl>
            <StyledInput
              type="text"
              name="email"
              label="Notification Email"
              value={email}
              placeholder={`Notification Email`}
              onChange={(e) => setEmail(e.target.value)}
            />
          </FormControl>

          <FormControl>
            <StyledInput
              type="number"
              name="hours"
              label="Training Budget (hours)"
              value={hours}
              placeholder={`Training Budget (hours)`}
              onChange={(e) => setHours(Number(e.target.value))}
            />
          </FormControl>

          <FormControl>
            <Label>Document Provider</Label>
            <StyledSelect
              className={`react-select-container`}
              classNamePrefix={`react-select`}
              name={`docProviderId`}
              id={`docProviderId`}
              inputId={`docProviderId-input`}
              instanceId={`docProviderId-instance`}
              label="Document Provider"
              options={providerOptions}
              formatOptionLabel={formatProviderSelect}
              placeholder={`Select Document Provider`}
              value={providerOptions.find(
                (nc) => nc?.value === selectedProvider
              )}
              menuPortalTarget={document.body}
              menuPlacement="auto"
              onChange={(e) => setSelectedProvider(e?.value)}
            />
          </FormControl>

          <div
            style={{
              marginBottom: "1rem",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Button
              type="button"
              disabled={!selectedProvider || !projectId}
              onClick={() => getLabels()}
            >
              Load Labels
            </Button>
          </div>
        </div>

        <div>
          {labelsOptions?.length ? (
            <>
              {" "}
              <FormControl>
                <Label>Select Labels that you want for training.</Label>

                <StyledSelect
                  className={`react-select-container`}
                  classNamePrefix={`react-select`}
                  name={`Tags`}
                  id={`Tags`}
                  inputId={`Tags-input`}
                  instanceId={`Tags-instance`}
                  menuPortalTarget={document.body}
                  placeholder={"Labels"}
                  label="Select Tags"
                  value={selectedLabels}
                  isMulti={true}
                  options={labelsOptions}
                  onChange={(e) => setSelectedLabels(e)}
                  closeMenuOnSelect={false}
                />
              </FormControl>
              <div
                style={{
                  marginBottom: "1rem",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Button
                  type="button"
                  danger
                  list
                  disabled={!selectedLabels?.length}
                  onClick={() => reset()}
                >
                  Clear Labels
                </Button>
                <Button
                  type="button"
                  disabled={!selectedLabels?.length}
                  onClick={() => beginTraining()}
                >
                  {loadingBegin ? <Spinner /> : "Begin Training"}
                </Button>
              </div>
            </>
          ) : null}
        </div>
        {loading ? <SpinningLoader text="Loading Labels" /> : null}
        {errors ? <ErrorMessages errors={errors} /> : null}
        {errorsBegin ? <ErrorMessages errors={errorsBegin} /> : null}
      </div>
    </div>
  );
};

const formatProviderSelect = ({ label, docProviderType }) => {
  return (
    <div style={{ display: "flex", flexDirection: "column", padding: ".5rem" }}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          fontSize: "1.1rem",
          marginBottom: ".3rem",
        }}
      >
        {label}
      </div>
      {docProviderType && (
        <div
          style={{
            opacity: 0.5,
            fontSize: "1rem",
            position: "relative",
            display: "block",
            maxWidth: "100%",
            whiteSpace: "normal",
          }}
        >
          {docProviderType}
        </div>
      )}
    </div>
  );
};

const ImportContainer = ({ importMultiDocuments, setShowImport }) => {
  const [importState, setImportState] = useState(null);
  const [importError, setImportError] = useState(null);
  const [replaceAll, setReplaceAll] = useState(false);
  return (
    <>
      <StyledTextArea
        name={`standardImport`}
        placeholder="Paste JSON Import Here"
        label="JSON Import"
        component="textarea"
        rows="5"
        onChange={(e) => {
          try {
            const parsedObj = JSON.parse(e);
            if (parsedObj?.ocrDocuments && parsedObj?.ocrDocuments?.length) {
              setImportState(parsedObj);
              setImportError(false);
            } else {
              setImportError(true);
            }
          } catch (e) {
            setImportError(true);
          }
        }}
      />

      {importError ? (
        <ErrorMessages
          errors={[
            {
              message: "Error With Import Format",
            },
          ]}
        />
      ) : null}

      <FormActions>
        <label style={{ cursor: "pointer", marginRight: "1rem" }}>
          <input
            type="checkbox"
            name={`Replace All Documents`}
            label="Replace All Documents"
            checked={replaceAll}
            onChange={() => setReplaceAll((prev) => !prev)}
          />
          Replace Existing Documents
        </label>

        <Button
          type="button"
          onClick={() => {
            importMultiDocuments(importState, replaceAll);
            setShowImport(false);
          }}
          disabled={!importState}
        >
          Import
        </Button>
      </FormActions>
    </>
  );
};

const ImportSingleContainer = ({
  importSingleDocument,
  setShowSingleImport,
}) => {
  const [importState, setImportState] = useState(null);
  const [importError, setImportError] = useState(null);

  return (
    <>
      <StyledTextArea
        name={`standardImport`}
        placeholder="Paste JSON Import Here"
        label="JSON Import"
        component="textarea"
        rows="5"
        onChange={(e) => {
          try {
            const parsedObj = JSON.parse(e);
            if (parsedObj?.modelName) {
              setImportState(parsedObj);
              setImportError(false);
            } else {
              setImportError(true);
            }
          } catch (e) {
            setImportError(true);
          }
        }}
      />

      {importError ? (
        <ErrorMessages
          errors={[
            {
              message:
                "Error With Import Format, be sure the document has a name",
            },
          ]}
        />
      ) : null}

      <FormActions>
        <Button
          type="button"
          onClick={() => {
            importSingleDocument(importState);
            setShowSingleImport(false);
          }}
          disabled={!importState}
        >
          Import
        </Button>
      </FormActions>
    </>
  );
};

const EditingDocument = ({
  isEditing,
  collapse,
  documents,
  setFieldValue,
  ocrConnection,
}) => {
  const [currentItem, setCurrentItem] = useState({ ...isEditing?.original });

  const CloseAndSave = () => {
    let currentDocs = [...documents];
    currentDocs[isEditing?.index] = currentItem;
    setFieldValue(`ocrConnection.ocrDocuments`, currentDocs);
    collapse();
  };

  const copyDocument = () => {
    const modCurrentItem = { ...currentItem, id: 0 };
    const documentJSON = JSON.stringify(modCurrentItem);

    navigator.clipboard.writeText(documentJSON).then(
      function () {
        toast.success("Document JSON Copied for Import", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      },
      function (err) {
        toast.error("Failed To Copy", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    );
  };

  return (
    <div>
      <FormControl>
        <StyledField
          name={`modelName`}
          type="text"
          placeholder="Model Name"
          label="Model Name"
          value={currentItem?.modelName}
          onChange={(e) => {
            setCurrentItem((currentItem) => {
              let item = { ...currentItem };
              item.modelName = e?.target?.value;
              return item;
            });
          }}
        />
      </FormControl>

      {ocrConnection?.decryptedMasterModelId ? null : (
        <FormControl>
          <Label>Model Type</Label>
          <StyledSelect
            className={`react-select-container`}
            classNamePrefix={`react-select`}
            name={`Model Id`}
            id={`modelId`}
            inputId={`modelIdSelect-input`}
            instanceId={`modelIdSelect-instance`}
            menuPortalTarget={document.body}
            placeholder={`Model Type`}
            label="Model Type"
            value={currentItem?.modelId}
            options={prebuiltModels}
            onChange={(e) => {
              setCurrentItem((currentItem) => {
                let item = { ...currentItem };
                item.modelId = e;
                return item;
              });
            }}
          />{" "}
        </FormControl>
      )}

      {currentItem?.modelId?.label === "Custom" ? (
        <FormControl>
          <StyledField
            name={`custommodelid`}
            type="text"
            placeholder="Custom Model Guid"
            label="Custom Model Guid"
            value={currentItem?.modelId?.value}
            onChange={(e) => {
              setCurrentItem((currentItem) => {
                let item = { ...currentItem };
                item.modelId = {
                  label: "Custom",
                  value: e.target.value,
                };
                return item;
              });
            }}
          />
        </FormControl>
      ) : null}

      <FormControl>
        <StyledField
          name={`typeId`}
          type="text"
          placeholder="Type Id"
          label="Type Id"
          value={currentItem?.typeId}
          onChange={(e) => {
            setCurrentItem((currentItem) => {
              let item = { ...currentItem };
              item.typeId = e?.target?.value;
              return item;
            });
          }}
        />
      </FormControl>

      <FormControl>
        <label>
          Document Identification Only
          <Field
            name={`docIdOnly`}
            type="checkbox"
            placeholder="Doc-Id Only"
            label="Doc-Id Only"
            checked={currentItem?.docIdOnly}
            onChange={(e) => {
              setCurrentItem((currentItem) => {
                let item = { ...currentItem };
                item.docIdOnly = e?.target?.checked;
                return item;
              });
            }}
          />
        </label>
      </FormControl>

      <FormControl>
        <StyledField
          name={`versions`}
          type="text"
          placeholder="Valid Versions"
          label="Versions (comma separated, leave empty to pass for all)"
          value={currentItem?.versions}
          onChange={(e) => {
            setCurrentItem((currentItem) => {
              let item = { ...currentItem };
              item.versions = e?.target?.value;
              return item;
            });
          }}
        />
      </FormControl>

      <FormControl>
        <StyledField
          name={`pagesToRead`}
          type="text"
          placeholder="Pages To Read (ex: 1-3, 7)"
          label="Pages To Read (comma separated, use dash to indicate an inclusive range, leave empty to read all)"
          value={currentItem?.pagesToRead}
          onChange={(e) => {
            setCurrentItem((currentItem) => {
              let item = { ...currentItem };
              item.pagesToRead = e?.target?.value;
              return item;
            });
          }}
        />
      </FormControl>

      <FormControl>
        <StyledField
          name={`minimumPageCount`}
          type="number"
          min="0"
          placeholder="Min Page Count"
          label="Minimum Page Count"
          value={currentItem?.minimumPageCount}
          onChange={(e) => {
            setCurrentItem((currentItem) => {
              let item = { ...currentItem };
              item.minimumPageCount =
                e?.target?.value < 0 ? null : Number(e?.target?.value);
              return item;
            });
          }}
        />
      </FormControl>

      {/* MinimumPredictionThreshold  */}
      <FormControl>
        <Label>Minimum Prediction Threshold</Label>
        <Label style={{ display: "flex", alignItems: "center" }}>
          <input
            id="minimumPredictionThreshold"
            type="range"
            min="0"
            max="1"
            step="0.05"
            value={currentItem?.minimumPredictionThreshold}
            onChange={(e) => {
              setCurrentItem((currentItem) => {
                let item = { ...currentItem };
                item.minimumPredictionThreshold = e?.target?.value;
                return item;
              });
            }}
          />
          {currentItem.minimumPredictionThreshold}
        </Label>
      </FormControl>

      <FormControl>
        <Label>Identification Type</Label>
        <StyledSelect
          className={`react-select-container`}
          classNamePrefix={`react-select`}
          name={`ocrConnection.identificationType`}
          id={`identificationType`}
          inputId={`identificationTypeSelect-input`}
          instanceId={`identificationTypeSelect-instance`}
          label="Destination Connection"
          options={IdentificationTypes}
          placeholder={`Select Identification Type`}
          value={IdentificationTypes.find(
            (nc) => nc?.value === currentItem.identificationType
          )}
          menuPortalTarget={document.body}
          menuPlacement="auto"
          onChange={(e) => {
            setCurrentItem((currentItem) => {
              let item = { ...currentItem };
              item.identificationType = e?.value;
              if (e?.value !== 32) {
                item.candidateTypeIds = "";
              }
              return item;
            });
          }}
        />
      </FormControl>
      {currentItem.identificationType === 32 && (
        <FormControl>
          <StyledField
            name={`candidateTypeIds`}
            type="text"
            placeholder="Candidate Type Ids (ex: Type1, Type2)"
            label="Candidate Type Ids"
            value={currentItem?.candidateTypeIds}
            onChange={(e) => {
              setCurrentItem((currentItem) => {
                let item = { ...currentItem };
                item.candidateTypeIds = e?.target?.value;
                return item;
              });
            }}
          />
        </FormControl>
      )}
      {currentItem.identificationType === 64 && (
        <FormControl>
          <StyledField
            name={`locateOnlyTypeIds`}
            type="text"
            placeholder="Locate Page Only Type Ids (ex: Type1, Type2)"
            label="Locate Page Only Type Ids"
            value={currentItem?.locateOnlyTypeIds}
            onChange={(e) => {
              setCurrentItem((currentItem) => {
                let item = { ...currentItem };
                item.locateOnlyTypeIds = e?.target?.value;
                return item;
              });
            }}
          />
        </FormControl>
      )}

      <div
        style={{ display: "flex", justifyContent: "center", marginTop: "2rem" }}
      >
        <Button type="button" list onClick={() => copyDocument()}>
          Copy Document
        </Button>
        <Button
          danger
          list={true}
          type="button"
          onClick={() => {
            let currentDocs = [...documents];

            currentDocs?.splice(isEditing?.index, 1);
            setFieldValue(`ocrConnection.ocrDocuments`, currentDocs);
            collapse();
          }}
          title="Remove Document"
        >
          Remove
        </Button>

        <Button type="button" list={true} danger onClick={() => collapse()}>
          Cancel
        </Button>

        <Button type="button" onClick={() => CloseAndSave()}>
          Continue
        </Button>
      </div>
    </div>
  );
};

const DocumentList = ({ ocrConnection, documents, setFieldValue }) => {
  const [isEditing, setIsEditing] = useState(null);
  const { closeMenu } = useContext(NavContext);

  // const [showAllColumns, setShowAllColumns] = useState(false);

  useEffect(() => {
    if (isEditing) {
      closeMenu();
    }
  }, [isEditing, closeMenu]);

  const removeDocument = (index) => {
    const currentDocs = [...documents];
    const newDocs = currentDocs.filter((_, i) => i !== index);
    setFieldValue(`ocrConnection.ocrDocuments`, newDocs);
  };

  const columnsData = [
    {
      Header: "Model Name",
      id: "modelName",
      accessor: (d) => d.modelName,
      sortType: (prev, curr, columnId) => {
        return caseInsenSort(prev, curr, columnId);
      },
    },
    {
      Header: "Custom Model Guid",
      id: "modelId.value",
      accessor: (d) => d.modelId?.value,
      Cell: ({ row: { original } }) => {
        return <>{original?.modelId?.value}</>;
      },
    },
    {
      Header: "Type ID",
      id: "typeId",
      accessor: (d) => d.typeId,
      Cell: ({ row: { original } }) => {
        return <>{original.typeId}</>;
      },
    },
    {
      Header: "Valid Versions",
      id: "versions",
      accessor: (d) => d.versions,
      Cell: ({ row: { original } }) => {
        return <>{original.versions}</>;
      },
    },
    {
      Header: "Pages To Read",
      id: "pagesToRead",
      accessor: (d) => d.pagesToRead,
      Cell: ({ row: { original } }) => {
        return <>{original.pagesToRead}</>;
      },
    },
    {
      Header: "Prediction Threshold",
      id: "minimumPredictionThreshold",
      accessor: (d) => d.minimumPredictionThreshold,
      Cell: ({ row: { original } }) => {
        return <>{original.minimumPredictionThreshold}</>;
      },
    },
    {
      Header: "Identification Type",
      id: "identificationType",
      disableFilters: true,
      accessor: (d) => d.identificationType,
      Cell: ({ row: { original } }) => {
        return (
          <>
            {
              IdentificationTypes.find(
                (nc) => nc?.value === original.identificationType
              )?.label
            }
          </>
        );
      },
    },
    // ...(showAllColumns ? allColumns : []),
    {
      Header: " ",
      id: "actions",
      sortable: false,
      Cell: ({ row }) => {
        return (
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <TableButton type="button" list onClick={() => setIsEditing(row)}>
              Edit
            </TableButton>
            <TableButton
              type="button"
              danger
              onClick={() => removeDocument(row?.index)}
            >
              <MdDelete style={{ fontSize: "1.2rem" }} />
            </TableButton>
          </div>
        );
      },
    },
  ];

  return (
    <>
      <div style={{ marginTop: "1rem", marginBottom: "1rem", display: "flex" }}>
        <div style={{}}>
          To designate Doc Id Only, Minimum Page Count, Candidate Type Ids, and
          Locate Page Only Type Ids, please click "Edit" to see the full menu of
          options.
          {/* <label style={{ cursor: "pointer" }}>
            <input
              type="checkbox"
              name={`show all columns`}
              label="Show All Columns"
              checked={showAllColumns}
              onChange={() => setShowAllColumns((prev) => !prev)}
            />
            Show All Columns
          </label> */}
        </div>
      </div>

      {isEditing ? (
        <TransformEdit toggle={() => setIsEditing(null)}>
          <EditingDocument
            isEditing={isEditing}
            collapse={() => setIsEditing(null)}
            documents={documents}
            setFieldValue={setFieldValue}
            ocrConnection={ocrConnection}
          />
        </TransformEdit>
      ) : null}

      <SortExpandTableV2Filters
        // dontReset={true}
        data={documents}
        columns={columnsData}
        defaultPageSize={50}
        defaultSort={[
          {
            id: "modelName",
            desc: false,
          },
        ]}
        setIsEditing={setIsEditing}
        pinning={true}
        pinned={[]}
      />
    </>
  );
};

const newDocument = {
  modelId: "",
  modelName: "",
  typeId: "",
  versions: "",
  minimumPageCount: null,
  minimumPredictionThreshold: 0.5,
  identificationType: 0,
  candidateTypeIds: "",
  locateOnlyTypeIds: "",
};

const addDocument = (setFieldValue, values, connection) => {
  setFieldValue(`ocrConnection.ocrDocuments`, [
    { ocrConnectionId: connection?.ocrConnection?.id, ...newDocument },
    ...values?.ocrConnection?.ocrDocuments,
  ]);
};

const OCRSettingsForm = ({ connection, getUpdatedConnection }) => {
  // Query for Data Providers
  const queryProviders = `
      query(){
        allDocProviders() {
            displayName
            docProviderType
            enabled
            id
        }
    }
  `;
  // Loading the query on component mount for latest configuration
  const [{ errors: providersErrors, data: providersData }] =
    useApi(queryProviders);

  const providerOptions =
    providersData?.allDocProviders
      ?.filter((pd) => pd.enabled)
      .map((provider) => {
        return {
          label: provider?.displayName,
          value: provider?.id,
          docProviderType: provider?.docProviderType,
        };
      }) ?? [];

  const [showImport, setShowImport] = useState(false);
  const [showSingleImport, setShowSingleImport] = useState(false);
  const [showTrain, setShowTrain] = useState(false);

  const { user } = useContext(AuthContext);

  let canUseMjolnir; // Can User Reset Cache
  if (/@basecapanalytics.com\s*$/.test(user?.email)) {
    canUseMjolnir = true;
  } else if (/@basecap.dev\s*$/.test(user?.email)) {
    canUseMjolnir = true;
  }

  //Prime GraphAPI For Permissions Updates
  const [{ loading, errors, data }, doUpdateOCR] = useApi();

  const [{ data: connectionData }] = useApi(connectionsList, {
    first: 9999, //fix to get all with hc update
    where: {
      enabled: { eq: true },
      id: { neq: -1 },
    },
    order: {
      name: "ASC",
    },
  });

  // TODO: We already have the new form data, lets move the connection into state
  // and update from there instead of circling back, for now we hit the server to get valid data
  useEffect(() => {
    if (data && !errors?.length) {
      toast.success("OCR Settings Saved", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }, [data, errors?.length]);

  //callback
  const updateOCR = (values) => {
    const variables = {
      connectionId: connection.id,
      ocrConnection: {
        identificationType: values?.ocrConnection?.identificationType,
        inputPath: values?.ocrConnection?.inputPath,
        isAutoSource: values?.ocrConnection?.isAutoSource,
        containerName: values?.ocrConnection?.containerName,
        docProviderId: values?.ocrConnection?.docProviderId,
        destinationConnectionId: values?.ocrConnection?.destinationConnectionId,
        customVisionProjectId:
          values?.ocrConnection?.customVisionProjectId ?? "",
        customVisionIteration:
          values?.ocrConnection?.customVisionIteration ?? "",
        ocrDocuments: values?.ocrConnection?.ocrDocuments.map((docs) => {
          return {
            ...docs,
            minimumPredictionThreshold: Number(
              docs?.minimumPredictionThreshold
            ),
            id: docs?.id ?? 0,
            minimumPageCount:
              docs?.minimumPageCount === "" || docs?.minimumPageCount === 0
                ? null
                : docs?.minimumPageCount,
            modelId: docs?.modelId?.value,
            pagesToRead: docs?.pagesToRead ?? "",
            locateOnlyTypeIds: docs?.locateOnlyTypeIds ?? "",
          };
        }),
        namingConvention: values?.ocrConnection?.namingConvention?.value,
      },
    };
    doUpdateOCR({ query: updateOcrConnection, variables });
  };

  const copyDocuments = (values) => {
    const variables = {
      ocrDocuments: values?.ocrConnection?.ocrDocuments.map((docs) => {
        return {
          ...docs,
          minimumPredictionThreshold: Number(docs?.minimumPredictionThreshold),
          id: 0,
          minimumPageCount:
            docs?.minimumPageCount === "" || docs?.minimumPageCount === 0
              ? null
              : docs?.minimumPageCount,
          modelId: docs?.modelId,
          pagesToRead: docs?.pagesToRead ?? "",
          locateOnlyTypeIds: docs?.locateOnlyTypeIds ?? "",
        };
      }),
    };

    const documentJSON = JSON.stringify(variables);

    navigator.clipboard.writeText(documentJSON).then(
      function () {
        toast.success("Documents JSON Copied for Import", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      },
      function (err) {
        toast.error("Failed To Copy", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    );
  };

  const connectionsOptions =
    connectionData?.availableConnections?.edges?.map((connection) => {
      return {
        label: connection?.node?.name,
        value: connection?.node?.id,
      };
    }) ?? [];

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={{
          ocrConnection: connection?.ocrConnection
            ? {
                containerName: connection?.ocrConnection?.containerName,
                inputPath: connection?.ocrConnection?.inputPath,
                isAutoSource: connection?.ocrConnection?.isAutoSource,
                docProviderId: providerOptions.find(
                  (nc) => nc?.value === connection?.ocrConnection?.docProviderId
                )?.value,
                identificationType: IdentificationTypes.find(
                  (it) =>
                    it.text === connection?.ocrConnection?.identificationType
                )?.value,
                destinationConnectionId:
                  connection?.ocrConnection?.destinationConnectionId,
                customVisionProjectId:
                  connection?.ocrConnection?.customVisionProjectId,
                customVisionIteration:
                  connection?.ocrConnection?.customVisionIteration,
                ocrDocuments:
                  connection?.ocrConnection?.ocrDocuments.map((docs) => {
                    const modelIdToLower = docs?.modelId?.toLowerCase() ?? "";
                    return {
                      ...docs,
                      identificationType: IdentificationTypes.find(
                        (it) => it.text === docs?.identificationType
                      )?.value,
                      modelId: docs?.modelId
                        ? prebuiltModels.find(
                            (pbm) => pbm?.value.toLowerCase() === modelIdToLower
                          ) ?? {
                            label: "Custom",
                            value: docs?.modelId,
                          }
                        : prebuiltModels[4],
                    };
                  }) ?? [],
                namingConvention: namingConventions.find(
                  (nc) =>
                    nc?.value === connection?.ocrConnection?.namingConvention
                ),
              }
            : {
                docProviderId: null,
                containerName: null,
                inputPath: null,
                destinationConnectionId: null,
                identificationType: null,
                ocrDocuments: [],
                namingConvention: 2,
              },
        }}
        validate={(values) => {
          let schema;
          schema = Yup.object().shape({
            ocrConnection: Yup.object().shape({
              ocrDocuments: Yup.array().of(
                Yup.object().shape({
                  typeId: Yup.string(),
                  // modelId: Yup.string().required("Required"),
                  modelName: Yup.string(),
                  docIdOnly: Yup.bool(),
                  versions: Yup.string(),
                  // pagesToRead: Yup.string(),
                })
              ),
            }),
          });
          const promise = validateYupSchema(values, schema);

          return new Promise((resolve, reject) => {
            promise.then(
              () => {
                resolve({});
              },
              (err) => {
                if (err.name === "ValidationError") {
                  resolve(yupToFormErrors(err));
                } else {
                  reject(err);
                }
              }
            );
          });
        }}
        validateOnMount={true}
        onSubmit={(values) => updateOCR(values)}
      >
        {({ values, setFieldValue }) => {
          const importMultiDocuments = (ocrDocuments, replaceAll) => {
            setFieldValue(
              `ocrConnection.ocrDocuments`,
              replaceAll
                ? [...ocrDocuments.ocrDocuments]
                : [
                    ...values?.ocrConnection?.ocrDocuments,
                    ...ocrDocuments.ocrDocuments,
                  ]
            );

            toast.success("Documents Imported", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          };

          const importSingleDocument = (document) => {
            setFieldValue(`ocrConnection.ocrDocuments`, [
              ...values?.ocrConnection?.ocrDocuments,
              document,
            ]);

            toast.success("Document Imported", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          };
          const isCustomVision = [
            "CUSTOM_VISION_PREDICTION",
            "CUSTOM_VISION_TRAINING",
          ].includes(
            providerOptions.find(
              (nc) => nc?.value === values?.ocrConnection?.docProviderId
            )?.docProviderType
          );

          const isNotXmlOrCopy = ![
            "XML_LOAN_LOGIC",
            "COPY_DOCUMENT",
            "EXTRACT_DOCUMENT",
          ].includes(
            providerOptions.find(
              (nc) => nc?.value === values?.ocrConnection?.docProviderId
            )?.docProviderType
          );

          const isDestinationVisible = [
            "EXTRACT_DOCUMENT",
            "COPY_DOCUMENT",
            "XML_LOAN_LOGIC",
            "CUSTOM_VISION_PREDICTION",
          ].includes(
            providerOptions.find(
              (po) => po.value === values?.ocrConnection?.docProviderId
            )?.docProviderType
          );

          return (
            <Form>
              {showImport ? (
                <Modal
                  title={"Import Documents"}
                  hide={() => setShowImport((prevState) => !prevState)}
                >
                  <ImportContainer
                    importMultiDocuments={importMultiDocuments}
                    setShowImport={setShowImport}
                  />
                </Modal>
              ) : null}
              {showSingleImport ? (
                <Modal
                  title={"Import Single Document"}
                  hide={() => setShowSingleImport((prevState) => !prevState)}
                >
                  <ImportSingleContainer
                    importSingleDocument={importSingleDocument}
                    setShowSingleImport={setShowSingleImport}
                  />
                </Modal>
              ) : null}

              <>
                <h3>OCR Source Location</h3>
                <p>
                  <small>
                    <i>
                      Documents will be added on the "OCR" tab on the connection
                      once established.
                    </i>
                  </small>
                </p>
                <FormControl>
                  <label style={{ cursor: "pointer", marginRight: "1rem" }}>
                    <input
                      id={`autocreatefromCSV`}
                      type="checkbox"
                      name={`Automatically create source from CSV outputs`}
                      label="Automatically create source from CSV outputs"
                      checked={values?.ocrConnection?.isAutoSource}
                      onChange={(e) =>
                        setFieldValue(
                          `ocrConnection.isAutoSource`,
                          e?.target?.checked
                        )
                      }
                    />
                    Automatically create source from CSV outputs
                  </label>
                </FormControl>
                <FormControl>
                  <Label>Document Provider</Label>
                  <StyledSelect
                    className={`react-select-container`}
                    classNamePrefix={`react-select`}
                    name={`docProviderId`}
                    id={`docProviderId`}
                    inputId={`docProviderId-input`}
                    instanceId={`docProviderId-instance`}
                    label="Document Provider"
                    options={providerOptions}
                    formatOptionLabel={formatProviderSelect}
                    placeholder={`Select Document Provider`}
                    value={providerOptions.find(
                      (nc) => nc?.value === values?.ocrConnection?.docProviderId
                    )}
                    menuPortalTarget={document.body}
                    menuPlacement="auto"
                    onChange={(e) =>
                      setFieldValue(`ocrConnection.docProviderId`, e?.value)
                    }
                  />
                </FormControl>

                {isNotXmlOrCopy && (
                  <FormControl>
                    <Label>Identification Type</Label>
                    <StyledSelect
                      className={`react-select-container`}
                      classNamePrefix={`react-select`}
                      name={`ocrConnection.identificationType`}
                      id={`identificationType`}
                      inputId={`identificationTypeSelect-input`}
                      instanceId={`identificationTypeSelect-instance`}
                      label="Identification Type"
                      options={IdentificationTypes}
                      placeholder={`Select Identification Type`}
                      value={IdentificationTypes.find(
                        (nc) =>
                          nc?.value ===
                          values?.ocrConnection?.identificationType
                      )}
                      menuPortalTarget={document.body}
                      menuPlacement="auto"
                      onChange={(e) =>
                        setFieldValue(
                          `ocrConnection.identificationType`,
                          e?.value
                        )
                      }
                    />
                    <ErrorMessage name={`ocrConnection.identificationType`} />
                  </FormControl>
                )}

                <FormControl>
                  <StyledField
                    name={`ocrConnection.containerName`}
                    type="text"
                    placeholder="Container Name"
                    label="Container Name"
                  />
                  <ErrorMessage name={`ocrConnection.containerName`} />
                </FormControl>

                <FormControl>
                  <StyledField
                    name={`ocrConnection.inputPath`}
                    type="text"
                    placeholder="Input Path"
                    label="Input Path (case sensitive)"
                  />
                  <ErrorMessage name={`ocrConnection.inputPath`} />
                </FormControl>

                {isNotXmlOrCopy && (
                  <FormControl>
                    <Label>File Naming Convention</Label>
                    <StyledSelect
                      className={`react-select-container`}
                      classNamePrefix={`react-select`}
                      name={`ocrConnection.namingConvention`}
                      id={`namingConvention`}
                      inputId={`namingConventionSelect-input`}
                      instanceId={`namingConventionSelect-instance`}
                      label="Naming Convention"
                      options={namingConventions}
                      placeholder={`Select Convention`}
                      value={values?.ocrConnection?.namingConvention}
                      menuPortalTarget={document.body}
                      menuPlacement="auto"
                      onChange={(e) =>
                        setFieldValue(`ocrConnection.namingConvention`, e)
                      }
                    />
                  </FormControl>
                )}

                {isDestinationVisible && (
                  <FormControl>
                    <Label>Destination Connection</Label>
                    <StyledSelect
                      className={`react-select-container`}
                      classNamePrefix={`react-select`}
                      name={`ocrConnection.destinationConnectionId`}
                      id={`destinationConnectionId`}
                      inputId={`destinationConnectionIdSelect-input`}
                      instanceId={`destinationConnectionIdSelect-instance`}
                      label="Destination Connection"
                      options={connectionsOptions}
                      placeholder={`Select Destination Connection`}
                      value={connectionsOptions.find(
                        (nc) =>
                          nc?.value ===
                          values?.ocrConnection?.destinationConnectionId
                      )}
                      menuPortalTarget={document.body}
                      menuPlacement="auto"
                      onChange={(e) =>
                        setFieldValue(
                          `ocrConnection.destinationConnectionId`,
                          e?.value
                        )
                      }
                    />
                  </FormControl>
                )}

                {isCustomVision && (
                  <div
                    style={{
                      padding: "1rem",
                      border: "1px solid #ccc",
                      borderRadius: "1rem",
                      marginBottom: "1rem",
                    }}
                  >
                    <h4 style={{ marginBottom: "1rem" }}>
                      Custom Vision Settings
                    </h4>

                    {/* Custom Vision Info */}

                    <FormControl>
                      <StyledField
                        name={`ocrConnection.customVisionProjectId`}
                        type="text"
                        placeholder="Custom Vision Project Id"
                        label="Custom Vision Project Id"
                      />
                      <ErrorMessage
                        name={`ocrConnection.customVisionProjectId`}
                      />
                    </FormControl>

                    <FormControl>
                      <StyledField
                        name={`ocrConnection.customVisionIteration`}
                        type="text"
                        placeholder="Custom Vision Iteration"
                        label="Custom Vision Project Iteration"
                      />
                      <ErrorMessage
                        name={`ocrConnection.customVisionIteration`}
                      />
                    </FormControl>
                  </div>
                )}
              </>

              {showTrain ? (
                <Modal
                  title={"Train Models"}
                  hide={() => setShowTrain((prevState) => !prevState)}
                >
                  <TrainModelLabels providerOptions={providerOptions} />
                </Modal>
              ) : null}

              <div style={{ display: "flex" }}>
                <div>
                  {" "}
                  <h3>OCR Documents</h3>
                  <Button
                    list
                    type="button"
                    onClick={() =>
                      addDocument(setFieldValue, values, connection)
                    }
                  >
                    Add New Document
                  </Button>
                </div>
                <FormActions style={{ marginLeft: "auto" }}>
                  <FormControl>
                    {errors ? <ErrorMessages errors={errors} /> : null}
                    {providersErrors ? (
                      <ErrorMessages errors={providersErrors} />
                    ) : null}

                    {canUseMjolnir && (
                      <Button
                        list
                        type="button"
                        onClick={() => setShowTrain((prevState) => !prevState)}
                      >
                        Train Model
                      </Button>
                    )}

                    {/* TODO: Add Toasts */}
                    {data && <div>Updated!</div>}
                    <Button type="submit" disabled={loading}>
                      {loading ? <Spinner /> : "Save OCR Settings"}
                    </Button>
                  </FormControl>
                </FormActions>
              </div>

              {values?.ocrConnection?.ocrDocuments?.length ? (
                <DocumentList
                  key={`docs-${values?.ocrConnection?.ocrDocuments?.length}`}
                  values={values}
                  documents={values?.ocrConnection?.ocrDocuments}
                  setFieldValue={setFieldValue}
                  ocrConnection={values?.ocrConnection}
                />
              ) : null}
              <div style={{ marginTop: "1rem" }}>
                <Button
                  list
                  type="button"
                  onClick={() => setShowSingleImport(true)}
                >
                  Import Single Document
                </Button>

                <Button list type="button" onClick={() => setShowImport(true)}>
                  Import Documents
                </Button>
                {values?.ocrConnection?.ocrDocuments?.length ? (
                  <Button
                    list
                    type="button"
                    onClick={() => copyDocuments(values, connection)}
                  >
                    Copy Documents
                  </Button>
                ) : null}

                <Button
                  type="button"
                  onClick={() =>
                    csvDownload({
                      filename: `${
                        values?.ocrConnection?.containerName
                      }.ocrDocuments.${new Date().toJSON().slice(0, 10)}`,
                      delimiter: ",",
                      headers:
                        [
                          ...Object.keys(
                            values?.ocrConnection?.ocrDocuments?.[0]
                          ),
                          "CustomModelGuid",
                          "ModelType",
                        ] ?? [],
                      data: values?.ocrConnection?.ocrDocuments.map((ocrd) => {
                        return {
                          ...ocrd,
                          identificationType: IdentificationTypes.find(
                            (nc) => nc?.value === ocrd?.identificationType
                          )?.label,
                          modelId: ocrd?.modelId?.value,
                          CustomModelGuid: ocrd?.modelId?.value,
                          ModelType:
                            prebuiltModels.find(
                              (pm) => pm.value === ocrd?.modelId?.value
                            )?.label ?? "Custom",
                        };
                      }),
                    })
                  }
                >
                  Download Document Settings
                </Button>
              </div>

              {values?.ocrConnection?.ocrDocuments?.length ? (
                <FormActions>
                  <FormControl>
                    {errors ? <ErrorMessages errors={errors} /> : null}
                    {/* TODO: Add Toasts */}
                    {data && <div>Updated!</div>}

                    <Button type="submit" disabled={loading}>
                      {loading ? <Spinner /> : "Save OCR Settings"}
                    </Button>
                  </FormControl>
                </FormActions>
              ) : null}
            </Form>
          );
        }}
      </Formik>
      <ToastContainer />
    </>
  );
};

export default OCRSettingsForm;
