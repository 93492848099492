import { useContext } from "react";
import { SocketContext } from "../contexts/useSubscriptions";

const UseSetWorkflowPipelineFailEvent = () => {
  const { ocrRateLimitNotification, setSetOcrRateLimitNotification } =
    useContext(SocketContext);

  return { ocrRateLimitNotification, setSetOcrRateLimitNotification };
};
export default UseSetWorkflowPipelineFailEvent;
