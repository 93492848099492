// Create Connection
export const createConnection = /* GraphQL */ `
  mutation ($connection: ConnectionInputModelInput!) {
    createConnection(connection: $connection)
  }
`;

export const updateOcrConnection = /* GraphQL */ `
  mutation (
    $connectionId: Int!
    $ocrConnection: OcrConnectionInputModelInput!
  ) {
    updateOcrConnection(
      connectionId: $connectionId
      ocrConnection: $ocrConnection
    )
  }
`;

export const removeCachedPdfs = /* GraphQL */ `
  mutation ($ocrConnectionId: Int!) {
    removeCachedPdfs(ocrConnectionId: $ocrConnectionId)
  }
`;

// Update Connection
export const updateConnectionDetails = /* GraphQL */ `
  mutation (
    $id: Int!
    $details: ConnectionDetailsInputModelInput!
    $ocrConnection: OcrConnectionInputModelInput
  ) {
    updateConnectionDetails(
      id: $id
      details: $details
      ocrDetails: $ocrConnection
    )
  }
`;

// Update Connection Permissions
export const setConnectionPermissions = /* GraphQL */ `
  mutation ($id: Int!, $permissions: [ConnectionPermissionsInputModelInput!]!) {
    setConnectionPermissions(id: $id, permissions: $permissions) {
      id
    }
  }
`;

// Update Connection Enabled Flag
export const updateConnectionEnabledFlag = /* GraphQL */ `
  mutation ($id: Int!, $enabledState: Boolean!) {
    updateConnectionEnabledFlag(id: $id, enabledState: $enabledState) {
      id
    }
  }
`;

// Update Ocr Connection Enabled Flag
export const updateOcrConnectionEnabledFlag = /* GraphQL */ `
  mutation ($id: Int!, $enabledState: Boolean!) {
    updateOcrConnectionEnabledFlag(id: $id, enabledState: $enabledState) {
      id
    }
  }
`;

// BROWSE
export const browseConnectionForDataSources = /* GraphQL */ `
  mutation ($request: BrowseConnectionRequestInputModelInput!) {
    browseConnectionForDataSources(request: $request) {
      connectionId
      itemToBrowse {
        name
        container
        extension
        itemType
        path
        parentPath
        customQuery
      }
    }
  }
`;

// BROWSE
export const importDataSources = /* GraphQL */ `
  mutation (
    $connectionId: Int!
    $dataSourcesToImport: [ConnectionBrowseItemInputModelInput!]!
  ) {
    importDataSources(
      connectionId: $connectionId
      dataSourcesToImport: $dataSourcesToImport
    )
  }
`;

// Start Custom vision training
export const startCustomVisionIterationTraining = /* GraphQL */ `
  mutation (
    $projectId: UUID!
    $docProviderId: Int!
    $selectedTags: [String]
    $hours: Int
    $notificationEmail: String
  ) {
    startCustomVisionIterationTraining(
      projectId: $projectId
      docProviderId: $docProviderId
      selectedTags: $selectedTags
      hours: $hours
      notificationEmail: $notificationEmail
    )
  }
`;
