// Org Consent State
export const upsertRecordLevelReport = /* GraphQL */ `
  mutation ($reportConfig: RecordLevelReportConfigurationInputModelInput!) {
    upsertRecordLevelReport(reportConfig: $reportConfig) {
      id
    }
  }
`;

// Export RLR
export const requestRLRExport = /* GraphQL */ `
  mutation ($rlrId: Int!) {
    requestRLRExport(rlrId: $rlrId)
  }
`;
