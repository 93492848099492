import React, { useState, useMemo, useEffect } from "react";
import { useFormikContext } from "formik";
import { useApi } from "../../../api/useApi";
import PagedTable from "../../Table/PagedTable";
import { groups } from "../../../api/groupQueries";
import styled from "styled-components";
import { addGroup, removeGroup } from "../../../api/workgroupMutations";
import TableButton from "../../Button/TableButton";
import { useApi as useApiRedux } from "../../../api/useApiRedux";
import debounce from "lodash.debounce";
import { FormControl, StyledInput } from "../../Form/FormControls";
import ErrorMessages from "../../Notifications/ErrorMessages";
import PagedTableRedux from "../../Table/PagedTableRedux";

const SingleRowFlex = styled.div`
  display: flex;
  align-items: center;
`;

const DisplayName = styled.div`
  flex: 1;
`;

const SearchGroups = ({ SetIsSearching, workGroupId, updateWorkGroup }) => {
  const [userData, setUserData] = useState([]);

  const [
    { errors: getUserErrors, loading: loadingUsers, data: usersDataFetch },
    getUsers,
  ] = useApiRedux();

  const [searchText, setSearchText] = React.useState("");
  const [inputValue, setInputValue] = useState("");

  const debouncedSave = useMemo(
    () => debounce((newValue) => setSearchText(newValue), 1000),
    []
  );

  const updateValue = (newValue) => {
    setInputValue(newValue);
    debouncedSave(newValue);
  };

  //Fetch for Table Paged
  const fetchData = React.useCallback(
    ({ params }) => {
      if (searchText) {
        getUsers({
          query: `/search/groups`,
          params: { query: searchText, ...params },
          method: "GET",
        });
      }
    },
    [getUsers, searchText]
  );

  useEffect(() => {
    if (searchText) {
      SetIsSearching(true);
    } else {
      SetIsSearching(false);
    }
  }, [searchText, SetIsSearching]);

  const totalCount = usersDataFetch?.metadata?.availableItems;

  const {
    values: { groups: selectedItems },
  } = useFormikContext();

  const addGroupId = (original) => {
    const variables = {
      workGroupId: workGroupId,
      orgGroupId: original?.id,
    };
    updateWorkGroup({ query: addGroup, variables: variables });
  };

  const removeSelection = (original) => {
    const variables = {
      workGroupId: workGroupId,
      groupId: original?.id,
    };
    updateWorkGroup({ query: removeGroup, variables: variables });
  };

  const columnsData = [
    {
      Header: "Name",
      id: "name",
      accessor: (d) => d?.displayName,
      Cell: ({ row: { original } }) => {
        const existingIndex = selectedItems.findIndex(
          (item) => item?.id === original?.id
        );

        return (
          <SingleRowFlex>
            <DisplayName>{original?.displayName}</DisplayName>{" "}
            {existingIndex === -1 ? (
              <TableButton type="button" onClick={() => addGroupId(original)}>
                +
              </TableButton>
            ) : (
              <TableButton
                type="button"
                danger={true}
                onClick={() => removeSelection(original)}
              >
                x
              </TableButton>
            )}
          </SingleRowFlex>
        );
      },
    },
  ];

  const [viewState] = useState({
    page: 1,
    size: 25,
  });

  useEffect(() => {
    if (usersDataFetch) {
      const availUsers = usersDataFetch?.data ?? [];
      setUserData(availUsers);
    }
  }, [usersDataFetch]);

  return (
    <>
      <FormControl>
        <StyledInput
          type="text"
          name="searchGroups"
          label="Search Groups"
          placeholder={`Search by Name`}
          value={inputValue}
          onChange={(input) => updateValue(input.target.value)}
        />

        {getUserErrors ? <ErrorMessages errors={getUserErrors} /> : null}
      </FormControl>
      {inputValue ? (
        <PagedTableRedux
          key={"viewState" + JSON.stringify(viewState)}
          fetchData={fetchData}
          totalCount={totalCount}
          loading={loadingUsers}
          data={userData}
          columns={columnsData}
          noMargin={true}
          defaultPageSize={50}
          initParams={viewState}
        />
      ) : null}
    </>
  );
};

const StaticGroupSelect = ({ updateWorkGroup, workGroupId }) => {
  const {
    values: { groups: selectedItems },
  } = useFormikContext();

  const [{ loading, data }, doFetch] = useApi();

  const availGroups = data?.groups?.edges ?? [];
  const totalCount = data?.groups?.totalCount;
  const pageInfo = data?.groups?.pageInfo;

  //Fetch for Table Paged
  const fetchData = React.useCallback(
    ({ pageSize, cursor }) => {
      doFetch({
        query: groups,
        variables: {
          first: pageSize,
          after: cursor,
          order: {
            displayName: "ASC",
          },
        },
      });
    },
    [doFetch]
  );

  const addGroupId = (original) => {
    const variables = {
      workGroupId: workGroupId,
      orgGroupId: original?.node?.id,
    };
    updateWorkGroup({ query: addGroup, variables: variables });
  };

  const removeSelection = (original) => {
    const variables = {
      workGroupId: workGroupId,
      groupId: original?.node?.id,
    };
    updateWorkGroup({ query: removeGroup, variables: variables });
  };

  const columnsData = [
    {
      Header: "Name",
      id: "name",
      accessor: (d) => d?.node?.displayName,
      Cell: ({ row: { original } }) => {
        const existingIndex = selectedItems.findIndex(
          (item) => item?.id === original?.node?.id
        );

        return (
          <SingleRowFlex>
            <DisplayName>{original?.node?.displayName}</DisplayName>{" "}
            {existingIndex === -1 ? (
              <TableButton type="button" onClick={() => addGroupId(original)}>
                +
              </TableButton>
            ) : (
              <TableButton
                type="button"
                danger={true}
                onClick={() => removeSelection(original)}
              >
                x
              </TableButton>
            )}
          </SingleRowFlex>
        );
      },
    },
  ];

  return (
    <>
      <PagedTable
        fetchData={fetchData}
        loading={loading}
        pageInfo={pageInfo}
        totalCount={totalCount}
        data={availGroups}
        columns={columnsData}
        defaultPageSize={50}
      />
    </>
  );
};

const SelectOrganizationGroup = React.memo(
  ({ updateWorkGroup, workGroupId }) => {
    const [isSearching, SetIsSearching] = useState();
    return (
      <>
        <SearchGroups
          SetIsSearching={SetIsSearching}
          workGroupId={workGroupId}
          updateWorkGroup={updateWorkGroup}
        />
        {!isSearching ? (
          <StaticGroupSelect
            updateWorkGroup={updateWorkGroup}
            workGroupId={workGroupId}
          />
        ) : null}
      </>
    );
  }
);

export default SelectOrganizationGroup;
