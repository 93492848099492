import React, { useRef, useCallback, useEffect } from "react";
import styled from "styled-components/macro";

const ViewRow = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  z-index: 999;
  width: 500px;
  height: 100vh;
  background: #fff;
  padding: 2rem;
  padding-top: 2.5rem;
  overflow-y: auto;
`;

const Opaque = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  z-index: 998;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
`;

const TableRowView = ({ children, toggle }) => {
  const node = useRef(null);

  const handleClick = useCallback(
    (e) => {
      //find if target is select option
      if (
        node.current.contains(e.target) ||
        e?.target?.classList.contains("react-select__option")
      ) {
        // click was detected inside the node, handle it here
        e.stopPropagation();
        return;
      }
      // click outside of node detected, close menu
      toggle(null);
    },
    [toggle]
  );

  useEffect(() => {
    // add listener on mount
    document.addEventListener("mousedown", handleClick);
    // when unmounted this return will be called
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, [handleClick]);

  return (
    <Opaque>
      <ViewRow ref={node}>{children}</ViewRow>
    </Opaque>
  );
};

export default TableRowView;
