export const failureEnums = [
  { value: "Connection_Failure", label: "Connection Failure" },
  { value: "Cancelled", label: "Cancelled" },
  { value: "Invalid_Rule_Mapping", label: "Invalid Rule Mapping" },
  { value: "Ingress_Source_Not_Found", label: "Waiting for New File" },
  { value: "Generic_Failure", label: "Unexpected Failure" },
  { value: "None", label: "None" },
  { value: "Report_Ready", label: "Ready" },
  { value: "Reference_Report_Ready", label: "Reference Ready" },
  { value: "Report_Ready_Incomplete", label: "Failed To Read All Rows" },
];

export const resultStateEnums = [
  { value: -7, name: "User Cancelled" },
  { value: -5, name: "Connection Failure" },
  { value: -4, name: "Cancelled" },
  {
    value: -3,
    name: "Invalid Rule Mapping",
  },
  {
    value: -2,
    name: "Waiting for New File",
  },
  {
    value: -1,
    name: "Unexpected Failure",
  },
  { value: 0, name: "" },
  { value: 1, name: "" },
  {
    value: 2,
    name: "Failed To Read All Rows",
  },
];
