import React from "react";
import styled from "styled-components/macro";

const ButtonContainer = styled.button`
  position: relative;
  /* Adapt the colours based on primary prop */
  background: ${(props) =>
    props.active ? props.theme.onSurfaceLight : "none"};
  color: ${(props) =>
    props.active ? props.theme.onSurfaceLightSecondary : "#21204B"};
  cursor: pointer;
  width: ${(props) => (props.span ? "100%" : "auto")};
  margin-right: ${(props) => (props.list ? "1rem" : "0")};
  padding: 0.2rem 0.8rem;
  display: ${(props) => (props.display ? props.display : "")};
  border: none;
  transition: all ${(props) => props.theme.transitionSpeed} linear;
  &:hover {
    background: ${(props) => (props.active ? "#263A7B" : "#E3E3E3")};
    border-radius: 4px;
  }
  &:focus {
    border: none;
  }
  &:disabled {
    opacity: 0.3;
    cursor: auto;
    background: ${(props) =>
      props.primary
        ? props.theme.onSurfaceLight
        : props.danger
        ? props.theme.danger
        : props.theme.onSurfaceLight};
  }
`;

const ButtonCenter = styled.div`
  width: 100%;
  text-align: center;
`;

const ButtonInner = styled.div`
  display: flex;
  align-items: center;
  font-size: 0.8125rem;
`;

const ButtonIcon = styled.div`
  margin-right: ${(props) => (props.iconBump ? ".5rem" : "")};
`;

const SmallToggle = (props) => {
  const IconComponent = props?.icon ?? null;

  return props.center ? (
    <ButtonCenter>
      <ButtonContainer {...props}>
        <ButtonInner>
          {IconComponent && (
            <ButtonIcon iconBump={props.iconBump}>
              <IconComponent />
            </ButtonIcon>
          )}
          {props.children}
        </ButtonInner>
      </ButtonContainer>
    </ButtonCenter>
  ) : (
    <ButtonContainer {...props}>
      <ButtonInner>
        {IconComponent && (
          <ButtonIcon iconBump={props.iconBump}>
            <IconComponent />
          </ButtonIcon>
        )}
        {props.children}
      </ButtonInner>
    </ButtonContainer>
  );
};

export default SmallToggle;
