import React, { useContext } from "react";
import { NavLink, Route, Switch } from "react-router-dom";
import { manageUsers } from "../../common/paths";
import AuditWrapper from "../../components/Audit";
import Card from "../../components/Card";
import styled from "styled-components/macro";
import AdminUsersManagement from "./ManageUsersPage";
import ETLManager from "../../components/ETLManager";
import OCRManager from "../../components/OCRManager";
import Organizations from "./Organizations";
import OrganizationForm from "../../components/Forms/Organization";
import Organization from "./Organization";
import ManageUsersPage from "./ManageUsersPage";
import Groups from "./Groups";
import { AuthContext } from "../../contexts/AuthContext";
import RulePackManager from "./RulePackManager";
import Configuration from "./Configuration";
import ScheduledCleanup from "./ScheduledCleanup";
import AdminAlerts from "../../components/Forms/Admin/AdminAlerts";

const Tabs = styled.div`
  border-bottom: 1px solid ${(props) => props.theme.surfaceAlt};
  background-color: ${(props) => props.theme.surface};
  position: relative;
  padding: 0.6rem;
  margin-bottom: 1rem;
`;

const TabLink = styled(NavLink)`
  font-size: 0.9rem;
  padding: 0.5rem;
  border-bottom: 2px solid transparent;
  text-decoration: none;
  opacity: 0.5;
  color: ${(props) => props.theme.onSurface};
  &.active {
    opacity: 1;
    border-bottom: 2px solid ${(props) => props.theme.onSecondarySurface};
  }
  &:hover {
    opacity: 1;
  }
  text-transform: uppercase;
`;

function Body() {
  const { user } = useContext(AuthContext);

  let canUseMjolnir; // Can User Reset Cache
  if (/@basecapanalytics.com\s*$/.test(user?.email)) {
    canUseMjolnir = true;
  } else if (/@basecap.dev\s*$/.test(user?.email)) {
    canUseMjolnir = true;
  }

  return (
    <>
      <Tabs>
        <TabLink to={`/admin/audit`}>Audit</TabLink>
        <TabLink to={`/admin/groups`}>Groups</TabLink>
        <TabLink to={manageUsers}>Users</TabLink>
        <TabLink to={`/admin/organizations`}>Organizations</TabLink>
        <TabLink to={`/admin/alerts`}>Alerts</TabLink>
        <TabLink to={`/admin/etl`}>ETL</TabLink>
        <TabLink to={`/admin/ocr`}>OCR</TabLink>
        <TabLink to={`/admin/configuration/workingdays`}>Configuration</TabLink>
        {canUseMjolnir && (
          <TabLink to={`/admin/scheduledCleanup`}>Scheduled Cleanup</TabLink>
        )}
        {canUseMjolnir && (
          <TabLink to={`/admin/rulepackmanager`}>Rule Pack Manager</TabLink>
        )}
      </Tabs>

      <Switch>
        <Route
          path={`/admin/scheduledCleanup`}
          component={() =>
            canUseMjolnir ? <ScheduledCleanup /> : <div>No Access</div>
          }
        />

        <Route
          path={`/admin/rulepackmanager`}
          component={() =>
            canUseMjolnir ? <RulePackManager /> : <div>No Access</div>
          }
        />

        <Route
          path={`/admin/organizations/add`}
          component={() => <OrganizationForm />}
        />

        <Route path={`/admin/alerts`} component={() => <AdminAlerts />} />

        <Route
          path={`/admin/organizations/:id/users`}
          component={() => <ManageUsersPage />}
        />

        <Route
          path={`/admin/organizations/:orgId`}
          component={() => <Organization />}
        />

        <Route exact path={`/admin/groups`} component={() => <Groups />} />

        <Route
          exact
          path={`/admin/organizations`}
          component={() => <Organizations />}
        />

        <Route
          path={`/admin/configuration`}
          component={() => <Configuration />}
        />

        <Route
          path={`/admin/users`}
          component={() => <AdminUsersManagement />}
        />

        <Route path={`/admin/audit`} component={() => <AuditWrapper />} />

        <Route path={`/admin/etl`} component={() => <ETLManager />} />

        <Route path={`/admin/ocr`} component={() => <OCRManager />} />
      </Switch>
    </>
  );
}

const AdminPage = () => {
  return (
    <Card title={"Admin"} titleDescription={"Manage Product"} body={Body} />
  );
};

export default AdminPage;
