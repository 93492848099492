import { createContext } from "react";
import { useState, useEffect } from "react";
import { useApi } from "../../api/useApiRedux";
import { useMemo } from "react";

export const PolicyChatContext = createContext();

const welcomeMessage =
  "Hi there! I'm an assistant to help you build policies to audit your data. To get started, enter a detailed description of the policy you'd like me to write for you.";

const initMessages = [
  { role: "bcaSystem", content: welcomeMessage, shouldRender: true },
];

const PolicyChatContextComponent = (props) => {
  const [messages, setMessages] = useState(initMessages);
  // { name, description, expression }
  const [generatedPolicy, setGeneratedPolicy] = useState();
  const [{ errors, loading: isLoading, data: generatedPolicyResponse }, doFetch] =
    useApi();

  useEffect(() => {
    if (!generatedPolicyResponse) return;

    if (generatedPolicyResponse.name && generatedPolicyResponse.name !== "") {
      setGeneratedPolicy({
        name: generatedPolicyResponse.name,
        description: generatedPolicyResponse.description,
        expression: generatedPolicyResponse.expression,
      });
      setMessages((prev) => [
        ...prev,
        {
          role: "assistant",
          content: generatedPolicyResponse.rawResponse,
          shouldRender: false,
        },
        {
          role: "bcaSystem",
          content: `Applying generated policy '${generatedPolicyResponse.name}'. Feel free to request changes or additions!`,
          shouldRender: true,
        },
      ]);
    } else {
      setMessages((prev) => [
        ...prev,
        {
          role: "assistant",
          content: generatedPolicyResponse.rawResponse,
          shouldRender: true, // only render raw response if we got an invalid nCalc back
        },
      ]);
    }
  }, [generatedPolicyResponse]);

  useEffect(() => {
    if (errors) {
      console.error(errors);
      setMessages((prev) => [
        ...prev,
        {
          role: "bcaSystem",
          shouldRender: true,
          content: `Sorry! We've encountered an error while generating the policy. If you believe there is an issue, please reach out to the support team.`,
        },
      ]);
    }
  }, [errors]);

  const chat = useMemo(() => {
    const generatePolicy = (message) => {
      const newConversation = [...messages, message];
      setMessages(newConversation);
      doFetch({
        query: "/ai/generate-policy",
        method: "POST",
        body: {
          conversation: newConversation.filter((m) => m.role !== "bcaSystem"),
        },
      });
    };

    const reset = () => {
      setMessages(initMessages);
      setGeneratedPolicy();
    };

    return {
      messages,
      generatedPolicy,
      isLoading,
      errors,
      generatePolicy,
      reset,
    };
  }, [messages, generatedPolicy, isLoading, errors, doFetch]);

  return (
    <PolicyChatContext.Provider value={chat}>
      {props.children}
    </PolicyChatContext.Provider>
  );
};

export default PolicyChatContextComponent;
