import React, { useEffect, useState } from "react";
import { useApi } from "../../api/useApi";
import { createTag } from "../../api/tagMutations";
import Button from "../Button";
import { FormControl, StyledInput, Label } from "../Form/FormControls";
import Spinner from "../Loaders/Spinner";
import ErrorMessages from "../Notifications/ErrorMessages";

const CreateTag = ({ isInModal, modalAction, modalClose }) => {
  const [{ errors, loading, data }, create] = useApi();

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");

  const createATag = () => {
    create({
      query: createTag,
      variables: {
        tag: {
          description: description,
          name: name,
        },
      },
    });
  };

  useEffect(() => {
    if (data?.createTag && !errors) {
      modalAction(data?.createTag);
    }
  }, [data, errors, modalAction]);

  return (
    <div>
      <FormControl>
        <Label>Name</Label>
        <StyledInput
          type="text"
          name="TagName"
          value={name}
          placeholder={"Tag Name"}
          onChange={(e) => setName(e?.target?.value)}
        />
      </FormControl>
      <FormControl>
        <Label>Description</Label>
        <StyledInput
          type="text"
          name="TagDescription"
          value={description}
          placeholder={"Tag Description"}
          onChange={(e) => setDescription(e?.target?.value)}
        />
      </FormControl>

      {errors ? <ErrorMessages errors={errors} /> : null}

      <div style={{ display: "flex" }}>
        <div style={{ marginLeft: "auto" }}>
          {isInModal ? (
            <>
              <Button
                type="button"
                list="true"
                danger
                onClick={() => modalClose()}
              >
                Cancel
              </Button>
              <Button
                type="button"
                disabled={!name}
                onClick={() => createATag()}
              >
                {loading ? <Spinner /> : "Create"}
              </Button>
            </>
          ) : (
            <Button type="button" disabled={!name} onClick={() => createATag()}>
              {loading ? <Spinner /> : "Create"}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default CreateTag;
