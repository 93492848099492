import React, { useCallback, useEffect, useState } from "react";
import Modal from "../Modal";
import Button from "../Button";
import { allCrossTableDataSources } from "../../api/dataSourceQueries";
import { useApi } from "../../api/useApi";
import SortTable from "../Table/SortTable";
import styled from "styled-components/macro";
import {
  MdRemoveCircle,
  MdExpandMore,
  MdExpandLess,
  MdCheckCircle,
  MdClose,
} from "react-icons/md";
import { Scrollbars } from "react-custom-scrollbars";
import { StyledInput } from "../Form/FormControls";
import { FormControl, FormActions } from "../Form/FormControls";

const RemoveColumn = styled.div`
  margin-left: auto;
  margin-right: 1rem;
`;

const ColumnComp = styled.div`
  padding: 0.5rem;
  margin-bottom: 0.5rem;
  cursor: pointer;
  display: flex;
  align-items: center;
`;

const ColumnPillContainers = styled.div`
  padding: 0.5rem;
  border-radius: 0.5rem;
  border: solid 1px #ccc;
  text-align: center;
`;

const ColumnPillSourceName = styled.div`
  font-size: 0.6rem;
  margin-bottom: 0.5rem;
`;

const ColumnPill = ({ column, fetchedColumnData, isId2 }) => {
  const dataSourceColumn = isId2
    ? column?.dataSourceColumn2
    : column?.dataSourceColumn1;
  const dataSourceName = fetchedColumnData.find(
    (fcd) => fcd?.id === dataSourceColumn?.dataSourceId
  )?.name;
  return (
    <ColumnPillContainers>
      <ColumnPillSourceName>{dataSourceName}</ColumnPillSourceName>
      <div>{dataSourceColumn?.name}</div>
    </ColumnPillContainers>
  );
};

const Columns = ({ columns, setMatchedColumn, isId2, matchedColumns }) => {
  return columns?.map((c) => {
    let isSelected;
    if (isId2) {
      isSelected = c?.id === matchedColumns?.dataSourceColumn2?.id;
    } else {
      isSelected = c?.id === matchedColumns?.dataSourceColumn1?.id;
    }
    return (
      <ColumnComp
        style={{
          background: isSelected ? "#eeeeee" : "#fefefe",
          border: isSelected ? "1px solid #555" : "1px solid #fefefe",
          wordBreak: "break-all",
          overflowWrap: "break-word",
        }}
        onClick={() =>
          setMatchedColumn((prevState) => {
            if (isId2) {
              return {
                ...prevState,
                dataSourceColumnId2:
                  prevState?.dataSourceColumnId2 === c?.id ? null : c?.id,
                dataSourceColumn2:
                  prevState?.dataSourceColumnId2 === c?.id ? null : c,
              };
            } else {
              return {
                ...prevState,
                dataSourceColumnId1:
                  prevState?.dataSourceColumnId1 === c?.id ? null : c?.id,
                dataSourceColumn1:
                  prevState?.dataSourceColumnId1 === c?.id ? null : c,
              };
            }
          })
        }
      >
        <div style={{ flex: 1 }}>{c?.name}</div>{" "}
        {isSelected && (
          <RemoveColumn>
            <MdClose style={{ color: "#f87e7e" }} />
          </RemoveColumn>
        )}
      </ColumnComp>
    );
  });
};

const ColumnList = ({
  dataSource,
  setMatchedColumn,
  isId2,
  matchedColumns,
}) => {
  const vertTrackWidthChosen = "8px"; // Original is 6px
  const [filterText, setFilterText] = useState("");
  const [filteredColumns, setFilteredColumns] = useState(dataSource?.columns);

  const filterColumns = useCallback(
    (event) => {
      const searchText = event.target.value;
      const filtered = dataSource?.columns.filter((column) =>
        column.name.toLowerCase().includes(searchText.toLowerCase())
      );
      setFilteredColumns(filtered);
      setFilterText(event?.target?.value);
    },
    [dataSource?.columns]
  );

  return (
    <div
      style={{
        flex: 1,
        marginRight: "1rem",
        background: "#ccc",
        padding: ".5rem",
      }}
    >
      <h4>{dataSource?.name}</h4>

      <div style={{ display: "flex", alignItems: "center" }}>
        <div style={{ flex: 1, marginRight: "1rem" }}>
          <FormControl>
            <StyledInput
              type="text"
              name="Column Filter"
              label={"Filter Columns"}
              value={filterText}
              placeholder={"Filter Columns"}
              onChange={filterColumns}
            />
          </FormControl>
        </div>

        <div>
          <Button
            danger
            type="button"
            disabled={filterText === ""}
            onClick={() => {
              setFilterText("");
              setFilteredColumns(dataSource?.columns);
            }}
          >
            Clear Filter
          </Button>
        </div>
      </div>

      <Scrollbars
        renderTrackVertical={({ style, ...props }) => {
          return (
            <div
              {...props}
              className={"scrollbarTrackVertical"}
              style={{ ...style, width: vertTrackWidthChosen }}
            />
          );
        }}
        autoHeightMax={350}
        autoHeight
      >
        <Columns
          columns={filteredColumns}
          setMatchedColumn={setMatchedColumn}
          isId2={isId2}
          matchedColumns={matchedColumns}
        />
      </Scrollbars>
    </div>
  );
};

const ColumnPairSection = ({
  ts,
  matched,
  locallySelected,
  removeMatchingColumn,
  showPair,
  setShowPair,
  updateMatchingColumns,
  setMatchedColumns,
  matchedColumns,
}) => {
  const matchedColumnsPairs = matched.filter((m) => {
    return ts?.id === m?.dataSourceColumn2?.dataSourceId;
  });

  const isShowing = showPair === ts?.id;
  return (
    <div style={{ marginBottom: ".2rem" }}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          background: "#efefef",
          padding: ".5rem",
          cursor: "pointer",
          marginBottom: "1rem",
          wordBreak: "break-all",
          overflowWrap: "break-word",
        }}
        onClick={() => setShowPair(isShowing ? null : ts?.id)}
      >
        <div>{isShowing ? <MdExpandLess /> : <MdExpandMore />}</div>
        <div
          style={{
            marginRight: "1rem",
            fontWeight: isShowing ? "bold" : "inherit",
          }}
        >
          {ts?.name}
        </div>
        {matchedColumnsPairs?.length ? (
          <div style={{ marginLeft: "auto", color: "#009fd4" }}>
            <MdCheckCircle />
          </div>
        ) : null}
      </div>

      {isShowing && (
        <>
          <div style={{ display: "flex", marginBottom: "1rem" }}>
            <ColumnList
              dataSource={locallySelected[0]}
              setMatchedColumn={setMatchedColumns}
              matchedColumns={matchedColumns}
            />
            <ColumnList
              dataSource={locallySelected.find((fcd) => fcd?.id === ts?.id)}
              isId2={true}
              setMatchedColumn={setMatchedColumns}
              matchedColumns={matchedColumns}
            />
          </div>
          <div style={{ display: "flex" }}>
            <div
              style={{
                flex: 1,
                marginLeft: "auto",
                justifyContent: "right",
                marginBottom: "1rem",
              }}
            >
              <Button
                onClick={() => updateMatchingColumns()}
                disabled={
                  !matchedColumns?.dataSourceColumnId1 ||
                  !matchedColumns?.dataSourceColumnId2
                }
              >
                Add Selected Pair
              </Button>
            </div>
          </div>

          {matchedColumnsPairs?.length ? (
            <div style={{ marginBottom: "1rem" }}>
              {matchedColumnsPairs.map((mc) => {
                return (
                  <div
                    style={{
                      display: "flex",
                      marginBottom: ".5rem",
                      alignItems: "center",
                    }}
                  >
                    <ColumnPill
                      column={mc}
                      fetchedColumnData={locallySelected}
                      isId2={false}
                    />
                    <div
                      style={{ borderBottom: "1px solid #ccc", width: ".5rem" }}
                    ></div>
                    <ColumnPill
                      column={mc}
                      fetchedColumnData={locallySelected}
                      isId2={true}
                    />
                    <div style={{ marginLeft: ".5rem" }}>
                      <Button
                        size="small"
                        danger
                        onClick={() => removeMatchingColumn(mc)}
                      >
                        <MdRemoveCircle />
                      </Button>
                    </div>
                  </div>
                );
              })}
            </div>
          ) : null}
        </>
      )}
    </div>
  );
};

const PrimaryKeyManagement = ({
  locallySelected,
  updateDataSources,
  pKMatchingColumns,
}) => {
  const [matchedColumns, setMatchedColumns] = useState(null);
  const [matched, setMatched] = useState(pKMatchingColumns);
  const [filterText, setFilterText] = useState("");
  const [filteredSources, setFilteredSources] = useState(
    locallySelected.slice(1) ?? locallySelected
  );

  const [showPair, setShowPair] = useState();

  const updateMatchingColumns = () => {
    setMatched((prevState) => {
      return [...prevState, matchedColumns];
    });
    setMatchedColumns(null);
  };

  const removeMatchingColumn = (column) => {
    setMatched((prevState) => {
      const filtered = prevState.filter(
        (ps) => JSON.stringify(ps) !== JSON.stringify(column)
      );
      return [...filtered];
    });
  };

  const saveData = () => {
    const availableDatasources = locallySelected;

    const matchingColumns = matched
      .filter((m) => {
        const found = availableDatasources.find(
          (ls) => ls?.id === m?.dataSourceColumn2?.dataSourceId
        );
        if (found) {
          return m;
        } else {
          return false;
        }
      })
      .map((ns) => {
        return {
          dataSourceColumn1: ns?.dataSourceColumn1,
          dataSourceColumn2: ns?.dataSourceColumn2,
        };
      });
    updateDataSources(locallySelected, matchingColumns);
  };

  const filterSources = useCallback(
    (event) => {
      const searchText = event.target.value;
      const filtered = locallySelected
        .slice(1)
        .filter((source) =>
          source.name.toLowerCase().includes(searchText.toLowerCase())
        );
      setFilteredSources(filtered);
      setFilterText(event?.target?.value);
    },
    [locallySelected]
  );

  return (
    <div>
      <div style={{ marginBottom: "1rem" }}>
        <h4>Column Pairs for Unique Row Identification</h4>
        Select A Data Source to add Column Pairs for Unique Row Identification.
        You can toggle between sources below by clicking on their heading. For
        "Exist Rules", these pairings are <b>NOT</b> required.
        <p>
          <span style={{ marginLeft: "auto", color: "#009fd4" }}>
            <MdCheckCircle />
          </span>{" "}
          indicates existing pairs.
        </p>
      </div>
      <Scrollbars autoHeightMax={window.innerHeight * 0.6} autoHeight>
        <div style={{ marginTop: "1rem" }}>
          <div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <div style={{ flex: 1, marginRight: "1rem" }}>
                <FormControl>
                  <StyledInput
                    type="text"
                    name="Data Source Filter"
                    label={"Filter Sources"}
                    value={filterText}
                    placeholder={"Filter DataSources"}
                    onChange={filterSources}
                  />
                </FormControl>
              </div>

              <div>
                <Button
                  danger
                  type="button"
                  disabled={filterText === ""}
                  onClick={() => {
                    setFilterText("");
                    setFilteredSources(locallySelected.slice(1));
                  }}
                >
                  Clear Filter
                </Button>
              </div>
            </div>

            {filteredSources.map((ts, i) => {
              return (
                <ColumnPairSection
                  ts={ts}
                  key={i}
                  matched={matched}
                  locallySelected={locallySelected}
                  removeMatchingColumn={removeMatchingColumn}
                  setShowPair={setShowPair}
                  showPair={showPair}
                  matchedColumns={matchedColumns}
                  setMatchedColumns={setMatchedColumns}
                  updateMatchingColumns={updateMatchingColumns}
                />
              );
            })}
          </div>
        </div>
      </Scrollbars>
      <div style={{ textAlign: "center" }}>
        <div style={{ marginBottom: ".5rem" }}>
          {matchedColumns?.dataSourceColumn2 ? (
            <p>
              You have unsaved pairs in{" "}
              <i style={{ color: "red" }}>
                {" " +
                  matchedColumns?.dataSourceColumn2?.fullyQualifiedName?.split(
                    /\|/
                  )?.[0]}
              </i>{" "}
              -{" "}
              <Button
                danger
                size="small"
                onClick={() => setMatchedColumns(null)}
              >
                x
              </Button>
            </p>
          ) : null}
        </div>
        <Button
          onClick={() => saveData()}
          disabled={matchedColumns?.dataSourceColumn2}
        >
          Finish
        </Button>
      </div>
    </div>
  );
};

const AddDataSourceModal = ({
  hide,
  sourceId,
  selectedDataSources,
  updateDataSources,
  pKMatchingColumns,
}) => {
  const [locallySelected, setLocallySelected] = useState([
    ...selectedDataSources,
  ]);

  //Init Data Fetch
  const [{ loading, data: apiData }, doFetch] = useApi();

  const dataSources = apiData?.availableCrosstableDataSources?.edges ?? [];

  useEffect(() => {
    doFetch({
      query: allCrossTableDataSources,
        variables: {
          first: 10000,
          order: {
            name: "ASC",
        },
      },
    });
  }, [doFetch]);

  const updateLocally = useCallback(
    (source) => {
      const foundInternally = locallySelected.find(
        (ds) => ds?.id === source?.id
      );

      if (foundInternally) {
        setLocallySelected((prevState) => {
          return prevState.filter((s) => s?.id !== source?.id);
        });
      } else {
        setLocallySelected((prevState) => [...prevState, source]);
      }
    },
    [setLocallySelected, locallySelected]
  );

  const [viewPrimary, setViewPrimary] = useState(false);

  //Fetch for Table Paged
  // const fetchData = React.useCallback(
  //   ({ pageSize, cursor }) => {
  //     doFetch({
  //       query: allCrossTableDataSources,
  //       variables: {
  //         first: pageSize,
  //         after: cursor,
  //         order: {
  //           name: "ASC",
  //         },
  //       },
  //     });
  //   },
  //   [doFetch]
  // );

  const columnsData = [
    {
      Header: "",
      id: "Selected",

      width: "100px",
      Cell: ({ row: { original } }) => {
        const foundInternally = locallySelected.find(
          (ds) => ds?.id === original?.node?.id
        );
        const isSelected = foundInternally;

        return (
          <div>
            <input
              disabled={Number(sourceId) === original?.node?.id}
              type="checkbox"
              name={`datasources.${original?.node?.id}`}
              label="Use Data Source in Cross-table"
              checked={isSelected}
              onChange={() => updateLocally(original?.node)}
            />
          </div>
        );
      },
    },
    {
      Header: "Name",
      id: "Name",
      accessor: "node.name",
      Cell: ({ row: { original } }) => {
        return original?.node?.name;
      },
    },
  ];

  return (
    <Modal
      title={`Add Data Sources`}
      hide={() => hide((prevState) => !prevState)}
      large
    >
      {viewPrimary ? (
        <PrimaryKeyManagement
          pKMatchingColumns={pKMatchingColumns}
          locallySelected={locallySelected}
          updateDataSources={updateDataSources}
        />
      ) : (
        <>
          <Scrollbars autoHeightMax={window.innerHeight * 0.7} autoHeight>
            <SortTable
              dontReset={true}
              data={dataSources}
              columns={columnsData}
              loading={loading}
              hidePagination={true}
              defaultPageSize={9999}
            />
          </Scrollbars>
          <div>
            <FormActions>
              <Button
                type="button"
                disabled={locallySelected?.length <= 1}
                onClick={() => {
                  setViewPrimary(true);
                }}
              >
                Continue
              </Button>
            </FormActions>
          </div>
        </>
      )}
    </Modal>
  );
};

export default AddDataSourceModal;
