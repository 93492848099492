export const dataTypeOptions = [
  { value: "Blob", label: "Blob", acceptableMappings: ["blob"] },
  {
    value: "Boolean",
    label: "Boolean",
    acceptableMappings: ["boolean", "wholenumber", "string", "decimal"],
  },
  {
    value: "DateTime",
    label: "Date Time",
    acceptableMappings: ["datetime", "string"],
  },
  {
    value: "Decimal",
    label: "Decimal",
    acceptableMappings: ["decimal", "wholenumber", "string", "boolean"],
  },
  { value: "Email", label: "Email", acceptableMappings: ["email", "string"] },
  { value: "List", label: "List", acceptableMappings: ["list", "string"] },
  {
    value: "PhoneNumber",
    label: "Phone Number",
    acceptableMappings: ["phonenumber", "string"],
  },
  {
    value: "string",
    label: "string",
    acceptableMappings: [
      "string",
      "dateTime",
      "decimal",
      "email",
      "list",
      "phonenumber",
      "wholenumber",
      "boolean",
      "uuid",
    ],
  },
  { value: "Uuid", label: "Uuid", acceptableMappings: ["uuid", "string"] },
  {
    value: "Whole_Number",
    label: "Whole Number",
    acceptableMappings: ["wholenumber", "decimal", "string", "boolean"],
  },
];

export const trackerOptions = [
  { value: "AZURE_DEVOPS", label: "Azure Dev Ops" },
  { value: "JIRA", label: "Jira" },
  { value: "SERVICE_NOW", label: "Service Now" },
  { value: "QUALITY_CENTER", label: "HP Quality Center" },
  { value: "VSTS", label: "Visual Studio Team Services" },
];

export const priorityLevelOptions = [
  { value: "HIGH", label: "High" },
  { value: "NORMAL", label: "Normal" },
  { value: "LOW", label: "Low" },
];

export const namingConventions = [
  {
    label: "id_type.fileFormat",
    value: "ID_TYPE",
  },
  {
    label: "id_type_version.fileFormat",
    value: "ID_TYPE_VERSION",
  },
  {
    label: "id_type_version_timestamp.fileFormat",
    value: "ID_TYPE_VERSION_TIMESTAMP",
  },
];

export const approvalStates = [
  { label: "Pending", value: "PENDING" },
  { label: "Ready for Review", value: "READY_FOR_REVIEW" },
  { label: "Approved", value: "APPROVED" },
  { label: "Rejected", value: "REJECTED" },
];
