import { useState, useEffect } from "react";
import UseDataSourcePercentage from "../../../Hooks/useDataSourcePercentage";
const Processing = ({ sourceId, inline }) => {
  const { dataSourcePercentageComplete, setDataSourcePercentageComplete } =
    UseDataSourcePercentage();
  const [complete, setComplete] = useState("");

  useEffect(() => {
    if (dataSourcePercentageComplete?.payload?.SourceId === sourceId) {
      if (dataSourcePercentageComplete?.payload?.PercentageComplete === 100) {
        setDataSourcePercentageComplete(null);
        setComplete("Finalizing");
      } else {
        setComplete(
          inline
            ? ` ` +
                dataSourcePercentageComplete?.payload?.PercentageComplete +
                `%`
            : `Processing ` +
                dataSourcePercentageComplete?.payload?.PercentageComplete +
                `%`
        );
      }
    }
  }, [
    inline,
    dataSourcePercentageComplete,
    sourceId,
    setDataSourcePercentageComplete,
  ]);

  useEffect(() => {
    if (complete === "Finalizing") {
      setTimeout(() => {
        setComplete("");
      }, 3000);
    }
  }, [complete]);

  return complete;
};

export default Processing;
