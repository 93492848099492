import { useContext } from "react";
import { SocketContext } from "../contexts/useSubscriptions";

const UseWorkflowUpdateEvent = () => {
  const { workflowUpdateCompleteEvent, setWorkflowUpdateCompleteEvent } =
    useContext(SocketContext);

  return { workflowUpdateCompleteEvent, setWorkflowUpdateCompleteEvent };
};

export default UseWorkflowUpdateEvent;
