// Organization Mutations
export const createOrganization = /* GraphQL */ `
  mutation ($organization: OrganizationInputModelInput!) {
    createOrganization(organization: $organization) {
      id
    }
  }
`;

// Organization Enable Flag
export const updateOrganizationEnabledFlag = /* GraphQL */ `
  mutation ($id: Int!, $enabledState: Boolean!) {
    updateOrganizationEnabledFlag(id: $id, enabledState: $enabledState) {
      id
    }
  }
`;

// Update Organization
export const updateOrganization = /* GraphQL */ `
  mutation ($id: Int!, $displayName: String!, $role: OrganizationRolesType!) {
    updateOrganization(id: $id, displayName: $displayName, role: $role) {
      id
    }
  }
`;

// Org Consent State
export const setOrganizationToConsentState = /* GraphQL */ `
  mutation ($id: Int!) {
    setOrganizationToConsentState(id: $id) {
      id
    }
  }
`;

// Org Consent State
export const startOrganizationFirstSync = /* GraphQL */ `
  mutation ($organizationIdentifier: String!) {
    startOrganizationFirstSync(
      organizationIdentifier: $organizationIdentifier
    ) {
      id
    }
  }
`;

// Check Orgs
export const checkADGroupExists = /* GraphQL */ `
  mutation ($groupIdorName: String!, $organizationId: Int!) {
    checkADGroupExists(
      groupIdorName: $groupIdorName
      organizationId: $organizationId
    ) {
      id
      displayName
      emailAddress
      type
      organizationId
      members {
        groupId
        userId
      }
    }
  }
`;

// Set Group Active
export const setGroupActive = /* GraphQL */ `
  mutation ($groups: [PlatformGroupInputModelInput!]!) {
    setGroupActive(groups: $groups) {
      id
    }
  }
`;
