import React, { useState, useCallback, useEffect } from "react";
import { MdViewColumn } from "react-icons/md";
import DraggableColumn from "./DraggableColumn";
import styled from "styled-components/macro";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DndProvider } from "react-dnd";
import RuleFragmentForm from "../RuleFragment/RuleFragmentForm";
import { businessRuleStandard } from "../../api/ruleQueries";
import { useApi } from "../../api/useApi";
import { sortByCreatedOn } from "../../common/helpers/util";
import SplashLoader from "../Loaders/SplashLoader";
import { dataSourceForCrosstable } from "../../api/dataSourceQueries";
import ReconciliationMappingSettings from "./ReconciliationMappingSettings";
import Modal from "../Modal";
import ReconciliationRuleSettings from "../Forms/Sources/ReconciliationRuleSettings";
import AddDataSourceModal from "./AddDataSourceModal";
import { MdExpandMore, MdExpandLess } from "react-icons/md";
import SuggestionMapping from "./SuggestionMapping";
import { getSuggested } from "./getSuggestedColumnsHelper";
import { ToastContainer, toast } from "react-toastify";
import { GiArtificialHive } from "react-icons/gi";
import ErrorMessages from "../Notifications/ErrorMessages";

const ColumnSection = styled.div`
  box-sizing: border-box;
  width: 100%;
  top: 0;
  left: 0;
  max-width: 300px;
`;

const ColumnHelperText = styled.div`
  font-size: 0.8em;
  padding: 4px;
  color: ${(props) => props.theme.onSurface};
`;

const ColumnHeading = styled.div`
  color: #fff;
  background: #666;
  padding: 10px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
`;

const ColumnHeadingIconWrap = styled.div`
  font-size: 1.5em;
  display: flex;
  margin-right: 0.5rem;
`;

const FilterInput = styled.input`
  align-items: center;
  border: 1px solid transparent;
  border-radius: 4px;
  display: inline-flex;
  font-size: 1rem;
  height: 2.25em;
  justify-content: flex-start;
  line-height: 1.5;
  padding-bottom: calc(0.375em - 1px);
  padding-left: calc(0.625em - 1px);
  padding-right: calc(0.625em - 1px);
  padding-top: calc(0.375em - 1px);
  position: relative;
  vertical-align: top;
  background-color: ${(props) => props.theme.surface};
  border-color: ${(props) => props.theme.secondary};
  color: ${(props) => props.theme.onSurface};
  /* box-shadow: inset 0 1px 2px rgba(10, 10, 10, 0.1); */
  max-width: 100%;
  width: 100%;
  margin-bottom: 10px;
`;

const ColumnList = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  max-height: calc(100vh - 535px);
`;

const DropZoneArea = styled.div`
  flex: 1;
  margin-right: 25px;
  margin-left: 25px;
  flex-direction: column;
  display: flex;
  overflow-y: auto;
  flex-grow: 1;
`;

const ColumnSelection = styled.div`
  border-top: 2px solid #ccc;
  border-bottom: 2px solid #ccc;
  padding: 1rem;
  font-weight: bold;
  cursor: pointer;
  overflow-wrap: break-word;
  word-break: break-all;
  display: flex;
  &:hover {
    opacity: 0.8;
  }
`;

const DataSourceColumns = ({
  dataSource,
  index,
  isEditing,
  suggestedColumns,
  currentFragments,
}) => {
  const [expanded, setExpanded] = useState(!index);

  const columns = dataSource?.columns ?? [];
  const filteredSuggestions = suggestedColumns?.suggestions?.filter(
    (sc) => sc?.dataSourceId === dataSource?.id
  );

  const [columnsList, setColumnsList] = useState(dataSource?.columns);

  const filterColumns = (event) => {
    let newColumns = columns;

    newColumns = newColumns.filter((column) => {
      return (
        column.name.toLowerCase().search(event.target.value.toLowerCase()) !==
        -1
      );
    });

    setColumnsList(newColumns);
  };

  const isSuggestionDisabled = window.localStorage.getItem(
    "disable-suggestions"
  );

  return (
    <ColumnSection>
      <ColumnSelection onClick={() => setExpanded((prevState) => !prevState)}>
        <div>{expanded ? <MdExpandLess /> : <MdExpandMore />}</div>{" "}
        <div>{dataSource?.name} </div>
      </ColumnSelection>

      {expanded ? (
        <div>
          <ColumnHelperText>Drag and drop Columns to Standard</ColumnHelperText>
          <ColumnHeading>
            <ColumnHeadingIconWrap>
              <MdViewColumn />{" "}
            </ColumnHeadingIconWrap>
            Columns
          </ColumnHeading>
          <FilterInput
            type="text"
            placeholder="Search"
            onChange={filterColumns}
          />
          <ColumnList>
            {filteredSuggestions?.length && !isSuggestionDisabled ? (
              <SuggestionMapping
                isFirst={!index}
                suggestedColumns={filteredSuggestions}
                currentFragments={currentFragments}
              />
            ) : null}

            {columnsList.map((dragItem, index) => {
              return (
                <DraggableColumn
                  item={dragItem}
                  name={dragItem.name}
                  currentFragments={currentFragments}
                  type={"column"}
                  dataType={dragItem.dataType.toLowerCase()}
                  key={`draggableColumn-${index}`}
                  // isDropped={this.isDropped(dragItem.name)}
                />
              );
            })}
          </ColumnList>
        </div>
      ) : null}
    </ColumnSection>
  );
};

const MappingForm = ({
  standardId,
  sourceId,
  selectedInstance,
  mappingType,
}) => {
  //Need to handle selected instance
  const [staticColumnsList, setStaticColumnsList] = useState([]);
  const [shownSuggestedMessage, setShownSuggestedMessage] = useState(false);
  const [selectedDataSources, setSelectedDataSources] = useState(null);
  const [pKMatchingColumns, setPKMatchingColumns] = useState();
  const [showReconciliationSettings, setShowReconciliationSettings] =
    useState(false);
  const [showDataSourceSelector, setShowDataSourceSelector] = useState(false);
  const [currentFragments, setCurrentFragments] = useState();

  //Get Rule
  const [
    { errors: standardErrors, loading: loadingStandard, data: standardData },
  ] = useApi(businessRuleStandard, {
    standardId: Number(standardId),
  });

  //Columns
  //Get Rule
  const [
    { loading: loadingSource, data: dataSourceData },
    getDataSourceColumns,
  ] = useApi(dataSourceForCrosstable, {
    id: Number(sourceId),
    where: {
      secondaryDataSources: {
        enabled: true,
      },
    },
  });

  const updateFragments = useCallback(
    (fragmentsFromForm) => {
      setCurrentFragments(fragmentsFromForm);
    },
    [setCurrentFragments]
  );

  useEffect(() => {
    if (dataSourceData) {
      const matchingColumns =
        dataSourceData?.dataSourceForCrosstable?.matchingColumns ?? [];

      setStaticColumnsList(
        dataSourceData?.dataSourceForCrosstable?.primaryDataSource?.columns
      );
      setPKMatchingColumns(matchingColumns);
      setSelectedDataSources([
        { ...dataSourceData?.dataSourceForCrosstable?.primaryDataSource },
        ...dataSourceData?.dataSourceForCrosstable?.secondaryDataSources.filter(
          (ds) =>
            ds?.id !==
            dataSourceData?.dataSourceForCrosstable?.primaryDataSource?.id
        ),
      ]);
    }
  }, [dataSourceData, setStaticColumnsList, setPKMatchingColumns]);

  const columns =
    selectedDataSources
      ?.map((ds) => {
        return ds?.columns;
      })
      .flat() ?? [];

  const versions = standardData?.businessRuleStandardById?.versions ?? [];

  const sortedVersion = sortByCreatedOn(versions);

  const standard = sortedVersion[0] ?? null;
  const standardVersionId = sortedVersion[0]?.id ?? null;
  const isSuggestionsDisabled = window.localStorage.getItem(
    "disable-suggestions"
  );
  let suggestedColumns = null;
  if (columns?.length && !isSuggestionsDisabled) {
    const suggested = getSuggested(standard, columns, standard?.standard?.name);
    suggestedColumns = suggested;
    if (selectedInstance) {
      suggestedColumns = [];
    }
  }

  const standardName = standardData?.businessRuleStandardById?.name ?? null;

  if (loadingSource || loadingStandard || !columns?.length)
    return <SplashLoader text="Loading Mapping Data" />;

  if (suggestedColumns === null && !isSuggestionsDisabled)
    return <SplashLoader text="Analyzing Data" />;

  const businessRules =
    standard?.fragments
      .filter((frag) => frag.typeInformation[0].typeValue === 3) // First Fragment of an Operation has a typeValue = 3
      .map((operationFrag) => operationFrag.typeInformation[1].typeValue) ?? []; // Second Fragment of an Operation's typeValue is the businessRule

  const isAReconciliationRule =
    businessRules.includes(14) || businessRules.includes(15);

  let matchingColumns = [];
  let asOfDateColumns = [];

  if (isAReconciliationRule) {
    matchingColumns = staticColumnsList.filter(
      (col) => col.flags >= 18 && col.flags <= 22
    );
    asOfDateColumns = staticColumnsList.filter((col) => col.flags === 32);
  }

  const modalAction = () => {
    getDataSourceColumns({
      query: dataSourceForCrosstable,
      variables: { id: Number(sourceId) },
    });
    setShowReconciliationSettings(false);
  };

  const updateDataSources = (sources, matchingColumns) => {
    setShowDataSourceSelector((prevState) => !prevState);
    setSelectedDataSources(sources);
    setPKMatchingColumns(matchingColumns);
  };

  if (
    suggestedColumns?.suggestions?.length &&
    !shownSuggestedMessage &&
    !selectedInstance
  ) {
    toast.info("Suggestions Found and Applied", {
      position: "top-center",
      autoClose: 4000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      icon: GiArtificialHive,
    });
    setShownSuggestedMessage(true);
  }

  return (
    <>
      {standardErrors ? <ErrorMessages errors={standardErrors} /> : null}
      {showReconciliationSettings ? (
        <Modal noWidth hide={() => setShowReconciliationSettings(false)}>
          <ReconciliationRuleSettings
            sourceId={sourceId}
            modalAction={modalAction}
          />
        </Modal>
      ) : null}

      <DndProvider backend={HTML5Backend}>
        <div style={{ display: "flex", alignSelf: "flex-start" }}>
          <div>
            {/* Sticky Columns for Mapping */}
            <div
              style={{
                position: "sticky",
                top: 0,
                minHeight: "calc(100vh - 320px)",
              }}
            >
              {selectedDataSources?.map((ds, i) => {
                return (
                  <DataSourceColumns
                    key={`${ds?.id}i`}
                    dataSource={ds}
                    isEditing={selectedInstance}
                    index={i}
                    suggestedColumns={suggestedColumns}
                    currentFragments={currentFragments}
                  />
                );
              })}
              {showDataSourceSelector ? (
                <AddDataSourceModal
                  sourceId={sourceId}
                  selectedDataSources={selectedDataSources}
                  pKMatchingColumns={pKMatchingColumns}
                  updateDataSources={updateDataSources}
                  hide={setShowDataSourceSelector}
                />
              ) : (
                <div
                  onClick={() =>
                    setShowDataSourceSelector((prevState) => !prevState)
                  }
                  style={{
                    borderTop: "solid 2px #000",
                    padding: "1rem",
                    cursor: "pointer",
                  }}
                >
                  +{" "}
                  {selectedDataSources
                    ? "Manage Data Sources"
                    : "Add Data Sources"}
                </div>
              )}
            </div>
          </div>
          <DropZoneArea>
            {standard && (
              <>
                {/* Possibly add a update to the standard api to indicate this check*/}

                {isAReconciliationRule && (
                  <ReconciliationMappingSettings
                    matchingColumns={matchingColumns}
                    asOfDateColumns={asOfDateColumns}
                    setShowReconciliationSettings={
                      setShowReconciliationSettings
                    }
                  />
                )}
                <RuleFragmentForm
                  template={standard}
                  standardId={standardId}
                  standardVersionId={standardVersionId}
                  standardName={standardName}
                  columns={columns}
                  sourceId={sourceId}
                  editingTemplate={selectedInstance}
                  isFilter={mappingType === "FILTER"}
                  isAReconciliationRule={isAReconciliationRule}
                  matchingColumns={matchingColumns}
                  pKMatchingColumns={pKMatchingColumns}
                  asOfDateColumns={asOfDateColumns}
                  selectedDataSources={selectedDataSources}
                  suggestedColumns={suggestedColumns}
                  updateFragments={updateFragments}
                />
              </>
            )}
          </DropZoneArea>
        </div>
      </DndProvider>
      <ToastContainer />
    </>
  );
};

export default MappingForm;
