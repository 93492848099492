import React, { useCallback, useState } from "react";
import Button from "../../components/Button";
import { availableCrosstableDataSources } from "../../api/dataSourceQueries";
import { useApi } from "../../api/useApi";
import PagedTable from "../../components/Table/PagedTable";
import { FormActions } from "../../components/Form/FormControls";

const SelectIngestSources = ({
  sourceId,
  selectedDataSources,
  dispatch,
  feed,
  sourceType,
}) => {
  const [locallySelected, setLocallySelected] = useState([
    ...selectedDataSources,
  ]);

  //Init Data Fetch
  const [{ loading, data: apiData }, doFetch] = useApi();

  const dataSources = apiData?.availableCrosstableDataSources?.edges ?? [];
  const totalCount = apiData?.availableCrosstableDataSources?.totalCount;
  const pageInfo = apiData?.availableCrosstableDataSources?.pageInfo;

  const updateLocally = useCallback(
    (source) => {
      const foundInternally = locallySelected.find(
        (ds) => ds?.id === source?.id
      );

      if (foundInternally) {
        setLocallySelected((prevState) => {
          return prevState.filter((s) => s?.id !== source?.id);
        });
      } else {
        setLocallySelected((prevState) => [...prevState, source]);
      }
    },
    [setLocallySelected, locallySelected]
  );

  //Fetch for Table Paged
  const fetchData = React.useCallback(
    ({ pageSize, cursor }) => {
      if (feed) {
        doFetch({
          query: availableCrosstableDataSources,
          variables: {
            first: pageSize,
            after: cursor,
            where: {
              or: [
                {
                  tagInstances: {
                    some: {
                      tagId: { eq: sourceType },
                    },
                  },
                },
              ],
              and: [{ enabled: { eq: true } }],
            },
            order: {
              name: "ASC",
            },
          },
        });
      } else {
        doFetch({
          query: availableCrosstableDataSources,
          variables: {
            first: pageSize,
            after: cursor,
            where: {
              enabled: { eq: true },
              id: { not: { eq: -2 } },
            },
            order: {
              name: "ASC",
            },
          },
        });
      }
    },
    [doFetch, sourceType, feed]
  );

  const columnsData = [
    {
      Header: "",
      id: "Selected",
      accessor: "Selected",
      width: "100px",
      Cell: ({ row: { original } }) => {
        const foundInternally = locallySelected.find(
          (ds) => ds?.id === original?.node?.id
        );
        const isSelected = foundInternally;

        return (
          <div>
            <input
              disabled={Number(sourceId) === original?.node?.id}
              type="checkbox"
              name={`datasources.${original?.node?.id}`}
              label="Select"
              checked={isSelected}
              onChange={() => updateLocally(original?.node)}
            />
          </div>
        );
      },
    },
    {
      Header: "Id",
      id: "id",
      accessor: "node.id",
    },
    {
      Header: "Name",
      id: "Name",
      accessor: "name",
      Cell: ({ row: { original } }) => {
        return original?.node?.name;
      },
    },
    {
      Header: "Tags",
      id: "tags",
      Cell: ({ row: { original } }) => {
        return (
          <div>
            {original?.node?.tagInstances?.map((ti, i) => {
              const isLast = original?.node?.tagInstances?.length - 1 <= i;
              return (
                <div
                  style={{
                    display: "inline-block",
                    background: "#e6e6e6",
                    padding: "0.2rem",
                    paddingLeft: ".4rem",
                    paddingRight: ".4rem",
                    fontSize: ".8rem",
                    marginRight: isLast ? "" : ".5rem",
                    marginBottom: isLast ? "" : ".5rem",
                  }}
                >
                  {ti?.tag?.name}
                </div>
              );
            })}
          </div>
        );
      },
    },
  ];

  return (
    <>
      <PagedTable
        fetchData={fetchData}
        loading={loading}
        pageInfo={pageInfo}
        totalCount={totalCount}
        data={dataSources}
        columns={columnsData}
        defaultPageSize={50}
      />

      <div>
        <FormActions>
          <Button
            list
            onClick={() => dispatch({ type: "GO_BACK", payload: 1 })}
          >
            back
          </Button>

          <Button
            type="button"
            disabled={!locallySelected?.length}
            onClick={() => {
              dispatch({
                type: "SELECT_EXTERNAL_SOURCES",
                payload: locallySelected,
              });
            }}
          >
            Continue
          </Button>
        </FormActions>
      </div>
    </>
  );
};

export default SelectIngestSources;
