import React, { useState, useEffect } from "react";
import { useApi } from "../../api/useApi";
import SplashLoader from "../../components/Loaders/SplashLoader";
import { dataSourceStandardInstanceVersion } from "../../api/dataSourceQueries";
import ErrorMessages from "../../components/Notifications/ErrorMessages";
import Toggle from "../../components/Toggle";
import ViewStandard from "./ViewStandard";
import StyledLink from "../../components/StyledLink";
import {
  editDataQualityRules,
  dataSourceStandardVersionUpdate,
  dataSourceFiltersEdit,
  dataSourceFilterVersionUpdate,
} from "../../common/paths";
import { dataSourceForCrosstable } from "../../api/dataSourceQueries";
import { Scrollbars } from "react-custom-scrollbars";

const ViewMapping = ({
  sourceId,
  instanceVersionId,
  ruleInstanceId,
  ruleId,
  editEnabled /* boolean */,
  isFilter /* boolean */,
}) => {
  const [view, setView] = useState("Mapping");

  const [selectedDataSources, setSelectedDataSources] = useState(null);

  //Columns
  //Get Rule
  const [{ loading: loadingSource, data: dataSourceData }] = useApi(
    dataSourceForCrosstable,
    {
      id: Number(sourceId),
      where: {
        secondaryDataSources: {
          enabled: true,
        },
      },
    }
  );

  useEffect(() => {
    if (dataSourceData) {
      setSelectedDataSources([
        { ...dataSourceData?.dataSourceForCrosstable?.primaryDataSource },
        ...dataSourceData?.dataSourceForCrosstable?.secondaryDataSources,
      ]);
    }
  }, [dataSourceData, setSelectedDataSources]);

  const columns =
    selectedDataSources
      ?.map((ds) => {
        return ds?.columns;
      })
      .flat() ?? [];

  //Get DataSource By Id
  const [{ loading, errors, data }] = useApi(
    dataSourceStandardInstanceVersion,
    {
      id: Number(sourceId),
      instanceVersionId: Number(instanceVersionId),
    }
  );

  if (loading || loadingSource)
    return (
      <SplashLoader text={isFilter ? "Loading Filter" : "Loading Policy"} />
    );
  if (errors) return <ErrorMessages errors={errors} />;

  //Standard Version
  const standardVersion =
    data?.dataSource?.ruleInstanceVersionById?.standardVersion;

  //Instance
  const instance = data?.dataSource?.ruleInstanceVersionById;
  const isCrossTable =
    data?.dataSource?.ruleInstanceVersionById?.instance
      ?.isACrosstabRuleInstance;

  //Mappings
  const mappings = data?.dataSource?.ruleInstanceVersionById?.mappings;
  const calcMappings =
    data?.dataSource?.ruleInstanceVersionById?.calculationMappings;
  // const matchingColumns =
  //   data?.dataSource?.columns?.map((col) => {
  //     const dataSourceColumn1 = {
  //       id: col?.id,
  //       name: col?.name,
  //       dataSourceId: col?.dataSourceId,
  //     };

  //     const createdMatchingColumns = col?.matchingColumn?.map((mc) => {
  //       return { dataSourceColumn1, ...mc };
  //     });

  //     return createdMatchingColumns;
  //   }) ?? [];
  // const flattenMatching = matchingColumns.flat();

  // let builtDataSources = [{ ...data?.dataSource }];

  // flattenMatching.forEach((mc) => {
  //   const dataSource = mc?.dataSourceColumn2?.dataSource;

  //   const exists = builtDataSources.find((ds) => ds?.id === dataSource?.id);
  //   if (!exists) {
  //     builtDataSources.push(dataSource);
  //   }
  // });

  // //Columns
  // const columns =
  //   builtDataSources
  //     ?.map((ds) => {
  //       return ds?.columns;
  //     })
  //     .flat() ?? [];

  const toggleData = [
    {
      name: "Mapping",
      action: () => setView("Mapping"),
    },
    {
      name: isFilter ? "Filter" : "Policy",
      action: () => setView(isFilter ? "Filter" : "Policy"),
    },
  ];

  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "top",
          marginBottom: "1rem",
        }}
      >
        <Toggle toggleState={view} toggleData={toggleData} />
        {editEnabled && (
          <div style={{ marginLeft: "auto" }}>
            <StyledLink
              to={
                view === (isFilter ? "Filter" : "Policy")
                  ? isFilter
                    ? dataSourceFiltersEdit(ruleId)
                    : editDataQualityRules(ruleId)
                  : isFilter
                  ? dataSourceFilterVersionUpdate(
                      sourceId,
                      ruleInstanceId,
                      instanceVersionId
                    )
                  : dataSourceStandardVersionUpdate(
                      sourceId,
                      ruleInstanceId,
                      instanceVersionId
                    )
              }
              title={`Edit ${
                view === (isFilter ? "Filter" : "Policy")
                  ? isFilter
                    ? "Filter"
                    : "Policy"
                  : "Mapping"
              }`}
            >
              Edit{" "}
              {view === (isFilter ? "Filter" : "Policy")
                ? isFilter
                  ? "Filter"
                  : "Policy"
                : "Mapping"}
            </StyledLink>
          </div>
        )}
      </div>
      {view === (isFilter ? "Filter" : "Policy") ? (
        <Scrollbars autoHeightMax={window.innerHeight * 0.7} autoHeight>
          <ViewStandard standard={standardVersion} instance={instance} />
        </Scrollbars>
      ) : (
        <Scrollbars autoHeightMax={window.innerHeight * 0.7} autoHeight>
          <ViewStandard
            standard={standardVersion}
            instance={instance}
            mappings={mappings}
            calcMappings={calcMappings}
            columns={columns}
            isCrossTable={isCrossTable}
          />
        </Scrollbars>
      )}
    </>
  );
};

export default ViewMapping;
