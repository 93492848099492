import React, { useEffect } from "react";
import { Route } from "react-router-dom";
import styled from "styled-components/macro";
import { useApi } from "../api/useApi";
import { startOrganizationFirstSync } from "../api/organizationMutations";
import ErrorMessages from "../components/Notifications/ErrorMessages";
import { useHistory } from "react-router-dom";
import SplashLoader from "../components/Loaders/SplashLoader";

const FullHeightContainer = styled.div`
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ConsentRedirect = React.memo(() => {
  // Set Current URL
  const currentURL = new URL(window.location);
  // Set Tenant
  const tenant = currentURL.searchParams.get("tenant");
  // TODO: TAKE AUTH PARAM AND PASS INTO SET ORG TO
  // CONSENT ON REDIRECT SUCCESS, SEND TO WELCOME
  const [{ loading, errors, data }, startSync] = useApi();
  let history = useHistory();
  useEffect(() => {
    if (tenant) {
      startSync({
        query: startOrganizationFirstSync,
        variables: { organizationIdentifier: tenant },
      });
    }
  }, [tenant, startSync]);

  useEffect(() => {
    if (data && !errors) {
      history.push(`/welcome`);
    }
  }, [data, errors, history]);

  return (
    <Route
      render={(props) => (
        <FullHeightContainer>
          <div>
            <div style={{ textAlign: "center" }}>
              {tenant ? tenant : "No tenant returned"}
            </div>
            {loading ? (
              <div>
                <SplashLoader text="Syncing Organization, this will take a moment" />
              </div>
            ) : null}
            {errors ? (
              <div>
                <ErrorMessages errors={errors} />
              </div>
            ) : null}
          </div>
        </FullHeightContainer>
      )}
    />
  );
});

export default ConsentRedirect;
