import React, { useEffect, useRef, useState } from "react";
import debounce from "lodash.debounce";
import { FormControl, Label, StyledInput } from "../../Form/FormControls";

const SearchDataSources = ({ action }) => {
  const [inputText, setInputText] = useState("");
  const [searchText, setSearchText] = useState("");
  const prevSearchTextRef = useRef(searchText);

  useEffect(() => {
    // Call the action if there is searchText or if transitioning from searchText to no searchText
    if (searchText || (prevSearchTextRef.current && searchText === "")) {
      if (action) {
        action(searchText);
      }
    }

    // Update the reference to the current searchText for the next render
    prevSearchTextRef.current = searchText;
  }, [searchText, action]);

  const setSearchTextDebounced = useRef(
    debounce((searchText) => setSearchText(searchText), 500)
  ).current;

  const handleInputChangePrimary = (event) => {
    const currentInputValue = event?.target?.value || "";

    setInputText(currentInputValue);
    setSearchTextDebounced(currentInputValue);
  };

  return (
    <FormControl style={{ maxWidth: "300px" }}>
      <Label>Search Primary Key</Label>
      <StyledInput
        type="text"
        name="pksearch"
        value={inputText}
        placeholder={`Search for PK by exact match`}
        onChange={handleInputChangePrimary}
      />
    </FormControl>
  );
};

export default SearchDataSources;
