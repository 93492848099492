import React from "react";
import Card from "../../Card";
import ConnectionsForm from "../../Forms/Connections";

const ConnectionsFormWidget = (props) => {
  const connectionId = props?.match?.params?.connectionId;
  //Body Section of Widget
  function Body() {
    return <ConnectionsForm {...props} />;
  }

  //Actions Section of Widget
  return (
    <Card
      title={(connectionId ? "Edit" : "Add") + " Connection"}
      titleDescription={(connectionId ? "Edit" : "Add") + " Data Connections"}
      body={Body}
    />
  );
};

export default ConnectionsFormWidget;
