import React from "react";
import Card from "../../components/Card";
import { MdArrowBack, MdExpandLess, MdExpandMore } from "react-icons/md";
import ActionWrapper from "../../components/ActionsWrapper";
import StyledLink from "../../components/StyledLink";
import { servicerTransferFeedByIdWithSummaries } from "../../api/serviceTransferQueries";
import { useApi } from "../../api/useApi";
import SortExpandTable from "../../components/Table/SortExpandTable";
import ExpandLink from "../../components/Button/ExpandLink";
import { format } from "date-fns";
import SortTable from "../../components/Table/SortTable";
import { useParams } from "react-router-dom";

const Summaries = ({ props }) => {
  const inputFiles = props?.row?.original?.inputFiles;

  const columnsData = [
    {
      Header: "Name",
      id: "dataSourceName",

      accessor: (d) => d?.dataSourceName,
      Cell: ({ row: { original } }) => {
        return (
          <StyledLink to={`/sources/${original?.dataSourceId}`}>
            {original?.dataSourceName}
          </StyledLink>
        );
      },
    },
    {
      Header: "Completed On",
      id: "Completed On",
      accessor: (d) => d?.completedOn,
      Cell: ({ row: { original } }) => {
        const date = original?.completedOn;
        if (date) {
          return format(new Date(date), "MM-dd-yyyy HH:mm:ss");
        } else {
          return "";
        }
      },
    },
    {
      Header: "Rows Processed",
      id: "RowsProcessed",
      accessor: (d) => d?.rowsProcessed,
    },
  ];

  return (
    <div style={{ background: "#efefee78", padding: "1rem" }}>
      <h4>Input Sources</h4>
      <SortTable data={inputFiles ?? []} columns={columnsData} />
    </div>
  );
};

const Body = () => {
  let params = useParams();

  const feedId = Number(params?.feedId);

  const [{ data }] = useApi(servicerTransferFeedByIdWithSummaries, {
    id: feedId,
  });

  const summaries =
    data?.servicerTransferFeedByIdWithSummaries?.summaries ?? [];

  const feedName = data?.servicerTransferFeedByIdWithSummaries?.name ?? "";

  const columnsData = [
    {
      Header: "Id",
      id: "id",
      filter: true,
      accessor: (d) => d?.id,
      Cell: ({ row }) => {
        return (
          <div {...row.getToggleRowExpandedProps()}>
            {row.isExpanded ? (
              <ExpandLink title={row?.original?.id}>
                <div>
                  <MdExpandLess />
                </div>
                <div style={{ fontSize: "0.875rem" }}>{row?.original?.id}</div>
              </ExpandLink>
            ) : (
              <ExpandLink title={row?.original?.id}>
                <div>
                  <MdExpandMore />
                </div>
                <div style={{ fontSize: "0.875rem" }}>{row?.original?.id}</div>
              </ExpandLink>
            )}
          </div>
        );
      },
    },
    {
      Header: "Started At",
      id: "Started At",
      accessor: (d) => d?.createdOn,
      Cell: ({ row: { original } }) => {
        const date = original?.createdOn;
        if (date) {
          return format(new Date(date), "MM-dd-yyyy HH:mm:ss");
        } else {
          return "";
        }
      },
    },
    {
      Header: "Completed On",
      id: "Completed On",
      accessor: (d) => d?.completedOn,
      Cell: ({ row: { original } }) => {
        const date = original?.completedOn;
        if (date) {
          return format(new Date(date), "MM-dd-yyyy HH:mm:ss");
        } else {
          return "";
        }
      },
    },
  ];

  return (
    <>
      <h3>{feedName} Transformation Summaries</h3>
      <p>
        Click to expand the items below to see meta data on input files for each
        transfer.
      </p>
      <SortExpandTable
        // loading={loading}
        data={summaries}
        defaultSort={[]}
        columns={columnsData}
        defaultPageSize={50}
        SubRowComponent={(props) => <Summaries props={props} />}
      />
    </>
  );
};

//Actions Section of Widget
function Actions({ archive }) {
  return (
    <>
      <ActionWrapper>
        <StyledLink title="Back to List" to={`/feeds`}>
          <MdArrowBack />
        </StyledLink>
      </ActionWrapper>
    </>
  );
}

const FeedsPage = () => {
  return (
    <Card
      title={`Transformations History`}
      titleDescription={"Transfer data"}
      body={() => <Body />}
      actions={() => <Actions />}
    />
  );
};

export default FeedsPage;
