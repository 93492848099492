import React, { useState, useCallback, useEffect } from "react";
import { useApi } from "../../api/useApi";
import {
  getServicerTransferFeedById,
  servicerTransferDictionaryByCollectionIds,
} from "../../api/serviceTransferQueries";
import { MdExpandLess, MdExpandMore } from "react-icons/md";
import SortTable from "../../components/Table/SortTable";
import SortExpandTable from "../../components/Table/SortExpandTable";
import ExpandLink from "../../components/Button/ExpandLink";
import SplashLoader from "../../components/Loaders/SplashLoader";
import ErrorMessages from "../../components/Notifications/ErrorMessages";
import Button from "../../components/Button";
import { startServicerTransferFeed } from "../../api/serviceTransferMutations";
import Notification from "../../components/Notification";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import { useHistory } from "react-router-dom";
import { NotificationLoading } from "../../components/Notification/NotificationLoading";

const DictionaryController = ({ row }) => {
  const columns = row?.original?.transformerArguments;

  const columnArguments = [...columns];

  const lastIndex = columns?.length - 1;
  //Buyer Column
  const outputColumn = columns[lastIndex];

  // remove the last item from the column
  columnArguments.pop();

  //Seller Column
  // const inputColumn = cb?.transformerArguments?.[0];

  //Buyer Column
  // const outputColumn = cb?.transformerArguments?.[1];

  return (
    <>
      {columnArguments.map((arg) => {
        if (
          arg?.column?.uniqueValueCollectionId &&
          outputColumn?.column?.uniqueValueCollectionId
        ) {
          return (
            <>
              <Dictionary inputColumn={arg} outputColumn={outputColumn} />
              {columnArguments?.length > 1 && <hr />}
            </>
          );
        } else {
          return null;
        }
      })}
    </>
  );
};

const Dictionary = ({ inputColumn, outputColumn }) => {
  const keyCollectionId = inputColumn?.column?.uniqueValueCollectionId; // Seller
  const valueCollectionId = outputColumn?.column?.uniqueValueCollectionId; // Buyer

  const [{ loading, errors, data }] = useApi(
    servicerTransferDictionaryByCollectionIds,
    {
      valueCollectionId: valueCollectionId,
      keyCollectionId: keyCollectionId,
    }
  );

  const dictionaryData =
    data?.servicerTransferDictionaryByCollectionIds?.entries ?? [];

  const allOutPuts = dictionaryData.map((dd) => {
    return {
      ...dd?.value,
    };
  });

  const uniqueOutputs = [
    ...new Map(allOutPuts.map((item) => [item["id"], item])).values(),
  ];

  const columnsData = [
    {
      Header: `Code Break: ${data?.servicerTransferDictionaryByCollectionIds?.name}`,
      columns: [
        {
          Header: "Input Values",
          accessor: "lastName",
          Cell: ({ row: { original } }) => {
            const findEntries = dictionaryData
              .filter((dd) => dd?.value?.id === original?.id)
              .map((kv) => kv.key.value);

            return (
              <>
                {" "}
                <div style={{ display: "flex" }}>
                  {findEntries.toString()}

                  <div style={{ marginLeft: "auto" }}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "row",
                        minWidth: "50px",
                      }}
                    >
                      <div
                        style={{
                          flex: 1,
                          height: "1px",
                          backgroundColor: "#ccc",
                        }}
                      ></div>
                      <div
                        style={{
                          flex: 0,
                          width: 0,
                          height: 0,
                          borderTop: "5px solid transparent",
                          borderBottom: "5px solid transparent",
                          borderLeft: "10px solid #ccc",
                        }}
                      ></div>
                    </div>
                  </div>
                </div>
              </>
            );
          },
        },
        {
          Header: "Output Value",
          accessor: "value",
          Cell: ({ row: { original } }) => {
            return <>{original?.value}</>;
          },
        },
      ],
    },
  ];

  return (
    <div style={{ marginTop: "1rem", marginBottom: "1rem" }}>
      {errors ? <ErrorMessages errors={errors} /> : null}
      {uniqueOutputs?.length ? (
        <SortTable
          noScroll={true}
          dontReset={true}
          data={uniqueOutputs}
          columns={columnsData}
          loading={loading}
          hidePagination={true}
        />
      ) : null}
    </div>
  );
};

function isInverse(transformType) {
  switch (transformType) {
    case "FULL_NAME_PARSE":
      return true;
    case "STRING_SPLIT":
      return true;
    case "ADDRESS_PARSE":
      return true;
    case "PHONE_NUMBER_PARSE":
      return true;
    default:
      return false;
  }
}

const FeedSummary = ({ feedId }) => {
  const [{ loading, errors, data }] = useApi(getServicerTransferFeedById, {
    id: feedId,
  });

  const feed = data?.servicerTransferFeedById;
  const feedName = feed?.name;
  const transformers = feed?.transformers ?? [];

  const ingestSources = data?.servicerTransferFeedById?.sourceDataSources;
  const outputSource = data?.servicerTransferFeedById?.destinationDataSource;

  const [showExport, setShowExport] = useState(false);

  const [savingPdf, setSavingPdf] = useState(false);

  //Init Data Fetch
  const [
    { errors: startFeedErrors, data: startFeedExport },
    doStartFeedExport,
  ] = useApi();
  let history = useHistory();
  const resetExport = useCallback(() => {
    setShowExport(false);
    history.push(`/feeds`);
  }, [setShowExport, history]);

  // Start Transfer
  const createCSV = useCallback(
    ({ feedId }) => {
      doStartFeedExport({
        query: startServicerTransferFeed,
        variables: {
          feedId: feedId,
        },
      });
    },
    [doStartFeedExport]
  );

  //Start Transfer Validation
  const startValidation = useCallback(
    ({ feedId }) => {
      doStartFeedExport({
        query: startServicerTransferFeed,
        variables: {
          feedId: feedId,
          validationRun: true,
        },
      });
    },
    [doStartFeedExport]
  );

  useEffect(() => {
    if (startFeedExport) {
      setShowExport(true);
    }
  }, [startFeedExport, setShowExport]);

  const columnsData = [
    {
      Header: "Column Mappings",
      columns: [
        {
          Header: "Input",
          id: "InputColumns",
          // The cell can use the individual row's getToggleRowSelectedProps method
          // to the render a checkbox
          Cell: ({ row }) => {
            const isDictionary =
              row?.original?.transformationType === "DICTIONARY" ||
              row?.original?.transformationType === "COALESCE_DICTIONARY" ||
              row?.original?.transformationType === "DICTIONARY_PASS_THROUGH";
            const columnArguments = [...row?.original?.transformerArguments];
            const first = columnArguments[0];
            const isInverseColumns = isInverse(
              row?.original?.transformationType
            );

            if (isInverseColumns) {
              // remove the first item from column args
              columnArguments.shift();
            } else {
              // remove the last item from the column
              columnArguments.pop();
            }

            if (!isDictionary) {
              return (
                <>
                  {isInverseColumns ? (
                    <>[{first?.column?.name}]</>
                  ) : (
                    columnArguments.map((inputColumn, i) => {
                      return <>[{inputColumn?.column?.name}]</>;
                    })
                  )}
                </>
              );
            }

            return (
              <>
                <div {...row.getToggleRowExpandedProps()}>
                  {row.isExpanded ? (
                    <ExpandLink title={row.original.failedRuleInstanceName}>
                      <div>
                        <MdExpandLess />
                      </div>
                      <div style={{ fontSize: "0.875rem" }}>
                        {isInverseColumns ? (
                          <>[{first?.column?.name}]</>
                        ) : (
                          columnArguments.map((inputColumn, i) => {
                            return <>[{inputColumn?.column?.name}]</>;
                          })
                        )}
                      </div>
                    </ExpandLink>
                  ) : (
                    <ExpandLink title={row.original.failedRuleInstanceName}>
                      <div>
                        <MdExpandMore />
                      </div>
                      <div style={{ fontSize: "0.875rem" }}>
                        {isInverseColumns ? (
                          <>[{first?.column?.name}]</>
                        ) : (
                          columnArguments.map((inputColumn, i) => {
                            return <>[{inputColumn?.column?.name}]</>;
                          })
                        )}
                      </div>
                    </ExpandLink>
                  )}
                </div>
              </>
            );
          },
        },
        {
          Header: "Output",
          accessor: "lastName",
          Cell: ({ row: { original } }) => {
            const columnArguments = [...original?.transformerArguments];
            const argumentsLength = original?.transformerArguments?.length;
            const last = columnArguments[argumentsLength - 1];
            const isInverseColumns = isInverse(original?.transformationType);

            if (isInverseColumns) {
              // remove the first item from column args
              columnArguments.shift();
            } else {
              // remove the last item from the column
              columnArguments.pop();
            }

            return (
              <>
                {isInverseColumns ? (
                  columnArguments.map((inputColumn, i) => {
                    return <>[{inputColumn?.column?.name}]</>;
                  })
                ) : (
                  <>[{last?.column?.name}]</>
                )}
              </>
            );
          },
        },
        {
          Header: "Type",
          accessor: "transformationType",
        },
        {
          Header: "Requires Data",
          accessor: "transformations[0].flags",
          Cell: ({ row: { original } }) => {
            return <>{original?.flags === 1 ? "Required" : "No"}</>;
          },
        },
      ],
    },
  ];
  const printRef = React.useRef();

  const handleDownloadImage = async () => {
    const element = printRef.current;
    setSavingPdf(true);
    const canvas = await html2canvas(element);

    const data = canvas.toDataURL("image/jpg");
    const link = document.createElement("a");

    if (typeof link.download === "string") {
      link.href = data;
      link.download = `${feedName}.jpg`;

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      window.open(data);
    }
    setSavingPdf(false);
  };

  const handleDownloadPdf = async () => {
    const element = printRef.current;
    window.scrollTo(0, 0);
    setSavingPdf(true);
    const canvas = await html2canvas(element, {
      windowWidth: document.documentElement.offsetWidth,
      windowHeight: document.documentElement.offsetHeight,
    });

    var contentWidth = canvas.width;
    var contentHeight = canvas.height;

    //The height of the canvas which one pdf page can show;
    var pageHeight = (contentWidth / 592.28) * 841.89;
    //the height of canvas that haven't render to pdf
    var leftHeight = contentHeight;
    //addImage y-axial offset
    var position = 0;
    //a4 format [595.28,841.89]
    var imgWidth = 595.28;
    var imgHeight = (592.28 / contentWidth) * contentHeight;

    var pageData = canvas.toDataURL("image/jpeg", 1.0);

    var pdf = new jsPDF("", "pt", "a4");

    if (leftHeight < pageHeight) {
      pdf.addImage(pageData, "JPEG", 0, 0, imgWidth, imgHeight);
    } else {
      while (leftHeight > 0) {
        pdf.addImage(pageData, "JPEG", 0, position, imgWidth, imgHeight);
        leftHeight -= pageHeight;
        position -= 841.89;
        //avoid blank page
        if (leftHeight > 0) {
          pdf.addPage();
        }
      }
    }

    pdf.save(feedName + ".pdf");
    setSavingPdf(false);
  };

  if (loading) return <SplashLoader text={"Loading Transformation Summary"} />;

  if (!loading && data && !transformers?.length)
    return <div>No Translations Found</div>;

  return (
    <div ref={printRef}>
      {showExport ? (
        <NotificationLoading>
          <Notification
            closeCallBack={resetExport}
            text="Your data extract is being prepared; an email will be sent to you when the extract is ready to download."
          />
        </NotificationLoading>
      ) : null}

      <div style={{ display: "flex", alignItems: "center" }}>
        <h3>{feedName}</h3>

        <Button
          style={{ marginLeft: "auto" }}
          list="true"
          type="button"
          disabled={savingPdf}
          onClick={handleDownloadPdf}
        >
          Download PDF
        </Button>

        <Button
          style={{}}
          list="true"
          type="button"
          disabled={savingPdf}
          onClick={handleDownloadImage}
        >
          Download JPG
        </Button>

        <Button
          style={{}}
          list="true"
          type="button"
          onClick={() =>
            startValidation({
              feedId: data?.servicerTransferFeedById?.id,
            })
          }
          //   disabled={original?.node?.status !== "Ready"}
        >
          Validate{" "}
        </Button>

        <Button
          style={{}}
          list="true"
          type="button"
          onClick={() =>
            createCSV({
              feedId: data?.servicerTransferFeedById?.id,
            })
          }
          //   disabled={original?.node?.status !== "Ready"}
        >
          Run Transfer{" "}
        </Button>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginBottom: "1rem",
          marginTop: "1rem",
        }}
      >
        <div
          style={{
            display: "inline-flex",
            background: "#f9f9f9",

            alignItems: "top",
            padding: "1rem",
          }}
        >
          <div>
            <h4 style={{ fontSize: "1.2rem" }}>Input Sources</h4>
            {ingestSources.map((is) => {
              return <div>{is?.name}</div>;
            })}
          </div>

          <div
            style={{
              marginLeft: "2rem",
              marginRight: "2rem",
              marginTop: "1.8rem",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "row",
                minWidth: "50px",
              }}
            >
              <div
                style={{
                  flex: 1,
                  height: "1px",
                  backgroundColor: "#ccc",
                }}
              ></div>
              <div
                style={{
                  flex: 0,
                  width: 0,
                  height: 0,
                  borderTop: "5px solid transparent",
                  borderBottom: "5px solid transparent",
                  borderLeft: "10px solid #ccc",
                }}
              ></div>
            </div>
          </div>

          <div>
            <h4 style={{ fontSize: "1.2rem" }}>Output Source</h4>
            {outputSource?.name}
          </div>
        </div>
      </div>

      {startFeedErrors ? <ErrorMessages errors={startFeedErrors} /> : null}
      {errors ? <ErrorMessages errors={errors} /> : null}
      <SortExpandTable
        noScroll={true}
        loading={loading}
        data={transformers}
        hidePagination={true}
        columns={columnsData}
        defaultPageSize={50}
        defaultSort={[]}
        SubRowComponent={(props) => <DictionaryController {...props} />}
      />
    </div>
  );
};

export default FeedSummary;
