import React, { useState, useEffect, useMemo, useCallback } from "react";
import { Formik, useFormikContext } from "formik";
import {
  FormControl,
  FormActions,
  FieldError,
  StyledField,
  Label,
  StyledInput,
} from "../../Form/FormControls";
import Button from "../../Button";
import { workGroups } from "../../../common/paths";
import { Link, useParams } from "react-router-dom";
import Card from "../../Card";
import { useApi } from "../../../api/useApi";
import { workGroupById } from "../../../api/workgroupQueries";
import styled from "styled-components";
import CTAMessage from "../../Help/CTAMessage";
import SelectOrganizationGroup from "./SelectOrganizationGroup";
import SplashLoader from "../../Loaders/SplashLoader";
import SelectUsers from "./SelectUsers";
import SelectUsersOrg from "./SelectUsersOrg";
import SelectedUsers from "./SelectedUsers";
import SelectedGroups from "./SelectedGroups";
import { updateWorkGroupName } from "../../../api/workgroupMutations";
import ErrorMessages from "../../Notifications/ErrorMessages";
import { StyledSelect } from "../../Form/FormControls";
import { availableOrganizations } from "../../../api/organizationQueries";
import { useApi as useApiRedux } from "../../../api/useApiRedux";
import debounce from "lodash.debounce";
import {
  updateUserWorkGroupRole,
  addUser,
} from "../../../api/workgroupMutations";
import PagedTableRedux from "../../Table/PagedTableRedux";

const roles = [
  { label: "INACTIVE", value: "INACTIVE" },
  { label: "ACTIVE", value: "ACTIVE" },
  { label: "OWNER", value: "OWNER" },
];

const FormSection = styled.div`
  margin-bottom: 1rem;
  display: flex;
  flex-direction: row;

  @media (max-width: 85.375em) {
    flex-direction: column;
  }
`;

const FormColumn = styled.div`
  flex: 1;
  margin-right: 1rem;
`;

const Tabs = styled.div`
  border-bottom: 1px solid ${(props) => props.theme.surfaceAlt};
  background-color: ${(props) => props.theme.surface};
  position: relative;
  display: flex;
  margin-bottom: 1rem;
`;

const TabLink = styled.div`
  font-size: 0.9rem;
  padding: 0.7rem;
  margin-bottom: -0.2rem;
  border-bottom: ${(props) =>
    props.active
      ? `2px solid ${props.theme.onSecondarySurface}`
      : "2px solid transparent"};
  opacity: ${(props) => (props.active ? "1" : "0.5")};
  color: ${(props) => props.theme.onSurface};
  &:hover {
    opacity: 1;
  }
  cursor: pointer;
  text-transform: uppercase;
`;

const SearchUsers = ({ SetIsSearching, workGroupId, updateWorkGroup }) => {
  const [userData, setUserData] = useState([]);

  const [
    { errors: getUserErrors, loading: loadingUsers, data: usersDataFetch },
    getUsers,
  ] = useApiRedux();

  const [searchText, setSearchText] = React.useState("");
  const [inputValue, setInputValue] = useState("");

  const debouncedSave = useMemo(
    () => debounce((newValue) => setSearchText(newValue), 1000),
    []
  );

  const updateValue = (newValue) => {
    setInputValue(newValue);
    debouncedSave(newValue);
  };

  //Fetch for Table Paged
  const fetchData = React.useCallback(
    ({ params }) => {
      if (searchText) {
        getUsers({
          query: `/search/users`,
          params: { query: searchText, ...params },
          method: "GET",
        });
      }
    },
    [getUsers, searchText]
  );

  useEffect(() => {
    if (searchText) {
      SetIsSearching(true);
    } else {
      SetIsSearching(false);
    }
  }, [searchText, SetIsSearching]);

  const totalCount = usersDataFetch?.metadata?.availableItems;

  const {
    values: { users: selectedUsers },
  } = useFormikContext();

  const updateSelection = (e, original) => {
    // IF EDITING OWNERS
    const existingIndex = selectedUsers.findIndex(
      (item) => item.member.id === original?.id
    );

    if (existingIndex === -1) {
      const variables = {
        workGroupId: workGroupId,
        newUserId: original?.id,
        role: e.value,
      };
      updateWorkGroup({ query: addUser, variables: variables });
    } else {
      const variables = {
        workGroupId: workGroupId,
        userToUpdate: original?.id,
        newRole: e.value,
      };
      updateWorkGroup({ query: updateUserWorkGroupRole, variables: variables });
    }
  };

  const columnsData = [
    {
      Header: "Name",
      id: "name",
      accessor: (d) => d?.displayName,
    },
    {
      Header: "Email",
      id: "emailAddress",
      accessor: (d) => d?.emailAddress,
    },
    {
      Header: () => (
        <div
          style={{
            minWidth: "165px",
            maxWidth: "165px",
          }}
        >
          Role
        </div>
      ),
      id: "role",

      Cell: ({ row: { original } }) => {
        const selected = selectedUsers.find(
          (e) => e.member.id === original?.id
        );
        return (
          <StyledSelect
            className={`react-select-container`}
            classNamePrefix={`react-select`}
            menuPortalTarget={document.body}
            name={`role`}
            id={`role`}
            inputId={`role-input`}
            instanceId={`role-instance`}
            options={roles}
            onChange={(e) => updateSelection(e, original)}
            placeholder={"Select Role"}
            value={selected ? selected.role : null}
          />
        );
      },
    },
  ];
  const [viewState] = useState({
    page: 1,
    size: 25,
  });

  useEffect(() => {
    if (usersDataFetch) {
      const availUsers = usersDataFetch?.data ?? [];
      setUserData(availUsers);
    }
  }, [usersDataFetch]);

  return (
    <>
      <FormControl>
        <StyledInput
          type="text"
          name="searchUsers"
          label="Search Users"
          placeholder={`Search by Name or Email`}
          value={inputValue}
          onChange={(input) => updateValue(input.target.value)}
        />

        {getUserErrors ? <ErrorMessages errors={getUserErrors} /> : null}
      </FormControl>
      {inputValue ? (
        <PagedTableRedux
          key={"viewState" + JSON.stringify(viewState)}
          fetchData={fetchData}
          totalCount={totalCount}
          loading={loadingUsers}
          data={userData}
          columns={columnsData}
          noMargin={true}
          defaultPageSize={50}
          initParams={viewState}
        />
      ) : null}
    </>
  );
};

const initialStateUserOrgs = [
  { label: "Entire Platform", value: "Entire Platform" },
];

const StaticUsersSection = ({ workGroupId, updateWorkGroup }) => {
  const [selectedOrg, setSelectedOrg] = useState({
    label: "Entire Platform",
    value: "Entire Platform",
  });

  return (
    <>
      <OrgSelection selectedOrg={selectedOrg} setSelectedOrg={setSelectedOrg} />
      <h4>Available Users</h4>
      <p>Select a role to add user to workgroup</p>
      {selectedOrg?.value === "Entire Platform" ? (
        <SelectUsers
          workGroupId={workGroupId}
          updateWorkGroup={updateWorkGroup}
        />
      ) : (
        <SelectUsersOrg
          key={`OrgID${selectedOrg?.value}`}
          selectedOrg={selectedOrg}
          workGroupId={workGroupId}
          updateWorkGroup={updateWorkGroup}
        />
      )}
    </>
  );
};

const UserSelect = ({ workGroupId, updateWorkGroup }) => {
  const [isSearching, SetIsSearching] = useState();

  return (
    <>
      <SearchUsers
        SetIsSearching={SetIsSearching}
        workGroupId={workGroupId}
        updateWorkGroup={updateWorkGroup}
      />
      {!isSearching ? (
        <StaticUsersSection
          key={"StaticKeyRender"}
          workGroupId={workGroupId}
          updateWorkGroup={updateWorkGroup}
        />
      ) : null}
    </>
  );
};

const OrgSelection = ({ selectedOrg, setSelectedOrg }) => {
  const [userOrgOptions, setUserOrgOptions] = useState(initialStateUserOrgs);

  //Available Users Count
  const [{ data }] = useApi(availableOrganizations);

  useEffect(() => {
    if (data) {
      const enabledOrganizations = data?.availableOrganizations?.edges
        .filter((wg) => wg?.node?.enabled)
        .map((wg) => {
          return { label: wg?.node?.displayName, value: wg?.node?.id };
        });

      setUserOrgOptions((prevState) => [...prevState, ...enabledOrganizations]);
    }
  }, [data]);

  return (
    <>
      <FormControl>
        <Label>Filter Users By Organization</Label>
        <StyledSelect
          className={`react-select-container`}
          classNamePrefix={`react-select`}
          menuPortalTarget={document.body}
          name={`Org-Select`}
          id={`Org-Select`}
          inputId={`Org-Select-input`}
          instanceId={`Org-Select-instance`}
          options={userOrgOptions}
          onChange={(e) => setSelectedOrg(e)}
          value={selectedOrg}
        />
      </FormControl>
    </>
  );
};

const WorkgroupPage = ({ workgroupData, updateWorkGroup, updateErrors }) => {
  //Body Section of Widget
  function Body() {
    const workGroupId = workgroupData?.id;

    const users =
      workgroupData?.users.map((user) => {
        return { member: user, role: { label: "ACTIVE", value: "ACTIVE" } };
      }) ?? [];

    const excludedUsers =
      workgroupData?.excludedUsers.map((user) => {
        return { member: user, role: { label: "INACTIVE", value: "INACTIVE" } };
      }) ?? [];

    const owners =
      workgroupData?.owners.map((user) => {
        return { member: user, role: { label: "OWNER", value: "OWNER" } };
      }) ?? [];

    const groups = workgroupData?.groups ?? [];

    const filteredUsers = users.filter((u) => {
      const foundOwner = owners.some((o) => o.member.id === u.member.id);
      const foundExcluded = excludedUsers.some(
        (ex) => ex.member.id === u.member.id
      );
      return !foundExcluded && !foundOwner;
    });

    const members = [...filteredUsers, ...excludedUsers, ...owners];

    const [activeTab, setActiveTab] = useState("groups");

    return (
      <Formik
        initialValues={{
          displayName: workgroupData?.displayName ?? "",
          groups: groups,
          users: members,
        }}
        validate={(values) => {
          let errors = {};
          if (!values.displayName) {
            errors.displayName = "required.";
          }

          return errors;
        }}
        onSubmit={(values) => {
          const variables = {
            name: values.displayName,
            workGroupId: workGroupId,
          };
          updateWorkGroup({ query: updateWorkGroupName, variables });
        }}
      >
        {(props) => {
          return (
            <form onSubmit={props.handleSubmit}>
              {updateErrors ? <ErrorMessages errors={updateErrors} /> : null}
              <FormControl>
                <StyledField
                  type="text"
                  name="displayName"
                  label={`Name`}
                  value={props.values.displayName}
                  placeholder={`Name`}
                />
                {props.errors?.displayName && (
                  <FieldError>{props.errors.displayName}</FieldError>
                )}
              </FormControl>

              <FormActions>
                <Button type="submit">Update Name</Button>
              </FormActions>

              <Tabs>
                <TabLink
                  onClick={() => setActiveTab("groups")}
                  active={activeTab === "groups"}
                >
                  Groups
                </TabLink>
                <TabLink
                  onClick={() => setActiveTab("users")}
                  active={activeTab === "users"}
                >
                  Users
                </TabLink>
              </Tabs>

              {activeTab === "users" ? (
                <FormSection>
                  <FormColumn>
                    <UserSelect
                      workGroupId={workGroupId}
                      updateWorkGroup={updateWorkGroup}
                    />
                  </FormColumn>

                  <FormColumn>
                    {props?.values?.users?.length ? (
                      <>
                        <h4>Workgroup Users</h4>
                        <p>
                          Adjust a users role or remove from workgroup if not
                          part of an Organization
                        </p>
                        <SelectedUsers
                          workGroupId={workGroupId}
                          updateWorkGroup={updateWorkGroup}
                        />
                      </>
                    ) : (
                      <CTAMessage msg={" No Users Added"} />
                    )}
                  </FormColumn>
                </FormSection>
              ) : null}

              {activeTab === "groups" ? (
                <FormSection>
                  <FormColumn>
                    <h4>Available Groups</h4>
                    <p>Select a Group to add</p>
                    <SelectOrganizationGroup
                      selectedName="groups"
                      workGroupId={workGroupId}
                      updateWorkGroup={updateWorkGroup}
                    />
                  </FormColumn>

                  <FormColumn>
                    {props?.values?.groups?.length ? (
                      <>
                        <h4>Active Groups</h4>
                        <p>Remove a Group from workgroup</p>
                        <SelectedGroups
                          workGroupId={workGroupId}
                          updateWorkGroup={updateWorkGroup}
                        />
                      </>
                    ) : (
                      <CTAMessage msg={" No Groups Added"} />
                    )}
                  </FormColumn>
                </FormSection>
              ) : null}

              <FormActions>
                <>
                  <Link to={workGroups}>
                    <Button list="true" type="button">
                      Cancel
                    </Button>
                  </Link>
                </>
              </FormActions>
            </form>
          );
        }}
      </Formik>
    );
  }

  return (
    <Card
      title={workgroupData?.displayName ?? ""}
      titleDescription={"Manage Workgroup"}
      body={Body}
      full
    />
  );
};

const LoadWorkgroup = () => {
  let params = useParams();
  const workgroupId = params?.workgroupId;

  const [{ errors, loading, data }, doFetch] = useApi();

  const [
    { loading: updateLoading, errors: updateErrors, data: updateData },
    callUpdate,
  ] = useApi();

  const updateWorkGroup = useCallback(
    (apiCall) => {
      callUpdate(apiCall);
    },
    [callUpdate]
  );

  const workgroupData = data?.workGroupById ?? null;

  const getLatestWorkgroup = useCallback(() => {
    doFetch({ query: workGroupById, variables: { id: Number(workgroupId) } });
  }, [doFetch, workgroupId]);

  useEffect(() => {
    if (workgroupId) {
      getLatestWorkgroup();
    }
  }, [getLatestWorkgroup, workgroupId]);

  useEffect(() => {
    if (updateData) {
      getLatestWorkgroup();
    }
  }, [updateData, getLatestWorkgroup]);

  if (loading || updateLoading)
    return <SplashLoader text="Loading Workgroup Data" />;

  return (
    <>
      {errors ? <ErrorMessages errors={errors} /> : null}

      <WorkgroupPage
        workgroupData={workgroupData}
        updateErrors={updateErrors}
        getLatestWorkgroup={getLatestWorkgroup}
        updateWorkGroup={updateWorkGroup}
      />
    </>
  );
};

export default LoadWorkgroup;
