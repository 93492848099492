import React, { useState } from "react";
import { useTable, useSortBy } from "react-table";
import {
  MdNavigateNext,
  MdNavigateBefore,
  MdExpandMore,
  MdExpandLess,
  MdPushPin,
} from "react-icons/md";
import {
  TableElement,
  Td,
  Th,
  ThSticky,
  Tr,
  PageButton,
  TableLoading,
  Thead,
  TBody,
} from "./elements";
import { usePageCursor } from "./plugins/usePageCursor";
import SplashLoader from "../Loaders/SplashLoader";
import { StyledSelect, FormControl, Label } from "../Form/FormControls";

const Cell = ({ row, sticky, header, i }) => {
  const cell = row.cells.find((c) => c.column.Header === header);
  const isSticky = sticky.find((s) => s.label === cell?.column?.Header);
  const existingIndex = sticky.findIndex(
    (s) => s.label === cell?.column?.Header
  );
  const isActions = cell?.column?.id === "actions";
  return (
    <Td
      {...cell?.getCellProps()}
      isLast={isSticky ? existingIndex + 1 === sticky.length : null}
      left={isSticky ? `${existingIndex * 180}px` : null}
      isOdd={i % 2 !== 1}
      right={isActions ? `0` : null}
    >
      {cell?.render("Cell")}
    </Td>
  );
};

const HeaderCell = ({ headerGroup, sticky, setSticky, header, pinning }) => {
  const column = headerGroup.headers.find((h) => h.Header === header);
  const isSticky = sticky.find((s) => s.label === column?.Header);
  const existingIndex = sticky.findIndex((s) => s.label === column?.Header);
  const isActions = column?.id === "actions";
  const ColumnSelector = isSticky || isActions ? ThSticky : Th;

  return (
    <ColumnSelector
      {...column?.getHeaderProps(column?.getSortByToggleProps())}
      left={isSticky ? `${existingIndex * 180}px` : null}
      isLast={isSticky ? existingIndex + 1 === sticky.length : null}
      right={isActions ? `0` : null}
    >
      <div style={{ display: "flex", alignItems: "center" }}>
        {column?.render("Header")}
        {/* Add a sort direction indicator */}
        <span>
          {column?.isSorted ? (
            column?.isSortedDesc ? (
              <MdExpandMore />
            ) : (
              <MdExpandLess />
            )
          ) : (
            ""
          )}
        </span>
        {pinning && column?.Header && column?.Header?.trim() !== "" && (
          <div
            style={{
              marginLeft: "auto",
              paddingLeft: "1rem",
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
            }}
          >
            <MdPushPin
              style={{ opacity: isSticky ? 1 : 0.4 }}
              onClick={() =>
                setSticky((prev) => {
                  const clone = [...prev];
                  if (existingIndex > -1) {
                    clone?.splice(existingIndex, 1);
                  } else {
                    // Check if the label already exists in the array
                    const isLabelExist = clone?.some(
                      (item) => item?.label === column?.Header
                    );

                    // Only push the new item if the label does not already exist
                    if (!isLabelExist) {
                      clone?.push({
                        label: column?.Header,
                        value: column?.id,
                      });
                    }
                  }

                  return clone;
                })
              }
            />
          </div>
        )}
      </div>
    </ColumnSelector>
  );
};

function Table({
  columns,
  data,
  fetchData,
  pageInfo,
  loading,
  totalCount,
  defaultPageSize,
  maxSize,
  endCursor,
  noMargin,
  pinning,
  pinned,
}) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page
    //Page information
    canPreviousPage,
    canNextPage,
    pageOptions,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize, cursor, sortBy },
    // totalColumnsWidth,
  } = useTable(
    {
      columns,
      data,
      disableSortRemove: true,
      initialState: {
        pageIndex: 0,
        pageSize: defaultPageSize,
        cursor: null,
      }, // Pass our hoisted table state
      manualPagination: true, // Tell the usePagination
      // hook that we'll handle our own data fetching
      // This means we'll also have to provide our own
      // pageCount.
      manualSortBy: true,
      totalCount: totalCount,
      pageInfo: pageInfo,
      endCursor: endCursor,
    },
    useSortBy,
    usePageCursor
  );

  const [sticky, setSticky] = useState(pinned ?? []);
  // Now we can get our table state from the hoisted table state tuple

  // Listen for changes in pagination and use the state to fetch our new data

  React.useEffect(() => {
    fetchData({
      pageSize,
      cursor,
      sortBy,
    });
  }, [sortBy, fetchData, pageIndex, pageSize, cursor]);

  const max = maxSize ? [maxSize] : [10, 50, 100, 150, 200];

  return (
    <div>
      {/* May need to expand to support pinning in non-paged */}
      {pinning && (
        <FormControl>
          <Label>Quick Column Pin</Label>
          <StyledSelect
            className={`react-select-container`}
            classNamePrefix={`react-select`}
            name={`qpn`}
            id={`qpn`}
            inputId={`qpn-input`}
            instanceId={`qpn-instance`}
            label="Members"
            options={columns
              ?.filter((c) => c?.Header && c?.Header?.trim() !== "")
              .map((c) => ({
                label: c?.Header,
                value: c?.id,
              }))}
            placeholder={`Select Columns to Pin Left`}
            value={sticky ?? []}
            menuPlacement="auto"
            isMulti
            onChange={(e) => setSticky(e)}
            menuPortalTarget={document.body}
          />
        </FormControl>
      )}

      <div
        style={{ position: "relative", maxHeight: "70vh", overflow: "auto" }}
      >
        <TableElement
          noMargin={noMargin}
          className="tableFixHead"
          {...getTableProps()}
          // may need to re-enable based on prop for specific
          // style={{ minWidth: totalColumnsWidth }}
        >
          <Thead className="thead">
            {headerGroups.map((headerGroup) => (
              <Tr {...headerGroup.getHeaderGroupProps()}>
                {sticky.map((s) => (
                  <HeaderCell
                    headerGroup={headerGroup}
                    sticky={sticky}
                    setSticky={setSticky}
                    header={s?.label}
                    pinning={pinning}
                  />
                ))}
                {headerGroup.headers
                  .filter((h) => {
                    const isPinned = sticky.some((s) => s.label === h?.Header);
                    return !isPinned;
                  })
                  .map((column) => (
                    <HeaderCell
                      headerGroup={headerGroup}
                      sticky={sticky}
                      setSticky={setSticky}
                      header={column?.Header}
                      pinning={pinning}
                    />
                  ))}
              </Tr>
            ))}
          </Thead>
          <TBody {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <Tr {...row.getRowProps()}>
                  {/* LOOP THROUGH STICKY COLUMNS */}
                  {sticky.map((s) => (
                    <Cell row={row} sticky={sticky} header={s?.label} i={i} />
                  ))}
                  {/* FILTER AND LOOP THROUGH THE REST */}
                  {row.cells
                    .filter((c) => {
                      const isPinned = sticky.some(
                        (s) => s.label === c.column?.Header
                      );

                      return !isPinned;
                    })
                    .map((cell) => (
                      <Cell
                        row={row}
                        sticky={sticky}
                        header={cell?.column?.Header}
                        i={i}
                      />
                    ))}
                </Tr>
              );
            })}
          </TBody>
        </TableElement>
        {loading ? (
          <TableLoading>
            <SplashLoader text="Loading Results" />
          </TableLoading>
        ) : null}
      </div>

      <div style={{ display: "flex", alignItems: "center" }}>
        <>{!loading && page && !page.length ? <div>No Results</div> : null}</>
        <div style={{ marginLeft: "auto", marginRight: "1rem" }}>
          <span>
            Page{" "}
            <strong>
              {pageIndex + 1} of {pageOptions.length}
            </strong>{" "}
          </span>
        </div>
        <div style={{ marginRight: "1rem" }}>
          <select
            value={pageSize}
            onChange={(e) => {
              setPageSize(Number(e.target.value));
            }}
          >
            {max.map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </select>
        </div>

        <div style={{ marginRight: "1rem" }}>
          {/* <PageButton onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
              <MdFirstPage />
            </PageButton>{" "} */}
          <PageButton
            type="button"
            onClick={() => previousPage()}
            disabled={!canPreviousPage || loading}
          >
            <MdNavigateBefore />
          </PageButton>{" "}
          <PageButton
            type="button"
            onClick={() => nextPage()}
            disabled={!canNextPage || loading}
          >
            <MdNavigateNext />
          </PageButton>{" "}
          {/* <PageButton
              onClick={() => gotoPage(pageCount - 1)}
              disabled={!canNextPage}
            >
              <MdLastPage />
            </PageButton> */}
        </div>
      </div>
    </div>
  );
}

const PagedTable = ({
  fetchData,
  pageInfo,
  data = [],
  loading,
  columns,
  totalCount,
  defaultPageSize = 10,
  maxSize,
  endCursor,
  noMargin,
  pinning,
  pinned,
}) => {
  return (
    <Table
      columns={columns}
      endCursor={endCursor}
      data={data}
      totalCount={totalCount}
      pageInfo={pageInfo}
      fetchData={fetchData}
      loading={loading}
      defaultPageSize={defaultPageSize}
      maxSize={maxSize}
      noMargin={noMargin}
      pinning={pinning}
      pinned={pinned}
    />
  );
};

export default PagedTable;
