// Create Workgroup
export const createWorkGroup = /* GraphQL */ `
  mutation ($workgroup: WorkGroupInputModelInput!) {
    createWorkGroup(workgroup: $workgroup) {
      id
    }
  }
`;

// Organization Mutations
export const removeUser = /* GraphQL */ `
  mutation ($workGroupId: Int!, $userToRemove: Int!) {
    removeUserFromWorkGroup(
      workGroupId: $workGroupId
      userToRemove: $userToRemove
    ) {
      id
    }
  }
`;

// removeGroup
export const removeGroup = /* GraphQL */ `
  mutation ($workGroupId: Int!, $groupId: String!) {
    removeOrgGroupFromWorkGroup(workGroupId: $workGroupId, groupId: $groupId) {
      id
    }
  }
`;

// addGroup
export const addGroup = /* GraphQL */ `
  mutation ($workGroupId: Int!, $orgGroupId: String!) {
    addOrgGroupToWorkGroup(workGroupId: $workGroupId, orgGroupId: $orgGroupId) {
      id
    }
  }
`;

// addUser
export const addUser = /* GraphQL */ `
  mutation (
    $workGroupId: Int!
    $newUserId: Int!
    $role: WorkGroupMemberRoles!
  ) {
    addUserToWorkGroup(
      workGroupId: $workGroupId
      newUserId: $newUserId
      role: $role
    ) {
      id
    }
  }
`;

// updateUserWorkGroupRole
export const updateUserWorkGroupRole = /* GraphQL */ `
  mutation (
    $workGroupId: Int!
    $userToUpdate: Int!
    $newRole: WorkGroupMemberRoles!
  ) {
    updateUserWorkGroupRole(
      workGroupId: $workGroupId
      userToUpdate: $userToUpdate
      newRole: $newRole
    ) {
      id
    }
  }
`;

// updateWorkGroupEnabledFlag
export const updateWorkGroupEnabledFlag = /* GraphQL */ `
  mutation ($workGroupId: Int!, $enabledState: Boolean!) {
    updateWorkGroupEnabledFlag(
      workGroupId: $workGroupId
      enabledState: $enabledState
    ) {
      id
    }
  }
`;

// updateWorkGroupName
export const updateWorkGroupName = /* GraphQL */ `
  mutation ($workGroupId: Int!, $name: String!) {
    updateWorkGroupName(workGroupId: $workGroupId, name: $name) {
      id
    }
  }
`;

// Org Consent State
export const updateCreateWorkGroupFlag = /* GraphQL */ `
  mutation ($createWorkGroupState: Boolean!, $groupId: String!) {
    updateCreateWorkGroupFlag(
      groupId: $groupId
      createWorkGroupState: $createWorkGroupState
    ) {
      id
    }
  }
`;
