import { useContext } from "react";
import { SocketContext } from "../contexts/useSubscriptions";

const UseSetWorkflowPipelineCompleteEvent = () => {
  const { workflowPipelineCompleteEvent, setWorkflowPipelineCompleteEvent } =
    useContext(SocketContext);

  return { workflowPipelineCompleteEvent, setWorkflowPipelineCompleteEvent };
};

export default UseSetWorkflowPipelineCompleteEvent;
