import React, { useState, useRef, useCallback, useEffect } from "react";
import Source from "./Source";
import Target from "./Target";
import Operation from "./Operation";
import styled from "styled-components/macro";
import { MdAdd } from "react-icons/md";
import {
  BusinessRuleSourceValues,
  BusinessRuleTargetValues,
  BusinessRuleVariables,
  BusinessRuleVariableOperations,
  ColumnModifiers,
} from "./buildingBlocks";
import Attachments from "./Attachments";
import { patterns } from "./patterns";
import ReactTooltip from "react-tooltip";
import { MdInfoOutline } from "react-icons/md";
import RenderOperation from "../../../components/RuleFragment/OperationHelper";
import DOMPurify from "dompurify";

const DetailsToggle = styled.div`
  transition: 0.3s all;
  display: inline-block;
  height: 16px;
  width: 16px;
  color: grey;
  transform: ${(props) => (props.showAttachments ? "rotate(45deg)" : "")};
`;

const DetailsToggleWrapper = styled.div`
  padding: 0.5rem;
  background: ${(props) => props.theme.secondarySurface};
  display: flex;
  margin-right: ${(props) => (props.showAttachments ? "1rem" : "")};
  margin-left: 1rem;
  &:hover {
    ${DetailsToggle} {
      transform: ${(props) => (props.showAttachments ? "" : "rotate(90deg)")};
      cursor: pointer;
      color: black;
    }
  }
`;

const FragmentInner = styled.div`
  padding: 1rem;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;

const FragmentElement = styled.div`
  margin-right: 1rem;
  display: flex;
  align-items: center;
`;

const FragmentContainer = styled.div`
  transition: max-height 300ms ease-in-out;
  border: 1px solid ${(props) => props.theme.surfaceAlt};
  border-left: 4px solid
    ${(props) => (props.hasError ? "red" : props.theme.onSecondarySurface)};
  cursor: ${(props) => (props.disableAction ? "default" : "pointer")};
`;

function formatAndHighlightNCalc(expression) {
  let indentation = 0;

  // captures all types of replacements: parameters, numbers, operators, functions, and parentheses.
  const pattern =
    /\[([a-zA-Z_]\w*)\]|\b(\d+)\b|(\+\+|\-\-|\*\*|\/\/|\^\^|\%\%|==|!=|>=|<=|&&|\|\|)|\b([a-zA-Z_]\w*)\(|([\(\)])/g;

  //  check which capture group matched and decide the replacement based on it
  expression = expression.replace(pattern, (match, p1, p2, p3, p4, p5) => {
    if (p1) return '<span class="ncalc-parameter">[' + p1 + "]</span>";
    if (p2) return '<span class="ncalc-number">' + p2 + "</span>";
    if (p3) return '<span class="ncalc-operator">' + p3 + "</span>";
    if (p4) return '<span class="ncalc-function">' + p4 + "</span>(";
    if (p5) return '<span class="ncalc-parenthesis">' + p5 + "</span>";
    return match; // Default return
  });

  // Format the expression
  expression = expression.replace(
    /(<span class="ncalc-operator">(&&|\|\|)<\/span>)([ \t]*)(<span class="ncalc-parameter">\[\w+\]<\/span>|<span class="ncalc-number">\d+<\/span>|\w+)/g,
    (match, p1, p2, spaces, p4) => {
      const indent = " ".repeat(indentation * 2);
      return p1 + "\n" + indent + p4; // Put the operator at the end and start the next condition/parameter on the next line
    }
  );

  return expression.trim(); // Trim any leading or trailing spaces
}

const AttachmentToggleComponent = ({
  toggleShowAttachments,
  showAttachments,
}) => {
  function handleClicks(e) {
    e.stopPropagation();
    toggleShowAttachments(!showAttachments);
  }
  return (
    <DetailsToggleWrapper
      showAttachments={showAttachments}
      onClick={(e) => handleClicks(e)}
      data-testid={`fragment-toggle-tool`}
    >
      <DetailsToggle showAttachments={showAttachments}>
        <MdAdd />
      </DetailsToggle>
    </DetailsToggleWrapper>
  );
};
const FragmentComponent = (props) => {
  return (
    <FragmentContainer disableAction={props?.disableAction}>
      <Fragment {...props} />
    </FragmentContainer>
  );
};

export default FragmentComponent;

const Fragment = ({
  element,
  dispatch,
  index,
  editView,
  isEditing,
  isFilter,
  enableTest,
  testRow,
  goodTestParse,
  disableAction,
}) => {
  const [details, toggleDetails] = useState(false);
  const [showAttachments, toggleShowAttachments] = useState(false);

  const node = useRef(null);

  const handleClick = useCallback(
    (e) => {
      // we have to check to make sure the click is within the app and not a modal
      const container = document.getElementById("root");

      e.stopPropagation();
      //if the click is within the root we can conclude that the click was not in a modal portal
      if (container.contains(e.target)) {
        // check to make sure the click within root was also out of the target
        if (!node.current.contains(e.target)) {
          // click was detected inside the node, handle it here
          // click outside of node detected, close menu
          toggleDetails(false);
        }
      }
    },
    [toggleDetails]
  );

  useEffect(() => {
    // add listener on mount
    document.addEventListener("mousedown", handleClick);
    // when unmounted this return will be called
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, [handleClick]);

  const sourceDetails = element?.source;
  const operationDetails = element?.operation;
  const targetDetails = element?.target;

  const businessOperation =
    element?.operation?.typeInformation?.[1]?.typeValue?.value;

  const isReconciliationRule =
    businessOperation === 14 || businessOperation === 15;

  const isByAmount = element?.operation?.typeInformation[2]?.typeValue === 4;
  const isByPercentage =
    element?.operation?.typeInformation[2]?.typeValue === 5;

  // currently checking for value on source side, if so we hide it from user
  const isNotCalcValue =
    sourceDetails?.typeInformation[1]?.typeValue?.value !== 4;

  if (details)
    return (
      <FragmentInner ref={node}>
        {isNotCalcValue && (
          <>
            <Source
              node={element}
              dispatch={dispatch}
              index={index}
              enableTest={enableTest}
              testRow={testRow}
              goodTestParse={goodTestParse}
            />
            <Operation
              node={element}
              dispatch={dispatch}
              index={index}
              isReconciliationRule={isReconciliationRule}
              isEditing={isEditing}
            />
          </>
        )}

        {element?.target ? (
          <Target
            node={element}
            dispatch={dispatch}
            index={index}
            isFilter={isFilter}
            enableTest={enableTest}
            testRow={testRow}
            goodTestParse={goodTestParse}
          />
        ) : null}
        {showAttachments && isNotCalcValue && (
          <Attachments
            index={index}
            dispatch={dispatch}
            showAttachments={showAttachments}
            toggleShowAttachments={toggleShowAttachments}
            type={"Fragment"}
          />
        )}

        {editView && isNotCalcValue && (
          <AttachmentToggleComponent
            index={index}
            showAttachments={showAttachments}
            toggleShowAttachments={toggleShowAttachments}
          />
        )}
      </FragmentInner>
    );

  return (
    <FragmentInner
      ref={node}
      onClick={() => (disableAction ? null : toggleDetails(!details))}
      data-testid="testFragmentWrapper"
    >
      {isNotCalcValue ? (
        <>
          <FragmentElement>
            <>
              {sourceDetails?.typeInformation[1]?.fragmentValue
                ? sourceDetails?.typeInformation[1]?.fragmentValue
                : BusinessRuleSourceValues[
                    sourceDetails?.typeInformation[1]?.typeValue?.value - 1
                  ]?.label}
              {sourceDetails?.typeInformation[2]?.typeValue
                ? `(${
                    ColumnModifiers[
                      sourceDetails?.typeInformation[2]?.typeValue?.value - 1
                    ]?.label
                  }) `
                : null}
              {sourceDetails?.typeInformation[2]?.typeValue?.value === 2
                ? `(Days: ${sourceDetails?.typeInformation[2]?.fragmentValue}) `
                : null}
            </>
          </FragmentElement>
          <FragmentElement>
            <RenderOperation operationDetails={operationDetails} />
          </FragmentElement>
        </>
      ) : null}

      {targetDetails !== null || targetDetails !== undefined ? (
        <>
          {element?.target && (
            <FragmentElement>
              <div>
                {targetDetails?.typeInformation[1]?.fragmentValue === null
                  ? BusinessRuleTargetValues[
                      targetDetails?.typeInformation[1]?.typeValue?.value - 1
                    ]?.label
                  : null}
              </div>

              {targetDetails?.typeInformation[1]?.typeValue?.value === 3 ? (
                <div>
                  {
                    BusinessRuleVariables[
                      targetDetails?.typeInformation[1]?.fragmentValue?.value -
                        1
                    ]?.label
                  }
                </div>
              ) : businessOperation === 13 ? (
                <>
                  {patterns.find(
                    (p) =>
                      p?.value ===
                      targetDetails?.typeInformation[1]?.fragmentValue
                  )?.label ? (
                    <div style={{ display: "flex", alignItems: "center" }}>
                      {
                        patterns.find(
                          (p) =>
                            p?.value ===
                            targetDetails?.typeInformation[1]?.fragmentValue
                        )?.label
                      }
                      <div
                        data-tip={
                          patterns.find(
                            (p) =>
                              p?.value ===
                              targetDetails?.typeInformation[1]?.fragmentValue
                          )?.description
                        }
                        data-for="patternHelper"
                      >
                        <MdInfoOutline />
                      </div>
                      <ReactTooltip id="patternHelper" type="info">
                        <span>
                          {
                            patterns.find(
                              (p) =>
                                p?.value ===
                                targetDetails?.typeInformation[1]?.fragmentValue
                            )?.description
                          }
                        </span>
                      </ReactTooltip>
                    </div>
                  ) : (
                    <div>
                      {targetDetails?.typeInformation[1]?.fragmentValue}
                    </div>
                  )}
                </>
              ) : (
                <div>
                  {isByAmount ? "By" : null} {isByPercentage ? "By" : null}{" "}
                  {isNotCalcValue ? (
                    targetDetails?.typeInformation[1]?.fragmentValue
                  ) : (
                    <pre
                      id="ncalc-expression"
                      dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(
                          formatAndHighlightNCalc(
                            targetDetails?.typeInformation[1]?.fragmentValue
                          )
                        ),
                      }}
                    />
                  )}
                  {isByPercentage ? "%" : null}
                </div>
              )}
              {targetDetails?.typeInformation[1]?.typeValue?.value === 3 ? (
                <>
                  {targetDetails?.typeInformation[2]?.typeValue?.value ? (
                    <div style={{ marginLeft: ".5rem", color: "orange" }}>
                      {
                        //not negative because 0 is true for this array
                        BusinessRuleVariableOperations[
                          targetDetails?.typeInformation[2]?.typeValue?.value
                        ]?.label
                      }{" "}
                    </div>
                  ) : null}

                  {targetDetails?.typeInformation[2]?.typeValue?.value !== 0 ? (
                    <div style={{ color: "orange" }}>
                      {targetDetails?.typeInformation[2]?.fragmentValue}
                    </div>
                  ) : null}
                </>
              ) : null}

              {targetDetails?.typeInformation[3]?.typeValue
                ? `(${
                    ColumnModifiers[
                      targetDetails?.typeInformation[3]?.typeValue?.value - 1
                    ]?.label
                  }) `
                : null}

              {targetDetails.typeInformation[3]?.typeValue?.value === 2
                ? `(Days: ${targetDetails?.typeInformation[3]?.fragmentValue}) `
                : null}
            </FragmentElement>
          )}
        </>
      ) : null}

      {editView && isNotCalcValue && (
        <AttachmentToggleComponent
          showAttachments={showAttachments}
          toggleShowAttachments={toggleShowAttachments}
        />
      )}
      {showAttachments && isNotCalcValue && (
        <Attachments
          index={index}
          dispatch={dispatch}
          showAttachments={showAttachments}
          toggleShowAttachments={toggleShowAttachments}
          type={"Fragment"}
        />
      )}
    </FragmentInner>
  );
};
