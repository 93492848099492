// Get All Groups
export const groups = /* GraphQL */ `
  query (
    $first: Int
    $after: String
    $order: [PlatformGroupSortInput!]
  ) {
    groups(first: $first, after: $after, order: $order) {
      totalCount
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      edges {
        cursor
        node {
          displayName
          id
          createWorkGroup
        }
      }
    }
  }
`;
