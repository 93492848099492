export function findThresholdScore(grade, theme) {
  let scoreObject;

  const thresholdScores = [
    {
      min: 0,
      max: 39,
      name: 'Critical',
      color: theme.danger
    },
    {
      min: 40,
      max: 69,
      name: 'Low',
      color: theme.bad
    },
    {
      min: 70,
      max: 94,
      name: 'Medium',
      color: theme.warn
    },
    {
      min: 95,
      max: 100,
      name: 'High',
      color: theme.good
    }
  ];
  //pin grade to critical if the score is negative
  if (grade <= 0) {
    scoreObject = thresholdScores[0];
  }
  thresholdScores.forEach((s, i) => {
    if (grade >= s.min && grade <= s.max) {
      scoreObject = thresholdScores[i];
    }
  });
  return scoreObject;
}
