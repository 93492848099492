import React from "react";
import styled from "styled-components/macro";

const ButtonContainer = styled.button`
  position: relative;
  /* Adapt the colours based on primary prop */
  background: rgba(0, 0, 0, 0) none repeat scroll 0% 0%;
  color: #131235;
  cursor: pointer;
  text-transform: uppercase;
  width: ${(props) => (props.span ? "100%" : "auto")};
  margin-right: ${(props) => (props.list ? ".8rem" : "0")};
  padding: 0.1875rem 0.5rem;
  margin-bottom: 0.4rem;
  display: ${(props) => (props.display ? props.display : "")};
  border: 1px solid #131235;
  transition: all ${(props) => props.theme.transitionSpeed} linear;
  &:hover {
    background: ${(props) =>
      props.primary
        ? props.theme.surfaceAlt
        : props.danger
        ? props.theme.dangerAlt
        : props.theme.surfaceAlt};
  }
  &:disabled {
    opacity: 0.3;
    cursor: auto;
    background: ${(props) =>
      props.primary
        ? props.theme.onSurfaceLight
        : props.danger
        ? props.theme.danger
        : props.theme.onSurfaceLight};
  }
`;

const ButtonCenter = styled.div`
  width: 100%;
  text-align: center;
`;

const ButtonInner = styled.div`
  display: flex;
  align-items: center;
  font-size: 0.8125rem;
  letter-spacing: 0.35px;
`;

const ButtonIcon = styled.div`
  margin-right: ${(props) => (props.iconBump ? ".5rem" : "")};
`;

const TableButtonSecondary = (props) => {
  const IconComponent = props?.icon ?? null;

  return props.center ? (
    <ButtonCenter>
      <ButtonContainer {...props}>
        <ButtonInner>
          {IconComponent && (
            <ButtonIcon iconBump={props.iconBump}>
              <IconComponent />
            </ButtonIcon>
          )}
          {props.children}
        </ButtonInner>
      </ButtonContainer>
    </ButtonCenter>
  ) : (
    <ButtonContainer {...props}>
      <ButtonInner>
        {IconComponent && (
          <ButtonIcon iconBump={props.iconBump}>
            <IconComponent />
          </ButtonIcon>
        )}
        {props.children}
      </ButtonInner>
    </ButtonContainer>
  );
};

export default TableButtonSecondary;
