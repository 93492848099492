import React, { useState, useEffect, useReducer, useRef } from "react";
import Card from "../../Card";
import {
  MdHome,
  MdArrowBack,
  MdFolder,
  MdInsertDriveFile,
} from "react-icons/md";
import { AiOutlineTable } from "react-icons/ai";
import { useApi } from "../../../api/useApi";
import {
  browseConnectionForDataSources,
  importDataSources,
} from "../../../api/connectionMutations";
import useConnectionBrowse from "../../../Hooks/useConnectionBrowse";
import styled from "styled-components/macro";
import SplashLoader from "../../Loaders/SplashLoader";
import { Scrollbars } from "react-custom-scrollbars";
import PagedTable from "../../Table/PagedTable";
import { egressConnectionsList } from "../../../api/connectionQueries";
import TableButton from "../../Button/TableButton";

const SingleRowFlex = styled.div`
  display: flex;
  align-items: center;
`;

const DisplayName = styled.div`
  flex: 1;
`;

const ConnectionTable = ({ setSelectedConnection }) => {
  const ref = useRef(null);

  //Init Data Fetch
  const [{ loading, data: apiData }, doFetch] = useApi();

  const connections = apiData?.availableConnections?.edges ?? [];
  const totalCount = apiData?.availableConnections?.totalCount;
  const pageInfo = apiData?.availableConnections?.pageInfo;

  // IF EDITING We Should Render this component
  // IF Type is Input, only render 0

  //Fetch for Table Paged
  const fetchData = React.useCallback(
    ({ pageSize, cursor }) => {
      doFetch({
        query: egressConnectionsList,
        variables: {
          first: pageSize,
          after: cursor,
        },
      });
    },
    [doFetch]
  );

  const columnsData = [
    {
      Header: "Name",
      id: "Name",
      accessor: "name",
      Cell: ({ row: { original } }) => {
        return (
          <SingleRowFlex>
            <TableButton
              type="button"
              onClick={() => setSelectedConnection(original?.node?.id)}
              style={{ marginRight: "1rem", marginBottom: 0 }}
            >
              Select
            </TableButton>
            <DisplayName>{original?.node?.name}</DisplayName>{" "}
          </SingleRowFlex>
        );
      },
    },
  ];

  return (
    <>
      <h3>Select Connection</h3>
      <div ref={ref}>
        <PagedTable
          fetchData={fetchData}
          loading={loading}
          pageInfo={pageInfo}
          totalCount={totalCount}
          data={connections}
          columns={columnsData}
          defaultPageSize={50}
        />
      </div>
    </>
  );
};

const itemTypes = [
  { label: "NONE", value: 0 },
  { label: "TABLE", value: 1 },
  { label: "VIEW", value: 2 },
  { label: "FILE", value: 3 },
  { label: "DIRECTORY", value: 4 }, //same thing
  { label: "CONTAINER", value: 5 }, //same thing
  { label: "CUBE", value: 6 }, //same thing
];

const ItemRow = styled.div`
  margin-bottom: 0.5rem;
  display: flex;
  align-items: center;
  cursor: pointer;
  background: rgba(242, 242, 242, 1);
  padding: 0.5rem;
  &:hover {
    background: rgba(242, 242, 242, 0.8);
  }
`;

const FileRow = styled.div`
  margin-bottom: 0.5rem;
  display: flex;
  align-items: center;
  cursor: ${(props) =>
    props.previouslyImported || !props.canAdd ? "not-allowed" : "pointer"};
  padding: 0.5rem;
  border-left: ${(props) =>
    props.isSelected
      ? `4px solid ${props.theme.onSecondarySurface}`
      : "4px solid transparent"};
  &:hover {
    background: rgba(242, 242, 242, 0.4);
  }
`;

const BrowseIcon = styled.div`
  margin-right: 0.4rem;
  font-size: 1.3rem;
`;

const HeaderIcon = styled.div`
  margin-right: 0.4rem;
  font-size: 1.3rem;
  cursor: pointer;
  background: rgba(242, 242, 242, 1);
  padding: 0.5rem;
  &:hover {
    background: rgba(242, 242, 242, 0.8);
  }
`;

const HeaderRow = styled.div`
  margin-bottom: 0.5rem;
  display: flex;
  align-items: center;
`;

const FileLoading = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 3;
  display: flex;
  background: rgba(255, 255, 255, 0.7);
`;

const SourcesForm = styled.div`
  display: flex;
`;

const SourcesFormSection = styled.div`
  flex: 1;
  margin-right: 1rem;
  position: relative;
`;

function extensionCheck(extension) {
  const excelFileExtensions = [".xls", ".xlsx"];
  const charSeparatedExtensions = [".tsv", ".csv", ".txt"];

  if (excelFileExtensions.includes(extension.toLowerCase())) {
    return true;
  } else if (charSeparatedExtensions.includes(extension.toLowerCase())) {
    return true;
  } else if (!extension) {
    return true;
  } else {
    return false;
  }
}

function fileInfoCheck(item, extension) {
  const excelFileExtensions = [".xls", ".xlsx"];
  const charSeparatedExtensions = [".tsv", ".csv", ".txt"];

  const fileInfo = {
    hasHeaderRow: true,
    containerName: item.container,
    directoryPathToFile: item.parentPath,
    fileNameWithWildcard: item.name,
  };

  const characterSeparatedFileInfo = {
    valueSeparator: "",
    hasHeaderRow: true,
    containerName: item.container,
    directoryPathToFile: item.parentPath,
    fileNameWithWildcard: item.name,
  };

  if (excelFileExtensions.includes(extension.toLowerCase())) {
    return {
      ingressDetailsType: "EXCEL_FILE",
      characterSeparatedFileInfo: null,
      fileInfo: fileInfo,
      archiveAction: { value: "NONE", label: "NONE" },
    };
  } else if (charSeparatedExtensions.includes(extension.toLowerCase())) {
    return {
      ingressDetailsType: "CHARACTER_SEPARATED_FILE",
      characterSeparatedFileInfo: characterSeparatedFileInfo,
      fileInfo: null,
      archiveAction: { value: "NONE", label: "NONE" },
    };
  } else {
    return {
      ingressDetailsType: null,
      characterSeparatedFileInfo: null,
      fileInfo: null,
      archiveAction: null,
    };
  }
}

const TreeItem = ({ data, isSelected, dispatch }) => {
  //Check if can expand
  const expandable =
    data.itemType === "DIRECTORY" || data.itemType === "CONTAINER";
  const canAdd = extensionCheck(data?.extension);
  if (expandable) {
    return (
      <ItemRow
        onClick={() => dispatch({ type: "CONTAINER_CLICK", payload: data })}
      >
        {expandable && (
          <BrowseIcon>
            <MdFolder />
          </BrowseIcon>
        )}
        {data?.name}
      </ItemRow>
    );
  } else {
    return (
      <FileRow
        onClick={() =>
          !data?.previouslyImported &&
          canAdd &&
          dispatch({ type: "SET_SELECTED", payload: data })
        }
        isSelected={isSelected || data.previouslyImported}
        disable={isSelected || data.previouslyImported || !canAdd}
        previouslyImported={data.previouslyImported}
        title={
          !canAdd
            ? "UNSUPPORTED FILE TYPE"
            : data?.previouslyImported
            ? "Previously Imported, Check Archive if not in list"
            : "Select to Import"
        }
        canAdd={canAdd}
      >
        {data?.itemType === "FILE" ? (
          <BrowseIcon>
            <MdInsertDriveFile />
          </BrowseIcon>
        ) : (
          <BrowseIcon>
            <AiOutlineTable />
          </BrowseIcon>
        )}

        {data?.name}
      </FileRow>
    );
  }
};

const FileBrowser = React.memo(({ connectionId, selectPath }) => {
  const [waiting, setWaiting] = useState(false);

  const [
    { errors: importedSourcesErrors, data: importedSourcesData },
    importSources,
  ] = useApi();

  useEffect(() => {
    if (importedSourcesData && !importedSourcesErrors?.length) {
      setWaiting(true);
    }
  }, [importedSourcesData, setWaiting, importedSourcesErrors]);

  const [{ data: browseData }, browse] = useApi(
    browseConnectionForDataSources,
    {
      request: {
        itemToBrowse: null,
        connectionId: connectionId,
      },
    }
  );

  // useEffect(() => {
  //   browse({
  //     query: browseConnectionForDataSources,
  //     variables: {
  //       request: {
  //         itemToBrowse: null,
  //         connectionId: connectionId,
  //       },
  //     },
  //   });
  // }, [browse, connectionId]);

  const reducer = (state, action) => {
    switch (action.type) {
      default:
        return {
          ...state,
        };

      // GO BACK
      case "GO_BACK": {
        const latestHistory = state.history.slice(0);
        const latest = latestHistory.pop();

        browse({
          query: browseConnectionForDataSources,
          variables: {
            request: { ...latest },
          },
        });

        return {
          ...state,
          history: latestHistory,
          fetchingItem: latest,
          loading: true,
          isBack: true,
        };
      }

      // GO TO ROOT
      case "GO_TO_ROOT": {
        browse({
          query: browseConnectionForDataSources,
          variables: {
            request: {
              itemToBrowse: null,
              connectionId: connectionId,
            },
          },
        });

        return {
          ...state,
          history: [],
          fetchingItem: null,
          currentItem: null,
          loading: true,
        };
      }

      // SET RESULTS
      case "SET_RESULTS": {
        return {
          ...state,
          tree: action.payload,
          history: state.isBack
            ? state.history
            : state.currentItem === null
            ? []
            : [...state.history, state.currentItem],
          fetchingItem: null,
          currentItem: state.fetchingItem,
          loading: false,
          isBack: null,
        };
      }

      // SET FETCHING
      case "SET_FETCHING": {
        return {
          ...state,
          fetchingItem: action?.payload?.request,
        };
      }

      // SET SELECTED
      case "SET_SELECTED": {
        const arr = state.selected.includes(action.payload)
          ? state.selected.filter((i) => i !== action.payload)
          : [...state.selected, action.payload];

        return {
          ...state,
          selected: arr,
        };
      }

      // REMOVE SELECTED
      case "REMOVE_SELECTED": {
        const currentSelected = state.selected.slice(0);
        currentSelected.splice(action.payload, 1);
        return {
          ...state,
          selected: currentSelected,
        };
      }

      // SET CURRENT NAMES
      case "SET_CURRENT_NAMES": {
        return {
          ...state,
          currentNames: action.payload,
        };
      }

      // SET FETCHING ITEM
      case "CONTAINER_CLICK": {
        const { previouslyImported, ...cleanItem } = action.payload;

        browse({
          query: browseConnectionForDataSources,
          variables: {
            request: {
              itemToBrowse: cleanItem,
              connectionId: connectionId,
            },
          },
        });

        return {
          ...state,
          loading: true,
        };
      }

      // SET FORM STATE
      case "SET_FORM_STATE": {
        const dataSourcesToImport = state.selected.map((item) => {
          const { extension } = item;
          return {
            ...item,
            ingressInformation: {
              ...fileInfoCheck(item, extension),
            },
          };
        });

        return {
          ...state,
          dataSourcesToImport:
            action.payload === 1 ? null : dataSourcesToImport,
          formState: action.payload,
        };
      }

      // SUBMIT FORM
      case "SUBMIT_SQL_FORM": {
        const dataSourcesToImport = state.selected.map((item) => {
          const { previouslyImported, ...cleanItem } = item;

          return {
            ...cleanItem,
            ingressInformation: null,
          };
        });

        const variables = {
          connectionId: connectionId,
          dataSourcesToImport: dataSourcesToImport,
        };

        importSources({ query: importDataSources, variables: variables });
        return {
          ...state,
        };
      }

      // CREATE ERROR
      case "CREATE_ERROR": {
        return {
          ...state,
          createError: action.payload,
        };
      }
    }
  };

  const initialState = {
    history: [],
    tree: [],
    selected: [],
    fetchingItem: null,
    currentItem: null,
    loading: true,
    formState: 1,
    dataSourcesToImport: [],
  };

  const [state, dispatch] = useReducer(reducer, initialState);
  const { browseNotification, setBrowseNotification } = useConnectionBrowse();

  // useEffect(() => {
  //   const currentSources = data?.dataSources;
  //   const sourceNames = currentSources.map(source => source.name);
  //   dispatch({ type: "SET_CURRENT_NAMES", payload: sourceNames });
  // }, [data, dispatch]);

  useEffect(() => {
    if (browseNotification) {
      const browseItems = browseNotification.payload.BrowseItems;
      const items = browseItems
        //filter for folders/containers
        .filter((bi) => bi.ItemType === 4 || bi.ItemType === 5)
        .map((item) => {
          return {
            container: item.Container,
            name: item.Name,
            extension: item.Extension,
            parentPath: item.ParentPath,
            path: item.Path,
            previouslyImported: item.PreviouslyImported,
            itemType: itemTypes.find((type) => type.value === item.ItemType)
              ?.label,
            ingressInformation: item.IngressInformation,
            customQuery: JSON.stringify(item?.CustomQuery),
          };
        });

      dispatch({ type: "SET_RESULTS", payload: items });
      setBrowseNotification(null);
    }
  }, [browseNotification, setBrowseNotification]);

  useEffect(() => {
    if (browseData) {
      const request = browseData?.browseConnectionForDataSources ?? null;
      dispatch({ type: "SET_FETCHING", payload: { request } });
    }
  }, [browseData]);

  if (state.formState === 1)
    return (
      <div>
        {waiting && (
          <FileLoading>
            <SplashLoader text="Initializing Data Sources" />
          </FileLoading>
        )}

        <SourcesForm>
          <SourcesFormSection>
            <h3>Select Path</h3>
            <HeaderRow>
              <HeaderIcon
                onClick={() => dispatch({ type: "GO_TO_ROOT", payload: null })}
              >
                <MdHome />
              </HeaderIcon>
              {state.history.length ? (
                <HeaderIcon
                  onClick={() => dispatch({ type: "GO_BACK", payload: null })}
                >
                  <MdArrowBack />
                </HeaderIcon>
              ) : null}
              {state?.currentItem?.itemToBrowse?.itemType === "CONTAINER"
                ? state?.currentItem?.itemToBrowse?.name
                : state?.currentItem?.itemToBrowse?.path ?? "/"}
              <TableButton
                style={{ marginLeft: "auto" }}
                type="button"
                onClick={() =>
                  selectPath(state?.currentItem?.itemToBrowse, connectionId)
                }
              >
                Select Current Path
              </TableButton>
            </HeaderRow>
            <Scrollbars style={{ minHeight: "calc(100vh - 420px)" }}>
              {state.loading ? (
                <FileLoading>
                  <SplashLoader text="Loading Results" />
                </FileLoading>
              ) : null}

              {state.tree.map((data, i) => {
                const isSelected = state.selected.find(
                  (item) => item.name === data.name
                );

                return (
                  <TreeItem
                    key={`treeItem-${i}`}
                    data={data}
                    dispatch={dispatch}
                    isSelected={isSelected}
                  />
                );
              })}
            </Scrollbars>
          </SourcesFormSection>
        </SourcesForm>
      </div>
    );
});

const FileBrowserComponent = ({ connectionId, importErrors, selectPath }) => {
  //Body Section of Widget
  function Body() {
    return (
      <FileBrowser
        connectionId={connectionId}
        importErrors={importErrors}
        selectPath={selectPath}
      />
    );
  }

  //Actions Section of Widget
  return <Card headless={true} body={Body} />;
};

const SelectConnection = React.memo(
  ({ selectPath, connectionId, isIngress }) => {
    const [selectedConnection, setSelectedConnection] = useState(
      connectionId ?? null
    );
    return (
      <div>
        {selectedConnection ? (
          <FileBrowserComponent
            connectionId={selectedConnection}
            selectPath={selectPath}
          />
        ) : (
          <ConnectionTable setSelectedConnection={setSelectedConnection} />
        )}
      </div>
    );
  }
);

export default SelectConnection;
