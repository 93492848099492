import styled from "styled-components/macro";

export const NotificationLoading = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
  display: flex;
  background: rgba(255, 255, 255, 0.7);
`;
