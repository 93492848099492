import React from "react";
import Card from "../../components/Card";
import { MdAdd, MdArchive } from "react-icons/md";
import ActionWrapper from "../../components/ActionsWrapper";
import StyledLink from "../../components/StyledLink";
import WorkflowList from "./WorkflowList";

const WorkflowsPage = () => {
  //Body Section of Widget
  function Body() {
    // connections
    return <WorkflowList />;
  }

  //Actions Section of Widget
  function Actions() {
    return (
      <>
        <ActionWrapper>
          <StyledLink to={"/workflows/create"} title="Add New Workflow">
            <MdAdd />
          </StyledLink>
        </ActionWrapper>
        <ActionWrapper>
          <StyledLink to={"/workflows/archive"} title="Archive">
            <MdArchive />
          </StyledLink>
        </ActionWrapper>
      </>
    );
  }

  return (
    <Card
      title={"Workflows"}
      titleDescription={"Data Transit Workflows"}
      body={Body}
      actions={Actions}
    />
  );
};

export default WorkflowsPage;
