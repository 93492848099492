import React from "react";
import styled from "styled-components/macro";

const HoverCard = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.8rem;
  transform: translateY(0);
  position: relative;
  transition: all 0.3s;
  overflow: hidden;
`;

const MinimalCardBody = styled.div`
  flex: 1;
`;

const HoverCardComponent = ({ body: Body }) => {
  return (
    <HoverCard flex={2}>
      <MinimalCardBody>{Body ? <Body /> : "Nothing to Render"}</MinimalCardBody>
    </HoverCard>
  );
};

export default HoverCardComponent;
