import { useContext } from "react";
import { SocketContext } from "../contexts/useSubscriptions";

const UseDataSourcePercentage = () => {
  const { dataSourcePercentageComplete, setDataSourcePercentageComplete } =
    useContext(SocketContext);

  return { dataSourcePercentageComplete, setDataSourcePercentageComplete };
};

export default UseDataSourcePercentage;
