import { useContext } from "react";
import { SocketContext } from "../contexts/useSubscriptions";

const UseDataSourceNotifications = (type) => {
  const { lastDataSourceNotification, setLastDataSourceNotification } =
    useContext(SocketContext);
  if (type && type === "failureRow") {
    //FailureRowsRetrievalFinished
    if (lastDataSourceNotification?.payload?.alertType === 27) {
      return { lastDataSourceNotification, setLastDataSourceNotification };
    }
  } else if (type && type === "actionsRow") {
    //DataSourceRowRetrievalFinished
    if (lastDataSourceNotification?.payload?.alertType === 23) {
      return { lastDataSourceNotification, setLastDataSourceNotification };
    }
  } else if (
    !type &&
    lastDataSourceNotification &&
    lastDataSourceNotification?.payload
  ) {
    return { lastDataSourceNotification, setLastDataSourceNotification };
  }

  return { lastDataSourceNotification, setLastDataSourceNotification };
};

export default UseDataSourceNotifications;
