import React from "react";
import { useFormikContext } from "formik";
import SortTable from "../../Table/SortTable";
import {
  updateUserWorkGroupRole,
  removeUser,
} from "../../../api/workgroupMutations";
import styled from "styled-components";
import { StyledSelect } from "../../Form/FormControls";
import TableButton from "../../Button/TableButton";

const SingleRowFlex = styled.div`
  display: flex;
  align-items: center;
`;

const DisplayName = styled.div`
  flex: 1;
`;

const roles = [
  { label: "INACTIVE", value: "INACTIVE" },
  { label: "ACTIVE", value: "ACTIVE" },
  { label: "OWNER", value: "OWNER" },
];

const SelectedUsers = React.memo(({ workGroupId, updateWorkGroup }) => {
  const {
    values: { users: selectedUsers },
  } = useFormikContext();

  const updateSelection = (e, original) => {
    const variables = {
      workGroupId: workGroupId,
      userToUpdate: original?.member?.id,
      newRole: e.value,
    };
    updateWorkGroup({ query: updateUserWorkGroupRole, variables: variables });
  };

  const removeSelection = (original) => {
    const variables = {
      workGroupId: workGroupId,
      userToRemove: original?.member?.id,
    };
    updateWorkGroup({ query: removeUser, variables: variables });
  };

  const userDataColumns = [
    {
      Header: "Name",
      id: "name",
      accessor: (d) => d.member.displayName,
      Cell: ({ row: { original } }) => {
        return (
          <>
            <SingleRowFlex>
              <DisplayName>{original.member.displayName}</DisplayName>{" "}
            </SingleRowFlex>
          </>
        );
      },
    },
    {
      Header: () => (
        <div
          style={{
            minWidth: "165px",
            maxWidth: "165px",
          }}
        >
          Role
        </div>
      ),
      id: "role",

      Cell: ({ row: { original } }) => {
        return (
          <StyledSelect
            className={`react-select-container`}
            classNamePrefix={`react-select`}
            menuPortalTarget={document.body}
            name={`role`}
            id={`role`}
            inputId={`role-input`}
            instanceId={`role-instance`}
            options={roles}
            onChange={(e) => updateSelection(e, original)}
            placeholder={"Select Role"}
            value={original.role}
          />
        );
      },
    },
    {
      Header: "",
      id: "remove",
      width: 40,
      Cell: ({ row: { original } }) => {
        return (
          <>
            <SingleRowFlex>
              <TableButton
                type="button"
                danger={true}
                onClick={() => removeSelection(original)}
              >
                x
              </TableButton>
            </SingleRowFlex>
          </>
        );
      },
    },
  ];

  return <SortTable data={selectedUsers} columns={userDataColumns} />;
});

export default SelectedUsers;
