import React from "react";
import styled, { css } from "styled-components";

const ErrorMessagesContainer = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
  background: ${(props) => props.theme.surface};
`;

const ErrorHeader = styled.div`
  padding: 10px;
  color: ${(props) => props.theme.onSurfaceLightSecondary};
  font-family: "Source Sans Pro Semibold";
  ${(props) =>
    props.isSpecialError
      ? css`
          background: #ffc300; // Set to yellow for the specific error
        `
      : css`
          background: ${props.theme.danger}; // Default background
        `}
`;

const ErrorMessagesInnerContainer = styled.div`
  padding: 10px;
  ${(props) =>
    props.isSpecialError
      ? css`
          border: solid #ffc300 4px; // Yellow border for the specific error
        `
      : css`
          border: solid ${props.theme.danger} 4px; // Default border color
        `}
`;

const MessageWrap = styled.div`
  word-break: break-word;
`;

export default function ErrorMessages({ errors }) {
  if (!errors?.length) return null;

  // Check if any of the errors has the specific message
  const hasSpecialError = errors?.some(
    (error) => error?.message === "The report refresh is already in progress"
  );

  return (
    <ErrorMessagesContainer>
      <ErrorHeader isSpecialError={hasSpecialError}>
        {hasSpecialError ? "Warning" : "Errors"} Found
      </ErrorHeader>
      <ErrorMessagesInnerContainer isSpecialError={hasSpecialError}>
        {errors?.map((error, i) => {
          return (
            <MessageWrap key={`messageWrap-${i}`}>{error?.message}</MessageWrap>
          );
        })}
      </ErrorMessagesInnerContainer>
    </ErrorMessagesContainer>
  );
}
