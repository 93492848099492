import React, { useEffect } from "react";
import { Formik, Form, Field } from "formik";
import { FormControl, FormActions } from "../../Form/FormControls";
import Button from "../../Button";
import Spinner from "../../Loaders/Spinner";
import ErrorMessages from "../../Notifications/ErrorMessages";
import { useApi } from "../../../api/useApi";
import { setConnectionPermissions } from "../../../api/connectionMutations";
import PermissionsSelect from "../../Form/PermissionsSelect";

const ConnectionsPermissionsForm = ({ connection, getUpdatedConnection }) => {
  //Prime GraphAPI For Permissions Updates
  const [{ loading, errors, data }, doUpdatePermissions] = useApi();

  //TODO: We already have the new form data, lets move the connection into state
  // and update from there instead of circling back, for now we hit the server to get valid data
  useEffect(() => {
    if (data) {
      getUpdatedConnection();
    }
  }, [data, getUpdatedConnection]);

  //callback
  const updatePermissions = (values) => {
    const variables = {
      id: connection.id,
      permissions: values.permissions,
    };

    doUpdatePermissions({ query: setConnectionPermissions, variables });
  };

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={{
          permissions: connection ? connection.permissions : [],
        }}
        validate={(values) => {
          let errors = {};

          if (!values.permissions.length) {
            errors.permissions = "Required";
          }

          return errors;
        }}
        validateOnMount={true}
        onSubmit={(values) => updatePermissions(values)}
      >
        {({ isValid }) => {
          return (
            <Form>
              <Field
                name={`permissions`}
                component={PermissionsSelect}
                // Editing flag passes in if we are updating/editing form
                editing={connection}
              />

              <FormActions>
                <FormControl>
                  {errors ? <ErrorMessages errors={errors} /> : null}
                  {/* TODO: Add Toasts */}
                  {data && <div>Updated!</div>}
                  <Button type="submit" disabled={loading || !isValid}>
                    {loading ? <Spinner /> : "Update"}
                  </Button>
                </FormControl>
              </FormActions>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default ConnectionsPermissionsForm;
