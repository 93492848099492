// Rule Pattern Match Helpers
export const patterns = [
  // {
  //   label: "Decimal Place Equal To 2",
  //   value: "^[0-9]\d*(\.\d{1,2})?$",
  //   description: "Checks for exactly 2 decimal places",
  // },
  // {
  //   label: "Decimal Place Equal To 0",
  //   value: "^([0-9]\d*|0)$",
  //   description: "Checks for no decimal places",
  // },
  {
    label: "No Alpha Characters",
    value: "^[^A-Za-z]+$",
    description: "Checks for numbers only",
  },
  {
    label: "No Special Characters",
    value: "^[a-zA-Z0-9 ]+$",
    description: "Checks to confirm absence of special characters",
  },
  {
    label: "No Numeric Characters",
    value: "^[^0-9]+$",
    description: "Checks for letters only",
  },
  {
    label: "City Contains Valid Characters",
    value: "^[a-zA-Z-.' ]{3,}$",
    description:
      "Checks for letters and select valid special characters only and must be more than 2 letters",
  },
  {
    label: "State Contains Valid Characters",
    value: "^[a-zA-Z]{2}$",
    description: "Checks for exactly 2 letters",
  },
  {
    label: "Address Contains Valid Characters",
    value: "^[a-zA-Z0-9-.',#&/ ]+$",
    description:
      "Checks for letters, numbers, and select valid special characters only",
  },
  // {
  //   label: "Date Matches Pattern Year-Month-Day",
  //   value:
  //     "^(19|20)dd([- /.])(0?[1-9]|1[012])([- /.])(0?[1-9]|[12][0-9]|3[01])$",
  //   description: "Checks for valid dates in year-month-day format",
  // },

  // {
  //   label: "Date Matches Pattern Month-Day-Year",
  //   value: "^(0?[1-9]|1[012])[- /.](0?[1-9]|[12][0-9]|3[01])[- /.](19|20)dd$",
  //   description: "Checks for valid dates in month-day-year format",
  // },

  // {
  //   label: "Date Matches Pattern Day-Month-Year",
  //   value: "^(0?[1-9]|[12][0-9]|3[01])[- /.](0?[1-9]|1[012])[- /.](19|20)dd$",
  //   description: "Checks for valid dates in day-month-year format",
  // },
];
