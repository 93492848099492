import React, { useState } from "react";
import { MdArchive } from "react-icons/md";
import ConnectionSourcesList from "./ConnectionSourcesList";
import Button from "../../components/Button";
import FileBrowse from "../../components/Forms/FileBrowse";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ConnectionSourcesContainer = ({ getUpdatedConnection, data }) => {
  const sources = data?.dataSources ?? [];
  const enabledSources =
    data?.dataSources?.filter((source) => source?.enabled) ?? [];
  const disabledSources =
    data?.dataSources?.filter((source) => !source?.enabled) ?? [];

  const [showBrowse, setShowBrowse] = useState(false);
  const [showArchive, setShowArchive] = useState(false);

  const importErrors = (message) => {
    toast.error(message, {
      position: "top-right",
      autoClose: 6000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  if (sources?.length) {
    return (
      <>
        <div style={{ display: "flex" }}>
          <div style={{ flex: "1" }}>
            <Button
              type="button"
              onClick={() => {
                setShowBrowse((prevState) => !prevState);
                setShowArchive(false);
              }}
            >
              {showBrowse ? "Cancel" : "Add Sources"}
            </Button>
          </div>
          <div style={{ marginLeft: "auto" }}>
            {disabledSources?.length ? (
              <Button
                type="button"
                onClick={() => {
                  setShowArchive((prevState) => !prevState);
                  setShowBrowse(false);
                }}
              >
                {showArchive ? (
                  <>Active List</>
                ) : (
                  <>
                    <MdArchive /> Archive List{" "}
                  </>
                )}
              </Button>
            ) : null}
          </div>
        </div>

        {showArchive && (
          <>
            <ConnectionSourcesList
              sources={disabledSources}
              getUpdatedConnection={getUpdatedConnection}
              connectionId={data?.id}
            />
          </>
        )}

        {showBrowse && !showArchive ? (
          <FileBrowse
            data={data}
            getUpdatedConnection={getUpdatedConnection}
            importErrors={importErrors}
          />
        ) : (
          !showArchive && (
            <ConnectionSourcesList
              sources={enabledSources}
              getUpdatedConnection={getUpdatedConnection}
              connectionId={data?.id}
            />
          )
        )}
        <ToastContainer />
      </>
    );
  } else {
    return (
      <>
        <FileBrowse
          getUpdatedConnection={getUpdatedConnection}
          data={data}
          importErrors={importErrors}
        />
        <ToastContainer />
      </>
    );
  }
};

export default ConnectionSourcesContainer;
