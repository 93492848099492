// Organizations
export const availableOrganizations = /* GraphQL */ `
  query {
    availableOrganizations {
      totalCount
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      edges {
        cursor
        node {
          displayName
          enabled
          id
          isPending
          role
          type
          details {
            createdOn
            id
            organizationId
          }
        }
      }
    }
  }
`;

export const getOrganization = /* GraphQL */ `
  query($id: Int!) {
    getOrganization(id: $id) {
      displayName
      enabled
      id
      isPending
      role
      type
      details {
        createdOn
        id
        organizationId
      }
    }
  }
`;
