import React, { Component } from "react";
import ReactDOM from "react-dom";
import styled from "styled-components/macro";
import Spacer from "../Layout/Spacer";
import { MdClose } from "react-icons/md";

const CloseButton = styled.button`
  border: none;
  background: none;
  cursor: pointer;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.6rem;
  position: absolute;
  top: 0;
  right: 0;
  padding: 0;
  margin: 0.5rem;
`;

const modalRoot = document.getElementById("modal-root");

const ModalWrapper = styled.div``;

const ModalContainer = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  border-radius: 3px;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: ${(props) => (props.large ? "95%" : props.noWidth ? "auto" : "600px")};
  max-width: 100%;
  max-height: 100%;
`;

const ModalContent = styled.div`
  padding: 2rem;
  background: #fff;
  overflow: auto;
  color: ${(props) => props.theme.onSurface};
`;

const ModalHeader = styled.div`
  display: flex;
`;

const ModalTitle = styled.div`
  flex: 1;
`;

export default class Modal extends Component {
  constructor(props) {
    super(props);
    // Create a div that we'll render the modal into. Because each
    // Modal component has its own element, we can render multiple
    // modal components into the modal container.
    this.el = document.createElement("div");
    this.el.className = "modal";
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClick, false);
    // Append the element into the DOM on mount. We'll render
    // into the modal container element (see the HTML tab).
    modalRoot.appendChild(this.el);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClick, false);
    // Remove the element from the DOM when we unmount
    modalRoot.removeChild(this.el);
  }

  handleClick = (e) => {
    e.stopPropagation();
    if (e.target.className === "modal") this.props.hide();
  };

  render() {
    // Use a portal to render the children into the element
    return ReactDOM.createPortal(
      // Any valid React child: JSX, strings, arrays, etc.
      <ModalContainer large={this?.props?.large} noWidth={this?.props?.noWidth}>
        <ModalWrapper>
          <ModalContent>
            <ModalHeader>
              <ModalTitle>
                {this.props.title ? <h3>{this.props.title}</h3> : null}
              </ModalTitle>
              {this.props?.disableHide ? null : (
                <CloseButton onClick={this.props.hide} type="button">
                  <MdClose />
                </CloseButton>
              )}
            </ModalHeader>
            <Spacer />
            {this.props.children}
          </ModalContent>
        </ModalWrapper>
      </ModalContainer>,
      // A DOM element
      this.el
    );
  }
}
