import React from "react";
import MinimalStatHoverWidget from "../MinimalStatHoverWidget";
import Spinner from "../../../components/Loaders/Spinner";
import HoverCard from "../../Card/HoverCard";

export default React.memo(({ data, loading, errors }) => {
  //Body Section of Widget
  function Body() {
    const rowCount =
      (data &&
        Number.isInteger(data.rowCount) &&
        data.rowCount.toLocaleString()) ||
      null;

    const percentChange = (data && data.percentChange.toFixed(2)) || null;

    if (loading) return <Spinner />;
    if (errors) return <div>Errors</div>;
    if (!data) return <div>No Data Available</div>;

    return (
      <MinimalStatHoverWidget
        currentStat={rowCount}
        percentChange={percentChange}
        statDescription={"Rows Measured"}
        goodTrend={"inc"}
      />
    );
  }

  return <HoverCard body={Body} />;
});
