import React, { useState } from "react";
import { Field } from "formik";

import {
  FormControl,
  StyledField,
  ErrorMessage,
} from "../../Form/FormControls";

const CubeConnection = ({ form }) => {
  const [showPassword, setShowPassword] = useState(false);
  const [showTenantId, setShowTenantId] = useState(false);

  return (
    <>
      <h3>Connection Details</h3>
      <FormControl>
        <label>
          Use Advanced Connection String
          <Field
            type="checkbox"
            checked={form.values.advanced}
            name={`advanced`}
            onChange={() => {
              form.setFieldValue(`advanced`, !form.values.advanced);
              form.setFieldValue(`details`, {
                cubeConnectionString: {
                  connectionString: null,
                  database: null,
                  region: null,
                  serverName: null,
                  tenantId: null,
                  userId: null,
                  password: null,
                },
                dataverseConnectionString: null,
                azureStorageConnectionString: null,
                sftpConnectionString: null,
                cosmosConnectionString: null,
                ftpsConnectionString: null,
              });
            }}
          />
        </label>
      </FormControl>

      {form.values.advanced ? (
        <FormControl>
          <StyledField
            name={`details.cubeConnectionString.connectionString`}
            type="text"
            placeholder="Connection String"
            label="Connection String"
          />
          <ErrorMessage
            name={`details.cubeConnectionString.connectionString`}
          />
        </FormControl>
      ) : (
        <>
          <FormControl>
            <StyledField
              name={`details.cubeConnectionString.database`}
              type="text"
              placeholder="Database"
              label="Database"
            />
            <ErrorMessage name={`details.cubeConnectionString.database`} />
          </FormControl>

          <FormControl>
            <StyledField
              name={`details.cubeConnectionString.region`}
              type="text"
              placeholder="Region"
              label="Region"
            />
            <ErrorMessage name={`details.cubeConnectionString.region`} />
          </FormControl>

          <FormControl>
            <StyledField
              name={`details.cubeConnectionString.serverName`}
              type="text"
              placeholder="Server Name"
              label="Server Name"
            />
            <ErrorMessage name={`details.cubeConnectionString.serverName`} />
          </FormControl>

          <FormControl>
            <StyledField
              name={`details.cubeConnectionString.tenantId`}
              type={showTenantId ? "text" : "tenantId"}
              placeholder="Tenant Id"
              label="Tenant Id"
            />
            <label>
              Show Tenant Id
              <Field
                type="checkbox"
                checked={showTenantId}
                name={`showTenantId`}
                onChange={() => setShowTenantId((prevState) => !prevState)}
              />
            </label>
            <ErrorMessage name={`details.cubeConnectionString.tenantId`} />
          </FormControl>

          <FormControl>
            <StyledField
              name={`details.cubeConnectionString.userId`}
              type="text"
              placeholder="User Id"
              label="User Id"
            />
            <ErrorMessage name={`details.cubeConnectionString.userId`} />
          </FormControl>

          <FormControl>
            <StyledField
              name={`details.cubeConnectionString.password`}
              type={showPassword ? "text" : "password"}
              placeholder="Password"
              label="Password"
            />
            <label>
              Show Password
              <Field
                type="checkbox"
                checked={showPassword}
                name={`showPassword`}
                onChange={() => setShowPassword((prevState) => !prevState)}
              />
            </label>
            <ErrorMessage name={`details.cubeConnectionString.password`} />
          </FormControl>
        </>
      )}
    </>
  );
};

export default CubeConnection;
