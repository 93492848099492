import React, { useEffect } from "react";
import debounce from "lodash.debounce";
import { FormControl, StyledInput } from "../Form/FormControls";

const ColumnFilter = ({ column, setFilter }) => {
  const [inputText, setInputText] = React.useState("");
  const [filterText, setFilterText] = React.useState("");

  useEffect(() => {
    setFilter(filterText);
  }, [filterText, setFilter]);

  const setSearchTextDebounced = React.useRef(
    debounce(
      (searchText) =>
        setFilterText(searchText ? { id: column, text: searchText } : null),
      500
    )
  ).current;

  const handleInputChangePrimary = (inputText) => {
    // prevent outside click from resetting inputText to ""
    setInputText(inputText);
    setSearchTextDebounced(inputText);
  };

  return (
    <div style={{ margin: "1rem" }}>
      <FormControl style={{ maxWidth: "300px" }}>
        <StyledInput
          type="text"
          name="source-value"
          value={inputText}
          placeholder="Filter by Feed Name"
          onChange={(e) => handleInputChangePrimary(e.target.value)}
        />
      </FormControl>
    </div>
  );
};

export default ColumnFilter;
