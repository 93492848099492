import React, { useState } from "react";
import Card from "../../components/Card";
import Button from "../../components/Button";
import { GiArtificialHive } from "react-icons/gi";

function Body() {
  const [disableSuggestions, setDisableSuggestions] = useState(
    window.localStorage.getItem("disable-suggestions")
  );

  const updateSuggestions = () => {
    if (!disableSuggestions) {
      window.localStorage.setItem("disable-suggestions", true);
      setDisableSuggestions(true);
    } else {
      window.localStorage.removeItem("disable-suggestions");
      setDisableSuggestions(false);
    }
  };

  return (
    <>
      <h3 style={{ display: "flex", alignItems: "center" }}>
        <GiArtificialHive style={{ marginRight: ".5rem" }} /> Auto Suggestions
      </h3>
      <p>
        Auto Suggestions affect when the platform suggests columns and rules to
        map. It will also automatically fill out a rule template when mapping if
        applicable. You can toggle this feature on and off for yourself below.
      </p>
      <Button
        danger={disableSuggestions ? false : true}
        onClick={() => updateSuggestions()}
      >
        {disableSuggestions ? "Enable" : "Disable"} Auto Suggestions
      </Button>
    </>
  );
}

export default function Settings() {
  return (
    <Card
      title={"Settings"}
      titleDescription={"Your personal settings"}
      body={Body}
    />
  );
}
