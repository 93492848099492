import React, { useState } from "react";
import Card from "../../components/Card";
import { withRouter } from "react-router-dom";
import {
  dataSourceFiltersEdit,
  editDataQualityRules,
} from "../../common/paths";
import { MdArrowBack, MdPageview } from "react-icons/md";
import ActionWrapper from "../../components/ActionsWrapper";
import StyledLink from "../../components/StyledLink";
import { useApi } from "../../api/useApi";
import { businessRuleStandard } from "../../api/ruleQueries";
import SortTable from "../../components/Table/SortTable";
import { formatTime } from "../../common/formats";
import ViewMappedRule from "../../components/RuleFragment/ViewMappedRule";
import Button from "../../components/Button";
import { ActionsIcon } from "../../components/Table/elements";
import SplashLoader from "../../components/Loaders/SplashLoader";
const RuleVersions = ({ versions }) => {
  const [version, setVersion] = useState(null);

  const columnsData = [
    {
      Header: "Version ID",
      id: "id",
      accessor: (d) => d.id,
    },
    {
      Header: "Created On",
      id: "createdOn",
      accessor: (d) => formatTime(new Date(d.createdOn)),
    },
    {
      Header: "",
      id: "actions",
      maxWidth: 100,
      Cell: ({ row: { original } }) => {
        return (
          <>
            <div style={{ fontSize: "1.2rem" }}>
              <ActionsIcon>
                <MdPageview
                  onClick={() => setVersion(original)}
                  title="View Rule Version"
                />
              </ActionsIcon>
            </div>
          </>
        );
      },
    },
  ];

  return (
    <>
      {version ? (
        <>
          <Button type="button" onClick={() => setVersion(null)}>
            Versions List
          </Button>
          <ViewMappedRule standard={version} />
        </>
      ) : (
        <SortTable data={versions} columns={columnsData} />
      )}
    </>
  );
};

const Body = ({ standardLoading, standard }) => {
  if (standardLoading) return "loading";
  const versions = standard?.versions ?? [];
  return (
    <>
      <RuleVersions versions={versions} />
    </>
  );
};

//Actions Section of Widget
function Actions(standardId, isFilter) {
  return (
    <>
      <ActionWrapper>
        <StyledLink
          title="Back to List"
          to={
            isFilter
              ? dataSourceFiltersEdit(standardId)
              : editDataQualityRules(standardId)
          }
        >
          <MdArrowBack />
        </StyledLink>
      </ActionWrapper>
    </>
  );
}

const CardWrapper = (props) => {
  const standardId = props?.match?.params?.ruleId;

  const isFilter =
    props?.location?.pathname === `/policies/filters/${standardId}/history`;

  const [{ loading: standardLoading, data: standardData }] = useApi(
    businessRuleStandard,
    {
      standardId: Number(standardId),
    }
  );

  const standard = standardData?.businessRuleStandardById;
  if (standardLoading) return <SplashLoader text="Loading Rule " />;
  return (
    <Card
      title={`${standard?.name}`}
      titleDescription={"Version History"}
      body={() => Body({ standardLoading, standard })}
      actions={() => Actions(standardId, isFilter)}
    />
  );
};

const CardWithRouter = withRouter(CardWrapper);
export default CardWithRouter;
