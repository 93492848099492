import {
  auditLogEntriesQuery,
  auditLogEntriesQueryUserActions,
} from "../../api/auditQueries";
import { format, subMonths } from "date-fns";
import React, { useState, useEffect, useMemo, useCallback } from "react";
import { useApi } from "../../api/useApi";
import PagedExpandTable from "../../components/Table/PagedExpandTable";
import {
  StyledSelect,
  Label,
  FormControl,
  StyledInput,
} from "../../components/Form/FormControls";
import ExpandLink from "../../components/Button/ExpandLink";
import { MdExpandLess, MdExpandMore } from "react-icons/md";
import StyledLink from "../../components/StyledLink";
import { useParams, Link } from "react-router-dom";
import styled from "styled-components";
import Button from "../Button";
import columnSort from "../../components/Table/helpers/columnSort";
import ErrorMessages from "../../components/Notifications/ErrorMessages";
import Modal from "../../components/Modal";
import { DateTimePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { useApi as useApiRedux } from "../../api/useApiRedux";
import debounce from "lodash.debounce";
import PagedTableRedux from "../../components/Table/PagedTableRedux";
import { exportAuditLogMutation } from "../../api/auditMutations";
import TableButton from "../../components/Button/TableButton";
import { NotificationLoading } from "../../components/Notification/NotificationLoading";
import Notification from "../../components/Notification";

const SearchUsers = ({ setSelected, selectedUser }) => {
  const [userData, setUserData] = useState([]);

  const [
    { errors: getUserErrors, loading: loadingUsers, data: usersDataFetch },
    getUsers,
  ] = useApiRedux();

  const [searchText, setSearchText] = React.useState("");
  const [inputValue, setInputValue] = useState("");

  const debouncedSave = useMemo(
    () => debounce((newValue) => setSearchText(newValue), 1000),
    []
  );

  const updateValue = (newValue) => {
    setInputValue(newValue);
    debouncedSave(newValue);
  };

  //Fetch for Table Paged
  const fetchData = React.useCallback(
    ({ params }) => {
      if (searchText)
        getUsers({
          query: `/search/users`,
          params: { query: searchText, ...params },
          method: "GET",
        });
    },
    [getUsers, searchText]
  );

  const totalCount = usersDataFetch?.metadata?.availableItems;

  const columnsData = [
    {
      Header: "Name",
      id: "name",
      accessor: (d) => d?.displayName,
    },
    {
      Header: "Email",
      id: "emailAddress",
      accessor: (d) => d?.emailAddress,
      Cell: ({ row: { original } }) => {
        return (
          <div
            style={{
              maxWidth: "300px",
              textOverflow: "ellipsis",
              overflow: "hidden",
            }}
            title={original?.emailAddress}
          >
            {original?.emailAddress}
          </div>
        );
      },
    },
    {
      Header: () => (
        <div
          style={{
            minWidth: "165px",
            maxWidth: "165px",
          }}
        ></div>
      ),
      id: "Selection",
      Cell: ({ row: { original } }) => {
        const isSelected = selectedUser?.id === original?.id;
        return (
          <div>
            <TableButton
              list="true"
              type="button"
              disabled={isSelected}
              onClick={() => setSelected(original)}
            >
              Select
            </TableButton>
          </div>
        );
      },
    },
  ];

  const [viewState] = useState({
    page: 1,
    size: 25,
  });

  useEffect(() => {
    if (usersDataFetch) {
      const availUsers = usersDataFetch?.data ?? [];
      setUserData(availUsers);
    }
  }, [usersDataFetch]);

  return (
    <>
      <FormControl>
        <StyledInput
          type="text"
          name="searchUsers"
          label="Search Users"
          placeholder={`Search by Name or Email`}
          value={inputValue}
          onChange={(input) => updateValue(input.target.value)}
        />

        {getUserErrors ? <ErrorMessages errors={getUserErrors} /> : null}
      </FormControl>
      {inputValue ? (
        <PagedTableRedux
          key={"viewState" + JSON.stringify(viewState)}
          fetchData={fetchData}
          totalCount={totalCount}
          loading={loadingUsers}
          data={userData}
          columns={columnsData}
          noMargin={true}
          defaultPageSize={50}
          initParams={viewState}
        />
      ) : null}
    </>
  );
};

function ExportAuditLog({ cancel }) {
  const today = new Date();
  const yesterday = new Date(today);
  yesterday.setDate(today.getDate() - 1);
  const [endTime, setEndTime] = useState(today);
  const [startTime, setStartTime] = useState(yesterday);
  const [useDateRange, setUseDateRange] = useState(false);
  const [filterByUser, setFilterByUser] = useState(false);
  const [selectedUser, setSelectedUser] = useState();
  const [showExport, setShowExport] = useState(false);

  const resetExport = useCallback(() => {
    setShowExport(false);
    cancel();
  }, [setShowExport, cancel]);

  const [{ loading, errors, data }, createExport] = useApi();

  useEffect(() => {
    if (data) {
      setShowExport(true);
    }
  }, [data, setShowExport]);

  const callCreateExport = useCallback(() => {
    createExport({
      query: exportAuditLogMutation,
      variables: {
        ...(useDateRange && {
          startTime: startTime.toISOString(),
        }),
        ...(useDateRange && {
          endTime: endTime.toISOString(),
        }),
        ...(selectedUser?.id && {
          userFilter: selectedUser?.id,
        }),
      },
    });
  }, [useDateRange, selectedUser, endTime, startTime, createExport]);

  return (
    <>
      {showExport ? (
        <NotificationLoading>
          <Notification
            closeCallBack={resetExport}
            text="Your audit log is being prepared; an email will be sent to you when the extract is ready to download."
          />
        </NotificationLoading>
      ) : null}
      {errors && <ErrorMessages errors={errors} />}
      <p>
        Export your audit log. You can select a Date range and or a specific
        user.
      </p>

      <FormControl>
        <label style={{ cursor: "pointer" }}>
          <input
            type="checkbox"
            checked={useDateRange}
            name={`ocr`}
            onChange={() => {
              setUseDateRange((prev) => !prev);
            }}
          />
          Use Date Range
        </label>
      </FormControl>

      <FormControl>
        <label style={{ cursor: "pointer" }}>
          <input
            type="checkbox"
            checked={filterByUser}
            name={`ocr`}
            onChange={() => {
              setFilterByUser((prev) => !prev);
              setSelectedUser();
            }}
          />
          Filter By User{selectedUser && `: ${selectedUser?.emailAddress}`}
        </label>
      </FormControl>

      {useDateRange && (
        <div style={{ display: "flex", alignItems: "center" }}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DateTimePicker
              label="From"
              value={startTime}
              onChange={(newValue) => setStartTime(newValue || today)}
            />
            <div style={{ marginLeft: "1rem", marginRight: "1rem" }}>
              {" - "}
            </div>
            <DateTimePicker
              label="To"
              value={endTime}
              onChange={(newValue) => setEndTime(newValue || today)}
            />
          </LocalizationProvider>
        </div>
      )}

      {filterByUser && (
        <div style={{ marginTop: "1rem" }}>
          <SearchUsers
            selectedUser={selectedUser}
            setSelected={(user) => setSelectedUser(user)}
          />
        </div>
      )}

      <div style={{ marginTop: "1rem" }}>
        <Button
          type="button"
          list
          //   disabled={removeProviderLoading}
          danger
          onClick={() => {
            cancel();
          }}
        >
          Cancel
          {/* {removeProviderLoading ? <Spinner /> : "Yes"} */}
        </Button>
        <Button
          type="button"
          //   disabled={removeProviderLoading}
          onClick={() => {
            callCreateExport();
          }}
        >
          Export
        </Button>
      </div>
    </>
  );
}

export default ExportAuditLog;
