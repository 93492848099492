import React from "react";
import SmallToggleButton from "../Button/SmallToggle";
import styled from "styled-components/macro";

const ToggleBorder = styled.div`
  border: 2px solid ${(props) => props.theme.onSurfaceLight};
  display: inline-block;
  border-radius: 6px;
`;

const Toggle = React.memo(({ toggleData, toggleState }) => {
  return (
    <ToggleBorder>
      {toggleData.map((td) => {
        return (
          <SmallToggleButton
            active={td.name === toggleState}
            onClick={td.action}
          >
            {td.name}
          </SmallToggleButton>
        );
      })}
    </ToggleBorder>
  );
});

export default Toggle;
