import React, { useRef } from "react";
import { useTable, useSortBy, usePagination, useExpanded } from "react-table";
import {
  MdNavigateNext,
  MdNavigateBefore,
  MdLastPage,
  MdFirstPage,
  MdExpandMore,
  MdExpandLess,
} from "react-icons/md";
import {
  TableElement,
  Td,
  TrSub,
  Th,
  ThSticky,
  Tr,
  PageButton,
  TableLoading,
  Thead,
  TdExpand,
  TBody,
} from "./elements";
import SplashLoader from "../Loaders/SplashLoader";

function Table({
  columns,
  data,
  loading,
  renderRowSubComponent,
  defaultSort,
  defaultPageSize,
  setIsEditing,
}) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page
    //Page information
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    visibleColumns,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      disableSortRemove: true,
      autoResetExpanded: false,
      autoResetPage: false,
      initialState: {
        pageIndex: 0,
        sortBy: defaultSort,
        pageSize: defaultPageSize,
      },
    },
    useSortBy,
    useExpanded,
    usePagination
  );

  const tableContainerRef = useRef(null);

  return (
    <div ref={tableContainerRef}>
      {/* overflowX: "auto", maxHeight: "100vh" */}
      <div
        style={{ position: "relative", maxHeight: "70vh", overflow: "auto" }}
      >
        <TableElement {...getTableProps()}>
          <Thead>
            {headerGroups.map((headerGroup) => {
              return (
                <Tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => {
                    const isActions = column?.id === "actions";
                    const ColumnSelector = isActions ? ThSticky : Th;
                    return (
                      <ColumnSelector
                        {...column.getHeaderProps(
                          column.getSortByToggleProps()
                        )}
                        {...column.getHeaderProps({
                          style: {
                            minWidth: column.minWidth,
                            width: column.width,
                            maxWidth: column.maxWidth,
                            cursor: "pointer",
                          },
                        })}
                        right={isActions ? `0` : null}
                      >
                        {/* Sort With Indicator */}
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <div style={{ marginRight: "auto" }}>
                            {column.render("Header")}
                          </div>
                          {column.isSorted ? (
                            <div style={{ fontSize: "1.5rem" }}>
                              {column.isSortedDesc ? (
                                <MdExpandMore />
                              ) : (
                                <MdExpandLess />
                              )}
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </ColumnSelector>
                    );
                  })}
                </Tr>
              );
            })}
          </Thead>
          <TBody {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <>
                  <Tr
                    {...row.getRowProps()}
                    style={{
                      borderLeft: row.isExpanded
                        ? "2px solid #009fd4"
                        : "2px solid transparent",
                    }}
                  >
                    {row.cells.map((cell, i) => {
                      const isActions = cell.column?.id === "actions";
                      return (
                        <Td
                          {...cell.getCellProps()}
                          style={{
                            cursor:
                              row.cells.length - 1 !== i
                                ? "pointer"
                                : "inherit",
                          }}
                          onClick={() => setIsEditing(row)}
                          right={isActions ? `0` : null}
                          isOdd={i % 2 !== 1}
                        >
                          {cell.render("Cell")}
                        </Td>
                      );
                    })}
                  </Tr>
                  {row.isExpanded ? (
                    <TrSub
                      style={{
                        borderLeft: row.isExpanded
                          ? "2px solid #009fd4"
                          : "2px solid transparent",
                      }}
                    >
                      <TdExpand colSpan={visibleColumns.length}>
                        {renderRowSubComponent({ row, tableContainerRef })}
                      </TdExpand>
                    </TrSub>
                  ) : null}
                </>
              );
            })}
          </TBody>
        </TableElement>
        {loading ? (
          <TableLoading>
            <SplashLoader text="Loading Results" />
          </TableLoading>
        ) : null}
      </div>

      <div style={{ display: "flex", alignItems: "center" }}>
        <>{!loading && page && !page.length ? <div>No Results</div> : null}</>
        <div style={{ marginLeft: "auto", marginRight: "1rem" }}>
          <span>
            Page{" "}
            <strong>
              {pageIndex + 1} of {pageOptions.length}
            </strong>{" "}
          </span>
        </div>
        <div style={{ marginRight: "1rem" }}>
          <select
            value={pageSize}
            onChange={(e) => {
              setPageSize(Number(e.target.value));
            }}
          >
            {[10, 25, 50].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </select>
        </div>
        <div style={{ marginRight: "1rem" }}>
          <PageButton
            type="button"
            onClick={() => gotoPage(0)}
            disabled={!canPreviousPage}
          >
            <MdFirstPage />
          </PageButton>{" "}
          <PageButton
            type="button"
            onClick={() => previousPage()}
            disabled={!canPreviousPage}
          >
            <MdNavigateBefore />
          </PageButton>{" "}
          <PageButton
            type="button"
            onClick={() => nextPage()}
            disabled={!canNextPage}
          >
            <MdNavigateNext />
          </PageButton>{" "}
          <PageButton
            type="button"
            onClick={() => gotoPage(pageCount - 1)}
            disabled={!canNextPage}
          >
            <MdLastPage />
          </PageButton>
        </div>
      </div>
    </div>
  );
}

const SortExpandTableV2 = ({
  data,
  columns,
  loading,
  SubRowComponent,
  defaultSort,
  defaultPageSize = 50,
  setIsEditing,
}) => {
  // Create a function that will render our row sub components
  const renderRowSubComponent = React.useCallback(({ row }) => {
    return (
      <div>
        <SubRowComponent row={row} />
      </div>
    );
  }, []);

  return (
    <Table
      defaultSort={defaultSort}
      columns={columns}
      data={data && data.length ? data : []}
      loading={loading}
      defaultPageSize={defaultPageSize}
      renderRowSubComponent={renderRowSubComponent}
      setIsEditing={setIsEditing}
    />
  );
};

export default SortExpandTableV2;
