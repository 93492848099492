import React from "react";
import { Route, NavLink, Switch } from "react-router-dom";
import styled from "styled-components/macro";
import HolidayConfig from "./HolidayConfig";

const TabLink = styled(NavLink)`
  font-size: 0.9rem;
  padding: 0.7rem;
  border-left: 4px solid transparent;
  text-decoration: none;
  opacity: 0.5;
  display: inline-block;
  color: ${(props) => props.theme.onSurface};
  &.active {
    opacity: 1;
    border-left: 4px solid ${(props) => props.theme.onSecondarySurface};
  }
  &:hover {
    opacity: 1;
  }
  text-transform: uppercase;
`;

const ManageContainer = styled.div`
  display: flex;
`;

const ManageContent = styled.div`
  padding: 1rem;
  flex: 1;

  display: block;
`;

const ManageSource = () => {
  return (
    <ManageContainer>
      <ul style={{ borderRight: "1px solid #efefef" }}>
        <li>
          <TabLink to={`/admin/configuration/workingdays`}>Holidays</TabLink>
        </li>
      </ul>
      <ManageContent>
        <Switch>
          <Route
            path={`/admin/configuration`}
            component={() => <HolidayConfig />}
          />
        </Switch>
      </ManageContent>
    </ManageContainer>
  );
};

export default ManageSource;
