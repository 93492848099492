import React from "react";
import styled from "styled-components/macro";

const CardActionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  right: 1em;
  top: 1em;
  margin-left: 1em;
`;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  /* justify-content: space-between; */
  color: ${(props) => props.theme.onSurface};
  background-color: ${(props) => props.theme.surface};
  flex: ${(props) => (props.flex ? props.flex : "1")};
  /* overflow:  ${(props) => (props.menuFlyInRight ? "" : "hidden")}; */
  height: ${(props) => (props.full ? "100%" : "auto")};
  /* &:hover {
    ${CardActionsContainer} {
      display: flex;
    }
  } */
  ${(props) =>
    props.active === "topLeft"
      ? `&:before {  content:'';
      position: absolute;
      bottom: 100%;
      left: 50%;
      margin-left: -27%;
      width: 0;
      height: 0;
      border-top: solid 1rem #ffffff;
      border-left: solid 1rem transparent;
      border-right: solid 1rem transparent;
      }`
      : ""}

  ${(props) =>
    props.active === "topRight"
      ? `&:before {  content:'';
      position: absolute;
      bottom: 100%;
      left: 50%;
      margin-left: 27%;
      width: 0;
      height: 0;
      border-top: solid 1rem #ffffff;
      border-left: solid 1rem transparent;
      border-right: solid 1rem transparent;
      }`
      : ""}
`;

const CardHeader = styled.div`
  display: flex;
  padding: 1em;
  align-items: center;
  position: absolute;
`;

const CardTitleContainer = styled.div`
  flex: 1;
  position: relative;
  width: 100%;
`;

const CardTitle = styled.div`
  text-transform: uppercase;
  font-size: 1.2em;
  color: ${(props) => props.theme.onBackground};
  font-family: "Source Sans Pro Semibold";
  margin-bottom: 0.2em;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const CardTitleDescription = styled.div`
  text-transform: uppercase;
  font-size: 0.8em;
  color: ${(props) => props.theme.onSurface};
  overflow: hidden;
  text-overflow: ellipsis;
  letter-spacing: 0.02em;
`;

const CardActions = styled.div`
  display: flex;
`;

const CardBody = styled.div`
  padding: 1em;
  border-radius: 0 0 2px 2px;
  height: 100%;
`;

const AbsoluteHeadingCard = ({
  title,
  titleDescription,
  active,
  body: Body,
  actions: Actions,
  menuFlyInRight,
  full,
  headless,
}) => {
  return (
    <Card full={full} flex={2} menuFlyInRight={menuFlyInRight} active={active}>
      {!headless && (
        <CardHeader>
          <CardTitleContainer>
            <CardTitle>{title}</CardTitle>
            <CardTitleDescription>{titleDescription}</CardTitleDescription>
          </CardTitleContainer>

          {Actions ? (
            <CardActionsContainer>
              <CardActions>
                <Actions />
              </CardActions>
            </CardActionsContainer>
          ) : null}
        </CardHeader>
      )}
      <CardBody>{Body ? <Body /> : "Nothing to Render"}</CardBody>
    </Card>
  );
};

export default AbsoluteHeadingCard;
