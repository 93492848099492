import { useContext } from "react";
import { SocketContext } from "../contexts/useSubscriptions";

const UseETLProviderCreateNotification = () => {
  const { etlProviderCreateCompleted, setEtlProviderCreateCompleted } =
    useContext(SocketContext);

  return { etlProviderCreateCompleted, setEtlProviderCreateCompleted };
};

export default UseETLProviderCreateNotification;
