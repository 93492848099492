import React, { useState, useEffect, useCallback, useContext } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components/macro";
import { MdPageview, MdRemoveCircle, MdError } from "react-icons/md";
import {
  setRuleInstanceEnabledFlag,
  setRuleInstancePriority,
} from "../../api/ruleMutations";
import { useApi } from "../../api/useApi";
import { StyledSelect } from "../../components/Form/FormControls";
import SortTable from "../../components/Table/SortTable";
import Button from "../../components/Button";
import Modal from "../../components/Modal";
import Spinner from "../../components/Loaders/Spinner";
import { ActionsIcon } from "../../components/Table/elements";
import SplashLoader from "../../components/Loaders/SplashLoader";
import {
  dataSourceStandardVersion,
  dataSourceFilterVersion,
} from "../../common/paths";
import caseInsenSort from "../../components/Table/helpers/caseInsenSort";
import { AuthContext } from "../../contexts/AuthContext";

const WarningIcon = styled.span`
  color: orange;
`;

const ErrorIcon = styled.span`
  color: red;
`;

const priorityOptions = [
  { label: "HIGH", value: "HIGH" },
  { label: "LOW", value: "LOW" },
  { label: "NORMAL", value: "NORMAL" },
  { label: "INFORMATIONAL", value: "INFORMATIONAL" },
];

const UpdatingLoading = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 3;
  display: flex;
  background: rgba(255, 255, 255, 0.7);
`;

const AppliedRules = ({ rules, getUpdatedSource, sourceId, mappingType }) => {
  const [showConfirm, setShowConfirm] = useState(false);
  const [ruleToDelete, setruleToDelete] = useState(null);
  const { user } = useContext(AuthContext);
  const [
    { loading: removeRuleDataLoading, data: removeRuleData },
    removeRuleApi,
  ] = useApi();

  //callback for removing a row
  const removeRuleStandard = useCallback(
    (ruleInstanceId) => {
      const variables = {
        ruleInstanceId: ruleInstanceId,
        enableState: "DISABLED",
      };
      removeRuleApi({ query: setRuleInstanceEnabledFlag, variables });
    },
    [removeRuleApi]
  );

  //handle remove rule update
  useEffect(() => {
    if (removeRuleData) {
      getUpdatedSource();
      setruleToDelete(null);
      setShowConfirm(false);
    }
  }, [removeRuleData, getUpdatedSource]);

  useEffect(() => {
    if (ruleToDelete) {
      setShowConfirm(true);
    } else {
      setShowConfirm(false);
    }
  }, [ruleToDelete]);

  const [{ loading: priorityLoading, data: priorityUpdate }, doUpdatePriority] =
    useApi();

  const updatePriority = React.useCallback(
    (updatePriorityEvent, instanceId) => {
      const updateInstancePriority = {
        instanceId: instanceId,
        priority: updatePriorityEvent.value,
      };

      const variables = {
        ...updateInstancePriority,
      };

      doUpdatePriority({ query: setRuleInstancePriority, variables });
    },
    [doUpdatePriority]
  );

  useEffect(() => {
    if (priorityUpdate?.setRuleInstancePriority) {
      getUpdatedSource();
    }
  }, [priorityUpdate, getUpdatedSource]);

  let columnsData = [
    {
      Header: "Title",
      id: "title",
      accessor: (d) => d.title,
      sortType: (prev, curr, columnId) => {
        return caseInsenSort(prev, curr, columnId);
      },
    },
    {
      Header: "",
      id: "actions",
      maxWidth: 220,
      Cell: ({ row: { original } }) => {
        const isRuleEnabled =
          original?.latestVersion?.standardVersion?.standard?.enabled;

        const isLatest =
          original?.latestVersion?.standardVersionId ===
          original?.latestVersion?.standardVersion?.standard?.latestVersion?.id;
        return (
          <>
            <div style={{ fontSize: "1.2rem" }}>
              {!isRuleEnabled && (
                <ErrorIcon>
                  <MdError title="Policy has been Disabled" />
                </ErrorIcon>
              )}
              <ActionsIcon>
                <Link
                  to={
                    mappingType === "FILTER"
                      ? dataSourceFilterVersion(
                          sourceId,
                          original?.id,
                          original?.latestVersion?.id
                        )
                      : dataSourceStandardVersion(
                          sourceId,
                          original?.id,
                          original?.latestVersion?.id
                        )
                  }
                >
                  {!isLatest ? (
                    <WarningIcon>
                      <MdPageview title="View Rule Mapping" />
                    </WarningIcon>
                  ) : (
                    <MdPageview title="View Rule Mapping" />
                  )}
                </Link>
              </ActionsIcon>
              {user && user.role >= 3 && (
                <ActionsIcon>
                  <WarningIcon>
                    <MdRemoveCircle
                      onClick={() => setruleToDelete(original.id)}
                      title="Disable Instance"
                    />
                  </WarningIcon>
                </ActionsIcon>
              )}
            </div>
          </>
        );
      },
    },
  ];

  if (mappingType === "RULE") {
    columnsData = [
      ...columnsData,
      {
        Header: "Impact",
        id: "Priority",
        accessor: "priority",
        maxWidth: 220,
        Cell: ({ row: { original } }) => {
          return (
            <>
              <StyledSelect
                className={`react-select-container`}
                classNamePrefix={`react-select`}
                menuPortalTarget={document.body}
                name={`${original?.id}-priority`}
                id={`${original?.id}-priority`}
                inputId={`${original?.id}-priority-input`}
                instanceId={`${original?.id}-priority-instance`}
                options={priorityOptions}
                onChange={(e) => updatePriority(e, original?.id)}
                placeholder={original?.priority}
                value={priorityOptions.find(
                  (priority) => priority.value === original?.priority
                )}
              />
            </>
          );
        },
      },
    ];
  }

  return (
    <>
      {showConfirm ? (
        <Modal
          title={`Confirm Rule Removal`}
          hide={() => setruleToDelete(null)}
        >
          <p>Are you sure you wish to remove this Mapping?</p>
          <div>
            <Button
              type="button"
              list="true"
              disabled={removeRuleDataLoading}
              danger
              onClick={() => removeRuleStandard(ruleToDelete)}
            >
              {removeRuleDataLoading ? <Spinner /> : "Yes"}
            </Button>
            <Button
              type="button"
              disabled={removeRuleDataLoading}
              onClick={() => {
                setruleToDelete(null);
              }}
            >
              Cancel
            </Button>
          </div>
        </Modal>
      ) : null}
      {priorityLoading ? (
        <UpdatingLoading>
          <SplashLoader text={"Updating Priority"} />
        </UpdatingLoading>
      ) : null}
      <SortTable data={rules} columns={columnsData} />
    </>
  );
};

export default AppliedRules;
