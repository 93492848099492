import React, { useEffect, useContext } from "react";
import { ToastContainer, toast } from "react-toastify";
import { useApi } from "../api/useApi";
import { SocketContext } from "../contexts/useSubscriptions";

const dismissNotifications = `
  mutation($ids: [UUID!]!){
    dismissNotifications(ids: $ids)
  }
`;

const BannerNotifications = () => {
  const { ocrRateLimitNotification } = useContext(SocketContext);
  const [{}, clear] = useApi(); // eslint-disable-line no-empty-pattern

  useEffect(() => {
    if (
      ocrRateLimitNotification?.payload.AlertType === 26 &&
      ocrRateLimitNotification?.payload?.SourceId &&
      ocrRateLimitNotification?.payload?.ErrorMessage
    ) {
      const clearNotif = (id) => {
        clear({
          query: dismissNotifications,
          variables: {
            ids: [id],
          },
        });
      };

      toast.warn(
        `${ocrRateLimitNotification?.payload?.ConnectionName} - ${ocrRateLimitNotification?.payload?.ErrorMessage}`,
        {
          position: "top-center",
          autoClose: false,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          onClose: () => clearNotif(ocrRateLimitNotification?.id),
        }
      );
    }
  }, [ocrRateLimitNotification, clear]);

  return;
};

export default BannerNotifications;
