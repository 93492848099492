import React, { useState, useCallback } from "react";

export const PinContext = React.createContext();

const Pins = (props) => {
  const [pinnedSources, setPinnedSources] = useState(
    JSON.parse(localStorage.getItem("pinned-sources")) ?? []
  );

  const [pinnedPolicies, setPinnedPolicies] = useState(
    JSON.parse(localStorage.getItem("pinned-policies")) ?? []
  );

  const update = useCallback(
    (type, data) => {
      if (type === "sources") {
        setPinnedSources(data);
      } else if (type === "policies") {
        setPinnedPolicies(data);
      }
    },
    [setPinnedSources, setPinnedPolicies]
  );

  const value = React.useMemo(() => {
    return {
      pinnedSources,
      pinnedPolicies,
      update,
    };
  }, [pinnedSources, pinnedPolicies, update]);

  return (
    <PinContext.Provider value={value}>{props.children}</PinContext.Provider>
  );
};

export default Pins;
