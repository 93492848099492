import React from "react";
import MinimalStatHoverWidget from "../MinimalStatHoverWidget";
import Spinner from "../../../components/Loaders/Spinner";
import HoverCard from "../../Card/HoverCard";

export default React.memo(({ data, loading, errors }) => {
  //Body Section of Widget
  function Body() {
    const count =
      (data &&
        Number.isInteger(data.failuresCount) &&
        data.failuresCount.toLocaleString()) ||
      null;

    const percent = (data && data.percentChange.toFixed(2)) || null;

    if (loading) return <Spinner />;
    if (errors) return <div>Errors</div>;
    if (!data) return <div>No Data Available</div>;

    return (
      <MinimalStatHoverWidget
        currentStat={count}
        percentChange={percent}
        statDescription={"Total Failures"}
        goodTrend={"dec"}
      />
    );
  }

  return <HoverCard body={Body} />;
});
