import React, { useEffect, useState, useReducer, useCallback } from "react";
import Card from "../../components/Card";
import { useParams, useLocation } from "react-router-dom";
import styled from "styled-components/macro";
import { feeds } from "../../common/paths";
import { MdArrowBack } from "react-icons/md";
import ActionWrapper from "../../components/ActionsWrapper";
import StyledLink from "../../components/StyledLink";
import SourceSelect from "./SourceSelect";
import { useApi } from "../../api/useApi";
import FeedMapping from "./FeedMapping";
import Button from "../../components/Button";
import { createServicerTransferFeed } from "../../api/serviceTransferMutations";
import SelectIngestSources from "./SelectIngestSources";
import CodeBreakers from "./CodeBreakers";
import useFeedNotifications from "../../Hooks/useFeedNotifications";
import ErrorMessages from "../../components/Notifications/ErrorMessages";
import FeedSummary from "./FeedSummary";
import { getServicerTransferFeedById } from "../../api/serviceTransferQueries";
import SplashLoader from "../../components/Loaders/SplashLoader";
import Modal from "../../components/Modal";
import { FormControl, StyledInput } from "../../components/Form/FormControls";
import MarkdownFetcher from "../../views/FeedsPage/transformerFunctions/MarkDownFetcher";

const LoadingScreen = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 500;
  display: flex;
  background: rgba(255, 255, 255, 0.7);
`;

const TabLink = styled.div`
  font-size: 0.9rem;
  padding: 0.7rem;
  border-left: 4px solid
    ${(props) =>
      props.active ? props.theme.onSecondarySurface : "transparent"};
  text-decoration: none;
  opacity: ${(props) => (props.active ? 1 : 0.5)};
  display: inline-block;
  color: ${(props) => props.theme.onSurface};

  &:hover {
    opacity: ${(props) => (props.disabled ? 0.5 : 1)};
    cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  }
  text-transform: uppercase;
`;

const ManageContainer = styled.div`
  display: flex;
`;

const ManageContent = styled.div`
  padding: 1rem;
  flex: 1;

  display: block;
`;

const SourceNameForm = ({ dispatch }) => {
  const [generatedOutputSourceName, setGeneratedOutputSourceName] =
    useState("");

  //Show Helper
  const [showHelper, setShowHelper] = useState(false);

  return (
    <>
      <Modal
        title={`Name Output Generation`}
        hide={() => dispatch({ type: "CONFIGURE_SOURCE_NAME" })}
      >
        {showHelper ? (
          <Modal hide={() => setShowHelper(false)}>
            <div
              style={{
                padding: "1rem",
                background: "#fbfbfb",
                marginBottom: "1rem",
              }}
            >
              <MarkdownFetcher
                hideClose
                MdFile={showHelper}
                close={setShowHelper}
              />
            </div>
          </Modal>
        ) : null}

        <p>Name your Output Source Name.</p>
        <p>
          An output source name is optional, defaults to
          "ServicerTransferGenerated_TRANSFERNAME"
        </p>
        <FormControl>
          <StyledInput
            type="text"
            name="Output Source Name"
            label="Generated Output Source Name"
            required={true}
            value={generatedOutputSourceName}
            placeholder={`Generated Output Source Name`}
            onChange={(e) => setGeneratedOutputSourceName(e.target.value)}
          />
        </FormControl>

        <Button
          type="button"
          onClick={() =>
            dispatch({
              type: "SET_SOURCE_NAME",
              payload: {
                generatedOutputSourceName,
              },
            })
          }
        >
          {generatedOutputSourceName ? "Save Output Name" : "Skip"}
        </Button>
      </Modal>
    </>
  );
};

const ManageFeed = ({ state, dispatch, selectOutputSource }) => {
  switch (state?.step) {
    default:
      return <div>Invalid State</div>;

    case 1:
      return (
        <div>
          <h3>Select Output</h3>
          <p>This will determine the column options available to you to map.</p>
          {state?.isClone ? (
            <div>
              Cloning: Output Id {state?.cloneSuggested?.selectedSourceId}
            </div>
          ) : null}
          <SourceSelect
            state={state}
            selectSource={selectOutputSource}
            feed={true}
            sourceType={-3}
          />
          {state?.configureSourceName && SourceNameForm({ dispatch })}

          <Button
            list
            disabled={state?.selectedSourceId}
            onClick={() => dispatch({ type: "CONFIGURE_SOURCE_NAME" })}
          >
            {state?.generatedOutputSource?.name
              ? `Configure Generation Settings`
              : `Generate & Continue`}
          </Button>
          <Button
            disabled={!state?.selectedSourceId}
            onClick={() => dispatch({ type: "CHANGE_STATE", payload: 7 })}
          >
            Save & Continue
          </Button>

          <div style={{ padding: ".5rem" }}>
            {state?.generatedOutputSource?.name
              ? `Generated Output Source Name: ${state?.generatedOutputSource?.name}`
              : null}
          </div>
        </div>
      );

    case 7:
      return (
        <div>
          <h3>Select External Input(s)</h3>
          <p>This will determine the column options available to you to map.</p>
          {state?.isClone ? (
            <div>
              Cloning: Input Ids{" "}
              {state?.cloneSuggested?.ingestSources.map((is) => {
                return <>{is?.id}</>;
              })}
            </div>
          ) : null}
          <SelectIngestSources
            sourceId={state?.selectedSourceId}
            selectedDataSources={state?.ingestSources}
            dispatch={dispatch}
            feed={true}
            sourceType={-2}
          />
        </div>
      );

    case 10:
      return (
        <div>
          <FeedMapping state={state} dispatch={dispatch} />
        </div>
      );

    case 11:
      return (
        <div>
          <CodeBreakers state={state} dispatch={dispatch} />
        </div>
      );
    case 12:
      return (
        <div>
          <FeedSummary feedId={Number(state?.feedId)} />
        </div>
      );
  }
};

const Body = ({ feed, isClone }) => {
  //TODO: Role UPDATE API is Broken currently, UI needs to indicate Loading and Completed Change
  const [{ loading, errors, data }, doSubmitForm] = useApi();

  useState();

  //Reducer States for this component, Details what part of the state updates pending dispatch
  const reducer = (state, action) => {
    switch (action.type) {
      //First State called when a query is passed to the component.
      default:
        return {
          ...state,
        };

      // CREATE An error state in the UI and clear creating state
      case "CHANGE_STATE": {
        return {
          ...state,
          step: action?.payload,
        };
      }
      // Show Source Name Form for Generated Mappings
      case "CONFIGURE_SOURCE_NAME": {
        return {
          ...state,
          configureSourceName: !state.configureSourceName,
        };
      }
      // SET Source Name for Generated Mappings
      case "SET_SOURCE_NAME": {
        return {
          ...state,
          configureSourceName: !state.configureSourceName,
          generatedOutputSource: {
            name:
              action?.payload?.generatedOutputSourceName !== ""
                ? action?.payload?.generatedOutputSourceName
                : "n/a",
          },
          step: 7,
        };
      }
      case "GO_BACK": {
        return {
          ...state,
          step: action?.payload,
        };
      }
      case "OUTPUT_SOURCE_SELECT": {
        return {
          ...state,
          step: 7,
          selectedSourceId: action?.payload,
        };
      }
      case "SELECT_EXTERNAL_SOURCES": {
        return {
          ...state,
          step: 10,
          ingestSources: action?.payload,
        };
      }
      case "SET_CLONE_DATA": {
        return {
          ...state,
          cloneSuggested: {
            ...state?.cloneSuggested,
            feed: action?.payload,
          },
        };
      }
      case "SELECT_EXTERNAL_SOURCES_MODAL": {
        return {
          ...state,
          ingestSources: action?.payload,
        };
      }
      case "START_SUBMISSION": {
        let generatedOutputSource;

        const isGeneratedOutputSource =
          state?.feed?.destinationDataSource?.tagInstances?.some(
            (tagInstance) => tagInstance.tagId === -7
          );

        if (state?.generatedOutputSource?.name || isGeneratedOutputSource) {
          generatedOutputSource = {
            ...(isGeneratedOutputSource && {
              id: state?.feed?.destinationDataSource?.id,
            }),
            columnInfo: [],
            name: isGeneratedOutputSource
              ? state?.feed?.destinationDataSource?.name
              : state?.generatedOutputSource?.name === "n/a"
              ? ""
              : state?.generatedOutputSource?.name,
          };

          action?.payload?.feed.forEach((f) => {
            f?.transformations.forEach((t) => {
              t?.transformerArguments.forEach((ta, i) => {
                if (
                  ta?.dataSourceColumnId &&
                  (ta?.dataSourceColumnId <= 0 ||
                    state?.feed?.destinationDataSource?.columns.find(
                      (c) => c.id === ta?.dataSourceColumnId
                    ))
                ) {
                  generatedOutputSource.columnInfo.push({
                    id: ta?.dataSourceColumnId,
                    name: ta?.name,
                  });
                }
              });
            });
          });
        }

        let transformers = [];
        action?.payload?.feed.forEach((f) => {
          f?.transformations.forEach((t) => {
            transformers.push({
              transformationType: t?.transformationType?.value,
              ...(t?.formattingString !== "" || null || undefined
                ? {
                    formattingString: t?.formattingString,
                  }
                : null),
              ...(t?.inputSubtype?.value && {
                inputSubtype: t?.inputSubtype?.value,
              }),
              ...(t?.outputSubtype?.value && {
                outputSubtype: t?.outputSubtype?.value,
              }),
              ...(t?.flags && {
                flags: t?.flags,
              }),
              transformerArguments: t?.transformerArguments.map((ta, i) => {
                return {
                  argumentOrder: i,
                  dataSourceColumnId: ta?.id,
                  ...(ta?.argumentInstanceId && {
                    argumentInstanceId: ta?.argumentInstanceId,
                  }),
                  ...(ta?.instanceMappingId && {
                    instanceMappingId: ta?.instanceMappingId,
                  }),
                };
              }),
            });
          });
        });
        const variables = {
          generatedOutputSource: generatedOutputSource,
          inputFeed: {
            permissions: state?.permissions,
            ...(state?.feedId &&
              !state?.isClone && {
                id: state?.feedId,
              }),
            destinationDataSourceId: action?.payload?.destinationDataSourceId,
            primarySourceDataSourceId:
              action?.payload?.primarySourceDataSourceId,
            sourceDataSourceIds: state?.ingestSources.map(
              (isources) => isources.id
            ),
            transformers: transformers,
            name: action?.payload?.name,
            ...(!state?.feedId && {
              dictionaries: [],
            }),
            tagInstanceInputs: state?.tagInstanceInputs,
          },
          refreshInfo: action?.payload?.refreshInfo,
        };

        // Comment the line below to DEBUG :: Don't Submit to API, log and Save to State
        doSubmitForm({ query: createServicerTransferFeed, variables });

        return {
          ...state,
        };
      }

      case "SET_FEED": {
        return {
          ...state,
          feed: action?.payload,
        };
      }
      case "CREATE_FEED_REQUEST": {
        return {
          ...state,
          createFeedId: action?.payload?.createFeedId,
          isFeedCreating: true,
        };
      }
      case "CREATE_FEED_FAILED": {
        return {
          ...state,
          isFeedCreating: false,
        };
      }
      case "SET_FEED_PROCESS_ERRORS": {
        return {
          ...state,
          isFeedCreating: false,
          createFeedId: null,
          processErrors: action?.payload?.errors,
        };
      }
      case "SET_FEED_ID": {
        return {
          ...state,
          step: action?.payload?.step,
          feedId: action?.payload?.feedId,
          isFeedCreating: false,
          createFeedId: null,
          processErrors: null,
        };
      }
      case "FINISH": {
        return {
          ...state,
          step: 12,
        };
      }
      case "SET_PERMISSIONS": {
        return {
          ...state,
          permissions: action.payload,
        };
      }

      case "SET_TAGS": {
        return {
          ...state,
          tagInstanceInputs: action?.payload,
        };
      }
    }
  };

  const initialState = {
    step: feed && !isClone ? 10 : 1,
    selectedSourceId: feed && !isClone ? feed?.destinationDataSourceId : null,
    ingestSources:
      feed && !isClone
        ? feed?.sourceDataSources.map((s) => {
            return { id: s?.id, name: s?.name };
          })
        : [],
    primarySourceDataSourceId: feed ? feed?.primarySourceDataSourceId : null,
    confirmedIngestColumns: 0,
    feed: feed && !isClone ? feed : null,
    feedId: feed ? feed?.id : null,
    permissions: feed?.permissions ? feed?.permissions : [],
    cloneSuggested: {
      feed: null,
      selectedSourceId: feed ? feed?.destinationDataSourceId : null,
      ingestSources: feed
        ? feed?.sourceDataSources.map((s) => {
            return { id: s?.id, name: s?.name };
          })
        : [],
    },
    isClone: isClone,
    processErrors: null,
  };
  const [state, dispatch] = useReducer(reducer, initialState);

  const selectOutputSource = useCallback((source) => {
    dispatch({ type: "OUTPUT_SOURCE_SELECT", payload: source });
  }, []);

  useEffect(() => {
    if (errors?.length) {
      dispatch({
        type: "CREATE_FEED_FAILED",
      });
      // Delay scrolling to ensure UI updates have occurred
      setTimeout(() => {
        window.scrollTo({
          top: document.body.scrollHeight,
          behavior: "smooth", // Optional: for smooth scrolling
        });
      }, 1000); // Delay in milliseconds, adjust as needed
    } else {
      if (data?.createServicerTransferFeed) {
        dispatch({
          type: "CREATE_FEED_REQUEST",
          payload: { createFeedId: data?.createServicerTransferFeed },
        });
      }
    }
  }, [data, errors]);

  const { feedNotification, setFeedNotification } = useFeedNotifications();

  useEffect(() => {
    const payload = feedNotification?.payload;

    const matchLastRequest = state?.createFeedId === payload?.RequestId;

    if (state.createFeedId && matchLastRequest) {
      if (
        payload?.AlertType === 30 &&
        payload?.SourceType === 7 &&
        !payload?.ErrorMessage &&
        payload?.SourceId
      ) {
        // Succeeded
        dispatch({
          type: "SET_FEED_ID",
          payload: { step: 11, feedId: payload?.SourceId },
        });
        setFeedNotification(null);
      } else if (payload?.ErrorMessage) {
        dispatch({
          type: "SET_FEED_PROCESS_ERRORS",
          payload: { errors: [{ message: payload?.ErrorMessage }] },
        });

        setFeedNotification(null);

        // Delay scrolling to ensure UI updates have occurred
        setTimeout(() => {
          window.scrollTo({
            top: document.body.scrollHeight,
            behavior: "smooth", // Optional: for smooth scrolling
          });
        }, 1000); // Delay in milliseconds, adjust as needed
      }
    }
  }, [feedNotification, setFeedNotification, state]);

  return (
    <>
      {state?.isFeedCreating || loading ? (
        <LoadingScreen>
          <SplashLoader text={"Saving Transformation Mapping"} />
        </LoadingScreen>
      ) : null}

      <ManageContainer>
        <div>
          <ul
            style={{
              borderRight: "1px solid #efefef",
              position: "sticky",
              insetBlockStart: "1rem",
            }}
          >
            <li>
              <TabLink
                active={state?.step <= 5}
                onClick={() => dispatch({ type: "CHANGE_STATE", payload: 1 })}
              >
                Output
              </TabLink>
            </li>
            <li>
              <TabLink
                active={state?.step <= 9 && state?.step >= 6}
                onClick={() => dispatch({ type: "CHANGE_STATE", payload: 7 })}
              >
                Input Files
              </TabLink>
            </li>
            <li>
              <TabLink
                disabled={
                  (!state?.generatedOutputSource && !state?.selectedSourceId) ||
                  !state?.ingestSources?.length
                }
                active={state?.step === 10}
                onClick={() =>
                  (state?.generatedOutputSource || state?.selectedSourceId) &&
                  state?.ingestSources?.length
                    ? dispatch({ type: "CHANGE_STATE", payload: 10 })
                    : null
                }
              >
                Mapping
              </TabLink>
            </li>
            <li>
              <TabLink
                disabled={
                  (!state?.generatedOutputSource && !state?.selectedSourceId) ||
                  !state?.ingestSources?.length
                }
                active={state?.step === 11}
                onClick={() =>
                  (state?.generatedOutputSource || state?.selectedSourceId) &&
                  state?.ingestSources?.length
                    ? dispatch({ type: "CHANGE_STATE", payload: 11 })
                    : null
                }
              >
                Code Breaking
              </TabLink>
            </li>
            <li>
              <TabLink
                disabled={!state?.feed}
                active={state?.step === 12}
                onClick={() =>
                  state?.feed && dispatch({ type: "CHANGE_STATE", payload: 12 })
                }
              >
                Summary
              </TabLink>
            </li>
          </ul>
        </div>

        <ManageContent>
          {state?.configureSourceName ? <div>Config</div> : null}
          {errors && <ErrorMessages errors={errors} />}
          {state?.processErrors && (
            <ErrorMessages errors={state?.processErrors} />
          )}

          <ManageFeed
            state={state}
            dispatch={dispatch}
            selectOutputSource={selectOutputSource}
          />
          {errors && <ErrorMessages errors={errors} />}
          {state?.processErrors && (
            <ErrorMessages errors={state?.processErrors} />
          )}
        </ManageContent>
      </ManageContainer>
    </>
  );
};

//Actions Section of Widget
function Actions() {
  return (
    <>
      <ActionWrapper>
        <StyledLink title="Back to List" to={feeds()}>
          <MdArrowBack />
        </StyledLink>
      </ActionWrapper>
    </>
  );
}

const FeedsPage = () => {
  let { feedId } = useParams();
  let location = useLocation();
  const isClone = feedId && location.pathname.includes("/clone");

  const [
    { loading: feedLoading, errors: feedErrors, data: feedData },
    doFetch,
  ] = useApi();

  useEffect(() => {
    if (feedId) {
      doFetch({
        query: getServicerTransferFeedById,
        variables: {
          id: Number(feedId),
        },
      });
    }
  }, [doFetch, feedId]);

  if (feedErrors) return <ErrorMessages errors={feedErrors} />;

  if (feedLoading) {
    return <div>Loading Transformation</div>;
  }
  const feed = feedData?.servicerTransferFeedById ?? null;

  return (
    <Card
      title={
        feedId
          ? isClone
            ? "Clone Transformation"
            : `Edit ${feed?.name} Transformation`
          : "Create New Transformation"
      }
      titleDescription={"Transform data"}
      body={() => <Body feed={feed} isClone={isClone} />}
      actions={Actions}
    />
  );
};

export default FeedsPage;
