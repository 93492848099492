import { useContext } from "react";
import { SocketContext } from "../contexts/useSubscriptions";

const UseFeedNotifications = () => {
  const {
    feedNotification,
    setFeedNotification,
    feedPreviewNotification,
    setFeedPreviewNotification,
  } = useContext(SocketContext);

  return {
    feedNotification,
    setFeedNotification,
    feedPreviewNotification,
    setFeedPreviewNotification,
  };
};
export default UseFeedNotifications;
