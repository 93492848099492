import React, { useContext } from "react";
import { Route } from "react-router-dom";
import roles from "../common/roles";
import Login from "./Login";
import { AuthContext } from "../contexts/AuthContext";
import CTAMessage from "../components/Help/CTAMessage";
import CTAWarn from "../components/Help/CTAWarn";
import ContentContainer from "../components/Layout/ContentContainer";
import styled from "styled-components/macro";

const FullHeightContainer = styled.div`
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ProtectedRoleRoute = ({
  component: Component,
  requiredRole,
  ...rest
}) => {
  const authContext = useContext(AuthContext);

  const defaultProtectedRole = roles.find(
    (userRole) => userRole.name === "REGULAR_USER"
  );
  const role = requiredRole
    ? roles.find((userRole) => userRole.name === requiredRole)
    : defaultProtectedRole;

  if (!authContext?.user) {
    return (
      <Route
        render={(props) => (
          <FullHeightContainer>
            <Login {...props} />
          </FullHeightContainer>
        )}
        {...rest}
      />
    );
  }

  if (authContext?.user.role !== 3 && !authContext?.userPermissioned) {
    return (
      <ContentContainer>
        <CTAMessage
          msg={
            <CTAWarn msg="Unauthorized. Please contact your administrator." />
          }
        />
      </ContentContainer>
    );
  }

  return (
    <Route
      render={(props) =>
        authContext?.user && role && authContext?.user.role >= role.roleId ? (
          <>
            <ContentContainer>
              <Component {...props} user={authContext?.user} />
            </ContentContainer>
          </>
        ) : (
          <ContentContainer>
            <CTAMessage
              msg={
                <CTAWarn msg="Unauthorized. Please contact your administrator." />
              }
            />
          </ContentContainer>
        )
      }
      {...rest}
    />
  );
};

export default ProtectedRoleRoute;
