import React, { useEffect, useReducer } from "react";
import Card from "../../Card";
import { MdHome, MdDelete } from "react-icons/md";
import { AiOutlineTable } from "react-icons/ai";
import { useApi } from "../../../api/useApi";
import styled from "styled-components/macro";
import SplashLoader from "../../Loaders/SplashLoader";
import Button from "../../Button";
import ErrorMessages from "../../Notifications/ErrorMessages";
import useETLProviderBrowseNotification from "../../../Hooks/useETLProviderBrowseNotification";
import useETLProviderInstanceCreateNotification from "../../../Hooks/useETLProviderInstanceCreateNotification";
import { browseForEtlProviders } from "../../../api/etlProviderQueries";
import { importEtlProviderInstances } from "../../../api/etlProviderMutations";

const ProviderRow = styled.div`
  margin-bottom: 0.5rem;
  display: flex;
  align-items: center;
  cursor: ${(props) => props.previouslyImported || "pointer"};
  padding: 0.5rem;
  border-left: ${(props) =>
    props.isSelected
      ? `4px solid ${props.theme.onSecondarySurface}`
      : "4px solid transparent"};
  &:hover {
    background: rgba(242, 242, 242, 0.4);
  }
`;

const SelectedRow = styled.div`
  margin-bottom: 0.5rem;
  display: flex;
  align-items: center;
  padding: 0.5rem;
`;

const SelectedRowDetails = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
`;

const BrowseIcon = styled.div`
  margin-right: 0.4rem;
  font-size: 1.3rem;
`;

const HeaderIcon = styled.div`
  margin-right: 0.4rem;
  font-size: 1.3rem;
  cursor: pointer;
  background: rgba(242, 242, 242, 1);
  padding: 0.5rem;
  &:hover {
    background: rgba(242, 242, 242, 0.8);
  }
`;

const HeaderRow = styled.div`
  margin-bottom: 0.5rem;
  display: flex;
  align-items: center;
`;

const ProvidersLoading = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 3;
  display: flex;
  background: rgba(255, 255, 255, 0.7);
`;

const ProvidersForm = styled.div`
  display: flex;
`;

const ProviderFormSection = styled.div`
  flex: 1;
  margin-right: 1rem;
  position: relative;
`;

const TreeItem = ({ data, isSelected, dispatch }) => {
  return (
    <ProviderRow
      onClick={() =>
        !data?.previouslyImported &&
        dispatch({ type: "SET_SELECTED", payload: data })
      }
      isSelected={isSelected || data.previouslyImported}
      disable={isSelected || data.previouslyImported}
      previouslyImported={data.previouslyImported}
      title={
        data?.previouslyImported ? "Previously Imported" : "Select to Import"
      }
    >
      <BrowseIcon>
        <AiOutlineTable />
      </BrowseIcon>
      {data?.name}
    </ProviderRow>
  );
};

const ProviderBrowser = ({ fetchLatestETLProvider, data }) => {
  const eltProviderId = data?.id ?? null;

  const [{}, importInstances] = useApi(); // eslint-disable-line no-empty-pattern

  const [{ errors, data: browseData }, browse] = useApi();

  // On Load, start the browse query
  useEffect(() => {
    if (eltProviderId) {
      browse({
        query: browseForEtlProviders,
        variables: {
          id: eltProviderId,
        },
      });
    }
  }, [browse, eltProviderId]);

  const reducer = (state, action) => {
    switch (action.type) {
      default:
        return {
          ...state,
          errors: null,
        };

      // GO TO ROOT
      case "GO_TO_ROOT": {
        browse({
          query: browseForEtlProviders,
          variables: {
            id: eltProviderId,
          },
        });

        return {
          ...state,
          fetchingItem: null,
          currentItem: null,
          loading: true,
          errors: null,
        };
      }

      // SET ERRORS
      case "SET_ERRORS": {
        return {
          ...state,
          loading: false,
          errors: action.payload,
        };
      }

      // SET RESULTS
      case "SET_RESULTS": {
        return {
          ...state,
          tree: action.payload,
          currentItem: state.fetchingItem,
          fetchingItem: null,
          loading: false,
          errors: null,
        };
      }

      // SET FETCHING
      case "SET_FETCHING": {
        return {
          ...state,
          fetchingItem: action?.payload?.request,
        };
      }

      // SET SELECTED
      case "SET_SELECTED": {
        const arr = state.selected.includes(action.payload)
          ? state.selected.filter((i) => i !== action.payload)
          : [...state.selected, action.payload];

        return {
          ...state,
          selected: arr,
          errors: null,
        };
      }

      // REMOVE SELECTED
      case "REMOVE_SELECTED": {
        const currentSelected = state.selected.slice(0);
        currentSelected.splice(action.payload, 1);
        return {
          ...state,
          selected: currentSelected,
          errors: null,
        };
      }

      // SUBMIT FORM
      case "SUBMIT_FORM": {
        const instancesToImport = state.selected.map((item) => {
          const { previouslyImported, ...cleanItem } = item;

          return {
            ...cleanItem,
          };
        });

        const variables = {
          id: eltProviderId,
          instances: instancesToImport,
        };

        importInstances({
          query: importEtlProviderInstances,
          variables: variables,
        });
        return {
          ...state,
          errors: null,
          loading: true,
        };
      }
    }
  };

  const initialState = {
    tree: [],
    selected: [],
    loading: true,
    currentItem: null,
    fetchingItem: null,
    instancesToImport: [],
    errors: [],
  };

  const [state, dispatch] = useReducer(reducer, initialState);
  const { etlProviderBrowseCompleted, setEtlProviderBrowseCompleted } =
    useETLProviderBrowseNotification();
  const {
    etlProviderInstanceCreateCompleted,
    setEtlProviderInstanceCreateCompleted,
  } = useETLProviderInstanceCreateNotification();

  useEffect(() => {
    if (browseData) {
      const request = browseData?.browseForEtlProviders?.edges ?? [];
      dispatch({ type: "SET_FETCHING", payload: { request } });
    }
  }, [browseData]);

  useEffect(() => {
    if (etlProviderBrowseCompleted) {
      const parsedItems = etlProviderBrowseCompleted.payload.BrowseItems;
      const items = parsedItems.map((item) => {
        return {
          name: item.Name,
          previouslyImported: item.PreviouslyImported,
        };
      });
      dispatch({ type: "SET_RESULTS", payload: items });
    }
  }, [etlProviderBrowseCompleted]);

  useEffect(() => {
    if (etlProviderInstanceCreateCompleted) {
      const { HasErrors, ErrorMessage } =
        etlProviderInstanceCreateCompleted.payload;

      if (HasErrors) {
        setEtlProviderBrowseCompleted(null);
        setEtlProviderInstanceCreateCompleted(null);
      } else if (ErrorMessage.length > 0) {
        dispatch({
          type: "SET_ERRORS",
          payload: ErrorMessage,
        });
      }
    }
  }, [
    etlProviderInstanceCreateCompleted,
    fetchLatestETLProvider,
    setEtlProviderInstanceCreateCompleted,
    setEtlProviderBrowseCompleted,
  ]);

  return (
    <>
      <ProvidersForm>
        <ProviderFormSection>
          <HeaderRow>
            <HeaderIcon
              onClick={() => dispatch({ type: "GO_TO_ROOT", payload: null })}
            >
              <MdHome />
            </HeaderIcon>
            {"/"}
          </HeaderRow>

          {state.loading ? (
            <ProvidersLoading>
              <SplashLoader text="Loading Results" />
            </ProvidersLoading>
          ) : null}

          {state.tree.map((data, i) => {
            const isSelected = state.selected.find(
              (item) => item.name === data.name
            );

            return (
              <TreeItem
                key={i}
                data={data}
                dispatch={dispatch}
                isSelected={isSelected}
              />
            );
          })}
        </ProviderFormSection>

        <ProviderFormSection>
          <div>
            <h3>Selected Instances:</h3>
          </div>

          {state.selected.length ? (
            <>
              {state.selected.map((item, i) => {
                return (
                  <SelectedRow>
                    <BrowseIcon>
                      <AiOutlineTable />
                    </BrowseIcon>
                    <SelectedRowDetails>
                      <div>{item?.name}</div>
                      <div
                        style={{ marginLeft: "auto", cursor: "pointer" }}
                        onClick={() =>
                          dispatch({ type: "REMOVE_SELECTED", payload: i })
                        }
                      >
                        <MdDelete />
                      </div>
                    </SelectedRowDetails>
                  </SelectedRow>
                );
              })}

              {errors ? <ErrorMessages errors={errors} /> : null}

              <Button
                type="button"
                onClick={() => dispatch({ type: "SUBMIT_FORM", payload: null })}
              >
                Continue
              </Button>
            </>
          ) : (
            <>
              Browse and select instances of the ETL Provider from the
              navigation on the left
            </>
          )}
        </ProviderFormSection>
      </ProvidersForm>
    </>
  );
};

const ETLBrowseComponent = ({ etlProviderData, fetchLatestETLProvider }) => {
  // Body Section of Widget
  function Body() {
    return (
      <ProviderBrowser
        data={etlProviderData}
        fetchLatestETLProvider={fetchLatestETLProvider}
      />
    );
  }

  // Actions Section of Widget
  return <Card headless={true} body={Body} />;
};

export default ETLBrowseComponent;
