import React, { useState, useReducer, useEffect, useContext } from "react";
import FragmentForm from "./FragmentForm";
import { MdAdd } from "react-icons/md";
import cloneDeep from "lodash.clonedeep";
import reducer from "./rulesReducer";
import { IoIosEyeOff, IoIosEye } from "react-icons/io";
import { AuthContext } from "../../../contexts/AuthContext";
import { VscBeaker, VscBeakerStop } from "react-icons/vsc";
import { canUseBCA } from "../../../common/helpers/auth";
import ErrorMessages from "../../Notifications/ErrorMessages";
import Spinner from "../../Loaders/Spinner";
import Button from "../../Button";
import {
  createRuleStandard,
  updateBusinessStandardAndFilter,
} from "../../../api/ruleMutations";
import { testBusinessRuleInstance } from "../../../api/ruleQueries";

import Permissions from "../../Form/PermissionsWithoutFormik";
import {
  FormControl,
  StyledInput,
  FormActions,
  StyledTextArea,
  Label,
} from "../../Form/FormControls";
import { useApi } from "../../../api/useApi";
import Fragment from "./Fragment";
import Conditional from "./Conditional";
import { buildingBlocks } from "./buildingBlocks";
import styled from "styled-components/macro";
import Modal from "../../Modal";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Prompt } from "react-router-dom";
import { SmallText } from "../ETL/CreateForm/CreateFormStyles";
import UpdateTags from "../../Tags/UpdateTags";
import SelectTags from "../../Tags/SelectTags";
import { ruleStandardById } from "../../../common/paths";
import { PolicyChatContext } from "../../../contexts/ai/PolicyChatContext";
import SpinningLoader from "../../Loaders/SpinningLoader";

const ImportContainer = ({ dispatch, setShowImport }) => {
  const [importState, setImportState] = useState(null);
  const [importError, setImportError] = useState(null);
  return (
    <>
      <p>
        <i>
          When Importing, Permissions will <b>NOT</b> Transfer and will need to
          be set.
        </i>
      </p>
      <StyledTextArea
        name={`standardImport`}
        placeholder="Paste JSON Import Here"
        label="JSON Import"
        component="textarea"
        rows="5"
        onChange={(e) => {
          try {
            const parsedObj = JSON.parse(e);
            if (
              parsedObj?.name &&
              parsedObj?.standard?.length &&
              parsedObj?.description
            ) {
              setImportState(parsedObj);
              setImportError(false);
            } else {
              setImportError(true);
            }
          } catch (e) {
            setImportError(true);
          }
        }}
      />

      {importError ? (
        <ErrorMessages
          errors={[
            {
              message:
                "Error With Import Format, Import Object Needs Name, Description, Standards",
            },
          ]}
        />
      ) : null}

      <FormActions>
        <Button
          type="button"
          onClick={() => {
            dispatch({ type: "IMPORT_STANDARD", payload: importState });
            setShowImport(false);
          }}
          disabled={!importState}
        >
          Import
        </Button>
      </FormActions>
    </>
  );
};

const DetailsToggle = styled.div`
  transition: 0.3s all;
  display: inline-block;
  height: 16px;
  width: 16px;
  margin-left: auto;
`;

const SecondHeading = styled.div`
  font-size: 1.5rem;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
  display: flex;
  align-items: center;
`;

const EditViewControl = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  margin-left: auto;
  font-size: 1.5rem;
  &:hover {
    cursor: pointer;
  }
`;

const ElementForm = styled.div`
  position: fixed;
  right: 0;
  width: 350px;
  height: calc(100vh);
  background: #fff;
  padding: 1rem;
  z-index: 2;
`;

const RulePermissions = styled.div`
  margin-top: 3em;
`;

const ComponentElements = {
  fragment: Fragment,
  conditions: Conditional,
  conditionOperators: Conditional,
};

const StandardFormContainer = styled.div`
  display: flex;
`;

const StandardFormElementsList = styled.div`
  margin-right: 1rem;
  min-width: 200px;
`;

const StandardFormElements = styled.div`
  flex: 1;
`;

const StandardContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const StandardElement = styled.div`
  margin-bottom: 1rem;
  margin-left: ${(props) => (props.indent ? "1rem" : "")};
`;

const Break = styled.div`
  width: 100%;
`;

const RuleListItem = styled.div`
  padding: 1rem;
  margin-bottom: 0.5rem;
  cursor: pointer;
  display: flex;
  background: ${(props) => props.theme.secondarySurface};
  border-left: 4px solid ${(props) => props.theme.onSecondarySurface};
  &:hover {
    ${DetailsToggle} {
      color: black;
    }
  }
`;

const Container = ({
  standardId,
  initialData,
  history,
  isFilter,
  isEditing,
  sourceInstances,
  triggerRefresh,
}) => {
  const { user } = useContext(AuthContext);
  const { generatedPolicy } = useContext(PolicyChatContext);

  const statementCombinations = [
    {
      name: "Basic Statement",
      buildingBlocks: [buildingBlocks.Fragment[0]],
    },
    {
      name: "If Statement",
      buildingBlocks: [
        buildingBlocks.Conditions[0],
        buildingBlocks["Condition Operators"][2],
        buildingBlocks.Fragment[0],
        buildingBlocks["Condition Operators"][3],
        buildingBlocks.Conditions[4],
        buildingBlocks.Fragment[0],
        buildingBlocks.Conditions[3],
      ],
    },
    {
      name: "If Else Statement",
      buildingBlocks: [
        buildingBlocks.Conditions[0],
        buildingBlocks["Condition Operators"][2],
        buildingBlocks.Fragment[0],
        buildingBlocks["Condition Operators"][3],
        buildingBlocks.Conditions[4],
        buildingBlocks.Fragment[0],
        buildingBlocks.Conditions[1],
        buildingBlocks["Condition Operators"][2],
        buildingBlocks.Fragment[0],
        buildingBlocks["Condition Operators"][3],
        buildingBlocks.Conditions[4],
        buildingBlocks.Fragment[0],
        buildingBlocks.Conditions[3],
      ],
    },
    ...(canUseBCA(user?.email)
      ? [
          {
            name: "Expression",
            buildingBlocks: [buildingBlocks.Fragment[1]],
          },
        ]
      : []),
  ];

  const initialState = {
    standard: initialData.standard,
    permissions: initialData.permissions,
    tagInstances: initialData.tagInstances,
    name: initialData.name,
    description: initialData.description,
    editStandardElement: null,
    editView: true,
    errors: [],
    loading: false,
    enableTest: false,
    testRow: "",
    prevRow: "",
    passed: false,
    goodTestParse: { status: true },
  };

  //initialize the reducer state
  const [state, dispatch] = useReducer(reducer, initialState);

  const [showImport, setShowImport] = useState(false);

  const [showAiBuildingRule, setShowAiBuildingRule] = useState(false);

  const [{ errors: testRuleResultErrors, data: testRuleResults }, doTest] =
    useApi();

  const [{ errors: createdErrors, data: created }, create] = useApi();

  const [{ errors: updatedErrors, data: updated }, implementationUpdate] =
    useApi();

  useEffect(() => {
    if ((created && !createdErrors) || (updated && !updatedErrors)) {
      if (sourceInstances && sourceInstances.length > 0 && updated) {
        triggerRefresh();
        history.push(ruleStandardById(standardId));
      } else {
        history.push(isFilter ? "/policies/filters" : "/policies/rules");
      }
    } else {
      dispatch({
        type: "SET_ERRORS",
        payload: createdErrors ? createdErrors : updatedErrors,
      });
    }
  }, [created, updated, createdErrors, updatedErrors, history, isFilter]);

  useEffect(() => {
    if (generatedPolicy) {
      ingestNCalcPolicy(generatedPolicy);
    }
  }, [generatedPolicy]);

  const ingestNCalcPolicy = (nCalc) => {
    setShowAiBuildingRule(true);
    setTimeout(() => {
      setShowAiBuildingRule(false);
      dispatch({
        type: "IMPORT_STANDARD",
        payload: {
          standard: [
            {
              name: "Fragment",
              def: "fragment",
              source: {
                typeInformation: [
                  {
                    typeHierarchyLevel: 0,
                    typeValue: 4,
                    fragmentValue: null,
                  },
                  {
                    typeHierarchyLevel: 1,
                    typeValue: { label: "Value", value: 4 },
                    fragmentValue: "True",
                  },
                ],
              },
              target: {
                typeInformation: [
                  {
                    typeHierarchyLevel: 0,
                    typeValue: 5,
                    fragmentValue: null,
                  },
                  {
                    typeHierarchyLevel: 1,
                    typeValue: {
                      label: "Calculation",
                      value: 4,
                      isDisabled: false,
                    },
                    fragmentValue: `${nCalc?.expression}`,
                  },
                  {
                    typeHierarchyLevel: 2,
                    typeValue: { label: "None", value: 0 },
                    fragmentValue: "0",
                  },
                ],
                testInfo: null,
              },
              operation: {
                typeInformation: [
                  {
                    typeHierarchyLevel: 0,
                    typeValue: 3,
                    fragmentValue: null,
                  },
                  {
                    typeHierarchyLevel: 1,
                    typeValue: { label: "=", value: 1 },
                    fragmentValue: null,
                  },
                ],
              },
            },
          ],
          name: nCalc?.name,
          description: nCalc?.description,
        },
      });
    }, 2000);
  };

  useEffect(() => {
    if (testRuleResults) {
      if (
        (testRuleResults?.testBusinessRuleInstance?.failureCode !== "SUCCESS" &&
          testRuleResults?.testBusinessRuleInstance?.failureCode !==
            "NOTAPPLICABLE") ||
        !testRuleResults?.testBusinessRuleInstance?.failureCode
      ) {
        dispatch({
          type: "SET_ERRORS",
          payload: [
            {
              message: `Test Failed: ${testRuleResults?.testBusinessRuleInstance?.failureCode}`,
            },
          ],
        });
      } else {
        toast.success("Test Passed", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });

        dispatch({
          type: "DONE_LOADING",
        });
      }
    }
  }, [testRuleResults]);

  useEffect(() => {
    if (testRuleResultErrors) {
      dispatch({
        type: "SET_ERRORS",
        payload: testRuleResultErrors,
      });
    }
  }, [testRuleResultErrors]);

  const exportRule = () => {
    if (standardId) {
      const standardPkg = JSON.stringify({
        standard: state.standard,
        name: state.name,
        description: state.description,
      });
      navigator.clipboard.writeText(standardPkg).then(
        function () {
          toast.success("Policy JSON Copied for Import", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        },
        function (err) {
          toast.error("Failed To Copy", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      );
    }
  };

  const saveRule = (isTest) => {
    const form = cloneDeep(state);
    let ruleFragments = [];

    dispatch({
      type: "SET_SAVED_LOADING",
    });
    const cloneCards = [...form.standard];

    let columnFragments = 0;

    cloneCards.forEach((card, i) => {
      if (card.def === "fragment") {
        if (card.source) {
          const source = card.source;

          if (source.typeInformation[1].typeValue?.value === 1) {
            columnFragments++;
          }

          source.typeInformation = source.typeInformation.map((st, i) => {
            return {
              fragmentValue: st.fragmentValue,
              typeHierarchyLevel: st.typeHierarchyLevel,
              typeValue: st?.typeValue?.label
                ? st?.typeValue?.value
                : st?.typeValue,
            };
          });

          ruleFragments.push(source);
        }

        if (card.operation) {
          const operation = card.operation;
          operation.typeInformation = operation.typeInformation.map((st, i) => {
            return {
              fragmentValue: st.fragmentValue,
              typeHierarchyLevel: st.typeHierarchyLevel,
              typeValue: st.typeValue.label ? st.typeValue.value : st.typeValue,
            };
          });

          ruleFragments.push(operation);
        }
        //Handle the case in which operation is set to IsEmpty, No Target should be sent or loaded
        if (card.target) {
          const target = card.target;
          if (target.typeInformation[1].typeValue?.value === 1) {
            columnFragments++;
          }
          target.typeInformation = target.typeInformation.map((st, i) => {
            return {
              typeHierarchyLevel: st.typeHierarchyLevel,
              typeValue: st.typeValue.label ? st.typeValue.value : st.typeValue,
              fragmentValue:
                st.fragmentValue && st.fragmentValue.label
                  ? st.fragmentValue.value.toString()
                  : st.fragmentValue !== null
                  ? st.fragmentValue.toString()
                  : st.fragmentValue,
            };
          });

          ruleFragments.push(target);
        }
      } else {
        const ruleFragment = {
          typeInformation: card.typeInformation.map((information) => {
            return {
              fragmentValue: information.fragmentValue,
              typeHierarchyLevel: information.typeHierarchyLevel,
              typeValue: information.typeValue,
            };
          }),
        };

        ruleFragments.push(ruleFragment);
      }
    });

    const orderedFragments = ruleFragments.map((frag, i) => {
      return { executionOrder: i, ...frag };
    });

    const cleanOrderedFragments = orderedFragments.map((f) => {
      return {
        executionOrder: f.executionOrder,
        typeInformation: f.typeInformation,
      };
    });

    const testFragments = orderedFragments.filter((cof) => cof.testInfo);

    if (standardId && !isTest) {
      const variables = {
        id: Number(standardId),
        newVersion: { fragments: cleanOrderedFragments },
        name: state.name,
        description: state.description,
        permissions: state.permissions,
        tagInstances: state?.tagInstances ?? null,
      };

      implementationUpdate({
        query: updateBusinessStandardAndFilter,
        variables,
      });
    } else {
      if (isTest) {
        if (testFragments?.length !== columnFragments) {
          dispatch({
            type: "SET_ERRORS",
            payload: [{ message: "You must select test data for all columns" }],
          });
        } else {
          const variables = {
            inputInstance: {
              standardId: 0,
              isAReconciliationRuleInstance: false,
              isACrosstableRuleInstance: false,
              title: "",
              versions: [
                {
                  standardVersionId: 0,
                  mappings: testFragments.map((ti) => {
                    return {
                      businessRuleFragmentId: ti.executionOrder,
                      columnId: ti.testInfo?.value,
                    };
                  }),
                  matchingColumns: [],
                },
              ],
            },
            inputStandard: {
              name: state.name,
              standardType: isFilter ? "FILTER" : "RULE",
              description: state.description,
              versions: [{ fragments: cleanOrderedFragments }],
              permissions: state.permissions,
              tagInstances: state?.tagInstances ?? null,
            },
            strDataRow: state.testRow,
            strPrevRow: "",
          };

          doTest({ query: testBusinessRuleInstance, variables });
        }
      } else {
        const variables = {
          newStandard: {
            name: state.name,
            standardType: isFilter ? "FILTER" : "RULE",
            description: state.description,
            versions: [{ fragments: cleanOrderedFragments }],
            permissions: state.permissions,
            tagInstances: state?.tagInstances ?? null,
          },
        };
        create({ query: createRuleStandard, variables });
      }
    }
  };

  if (standardId === "-2" || standardId === "-3") {
    return (
      <div>
        You cannot edit System Policies Templates.
        <div>
          <div style={{ marginTop: "1rem" }}>
            {" "}
            <UpdateTags
              remoteObjectId={Number(standardId)}
              tagType={"POLICY"}
            />
          </div>
        </div>
      </div>
    );
  }

  return (
    <StandardFormContainer>
      {/* Prompt Unsaved Changes only on create for new, need to implement isDirty */}
      {!standardId && (
        <Prompt
          when={state?.standard?.length && !created}
          message="Are you sure you want to leave unsaved changes?"
        />
      )}
      <ToastContainer />
      {showImport ? (
        <Modal
          title={"Import Standard"}
          hide={() => setShowImport((prevState) => !prevState)}
        >
          <ImportContainer dispatch={dispatch} setShowImport={setShowImport} />
        </Modal>
      ) : null}

      {state.editStandardElement ? (
        <ElementForm>
          <FragmentForm
            element={state.editStandardElement.element}
            index={state.editStandardElement.index}
            dispatch={dispatch}
            isEditing={isEditing}
          />
        </ElementForm>
      ) : null}
      <StandardFormElementsList>
        <h3>Statements</h3>
        {statementCombinations.map((statement, i) => {
          return (
            <RuleListItem
              key={`statementCombinations-${i}`}
              onClick={() =>
                dispatch({
                  type: "ADD_CONDITION",
                  payload: statement.buildingBlocks,
                })
              }
            >
              {statement.name}

              <DetailsToggle>
                <MdAdd />
              </DetailsToggle>
            </RuleListItem>
          );
        })}
      </StandardFormElementsList>
      <StandardFormElements>
        <div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "1rem",
            }}
          >
            <div style={{ fontSize: "1.5rem", fontWeight: "bold" }}>
              Details
            </div>
            <div style={{ marginLeft: "auto" }}>
              {state.enableTest ? (
                <Button
                  type="button"
                  list={true}
                  onClick={() => saveRule(true)}
                  disabled={
                    state?.loading ||
                    !state.name ||
                    !state.description ||
                    !state.goodTestParse?.status
                  }
                >
                  {state?.loading ? <Spinner /> : `Test Rule`}
                </Button>
              ) : null}

              <Button
                type="button"
                onClick={() => saveRule()}
                disabled={state?.loading || !state.name || !state.description}
              >
                {state?.loading ? (
                  <Spinner />
                ) : (
                  `Save ${isFilter ? "Filter" : "Rule"}`
                )}
              </Button>
            </div>
          </div>

          {state?.errors?.length ? (
            <ErrorMessages errors={state.errors} />
          ) : null}

          <FormControl>
            <StyledInput
              type="text"
              name="name"
              label="Name"
              required={true}
              value={state.name}
              placeholder={`${isFilter ? "Filter" : "Rule"} Name`}
              onChange={(e) =>
                dispatch({
                  type: "UPDATE_NAME",
                  payload: e.target.value,
                })
              }
            />
          </FormControl>
          <FormControl>
            <StyledInput
              type="text"
              name="description"
              label="Description"
              required={true}
              value={state.description}
              placeholder={`${isFilter ? "Filter" : "Rule"} Description`}
              onChange={(e) =>
                dispatch({
                  type: "UPDATE_DESCRIPTION",
                  payload: e.target.value,
                })
              }
            />
          </FormControl>
        </div>

        <SecondHeading>
          Policy{" "}
          {showAiBuildingRule && (
            <span style={{ marginLeft: "10px" }}>
              <SpinningLoader text={"Applying generated policy..."} />
            </span>
          )}
          <div style={{ display: "flex", marginLeft: "auto" }}>
            <EditViewControl
              title="Toggle Test Mode"
              onClick={() => dispatch({ type: "ENABLE_TEST" })}
            >
              {state.enableTest ? <VscBeaker /> : <VscBeakerStop />}
            </EditViewControl>
            <EditViewControl
              title="Toggle Edit Mode View"
              onClick={() => dispatch({ type: "TOGGLE_EDIT" })}
            >
              {state.editView ? <IoIosEye /> : <IoIosEyeOff />}
            </EditViewControl>
          </div>
        </SecondHeading>
        {state?.enableTest ? (
          <FormControl>
            <Label>Test Data</Label>

            <SmallText>
              Example: ["729447","Frederick Rodriguez", null, "9 Bechtelar
              Alley", 7]
            </SmallText>
            {state.goodTestParse?.status ? null : (
              <div style={{ color: "red" }}>
                {state.goodTestParse?.message
                  ? state?.goodTestParse?.message
                  : "Failed To Parse JSON"}
              </div>
            )}
            <StyledInput
              type="text"
              name="source-value"
              value={state?.testRow}
              placeholder="Test Row Data"
              onChange={(e) =>
                dispatch({
                  type: "UPDATE_TEST_ROW",
                  payload: e.target.value,
                })
              }
            />
          </FormControl>
        ) : null}
        <StandardContainer>
          {state.standard.map((element, i) => {
            const isLastFirstBracket =
              element.name === "(" &&
              state.standard[i + 1] &&
              state.standard[i + 1].name !== "(";

            const isLastLastBracket =
              element.name === ")" &&
              state.standard[i - 1] &&
              state.standard[i - 1].name !== ")";

            const isBlockedElement =
              element.name === "Then" ||
              element.name === "End If" ||
              element.name === "Else If";

            const ConditionalElementComponent = ComponentElements[element.def];

            const isOrStatement = element.name === "Or";
            const isAndStatement = element.name === "And";

            const indent = isOrStatement || isAndStatement;

            return (
              <React.Fragment key={`standardMap-${i}`}>
                {isBlockedElement ||
                isLastLastBracket ||
                isOrStatement ||
                isAndStatement ? (
                  <Break />
                ) : null}
                <StandardElement
                  isBlockedElement={isBlockedElement}
                  indent={indent}
                >
                  <ConditionalElementComponent
                    dispatch={dispatch}
                    element={element}
                    index={i}
                    editView={state.editView}
                    isEditing={isEditing}
                    isFilter={isFilter}
                    enableTest={state.enableTest}
                    testRow={state.testRow}
                    goodTestParse={state.goodTestParse?.status}
                  />
                </StandardElement>
                {isBlockedElement || isLastFirstBracket ? <Break /> : null}
              </React.Fragment>
            );
          })}
        </StandardContainer>

        {state.standard && state.standard.length ? (
          <div>
            <Button
              danger
              onClick={() => dispatch({ type: "CLEAR_FORM", payload: null })}
            >
              Clear {`${isFilter ? "Filter" : "Rule"}`}
            </Button>
          </div>
        ) : null}

        <RulePermissions>
          <h3>Permissions</h3>

          <Permissions dispatch={dispatch} permissions={state.permissions} />
        </RulePermissions>

        {!standardId ? (
          <>
            <h3>Tags</h3>
            <p>Select the appropriate tags below.</p>
            <SelectTags
              currentTags={initialData.tagInstances}
              updateTags={dispatch}
              // fieldName={`dataSourcesToImport[${i}].tagInstances`}
              tagType={"POLICY"}
              dispatchType={"SET_TAGS"}
            />{" "}
          </>
        ) : null}

        {state?.errors?.length ? <ErrorMessages errors={state.errors} /> : null}

        <div style={{ display: "flex", marginTop: "1rem" }}>
          <div style={{ flex: 1 }}>
            <Button
              type="button"
              list="true"
              onClick={() => setShowImport((prevState) => !prevState)}
              disabled={state?.loading}
            >
              {`Import ${isFilter ? "Filter" : "Rule"}`}
            </Button>

            {standardId ? (
              <Button
                type="button"
                list="true"
                onClick={() => exportRule()}
                disabled={state?.loading || !state.name || !state.description}
              >
                {`Copy ${isFilter ? "Filter" : "Rule"}`}
              </Button>
            ) : null}
          </div>
          <div style={{ marginLeft: "auto" }}>
            {state.enableTest ? (
              <Button
                type="button"
                list={true}
                onClick={() => saveRule(true)}
                disabled={
                  state?.loading ||
                  !state.name ||
                  !state.description ||
                  !state.goodTestParse?.status
                }
              >
                {state?.loading ? <Spinner /> : `Test Rule`}
              </Button>
            ) : null}

            <Button
              type="button"
              onClick={() => saveRule()}
              disabled={state?.loading || !state.name || !state.description}
            >
              {state?.loading ? (
                <Spinner />
              ) : (
                `Save ${isFilter ? "Filter" : "Rule"}`
              )}
            </Button>
          </div>
        </div>

        {standardId && (
          <div style={{ marginTop: "1rem" }}>
            {" "}
            <UpdateTags
              remoteObjectId={Number(standardId)}
              tagType={"POLICY"}
            />
          </div>
        )}
      </StandardFormElements>
    </StandardFormContainer>
  );
};

export default Container;
