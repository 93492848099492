import React, { useState } from "react";
import styled from "styled-components/macro";
import DraggableColumn from "./DraggableColumn";
import { GiArtificialHive } from "react-icons/gi";
import { MdExpandLess, MdExpandMore } from "react-icons/md";

const ColumnList = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  max-height: calc(100vh - 535px);
`;

const ColumnHeadingIconWrap = styled.div`
  font-size: 1.5em;
  display: flex;
  margin-right: 0.5rem;
`;

const ColumnHeading = styled.div`
  padding: 10px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  color: #fff;
  background: #666;
  cursor: pointer;
`;

const SuggestionMapping = ({ suggestedColumns, currentFragments }) => {
  const [expand, setExpand] = useState(true);

  return (
    <div
      style={{
        marginBottom: ".8rem",
        borderBottom: "2px solid #666",
      }}
    >
      <ColumnHeading
        onClick={() => setExpand((prev) => !prev)}
        title={expand ? "Close" : "Expand"}
      >
        <ColumnHeadingIconWrap>
          <GiArtificialHive />
        </ColumnHeadingIconWrap>
        Suggested
        <div style={{ marginLeft: "auto" }}>
          {expand ? <MdExpandLess /> : <MdExpandMore />}
        </div>
      </ColumnHeading>
      {expand ? (
        <ColumnList>
          {suggestedColumns.map((dragItem, index) => {
            return (
              <DraggableColumn
                item={dragItem}
                name={dragItem.name}
                currentFragments={currentFragments}
                type={"column"}
                dataType={dragItem.dataType.toLowerCase()}
                key={`draggableColumn-${index}`}
                // isDropped={this.isDropped(dragItem.name)}
              />
            );
          })}
        </ColumnList>
      ) : null}
    </div>
  );
};

export default SuggestionMapping;
